import { useEffect, useState } from 'react';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import ChangeCompanyView from './components/DataDrawer';

import Client from '../../../../classes/Client';
import ChangeProjectSignersWindow from '../../../../components/ChangeProjectSignersWindow';
import FilterInput from '../../../../components/FilterInput';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import Pagination from '../../../../components/Pagination';
import TinyTable from '../../../../components/TinyTable';
import ViewBox from '../../../../components/ViewBox';
import { useAppStateCtx } from '../../../../context/AppState';
import { useOperatorCompaniesCtxAPI } from '../../../../context/OperatorCompaniesContext';
import { escapeRegex, removeDiacritics } from '../../../../utils/common';
import { sortByKey, sortObjectEntriesByKey } from '../../../../utils/filters';

const filterText = signal('');

const FilterText = () => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value]);

    return (
        <FilterInput value={value} setValue={setValue} placeholder="Digite para filtrar" />
    );
};

const Pages = ({ count, currentPage, perPage, handlePageClick }) => {
    if(filterText.value.length === 0){
        return (
            <Pagination count={count} page={currentPage} perPage={perPage} onChange={(_, page) => handlePageClick(page)} />
        );
    }
    return null;
};

const Companies = () => {
    useSignals();
    const { workspaceClients, selectedWorkspace } = useAppStateCtx();
    const { showAddCompanyView, showChangeCompanyView } = useOperatorCompaniesCtxAPI();
    const [loading, setLoading] = useState(true);
    const [availableClients, setAvailableClients] = useState([]);
    const [visibleClients, setVisibleClients] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    
    useEffect(() => {
        if(selectedWorkspace.value && workspaceClients.value){
            const currentClients = Object.entries(workspaceClients.value).sort(sortObjectEntriesByKey('name')).map(([clientId, currentClient]) => {
                currentClient.uid = clientId;
                currentClient.workspaceId = selectedWorkspace.value.uid;
                return currentClient;
            });
            setAvailableClients(currentClients);
        }
    }, [selectedWorkspace.value, workspaceClients.value]);

    useEffect(() => {
        if(workspaceClients.value && visibleClients){
            setLoading(false);
        }
    }, [workspaceClients.value, visibleClients]);

    const perPage = 100;

    const showPage = () => {
        if(availableClients){
            const firstIndex = (currentPage - 1) * perPage;
            const lastIndex = (currentPage * perPage);
            const pageArray = availableClients.slice(firstIndex, lastIndex);
            setVisibleClients(pageArray);
        }
    }

    useEffect(() => {
        showPage();
    }, [availableClients, currentPage]);

    useEffect(() => {
        if(filterText.value.length !== 0){
            setVisibleClients(
                availableClients.filter(client => {
                    const textFields = removeDiacritics(`${client.name}`);
                    if(filterText.value.length === 1){
                        const regEx = new RegExp(`^${escapeRegex(filterText.value).toUpperCase()}`);
                        return regEx.test(textFields.toUpperCase());
                    }
                    return textFields.toUpperCase().indexOf(removeDiacritics(filterText.value).toUpperCase()) !== -1;
                })
            )
        } else {
            showPage();
        }
    }, [filterText.value]);

    const handleAddCompanyButtonClick = () => {
        showAddCompanyView();
    };

    const handleClientRowClick = (clickedClient) => {
        const newClient = new Client({...clickedClient.data});
        showChangeCompanyView(newClient);
    }

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>

            <Box mb={2}>
                <Grid container justifyContent="flex-end" alignContent="flex-end">
                    <Grid size="grow">
                        <FilterText />
                    </Grid>
                    <Grid size={{ xs: 6 }}></Grid>
                    <Grid>
                        <Button variant="contained" onClick={handleAddCompanyButtonClick} startIcon={<AddIcon />}>Novo cliente</Button>
                    </Grid>
                </Grid>
            </Box>
            
            {
                loading
                ? <LoaderEllipsis />
                :
                <>
                    <Pages count={availableClients.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    <Box pb={5}>
                        <Container maxWidth="md">
                            <TinyTable
                                head={[
                                    { content: '' },
                                    { content: <Typography variant="body1">Cliente</Typography> },
                                    { content: <Typography variant="body1">Estado</Typography> }
                                ]}
                                body={
                                    visibleClients
                                    .sort(sortByKey('name'))
                                    .map((mappedClient) => {
                
                                        const columns = [
                                            {
                                                content:
                                                    mappedClient.logoUrl
                                                    ? <Box><img src={mappedClient.logoUrl} alt={mappedClient.name} loading="lazy" style={{ maxWidth: '100px' }} /></Box>
                                                    : '',
                                            },
                                            { content: <Typography variant="body2">{mappedClient.name}</Typography> },
                                            { content: <Typography variant="body2">{mappedClient.location}</Typography> }
                                        ];
                                        return ({
                                            data: mappedClient,
                                            columns
                                        })
                                    })
                                }
                                handleBodyRow={handleClientRowClick}
                            />
                        </Container>
                        <Pages count={availableClients.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    </Box>
                </>
            }

            <ChangeCompanyView />

            <ChangeProjectSignersWindow type="client" />

        </ViewBox>
    );
}

export default Companies;