import { useState, useCallback, useEffect, useRef } from 'react';
import queryString from 'query-string';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import FilterListIcon from '@mui/icons-material/FilterList';

import FiltersPopOver from './components/FiltersPopOver';

import ActionMenu from '../components/ActionMenu';

import AddAlertWindow from '../../../../components/AddAlertWindow';
import ChangeDocumentFolderMenu from '../../../../components/ChangeDocumentFolderMenu';
import ChangeDocumentGroupWindow from '../../../../components/ChangeDocumentGroupWindow';
import ChangePageButton from '../../../../components/ChangePageButton';
import ChangeStarredInfoPopOver from '../../../Client/Docs/components/ChangeStarredInfoPopOver';
import CommentWindow from '../../../../components/CommentWindow';
import DeleteDocDialog from '../../../../components/DeleteDocDialog';
import DocFormView from '../../../../components/DocFormView';
import DocumentListItem from '../../../../components/DocumentListItem';
import FavoriteProjects from '../../../../components/FavoriteProjects';
import FlagDocumentWindow from '../../../../components/Documents/FlagDocumentWindow';
import HistoryPopOver from '../../../../components/HistoryPopOver';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import NewStarredInfoPopOver from '../../../../components/NewStarredInfoPopOver';
import SearchEngineTextField from '../../../../components/SearchEngineTextField';
import SignDocumentWindow from '../../../../components/eSignature/SignDocumentWindow';
import UpdateNameDialog from '../../../../components/UpdateNameDialog';
import ViewBox from '../../../../components/ViewBox';
import VirtualizedAutocomplete from '../../../../components/VirtualizedAutocomplete';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useDocumentsAPI } from '../../../../context/DocumentsContext';
import { getGroupNameById, getDocumentLastCommentIfHuman, getFolderCustomDocumentFields, getGroupsByFolderId } from '../../../../utils/common';

import useGetDocuments from '../../../../hooks/useGetDocuments';

const filtersPopOverAnchorEl = signal(null);
const filtersPopOverOpen = signal(false);
const documentsListenerSettings = signal(null);
const filterClient = signal('all');
const filterOrderBy = signal('sentOn');
const filterProject = signal('all');
const filterSignatureStatus = signal('none');
const filterStatus = signal('sent');
const filterText = signal('');
const loading = signal(false);
const projectOptions = signal([]);
// const setDocumentPendingDialogOpen = signal(false);
const shouldGetProjectOptions = signal(true);
const shouldSetDocumentsListener = signal(false);
const visibleDocuments = signal([]);

const GetRecentDocumentsButton = ({onClick}) => {
    useSignals();
    return (
        <Button variant="contained" color="primary" onClick={onClick}>Recentes</Button>
    )
}

const ClientSelector = ({ onChange }) => {
    useSignals();
    const { workspaceClients } = useAppStateCtx();
    const { mappedWorkspaceClients } = useAppStateCtxUtils();

    if(workspaceClients.value){
        return (
            <Grid size={{ xs: 12, sm: 2 }}>
                <VirtualizedAutocomplete
                    label="Cliente"
                    variant="standard"
                    options={['all', ...mappedWorkspaceClients.value.map(client => client.uid)]}
                    getOptionLabel={
                        (option) => {
                            if(option === 'all') return '(Todas)';
                            const foundClient = mappedWorkspaceClients.value.find(client => client.uid === option);
                            if(foundClient){
                                return foundClient.label;
                            }
                            return option;
                        }
                    }
                    value={filterClient.value}
                    onChange={(_, newValue) => onChange(newValue)}
                />
            </Grid>
        );
    }
    return null;
}

const ProjectSelector = ({ onChange }) => {
    useSignals();
    const { mappedWorkspaceProjects } = useAppStateCtxUtils();

    const getProjectOptions = () => {
        if(filterClient.value && filterClient.value !== 'all'){
            const currentProjectOptions = mappedWorkspaceProjects.value.filter(project => project.clientId === filterClient.value);
            return projectOptions.value = currentProjectOptions;
        }
        projectOptions.value = mappedWorkspaceProjects.value;
        shouldGetProjectOptions.value = false;
    };

    useEffect(() => {
        if(shouldGetProjectOptions.value) getProjectOptions();
    }, [shouldGetProjectOptions.value]);

    return (
        <VirtualizedAutocomplete
            label="Projeto"
            variant="standard"
            options={['all', ...(projectOptions.value?.map(project => project.uid) || [])]}
            getOptionLabel={
                (option) => {
                    if(option === 'all') return '(Todas)';
                    const foundClient = projectOptions.value?.find(project => project.uid === option);
                    if(foundClient){
                        return foundClient.label;
                    }
                    return option;
                }
            }
            value={filterProject.value}
            onChange={(_, newValue) => onChange(newValue)}
            onFocus={getProjectOptions}
        />
    );
}

const PageTop = ({ handleClientChange, handleGetRecentDocumentsClick, handleProjectChange }) => {
    useSignals();
    const { workspaceProjects } = useAppStateCtx();
    const theme = useTheme();
    const screenMDDownExclusive = useMediaQuery(theme.breakpoints.down('md'));
    const filtersButtonRef = useRef(null);

    const setFavoriteFolder = (type, clientOrProjectId) => {
        if(type === 'clients'){
            handleClientChange(clientOrProjectId);
        } else {
            let favoriteProject = workspaceProjects.value[clientOrProjectId];
            handleClientChange(favoriteProject.clientId, clientOrProjectId);
        }
        shouldGetProjectOptions.value = true;
    };

    const handleFiltersButtonClick = () => {
        filtersPopOverAnchorEl.value = filtersButtonRef.current;
        filtersPopOverOpen.value = true;
    };

    const handleEndIconClick = () => {
        handleFiltersButtonClick();
    };

    const handleSearch = (newValue) => {
        filterText.value = newValue;
    };

    return (
        <>
            <FavoriteProjects handleFavoriteClick={setFavoriteFolder} />
            <Box mb={2}>
                <Grid container alignItems="flex-end" justifyContent="center" spacing={1}>
                    <Grid size={{ xs: 12, md: 2 }} style={{textAlign: 'center'}}>
                        <GetRecentDocumentsButton onClick={handleGetRecentDocumentsClick} />
                    </Grid>
                    {
                        screenMDDownExclusive
                        ?
                        <>
                            <Grid>
                                <Button
                                    ref={filtersButtonRef}
                                    variant="outlined" startIcon={<FilterListIcon />}
                                    onClick={handleFiltersButtonClick}
                                >Filtros</Button>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <SearchEngineTextField
                                    onEndIconClick={handleEndIconClick}
                                    onSearch={handleSearch}
                                />
                            </Grid>
                        </>
                        :
                        <>
                            <ClientSelector onChange={handleClientChange} />
                            <Grid size={{ xs: 12, sm: 2 }}>
                                <ProjectSelector onChange={handleProjectChange} />
                            </Grid>
                            <Grid size="grow" ref={filtersButtonRef}>
                                <SearchEngineTextField
                                    onEndIconClick={handleEndIconClick}
                                    onSearch={handleSearch}
                                />
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </>
    );
}

const numberOfDocuments = signal(0);

const LocalChangePageButton = () => {
    useSignals();

    const handleNextClick = () => {
        loading.value = true;
        documentsListenerSettings.value = {
            ...documentsListenerSettings.value,
            action: 'nextPage',
            page: documentsListenerSettings.value.page + 1,
        }
    };

    const handlePrevClick = () => {
        loading.value = true;
        documentsListenerSettings.value = {
            ...documentsListenerSettings.value,
            action: 'prevPage',
            page: documentsListenerSettings.value.page - 1
        };
    };

    return (
        <ChangePageButton numberOfRows={numberOfDocuments.value} onNextClick={handleNextClick} onPrevClick={handlePrevClick} page={documentsListenerSettings.value?.page || 1} />
    );
}

const NumberOfDocuments = () => {
    useSignals();
    if(!loading.value && !!numberOfDocuments.value){
        return (
            <Grid container justifyContent="center">
                <Grid>
                    <Typography variant="h6">({numberOfDocuments.value} documentos{filterStatus.value === 'sent' ? ' enviados' : filterStatus.value === 'scheduledSent' ? ' enviados automaticamente' : filterStatus.value === 'deleted' ? ' cancelados' : ''})</Typography>
                </Grid>
            </Grid>
        );
    }
    return null;
}

const DocumentsList = ({ handleClientChange, setChangeStarredInfoList }) => {
    useSignals();
    const theme = useTheme();
    const { workspaceClients, workspaceProjects, workspaceUsers } = useAppStateCtx();
    const { setActionMenuAnchorEl, setSelectedDocument, showFlagDocumentView } = useDocumentsAPI();
    const shouldFetchDocuments = useRef(false);

    const retrievedDocuments = useGetDocuments(shouldSetDocumentsListener.value, documentsListenerSettings.value);
    useEffect(() => {
        if(!retrievedDocuments.loading){
            visibleDocuments.value = retrievedDocuments.data;
            loading.value = false;
        }
    }, [retrievedDocuments]);

    useEffect(() => {
        if(!retrievedDocuments.loading){
            numberOfDocuments.value = retrievedDocuments.count;
        }
    }, [retrievedDocuments.loading, retrievedDocuments.count]);

    const loadPage = async (page) => {
        loading.value = true;
        // setVisibleDocuments([]);
        
        // if(filters.text) postData.text = filters.text;
        
        //     if(foundDocuments.length >= 1){
        //         foundAlerts = await Alerts.find({ deleted: { $ne: true }, documentId: { $in: foundDocuments.map(foundDocument => foundDocument.uid) } })
        //     }

        documentsListenerSettings.value = {
            clientId: filterClient.value,
            projectId: filterProject.value,
            signatureStatus: filterSignatureStatus.value,
            status: filterStatus.value,
            orderBy: filterOrderBy.value,
            page,
            text: filterText.value
            // shouldSetListener: true
        }
    };

    const handlePageClick = (page) => {
        loadPage(page);
    };

    useEffect(() => {
        if(shouldFetchDocuments.current){
            shouldSetDocumentsListener.value = new Date();
        }
    }, [filterClient.value, filterOrderBy.value, filterProject.value, filterSignatureStatus.value, filterStatus.value, filterText.value]);

    useEffect(() => {
        if(shouldFetchDocuments.current){
            handlePageClick(1);
        }
        shouldFetchDocuments.current = true;
    }, [shouldSetDocumentsListener.value, filterClient.value, filterOrderBy.value, filterProject.value, filterSignatureStatus.value, filterStatus.value, filterText.value]);

    useEffect(() => {
        if(workspaceClients.value && workspaceProjects.value){
            const windowLocationSearch = queryString.parse(window.location.search);
            const clientId = windowLocationSearch.c;
            if(clientId){
                shouldFetchDocuments.current = true;
                const projectId = windowLocationSearch.p;
                handleClientChange(clientId, projectId);
            }
        }
    }, []);

    const handle_actionMenuOpen = (e, clickedDocument) => {
        setActionMenuAnchorEl(e.target, clickedDocument);
    };
    
    const handleFlagDocumentButtonClick = (clickedDocument) => {
        showFlagDocumentView(clickedDocument);
    };

    return (
        <>
            {
                loading.value &&
                <LoaderEllipsis />
            }
            <Collapse in={!loading.value} timeout={1000}>
                <Box>
                    <LocalChangePageButton />
                    <List id="docs-list" dense>
                        {
                            visibleDocuments.value
                            .map((doc) => {
                                let useGroups = false;
                                let groupName = '';
                                if(workspaceClients.value && workspaceProjects.value){
                                    let currentProject = {...workspaceClients.value, ...workspaceProjects.value}[doc.project] || {...workspaceClients.value, ...workspaceProjects.value}[doc.client];
                                    if(currentProject && currentProject.useGroups){
                                        useGroups = true;
                                        if(useGroups){
                                            let groupId = doc.groupId;
                                            if(groupId){
                                                let folderGroups = getGroupsByFolderId(doc.projectId, {...workspaceClients.value, ...workspaceProjects.value});
                                                groupName = getGroupNameById(groupId, folderGroups);
                                            } else {
                                                groupName = 'Nenhum';
                                            }
                                        }
                                    }
                                }

                                const { comment: documentComment, noHtmlComment: documentNoHtmlComment } = getDocumentLastCommentIfHuman(doc, workspaceUsers.value);

                                let clientName = '', projectName = '', documentProject, folderCustomDocumentFields = [];
                                if(workspaceClients.value && workspaceProjects.value){
                                    if(doc.projectId){
                                        documentProject = workspaceProjects.value[doc.projectId];
                                        if(documentProject){
                                            documentProject.uid = doc.projectId;
                                            projectName = documentProject.name;
                                            const companyId = documentProject.clientId;
                                            if(companyId){
                                                const documentCompany = workspaceClients.value[companyId];
                                                if(documentCompany){
                                                    clientName = documentCompany.name;
                                                }
                                            }
                                            folderCustomDocumentFields = getFolderCustomDocumentFields(documentProject);
                                        }
                                    } else if(doc.clientId) {
                                        const documentCompany = workspaceClients.value[doc.clientId];
                                        if(documentCompany) clientName = documentCompany.name;
                                    }
                                }

                                const boxStyle = doc.flag === 2 ? {border: `2px solid ${theme.palette.secondary.main}`} : undefined;
                                return (
                                    <DocumentListItem
                                        key={doc.uid}
                                        activeUserIsManager
                                        activeUserIsOperator
                                        boxStyle={boxStyle}
                                        comment={documentComment}
                                        companyName={clientName}
                                        documentFolder={documentProject}
                                        eSignature
                                        folderCustomDocumentFields={folderCustomDocumentFields}
                                        folderName={projectName}
                                        groupName={groupName}
                                        handleActionMenuOpen={handle_actionMenuOpen}
                                        handleFlagDocumentButtonClick={handleFlagDocumentButtonClick}
                                        listDocument={doc}
                                        noHtmlComment={documentNoHtmlComment}
                                        projects={{...workspaceClients.value, ...workspaceProjects.value}}
                                        setChangeStarredInfoList={setChangeStarredInfoList}
                                        setSelectedDocument={setSelectedDocument}
                                        showCompany
                                        useGroups={useGroups}
                                    />
                                )
                            })
                        }
                    </List>
                    <LocalChangePageButton />
                </Box>
            </Collapse>
        </>
    )
};

function Sent(){
    useSignals();
    const { workspaceProjects } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const [changeStarredInfoList, set_changeStarredInfoList] = useState([]);

    useEffect(() => {
        return () => {
            clearFilters();
            documentsListenerSettings.value = null;
            shouldSetDocumentsListener.value = false;
            visibleDocuments.value = [];
            numberOfDocuments.value = 0;
        }
    }, []);

    useEffect(() => {
        setFirestoreListener('workspaceDocumentsTemplates');
    }, []);
    
    const handleClientChange = useCallback((newValue, projectId) => {
        filterClient.value = newValue || 'all';
        filterProject.value = projectId || 'all';
    }, []);

    const handleProjectChange = (newValue) => {
        filterProject.value = newValue || 'all';
    };

    const handleGetRecentDocumentsClick = useCallback(() => {
        shouldSetDocumentsListener.value = null;
        clearFilters();
    }, []);

    const clearFilters = useCallback(() => {
        filterClient.value = 'all';
        handleClientChange('all');
        filterOrderBy.value = 'sentOn';
        filterSignatureStatus.value = 'none';
        filterStatus.value = 'sent';
        filterText.value = '';
        shouldSetDocumentsListener.value = new Date();
    }, []);

    return (
        <>
            <ViewBox>
                <PageTop
                    handleClientChange={handleClientChange}
                    handleGetRecentDocumentsClick={handleGetRecentDocumentsClick}
                    handleProjectChange={handleProjectChange}
                />
                
                <NumberOfDocuments />

                <DocumentsList
                    handleClientChange={handleClientChange}
                    setChangeStarredInfoList={set_changeStarredInfoList}
                />

            </ViewBox>

            <FiltersPopOver
                clearFilters={clearFilters}
                filtersPopOverAnchorEl={filtersPopOverAnchorEl}
                filtersPopOverOpen={filtersPopOverOpen}
                filterClient={filterClient}
                filterOrderBy={filterOrderBy}
                filterProject={filterProject}
                filterSignatureStatus={filterSignatureStatus}
                filterStatus={filterStatus}
                handleClientChange={handleClientChange}
                handleProjectChange={handleProjectChange}
                loading={loading}
                projectOptions={projectOptions}
            />

            <CommentWindow />
            <DeleteDocDialog />
            <DocFormView />
            <FlagDocumentWindow activeUserIsOperator />
            <HistoryPopOver />
            <SignDocumentWindow />
            <ChangeStarredInfoPopOver list={changeStarredInfoList} data={{set_changeStarredInfoList}} />
            <NewStarredInfoPopOver list={changeStarredInfoList} />
            <ActionMenu />
            <ChangeDocumentFolderMenu projects={workspaceProjects.value} />
            <ChangeDocumentGroupWindow projects={workspaceProjects.value} />
            <UpdateNameDialog />
            <AddAlertWindow />
        </>
    );
}
export default Sent;