import { useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import Main from '../../components/Main';
import Header from './components/Header';
import Home from './Home';
import Profile from './Profile';
import ConnectedFolderUser from '../ConnectedFolderUser';
import PageNotFound from '../PageNotFound';
import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI } from '../../context/SystemContext';
import { useFolderUserCtxAPI, useFolderUserCtxSidenavMobile } from './context/ClientContext';
import DesktopSidenav from '../../components/DesktopSidenav';
import MobileSidenav from '../../components/MobileSidenav';
import { detectPresence, setOffline } from '../../firebase/realtime_database/presence';
import Account from './Account';
import useMenuItems from '../../components/FolderUserContext/hooks/useMenuItems';

function Client(){
    useSignals();
    const { activeUser, onlineUserWindowId, selectedFolder, selectedWorkspace } = useAppStateCtx();
    const { setWindowId } = useAppCtxAPI();
    const { setSidenavMobileOpen } = useFolderUserCtxAPI();
    const sidenavMobileOpen = useFolderUserCtxSidenavMobile();
    const menuItems = useMenuItems();

    useEffect(() => {
        if(activeUser.value?.uid && selectedWorkspace.value?.uid && selectedFolder.value?.uid){
            detectPresence(activeUser.value.uid, `${selectedWorkspace.value.uid}/clients/${selectedFolder.value?.uid}`, setWindowId);
        }
        return () => {
            if(onlineUserWindowId.value) setOffline(onlineUserWindowId.value, setWindowId);
        }
    }, [activeUser.value?.uid, selectedWorkspace.value?.uid, selectedFolder.value?.uid]);

    const handleCloseMobileSidenav = () => {
        setSidenavMobileOpen(false);
    };

    return (
        <Main
            header={<Header />}
            sidenav={<DesktopSidenav menuItems={menuItems} account={<Account />} />}
            mobileSidenav={<MobileSidenav account={<Account size={7} />} handleCloseMobileSidenav={handleCloseMobileSidenav} menuItems={menuItems} sidenavMobileOpen={sidenavMobileOpen} set_sidenavMobileOpen={setSidenavMobileOpen} />} 
        >
            <Route path="/" element={<Home />} />
            <Route path="/preferencias" element={<Profile />} />
            <Route path="/:folderId/*" element={<ConnectedFolderUser />} />
            <Route path="*" element={<PageNotFound />} />
        </Main>
    );
}

export default Client;