import { collection, deleteField, doc, documentId, getDoc, getDocs, query, runTransaction, updateDoc, where } from 'firebase/firestore';
import ShortUniqueId from 'short-unique-id';

import { db } from '../firebase/config';
import { WorkspaceDocumentsTemplatesList } from '../shared/types';

export default class Workspace {
    createdAt = '';
    createdBy = '';
    disabled = false;
    disabledAt = '';
    disabledBy = '';
    emails: { [key: string]: boolean; } = {}; // NOT SAVED IN SAME DOCUMENT
    favoriteProjects: string[] = []; // NOT SAVED IN SAME DOCUMENT
    logoFirebaseStoragePath = '';
    logoUrl = '';
    modules: string[] = [];
    name = '';
    projects: { [projectId: string]: { role: '' } } = {};
    role = ''; // NOT SAVED IN SAME DOCUMENT
    shortName = '';
    uid = '';

    constructor({
        createdAt,
        createdBy,
        disabled,
        disabledAt,
        disabledBy,
        favoriteProjects,
        logoFirebaseStoragePath,
        logoUrl,
        modules,
        name,
        projects,
        role,
        shortName,
        uid
    }: Workspace){
        this.createdAt = createdAt || '';
        this.createdBy = createdBy || '';
        this.disabled = disabled || false;
        this.disabledAt = disabledAt || '';
        this.disabledBy = disabledBy || '';
        this.favoriteProjects = favoriteProjects || [];
        this.logoFirebaseStoragePath = logoFirebaseStoragePath || '';
        this.logoUrl = logoUrl || '';
        this.modules = modules || [];
        this.name = name || '';
        if(projects) this.projects = projects;
        this.role = role || '';
        this.shortName = shortName || '';
        this.uid = uid || '';
    }
    
    async createDocumentsTemplatesList({ activeUserId, list, name }: {
        activeUserId: string;
        list: string[];
        name: string;
    }){
        let result = null, error = null;
        
        const workspaceDocumentsTemplatesListsRef = doc(db, `workspaces/${this.uid}/_more/template_lists`);

        // @ts-ignore
        const newListId = new ShortUniqueId().rnd();

        const newList = new WorkspaceDocumentsTemplatesList({
            createdBy: activeUserId,
            list,
            name
        });
        
        try {
            await updateDoc(workspaceDocumentsTemplatesListsRef, {
                [`templateLists.${newListId}`]: {...newList}
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async createList({ description, name }: {
        description: string;
        name: string;
    }){
        let result = null, error = null;
        
        const workspaceListsRef = doc(db, `workspaces/${this.uid}/_more/lists`);

        // @ts-ignore
        const newListId = new ShortUniqueId().rnd();
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(workspaceListsRef, {
                    [`lists.${newListId}`]: {
                        createdAt: new Date().toISOString(),
                        description,
                        name,
                        shortName: ''
                    }
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        // let result = null, error = null;
        
        // const projectRef = doc(db, `projects/${this.uid}`);
        // const workspaceProjectsRef = doc(db, `workspaces/${workspaceId}/_more/projects`);
        
        // try {
        //     await runTransaction(db, async (transaction) => {
        //         transaction.update(projectRef, updates);
        //         const workspaceProjectsUpdates: { [key: string]: any } = {};
        //         for(const updateKey in updates){
        //             workspaceProjectsUpdates[`projects.${this.uid}.${updateKey}`] = updates[updateKey];
        //         }
        //         transaction.update(workspaceProjectsRef, workspaceProjectsUpdates);
        //     });
        //     result = true;
        // } catch (e) {
        //     error = e;
        // }

        // return { result, error };
    }

    async updateDocumentsTemplatesList({ listId, updates }: {
        listId: string;
        updates: {[key: string]: any};
    }){
        let result = null, error = null;
        
        const workspaceDocumentsTemplatesListsRef = doc(db, `workspaces/${this.uid}/_more/template_lists`);

        const mappedUpdates: {[key: string]: any} = {};
        for(const updateKey in updates){
            mappedUpdates[`templateLists.${listId}.${updateKey}`] = updates[updateKey];
        }
        
        try {
            await updateDoc(workspaceDocumentsTemplatesListsRef, mappedUpdates);
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateFinanceSettings({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;
        
        const workspaceFinanceSettingsRef = doc(db, `workspaces/${this.uid}/_more/payments`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(workspaceFinanceSettingsRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}