import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, Grid } from '@mui/material';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import { useFolderUserCtxAPI, useFolderUserCtxCompanyFolders } from '../context/ClientContext';

import FoldersList from '../../../components/FoldersList';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import PromptDialog from '../../../components/PromptDialog';
import ViewBox from '../../../components/ViewBox';
import { useAppStateCtx } from '../../../context/AppState';
import { useAppCtxAPI } from '../../../context/SystemContext';
// import {
//     ERROR_MESSAGE_UNKNOWN, 
//     SUCCESS_MESSAGE_SAVED
// } from '../../../utils/constants';

const Folders = () => {
    useSignals();
    const { activeUser, selectedFolder, selectedWorkspace } = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();
    const { addCompanyFolder } = useFolderUserCtxAPI()
    const clientProjects = useFolderUserCtxCompanyFolders();
    const [isLoading, setLoadingPage] = useState(true);
    const [isNewFolderWindowOpen, setNewFolderWindowOpen] = useState(false);
    const newFolderNameRef = useRef('');
    const [floatingNotification, setFloatingNotification] = useState({});

    useEffect(() => {
        if(activeUser.value && selectedFolder.value){
            setLoadingPage(false);

            setLoading(false);
        }
    }, [activeUser.value, selectedFolder.value]);

    const handleNewFolderButtonClick = () => {
        toast(`Essa funcionalidade está temporariamente desativada. Por gentileza entre em contato com a ${selectedWorkspace.value.name} para criar novos projetos.`);
        // setNewFolderWindowOpen(true);
    };

    const handleNewFolderConfirmButtonClick = async () => {
        setLoading(true);
        
        setNewFolderWindowOpen(false);

        const newFolder = {
            name: newFolderNameRef.current,
            client: selectedFolder.value.uid,
        };

        let formData = new FormData();
        formData.append('name', newFolderNameRef.current);
        formData.append('client', selectedFolder.value.uid);

        // const clientFoldersCopy = [...clientProjects];
        addCompanyFolder(newFolder);

        //TODO /data/operator/folders/projects/save
        // setFloatingNotification({message: SUCCESS_MESSAGE_SAVED});

        setLoading(false);
    };

    return (
        <ViewBox message={floatingNotification}>
            <Box mb={3}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                        <Button
                            disabled={isLoading}
                            variant="contained" color="primary"
                            onClick={handleNewFolderButtonClick}
                            startIcon={<CreateNewFolderIcon />}
                        >Nova pasta</Button>
                    </Grid>
                </Grid>
            </Box>
            {
                isLoading
                ? <LoaderEllipsis />
                : <FoldersList foldersList={clientProjects} />
            }
            <PromptDialog
                open={isNewFolderWindowOpen} setOpen={setNewFolderWindowOpen}
                title="Nova pasta" text="Insira um nome a seguir para criar uma nova pasta" label="Nome"
                inputValueRef={newFolderNameRef}
                onClickConfirmButton={handleNewFolderConfirmButtonClick}
            />
        </ViewBox>
    );
};

export default Folders;