import { useEffect, useState } from 'react';
import { and, collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import TimesheetRecord from '../classes/TimesheetRecord';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceTimesheetRecords = (shouldSetListener) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const [hookState, setHookState] = useState({ loading: true, data: null });

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.modules?.includes('timesheet')){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'timesheet_records'),
                    and(
                        where('workspaceId', '==', selectedWorkspace.value.uid),
                        where('deleted', '==', false),
                        where('minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient', '==', false),
                        where('startAt', '>=', moment().startOf('month').toISOString()),
                        where('startAt', '<=', moment().endOf('day').toISOString()),
                    ),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const timesheetRecord = new TimesheetRecord({
                            ...data,
                            uid: snapshot.id,
                        });
                        dataArray.push(timesheetRecord)
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspaceTimesheetRecords;