import { auth } from '../config';

export default async function signOut() {
    let error = null;
    
    try {
        await auth.signOut();
    } catch (e) {
        error = e;
    }

    return { error };
}