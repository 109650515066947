import FormQuestionCurrency from '../../../../components/FormQuestionCurrency';
import { useAppContext } from '../../../../context/SystemContext';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const CurrencyQuestion = ({question, setQuestionValid}) => {
    const { formEffects } = useAppContext();
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'currency',
        setQuestionValid,
        typeSpecifics: question.typeSpecifics
    });

    const handleChange = (questionName, updates) => {
        const input = !!updates.value ? formEffects.currency.getTypeSpecificInput(updates.value, question.typeSpecifics) : '';
        setUserFormQuestion(prevState => ({
            ...prevState,
            value: updates.value,
            input,
        }));
    };
    
    return (
        <FormQuestionCurrency
            onChange={handleChange}
            initialValue={initialValue}
            question={question}
        />
    );
}

export default CurrencyQuestion;