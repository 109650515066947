import { useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import SaveIcon from '@mui/icons-material/Save';
import PopOver from './PopOver';
import TwoButtonsSwitch from './TwoButtonsSwitch';
import { useAppStateCtx } from '../context/AppState';
import { useAppCtxAPI } from '../context/SystemContext';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const AddDocumentVersionPopOver = ({addDocumentVersionWindowAnchorPosition, addDocumentVersionWindowOpen, selectedDocument, shouldShowSuccessToast}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();
    const [versionName, set_versionName] = useState('');
    const [uploadTypeUrl, set_uploadTypeUrl] = useState(false);
    const [googleUrl, set_googleUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    const handle_uploadExecutedAgreementClick = () => {
        if(!versionName){
            setErrorMessage('Insira uma descrição.');
        } else {
            if(uploadTypeUrl){
                if(!googleUrl){
                    setErrorMessage('Insira um link.');
                } else {
                    handle_saveNewVersion(false);
                }
            } else {
                triggerFileButton();
            }
        }
    };
    const triggerFileButton = () => {
        document.getElementById('docFile').click();
    };
    const handle_saveNewVersion = async (hasFile) => {
        handleClose();
        setErrorMessage('');

        const toastId = toast.loading('Salvando...', { position: 'top-center' });

        let filesList, fileUrl;
        const filesInput = document.getElementById('docFile');
        if(!hasFile){
            fileUrl = googleUrl;
        } else {
            filesList = filesInput.files;
            if(filesList.length === 0){
                toast.dismiss(toastId);
                return setErrorMessage('Escolha um arquivo.');
            }
        }

        const res = await selectedDocument.addVersion({
            createdBy: activeUser.value.uid,
            filesList,
            fileUrl,
            name: versionName
        });
        setLoading(false);
        if(res.error || !res.result){
            return toast.update(toastId, { autoClose: 5000, render: ERROR_MESSAGE_UNKNOWN, type: 'error', isLoading: false })
        }
        shouldShowSuccessToast.current = {
            message: `As informações da versão "${versionName}" foram armazenadas.`,
            toastId
        };
        if(filesInput) filesInput.value = '';
    };

    const handleClose = () => {
        addDocumentVersionWindowOpen.value = false;
    };

    return (
        <PopOver open={addDocumentVersionWindowOpen.value} onClose={handleClose} anchorPosition={addDocumentVersionWindowAnchorPosition.value}
            title="Nova versão"
        >
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    label="Descrição"
                    value={versionName}
                    onChange={(e) => set_versionName(e.target.value)} />
            </Box>
            <Box mb={2}>
                <TwoButtonsSwitch
                    boolean={uploadTypeUrl}
                    setBoolean={set_uploadTypeUrl}
                    firstButtonStartIcon={<LinkIcon />}
                    firstButtonText="Link"
                    secondButtonStartIcon={<AttachFileIcon />}
                    secondButtonText="Arquivo"
                />
            </Box>
            {
                uploadTypeUrl
                ?
                <Box>
                    <Box mb={2}>
                        <TextField
                            variant="standard"
                            fullWidth
                            label="Link"
                            value={googleUrl}
                            onChange={(e) => set_googleUrl(e.target.value)} />
                    </Box>
                    <Box mb={2} style={{textAlign: 'center'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SaveIcon />}
                            onClick={() => handle_uploadExecutedAgreementClick()}
                        >Salvar</Button>
                    </Box>
                </Box>
                :
                <Box mb={2} style={{textAlign: 'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FontAwesomeIcon icon={faFileUpload} />}
                        onClick={() => handle_uploadExecutedAgreementClick()}
                    >Escolher PDF ou DOC</Button>
                    <input style={{display: 'none'}} id="docFile" type="file" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={() => handle_saveNewVersion(true)} />
                </Box>
            }
            <Collapse in={errorMessage}>
                <FormHelperText error>{errorMessage}</FormHelperText>
            </Collapse>
        </PopOver>
    );
};

export default AddDocumentVersionPopOver;