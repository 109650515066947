import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAppStateCtx } from '../../../../context/AppState';
import { getUserNameById } from '../../../../utils/common';

const classes = {
    annotationWarningContainer: {
        position: 'absolute', top: 0, right: 0, transform: 'translate(-105%, -50%)',
    },
    annotationWarning: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        padding: '8px'
    }
};

const CustomText = ({doc, fieldId}) => {
    useSignals();
    const { activeUser, workspaceUsers } = useAppStateCtx();
    const [lastValue, set_lastValue] = useState(null);
    const [value, setValue] = useState('');
    const [showAnnotationWarning, setShowAnnotationWarning] = useState(false);

    useEffect(() => {
        const documentCustomField = getDocumentCustomFieldById();
        if(documentCustomField){
            set_lastValue(documentCustomField);
            setValue(documentCustomField.value);
        }
    }, []);

    const getDocumentCustomFieldById = () => {
        const documentCustomFields = doc.customFields || {};
        return documentCustomFields[fieldId] || null;
    };
    const handleBlur = async () => {
        setShowAnnotationWarning(false);
        const documentCustomField = getDocumentCustomFieldById();
        if((!documentCustomField && value) || (documentCustomField && documentCustomField.value !== value)){
            const newValue = {
                value,
                valueAt: moment().toISOString(),
                valueBy: activeUser.value.uid
            };
            if(documentCustomField){
                const res = doc.update({
                    updates: {
                        [`customFields.${fieldId}.value`]: newValue.value,
                        [`customFields.${fieldId}.valueAt`]: newValue.valueAt,
                        [`customFields.${fieldId}.valueBy`]: newValue.valueBy
                    }
                });
                if(res.error){
                    return toast(`Não foi possível salvar o texto "${newValue.value}". Tente novamente.`, { type: 'error' });
                }
            } else {
                const res = doc.update({
                    updates: {
                        [`customFields.${fieldId}`]: newValue
                    }
                });
                if(res.error){
                    return toast(`Não foi possível salvar o texto "${newValue.value}". Tente novamente.`, { type: 'error' });
                }
            }
            set_lastValue(newValue);
        }
    };

    return (
        <Box>
            <Box style={{position: 'relative'}}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    InputProps={{style: {fontSize: 12}}}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={handleBlur}
                    onFocus={() => setShowAnnotationWarning(true)} />
                <Box sx={classes.annotationWarningContainer}>
                    <Collapse in={showAnnotationWarning}>
                        <Box sx={classes.annotationWarning}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <HelpOutlineIcon style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                                </Grid>
                                <Grid item xs>
                                    <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.90)'}}>As anotações são para uso interno e não notificam os revisores do documento.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
            {
                (lastValue && (lastValue.valueAt || lastValue.valueBy)) &&
                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Box>
                        <EditIcon style={{fontSize: 11}} />
                    </Box>
                    <Box>
                        <Typography variant="body2" style={{fontSize: 9}}>
                            <em>
                                {lastValue.valueAt ? moment(lastValue.valueAt).format('L LT') : ''}
                                {(lastValue.valueBy && workspaceUsers.value) ? ` por ${getUserNameById(workspaceUsers.value, lastValue.valueBy)}` : ''}
                            </em>
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
};

export default CustomText;