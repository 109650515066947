import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

import JudicialCase from '../classes/JudicialCase';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceJudicialCases = (shouldSetListener, shouldGetDisabled = false) => {
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.modules?.includes('lawsuits')){
            setHookState(defaultHookState);
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'judicial_cases'),
                    where('workspaceId', '==', selectedWorkspace.value.uid),
                    where('disabled', '==', shouldGetDisabled)
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newJudicialCase = new JudicialCase({...data, uid: snapshot.id});
                        dataArray.push(newJudicialCase)
                    });
                    setHookState({
                        loading: false,
                        data: dataArray.sort((a, b) => {
                            if(shouldGetDisabled){
                                if(a.disabledAt && !b.disabledAt){
                                    return -1;
                                }
                                if(!a.disabledAt && b.disabledAt){
                                    return 1;
                                }
                                if(a.disabledAt > b.disabledAt){
                                    return -1;
                                }
                                if(a.disabledAt < b.disabledAt){
                                    return 1;
                                }
                                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
                            } else {
                                if(a.orderNumber && !b.orderNumber){
                                    return 1;
                                }
                                if(!a.orderNumber && b.orderNumber){
                                    return -1;
                                }
                                if(a.orderNumber > b.orderNumber){
                                    return 1;
                                }
                                if(a.orderNumber < b.orderNumber){
                                    return -1;
                                }
                                return a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0;
                            }
                        })
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspaceJudicialCases;