import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { db } from '../firebase/config';

const useGetClientSuppliers = (clientId) => {
    const [hookState, setHookState] = useState({ loading: true, data: null });

    useEffect(() => {
        if(clientId){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, `clients/${clientId}/suppliers`),
                    where('deleted', '==', false),
                    orderBy('name', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const dataWithId = { ...data, uid: snapshot.id };
                        dataArray.push(dataWithId);
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [clientId]);

    return hookState;
};

export default useGetClientSuppliers;