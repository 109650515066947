import { createContext, useContext, useMemo, useReducer } from 'react';

const OperatorFoldersCtxAPI = createContext();
const OperatorFoldersCtxChangeFolderView = createContext();
const OperatorFoldersCtxFolderClearanceView = createContext();
const OperatorFoldersCtxFolderSignatureView = createContext();
const OperatorFoldersCtxFolderTemplatesView = createContext();

const defaultState = {
    changeFolderViewOpen: false,
    changeFolderViewClickedFolder: null,
    changeFolderViewFoundFolder: null,
    changeFolderViewUpdatedAt: null,
    folderClearanceViewOpen: false,
    folderClearanceViewSelectedFolder: null,
    folderClearanceViewUpdatedAt: null,
    folderSignatureViewOpen: false,
    folderSignatureViewSelectedFolder: null,
    folderSignatureViewUpdatedAt: null,
    folderTemplatesViewOpen: false,
    folderTemplatesViewSelectedFolder: null,
    folderTemplatesViewUpdatedAt: null,
    folderUsers: [],
};

const reducer = (state, action) => {
    const { payload, type } = action;
    
    switch (type) {
        case 'HIDE CHANGE FOLDER VIEW':
            return { ...state, changeFolderViewOpen: false, changeFolderViewClickedFolder: null };
        case 'HIDE FOLDER CLEARANCE VIEW':
            return { ...state, folderClearanceViewOpen: false, folderClearanceViewSelectedFolder: null };
        case 'HIDE FOLDER SIGNATURE VIEW':
            return { ...state, folderSignatureViewOpen: false, folderSignatureViewSelectedFolder: null };
        case 'HIDE FOLDER TEMPLATES VIEW':
            return { ...state, folderTemplatesViewOpen: false, folderTemplatesViewSelectedFolder: null };
        case 'SET CHANGE FOLDER VIEW CLICKED FOLDER':
            return { ...state, changeFolderViewClickedFolder: payload };
        case 'SET CHANGE FOLDER VIEW FOUND FOLDER':
            return { ...state, changeFolderViewFoundFolder: payload, changeFolderViewUpdatedAt: new Date() };
        case 'SET FOLDER CLEARANCE VIEW SELECTED FOLDER':
            return { ...state, folderClearanceViewSelectedFolder: payload, folderClearanceViewUpdatedAt: new Date() };
        case 'SET FOLDER SIGNATURE VIEW SELECTED FOLDER':
            return { ...state, folderSignatureViewSelectedFolder: payload, folderSignatureViewUpdatedAt: new Date() };
        case 'SET FOLDER TEMPLATES VIEW SELECTED FOLDER':
            return { ...state, folderTemplatesViewSelectedFolder: payload, folderTemplatesViewUpdatedAt: new Date() };
        case 'SET FOLDER USERS':
            return { ...state, folderUsers: payload };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SHOW FOLDER CLEARANCE VIEW':
            return { ...state, folderClearanceViewOpen: true, folderClearanceViewSelectedFolder: payload.selectedFolder };
        case 'SHOW FOLDER SIGNATURE VIEW':
            return { ...state, folderSignatureViewOpen: true, folderSignatureViewSelectedFolder: payload.selectedFolder };
        case 'SHOW FOLDER TEMPLATES VIEW':
            return { ...state, folderTemplatesViewOpen: true, folderTemplatesViewSelectedFolder: payload.selectedFolder };
        default: return state;
    }
    
};

const OperatorFoldersProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const api = useMemo(() => {

        const addVisibleFolder = (payload) => {
            dispatch({type: 'ADD VISIBLE FOLDER', payload});
        };
        
        const hideChangeFolderView = (payload) => {
            dispatch({type: 'HIDE CHANGE FOLDER VIEW', payload});
        };

        const hideFolderClearanceView = (payload) => {
            dispatch({type: 'HIDE FOLDER CLEARANCE VIEW', payload});
        };

        const hideFolderSignatureView = (payload) => {
            dispatch({type: 'HIDE FOLDER SIGNATURE VIEW', payload});
        };

        const hideFolderTemplatesView = (payload) => {
            dispatch({type: 'HIDE FOLDER TEMPLATES VIEW', payload});
        };

        const setChangeFolderViewClickedFolder = (clickedFolder) => {
            dispatch({type: 'SET CHANGE FOLDER VIEW CLICKED FOLDER', payload: clickedFolder});
        };

        const setChangeFolderViewFoundFolder = (selectedFolder) => {
            dispatch({type: 'SET CHANGE FOLDER VIEW FOUND FOLDER', payload: selectedFolder});
        };

        const setFolderClearanceViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET FOLDER CLEARANCE VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setFolderSignatureViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET FOLDER SIGNATURE VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setFolderTemplatesViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET FOLDER TEMPLATES VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const showFolderClearanceView = (selectedFolder) => {
            dispatch({type: 'SHOW FOLDER CLEARANCE VIEW', payload: { selectedFolder }});
        };

        const showFolderSignatureView = (selectedFolder) => {
            dispatch({type: 'SHOW FOLDER SIGNATURE VIEW', payload: { selectedFolder }});
        };

        const showFolderTemplatesView = (selectedFolder) => {
            dispatch({type: 'SHOW FOLDER TEMPLATES VIEW', payload: { selectedFolder }});
        };

        const updateVisibleFolder = (payload) => {
            dispatch({type: 'UPDATE VISIBLE FOLDER', payload});
        };

        return {
            dispatch,
            addVisibleFolder,
            hideChangeFolderView,
            hideFolderClearanceView,
            hideFolderSignatureView,
            hideFolderTemplatesView,
            setChangeFolderViewClickedFolder,
            setChangeFolderViewFoundFolder,
            setFolderClearanceViewSelectedFolder,
            setFolderSignatureViewSelectedFolder,
            setFolderTemplatesViewSelectedFolder,
            setState,
            showFolderClearanceView,
            showFolderSignatureView,
            showFolderTemplatesView,
            updateVisibleFolder
        };
    }, []);

    const operatorFoldersCtxChangeFolderViewValue = useMemo(() => ({
        changeFolderViewOpen: state.changeFolderViewOpen,
        changeFolderViewClickedFolder: state.changeFolderViewClickedFolder,
        changeFolderViewFoundFolder: state.changeFolderViewFoundFolder,
        changeFolderViewUpdatedAt: state.changeFolderViewUpdatedAt
    }), [state.changeFolderViewOpen, state.changeFolderViewUpdatedAt]);

    const operatorFoldersCtxFolderClearanceViewValue = useMemo(() => ({
        folderClearanceViewOpen: state.folderClearanceViewOpen,
        folderClearanceViewSelectedFolder: state.folderClearanceViewSelectedFolder,
        folderUsers: state.folderUsers
    }), [state.folderClearanceViewOpen, state.folderClearanceViewUpdatedAt]);

    const operatorFoldersCtxFolderSignatureViewValue = useMemo(() => ({
        folderSignatureViewOpen: state.folderSignatureViewOpen,
        folderSignatureViewSelectedFolder: state.folderSignatureViewSelectedFolder,
    }), [state.folderSignatureViewOpen, state.folderSignatureViewUpdatedAt]);

    const operatorFoldersCtxFolderTemplatesViewValue = useMemo(() => ({
        folderTemplatesViewOpen: state.folderTemplatesViewOpen,
        folderTemplatesViewSelectedFolder: state.folderTemplatesViewSelectedFolder,
    }), [state.folderTemplatesViewOpen, state.folderTemplatesViewUpdatedAt]);

    return (
        <OperatorFoldersCtxAPI.Provider value={api}>
        <OperatorFoldersCtxChangeFolderView.Provider value={operatorFoldersCtxChangeFolderViewValue}>
        <OperatorFoldersCtxFolderClearanceView.Provider value={operatorFoldersCtxFolderClearanceViewValue}>
        <OperatorFoldersCtxFolderSignatureView.Provider value={operatorFoldersCtxFolderSignatureViewValue}>
        <OperatorFoldersCtxFolderTemplatesView.Provider value={operatorFoldersCtxFolderTemplatesViewValue}>
            {children}
        </OperatorFoldersCtxFolderTemplatesView.Provider>
        </OperatorFoldersCtxFolderSignatureView.Provider>
        </OperatorFoldersCtxFolderClearanceView.Provider>
        </OperatorFoldersCtxChangeFolderView.Provider>
        </OperatorFoldersCtxAPI.Provider>
    );
};

const useOperatorFoldersCtxAPI = () => useContext(OperatorFoldersCtxAPI);
const useOperatorFoldersCtxChangeFolderView = () => useContext(OperatorFoldersCtxChangeFolderView);
const useOperatorFoldersCtxFolderClearanceView = () => useContext(OperatorFoldersCtxFolderClearanceView);
const useOperatorFoldersCtxFolderSignatureView = () => useContext(OperatorFoldersCtxFolderSignatureView);
const useOperatorFoldersCtxFolderTemplatesView = () => useContext(OperatorFoldersCtxFolderTemplatesView);

export {
    OperatorFoldersProvider,
    useOperatorFoldersCtxAPI,
    useOperatorFoldersCtxChangeFolderView,
    useOperatorFoldersCtxFolderClearanceView,
    useOperatorFoldersCtxFolderSignatureView,
    useOperatorFoldersCtxFolderTemplatesView,
};