import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import MultilineText from '../../../../../../components/MultilineText';
import { useDocumentsCtxSubmitDocumentView } from '../../../../../../context/DocumentsContext';
import { getDocumentLastCommentIfHuman } from '../../../../../../utils/common';

const LastComment = () => {
    const { submitDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxSubmitDocumentView();
    const [comment, setComment] = useState({});
    const [commentVisible, setCommentVisible] = useState(false);

    useEffect(() => {
        if(selectedDocument){
            const currentComment = getDocumentLastCommentIfHuman(selectedDocument);
            setComment(currentComment);
            setCommentVisible(true);
        }
    }, [selectedDocument]);

    const handleCloseIconClick = () => {
        setCommentVisible(false);
    };

    const handleCommentTopClick = () => {
        setCommentVisible(prevState => !prevState);
    }

    if(!comment.comment) return null;

    return (
        <Box
            sx={{
                background: 'rgba(255, 255, 127, 0.9)',
                bottom: 0,
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
                left: 0,
                overflow: 'hidden',
                padding: '8px 16px',
                position: 'absolute',
                width: '100%',
                '&:hover': {
                    boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
                },
            }}
        >
            <Box
                sx={{
                    cursor: 'pointer',
                    left: 0,
                    padding: '8px 0',
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                }}
                onClick={handleCommentTopClick}
            ></Box>
            <Collapse in={commentVisible}>
                <Grid container spacing={1} alignItems="center">
                    <Grid>
                        <AnnouncementOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                    </Grid>
                    <Grid size="grow">
                        <Typography variant="body2">{comment.comment.createdByName} em {moment(comment.comment.createdAt).format('L LTS')}:</Typography>
                        <Typography variant="body2">&ldquo;<em><MultilineText text={comment.noHtmlComment} /></em>&rdquo;</Typography>
                    </Grid>
                    <Grid>
                        <IconButton size="small" onClick={handleCloseIconClick}><CloseIcon /></IconButton>
                    </Grid>
                </Grid>
            </Collapse>
        </Box>
    );
};

export default LastComment;