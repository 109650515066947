import { useSignals } from '@preact/signals-react/runtime';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const FiltersMenu = ({ filters, options, settings }) => {
    useSignals();

    const handleMenuOptionClick = (newValue) => {
        filters.value = {
            ...filters.value,
            page: 1,
            [settings.value.optionId]: newValue
        }
        handleClose();
    };

    const handleClose = () => {
        settings.value = {
            element: null,
            optionId: '',
            open: false
        }
    };

    return (
        <Menu
            id="simple-menu"
            anchorEl={settings.value.element}
            keepMounted
            open={settings.value.open}
            onClose={handleClose}
        >
            {
                settings.value.optionId &&
                options.value[settings.value.optionId].map((option, optionIndex) => (
                    <MenuItem
                        key={optionIndex}
                        onClick={() => handleMenuOptionClick(option.value)}
                        selected={option.value === filters.value[settings.value.optionId]}
                    >{option.label}</MenuItem>
                ))
            }
        </Menu>
    );
}

export default FiltersMenu;