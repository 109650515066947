import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import WorkspaceListItem from '../classes/WorkspaceListItem';
import { useAppStateCtx } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceListItems = (shouldSetListener, listId) => {
    useSignals();
    const { selectedWorkspace, workspaceLists } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && selectedWorkspace.value && workspaceLists.value){
            setHookState(prevState => ({...prevState, loading: true}));
            let foundListId = '';
            let foundListShortName = '';
            const foundList = Object.entries(workspaceLists.value).find(([workspaceListId, workspaceList]) => {
                if(workspaceListId === listId){
                    foundListId = listId;
                    foundListShortName = workspaceList.shortName;
                    return true;
                } else if(workspaceList.shortName === listId) {
                    foundListShortName = listId;
                    foundListId = workspaceListId;
                    return true;
                }
                return false;
            });
            if(foundList && foundListId){
                const unsubscribe = onSnapshot(
                    query(collection(db, `workspaces/${selectedWorkspace.value.uid}/lists_items`), where('listId', '==', foundListId)),
                    (snapshots) => {
                        const dataArray = [];
                        snapshots.forEach(snapshot => {
                            const data = snapshot.data();
                            const currentListItem = new WorkspaceListItem({ ...data, uid: snapshot.id, });
                            dataArray.push(currentListItem);
                        });
                        setHookState({
                            loading: false,
                            data: {
                                [foundListId]: dataArray,
                                [foundListShortName]: dataArray,
                            }
                        });
                    },
                    (error) => {
                        console.error(error.message);
                    }
                );
                return () => unsubscribe();
            }
        }
    }, [shouldSetListener, selectedWorkspace.value, workspaceLists.value, listId]);

    return hookState;
};

export default useGetWorkspaceListItems;