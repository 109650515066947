import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Grid, Typography } from '@mui/material';
import QuestionBox from '../QuestionBox';
import MultilineText from '../MultilineText';
import SideBarSection from '../SideBarSection';
import ReadDocumentTalentForm from '../talents/ReadDocumentTalentForm';

const List = ({checklist, documentFormArray, handleDoneCheckChange, showDone}) => (
    documentFormArray
    .filter(question => {
        if(question.input || question.choice){
            if(checklist){
                return !!question.done === showDone;
            }
            return true;
        }
        return false;
    })
    .map((question, index) => (
        <Box key={index} mb={2}>
            <QuestionBox questionValid>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <Typography variant="body1">{question.id}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Box mb={1}>
                            <Typography variant="h4">{question.question}</Typography>
                        </Box>
                        <Box>
                            <Typography variant="body1" style={{wordBreak: 'break-word'}}>
                                <MultilineText
                                    text={
                                        question.choice && question.choice !== '&p' && question.choice !== '~t;'
                                        ? question.choice
                                        : question.input
                                    }
                                />
                            </Typography>
                        </Box>
                        {
                            checklist &&
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Checkbox
                                        checked={!!question.done}
                                        onChange={(e) => handleDoneCheckChange(question, e.target.checked)}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </QuestionBox>
        </Box>
    ))
);

const ReadDocumentForm = ({documentForm, checklist, sectionTop, talentForm}) => {
    const [documentFormArray, setDocumentFormArray] = useState([]);
    const [showDone, setShowDone] = useState(false);

    useEffect(() => {
        let currentDocumentFormArray = [];
        if(documentForm){
            let currentDocumentForm = documentForm;
            try {
                currentDocumentForm = JSON.parse(currentDocumentForm);
            } catch (error) {
                currentDocumentForm = {};
            }
            let questionIndex = 1;
            for(let key in currentDocumentForm){
                const question = {id: questionIndex, question: key};
                if(currentDocumentForm[key].input) question.input = currentDocumentForm[key].input;
                if(currentDocumentForm[key].choice) question.choice = currentDocumentForm[key].choice;
                currentDocumentFormArray.push(question);
                questionIndex++;
            }
        }
        setDocumentFormArray(currentDocumentFormArray);
    }, [documentForm]);

    const handleDoneCheckChange = (updatedQuestion, newValue) => {
        setDocumentFormArray(prevState => {
            const index = prevState.findIndex(question => question.id === updatedQuestion.id);
            return [...prevState.slice(0, index), {...updatedQuestion, done: newValue}, ...prevState.slice(index + 1)];
        });
    };
    
    if(talentForm){
        return (
            <Box mb={2}>
                <ReadDocumentTalentForm documentTalentForm={talentForm || {}} />
            </Box>
        );
    }

    return (
        <Box mb={2}>
            {
                documentFormArray.length >= 1 &&
                <>
                    {
                        sectionTop &&
                        <SideBarSection title="FORMULÁRIO" showDone={showDone} setShowDone={setShowDone}><List checklist={checklist} documentFormArray={documentFormArray} handleDoneCheckChange={handleDoneCheckChange} showDone={showDone} /></SideBarSection>
                    }
                </>
            }
            {
                !sectionTop &&
                <List checklist={checklist} documentFormArray={documentFormArray} handleDoneCheckChange={handleDoneCheckChange} showDone={showDone} />
            }
        </Box>
    );
}

export default ReadDocumentForm;