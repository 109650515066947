import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PopOver from '../../../../components/PopOver';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../utils/constants';
import { useAppStateCtx } from '../../../../context/AppState';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentStarredInformationView } from '../../../../context/DocumentsContext';
import moment from 'moment';

const ChangeStarredInfoPopOver = ({list, data}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const { set_changeStarredInfoList } = data;
    const { setLoading } = useAppCtxAPI();
    const { hideChangeDocumentStarredInformationView } = useDocumentsAPI();
    const { changeDocumentStarredInformationViewAnchorPosition, changeDocumentStarredInformationViewOpen, changeDocumentStarredInformationViewSelectedDocument: selectedDocument } = useDocumentsCtxChangeDocumentStarredInformationView();
    const [textValue, set_textValue] = useState([]);

    useEffect(() => {
        let newValue = [];
        list.forEach(item => newValue.push(item.value));
        set_textValue(newValue);
    }, [changeDocumentStarredInformationViewOpen, list]);

    const orderInfo = async (direction, item, index) => {
        let listCopy = [...list];
        let newListValue, newIndex;
        if(direction === 'up'){
            newIndex = index - 1;
        } else {
            newIndex = index + 1;
        }
        newListValue = [...listCopy.slice(0, index), ...listCopy.slice(index + 1)];
        newListValue = [...newListValue.slice(0, newIndex), item, ...newListValue.slice(newIndex)];
        set_changeStarredInfoList(newListValue);
        setLoading(true);

        //TODO
        const toastId = toast.loading(`Salvando...`);
        const res = selectedDocument.update({
            updates: {
                info: newListValue
            }
        })
        setLoading(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    }
    const handleBlur = async () => {
        let changed = false;
        let newValue = list.map((item, index) => {
            if(item.value !== textValue[index]){
                changed = true;
                let listItemNewValue;
                if(textValue[index] !== ''){
                    listItemNewValue = {...item, value: textValue[index], timestamp: moment().format(), user: activeUser.value.uid};
                } else {
                    listItemNewValue = null;
                }
                return listItemNewValue;
            } else {
                return item;
            }
        });
        if(changed){
            setLoading(true);
            newValue = newValue.filter(i => i !== null);

            //TODO
            const toastId = toast.loading(`Salvando...`);
            const res = selectedDocument.update({
                updates: {
                    info: newValue
                }
            })
            setLoading(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
            toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        }
    };
    const handleClose = () => {
        hideChangeDocumentStarredInformationView();
    };

    return (
        <PopOver open={changeDocumentStarredInformationViewOpen} onClose={handleClose} anchorPosition={changeDocumentStarredInformationViewAnchorPosition}
            transformOrigin={{vertical: 'center', horizontal: 'left'}}
            title="Alterar informação"
        >
            <Box>
                {
                    list.map((item, index) => (
                        <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                            <Box style={{flex: 1}}>
                                <Typography variant="body2"><strong>{item.key}</strong></Typography>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    multiline
                                    placeholder="Preencher"
                                    value={textValue[index]}
                                    onChange={(e) => set_textValue(prevState => [...prevState.slice(0, index), e.target.value, ...prevState.slice(index + 1)])}
                                    onBlur={handleBlur} />
                            </Box>
                            <Box>
                                {
                                    index !== 0 &&
                                    <Box>
                                        <IconButton size="small"
                                            onClick={() => orderInfo('up', item, index)}
                                        ><ArrowUpwardIcon /></IconButton>
                                    </Box>
                                }
                                {
                                    index !== list.length - 1 &&
                                    <Box>
                                        <IconButton size="small"
                                            onClick={() => orderInfo('down', item, index)}
                                        ><ArrowDownwardIcon /></IconButton>
                                    </Box>
                                }
                                
                            </Box>
                        </Box>
                    ))
                }
            </Box>
        </PopOver>
    );
};

export default ChangeStarredInfoPopOver;