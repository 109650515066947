import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CreateIcon from '@mui/icons-material/Create';

import ChangeLogoButton from '../../../../../components/projects/ChangeLogoButton';

import Client from '../../../../../classes/Client';
import Dialog from '../../../../../components/Dialog/Dialog';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import ResponsiveButton from '../../../../../components/ResponsiveButton/ResponsiveButton';
import Select from '../../../../../components/Select';
import { useOperatorCompaniesCtxAPI, useOperatorCompaniesCtxChangeCompanyView } from '../../../../../context/OperatorCompaniesContext';
import { useOperatorFoldersCtxAPI } from '../../../../../context/OperatorFoldersContext';
import { useAppStateCtx } from '../../../../../context/AppState';
import useGetClientDocumentsSettings from '../../../../../hooks/useGetClientDocumentsSettings';
import { BRAZILIAN_STATES, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';

const DataDrawer = () => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { hideChangeCompanyView, setChangeCompanyViewClickedCompany } = useOperatorCompaniesCtxAPI();
    const { changeCompanyViewOpen, changeCompanyViewClickedCompany: selectedClient } = useOperatorCompaniesCtxChangeCompanyView();
    const { showFolderSignatureView } = useOperatorFoldersCtxAPI();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const brazilianStatesSelectOptions = BRAZILIAN_STATES.map(i => ({value: i, label: i}));

    const defaultClientStateInfo = {
        address: '',
        city: '',
        fullName: '',
        googleDriveSignedDocumentsFolderId: '',
        jurisdiction: '',
        logoAlignment: 'right',
        postCode: '',
        realId: '',
        representativeEmail: '',
        representativeName: '',
        representativePosition: '',
        representativeRealId: '',
        state: '',
        type: 'PJ',
    };
    const defaultClientState = {
        info: defaultClientStateInfo,
        name: '',
    };
    const [clientState, setClientState] = useState(defaultClientState);

    const retrievedClientDocumentsSettings = useGetClientDocumentsSettings(selectedClient?.uid);
    useEffect(() => {
        if(retrievedClientDocumentsSettings.loading){
            setLoading(true);
        }
    }, [retrievedClientDocumentsSettings]);

    useEffect(() => {
        if(changeCompanyViewOpen && !selectedClient?.uid){
            setLoading(false);
        }
    }, [changeCompanyViewOpen]);
    
    useEffect(() => {
        if(!selectedClient){
            clearForm();
        } else {
            if(retrievedClientDocumentsSettings.data){
                setClientState({
                    ...defaultClientStateInfo,
                    info: {...selectedClient.info, ...retrievedClientDocumentsSettings.data},
                    name: selectedClient.name || '',
                    logoAlignment: selectedClient.logoAlignment || 'right',
                    googleDriveSignedDocumentsFolderId: selectedClient.googleDriveSignedDocumentsFolderId || ''
                });
                setLoading(false);
            }
        }
    }, [selectedClient, retrievedClientDocumentsSettings]);

    const handleChangeSignatureButtonPress = () => {
        showFolderSignatureView(selectedClient);
    };

    const setClientStateField = (field, newValue) => {
        setClientState(prevState => ({...prevState, [field]: newValue}));
    };
    
    const setClientStateInfo = (field, newValue) => {
        setClientState(prevState => ({...prevState, info: {...prevState.info, [field]: newValue}}));
    };

    const handleSubmit = async () => {
        setSaving(true);

        const toastId = toast.loading(`Salvando o cliente "${clientState.name}"...`);

        if(!selectedClient){
            const newClient = new Client({
                createdBy: activeUser.value.uid,
                name: clientState.name,
                workspaceId: selectedWorkspace.value.uid,
            });
            const res = await newClient.createClient({
                address: clientState.info.address,
                city: clientState.info.city,
                fullName: clientState.info.fullName,
                jurisdiction: clientState.info.jurisdiction,
                postCode: clientState.info.postCode,
                realId: clientState.info.realId,
                representativeEmail: clientState.info.representativeEmail,
                representativeName: clientState.info.representativeName,
                representativePosition: clientState.info.representativePosition,
                representativeRealId: clientState.info.representativeRealId,
                state: clientState.info.state,
                type: clientState.info.type
            });
            setSaving(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
            handleClose();
        } else {
            const updates = {
                name: clientState.name
            };
            const documentsSettingsUpdates = {
                ...clientState.info,
            };
            const res = await selectedClient.update({ documentsSettingsUpdates, updates, workspaceId: selectedWorkspace.value.uid });
            setSaving(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    }

    const clearForm = () => {
        setClientState(defaultClientState);
    };

    const handleSavedImageCallback = (imageUrl) => {
        let currentClient = {...selectedClient};
        currentClient.logoUrl = imageUrl;
        currentClient = new Client(currentClient);
        setChangeCompanyViewClickedCompany(currentClient);
    }

    const handleClose = () => {
        hideChangeCompanyView();
    };

    return (
        <Dialog
            buttons={[
                (selectedWorkspace.value?.modules.includes('documents') && !!selectedClient) &&
                <ResponsiveButton
                    variant="outlined" color="primary" size="small"
                    startIcon={<CreateIcon />}
                    onClick={handleChangeSignatureButtonPress}
                    label="Signatários"
                />
            ]}
            maxWidth="lg"
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeCompanyViewOpen}
            saveButtonDisabled={!clientState.name}
            saving={saving}
            title={!!selectedClient ? selectedClient.name : 'Nova empresa'}
        >

            {
                loading
                ? <LoaderEllipsis />
                :
                <Container maxWidth="sm">
                    <Box>
                        {
                            selectedClient &&
                            <Box mb={3}>
                                <Box mb={1}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs></Grid>
                                        <Grid item>
                                            <ChangeLogoButton selectedClientOrProject={selectedClient} callback={handleSavedImageCallback} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        }

                        <Box mb={2}>
                            <Box mb={1}>
                                <Typography variant="h5">Dados gerais</Typography>
                            </Box>
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            variant="standard"
                                            label="Nome do(a) cliente"
                                            fullWidth
                                            required
                                            value={clientState.name}
                                            onChange={(e) => setClientStateField('name', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Select
                                            label="Tipo" value={clientState.info.type} onChange={(e) => setClientStateInfo('type', e.target.value)}
                                            options={[{value: 'PF', label: 'Pessoa física'}, {value: 'PJ', label: 'Pessoa jurídica'}]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            variant="standard"
                                            label={clientState.info.type === 'PF' ? 'Nome completo' : 'Razão social'}
                                            fullWidth
                                            value={clientState.info.fullName}
                                            onChange={(e) => setClientStateInfo('fullName', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            variant="standard"
                                            label={clientState.info.type === 'PF' ? 'CPF' : 'CNPJ'}
                                            fullWidth
                                            value={clientState.info.realId}
                                            onChange={(e) => setClientStateInfo('realId', e.target.value)} />
                                    </Grid>
                                    {
                                        clientState.info.type === 'PJ' &&
                                        <>
                                            <Grid item xs={12} sm={8} md={6}>
                                                <TextField
                                                    variant="standard"
                                                    label="Representante legal"
                                                    fullWidth
                                                    value={clientState.info.representativeName}
                                                    onChange={(e) => setClientStateInfo('representativeName', e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={2}>
                                                <TextField
                                                    variant="standard"
                                                    label="CPF"
                                                    fullWidth
                                                    value={clientState.info.representativeRealId}
                                                    onChange={(e) => setClientStateInfo('representativeRealId', e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <TextField
                                                    variant="standard"
                                                    label="Cargo"
                                                    fullWidth
                                                    value={clientState.info.representativePosition}
                                                    onChange={(e) => setClientStateInfo('representativePosition', e.target.value)} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={2}>
                                                <TextField
                                                    variant="standard"
                                                    label="E-mail"
                                                    fullWidth
                                                    value={clientState.info.representativeEmail}
                                                    onChange={(e) => setClientStateInfo('representativeEmail', e.target.value)} />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} sm={6} md={5}>
                                        <TextField
                                            variant="standard"
                                            label="Endereço"
                                            fullWidth
                                            value={clientState.info.address}
                                            onChange={(e) => setClientStateInfo('address', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <TextField
                                            variant="standard"
                                            label="Cidade"
                                            fullWidth
                                            value={clientState.info.city}
                                            onChange={(e) => setClientStateInfo('city', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={1}>
                                        <Select
                                            label="UF" value={clientState.info.state} onChange={(e) => setClientStateInfo('state', e.target.value)}
                                            options={brazilianStatesSelectOptions}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            variant="standard"
                                            label="CEP"
                                            fullWidth
                                            value={clientState.info.postCode}
                                            onChange={(e) => setClientStateInfo('postCode', e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={2}>
                                        <TextField
                                            variant="standard"
                                            label="Foro"
                                            fullWidth
                                            value={clientState.info.jurisdiction}
                                            onChange={(e) => setClientStateInfo('jurisdiction', e.target.value)} />
                                    </Grid>
                                    {
                                        selectedClient?.logoUrl &&
                                        <Grid item xs={12} sm={6} md={2}>
                                            <Select
                                                label="Posição da logo"
                                                options={[{value: 'left', label: 'Esquerda'}, {value: 'center', label: 'Centro'}, {value: 'right', label: 'Direita'}]}
                                                value={clientState.info.logoAlignment} onChange={(e) => setClientStateInfo('logoAlignment', e.target.value)}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                        </Box>
                        
                        {
                            //TODO
                            // (activeUser.value && ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)) && 
                            // <Box mb={2}>
                            //     <Box mb={1}>
                            //         <Typography variant="h5">Configurações adicionais</Typography>
                            //     </Box>
                            //     <Box mb={1}>
                            //         <Grid container spacing={1}>
                            //             <Grid item xs={12}>
                            //                 <TextField
                            //                     variant="standard"
                            //                     label="ID para transferência de arquivos para Google Drive"
                            //                     fullWidth
                            //                     value={clientState.info.googleDriveSignedDocumentsFolderId}
                            //                     onChange={(e) => setClientStateInfo('googleDriveSignedDocumentsFolderId', e.target.value)} />
                            //             </Grid>

                            //         </Grid>
                            //     </Box>
                            // </Box>
                        }
                    </Box>
                </Container>
            }

        </Dialog>
    );
}

export default DataDrawer;