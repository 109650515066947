import { useSignals } from '@preact/signals-react/runtime';

import { Editor } from '@tinymce/tinymce-react';
import '../../../../../components/TinyMCE/plugins/fonts.css';
import commandpalette from '../../../../../components/TinyMCE/plugins/commandpalette';
import solidacontextmenu from '../../../../../components/TinyMCE/plugins/solidacontextmenu';
import { useAppStateCtx } from '../../../../../context/AppState';
import { useOperatorTemplatesCtxTemplateEffects } from '../context/TemplatesContext';

function TinyMCE({data, onBlur, onEditorChange, onEffectClick, onEffectDoubleClick, value, noMenuBar, toolbar, minusHeight}){
    useSignals();
    const { setExecCommand, specialEffects } = data;
    const { selectedWorkspace } = useAppStateCtx();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    return (
        <Editor
            apiKey="ef5foskvm51flbsdtr9ctj6l6nk4m0ffwdkakmr7g4hqt2hx"
            value={value}
            // onInit={(evt, editor) => {
                
            // }}
            init={{
                // forced_root_block: false,
                font_css: 'fonts.css',
                font_formats: 'Abril Fatface=Abril Fatface;Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino; Calibri=calibri; Cambria=cambria; Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Montserrat=Montserrat;Staatliches=Staatliches;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats',
                fontsize_formats: '7pt 8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
                formats: {
                    col: {block: 'div', wrapper: true}
                },
                height: minusHeight ? `calc(100% - ${minusHeight}px)` : '100%',
                language: 'pt_BR',
                language_url: '/TinyMCE/pt_BR.js',
                menubar: noMenuBar ? '' : 'file',
                mobile: {
                    plugins: 'code commandpalette solidacontextmenu print importcss searchreplace fullscreen image link media table charmap hr pagebreak advlist lists help quickbars lineheight'
                },
                plugins: 'code commandpalette solidacontextmenu print importcss searchreplace fullscreen image link media table charmap hr pagebreak advlist lists imagetools help quickbars noneditable lineheight',
                noneditable_noneditable_class: 'mceNonEditable',
                resize: false,
                content_css: '/document-editor.css',
                contextmenu: 'contextMenuCopyEffect contextMenuPasteEffect contextMenuDeleteEffect contextMenuNewEffect | image | inserttable tableprops tablerowprops tablecellprops contextMenuInvisibleTable | contextMenuPageBreak',
                table_default_attributes: {
                    cellpadding: '8',
                    border: '1'
                },
                table_default_styles: {
                    width: '100%',
                    border: '1px solid #000'
                },
                toolbar: toolbar || `undo redo | bold italic underline | fontselect fontsizeselect | alignleft aligncenter alignright alignjustify | outdent indent | lineheight | table | numlist bullist | forecolor backcolor removeformat | image | searchreplace | fullscreen${['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) ? ' | code' : ''}`, // formatselect
                content_style: `
                    body {
                        background: #fff;
                    }
                    @media (min-width: 840px) {
                        html {
                            background: #eceef4;
                            min-height: 100%;
                            padding: 0 .5rem
                        }
                        body {
                            background-color: #fff;
                            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
                            box-sizing: border-box;
                            margin: 1rem auto 0;
                            max-width: 820px;
                            min-height: calc(100vh - 1rem);
                            padding:4rem 6rem 6rem 6rem
                        }
                    }
                `,
                icons: 'customIcons',
                setup: (editor) => {
                    if(onEffectClick) editor.on('click', onEffectClick);
                    if(onBlur) editor.on('blur', onBlur);
                    editor.on('dblclick', onEffectDoubleClick);
                    // if(onContextMenu) editor.on('contextmenu', onContextMenu);

                    commandpalette({editor, setExecCommand, specialEffects});
                    solidacontextmenu({editor, setExecCommand});
                },
                customVariables: {
                    templateEffects
                }
            }}
            onEditorChange={onEditorChange}
        />
    );
}

export default TinyMCE;