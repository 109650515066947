import { collection, deleteField, doc, documentId, getDoc, getDocs, query, runTransaction, updateDoc, where } from 'firebase/firestore';

import { db } from '../firebase/config';

type ConstructorArgs = {
    averageMinutesRequiredForDocumentReview?: number;
    bypassDocumentOperatorReview?: boolean;
    bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText?: {
        bypass: boolean;
        delay?: number;
        exceptions?: {
            [questionId: string]: {
                questionName: string;
                userInput: string;
            }
        }
    };
    checkCNPJ?: boolean;
    createDocumentAsPdf?: boolean;
    includeLogo?: boolean;
};

export default class DocumentsTemplate_MoreSettings {
    averageMinutesRequiredForDocumentReview = 15;
    bypassDocumentOperatorReview = false;
    bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: ConstructorArgs['bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText'] = {
        bypass: false
    };
    checkCNPJ = false;
    createDocumentAsPdf = false;
    includeLogo = false;

    constructor({
        averageMinutesRequiredForDocumentReview,
        bypassDocumentOperatorReview,
        bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText,
        checkCNPJ,
        createDocumentAsPdf,
        includeLogo,
    }: ConstructorArgs){
        this.averageMinutesRequiredForDocumentReview = averageMinutesRequiredForDocumentReview || 15;
        this.bypassDocumentOperatorReview = bypassDocumentOperatorReview || false;
        this.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText = bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText || {
            bypass: false
        };
        this.checkCNPJ = checkCNPJ || false;
        this.createDocumentAsPdf = createDocumentAsPdf || false;
        this.includeLogo = includeLogo || false;
    }

    async update({ updates, workspaceId }: {
        updates: {[key: string]: any};
        workspaceId: string
    }){
        // let result = null, error = null;
        
        // const projectRef = doc(db, `projects/${this.uid}`);
        // const workspaceProjectsRef = doc(db, `workspaces/${workspaceId}/_more/projects`);
        
        // try {
        //     await runTransaction(db, async (transaction) => {
        //         transaction.update(projectRef, updates);
        //         const workspaceProjectsUpdates: { [key: string]: any } = {};
        //         for(const updateKey in updates){
        //             workspaceProjectsUpdates[`projects.${this.uid}.${updateKey}`] = updates[updateKey];
        //         }
        //         transaction.update(workspaceProjectsRef, workspaceProjectsUpdates);
        //     });
        //     result = true;
        // } catch (e) {
        //     error = e;
        // }

        // return { result, error };
    }
    
}