import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PersonIcon from '@mui/icons-material/Person';
import { unstable_styleFunctionSx } from '@mui/system';
import Tooltip from '../Tooltip';
import { useDraggable } from '@dnd-kit/core';
import { useAppCtxAPI } from '../../context/SystemContext';
import { v4 as uuidv4 } from 'uuid';

const Div = styled('div')(unstable_styleFunctionSx);
const BoxButton = styled('div')(unstable_styleFunctionSx);

const classes = {
    root: {
        '&:hover .boxButton': {
            opacity: 0.9
        },
        '&:hover .styledBox': {
            opacity: 0.9
        }
    },
    boxButton: {
        opacity: 0,
        position: 'absolute',
        transition: 'opacity .2s ease-out'
    },
    styledBox: {
        border: '1px dashed #9e9e9e',
        color: '#212121',
        cursor: 'move',
        height: '80px',
        opacity: 0.5,
        padding: '4px',
        position: 'absolute',
        transition: 'opacity .2s ease-out',
        width: '130px',
    }
};

const SignaturePositionBox = ({
    box,
    boxes, setBoxes,
    getPosition,
    index,
    numberOfPages,
    signers
}) => {
    const { signer, type } = box;
    const { toast } = useAppCtxAPI();
    const ref = useRef();
    const [signerValue, set_signerValue] = useState(signer);
    const [signerColor, set_signerColor] = useState(0);
    const [signatureType, set_signatureType] = useState(type || null);
    const [signersMenuOpen, set_signersMenuOpen] = useState(false);

    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: box.id,
    });
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    useEffect(() => {
        if(box){
            set_signerValue(signer);
            set_signatureType(type);
        }
    }, [box, boxes]);
    useEffect(() => {
        getSignerColor(signerValue);
    }, [signerValue]);
    
    const colors = [
        '#bbdefb', // blue lighten-4
        '#ffcdd2', // red lighten-4
        '#c8e6c9', // green lighten-4
        '#c5cae9', // indigo lighten-4
        '#f5f5f5', // grey lighten-4
        '#d7ccc8', // brown lighten-4
        '#b2dfdb', // teal lighten-4
        '#ffe0b2' // orange lighten-4
    ];

    const getSignerColor = (signer) => {
        let signerIndex = signers.findIndex(s => s.name === signer);
        let colorsLength = colors.length;
        let remainder = signerIndex % colorsLength;
        set_signerColor(remainder);
    };
    const handleChangeSigner = (newValue) => {
        set_signerValue(newValue);
        setBoxes(prevState => {
            const clickedBoxIndex = prevState.findIndex((prevStateBox) => prevStateBox.id === box.id);
            if(clickedBoxIndex !== -1) return [...prevState.slice(0, clickedBoxIndex), {...prevState[clickedBoxIndex], signer: newValue}, ...prevState.slice(clickedBoxIndex + 1)];
            return prevState;
        });
    };
    const repeatSignature = () => {
        for (let page = 1; page <= numberOfPages; page++) {
            if(page !== box.page){
                const newPosition = getPosition({
                    action: 'repeatSignature',
                    left: box.left,
                    pageNumber: page,
                    top: box.top
                });
                const newBox = {...box, ...newPosition, id: uuidv4(), page};
                setBoxes(prevState => [...prevState, newBox]);    
            }
        }
        toast('Copiada em todas as páginas');
    };
    const deleteSignature = (e) => {
        e.stopPropagation();
        setBoxes(prevState => {
            const clickedBoxIndex = prevState.findIndex((prevStateBox) => prevStateBox.id === box.id);
            if(clickedBoxIndex !== -1) return [...prevState.slice(0, clickedBoxIndex), ...prevState.slice(clickedBoxIndex + 1)];
            return prevState;
        });
    };
    const handleChangeSignatureType = (newValue) => {
        setBoxes(prevState => [...prevState.slice(0, index), {...prevState[index], type: newValue}, ...prevState.slice(index + 1)]);
        set_signatureType(newValue);
    };

    const handleChangeSignerButtonClick = () => {
        set_signersMenuOpen(true);
    };

    const handleChangeSignerMenuClose = () => {
        set_signersMenuOpen(false);
    };

    return (
        <Div ref={setNodeRef} sx={classes.root} style={{...style, height: 80, left: `${box.left}px`, position: 'absolute', top: `${box.top}px`, width: 130, zIndex: 9999}}>
            <BoxButton className="boxButton" sx={classes.boxButton} style={{ top: -30, left: -30 }}>
                <Tooltip text={`Alterar para ${signatureType === 'signature' ? 'visto' : 'assinatura'}`}>
                    <IconButton color="primary" onClick={() => handleChangeSignatureType(signatureType === 'signature' ? 'initials' : 'signature')}><EditIcon /></IconButton>
                </Tooltip>
            </BoxButton>
            <BoxButton className="boxButton" sx={classes.boxButton} style={{ top: -30, right: -30 }}>
                <Tooltip text={`Excluir`}>
                    <IconButton color="primary" onClick={deleteSignature}><ClearIcon /></IconButton>
                </Tooltip>
            </BoxButton>
            {
                numberOfPages > 1 &&
                <BoxButton className="boxButton" sx={classes.boxButton} style={{ bottom: -30, left: -30 }}>
                    <Tooltip text={`Repetir em todas as páginas`}>
                        <IconButton color="primary" onClick={repeatSignature}><FileCopyIcon /></IconButton>
                    </Tooltip>
                </BoxButton>
            }
            <BoxButton className="boxButton" sx={classes.boxButton} style={{ bottom: -30, right: -30 }}>
                <Tooltip text={`Alterar signatário(a)`}>
                    <IconButton ref={ref} color="primary" onClick={handleChangeSignerButtonClick}><PersonIcon /></IconButton>
                </Tooltip>
            </BoxButton>
    
            <Div
                {...listeners} {...attributes}
                role="button"
                className="styledBox"
                sx={classes.styledBox}
                style={{ backgroundColor: colors[signerColor] }}
                title={signerValue}
            >
                <Box style={{ left: '50%', position: 'absolute', textAlign: 'center', top: '30%', transform: 'translate(-50%, -30%)' }}>
                    <Typography variant="h6" style={{fontSize: 13}} noWrap>{signatureType === 'signature' ? 'ASSINATURA' : 'VISTO'}</Typography>
                </Box>
                <Box style={{ bottom: 8, left: 0, position: 'absolute', textAlign: 'center', width: 130 }}>
                    <Typography variant="body1" style={{fontFamily: 'Pinyon Script', fontSize: 17}} noWrap>{signerValue}</Typography>
                </Box>
                <Box style={{ bottom: 8, left: '50%', position: 'absolute', textAlign: 'center', transform: 'translate(-50%)' }}>
                    <Typography variant="body2" style={{fontSize: 12}}>x_______________</Typography>
                </Box>
            </Div>

            <Menu
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                getContentAnchorEl={undefined}
                open={signersMenuOpen}
                onClose={handleChangeSignerMenuClose}
                onClick={handleChangeSignerMenuClose}
            >
                {
                    signers.filter(signer => signer.type !== 'observer').map((signer, index) => (
                        <MenuItem key={index} selected={signer.name === signerValue} onClick={() => handleChangeSigner(signer.name)}>{signer.name}</MenuItem>
                    ))
                }
            </Menu>
        </Div>
    );
};

export default SignaturePositionBox;