import { useSignals } from '@preact/signals-react/runtime';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Tooltip from '../Tooltip';
import { useAppStateCtx } from '../../context/AppState';

const DocumentListItemTopCreator = ({ userId }) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    const user = workspaceUsers.value[userId];
    const userName = user?.name || '(Não especificado)';
    return (
        <Tooltip text={userName} enterDelay={400}>
            <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                <Grid item style={{width: 24}}>
                    <PersonOutlineOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                </Grid>
                <Grid item style={{width: 'calc(100% - 24px)'}}>
                    <Typography variant="body2" noWrap>{userName}</Typography>
                </Grid>
            </Grid>
        </Tooltip>
    );
};

export default DocumentListItemTopCreator;