import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
// import EditIcon from '@mui/icons-material/Edit';
import { formEffectTypes } from '../../../utils/constants';
import { useDocumentEditor } from '../../../pages/Operator/Docs/Templates/DocumentEditor/context/DocumentEditorContext';

const ListOption = ({label, onClick}) => {
    return (
        <Box>
            <Divider />
            <Box
                sx={{
                    '&:hover': {
                        backgroundColor: theme => theme.palette.action.hover
                    }
                }}
                mb={0.5} mt={0.5}
            >
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid size="grow">
                        <Typography variant="body2">{label}</Typography>
                    </Grid>
                    <Grid>
                        <IconButton size="small" onClick={onClick}><DescriptionIcon /></IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const { handle_effectRadioOutputButtonClick } = useDocumentEditor();

    return (
        <Box>
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid xs>
                        <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
                    </Grid>
                    {/* <Grid>
                        <Tooltip text="Alterar o título em todas as ocorrências">
                            <IconButton
                                onClick={() => editRadioEffect(selectedEffect)} 
                            ><EditIcon /></IconButton>
                        </Tooltip>
                    </Grid> */}
                </Grid>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            {
                selectedEffect.options.map((option, index) => (
                    <ListOption
                        key={option.option}
                        label={<><strong>{`Opção ${index + 1}`}:</strong> {option.option}</>}
                        onClick={() => handle_effectRadioOutputButtonClick(selectedEffect.id, index)}
                    />
                ))
            }
            {
                selectedEffect.textOption?.show &&
                <ListOption
                    label={`Opção "Preencher"`}
                    onClick={() => handle_effectRadioOutputButtonClick(selectedEffect.id, 'textOption')}
                />
            }
        </Box>
    )
};

export default EditorEffect;