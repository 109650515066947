import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import Document from '../classes/Document';
import { useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const useGetDocument = (documentId) => {
    useSignals();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null, error: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(documentId && activeUserIsOperator.value){
            setHookState(prevState => ({...prevState, loading: true}));
            const unsubscribe = onSnapshot(doc(db, `documents/${documentId}`),
                (snapshot) => {
                    let foundDocument = null;
                    const data = snapshot.data();
                    if(data){
                        foundDocument = new Document({...data, uid: snapshot.id});
                    }
                    setHookState({
                        loading: false,
                        data: foundDocument,
                        error: null
                    });
                },
                (_error) => {
                    setHookState({
                        loading: false,
                        data: null,
                        error: ERROR_MESSAGE_UNKNOWN
                    });
                }
            );
            return () => unsubscribe();
        }
    }, [documentId, activeUserIsOperator.value]);

    return hookState;
};

export default useGetDocument;