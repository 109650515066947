import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ShortUniqueId from 'short-unique-id';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ChangeFolderInitialResponsesWindow from './ChangeFolderInitialResponsesWindow';
import Dialog from './Dialog/Dialog';
import MultipleAutocomplete from './MultipleAutocomplete';
import Select from './Select';
import ShadowedBox from './ShadowedBox';

import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { useAppCtxAPI } from '../context/SystemContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxFolderTemplatesView } from '../context/OperatorFoldersContext';
import useGetProjectDocumentsSettings from '../hooks/useGetProjectDocumentsSettings';
import useGetProjectMisc from '../hooks/useGetProjectMisc';
import { copy, getProjectTemplates, getTemplateNameById, removeDiacritics } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN, ERROR_MESSAGE_CHANGES_UNDONE, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { sortByKey } from '../utils/filters';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const projectDocumentsTemplateIdToFormLinkId = signal(null);

const FormLinkButton = ({templateId}) => {
    const { folderTemplatesViewSelectedFolder: selectedFolder } = useOperatorFoldersCtxFolderTemplatesView();
    const [creatingLink, setCreatingLink] = useState(false);

    const handleAddLinkClick = async () => {
        setCreatingLink(true);
        const res = await selectedFolder.createProjectDocumentsFormLink(templateId);
        setCreatingLink(false);
        if(res.error){
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        toast(SUCCESS_MESSAGE_SAVED, { type: 'success' });
    };

    if(creatingLink){
        return (
            <CircularProgress size={20} />
        );
    }

    if(projectDocumentsTemplateIdToFormLinkId.value){
        const formShortId = projectDocumentsTemplateIdToFormLinkId.value[templateId];
        if(formShortId){
            return (
                <IconButton size="small" href={`/formulario/${formShortId}`} target="_blank"><OpenInNewIcon /></IconButton>
            )
        }
    }
    return (
        <IconButton size="small" onClick={handleAddLinkClick}><AddLinkIcon /></IconButton>
    );
}

const changeProjectInitialResponsesWindowOpen = signal(false);
const projectInitialResponses = signal(null);

const CustomTextFieldInput = ({ customFieldValues, field, setCustomFieldValues }) => {
    const [value, setValue] = useState('');

    useEffect(() => {
        if(customFieldValues && field){
            setValue(customFieldValues[field.uid] || '')
        }
        
    }, [customFieldValues, field]);

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const handleBlur = () => {
        setCustomFieldValues(prevState => ({...prevState, [field.uid]: value}))
    }

    return (
        <TextField
            variant="standard"
            fullWidth
            multiline
            placeholder="Sua resposta"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    )
}

const ChangeProjectTemplatesWindow = () => {
    useSignals();
    const { workspaceDocumentsTemplates, workspaceProjectsCustomFields, workspaceTemplateLists } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { APP_PATH } = useAppCtxAPI();
    const { hideFolderTemplatesView } = useOperatorFoldersCtxAPI();
    const { folderTemplatesViewOpen, folderTemplatesViewSelectedFolder: selectedFolder } = useOperatorFoldersCtxFolderTemplatesView();
    const [saving, setSaving] = useState(false);
    const [selectedLists, set_selectedLists] = useState([]);
    const [includeTemplates, set_includeTemplates] = useState([]);
    const [excludeTemplates, set_excludeTemplates] = useState([]);
    const [allProjectTemplates, setAllProjectTemplates] = useState([]);
    const [excludeTemplatesOptions, set_excludeTemplatesOptions] = useState([]);
    const [userCreatedFieldsValues, setUserCreatedFieldsValues] = useState({});
    const [folderFormShortIds, setFolderFormShortIds] = useState(null);
    const [folderTemplateFields, setFolderTemplateFields] = useState([]);
    const folderInitialFormResponses = useRef([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [projectTemplates, setProjectTemplates] = useState({});
    const [projectMisc, setProjectMisc] = useState({});

    useEffect(() => {
        setFirestoreListener('workspaceTemplateLists');
        setFirestoreListener('workspaceProjectsCustomFields');
        setFirestoreListener('workspaceDocumentsTemplates');

        return () => {
            projectDocumentsTemplateIdToFormLinkId.value = null;
            projectInitialResponses.value = null;
        };
    }, []);

    const retrievedProjectDocumentsSettings = useGetProjectDocumentsSettings(true, selectedFolder?.uid)
    useEffect(() => {
        let data = retrievedProjectDocumentsSettings.data || {};
        setProjectTemplates(data.templates || null);
        projectInitialResponses.value = data.initialFormResponses || null;
        projectDocumentsTemplateIdToFormLinkId.value = data.documentsTemplateIdToFormLinkId || null;
    }, [retrievedProjectDocumentsSettings]);

    const retrievedProjectMisc = useGetProjectMisc(selectedFolder?.uid);
    useEffect(() => {
        let data = retrievedProjectMisc.data || {};
        setProjectMisc(data);
    }, [retrievedProjectMisc]);

    useEffect(() => {
        if(folderTemplatesViewOpen && selectedFolder){
            if(workspaceDocumentsTemplates.value){
                if(projectTemplates){
                    const currentSelectedLists = [];
                    if(projectTemplates.lists){
                        projectTemplates.lists.forEach(listId => {
                            const foundList = workspaceTemplateLists.value[listId];
                            if(foundList) currentSelectedLists.push({...foundList, uid: listId});
                        });
                    }
                    set_selectedLists(currentSelectedLists);
                    const currentIncludeTemplates = [];
                    if(projectTemplates.include){
                        projectTemplates.include.forEach(templateId => {
                            const foundTemplate = workspaceDocumentsTemplates.value[templateId];
                            if(foundTemplate) currentIncludeTemplates.push({...foundTemplate, uid: templateId});
                        });
                    }
                    set_includeTemplates(currentIncludeTemplates);
                    const currentExcludeTemplates = [];
                    if(projectTemplates.exclude){
                        projectTemplates.exclude.forEach(templateId => {
                            const foundTemplate = workspaceDocumentsTemplates.value[templateId];
                            if(foundTemplate) currentExcludeTemplates.push(templateId);
                        });
                    }
                    set_excludeTemplates(currentExcludeTemplates);
                }
            }
        }
    }, [folderTemplatesViewOpen, selectedFolder, workspaceDocumentsTemplates.value, workspaceTemplateLists.value, projectTemplates]);

    useEffect(() => {
        setFolderFormShortIds(selectedFolder ? (Array.isArray(selectedFolder.formShortIds) ? selectedFolder.formShortIds : []) : null);
    }, [selectedFolder]);

    useEffect(() => {
        folderInitialFormResponses.current = selectedFolder ? selectedFolder.folderInitialFormResponses || [] : [];
    }, [selectedFolder]);

    useEffect(() => {
        if(workspaceProjectsCustomFields.value && workspaceDocumentsTemplates.value && allProjectTemplates.length >= 1){
            const currentFolderTemplateFields = [];
            workspaceProjectsCustomFields.value.forEach(field => {
                if(field.template){
                    let foundFieldTemplateInFolder = false;
                    if(Array.isArray(field.template)){
                        foundFieldTemplateInFolder = allProjectTemplates.find(template => field.template.includes(template));
                    } else {
                        foundFieldTemplateInFolder = allProjectTemplates.includes(field.template);
                        field.template = [field.template];
                    }
                    if(foundFieldTemplateInFolder){
                        currentFolderTemplateFields.push({
                            ...field,
                            template: field.template.map(fieldTemplateId => getTemplateNameById(fieldTemplateId, workspaceDocumentsTemplates.value, true)).join('; ')
                        });
                    }
                }
            });
            return setFolderTemplateFields(currentFolderTemplateFields);
        }
        return setFolderTemplateFields(null);
    }, [workspaceProjectsCustomFields.value, workspaceDocumentsTemplates.value, allProjectTemplates]);

    useEffect(() => {
        if(folderTemplatesViewOpen && projectMisc){
            return setUserCreatedFieldsValues(projectMisc || {});
        }
        setUserCreatedFieldsValues({});
    }, [folderTemplatesViewOpen, projectMisc]);

    useEffect(() => {
        if(selectedFolder){
            let folderTemplatesNewValue = getProjectTemplates({
                lists: selectedLists.map(t => t.uid),
                include: includeTemplates.map(t => t.uid),
                exclude: excludeTemplates
            }, workspaceTemplateLists.value);
            setAllProjectTemplates(folderTemplatesNewValue);
        }
    }, [workspaceTemplateLists.value, selectedLists, includeTemplates, excludeTemplates]);

    useEffect(() => {
        let listItems = [];
        selectedLists.forEach(list => {
            listItems = [
                ...listItems,
                ...list.list
            ]
        });
        set_excludeTemplatesOptions(listItems);
    }, [selectedLists]);

    const handleTemplateListsChange = (newValue) => {
        set_selectedLists(newValue);
    };
    const handleIncludeTemplatesChange = (newValue) => {
        set_includeTemplates(newValue);
    };
    const handleExcludeTemplatesChange = (newValue) => {
        set_excludeTemplates(newValue);
    };

    const handleStringFieldChange = (fieldId, newValue) => {
        setUserCreatedFieldsValues(prevState => ({...prevState, [fieldId]: newValue}));
    };

    const handleFolderInitialFormResponsesWindowOpenButtonClick = (templateId) => {
        setSelectedTemplateId(templateId);
        changeProjectInitialResponsesWindowOpen.value = true;
    };

    const handleSelectedFolderInitialFormResponsesChange = (templateId, newValue) => {
        const templateIndex = folderInitialFormResponses.current.findIndex(item => item.templateId === templateId);
        if(templateIndex !== -1){
            folderInitialFormResponses.current[templateIndex].questions = newValue;
        } else {
            folderInitialFormResponses.current.push({templateId, questions: newValue});
        }
    };

    const handleSave = async () => {
        
        let newValue = {
            
        };

        setSaving(true);

        const res = await selectedFolder.updateTemplates({
            misc: userCreatedFieldsValues,
            templates: {
                lists: selectedLists.map(t => t.uid),
                include: includeTemplates.map(t => t.uid),
                exclude: excludeTemplates
            }
        });
        setSaving(false);
        if(res.error){
            return toast(ERROR_MESSAGE_CHANGES_UNDONE, { type: 'error' });
        }
        toast(SUCCESS_MESSAGE_SAVED, { autoClose: 3000, type: 'success' });

        return;
        //TODO/PRIORITY

        const currentFolderTemplates = getProjectTemplates({...selectedFolder, templates: newValue}, workspaceTemplateLists.value);
        let currentFolderFormShortIds = [...folderFormShortIds];
        currentFolderTemplates.forEach(folderTemplateId => {
            const templateIndex = currentFolderFormShortIds.findIndex(prevStateTemplate => prevStateTemplate.templateId === folderTemplateId);
            if(templateIndex !== -1){
                currentFolderFormShortIds[templateIndex].disabled = false;
            } else {
                const shortId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
                currentFolderFormShortIds.push({ templateId: folderTemplateId, shortId, disabled: false});
            }
        });
        currentFolderFormShortIds = currentFolderFormShortIds.map(formShortId => {
            if(!currentFolderTemplates.includes(formShortId.templateId)) formShortId.disabled = true;
            return formShortId;
        });
        setFolderFormShortIds(currentFolderFormShortIds);

        //TODO
        const updates = {
            // templates: newValue,
            // userCreatedFieldsValues: currentUserCreatedFieldsValues,

            folderInitialFormResponses: folderInitialFormResponses.current,
            formShortIds: currentFolderFormShortIds,
        };
        
    };

    const handleClose = () => {
        hideFolderTemplatesView();
    };

    const FolderTemplateName = ({templateId}) => {

        const handleClick = (e, linkId) => {
            e.preventDefault();
            copy(`${APP_PATH}formulario/${linkId}`, toast, 'Link aberto do formulário copiado para a área de transferência');
        };

        const templateName = getTemplateNameById(templateId, workspaceDocumentsTemplates.value, true)

        if(projectDocumentsTemplateIdToFormLinkId.value){
            const linkId = projectDocumentsTemplateIdToFormLinkId.value[templateId];
            if(linkId){
                return (
                    <Link
                        variant="body2"
                        href="#"
                        onClick={(e) => handleClick(e, linkId)}
                        underline="hover">{templateName}</Link>
                );
            }
        }
        return (
            <Typography variant="body2">{templateName}</Typography>
        );
    }
    
    return (
        <>
            <Dialog
                onClose={handleClose}
                onSaveClick={handleSave}
                open={folderTemplatesViewOpen}
                saving={saving}
                title={`${selectedFolder ? `${selectedFolder.name} | ` : ''}Matrizes`}
                TransitionComponent={Transition}
            >

                <Container maxWidth="md">
                    {
                        folderTemplatesViewOpen &&
                        <>
                            {
                                // loadingTemplates
                                // ? <LoaderEllipsis />
                                // :
                                <Box>
                                    <Box mb={2}>
                                        {
                                            workspaceDocumentsTemplates.value &&
                                            <>
                                                <Box mb={2}>
                                                    <ShadowedBox>
                                                        <List>
                                                            {
                                                                allProjectTemplates
                                                                .sort((a, b) => {
                                                                    let itemA = getTemplateNameById(a, workspaceDocumentsTemplates.value, true);
                                                                    let itemB = getTemplateNameById(b, workspaceDocumentsTemplates.value, true);
                                                                    itemA = removeDiacritics(itemA.toLowerCase());
                                                                    itemB = removeDiacritics(itemB.toLowerCase());
                                                                    return (itemA > itemB) ? 1 : ((itemB > itemA) ? -1 : 0);
                                                                })
                                                                .map(folderTemplateId => (
                                                                    <Grid key={folderTemplateId} container spacing={1} alignItems="center" wrap="nowrap">
                                                                        <Grid item xs>
                                                                            <FolderTemplateName templateId={folderTemplateId} />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <FormLinkButton templateId={folderTemplateId} />
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <IconButton size="small" onClick={() => handleFolderInitialFormResponsesWindowOpenButtonClick(folderTemplateId)}><QuestionAnswerIcon /></IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </List>
                                                    </ShadowedBox>
                                                </Box>
                                                <Box>
                                                    {
                                                        Object.keys(workspaceTemplateLists.value).length !== 0 &&
                                                        <Box mb={2}>
                                                            <MultipleAutocomplete
                                                                disabled={saving}
                                                                label="Listas"
                                                                value={selectedLists} onChange={(_, newValue) => handleTemplateListsChange(newValue)}
                                                                options={
                                                                    Object.entries(workspaceTemplateLists.value)
                                                                    .map(([listId, list]) => ({...list, uid: listId}))
                                                                    .sort(sortByKey('name'))
                                                                }
                                                                getOptionLabel={(option) => option.name}
                                                            />
                                                        </Box>
                                                    }
                                                    <Box mb={2}>
                                                        <MultipleAutocomplete
                                                            disabled={saving}
                                                            label="Incluir"
                                                            value={includeTemplates} onChange={(e, newValue) => handleIncludeTemplatesChange(newValue)}
                                                            options={
                                                                Object.entries(workspaceDocumentsTemplates.value)
                                                                .map(([templateId, template]) => ({...template, uid: templateId}))
                                                                .sort((a, b) => {
                                                                    let argumentA = getTemplateNameById(a.uid, workspaceDocumentsTemplates.value, true);
                                                                    let argumentB = getTemplateNameById(b.uid, workspaceDocumentsTemplates.value, true);
                                                                    return argumentA > argumentB ? 1 : (argumentA < argumentB ? -1 : 0);
                                                                })
                                                            }
                                                            getOptionLabel={(option) => getTemplateNameById(option.uid, workspaceDocumentsTemplates.value, true, true)}
                                                        />
                                                    </Box>
                                                    {
                                                        selectedLists.length !== 0 &&
                                                        <Box mb={2}>
                                                            <MultipleAutocomplete
                                                                disabled={saving}
                                                                label="Excluir"
                                                                value={excludeTemplates} onChange={(e, newValue) => handleExcludeTemplatesChange(newValue)}
                                                                options={excludeTemplatesOptions.sort((a, b) => {
                                                                    let argumentA = getTemplateNameById(a, workspaceDocumentsTemplates.value, true);
                                                                    let argumentB = getTemplateNameById(b, workspaceDocumentsTemplates.value, true);
                                                                    return argumentA > argumentB ? 1 : (argumentA < argumentB ? -1 : 0);
                                                                })}
                                                                getOptionLabel={(option) => getTemplateNameById(option, workspaceDocumentsTemplates.value, true, true)}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                    {
                                        folderTemplateFields &&
                                        <Box mb={2}>
                                            <Box mb={2}>
                                                <Typography variant="h4">Informações especiais</Typography>
                                            </Box>
                                            {
                                                folderTemplateFields
                                                .sort(sortByKey('name'))
                                                .map((field, fieldIndex) => {
                                                    const types = {
                                                        'string': {
                                                            name: 'Texto',
                                                            component:
                                                                <Grid container spacing={1} alignItems="flex-end">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6">{field.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} container spacing={1}>
                                                                        <Grid item xs>
                                                                            <CustomTextFieldInput
                                                                                customFieldValues={userCreatedFieldsValues}
                                                                                field={field}
                                                                                setCustomFieldValues={setUserCreatedFieldsValues}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            field.options && field.options.length >= 1 &&
                                                                            <Grid item xs={4}>
                                                                                {
                                                                                    field.options.map(option => (
                                                                                        <Box key={option}>
                                                                                            <Grid container spacing={1} alignItems="center">
                                                                                                {
                                                                                                    option === userCreatedFieldsValues[field.uid] &&
                                                                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                                                                }
                                                                                                <Grid item xs>
                                                                                                    <Link
                                                                                                        component="button"
                                                                                                        variant="body1"
                                                                                                        onClick={() => handleStringFieldChange(field.uid, option)}
                                                                                                        underline="hover">{option}</Link>
                                                                                                </Grid>
                                                                                            </Grid>
                                                                                        </Box>
                                                                                    ))
                                                                                }
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                        },
                                                        'boolean': {
                                                            name: 'Verdadeiro ou falso',
                                                            component:
                                                                <FormGroup row>
                                                                    <Grid component="label" container spacing={1} alignItems="center">
                                                                        <Grid item xs={12}>
                                                                            <Typography variant="h6">{field.name}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Switch
                                                                                checked={userCreatedFieldsValues[field.uid]}
                                                                                onChange={(e) => setUserCreatedFieldsValues(prevState => ({...prevState, [field.uid]: e.target.checked}))}
                                                                                name={'switch'}
                                                                                color={'primary'}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </FormGroup>
                                                        },
                                                        'select': {
                                                            name: 'Múltipla escolha',
                                                            component:
                                                                <Grid container spacing={1} alignItems="flex-end">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="h6">{field.name}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} container spacing={1} wrap="nowrap">
                                                                        <Grid item xs>
                                                                            <Select
                                                                                options={field.options?.map(option => ({value: option, label: option}))}
                                                                                value={userCreatedFieldsValues[field.uid] || 'select'}
                                                                                onChange={(e) => setUserCreatedFieldsValues(prevState => ({...prevState, [field.uid]: e.target.value}))}
                                                                            />
                                                                        </Grid>
                                                                        {
                                                                            userCreatedFieldsValues[field.uid] &&
                                                                            <Grid item>
                                                                                <IconButton size="small"
                                                                                    onClick={() => setUserCreatedFieldsValues(prevState => ({...prevState, [field.uid]: ''}))}
                                                                                ><ClearIcon /></IconButton>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                    };
                                                    return (
                                                        <Box key={field.uid} mb={1}>
                                                            <Box mb={1}>
                                                                <Grid container spacing={1} alignItems="flex-start">
                                                                    <Grid item>
                                                                        <Typography variant="h6">{fieldIndex + 1}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs>
                                                                        <Box mb={1}>
                                                                            {types[field.type]?.component}
                                                                        </Box>
                                                                        <Typography variant="body2"><em>{field.template}</em></Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                            <Divider />
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    }
                                </Box>
                            }
                        </>
                    }
                </Container>

            </Dialog>
            <ChangeFolderInitialResponsesWindow
                changeProjectInitialResponsesWindowOpen={changeProjectInitialResponsesWindowOpen}
                handleSelectedFolderInitialFormResponsesChange={handleSelectedFolderInitialFormResponsesChange}
                projectInitialResponses={projectInitialResponses}
                selectedFolder={selectedFolder}
                templateId={selectedTemplateId}
            />
        </>
    );
};

export default ChangeProjectTemplatesWindow;