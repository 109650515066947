import { useNavigate } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Logo from './Logo';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuBtn from './MenuBtn';
import { useAppCtxAPI, useAppContext } from '../context/SystemContext';
import { SIDENAV_WIDTH } from '../utils/constants';

const classes = {
    header: {
        textAlign: 'center',
        height: '50px',
    },
    sidenav: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: 99,
        width: [0, `${SIDENAV_WIDTH}px`],
        height: '100%',
        backgroundColor: theme => theme.palette.details,
        overflowX: 'hidden',
    },
    sidenavContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    wrapper: {
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        
        width: [0, `${SIDENAV_WIDTH}px`],

        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: 'rgba(0, 0, 0, 0.4)'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'rgba(0, 0, 0, 0.6)'
        }
    },
};

function Sidenav({children}){
    useSignals();
    const navigate = useNavigate();
    const { setLoading } = useAppCtxAPI();
    const { logout } = useAppContext();

    return (
        <Grid item sx={classes.sidenav}>
            <Box width={60} sx={classes.wrapper}>
                <Box sx={classes.header}>
                    <Logo />
                </Box>
                <Box sx={classes.sidenavContent}>
                    <Box style={{flex: 1}}>
                        {children}
                    </Box>
                    <Box>
                        <MenuBtn
                            onClick={() => logout(navigate, setLoading)}
                            label="Sair"
                            icon={<MeetingRoomIcon />}
                        />
                    </Box>
                </Box>
            </Box>
        </Grid>
    );
}

export default Sidenav;