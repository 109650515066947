import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PopOver from './PopOver';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppStateCtx } from '../context/AppState';
import { useAppCtxAPI } from '../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxAddDocumentStarredInformationView } from '../context/DocumentsContext';
import moment from 'moment';

const NewStarredInfoPopOver = ({list}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();
    const { hideAddDocumentStarredInformationView } = useDocumentsAPI();
    const { addDocumentStarredInformationViewAnchorPosition, addDocumentStarredInformationViewOpen, addDocumentStarredInformationViewSelectedDocument: selectedDocument } = useDocumentsCtxAddDocumentStarredInformationView();
    const [infoKey, set_infoKey] = useState('');
    const [infoValue, set_infoValue] = useState('');

    useEffect(() => {
        set_infoKey('');
        set_infoValue('');
    }, [addDocumentStarredInformationViewOpen]);

    const handle_submit = async () => {
        handleClose();
        setLoading(true);
        let newInfo = {key: infoKey, value: infoValue, timestamp: moment().format(), user: activeUser.value.uid};
        let infoId = 1;
        while (list.find(i => i.id === infoId)) {
            infoId++;
        }
        newInfo.id = infoId;
        let newValue = [...list, newInfo];
        const toastId = toast.loading(`Salvando...`);
        const res = selectedDocument.update({
            updates: {
                info: newValue //TODO: /documents/#-customFields ou starredFields
            }
        })
        setLoading(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };
    const handleClose = () => {
        hideAddDocumentStarredInformationView();
    };

    return (
        <PopOver open={addDocumentStarredInformationViewOpen} onClose={handleClose} anchorPosition={addDocumentStarredInformationViewAnchorPosition}
            transformOrigin={{vertical: 'center', horizontal: 'right'}}
            ellipsisTitle={selectedDocument?.name}
            title="Cadastrar informação em destaque"
            handleSubmitButtonClick={handle_submit}
            firstBoxStyle={{ padding: 8 }}
        >
            <Box>
                <Typography variant="body2">Informações em destaque ficam visíveis na linha do documento para que não seja necessário acessá-lo para lembrar de informações importantes.</Typography>
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    label="Título"
                    value={infoKey}
                    onChange={(e) => set_infoKey(e.target.value)} />
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    label="Informação"
                    value={infoValue}
                    onChange={(e) => set_infoValue(e.target.value)} />
            </Box>
        </PopOver>
    );
};

export default NewStarredInfoPopOver;