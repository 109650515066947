import { useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FilterInput from '../../../components/FilterInput';
import NoResults from '../../../components/NoResults';
import TinyTable from '../../../components/TinyTable';
import Window from '../../../components/Window';

import { useAppStateCtx } from '../../../context/AppState';
import { sortByKey } from '../../../utils/filters';

import { useForm } from '../context/FormContext';

const tableHead = [
    {content: 'Nome'},
    {content: 'Número'},
    {content: 'Endereço'},
    {content: 'Representante legal'},
    {content: 'CPF'},
    {content: 'Outras informações'}
];

const SuppliersWindow = ({suppliersListOpen, suppliersList}) => {
    useSignals();
    const {
        formAllQuestions,
        formInitialValues,
        formSelectedSupplier,
    } = useAppStateCtx();
    const {
        usedSupplierInformation,
        values
    } = useForm();
    const [filterText, setFilterText] = useState('');

    const setSupplier = (supplier) => {
        suppliersListOpen.value = false;
        formSelectedSupplier.value = supplier;
        usedSupplierInformation.current = false;
        let formClone = {...values.current};
        formAllQuestions.value.forEach(question => {
            let assignedField = question.assignedField;
            if(assignedField !== 0){
                let fieldValue = null;
                switch (assignedField) {
                    case 1:
                        fieldValue = supplier.name;
                        break;
                    case 2:
                        fieldValue = supplier.identity || supplier.taxpayerNumber;
                        break;
                    case 3:
                        fieldValue = supplier.address;
                        break;
                    case 4:
                        fieldValue = supplier.representative;
                        break;
                    case 5:
                        fieldValue = supplier.repId || supplier.representativeTaxpayerNumber;
                        break;
                    case 'email':
                        fieldValue = supplier.email;
                        break;
                    case 'supplierEmail':
                        fieldValue = supplier.email;
                        break;
                    case 'mobile':
                        fieldValue = supplier.mobile;
                        break;
                    case 'supplierMobile':
                        fieldValue = supplier.mobile;
                        break;
                }
                if(fieldValue){
                    if(!formClone[question.name]) formClone[question.name] = {};
                    if(['t', 'text', 'corporationId', 'individualId'].includes(question.type)){
                        formClone[question.name].input = fieldValue;
                    } 
                }
            } else {
                const partyTypeQuestions = [
                    'A parte contratada é pessoa física ou jurídica?',
                    'A outra parte é pessoa física ou jurídica?',
                    'A parte contratada é pessoa física ou pessoa jurídica?',
                    'A outra parte é pessoa física ou pessoa jurídica?',
                    
                    'A outra parte é pessoa física ou pessoa jurídica'
                ];
                if(partyTypeQuestions.includes(question.name)){
                    const fieldValue = supplier.representative ? 'Pessoa jurídica' : 'Pessoa física';
                    if(!formClone[question.name]) formClone[question.name] = {};
                    formClone[question.name].choice = fieldValue;
                }
            }
        });
        values.current = formClone;
        formInitialValues.value = formClone;
    };

    const handleBodyRowClick = (row) => {
        setSupplier(row.supplier);
    };

    const handleClose = () => {
        suppliersListOpen.value = false;
    };
    
    return (
        <Window
            open={suppliersListOpen.value} onClose={handleClose}
            title="Fichas cadastrais"
            subTitle="Clique em uma linha para escolher a ficha"
            modalInnerBoxSx={{ width: ['100%', '90%', '75%'] }}
        >
            {
                suppliersList.value.length === 0
                ? <NoResults text="Não há fichas cadastradas nesta empresa." />
                :
                <Box>
                    <Box mb={2}>
                        <Container maxWidth="xs">
                            <FilterInput value={filterText} setValue={setFilterText} />
                        </Container>
                    </Box>
                    <TinyTable
                        head={tableHead}
                        body={
                            suppliersList.value
                            .filter((supplier) => {
                                if(filterText){
                                    const textFields = `${supplier.name}${supplier.address}${supplier.representative}${supplier.email}${supplier.mobile}`;
                                    return textFields.toUpperCase().indexOf(filterText.toUpperCase()) !== -1;
                                }
                                return true;
                            })
                            .sort(sortByKey('name'))
                            .map((supplier) => {
                                const columns = [
                                    {content: <Typography variant="body2">{supplier.name}</Typography>},
                                    {content: <Typography variant="body2">{supplier.type ? (supplier.type === 'PF' ? 'CPF' : 'CNPJ') : (supplier.representative ? 'CNPJ' : 'CPF')} {supplier.taxpayerNumber}</Typography>},
                                    {content: <Typography variant="body2">{supplier.address}</Typography>},
                                    {content: <Typography variant="body2">{supplier.representative}</Typography>},
                                    {content: <Typography variant="body2">{supplier.representativeTaxpayerNumber}</Typography>},
                                    {
                                        content:
                                            <Box>
                                                {
                                                    supplier.email &&
                                                    <Typography variant="body2">Email {supplier.email}</Typography>
                                                }
                                                {
                                                    supplier.mobile &&
                                                    <Typography variant="body2">Celular {supplier.mobile}</Typography>
                                                }
                                            </Box>
                                    },
                                ];
                                return ({
                                    columns,
                                    supplier
                                });
                            })
                        }
                        handleBodyRow={handleBodyRowClick}
                        updatedAt={new Date()}
                    />
                </Box>
            }
        </Window>
    );
};

export default SuppliersWindow;