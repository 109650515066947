import React from 'react';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { sortByKey } from '../utils/filters';

const FoldersList = ({foldersList, handleFolderClick}) => {
    
    const handleListItemClick = (folder) => {
        if(handleFolderClick) handleFolderClick(folder);
    };

    return (
        <Paper>
            <List>
                {
                    foldersList
                    .sort(sortByKey('name'))
                    .sort((a, b) => {
                        if(a.client && !b.client){
                            return 1;
                        } else if(!a.client && b.client){
                            return -1;
                        }
                        return 0;
                    })
                    .map((folder) => (
                        <React.Fragment key={folder.uid}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleListItemClick(folder)}>
                                    <ListItemIcon>
                                        <FolderIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={folder.name} />
                                </ListItemButton>
                            </ListItem>
                            <Divider variant="fullWidth" component="li"  />
                        </React.Fragment>
                    ))
                }
            </List>
        </Paper>
    );
};

export default FoldersList;