import { Buffer } from 'buffer';
// import { Buffer as ImportedBuffer } from 'buffer';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { nanoid } from 'nanoid';

import { storage } from '../config';

// if(!window.Buffer) window.Buffer = ImportedBuffer;

export const getFileContent = async (file: File) => {
    const contentType = file.type;
    const fileArrayBuffer = await file.arrayBuffer();
    const fileBuffer = Buffer.from(fileArrayBuffer);
    return { contentType, fileBuffer };
};

type UploadFileResult = { downloadUrl: string; newFileFormat: string; storageFilePath: string; } | null;
export const uploadFile = (
    storageFilePath: string,
    fileBuffer: Buffer,
    contentType: string,
    setUploadProgress?: (newValue: number) => void
): Promise<{ error: unknown; result: UploadFileResult }> => {
    return new Promise(async (resolve) => {
        let error: unknown = null, result: UploadFileResult = null;
        
        let uploadRef = ref(storage, storageFilePath);

        try {
            await getDownloadURL(uploadRef);
            const storageFilePathArray = storageFilePath.split('/');
            const fullFileName = storageFilePathArray[storageFilePathArray.length - 1];
            const fullFileNameArray = fullFileName.split('.');
            const fileName = fullFileNameArray.slice(0, -1).join('');
            storageFilePath = `${storageFilePathArray.slice(0, -1).join('/')}/${fileName}.${nanoid()}.${fullFileNameArray[fullFileNameArray.length - 1].toLowerCase()}`;
            uploadRef = ref(storage, storageFilePath);
        } catch (e) {
            // FILE DOES NOT EXIST
        }

        const uploadTask = uploadBytesResumable(uploadRef, fileBuffer, { contentType });

        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if(setUploadProgress) setUploadProgress(progress);
            },
            (e) => {
                error = e;
                resolve({ error, result });
            },
            async () => {
                try {
                    const downloadUrl = await getDownloadURL(uploadRef);
                    const mimeTypes: { [mimeType: string]: string } = {
                        'application/pdf': 'pdf',
                        'application/msword': 'doc',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
                        'image/webp': 'webp'
                    }
                    let newFileFormat = mimeTypes[contentType] || '';
                    if(!newFileFormat){
                        const fileExtension = storageFilePath.split('.').pop()?.toLowerCase();
                        if(fileExtension) newFileFormat = fileExtension;
                    }
                    resolve({ error, result: { downloadUrl, newFileFormat, storageFilePath } });
                } catch (e) {
                    error = e;
                    resolve({ error, result });
                }
            }
        );

    });
}