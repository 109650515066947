import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { toast } from 'react-toastify';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useAppStateCtx } from '../context/AppState';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentGroupView } from '../context/DocumentsContext';

const ChangeDocumentGroupWindow = () => {
    useSignals();
    const { projectGroupSettings } = useAppStateCtx();
    const { hideChangeDocumentGroupView } = useDocumentsAPI();
    const { changeDocumentGroupViewAnchorEl, changeDocumentGroupViewOpen, changeDocumentGroupViewSelectedDocument } = useDocumentsCtxChangeDocumentGroupView();
    const [groupOptions, set_groupOptions] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');

    useEffect(() => {
        if(changeDocumentGroupViewOpen && changeDocumentGroupViewSelectedDocument && projectGroupSettings.value && projectGroupSettings.value.projectId === changeDocumentGroupViewSelectedDocument.projectId){
            if(projectGroupSettings.value.enabled){
                const projectGroups = Object.entries(projectGroupSettings.value.groups).map(([groupId, group]) => ({value: groupId, label: group.name}));
                set_groupOptions(projectGroups);
                setSelectedGroupId(changeDocumentGroupViewSelectedDocument.groupId)
            }
        }
    }, [changeDocumentGroupViewOpen, changeDocumentGroupViewSelectedDocument, projectGroupSettings.value]);

    const handle_selectedGroupChange = async (newValue) => {
        setSelectedGroupId(newValue);
        handleClose();

        const toastId = toast.loading(`Salvando...`);
        const res = changeDocumentGroupViewSelectedDocument.update({
            updates: {
                groupId: newValue
            }
        })
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        hideChangeDocumentGroupView();
    };

    return (
        <Menu
            keepMounted
            anchorEl={changeDocumentGroupViewAnchorEl}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            open={changeDocumentGroupViewOpen}
            onClose={handleClose}
        >
            {
                groupOptions
                .map((groupOption) => (
                    <MenuItem
                        key={groupOption.value}
                        onClick={() => handle_selectedGroupChange(groupOption.value)}
                        selected={groupOption.value === selectedGroupId}
                    >
                        <Typography>{groupOption.label}</Typography>
                    </MenuItem>
                ))
            }
        </Menu>
    );
};

export default ChangeDocumentGroupWindow;