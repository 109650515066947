import { collection, deleteField, doc, DocumentData, documentId, getDoc, getDocs, query, runTransaction, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';

import { db } from '../firebase/config';

export default class Timesheet {
    createdAt = '';
    createdBy = '';
    deleted? = false;
    deletedAt? = '';
    deletedBy? = '';
    description = '';
    minutes = 0;
    minutesConsumedWithDocumentsCheckedViaTimesheet? = false;
    minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient? = false;
    projectId = '';
    startAt = '';
    taskId? = '';
    uid? = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        description,
        minutes,
        minutesConsumedWithDocumentsCheckedViaTimesheet,
        minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient,
        projectId,
        startAt,
        taskId,
        uid,
        workspaceId,
    }: Timesheet | DocumentData){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.deleted = deleted || false;
        this.deletedAt = deletedAt || '';
        this.deletedBy = deletedBy || '';
        this.description = description || '';
        this.minutes = minutes || 0;
        this.minutesConsumedWithDocumentsCheckedViaTimesheet = minutesConsumedWithDocumentsCheckedViaTimesheet || false;
        this.minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient = minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient || false;
        this.projectId = projectId || '';
        this.startAt = startAt || '';
        this.taskId = taskId || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async createTimesheetRecord(){
        let result = null, error = null;
        
        const timesheetRecordRef = doc(collection(db, `timesheet_records`));

        const {uid: _, ...timesheetRecordWithoutUid} = this;
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(timesheetRecordRef, {...timesheetRecordWithoutUid});
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async deleteTimesheetRecord(activeUserId: string){
        let result = null, error = null;
        
        try {
            await this.update({ updates: { deleted: true, deletedAt: new Date().toISOString(), deletedBy: activeUserId } });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;
        
        const timesheetRecordRef = doc(db, `timesheet_records/${this.uid}`);
        
        try {
            await updateDoc(timesheetRecordRef, updates);
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}