import { useEffect, useRef, useState } from 'react';
import { collection, limit as _limit, onSnapshot, query, orderBy as _orderBy, startAfter as _startAfter, startAt as _startAt, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import ClearanceQuestion from '../classes/ClearanceQuestion';
import { db } from '../firebase/config';
import _getDocsCount from '../firebase/firestore/getDocsCount';
import { useAppStateCtx } from '../context/AppState';
import { toLowerCaseWithoutSpecialCharacters } from '../utils/common';

const useGetWorkspaceClearanceQuestions = (shouldSetListener, restrictedToProject, activeUserIsOperator, filters, page, action) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null, count: 0 };
    const [hookState, setHookState] = useState(defaultHookState);
    const firstSnapshot = useRef(null);
    const lastSnapshot = useRef(null);
    const prevFirstSnapshot = useRef(null);

    const getClearanceQuestionsCount = async (queryConstraints) => {
        const res = await _getDocsCount(`clearance_questions`, { queryConstraints });
        if(!res.error){
            setHookState(prevState => ({...prevState, count: res.result}));
        }
    };

    useEffect(() => {
        if(shouldSetListener){
            setHookState(defaultHookState);
            const {
                createdBy, folder, folderGroup, rightType, riskLevel, status, search
            } = filters;
            
            let orderBy = ['createdAt', 'desc'];
            let startAfter = null;
            let startAt = null;
            let limit = null;
            const compositeFilterConstraint = [
                where('workspaceId', '==', restrictedToProject?.workspaceId || selectedWorkspace.value.uid),
                where('deleted', '==', false)
            ];
            if(createdBy && createdBy !== 'all') compositeFilterConstraint.push(where('createdBy', '==', createdBy));
            if(status && status !== 'all'){
                if(status === 'pending'){
                    compositeFilterConstraint.push(where('awaitingOperatorReview', '==', true));
                } else if(status === 'done'){
                    compositeFilterConstraint.push(where('awaitingOperatorReview', '==', false));
                } else if(status === 'approved'){
                    compositeFilterConstraint.push(where('approved', '==', 'yes'));
                } else if(status === 'rejected'){
                    compositeFilterConstraint.push(where('approved', '==', 'no'));
                }
            }
            if(restrictedToProject){
                compositeFilterConstraint.push(where('projectId', '==', restrictedToProject.uid));
            } else {
                if(folder && folder !== 'all') compositeFilterConstraint.push(where('projectId', '==', folder));
            }
            if(folderGroup && folderGroup !== 'all') compositeFilterConstraint.push(where('groupId', '==', folderGroup));
            if(rightType && rightType !== 'all') compositeFilterConstraint.push(where('rightType', '==', rightType));
            if(riskLevel && riskLevel !== 'all') compositeFilterConstraint.push(where('riskLevel', '==', riskLevel));
            if(search) compositeFilterConstraint.push(where('keywords', 'array-contains', toLowerCaseWithoutSpecialCharacters(search)));
            
            const queryConstraints = [...compositeFilterConstraint];

            if(!activeUserIsOperator || status === 'all' || status === 'done'){
                getClearanceQuestionsCount(queryConstraints);
                limit = 25;
                if(page !== 1){
                    if(action === 'nextPage'){
                        startAfter = lastSnapshot.current;
                    } else {
                        startAt = prevFirstSnapshot.current;
                    }
                }
            }

            queryConstraints.push(_orderBy(...orderBy));

            if(startAfter) queryConstraints.push(_startAfter(startAfter));
            if(startAt) queryConstraints.push(_startAt(startAt));

            if(limit) queryConstraints.push(_limit(limit));
            
            //TODO if groupsBehavior === 'restrict', check user group
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'clearance_questions'),
                    ...queryConstraints
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newClearanceQuestion = new ClearanceQuestion({ ...data, uid: snapshot.id });
                        dataArray.push(newClearanceQuestion);
                    });
                    if(snapshots.docs){
                        prevFirstSnapshot.current = firstSnapshot.current;
                        firstSnapshot.current = snapshots.docs[0];
                        lastSnapshot.current = snapshots.docs[snapshots.docs.length - 1];
                    }
                    setHookState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: dataArray
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, filters, page]);

    return hookState;
};

export default useGetWorkspaceClearanceQuestions;