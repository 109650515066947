const onlyUnique = (value, index, self) => { 
    return self.indexOf(value) === index;
};
const sortAlphabetically = (col) => {
    return function (x, y) {
        let compareArgumentA = x[col]; let compareArgumentB = y[col]; let result = 0;
        if(compareArgumentA === compareArgumentB){ return result; }
        if(compareArgumentA < compareArgumentB ){ result = -1; return result; }
        if(compareArgumentA > compareArgumentB ){ result = 1; return result; }
    }
};
const sortByKey = (key, reverse) => {
    return (a, b) => {
        let itemA = a[key];
        if(itemA && typeof itemA === 'string') itemA = itemA.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        let itemB = b[key];
        if(itemB && typeof itemB === 'string') itemB = itemB.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (itemA > itemB) ? (reverse ? -1 : 1) : ((itemB > itemA) ? (reverse ? 1 : -1) : 0);
    };
};
const sortObjectEntriesByKey = (key, reverse) => {
    return (a, b) => {
        let itemA = a[1][key];
        if(itemA && typeof itemA === 'string') itemA = itemA.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        let itemB = b[1][key];
        if(itemB && typeof itemB === 'string') itemB = itemB.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return (itemA > itemB) ? (reverse ? -1 : 1) : ((itemB > itemA) ? (reverse ? 1 : -1) : 0);
    };
};

export {
    onlyUnique,
    sortAlphabetically,
    sortByKey,
    sortObjectEntriesByKey
};