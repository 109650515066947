import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const classes = {
    categoryItem: {
        backgroundColor: (theme: { [key: string]: any; }) => theme.palette.background.paper
    },
    listItem: {
        whiteSpace: 'normal',
    },
};

const StyledSelect = ({
    autoFocus, defaultValue, disabled, id, label, multiline, onBlur, onChange, onKeyPress, options, optionsStyle, ref, required, shrink, style, value
}: {
    autoFocus?: boolean;
    defaultValue?: string;
    disabled?: boolean;
    id?: string;
    label?: string;
    multiline?: boolean;
    onBlur?: (args: any) => void;
    onChange?: (args: any) => void;
    onKeyPress?: (args: any) => void;
    options: { [key: string]: any; }[];
    optionsStyle?: { [key: string]: any; };
    ref?: any;
    required?: boolean;
    shrink?: boolean;
    style?: { [key: string]: any; };
    value?: string;
}) => {
    return (
        <FormControl variant="standard" fullWidth={!shrink}>
        {
            label &&
            <InputLabel>{label}</InputLabel>
        }
            <Select
                variant="standard"
                autoFocus={autoFocus}
                id={id}
                ref={ref}
                style={style}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                required={required}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                disabled={disabled}>
                <MenuItem value="select" disabled><em>Escolher</em></MenuItem>
            {
                options?.map((option) => {
                    if(option.category){
                        return <ListSubheader key={`category_${option.category}`} sx={classes.categoryItem}>{option.category}</ListSubheader>;
                    } else {
                        return <MenuItem key={`option_${option.value || option.id}`} style={optionsStyle} sx={multiline ? classes.listItem : undefined} value={option.value || option.id}>{option.label}</MenuItem>;
                    }
                })
            }
            </Select>
        </FormControl>
    );
};

export default StyledSelect;