import { useEffect, useState } from 'react';
// import EmojiPicker from 'emoji-picker-react';
import { Emoji, EmojiClickData } from 'emoji-picker-react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';
import { signal, Signal } from '@preact/signals-react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Notice from '../../classes/Notice';

import LoaderEllipsis from '../LoaderEllipsis';
import MultilineText from '../MultilineText';

import { useAppStateCtx, useAppStateCtxUtils } from '../../context/AppState';

import useGetNotices from '../../hooks/useGetNotices';

import { getUserImageById, getUserNameById, updateArrayItem } from '../../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../../utils/constants';

const loading = signal(true);
const reactionsListPopoverAnchorEl: Signal<EventTarget | null> = signal(null);
const reactionsListPopoverOpen = signal(false);
const retrieveNoticesSettings: Signal<{
    clientId?: string;
    projectId?: string
}> = signal({});
const shouldRetrieveNotices = signal(false);
const visibleNotices: Signal<Notice[] | null> = signal(null);
const visibleReactionsList: Signal<Notice['reactions']> = signal({});

const ReactionIcon = ({ reactionId }: { reactionId: string; }) => {
    if(reactionId === '2764-fe0f'){
        return (
            <FavoriteIcon fontSize="small" color="secondary" />
        );
    }
    return (
        <Emoji key={reactionId} size={20} unified={reactionId} />
    );
}

const ReactionsListPopover = () => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    const handleClose = () => {
        reactionsListPopoverOpen.value = false;
    };

    return (
        <Popover
            open={reactionsListPopoverOpen.value}
            //@ts-ignore
            anchorEl={reactionsListPopoverAnchorEl.value}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{ paper: { sx: { padding: '8px', width: '200px' } } }}
        >
            {
                visibleReactionsList.value &&
                <Grid container spacing={1} alignItems="center">
                    {
                        Object.entries(visibleReactionsList.value)
                        .sort((a, b) => a[1].reactionAt > b[1].reactionAt ? 1 : a[1].reactionAt < b[1].reactionAt ? -1 : 0)
                        .map(([reactionId, reaction]) => {
                            return (
                                <Grid key={reactionId} size={{ xs: 12 }} container spacing={1} alignItems="center">
                                    <Grid>
                                        <ReactionIcon reactionId={reaction.unifiedId} />
                                    </Grid>
                                    <Grid size="grow">
                                        <Typography variant="body1">{getUserNameById(workspaceUsers.value, reactionId)}</Typography>
                                    </Grid>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            }
        </Popover>
    );
}

const NoticeListItemReactions = ({ reactions }: { reactions: Notice['reactions'] }) => {
    useSignals();

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        visibleReactionsList.value = reactions;
        reactionsListPopoverAnchorEl.value = e.target;
        reactionsListPopoverOpen.value = true;
    };

    if(reactions){
        let reactionIdToCount: { [key: string]: number } = {};
        Object.entries(reactions)
        .sort((a, b) => a[1].reactionAt > b[1].reactionAt ? 1 : a[1].reactionAt < b[1].reactionAt ? -1 : 0)
        .forEach(([_, reaction]) => {
            if(!reactionIdToCount[reaction.unifiedId]) reactionIdToCount[reaction.unifiedId] = 0;
            reactionIdToCount[reaction.unifiedId]++;
        });

        return (
            <div onClick={handleClick}>
                <Grid container spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
                    {
                        Object.entries(reactionIdToCount)
                        .map(([reactionId, count]) => (
                            <Grid container spacing={0} alignItems="center" >
                                <Grid>
                                    <ReactionIcon reactionId={reactionId} />
                                </Grid>
                                <Grid>
                                    <Typography variant="body1">{count}</Typography>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        );
    }
    return null;
}

const NoticeListItemAvatar = ({ notice }: { notice: Notice }) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    const userImage = getUserImageById(workspaceUsers.value, notice.createdBy);
    const userName = getUserNameById(workspaceUsers.value, notice.createdBy);
    return (
        <Avatar alt={userName} src={userImage}>{userName}</Avatar>
    );
}

const NoticeListItemOrigin = ({ notice }: { notice: Notice }) => {
    useSignals();
    const { workspaceUsers, selectedFolder, selectedProjectClient, selectedWorkspace } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();
    const userName = getUserNameById(workspaceUsers.value, notice.createdBy);
    let origin = selectedWorkspace.value!.name;
    if(notice.clientId){
        origin = '';
        if(notice.clientId === selectedProjectClient.value?.uid) origin = selectedProjectClient.value.name;
        if(selectedFolderIsClient.value && notice.clientId === selectedFolder.value?.uid) origin = selectedFolder.value.name;
    }
    if(notice.projectId){
        origin = '';
        if(notice.projectId === selectedFolder.value?.uid) origin = selectedFolder.value.name;
    }
    if(origin) origin = origin.toUpperCase();
    const createdAt = moment(notice.createdAt);
    return (
        <Typography variant="body2">{origin}{notice.createdBy ? `${origin ? ' | ' : ''}${userName}` : ''}{origin || userName ? ' | ' : ''}{createdAt.year() === moment().year() ? createdAt.format('D MMM') : createdAt.format('D MMM YY')}</Typography>
    );
}

const NoticeListItem = ({ notice }: { notice: Notice }) => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    
    const handleMouseOver = () => {
        setEmojiPickerOpen(true);
    }

    const handleMouseOut = () => {
        setEmojiPickerOpen(false);
    }

    const saveReaction = async (unifiedId: string) => {
        let updatedNotice = {...notice};
        if(!updatedNotice.reactions) updatedNotice.reactions = {};
        if(unifiedId){
            updatedNotice.reactions[activeUser.value!.uid] = {
                reactionAt: new Date().toISOString(),
                unifiedId: unifiedId
            }
        } else {
            delete updatedNotice.reactions[activeUser.value!.uid];
        }
        updatedNotice = new Notice(updatedNotice);
        updateArrayItem(visibleNotices, updatedNotice);
        const res = await notice.react(unifiedId, activeUser.value!.uid);
        if(res.error || !res.result){
            return toast(ERROR_MESSAGE_UNKNOWN, { autoClose: 5000, type: 'error', isLoading: false })
        }
    }

    const handleEmojiClick = (emojiData: EmojiClickData) => {
        const unifiedId = emojiData.unified;
        saveReaction(unifiedId);
    }

    return (
        <Box
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            sx={{
                position: 'relative'
            }}
            mb={2}
        >
            <Card>
                <CardContent>
                    <Box mb={1}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid>
                                <NoticeListItemAvatar notice={notice} />
                            </Grid>
                            <Grid size="grow">
                                <Typography variant="h4">{notice.subject}</Typography>
                                <NoticeListItemOrigin notice={notice} />
                            </Grid>
                            <Grid size={{ md: 12, lg: 'auto' }}>
                                {
                                    notice.workspaceId &&
                                    <img src={selectedWorkspace.value!.logoUrl} alt={selectedWorkspace.value!.shortName} style={{maxHeight: 40}} />
                                }
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box my={1}>
                        <Box mb={2}>
                            <Typography variant="body1"><MultilineText text={notice.text} /></Typography>
                        </Box>
                        <NoticeListItemReactions reactions={notice.reactions} />
                    </Box>
                    <Divider />
                    <Box mt={1}>
                        <Grid container justifyContent="center">
                            <Grid>
                                <IconButton
                                    sx={{ marginLeft: 'auto', marginRight: 'auto' }}
                                    onClick={() => saveReaction(notice.reactions && notice.reactions[activeUser.value!.uid] ? '' : '2764-fe0f')}
                                >
                                    {
                                        notice.reactions && notice.reactions[activeUser.value!.uid]
                                        ?
                                        <Tooltip title="Desfazer">
                                            <FavoriteBorderIcon />
                                        </Tooltip>
                                        :
                                        <FavoriteIcon />
                                    }
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}

const NoticesList = ({ activeUserIsOperator }: { activeUserIsOperator?: boolean; }) => {
    useSignals();
    const { selectedFolder } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();

    useEffect(() => {
        if(activeUserIsOperator){
            let settings: {
                clientId?: string;
                projectId?: string
            } = {};
            retrieveNoticesSettings.value = settings;
            shouldRetrieveNotices.value = true;
        } else if (selectedFolder.value){
            let settings: {
                clientId?: string;
                projectId?: string
            } = {};
            if(selectedFolderIsClient.value){
                settings.clientId = selectedFolder.value.uid;
            } else {
                settings.projectId = selectedFolder.value.uid;
                settings.clientId = selectedFolder.value.clientId;
            }
            retrieveNoticesSettings.value = settings;
            shouldRetrieveNotices.value = true;
        }
    }, [activeUserIsOperator, selectedFolder.value, selectedFolderIsClient.value]);

    const retrievedNotices = useGetNotices(shouldRetrieveNotices.value, retrieveNoticesSettings.value);
    useEffect(() => {
        if(retrievedNotices.data){
            visibleNotices.value = retrievedNotices.data;
        }
    }, [retrievedNotices]);

    useEffect(() => {
        if(visibleNotices.value){
            loading.value = false;
        }
    }, [visibleNotices.value]);

    if(loading.value){
        return (
            <LoaderEllipsis />
        );
    }

    if(visibleNotices.value){
        return (
            <Box>
                {
                    visibleNotices.value.map(visibleNotice => {
                        return (
                            <NoticeListItem key={visibleNotice.uid} notice={visibleNotice} />
                        )
                    })
                }
            </Box>
        )
    }

    return null;

}

const Notices = ({ activeUserIsOperator }: { activeUserIsOperator?: boolean; }) => {
    useSignals();

    const resetState = () => {
        loading.value = false;
        reactionsListPopoverAnchorEl.value = null;
        reactionsListPopoverOpen.value = false;
        retrieveNoticesSettings.value = {};
        shouldRetrieveNotices.value = false;
        visibleNotices.value = [];
    };
    
    useEffect(() => {
        loading.value = true;
        return () => {
            resetState();
        };
    }, []);

    return (
        <>
            <NoticesList activeUserIsOperator={activeUserIsOperator} />
            <ReactionsListPopover />
        </>
    );
}

export default Notices;