import { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuestionBox from '../../../../components/QuestionBox';
import ActorScheduleQuestion from './ActorScheduleQuestion';
import CheckboxQuestion from './CheckboxQuestion';
import CorporationIdQuestion from './CorporationIdQuestion';
import CurrencyQuestion from './CurrencyQuestion';
import DateQuestion from './DateQuestion';
import FileQuestion from './FileQuestion';
import IndividualIdQuestion from './IndividualIdQuestion';
import ListQuestion from './ListQuestion';
import MovablePropertyLeaseDetailsQuestion from './MovablePropertyLeaseDetailsQuestion';
import NumberQuestion from './NumberQuestion';
import PaymentScheduleQuestion from './PaymentScheduleQuestion';
import RadioQuestion from './RadioQuestion';
import RadioGridQuestion from '../../../../documents/templates/form-question-context-components/RadioGridQuestion';
import ScheduleQuestion from './ScheduleQuestion';
import SelectQuestion from './SelectQuestion';
import TextQuestion from './TextQuestion';
import { useForm } from '../../context/FormContext';

const Question = ({question}) => {
    const { questionElements } = useForm();
    const [questionValid, setQuestionValid] = useState(true);

    const acceptedComponents = {
        checkbox: CheckboxQuestion,
        corporationId: CorporationIdQuestion,
        currency: CurrencyQuestion,
        date: DateQuestion,
        file: FileQuestion,
        individualId: IndividualIdQuestion,
        list: ListQuestion,
        movablePropertyLeaseDetails: MovablePropertyLeaseDetailsQuestion,
        number: NumberQuestion,
        paymentSchedule: PaymentScheduleQuestion,
        radio: RadioQuestion,
        radioGrid: RadioGridQuestion,
        schedule: ScheduleQuestion,
        actorSchedule: ActorScheduleQuestion,
        select: SelectQuestion,
        special: ListQuestion,
        text: TextQuestion,
        t: TextQuestion,
        me: RadioQuestion,
        e: ListQuestion,
    }
    const QuestionType = acceptedComponents[question.type] ? acceptedComponents[question.type] : Box;

    return (
        <QuestionBox
            assigned-field={question.assignedField}
            description={question.description}
            invisible={question.isLogicalBranchingQuestion}
            mandatory={question.mandatory}
            name={question.name}
            questionElements={questionElements}
            questionValid={questionValid}
            style={['h', 'heading'].includes(question.type) ? {borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: '#434343'} : {}}
            type={question.type}
        >
            {
                !['h', 'heading'].includes(question.type) &&
                <Box mt={2}>
                    <QuestionType question={question} setQuestionValid={setQuestionValid} />
                    <Collapse in={!questionValid}>
                        <Box mt={2}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <ErrorOutlineIcon color="secondary" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="secondary">Esta pergunta é obrigatória</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </Box>
            }
        </QuestionBox>
    );
};

export default Question;