import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import FormQuestionCorporationId from '../../../../components/FormQuestionCorporationId';
import { useAppStateCtx } from '../../../../context/AppState';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

import { useForm } from '../../context/FormContext';

const CorporationIdQuestion = ({question, setQuestionValid}) => {
    useSignals();
    const { formFoundCNPJs, formInitialValues, formQuestionNameToCNPJ, formSelectedSupplier, formVisibleQuestions } = useAppStateCtx();
    const { usedSupplierInformation, values } = useForm();
    const [foundCNPJ, setFoundCNPJ] = useState('');
    const [foundCNPJErrorMessage, setFoundCNPJErrorMessage] = useState('');
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'corporationId',
        setQuestionValid
    });

    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            foundCNPJ,
            foundCNPJErrorMessage
        }));
    }, [foundCNPJ]);

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };

    const capitalizeFirstLetter = (phrase) => {
        const phraseWords = phrase.split(' ').map(phraseWord => {
            phraseWord = phraseWord.toLowerCase();
            if(/^(das?|de|dos?)$/.test(phraseWord)){
                return phraseWord;
            }
            return phraseWord.charAt(0).toUpperCase() + phraseWord.slice(1);
        });
        const newValue = phraseWords.join(' ');
        return newValue;
    }

    const completeFieldsFromCNPJ = (CNPJ) => {
        const companyName = CNPJ.company.name;
        CNPJ.companyName = companyName;
        const checkCorporationIdIsMEI = !!CNPJ.company.simei.optant;
        const onePerson = checkCorporationIdIsMEI || CNPJ.company.nature.id === 2135; // "Empresário (Individual)"
        if(checkCorporationIdIsMEI){
            const onePersonRegEx = /^((\d{2}\.?\d{3}\.?\d{3} )?([A-zÀ-ú .]+)|([A-zÀ-ú .]+)( \d{11})?)$/.exec(companyName);
            if(onePersonRegEx){
                CNPJ.company.members = [
                    {
                        person: {
                            name: onePersonRegEx[3] || onePersonRegEx[4],
                        },
                        role: {
                            id: 49
                        }
                    }
                ];
            }
        }
        CNPJ.isMEI = checkCorporationIdIsMEI;
        const addressStreet = capitalizeFirstLetter(CNPJ.address.street);
        const addressStreetNumber = capitalizeFirstLetter(CNPJ.address.number);
        const addressBuildingNumber = CNPJ.address.details ? capitalizeFirstLetter(CNPJ.address.details) : '';
        const addressNeighbourhood = capitalizeFirstLetter(CNPJ.address.district);
        const addressCity = capitalizeFirstLetter(CNPJ.address.city);
        const addressState = CNPJ.address.state;
        function formatPostCode(value) {
            let i = 0;
            const v = value.toString();
            return '##.###-###'.replace(/#/g, _ => v[i++]);
        }
        const addressPostCode = formatPostCode(CNPJ.address.zip);
        const fullAddress = `${addressStreet}, ${addressStreetNumber}${addressBuildingNumber ? `, ${addressBuildingNumber}` : ''}, ${addressNeighbourhood} - ${addressCity}/${addressState}, CEP ${addressPostCode}`
        CNPJ.addressStreet = addressStreet;
        CNPJ.addressStreetNumber = addressStreetNumber;
        CNPJ.addressBuildingNumber = addressBuildingNumber;
        CNPJ.addressNeighbourhood = addressNeighbourhood;
        CNPJ.addressCity = addressCity;
        CNPJ.addressState = addressState;
        CNPJ.addressPostCode = addressPostCode;
        CNPJ.fullAddress = fullAddress;
        const currentPartners = CNPJ.company.members;
        const mappedPartners = currentPartners.map(partner => capitalizeFirstLetter(partner.person.name));
        CNPJ.partnerNames = mappedPartners;
        const mappedLegalRepresentatives = currentPartners.filter(partner => partner.role.id === 49).map(partner => capitalizeFirstLetter(partner.person.name));
        CNPJ.legalRepresentativeNames = mappedLegalRepresentatives;
        
        const updates = {};
        if(formVisibleQuestions.value){
            const corporationNameQuestion = formVisibleQuestions.value.find(visibleQuestion => visibleQuestion.assignedField === 1 && visibleQuestion.corporationIdQuestion === question.name);
            if(corporationNameQuestion && (!values.current[corporationNameQuestion.name] || !values.current[corporationNameQuestion.name].input || (formSelectedSupplier.value && !usedSupplierInformation.current))){
                if(!updates[corporationNameQuestion.name]){
                    updates[corporationNameQuestion.name] = { id: corporationNameQuestion.id };
                }
                updates[corporationNameQuestion.name].input = companyName;
                updates[corporationNameQuestion.name].type = 'text';
                // updates[corporationNameQuestion.name].foundCNPJ = 'active';
                updates[corporationNameQuestion.name].retrievedFromCorporationId = true;
            }

            const corporationAddressQuestion = formVisibleQuestions.value.find(visibleQuestion => visibleQuestion.assignedField === 3 && visibleQuestion.corporationIdQuestion === question.name);
            if(corporationAddressQuestion && (!values.current[corporationAddressQuestion.name] || !values.current[corporationAddressQuestion.name].input || (formSelectedSupplier.value && !usedSupplierInformation.current))){
                if(!updates[corporationAddressQuestion.name]){
                    updates[corporationAddressQuestion.name] = { id: corporationAddressQuestion.id };
                }
                updates[corporationAddressQuestion.name].input = fullAddress;
                updates[corporationAddressQuestion.name].type = 'text';
                // updates[corporationAddressQuestion.name].foundCNPJ = 'active';
                updates[corporationAddressQuestion.name].retrievedFromCorporationId = true;
            }

            const corporationLegalRepresentativeQuestion = formVisibleQuestions.value.find(visibleQuestion => visibleQuestion.assignedField === 4 && visibleQuestion.corporationIdQuestion === question.name);
            if(corporationLegalRepresentativeQuestion && (!values.current[corporationLegalRepresentativeQuestion.name] || !values.current[corporationLegalRepresentativeQuestion.name].input || (formSelectedSupplier.value && !usedSupplierInformation.current))){
                const foundSocio = mappedLegalRepresentatives[0];
                if(foundSocio){
                    if(!updates[corporationLegalRepresentativeQuestion.name]){
                        updates[corporationLegalRepresentativeQuestion.name] = { id: corporationLegalRepresentativeQuestion.id };
                    }
                    updates[corporationLegalRepresentativeQuestion.name].input = foundSocio;
                    updates[corporationLegalRepresentativeQuestion.name].type = 'text';
                    // updates[corporationLegalRepresentativeQuestion.name].foundCNPJ = 'active';
                    updates[corporationLegalRepresentativeQuestion.name].retrievedFromCorporationId = true;
                }
            }

            if(formSelectedSupplier.value && !usedSupplierInformation.current) usedSupplierInformation.current = true;
        }
        
        if(!formFoundCNPJs.value[CNPJ.cnpj]) formFoundCNPJs.value = {...formFoundCNPJs.value, [CNPJ.cnpj]: CNPJ};
        formQuestionNameToCNPJ.value = {
            ...formQuestionNameToCNPJ.value,
            [question.name]: CNPJ
        }

        // shouldNotUpdate.current = true;
        formInitialValues.value = {
            ...formInitialValues.value,
            ...updates
        };
        
        setUserFormQuestion(userFormQuestionPrevState => ({
            ...userFormQuestionPrevState,
            foundCNPJ: 'active'
        }));

        values.current = {...values.current, ...updates};
        setFoundCNPJ('active');
    };
    
    return (
        <FormQuestionCorporationId
            completeFieldsFromCNPJ={completeFieldsFromCNPJ}
            foundCNPJ={foundCNPJ} setFoundCNPJ={setFoundCNPJ}
            foundCNPJErrorMessage={foundCNPJErrorMessage} setFoundCNPJErrorMessage={setFoundCNPJErrorMessage}
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
        />
    );
};

export default CorporationIdQuestion;