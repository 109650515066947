import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, orderBy, query, runTransaction, where } from 'firebase/firestore';
import ShortUniqueId from 'short-unique-id';

import { db } from '../firebase/config';
import callFunction from '../firebase/functions/callFunction';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';

type ProjectClearanceFile = {
    firebaseStoragePath: string;
    format: string;
    name: string;
    uploadedAt: string;
    uploadedBy: string;
    url: string;
}
const uploadClearanceFiles = async (folderPath: string, filesList: any, activeUserId: string) => {
    let newFiles: {
        [newFileId: string]: ProjectClearanceFile
    } = {};
    let newFilesArray: (ProjectClearanceFile & { uid: string })[] = [];
    for(const file of filesList){
        const { contentType, fileBuffer } = await getFileContent(file);
        const { result } = await uploadFile(
            `${folderPath}/${file.name}`,
            fileBuffer,
            contentType
        );
        if(result){
            const { downloadUrl, newFileFormat, storageFilePath } = result;
            // @ts-ignore
            const fileId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
            let newFile = {
                firebaseStoragePath: storageFilePath,
                format: newFileFormat,
                name: file.name,
                uploadedAt: new Date().toISOString(),
                uploadedBy: activeUserId,
                url: downloadUrl
            };
            newFiles[fileId] = newFile;
            newFilesArray.push({...newFile, uid: fileId});
        }
    }
    return { newFiles, newFilesArray };
};

const uploadImage = async (folderPath: string, filesList: any) => {
    let newFileUrl = '';
    for(const file of filesList){
        const { contentType, fileBuffer } = await getFileContent(file);
        const { result } = await uploadFile(
            `${folderPath}/${file.name}`,
            fileBuffer,
            contentType
        );
        if(result){
            const { downloadUrl } = result;
            newFileUrl = downloadUrl;
        }
    }
    return newFileUrl;
};

export default class Project {
    clientId = '';
    createdAt = '';
    createdBy = '';
    disabled = false;
    disabledAt = '';
    disabledBy = '';
    logoUrl = '';
    name = '';
    operatorId = '';
    uid = '';
    workspaceId = '';

    constructor({
        clientId,
        createdAt,
        createdBy,
        disabled,
        disabledAt,
        disabledBy,
        logoUrl,
        name,
        operatorId,
        uid,
        workspaceId
    }: Project){
        this.clientId = clientId || '';
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.disabled = disabled || false;
        this.disabledAt = disabledAt || '';
        this.disabledBy = disabledBy || '';
        this.logoUrl = logoUrl || '';
        this.name = name || '';
        this.operatorId = operatorId || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async createProject(){
        let result = null, error = null;

        const projectRef = doc(collection(db, `projects`));
        const workspaceProjectsRef = doc(db, `workspaces/${this.workspaceId}/_more/projects`);

        const {uid: _, ...newProject} = this;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(projectRef, newProject);
                transaction.update(workspaceProjectsRef, {
                    [`projects.${projectRef.id}`]: {
                        clientId: this.clientId,
                        disabled: this.disabled,
                        name: this.name
                    }
                });
                transaction.set(doc(db, `projects/${projectRef.id}/_more/clearance`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/documents`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/documents_forms_short_ids`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/eSignature`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/groups`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/misc`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/talent`), {});
                transaction.set(doc(db, `projects/${projectRef.id}/_more/users`), {});
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async createProjectClearanceReport({ activeUser, cutVersion, episode, importPreviousReportId, reportType, scriptTreatment }: {
        activeUser: any;
        cutVersion: number;
        episode: number;
        importPreviousReportId: string;
        reportType: string;
        scriptTreatment: number;
    }){

        // @ts-ignore
        const newReportId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();

        const res = await callFunction('createProjectClearanceReport', {
            activeUserId: activeUser.uid,
            cutVersion,
            episode,
            newReportId,
            parentReportId: importPreviousReportId,
            projectId: this.uid,
            reportType,
            scriptTreatment
        });

        return res;
    }

    async createProjectDocumentsFormLink(templateId: string){
        let result = null, error = null;

        // @ts-ignore
        const newLinkId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(doc(db, `projects/${this.uid}/_more/documents`), {
                    [`documentsTemplateIdToFormLinkId.${templateId}`]: newLinkId
                });
                transaction.set(doc(db, `projects_documents_forms_links/${newLinkId}`), {
                    disabled: false,
                    projectId: this.uid,
                    templateId
                })
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getDocumentsSettings(){
        let result = null, error = null;
        
        const documentsSettingsRef = doc(db, `projects/${this.uid}/_more/documents`);
        
        try {
            const snapshot = await getDoc(documentsSettingsRef);
            const data = snapshot.data();
            result = data;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getClearanceSettings(){
        let result = null, error = null;
        
        const projectClearanceRef = doc(db, `projects/${this.uid}/_more/clearance`);
        
        try {
            const snapshot = await getDoc(projectClearanceRef);
            const data = snapshot.data();
            result = data;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getElectronicSignatureSettings(){
        let result = null, error = null;
        
        const projectElectronicSignatureRef = doc(db, `projects/${this.uid}/_more/eSignature`);
        
        try {
            const snapshot = await getDoc(projectElectronicSignatureRef);
            const data = snapshot.data();
            result = data;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getFormsDrafts(){
        let result = null, error = null;
        
        const projectFormsDraftsRef = collection(db, `projects/${this.uid}/documents_forms_drafts`);
        
        try {
            const snapshots = await getDocs(query(projectFormsDraftsRef, where('deleted', '==', false), orderBy('createdAt', 'asc')));
            const dataArray: { [key: string]: any }[] = [];
            snapshots.forEach(snapshot => {
                const data = snapshot.data();
                dataArray.push({
                    ...data,
                    uid: snapshot.id,
                })
            });
            result = dataArray;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getGroups(){
        let result = null, error = null;
        
        const projectGroupsRef = doc(db, `projects/${this.uid}/_more/groups`);
        
        try {
            const snapshot = await getDoc(projectGroupsRef);
            const data = snapshot.data();
            result = data;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async makeClearanceReportAvailableToClient({
        reportId
    }: {
        reportId: string;
    }){
        let result = null, error = null;
        
        const projectClearanceRef = doc(db, `projects/${this.uid}/_more/clearance`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(projectClearanceRef, {
                    [`reports.${reportId}.sentAt`]: new Date().toISOString()
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ options, root, updatedDocumentsSettings, workspaceId }: {
        options: {[key: string]: any};
        root: {[key: string]: any};
        updatedDocumentsSettings: {[key: string]: any};
        workspaceId: string;
    }){
        let result = null, error = null;
        
        const projectRef = doc(db, `projects/${this.uid}`);
        const workspaceProjectsRef = doc(db, `workspaces/${workspaceId}/_more/projects`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(projectRef, root);
                const workspaceProjectsUpdates: { [key: string]: any } = {};
                for(const updateKey in root){
                    workspaceProjectsUpdates[`projects.${this.uid}.${updateKey}`] = root[updateKey];
                }
                transaction.update(workspaceProjectsRef, workspaceProjectsUpdates);
                if(typeof options?.clearance === 'boolean'){
                    transaction.set(doc(db, `projects/${this.uid}/_more/clearance`), {
                        enabled: options?.clearance
                    }, { merge: true });
                    if(options?.clearance){
                        transaction.update(doc(db, `workspaces/${this.workspaceId}/_more/clearance`), {
                            projectsIds: arrayUnion(this.uid)
                        })
                    } else {
                        transaction.update(doc(db, `workspaces/${this.workspaceId}/_more/clearance`), {
                            projectsIds: arrayRemove(this.uid)
                        })
                    }
                }
                if(options?.eSignature){
                    transaction.set(doc(db, `projects/${this.uid}/_more/eSignature`), {
                        enabled: options?.eSignature
                    }, { merge: true });
                }
                if(options?.groups){
                    transaction.set(doc(db, `projects/${this.uid}/_more/groups`), {
                        enabled: options?.groups
                    }, { merge: true });
                }
                if(options?.starredFields || options?.customFields || options?.allowClientToAddDocumentWithoutForm || updatedDocumentsSettings.googleDocDefaultPermission){
                    let documentsSettingsUpdates: { [key: string]: any } = {};
                    if(options?.starredFields) documentsSettingsUpdates['starredFields.enabled'] = options?.starredFields;
                    if(options?.customFields) documentsSettingsUpdates['customFields.enabled'] = options?.customFields;
                    if(options?.allowClientToAddDocumentWithoutForm) documentsSettingsUpdates['allowClientToAddDocumentWithoutForm'] = options?.allowClientToAddDocumentWithoutForm;
                    if(updatedDocumentsSettings.googleDocDefaultPermission) documentsSettingsUpdates['googleDocDefaultPermission'] = updatedDocumentsSettings.googleDocDefaultPermission;

                    transaction.set(doc(db, `projects/${this.uid}/_more/documents`), documentsSettingsUpdates, { merge: true });
                }
                if(options?.talent){
                    transaction.set(doc(db, `projects/${this.uid}/_more/talent`), {
                        enabled: options?.talent
                    }, { merge: true });
                }
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateClearance({
        activeUserId,
        authorities,
        dashboardPassword,
        enabled,
        filesList,
        groupsBehavior,
        guidelinesUrl,
        numberOfApprovalsRequired,
        show2FieldsForClearanceNoteRecommendation,
        reportsRestrictedToManagers,
        templateId,
        type
    }: {
        activeUserId?: string;
        authorities?: any;
        dashboardPassword?: string;
        enabled?: boolean; // APPROVALS
        filesList: any[];
        groupsBehavior?: string;
        guidelinesUrl?: string;
        numberOfApprovalsRequired?: any;
        reportsRestrictedToManagers?: boolean;
        show2FieldsForClearanceNoteRecommendation?: boolean;
        templateId?: string;
        type?: string;
    }){
        let result = null, error = null;

        let newFiles;
        if(filesList && activeUserId){
            const { newFiles: _newFiles } = await uploadClearanceFiles(
                `workspaces/${this.workspaceId}/projects/${this.uid}/clearance/files`,
                filesList || [],
                activeUserId
            );
            newFiles = _newFiles;
        }
        
        const projectClearanceRef = doc(db, `projects/${this.uid}/_more/clearance`);

        const updates:
        {
            approvals?: {
                authorities?: any; enabled?: boolean; numberOfApprovalsRequired?: any;
            };
            dashboardPassword?: string;
            files?: any;
            groupsBehavior?: string;
            guidelinesUrl?: string;
            reportsRestrictedToManagers?: boolean;
            show2FieldsForClearanceNoteRecommendation?: boolean;
            templateId?: string;
            type?: string;
        }
        &
        { [key: string]: any }
        = {
            dashboardPassword,
            groupsBehavior,
            guidelinesUrl,
            reportsRestrictedToManagers,
            show2FieldsForClearanceNoteRecommendation,
            templateId,
            type
        };
        if(enabled){
            updates.approvals = {};
            if(authorities) updates.approvals.authorities = authorities;
            if(numberOfApprovalsRequired) updates.approvals.numberOfApprovalsRequired = numberOfApprovalsRequired;
        }
        if(newFiles){
            for(const fileId in newFiles){
                updates[`files.${fileId}`] = newFiles[fileId];
            }
        }
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(projectClearanceRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateESignatureSettings({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;

        const clientESignatureSettingsRef = doc(db, `projects/${this.uid}/_more/eSignature`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clientESignatureSettingsRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateGroups({ enabled, groups }: {
        enabled?: boolean;
        groups?: {[groupId: string]: any};
    }){
        let result = null, error = null;
        
        const projectGroupsRef = doc(db, `projects/${this.uid}/_more/groups`);

        const updates: { enabled?: boolean; groups?: {[groupId: string]: any}; } = {};
        if(enabled !== undefined) updates.enabled = enabled;
        if(groups) updates.groups = groups;
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(projectGroupsRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateImage({ filesList }: {
        filesList: any
    }){
        let result = null, error = null;

        let newFileUrl = await uploadImage(
            `workspaces/${this.workspaceId}/projects/${this.uid}/images`,
            filesList || []
        );

        const projectRef = doc(db, `projects/${this.uid}`);
        const workspaceProjectsRef = doc(db, `workspaces/${this.workspaceId}/_more/projects`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(projectRef, {
                    logoUrl: newFileUrl
                });
                transaction.update(workspaceProjectsRef, {
                    [`projects.${this.uid}.logoUrl`]: newFileUrl
                });
            });
            result = newFileUrl;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateTemplates({ misc, templates }: {
        misc: {[key: string]: any};
        templates: {[key: string]: any};
    }){

        let result = null, error = null;
        
        const projectTemplatesRef = doc(db, `projects/${this.uid}/_more/documents`);
        const projectMiscRef = doc(db, `projects/${this.uid}/_more/misc`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(projectTemplatesRef, {
                    templates: templates
                }, { merge: true });
                transaction.set(projectMiscRef, {...misc}, { merge: true });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}