import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useAppStateCtx } from '../context/AppState';
import callFunction from '../firebase/functions/callFunction';
import { CNPJ_REGEXP } from '../utils/constants';

const FoundCorporationIdError = ({message}) => (
    <Box>
        <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
            <Grid item>
                <CancelIcon htmlColor="red" fontSize="small" />
            </Grid>
            <Grid item>
                <Typography variant="body1" style={{color:  'red'}}>{message}</Typography>
            </Grid>
        </Grid>
    </Box>
)

const CorporationIdQuestion = ({
    completeFieldsFromCNPJ,
    foundCNPJ, setFoundCNPJ,
    foundCNPJErrorMessage, setFoundCNPJErrorMessage,
    initialValue,
    onChange,
    question,
}) => {
    useSignals();
    const { formFoundCNPJs, formQuestionNameToCNPJ, formQuestionsStatus } = useAppStateCtx();
    const defaultValue = { input: '' };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(onChange) onChange(question.name, value);
        if(formFoundCNPJs.value && completeFieldsFromCNPJ){
            const questionValid = value.input && CNPJ_REGEXP.test(value.input);
            getCNPJ(value.input, questionValid);
        }
    }, [value]);

    const getCNPJ = async (questionValue, questionValid) => {
        if(questionValid && questionValue){
            if(!formFoundCNPJs.value[questionValue]){
                formQuestionsStatus.value = {
                    ...formQuestionsStatus.value,
                    [question.name]: 'retrievingData'
                }
                
                const res = await callFunction('getCNPJ', { cnpj: questionValue, simples: true })
                if(res.error){
                    formQuestionsStatus.value = {
                        ...formQuestionsStatus.value,
                        [question.name]: ''
                    }
                    // error retrieving CNPJ, so reset CNPJ data 
                    formQuestionNameToCNPJ.value = {
                        ...formQuestionNameToCNPJ.value,
                        [question.name]: null
                    }
                    setFoundCNPJ('error');
                    setFoundCNPJErrorMessage(res.error.message);
                    return;
                }

                formQuestionsStatus.value = {
                    ...formQuestionsStatus.value,
                    [question.name]: ''
                }
                const data = res.result;
                if(data.status.id === 2){ // Ativa
                    const result = {...data, cnpj: questionValue, selectedLegalRepresentativeIndex: 0};
                    completeFieldsFromCNPJ(result);
                } else if([3, 4, 8].includes(data.status.id)) { //Suspensa, Inapta, Baixada
                    formQuestionNameToCNPJ.value = {
                        ...formQuestionNameToCNPJ.value,
                        [question.name]: null
                    }
                    setFoundCNPJ('inactive');
                }
            } else {
                completeFieldsFromCNPJ(formFoundCNPJs.value[questionValue]);
            }
        } else {
            // question empty or not valid, so reset CNPJ data
            formQuestionNameToCNPJ.value = {
                ...formQuestionNameToCNPJ.value,
                [question.name]: null
            }
            setFoundCNPJ('');
        }
    };

    const handleChange = (newValue) => {
        setValue(prevState => ({...prevState, input: newValue}));
    };
    
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs>
                    <InputMask
                        mask="99.999.999/9999-99"
                        value={value.input}
                        onChange={(e) => handleChange(e.target.value)}
                        maskChar=""
                    >
                        {() => (
                            <TextField variant="standard" fullWidth placeholder="Sua resposta" />
                        )}
                    </InputMask>
                </Grid>
                {
                    formQuestionsStatus.value[question.name] === 'retrievingData'
                    ? <Grid item><CircularProgress size={20} /></Grid>
                    :
                    foundCNPJ !== '' &&
                    <Grid item xs={12} sm={6} md={4}>
                        {
                            foundCNPJ === 'active'
                            ?
                            <Box>
                                <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                    <Grid item>
                                        <CheckCircleIcon htmlColor="green" fontSize="small" />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" style={{color:  'green'}}>O CNPJ foi encontrado na Receita Federal</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            :
                            foundCNPJ === 'inactive'
                            ? <FoundCorporationIdError message="Esse CNPJ está inativo" />
                            :
                            <FoundCorporationIdError message={foundCNPJErrorMessage} />
                        }
                    </Grid>
                }
            </Grid>
        </Box>
    );
};

export default CorporationIdQuestion;