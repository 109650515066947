import moment from 'moment';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DocumentFlag from '../../../../../../../../components/DocumentFlag';
import PendingDocumentListItemTopClientName from './components/PendingDocumentListItemTopClientName';
import PendingDocumentListItemTopCreatorName from './components/PendingDocumentListItemTopCreatorName';
import PendingDocumentListItemTopProjectName from './components/PendingDocumentListItemTopProjectName';

const PendingDocumentListItemTop = ({handleDocumentProjectNameClick, pendingDocument}) => {
    const theme = useTheme();
    const screenMDUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={!!pendingDocument.flag ? 3 : 4} sm={2} md={!!pendingDocument.flag ? 1 : 2}>
                <Typography variant="body2" noWrap>{moment(pendingDocument.createdAt).format('D/M/YY H:mm')}</Typography>
            </Grid>
            {
                screenMDUp &&
                <Grid item xs={3} sm={3} md={!!pendingDocument.flag ? 2 : 3}>
                    <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                        <Grid item style={{width: 24}}>
                            <PersonOutlineOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                        </Grid>
                        <Grid item style={{width: 'calc(100% - 24px)'}}>
                            <PendingDocumentListItemTopCreatorName userId={pendingDocument.createdBy} />
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={!!pendingDocument.flag ? 6 : 8} sm={!!pendingDocument.flag ? 6 : 10} md={!!pendingDocument.flag ? 6 : 7}>
                <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                    <Grid item style={{width: 24}}>
                        <FolderOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                    </Grid>
                    <Grid item style={{width: 'calc(100% - 24px)'}}>
                        <Grid item container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                            <Grid item style={{maxWidth: `calc(50% - 14px)`}}>
                                <PendingDocumentListItemTopClientName clientId={pendingDocument.clientId} onClick={handleDocumentProjectNameClick} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                            </Grid>
                            <Grid item style={{maxWidth: `calc(50%)`}}>
                                <PendingDocumentListItemTopProjectName onClick={handleDocumentProjectNameClick} projectId={pendingDocument.projectId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                !!pendingDocument.flag.type &&
                <Grid item xs={3} sm={4} md={3}>
                    <DocumentFlag
                        activeUserIsOperator
                        flag={pendingDocument.flag}
                        iconClass="spinningIcon"
                    />
                </Grid>
            }
        </Grid>
    );
};

export default PendingDocumentListItemTop;