import { createContext, useContext, useMemo, useReducer, useEffect } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from './AppState';

const OperatorUsersCtxAPI = createContext();
const OperatorCtxChangeUserView = createContext();
const OperatorCtxUserTypeOptions = createContext();

const defaultState = {
    changeUserViewOpen: false,
    changeUserViewClickedUser: null,
    changeUserViewUpdatedAt: null,
    userTypeOptions: [],
};

const reducer = (state, action) => {
    const { payload, type } = action;
    
    switch (type) {
        case 'HIDE CHANGE USER VIEW':
            return { ...state, changeUserViewOpen: false, changeUserViewClickedUser: null };
        case 'SET CHANGE USER VIEW CLICKED USER':
            return { ...state, changeUserViewClickedUser: payload };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SHOW ADD USER VIEW':
            return { ...state, changeUserViewOpen: true, changeUserViewClickedUser: null };
        case 'SHOW CHANGE USER VIEW':
            return { ...state, changeUserViewOpen: true, changeUserViewClickedUser: payload.clickedUser };
        default: return state;
    }
    
};

const OperatorUsersProvider = ({children}) => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();

    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const hideChangeUserView = (payload) => {
            dispatch({type: 'HIDE CHANGE USER VIEW', payload});
        };

        const setChangeUserViewClickedUser = (clickedUser) => {
            dispatch({type: 'SET CHANGE USER VIEW CLICKED USER', payload: clickedUser});
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const showAddUserView = (payload) => {
            dispatch({type: 'SHOW ADD USER VIEW', payload});
        };
        
        const showChangeUserView = (clickedUser) => {
            dispatch({type: 'SHOW CHANGE USER VIEW', payload: { clickedUser }});
        };

        const updateVisibleUser = (payload) => {
            dispatch({type: 'UPDATE VISIBLE USER', payload});
        };

        return {
            dispatch,
            hideChangeUserView,
            setChangeUserViewClickedUser,
            setState,
            showAddUserView,
            showChangeUserView,
            updateVisibleUser
        };
    }, []);

    useEffect(() => {
        if(activeUser.value && selectedWorkspace.value){
            let currentUserTypeOptions = [
                {value: 'client', label: 'Cliente'},
                {value: 'operator', label: 'Advogado(a)'},
            ];
            if(['operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)){
                currentUserTypeOptions.push({ value: 'general partner', label: 'Sócio(a)', disabled: ['operator', 'operator/controller', 'general partner'].includes(selectedWorkspace.value?.role) });
            }
            if(['operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)){
                currentUserTypeOptions.push({value: 'operator/controller', label: 'Administrativo', disabled: ['operator', 'operator/controller', 'general partner', 'operator/manager'].includes(selectedWorkspace.value?.role) });
                currentUserTypeOptions.push({value: 'operator/manager', label: 'Sócio(a) gestor(a)', disabled: ['operator', 'operator/controller', 'general partner', 'operator/manager'].includes(selectedWorkspace.value?.role) });
            }
            if(['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)){
                currentUserTypeOptions.push({value: 'operator/admin', label: 'Sócio(a) administrador(a)', disabled: ['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin'].includes(selectedWorkspace.value?.role) });
                currentUserTypeOptions.push({value: 'operator/developer', label: 'Administrador do sistema', disabled: ['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin'].includes(selectedWorkspace.value?.role) });
                currentUserTypeOptions.push({value: 'developer', label: 'Desenvolvedor', disabled: ['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin'].includes(selectedWorkspace.value?.role) });
            }
            dispatch({ type: 'SET STATE', payload: { key: 'userTypeOptions', value: currentUserTypeOptions } });
        }
    }, [activeUser.value, selectedWorkspace.value]);
    
    const operatorUsersCtxChangeUserViewValue = useMemo(() => ({
        changeUserViewOpen: state.changeUserViewOpen,
        changeUserViewClickedUser: state.changeUserViewClickedUser,
        changeUserViewUpdatedAt: state.changeUserViewUpdatedAt
    }), [state.changeUserViewOpen, state.changeUserViewUpdatedAt]);

    return (
        <OperatorUsersCtxAPI.Provider value={api}>
        <OperatorCtxChangeUserView.Provider value={operatorUsersCtxChangeUserViewValue}>
        <OperatorCtxUserTypeOptions.Provider value={state.userTypeOptions}>
            {children}
        </OperatorCtxUserTypeOptions.Provider>
        </OperatorCtxChangeUserView.Provider>
        </OperatorUsersCtxAPI.Provider>
    );
};

const useOperatorUsersCtxAPI = () => useContext(OperatorUsersCtxAPI);
const useOperatorCtxChangeUserView = () => useContext(OperatorCtxChangeUserView);
const useOperatorCtxUserTypeOptions = () => useContext(OperatorCtxUserTypeOptions);

export {
    OperatorUsersProvider,
    useOperatorUsersCtxAPI,
    useOperatorCtxChangeUserView,
    useOperatorCtxUserTypeOptions,
    
};