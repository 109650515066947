import {
    useEffect,
    useRef,
    useState
} from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import FormQuestionSelect from '../../../../components/FormQuestionSelect';
import { useAppStateCtx } from '../../../../context/AppState';
import { useForm } from '../../context/FormContext';

const SelectQuestion = ({question, setQuestionValid}) => {
    useSignals();
    const { activeUserMainGroup, formInitialValues, projectGroupSettings } = useAppStateCtx();
    const { values } = useForm();
    const [value, setValue] = useState(null);
    // const touchedField = useRef(false);
    const [userFormQuestion, setUserFormQuestion] = useState(null);

    useEffect(() => {
        if(userFormQuestion){
            values.current = {...values.current, [question.name]: userFormQuestion};
        }
    }, [userFormQuestion]);
    
    useEffect(() => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            id: question.id,
            type: 'select'
        }));
    }, []);

    useEffect(() => {
        if(question.name === 'Grupo' && (!formInitialValues.value || (formInitialValues.value && !formInitialValues.value['Grupo']) || projectGroupSettings.value?.enabled)){
            const newValue = formInitialValues.value[question.name] ? formInitialValues.value[question.name].choice : activeUserMainGroup.value || question.options[0].value;
            setValue({choice: newValue});
            handleChange(newValue);
        } else {
            setValue(formInitialValues.value[question.name]);
        }
    }, [formInitialValues.value]);

    // const checkValidation = () => {
    //     const questionValid = formEffects.select.checkValidation(userFormQuestion.current);
    //     setQuestionValid(questionValid);
    // };
    
    // useEffect(() => {
    //     if((userFormQuestion.current && userFormQuestion.current.choice) || touchedField.current){
    //         touchedField.current = true;
    //         checkValidation();
    //     }
    // }, [userFormQuestionUpdated]);

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionSelect
            initialValue={value}
            question={question}
            onChange={handleChange}
        />
    );
};

export default SelectQuestion;