import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

function StyledInput({
    autoFocus,
    disabled,
    endIcon,
    endIconOnClick,
    endIconTitle,
    error,
    fullWidth,
    helperText,
    id, inputProps, InputProps, inputRef, label,
    maxRows, minRows, multiline,
    onBlur,
    onChange,
    onClick,
    onError,
    onErrorCapture,
    onFocus, onKeyDown, onKeyPress, placeholder, required, rows, shrinkLabel, startIcon, onStartIconClick, type, value, variant}){
    return (
        <Grid container spacing={1} alignItems="flex-end">
            {
                startIcon &&
                <Grid item>
                    {
                        onStartIconClick
                        ?
                        <IconButton
                            size="small"
                            onClick={onStartIconClick}
                        >
                            {startIcon}
                        </IconButton>
                        :
                        startIcon
                    }
                </Grid>
            }
            <Grid item xs>
                <TextField
                    autoFocus={autoFocus}
                    disabled={disabled}
                    error={error}
                    id={id} inputRef={inputRef}
                    fullWidth={fullWidth} label={label} placeholder={placeholder}
                    multiline={multiline} maxRows={maxRows} minRows={minRows}
                    variant={variant || 'standard'}
                    type={type}
                    required={required}
                    rows={rows || minRows}
                    helperText={
                        helperText &&
                        <FormHelperText error={error}>{helperText}</FormHelperText>
                    }
                    inputProps={inputProps}
                    InputProps={InputProps || {
                        endAdornment: 
                            endIcon
                            ?
                                (
                                    endIconOnClick
                                    ?
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={disabled}
                                            size="small"
                                            title={endIconTitle}
                                            onClick={endIconOnClick}
                                        >
                                            {endIcon}
                                        </IconButton>
                                    </InputAdornment>
                                    : endIcon
                                )
                            : undefined
                    }}
                    InputLabelProps={shrinkLabel ? { shrink: true } : undefined}
                    value={value} onChange={onChange}
                    onError={onError}
                    onErrorCapture={onErrorCapture}
                    onBlur={onBlur}
                    onClick={onClick}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                    onKeyPress={onKeyPress}
                />
            </Grid>
        </Grid>
    );
}

export default StyledInput;