import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

import { db } from '../firebase/config';

const useGetAppVersion = (shouldSetListener: boolean) => {
    const [hookState, setHookState] = useState<{ isCriticalUpdate: boolean; latest: string; } | null>(null);

    useEffect(() => {
        if(shouldSetListener){
            const unsubscribe = onSnapshot(doc(db, `_app/version`),
                (snapshot) => {
                    const data = snapshot.data();
                    if(data) setHookState({
                        isCriticalUpdate: !!data.isCriticalUpdate,
                        latest: data.latest || ''
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener]);

    return hookState;
};

export default useGetAppVersion;