import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { useAppStateCtx } from '../context/AppState';
import LoadingBackdrop from './LoadingBackdrop';

const LoadingSystemScreen = () => {
    useSignals();
    const { loadingApp, loadingAppText } = useAppStateCtx();
    const [showProgress, setShowProgress] = useState(false);

    useEffect(() => {
        setShowProgress(false);
        setTimeout(() => {
            setShowProgress(true);
        }, 3000);
    }, [loadingApp.value]);

    const handleClick = () => {
        setShowProgress(true);
    };

    const handleClose = () => {
        loadingApp.value = false;
    };

    return (
        <LoadingBackdrop open={loadingApp.value} onClick={handleClick} onClose={handleClose} backgroundColor="white"
            content={
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid>
                        <Box mb={2}>
                            <img src={'/SOLIDA-500.png'} style={{height: 80, maxWidth: '100%'}} alt="SOLIDA" />
                        </Box>
                    </Grid>
                    {
                        loadingAppText.value &&
                        <Grid container justifyContent="center">
                            <Grid>
                                <Box mb={1}>
                                    <Typography variant="body1" color="primary">{loadingAppText.value}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    }
                    <Grid container justifyContent="center">
                        <Grid>
                            <Fade in={showProgress}>
                                <CircularProgress size={20} />
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};

export default LoadingSystemScreen;