import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Collapse } from '@mui/material';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import Dropzone from '../../../components/Dropzone';
import InputField from './components/InputField';
import QuestionBox from '../../../components/QuestionBox';
import Select from '../../../components/Select';
import TalentForm from '../../../components/talents/TalentForm';
import TwoButtonsSwitch from '../../../components/TwoButtonsSwitch';
import ViewBox from '../../../components/ViewBox';
import Document from '../../../classes/Document';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../utils/constants';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { useAppCtxAPI, useAppCtxLoading } from '../../../context/SystemContext';
import { getForm } from '../../../utils/talents';

function RequestReview(){
    useSignals();
    const { activeUser, activeUserGroups, activeUserIsGroupsManager, activeUserMainGroup, projectGroupSettings, projectTalentSettings, selectedFolder, selectedWorkspace } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { setLoading } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const question1Name = 'Nome ou razão social da outra parte';
    const question2Name = 'Contrato de...';
    const question3Name = 'Considerações para revisão';
    const question4Name = 'Anexo - documento a revisar, se for o caso (PDF, DOC ou DOCX, no máximo 15 MB por arquivo)';
    const [groupOptions, set_groupOptions] = useState([]);
    const [selectedGroup, setSelectedGroupId] = useState('');
    const [errorText, setErrorText] = useState('');
    const acceptedFilesRef = useRef([]);
    const defaultReviewDocumentForm = {
        [question1Name]: {id: 1, input: ''},
        [question2Name]: {id: 2, input: ''},
        [question3Name]: {id: 3, input: ''},
    };
    const reviewDocumentFormRef = useRef(defaultReviewDocumentForm);
    const defaultAdditionalDocumentFieldsRef = {
        fileUrl: {input: ''}
    };
    const additionalDocumentFieldsRef = useRef(defaultAdditionalDocumentFieldsRef);
    const [userWillUploadFile, setUserWillUploadFile] = useState(true);
    const talentRef = useRef({});
    const [loadingProjectTalent, setLoadingProjectTalent] = useState(false);
    const [formSent, setFormSent] = useState(false);
    const [talentType, setTalentType] = useState('');

    useEffect(() => {
        setFirestoreListener('projectTalentSettings');
    }, []);

    useEffect(() => {
        let currentGroups = [], currentSelectedGroup = null;
        if(selectedFolder.value && projectGroupSettings.value?.enabled && activeUserGroups.value){
            currentGroups = activeUserGroups.value.map(group => ({value: group.id, label: group.name}));
            if(activeUserIsGroupsManager.value || activeUserGroups.value.length === 0){
                currentGroups.unshift({value: 'none', label: 'Sem grupo (disponível para todos)'});
            }
            currentSelectedGroup = activeUserMainGroup.value;
        }
        set_groupOptions(currentGroups);
        setSelectedGroupId(currentSelectedGroup);
    }, [selectedFolder.value, projectGroupSettings.value, activeUserGroups.value]);

    useEffect(() => {
        if(projectTalentSettings.value?.enabled && talentType){
            setLoadingProjectTalent(true);
            reviewDocumentFormRef.current[question1Name] = {id: 1, input: ''};
            reviewDocumentFormRef.current[question3Name] = {id: 3, input: ''};
            talentRef.current = {};
            setLoadingProjectTalent(false);
        }
    }, [projectTalentSettings.value, talentType]);

    const handleSubmit = async () => {
        if(userWillUploadFile){
            if(acceptedFilesRef.current.length === 0) return setErrorText('Selecione um arquivo.');
        } else {
            if(!additionalDocumentFieldsRef.current.fileUrl.input) return setErrorText('Indique o link do arquivo.');
        }
        if(!talentType){
            if(!reviewDocumentFormRef.current[question2Name].input) return setErrorText(`Você deve responder a pergunta "${question2Name}".`);
        }

        setLoading(true);
        const toastId = toast.loading('Enviando...');

        const newDocument = new Document({
            clientId: selectedFolder.value.clientId,
            createdBy: activeUser.value.uid,
            form: JSON.stringify({...reviewDocumentFormRef.current}),
            groupId: selectedGroup !== 'none' ? selectedGroup : '',
            projectId: selectedFolder.value.uid,
            templateId: 'review',
            workspaceId: selectedWorkspace.value.uid
        });

        let fileUrl, filesList;
        if(userWillUploadFile){
            filesList = acceptedFilesRef.current;
        } else {
            fileUrl = additionalDocumentFieldsRef.current.fileUrl.input;
        }

        let talentDocumentName;
        if(projectTalentSettings.value?.enabled){
            const mappedDocumentTalentForm = [];
            const documentTalentForm = talentRef.current;
            const talentForm = getForm(talentType);
            for(const talentFormQuestion of talentForm){
                const talentFormQuestionType = talentFormQuestion.type;
                if(talentFormQuestionType !== 'sectionTitle'){
                    const information = {};
                    const documentTalentFormQuestion = documentTalentForm[talentFormQuestion.id];
                    if(documentTalentFormQuestion){
                        if(talentFormQuestionType === 'text'){
                            information.input = documentTalentFormQuestion.input || '';
                            information.clause = talentFormQuestion.clause || '';
                        } else if(talentFormQuestionType === 'radio'){
                            const documentTalentFormQuestionChoice = documentTalentFormQuestion.choice;
                            if(documentTalentFormQuestionChoice){
                                const selectedOption = talentFormQuestion.options.find(option => option.value === documentTalentFormQuestionChoice);
                                if(selectedOption){
                                    information.choice = selectedOption.label;
                                    information.clause = selectedOption.clause || '';
                                    if(selectedOption.other){
                                        information.input = documentTalentFormQuestion.input || (selectedOption.other !== true ? selectedOption.other : '');
                                    }
                                }
                            }
                        } else if(talentFormQuestionType === 'checkbox'){
                            information.clause = '';
                            if(talentFormQuestion.clauseBefore) information.clause = `${talentFormQuestion.clauseBefore}\n`;
                            information.choices = [];
                            for(let key in documentTalentFormQuestion){
                                if(documentTalentFormQuestion[key].checked){
                                    const selectedOption = talentFormQuestion.options.find(selectedOption => selectedOption.value === key);
                                    if(selectedOption){
                                        if(selectedOption.clause) information.clause += `${selectedOption.clause}\n`;
                                        const informationChoice = {};
                                        informationChoice.choice = selectedOption.label;
                                        if(selectedOption.other){
                                            informationChoice.input = documentTalentFormQuestion[key].input || (selectedOption.other !== true ? selectedOption.other : '');
                                        }
                                        information.choices.push(informationChoice);
                                    }
                                }
                            }
                            if(talentFormQuestion.clauseAfter) information.clause += talentFormQuestion.clauseAfter;
                        }
                    } else {
                        // if not found, use defaults
                        const talentFormQuestionDefaultValue = talentFormQuestion.defaultValue;
                        if(talentFormQuestionDefaultValue){
                            if(talentFormQuestionType === 'text'){
                                information.input = talentFormQuestionDefaultValue.input || '';
                                information.clause = talentFormQuestion.clause || '';
                            } else if(talentFormQuestionType === 'radio'){
                                const selectedOption = talentFormQuestion.options.find(option => option.value === talentFormQuestionDefaultValue.choice);
                                if(selectedOption){
                                    information.choice = selectedOption.label;
                                    information.clause = selectedOption.clause || '';
                                    if(selectedOption.other){
                                        information.input = selectedOption.other !== true ? selectedOption.other : '';
                                    }
                                }
                            } else if(talentFormQuestionType === 'checkbox'){
                                information.clause = '';
                                if(talentFormQuestion.clauseBefore) information.clause = `${talentFormQuestion.clauseBefore}\n`;
                                information.choices = [];
                                for(let key in talentFormQuestionDefaultValue){
                                    if(talentFormQuestionDefaultValue[key].checked){
                                        const selectedOption = talentFormQuestion.options.find(selectedOption => selectedOption.value === key);
                                        if(selectedOption){
                                            if(selectedOption.clause) information.clause += `${selectedOption.clause}\n`;
                                            const informationChoice = {};
                                            informationChoice.choice = selectedOption.label;
                                            if(selectedOption.other){
                                                informationChoice.input = selectedOption.other !== true ? selectedOption.other : '';
                                            }
                                            information.choices.push(informationChoice);
                                        }
                                    }
                                }
                                if(talentFormQuestion.clauseAfter) information.clause += talentFormQuestion.clauseAfter;
                            }
                        }
                    }
                    mappedDocumentTalentForm.push({
                        id: talentFormQuestion.id,
                        type: talentFormQuestionType,
                        name: talentFormQuestion.name,
                        information
                    });
                }
            }
            newDocument.talentForm = {
                form: mappedDocumentTalentForm,
                type: talentType
            };

            const talentFormNames = {
                castComplete: () => 'Elenco (completo)',
                castSimple: () => 'Elenco (simples)',
                creativesComplete: () => {
                    const roles = [];
                    for(let key in documentTalentForm.roleType){
                        if(key !== 'type' && documentTalentForm.roleType[key].checked){
                            const talentFormQuestion = talentForm.find(talentFormQuestion => talentFormQuestion.id === 'roleType');
                            if(talentFormQuestion){
                                const selectedOption = talentFormQuestion.options.find(selectedOption => selectedOption.value === key);
                                if(selectedOption){
                                    roles.push(selectedOption.label);
                                }
                            }
                            
                        }
                    }
                    return roles.join(' | ');
                },
                creativesSimple: () => {
                    const roles = [];
                    for(let key in documentTalentForm.roleType){
                        if(key !== 'type' && documentTalentForm.roleType[key].checked){
                            const talentFormQuestion = talentForm.find(talentFormQuestion => talentFormQuestion.id === 'roleType');
                            if(talentFormQuestion){
                                const selectedOption = talentFormQuestion.options.find(selectedOption => selectedOption.value === key);
                                if(selectedOption){
                                    roles.push(selectedOption.label);
                                }
                            }
                            
                        }
                    }
                    return roles.join(' | ');
                },
                directorComplete: () => {
                    const roles = [];
                    for(let key in documentTalentForm.roleType){
                        if(key !== 'type' && documentTalentForm.roleType[key].checked){
                            const talentFormQuestion = talentForm.find(talentFormQuestion => talentFormQuestion.id === 'roleType');
                            if(talentFormQuestion){
                                const selectedOption = talentFormQuestion.options.find(selectedOption => selectedOption.value === key);
                                if(selectedOption){
                                    roles.push(selectedOption.label);
                                }
                            }
                            
                        }
                    }
                    return roles.join(' | ');
                },
                directorSimple: () => {
                    const roles = [];
                    for(let key in documentTalentForm.roleType){
                        if(key !== 'type' && documentTalentForm.roleType[key].checked){
                            const talentFormQuestion = talentForm.find(talentFormQuestion => talentFormQuestion.id === 'roleType');
                            if(talentFormQuestion){
                                const selectedOption = talentFormQuestion.options.find(selectedOption => selectedOption.value === key);
                                if(selectedOption){
                                    roles.push(selectedOption.label);
                                }
                            }
                            
                        }
                    }
                    return roles.join(' | ');
                },
                option: () => 'Opção'
            };
            let workTitle = '';
            if(documentTalentForm.workTitle) workTitle = documentTalentForm.workTitle.input || '';
            let productionCompany = '';
            if(documentTalentForm.productionCompany) productionCompany = documentTalentForm.productionCompany.input || '';
            let workPlayer = '';
            if(documentTalentForm.workPlayer) workPlayer = documentTalentForm.workPlayer.input || '';
            talentDocumentName = `${talentFormNames[talentType]()}${workTitle ? ` - ${workTitle}` : ''}${productionCompany ? ` (${productionCompany}${workPlayer ? ` - ${workPlayer}` : ''})` : ''}`;
        }

        newDocument.name = `Revisão - ${talentDocumentName || `${reviewDocumentFormRef.current['Contrato de...'].input || ''} ${reviewDocumentFormRef.current['Nome ou razão social da outra parte'].input || ''}`}`.replace('/', '\/');

        const res = await newDocument.createDocument({ awaitingOperatorReview: true, saveFormId: null, fileUrl, filesList });
        setLoading(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });

        clearForm();
    };

    const clearForm = () => {
        reviewDocumentFormRef.current = defaultReviewDocumentForm;
        additionalDocumentFieldsRef.current = defaultAdditionalDocumentFieldsRef;
        talentRef.current = {};
        setUserWillUploadFile(true);
        setTalentType('');
        setFormSent(true);
    };

    const handleNewFormButton = () => {
        setFormSent(false);
    };

    const selectedTalentForm = useMemo(() => <TalentForm noTitle form="document" type={talentType} talentRef={talentRef} />, [talentType]);

    return (
        <ViewBox>
            <Container maxWidth="md">
                {
                    formSent
                    ?
                    <Box>
                        <Box mb={3}>
                            <Collapse in={true}>
                                <Alert variant="filled" severity="success">O formulário foi enviado.</Alert>
                            </Collapse>
                        </Box>
                        <Box>
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained" color="primary"
                                    onClick={handleNewFormButton}
                                >Novo formulário</Button>
                            </Grid>
                        </Box>
                    </Box>
                    :
                    <Box>
                        {
                            projectTalentSettings.value?.enabled &&
                            <Box mb={2}>
                                <Container maxWidth="xs">
                                    <Select
                                        options={[
                                            { value: 'castComplete', label: 'Elenco (completo)' },
                                            { value: 'castSimple', label: 'Elenco (simples)' },
                                            { value: 'creativesComplete', label: 'Roteirista (completo)' },
                                            { value: 'creativesSimple', label: 'Roteirista (simples)' },
                                            { value: 'directorComplete', label: 'Diretor (completo)' },
                                            { value: 'directorSimple', label: 'Diretor (simples)' },
                                            { value: 'option', label: 'Contrato de opção' },
                                        ]}
                                        value={talentType || 'select'} onChange={(e) => setTalentType(e.target.value)}
                                    />
                                </Container>
                            </Box>
                        }
                        {
                            projectGroupSettings.value?.enabled &&
                            <QuestionBox name="Grupo" mandatory questionValid>
                                <Select options={groupOptions} value={selectedGroup} onChange={(e) => setSelectedGroupId(e.target.value)} />
                            </QuestionBox>
                        }
                        {
                            !talentType &&
                            <>
                                <QuestionBox name={question1Name} mandatory questionValid>
                                    <InputField formRef={reviewDocumentFormRef} questionKey={question1Name} />
                                </QuestionBox>
                                <QuestionBox name={question2Name} mandatory questionValid>
                                    <InputField formRef={reviewDocumentFormRef} questionKey={question2Name} />
                                </QuestionBox>
                                <QuestionBox name={question3Name} questionValid>
                                    <InputField formRef={reviewDocumentFormRef} questionKey={question3Name} />
                                </QuestionBox>
                            </>
                        }
                        {
                            (projectTalentSettings.value?.enabled && talentType) &&
                            <Box mb={3}>
                                {
                                    loadingProjectTalent
                                    ? <LoaderEllipsis />
                                    :
                                    <QuestionBox questionValid>
                                        {selectedTalentForm}
                                    </QuestionBox>
                                }
                            </Box>
                        }

                        <Box mb={2}>
                            <TwoButtonsSwitch
                                boolean={userWillUploadFile}
                                setBoolean={setUserWillUploadFile}
                                firstButtonStartIcon={<AttachFileIcon />}
                                firstButtonText="Arquivo"
                                secondButtonStartIcon={<LinkIcon />}
                                secondButtonText="Link"
                            />
                        </Box>
                        
                        {
                            userWillUploadFile
                            ?
                            <QuestionBox name={question4Name} questionValid>
                                <Dropzone
                                    acceptedFilesRef={acceptedFilesRef}
                                    accept={{
                                        'application/pdf': ['.pdf'],
                                        'application/msword': ['.doc'],
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                                    }}
                                    multiple
                                />
                            </QuestionBox>
                            :
                            <QuestionBox name="Link" questionValid>
                                <InputField formRef={additionalDocumentFieldsRef} questionKey="fileUrl" />
                            </QuestionBox>
                        }

                        <Box>
                            {
                                errorText &&
                                <FormHelperText error>{errorText}</FormHelperText>
                            }
                            <Grid container justifyContent="center">
                                <Button
                                    variant="contained" color="primary"
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >Enviar</Button>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Typography variant="body1" color="secondary">* Perguntas obrigatórias</Typography>
                            </Grid>
                        </Box>
                    </Box>
                }
            </Container>
        </ViewBox>
    );
}

export default RequestReview;