import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ShortUniqueId from 'short-unique-id';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxChangeTemplateView } from '../context/TemplatesContext';

import CurrencyInput from '../../../Accounting/components/CurrencyInput';

import AddOrSubtractDial from '../../../../../components/AddOrSubtractDial';
import Autocomplete from '../../../../../components/Autocomplete';
import Dialog from '../../../../../components/Dialog/Dialog';
import Select from '../../../../../components/Select';
import Switch from '../../../../../components/Switch';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';
import { useAppCtxAPI } from '../../../../../context/SystemContext';
import getDoc from '../../../../../firebase/firestore/getDoc';
import useGetDocumentTemplateSettings from '../../../../../hooks/useGetDocumentTemplateSettings';
import useGetWorkspaceListItems from '../../../../../hooks/useGetWorkspaceListItems';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';
import { sortByKey } from '../../../../../utils/filters'; 

const BypassReviewException = ({
    exception,
    exceptionId,
    handleSkipOperatorsIfNotFreeStyleExceptionDelete,
    handleSkipOperatorsIfNotFreeStyleExceptionInputChange,
    handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange,
    templateFormQuestions
}) => {
    useSignals();
    const { workspaceLists } = useAppStateCtx();
    const [foundQuestion, setFoundQuestion] = useState(null);
    const [workspaceListId, setWorkspaceListId] = useState('');
    const [workspaceList, setWorkspaceList] = useState(null);
    const [loadingWorkspaceList, setLoadingWorkspaceList] = useState(true);

    useEffect(() => {
        let currentFoundQuestion = null, currentWorkspaceListId = '';
        if(exception.questionName){
            currentFoundQuestion = templateFormQuestions.find(templateFormQuestion => templateFormQuestion.name === exception.questionName);
            if(currentFoundQuestion){
                currentFoundQuestion.mappedOptions = [];
                if(currentFoundQuestion.type === 'list'){
                    currentWorkspaceListId = currentFoundQuestion.typeId;
                } else if(currentFoundQuestion.options){
                    currentFoundQuestion.mappedOptions = currentFoundQuestion.options.map(option => ({...option, value: option.choice}));
                }
            }
        }
        setWorkspaceListId(currentWorkspaceListId);
        setFoundQuestion(currentFoundQuestion);
    }, [exception]);

    const retrievedWorkspaceListItems = useGetWorkspaceListItems(workspaceListId, workspaceListId);
    useEffect(() => {
        let currentWorkspaceList = null;
        let currentLoadingWorkspaceList = false;
        if(retrievedWorkspaceListItems.data){
            const foundList = Object.entries(workspaceLists.value).find(([listId, list]) => workspaceListId === listId || workspaceListId === list.shortName);
            if(foundList){
                let foundListItems = retrievedWorkspaceListItems.data[workspaceListId];
                if(!foundListItems && foundList.shortName){
                    foundListItems = retrievedWorkspaceListItems.data[foundList.shortName];
                }
                if(foundListItems){
                    currentWorkspaceList = {
                        list: foundListItems,
                        mappedOptions: foundListItems.sort(sortByKey('key')).map(option => option.uid)
                    };
                }
            }
        }
        setWorkspaceList(currentWorkspaceList);
        setLoadingWorkspaceList(currentLoadingWorkspaceList);
    }, [retrievedWorkspaceListItems]);
    
    if(foundQuestion){

        if(foundQuestion.type === 'list' && loadingWorkspaceList){
            return (<CircularProgress />);
        }

        if(foundQuestion.type !== 'list' || !!workspaceList){
            return (
                <Box>
                    <Grid container spacing={1} alignItems="flex-start" wrap="nowrap" style={{width: '100%'}}>
                        <Grid style={{width: '34%'}}>
                            <Select
                                options={templateFormQuestions}
                                value={exception.questionName}
                                onChange={(e) => handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange(e.target.value, exceptionId)}
                            />
                        </Grid>
                        <Grid style={{width: 'calc(100% - 34% - 56px)'}}>
                            {
                                foundQuestion.type === 'radio'
                                ?
                                <Select
                                    options={foundQuestion.mappedOptions.filter(option => option.value !== '~t;')}
                                    value={exception.userInput || 'select'}
                                    onChange={(e) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(e.target.value, exceptionId)}
                                />
                                :
                                foundQuestion.type === 'list'
                                ?
                                <Autocomplete
                                    options={workspaceList.mappedOptions}
                                    getOptionLabel={(option) => {
                                        const foundListItem = workspaceList.list.find(listItem => listItem.uid === option);
                                        if(foundListItem) return foundListItem.key;
                                        return '';
                                    }}
                                    value={exception.userInput}
                                    onChange={(e, newValue) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(newValue, exceptionId)}
                                />
                                :
                                foundQuestion.type === 'currency'
                                ?
                                <Box>
                                    <CurrencyInput
                                        fullWidth
                                        value={exception.userInput || 0}
                                        onChange={(e, newValue) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(newValue, exceptionId)}
                                    />
                                    <Typography variant="body2">O documento não será enviado se a resposta do formulário for maior ou igual ao valor acima.</Typography>
                                </Box>
                                :
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    value={exception.userInput}
                                    onChange={(e) => handleSkipOperatorsIfNotFreeStyleExceptionInputChange(e.target.value, exceptionId)} />
                            }
                        </Grid>
                        <Grid style={{width: 56}}>
                            <IconButton
                                onClick={() => handleSkipOperatorsIfNotFreeStyleExceptionDelete(exceptionId)}
                                size="large">
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    }
    return null;
}

const TemplateWindow = () => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { handleNavigate } = useAppCtxAPI();
    const { hideChangeTemplateView } = useOperatorTemplatesCtxAPI();
    const { changeTemplateViewOpen, changeTemplateViewClickedTemplate: selectedTemplate } = useOperatorTemplatesCtxChangeTemplateView();
    const defaultSkipOperatorIfNotFreeStyleException = {
        id: '',
        questionId: '',
        questionName: '',
        userInput: ''
    };
    const defaultTemplateState = {
        averageMinutesRequiredForDocumentReview: 15,
        bypassDocumentOperatorReview: false,
        bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: false,
        bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay: 10,
        bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions: {},
        checkCNPJ: false,
        createDocumentAsPdf: false,
        description: '',
        name: '',
        includeLogo: false,
    };
    const [templateState, setTemplateState] = useState(defaultTemplateState);
    const [templateFormQuestions, setTemplateFormQuestions] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setFirestoreListener('workspaceLists');
    }, []);

    useEffect(() => {
        if(changeTemplateViewOpen && selectedTemplate){
            setTemplateState({...selectedTemplate});
        }
    }, [changeTemplateViewOpen, selectedTemplate]);

    useEffect(() => {
        if(selectedTemplate){
            const fetchData = async () => {
                const getDocumentTemplateRes = await getDoc(`documents_templates/${selectedTemplate.uid}/_more/form`);
                if(getDocumentTemplateRes.error){
        
                }
                if(getDocumentTemplateRes.result.form){
                    const currentTemplateFormQuestions = getDocumentTemplateRes.result.form.filter(templateFormQuestion => templateFormQuestion.type !== 'heading').map(templateFormQuestion => ({...templateFormQuestion, label: templateFormQuestion.name, value: templateFormQuestion.name}));
                    setTemplateFormQuestions(currentTemplateFormQuestions);
                }
            };
        
            fetchData();
        }
    }, [selectedTemplate?.uid]);

    const documentTemplateSettings = useGetDocumentTemplateSettings(selectedTemplate?.uid);
    useEffect(() => {
        if(!documentTemplateSettings.loading && documentTemplateSettings.data){
            setTemplateState(prevState => ({
                ...prevState,
                averageMinutesRequiredForDocumentReview: documentTemplateSettings.data.averageMinutesRequiredForDocumentReview || 15,
                bypassDocumentOperatorReview: !!documentTemplateSettings.data.bypassDocumentOperatorReview,
                bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: !!documentTemplateSettings.data.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText.bypass,
                bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay: documentTemplateSettings.data.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText.delay || 10,
                bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions: documentTemplateSettings.data.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText.exceptions || {},
                checkCNPJ: !!documentTemplateSettings.data.checkCNPJ,
                createDocumentAsPdf: !!documentTemplateSettings.data.createDocumentAsPdf,
                includeLogo: !!documentTemplateSettings.data.includeLogo,
            }));
        }
    }, [documentTemplateSettings]);

    const changeMinimumNumberOfMinutesRequiredForDocumentDelivery = (minutes) => {
        let newValue = (templateState.averageMinutesRequiredForDocumentReview || 15) + minutes;
        if(newValue <= 15) newValue = 15;
        setTemplateState(prevState => ({...prevState, averageMinutesRequiredForDocumentReview: newValue}));
    };

    const changeSkipOperatorIfNotFreeStyleDelay = (minutes) => {
        let newValue = (templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay || 10) + minutes;
        if(newValue <= 10) newValue = 10;
        setTemplateState(prevState => ({...prevState, bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay: newValue}));
    };

    const handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange = (newValue, exceptionId) => {
        setTemplateState(prevState => ({
            ...prevState,
            bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions: {
                ...prevState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions,
                [exceptionId]: {
                    ...prevState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions[exceptionId],
                    questionName: newValue
                }
            }
        }));
    };

    const handleSkipOperatorsIfNotFreeStyleExceptionDelete = (exceptionId) => {
        setTemplateState(prevState => {
            const nextState = {...prevState};
            delete nextState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions[exceptionId];
            return nextState;
        });
    };
    
    const handleSkipOperatorsIfNotFreeStyleExceptionInputChange = (newValue, exceptionId) => {
        setTemplateState(prevState => ({
            ...prevState,
            bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions: {
                ...prevState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions,
                [exceptionId]: {
                    ...prevState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions[exceptionId],
                    userInput: newValue
                }
            }
        }));
    };

    const handleSubmit = async () => {
        setSaving(true);
        const toastId = toast.loading(`Salvando a matriz ${templateState.name}...`);
        const settingsUpdates = {
            averageMinutesRequiredForDocumentReview: templateState.averageMinutesRequiredForDocumentReview,
            bypassDocumentOperatorReview: templateState.bypassDocumentOperatorReview,
            bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: {
                bypass: templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText
            },
            checkCNPJ: templateState.checkCNPJ,
            createDocumentAsPdf: templateState.createDocumentAsPdf,
            includeLogo: templateState.includeLogo
        }   
        if(templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText){
            settingsUpdates.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText.delay = templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay;
            settingsUpdates.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText.exceptions = templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions;
        }
        const res = await selectedTemplate.update({
            settings: settingsUpdates,
            updates: {
                name: templateState.name,
                description: templateState.description || ''
            },
            workspaceId: selectedWorkspace.value.uid
        });
        if(res.error || !res.result){
            setSaving(false);
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        setSaving(false);
    };

    const handleChangeTemplateClick = async () => {
        hideChangeTemplateView();
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes/${selectedTemplate.uid}/documento`);
    };

    const handleChangeTemplateTitlePatternClick = () => {
        hideChangeTemplateView();
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes/titulo?id=${selectedTemplate.uid}`);
    };

    //TODO
    // const handleCloneTemplateClick = async () => {
    //     // setLoading(true);
    //     // toast('Duplicando... aguarde alguns segundos...');
    //     //TODO
    //     // setLoading(false);
    // };

    const handleDisableTemplateClick = async () => {
        const res = await selectedTemplate.disable(activeUser.value.uid, selectedWorkspace.value.uid);
        if(res.error){
            return;
        }
        handleClose();
    };

    const handleClose = () => {
        hideChangeTemplateView();
    };

    const handleAddExceptionClick = () => {
        const shortId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
        setTemplateState(prevState => ({
            ...prevState,
            bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions: {
                ...prevState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions,
                [shortId]: {
                    ...defaultSkipOperatorIfNotFreeStyleException,
                    id: shortId
                }
            }
        }));
    };

    // const optionsDisabled = selectedWorkspace.value?.uid === FIRST_CLIENT_ID && templateState.clientId !== FIRST_CLIENT_ID && !['operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role);
    
    return (
        <Dialog
            // maxWidth="lg"
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeTemplateViewOpen}
            saveButtonDisabled={!templateState.name}
            saving={saving}
            title={selectedTemplate?.name || ''}
        >

            <Box>
                <Box mb={2}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid>
                            <Button onClick={handleChangeTemplateClick}>Alterar matriz</Button>
                            <Button onClick={handleChangeTemplateTitlePatternClick}>Alterar padrão de título</Button>
                            {/* <Button onClick={handleCloneTemplateClick}>Duplicar matriz</Button> */}
                            <Button onClick={handleDisableTemplateClick}>Desativar</Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={3}>
                    <Box mb={2}>
                        <Typography variant="h4">Dados gerais</Typography>
                    </Box>
                    <Box mb={1}>
                        <Grid container spacing={1}>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <TextField
                                    variant="standard"
                                    label="Nome da matriz"
                                    fullWidth
                                    required
                                    value={templateState.name}
                                    onChange={(e) => setTemplateState(prevState => ({...prevState, name: e.target.value}))} />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                                <TextField
                                    variant="standard"
                                    label="Descrição"
                                    fullWidth
                                    value={templateState.description}
                                    InputLabelProps={{ shrink: !!templateState.description }}
                                    onChange={(e) => setTemplateState(prevState => ({...prevState, description: e.target.value}))} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box mb={3}>
                    <Box mb={2}>
                        <Typography variant="h4">Tempo mínimo para revisão</Typography>
                    </Box>
                    <Box mb={1}>
                        <AddOrSubtractDial
                            subtractDisabled={!templateState.averageMinutesRequiredForDocumentReview || templateState.averageMinutesRequiredForDocumentReview === 15}
                            subtractOnClick={() => changeMinimumNumberOfMinutesRequiredForDocumentDelivery(-15)}
                            number={templateState.averageMinutesRequiredForDocumentReview || 15}
                            // addDisabled={optionsDisabled}
                            addOnClick={() => changeMinimumNumberOfMinutesRequiredForDocumentDelivery(15)}
                        />
                    </Box>
                </Box>
                <Box mb={3}>
                    <Box mb={2}>
                        <Typography variant="h4">Opções</Typography>
                    </Box>
                    <Box mb={1}>
                        <Switch
                            // disabled={optionsDisabled}
                            label={`Inserir logo da pasta ou da empresa no documento${templateState.includeLogo ? '' : '?'}`}
                            noWrap
                            checked={templateState.includeLogo}
                            onChange={(e) => setTemplateState(prevState => ({...prevState, includeLogo: e.target.checked}))}
                        />
                        <Switch
                            // disabled={optionsDisabled}
                            label={`Gerar PDF ao invés de documento editável${templateState.createDocumentAsPdf ? '' : '?'}`}
                            noWrap
                            checked={templateState.createDocumentAsPdf}
                            onChange={(e) => setTemplateState(prevState => ({...prevState, createDocumentAsPdf: e.target.checked}))}
                        />
                        <Switch
                            // disabled={optionsDisabled}
                            label={`Disponibilizar o documento ao(à) solicitante imediatamente${templateState.bypassDocumentOperatorReview ? '' : '?'}`}
                            noWrap
                            checked={templateState.bypassDocumentOperatorReview}
                            onChange={(e) => setTemplateState(prevState => ({...prevState, bypassDocumentOperatorReview: e.target.checked}))}
                        />
                        <Switch
                            // disabled={optionsDisabled}
                            label={`Verificar se o CNPJ indicado no formulário é compatível com o objeto do contrato${templateState.checkCNPJ ? '' : '?'}`}
                            noWrap
                            checked={templateState.checkCNPJ}
                            onChange={(e) => setTemplateState(prevState => ({...prevState, checkCNPJ: e.target.checked}))}
                        />
                        <Switch
                            // disabled={optionsDisabled}
                            label={`Disponibilizar o documento ao(à) solicitante automaticamente caso o formulário não contenha respostas de texto livre${templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText ? '' : '?'}`}
                            noWrap
                            checked={templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText}
                            onChange={(e) => setTemplateState(prevState => ({...prevState, bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: e.target.checked}))}
                        />
                    </Box>
                </Box>
                {
                    templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText &&
                    <Box mb={3}>
                        <Box mb={2}>
                            <Typography variant="h4">Tempo para envio automático de documentos com formulários sem respostas de texto livre</Typography>
                        </Box>
                        <Box mb={1}>
                            <AddOrSubtractDial
                                subtractDisabled={templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay === 10}
                                subtractOnClick={() => changeSkipOperatorIfNotFreeStyleDelay(-10)}
                                number={templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextDelay || 10}
                                // addDisabled={optionsDisabled}
                                addOnClick={() => changeSkipOperatorIfNotFreeStyleDelay(10)}
                            />
                        </Box>
                    </Box>
                }
                {
                    templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText &&
                    <Box mb={3}>
                        <Box mb={2}>
                            <Typography variant="h4">Exceções ao envio automático de documentos com formulários sem respostas de texto livre</Typography>
                            <Typography variant="body2">Se o formulário for preenchido com qualquer uma das respostas a seguir, o documento não será enviado automaticamente.</Typography>
                        </Box>
                        {
                            selectedTemplate &&
                            <Box mb={1}>
                                {
                                    Object.entries(templateState.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeTextExceptions)
                                    .map(([exceptionId, skipOperatorIfNotFreeStyleException]) => {
                                        return (
                                            <BypassReviewException
                                                key={exceptionId}
                                                exceptionId={exceptionId}
                                                exception={skipOperatorIfNotFreeStyleException}
                                                handleSkipOperatorsIfNotFreeStyleExceptionDelete={handleSkipOperatorsIfNotFreeStyleExceptionDelete}
                                                handleSkipOperatorsIfNotFreeStyleExceptionInputChange={handleSkipOperatorsIfNotFreeStyleExceptionInputChange}
                                                handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange={handleSkipOperatorsIfNotFreeStyleExceptionQuestionChange}
                                                templateFormQuestions={templateFormQuestions}
                                            />
                                        );
                                    })
                                }
                                <Grid container justifyContent="flex-end">
                                    <Grid>
                                        <Button startIcon={<AddIcon />} onClick={handleAddExceptionClick}>Nova exceção</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box>
                }
            </Box>

        </Dialog>
    );
};

export default TemplateWindow;