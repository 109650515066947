import { useRef, useState, useEffect } from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import { Badge, Box, IconButton } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useAppStateCtx } from '../context/AppState';
import useGetUserNotifications from '../hooks/useGetUserNotifications';
import { updateArrayItem } from '../utils/common';

import NotificationsPopOver from './NotificationsPopOver';

const notifications = signal(null);
const notificationsListOpen = signal(false);
const numberOfNotifications = signal(null);

const NotificationsIconWithCounter = () => {
    useSignals();
    return (
        <Badge badgeContent={numberOfNotifications.value} color="secondary">
            <NotificationsIcon /> 
        </Badge>
    )
}

function Notifications(){
    useSignals();
    const { activeUser } = useAppStateCtx();
    const ref = useRef();

    useEffect(() => {
        return () => {
            notifications.value = null;
            notificationsListOpen.value = false;
            numberOfNotifications.value = 0;
        }
    }, []);

    const userNotifications = useGetUserNotifications(activeUser.value?.uid, activeUser.value?.notificationsReatAt);
    useEffect(() => {
        if(userNotifications.data){
            notifications.value = userNotifications.data;
            numberOfNotifications.value = userNotifications.data.length;
        }
    }, [userNotifications]);

    useEffect(() => {
        if(notificationsListOpen.value){
            setTimeout(() => {
                notifications.value.forEach(notification => {
                    updateArrayItem(notifications, {...notification, read: true, readAt: new Date()});
                });
                numberOfNotifications.value = 0;
            }, 1500);
        }
    }, [notificationsListOpen.value]);

    function handleOpen(){
        notificationsListOpen.value = true;
    }

    if(!notifications.value) return null;

    return (
        <>
            <Box mx={2}>
                <IconButton ref={ref} onClick={handleOpen} size="large">
                    <NotificationsIconWithCounter />
                </IconButton>
            </Box>
            <NotificationsPopOver open={notificationsListOpen} notifications={notifications} popOverRef={ref} />
        </>
    );
}

export default Notifications;