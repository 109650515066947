import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

const classes = {
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme => theme.palette.background.paper,
        outline: 'none',
        borderRadius: theme => theme.spacing(1),
        maxHeight: '98%',
        minWidth: '280px',
        maxWidth: '98%',
        boxShadow: theme => theme.shadows[5],
        overflow: 'auto',
        padding: theme => [theme.spacing(1), theme.spacing(2, 4, 3)],
    },
};
 
const StyledModal = ({children, id, innerBoxSx, style, modalRef, modalStyle, onClose, open, disableEscapeKeyDown}) => {
    modalStyle = {
        position: 'relative',
        ...modalStyle
    }
    if(style) modalStyle = {...modalStyle, ...style};

    const innerBoxClasses = [classes.paper];
    if(innerBoxSx) innerBoxClasses.push(innerBoxSx);

    return (
        <Modal
            ref={modalRef}
            id={id}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            sx={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            disableEscapeKeyDown={disableEscapeKeyDown}
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
            style={style}>
            <Fade in={open}>
                <Box
                    sx={innerBoxClasses}
                    style={modalStyle}
                >
                    {children}
                </Box>
            </Fade>
    </Modal>
    );
};

export default StyledModal;