import { useEffect, useRef, useState } from 'react';
import { Box, Button, Chip, Grid, IconButton, TextField, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { useTasksCtxAPI, useTasksCtxCompleteTaskView } from '../../../context/TasksContext';
import AddOrSubtractDial from '../../../components/AddOrSubtractDial';
import Window from '../../../components/Window';

const CompleteTaskDialog = () => {
    const { handleCompleteTaskConfirmButtonPress, hideCompleteTaskView } = useTasksCtxAPI();
    const { completeTaskViewOpen, completeTaskViewSelectedTask: selectedTask } = useTasksCtxCompleteTaskView();
    const [taskDescription, setTaskDescription] = useState('');
    const [taskMinutes, setTaskMinutes] = useState(0);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const descriptionRef = useRef();

    useEffect(() => {
        if(completeTaskViewOpen && selectedTask){
            setTaskDescription(selectedTask.task.subject);
            setTaskMinutes(selectedTask.task.estimatedMinutesRequiredToComplete || 0);
            setTimeout(() => {
                descriptionRef.current?.focus();    
            }, 500);
        }
    }, [completeTaskViewOpen, selectedTask]);

    useEffect(() => {
        let disabled = false;
        if(!taskDescription || !taskMinutes || taskMinutes === 0){
            disabled = true;
        }
        setSaveButtonDisabled(disabled);
    }, [taskDescription, taskMinutes]);

    const handleClose = () => {
        hideCompleteTaskView();
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            if(!saveButtonDisabled){
                handleCompleteTaskConfirmButtonPress(taskDescription, taskMinutes);
            }
        }
    };

    const handleTaskMinutesClick = (event, newValue) => {
        setTaskMinutes(newValue);
        if(event){
            if(event.detail === 2){ //double click
                if(!saveButtonDisabled){
                    handleCompleteTaskConfirmButtonPress(taskDescription, newValue);
                }
            }
        }
        
    }

    return (
        <Window open={completeTaskViewOpen} onClose={handleClose} hideBottomButtons>
            <Box pb={2}>
                <IconButton
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                    onClick={handleClose}
                    size="large"><ClearIcon /></IconButton>
            </Box>
            <Box mb={2}>
                <Typography variant="h6">Completar tarefa</Typography>
            </Box>
            <Box mb={2}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs>
                        <TextField
                            variant="standard"
                            fullWidth
                            inputProps={{ ref: descriptionRef, style: {fontSize: 22} }}
                            placeholder={'Tarefa'}
                            value={taskDescription}
                            onChange={(e) => setTaskDescription(e.target.value)}
                            onKeyPress={handleKeyPress} />
                    </Grid>
                </Grid>
            </Box>
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                            <AccessTimeIcon />
                        </Grid>
                        <Grid item>
                            <Chip label="15min" onClick={(e) => handleTaskMinutesClick(e, 15)} color={taskMinutes === 15 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="30min" onClick={(e) => handleTaskMinutesClick(e, 30)} color={taskMinutes === 30 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="45min" onClick={(e) => handleTaskMinutesClick(e, 45)} color={taskMinutes === 45 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="1h" onClick={(e) => handleTaskMinutesClick(e, 60)} color={taskMinutes === 60 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="1h30" onClick={(e) => handleTaskMinutesClick(e, 90)} color={taskMinutes === 90 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="2h" onClick={(e) => handleTaskMinutesClick(e, 120)} color={taskMinutes === 120 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="3h" onClick={(e) => handleTaskMinutesClick(e, 180)} color={taskMinutes === 180 ? 'primary' : 'default'} />
                        </Grid>
                        <Grid item>
                            <AddOrSubtractDial
                                subtractDisabled={!taskMinutes || taskMinutes === 0}
                                subtractOnClick={() => handleTaskMinutesClick(null, taskMinutes - 15)}
                                number={taskMinutes || 0}
                                addOnClick={() => handleTaskMinutesClick(null, taskMinutes + 15)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AccessTimeIcon />}
                        disabled={saveButtonDisabled}
                        onClick={() => handleCompleteTaskConfirmButtonPress(taskDescription, taskMinutes)}
                    >Lançar</Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={!taskDescription}
                        variant="contained"
                        color="primary"
                        startIcon={<AssignmentOutlinedIcon />}
                        onClick={() => handleCompleteTaskConfirmButtonPress(taskDescription)}
                    >Completar sem lançar no timesheet</Button>
                </Grid>
            </Grid>
        </Window>
    );
}

export default CompleteTaskDialog;