import { useEffect, useState } from 'react';
import { and, collection, collectionGroup, onSnapshot, or, query, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';

const useGetYearExpenses = (shouldSetListener) => {
    useSignals();
    const { selectedWorkspace, selectedYear } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, expenses: null, year: null, yearExpenses: null };
    const [hookState, setHookState] = useState(defaultHookState);
    const [expenses, setExpenses] = useState(null);
    const [yearExpenses, setYearExpenses] = useState({ year: null, yearExpenses: null });

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.role !== 'operator' && selectedYear.value){
            setHookState(prevState => ({...prevState, loading: true, expenses: null }));
            setExpenses(null);
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'expenses'),
                    and(
                        where('deleted', '==', false),
                        where('workspaceId', '==', selectedWorkspace.value.uid),
                        or(
                            where(`showAsOfYear`, '==', false),
                            where(`showAsOfYear`, '<=', selectedYear.value)
                        ),
                        or(
                            where(`hideAsOfYear`, '==', false),
                            where(`hideAsOfYear`, '>', selectedYear.value)
                        )
                    )
                ),
                (snapshot) => {
                    const dataArray = [];
                    snapshot.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            uid: snapshot.id,
                        });
                    });
                    setExpenses(dataArray);
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value, selectedYear.value]);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.role !== 'operator' && selectedYear.value){
            setHookState(prevState => ({...prevState, loading: true, year: null, yearExpenses: null }));
            setYearExpenses({year: null, yearExpenses: null});
            const unsubscribe = onSnapshot(
                query(
                    collectionGroup(db, `expenses_years`),
                    where('year', '==', selectedYear.value)
                ),
                (snapshot) => {
                    const dataArray = [];
                    snapshot.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            uid: snapshot.id,
                        });
                    });
                    setYearExpenses({year: selectedYear.value, yearExpenses: dataArray});
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value, selectedYear.value]);

    useEffect(() => {
        if(expenses && yearExpenses){
            setHookState(prevState => ({ ...prevState, loading: false, expenses, ...yearExpenses }));
        }
    }, [expenses, yearExpenses]);

    return hookState;
};

export default useGetYearExpenses;