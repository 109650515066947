import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';
import { useAppStateCtx } from '../context/AppState';

const useGetDocumentComments = (documentId) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: [] };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(documentId && selectedWorkspace.value){
            setHookState(prevState => ({...prevState, loading: true}));
            const unsubscribe = onSnapshot(
                query(collection(db, `documents/${documentId}/comments`), orderBy('createdAt', 'asc')),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            uid: snapshot.id,
                        })
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        } else {
            setHookState(defaultHookState);
        }
    }, [documentId, selectedWorkspace.value]);

    return hookState;
};

export default useGetDocumentComments;