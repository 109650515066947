import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import WorkspaceCustomField from '../../../../../classes/WorkspaceCustomField';
import MultipleAutocomplete from '../../../../../components/MultipleAutocomplete';
import Select from '../../../../../components/Select';
import Window from '../../../../../components/Window';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';

const AddUserFieldWindow = ({open, selectedField}) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { mappedWorkspaceDocumentTemplates } = useAppStateCtxUtils();
    const nameRef = useRef();
    const defaultField = useMemo(() => ({
        name: '',
        template: [],
        type: 'select',
        options: []
    }), []);
    const [field, set_field] = useState(defaultField);
    const [fieldName, set_fieldName] = useState('');
    const [fieldType, set_fieldType] = useState('string');
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const clearForm = useCallback(() => {
        set_field(defaultField);
        set_fieldName('');
        set_fieldType('string');
    }, [defaultField]);

    useEffect(() => {
        if(open.value){
            if(selectedField.value){

                let fieldTemplate = [];
                if(!Array.isArray(selectedField.value.template)){
                    const currentFieldTemplate = mappedWorkspaceDocumentTemplates.value.find(template => template.value === fieldTemplate);
                    if(currentFieldTemplate) fieldTemplate = [currentFieldTemplate];
                } else {
                    const currentFieldTemplate = mappedWorkspaceDocumentTemplates.value.filter(template => selectedField.value.template.includes(template.value));
                    if(currentFieldTemplate && currentFieldTemplate.length >= 1){
                        fieldTemplate = currentFieldTemplate;
                    }
                }
                set_field({
                    ...selectedField.value,
                    template: fieldTemplate,
                });
                set_fieldName(selectedField.value.name || '');
                set_fieldType(selectedField.value.type || '');
            } else {
                clearForm();
            }
            setTimeout(() => {
                nameRef.current?.focus();    
            }, 500);
        }
    }, [open.value, selectedField.value, mappedWorkspaceDocumentTemplates.value, clearForm]);

    useEffect(() => {
        let currentSubmitButtonDisabled = false;
        if(!fieldName || !fieldType || !field.template || field.template.length === 0){
            currentSubmitButtonDisabled = true;
        }
        if(fieldType === 'select'){
            if(!field.options || field.options.length === 0) currentSubmitButtonDisabled = true;
        }
        if(field.options){
            const options = [];
            field.options.forEach(option => {
                if(!option){
                    currentSubmitButtonDisabled = true;
                } else {
                    if(options.includes(option)) currentSubmitButtonDisabled = true;
                    options.push(option);
                }
            });
        }
        setSubmitButtonDisabled(currentSubmitButtonDisabled);
    }, [fieldName, field, fieldType]);

    const handleFieldTypeChange = (newValue) => {
        set_fieldType(newValue);
        if(newValue === 'select'){
            if(!field.options || field.options.length === 0) set_field(prevState => ({...prevState, options: ['']}));
        }
    };
    const handleFieldTemplateChange = (newValue) => {
        set_field(prevState => ({...prevState, template: newValue}))
    };

    const handleSubmit = async () => {
        const toastId = toast.loading('Salvando...', { position: 'top-center' });
        setSubmitButtonDisabled(true);

        if(!selectedField.value){

            const newCustomField = new WorkspaceCustomField({
                name: fieldName,
                options: field.options,
                template: field.template.map(template => template.value),
                type: fieldType
            });
            const res = await newCustomField.createCustomField(selectedWorkspace.value.uid);
            setSubmitButtonDisabled(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, render: res.error.message, type: 'error', isLoading: false });
            }

        } else {
            const res = await selectedField.value.update({
                updates: {
                    name: fieldName,
                    options: field.options,
                    template: field.template.map(template => template.value),
                    type: fieldType
                },
                workspaceId: selectedWorkspace.value.uid
            });
            setSubmitButtonDisabled(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, render: res.error.message, type: 'error', isLoading: false });
            }
        }

        handleClose();
        clearForm();
        toast.update(toastId, { autoClose: 3000, render: `Você salvou a informação especial "${fieldName}"`, type: 'success', isLoading: false });
    };

    const handleClose = () => {
        open.value = false;
    };

    return (
        <Window
            open={open.value} onClose={handleClose}
            title={selectedField.value ? `Editando informação especial "${selectedField.value.name}"` : 'Nova informação especial'}
            handleSubmitButtonClick={handleSubmit}
            submitButtonDisabled={submitButtonDisabled}
        >
            <Box mb={1}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} sm={6} md={9}>
                        <TextField
                            variant="standard"
                            disabled={selectedField.value && !['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)}
                            fullWidth
                            inputProps={{ref: nameRef}}
                            label="Nome"
                            value={fieldName}
                            onChange={(e) => set_fieldName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Select
                            label="Tipo"
                            options={[
                                {value: 'string', label: 'Texto'},
                                {value: 'boolean', label: 'Verdadeiro ou falso'},
                                {value: 'select', label: 'Múltipla escolha'},
                            ]}
                            value={fieldType} onChange={(e) => handleFieldTypeChange(e.target.value)}
                        />
                    </Grid>
                    {
                        (fieldType === 'string' || fieldType === 'select') &&
                        <Grid item xs={12}>
                            {
                                field.options.map((option, optionIndex) => (
                                    <Box key={optionIndex}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs>
                                                <TextField
                                                    variant="standard"
                                                    placeholder={`Opção ${optionIndex + 1}`}
                                                    fullWidth
                                                    value={option}
                                                    onChange={(e) => set_field(prevState => ({...prevState, options: [...prevState.options.slice(0, optionIndex), e.target.value, ...prevState.options.slice(optionIndex + 1)]}))} />
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    onClick={(e) => set_field(prevState => ({...prevState, options: [...prevState.options.slice(0, optionIndex), ...prevState.options.slice(optionIndex + 1)]}))}
                                                    size="large"><ClearIcon /></IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))
                            }
                            <Box style={{textAlign: 'center'}}>
                                <Button startIcon={<AddIcon />} onClick={() => set_field(prevState => ({...prevState, options: [...prevState.options, '']}))}>Opção</Button>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <MultipleAutocomplete
                            label="Matriz"
                            options={mappedWorkspaceDocumentTemplates.value} getOptionLabel={(option) => option.labelWithOrderNumber}
                            value={field.template} onChange={(_, newValue) => handleFieldTemplateChange(newValue)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Window>
    );
};

export default AddUserFieldWindow;