import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import AlertDialog from './AlertDialog';

import { useAppStateCtx } from '../context/AppState';
import { useDocumentsAPI, useDocumentsCtxDeleteDocumentView } from '../context/DocumentsContext';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const DeleteDocDialog = () => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const { hideDeleteDocumentView } = useDocumentsAPI();
    const { deleteDocumentViewOpen, deleteDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxDeleteDocumentView();

    const handle_okClick = async () => {
        handleClose();

        const toastId = toast.loading(`Excluindo o documento "${selectedDocument.name}"...`);

        const res = await selectedDocument.update({
            updates: {
                deleted: true,
                deletedAt: new Date().toISOString(),
                deletedBy: activeUser.value.uid
            }
        });
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: `O documento "${selectedDocument.name}" foi excluído.`, type: 'success' });
    };

    const handleClose = () => {
        hideDeleteDocumentView();
    };
    
    return (
        <AlertDialog
            id="delete-doc-dialog"
            open={deleteDocumentViewOpen} onClose={handleClose}
            text={`Você quer cancelar o documento "${selectedDocument?.name}"?`}
            okButtonOnClick={handle_okClick}
        />
    );
}

export default DeleteDocDialog;