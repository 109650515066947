import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import ShortUniqueId from 'short-unique-id';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import Zoom from '@mui/material/Zoom';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

import ActiveProjectsWorkTypeBoxes from './components/ActiveProjectsWorkTypeBoxes/ActiveProjectsWorkTypeBoxes';
import NewActiveProject from './components/NewActiveProject';

import ActiveProjectsBoard from './components/ActiveProjectsBoard';

import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import SearchEngineTextField from '../../../../components/SearchEngineTextField';
import ViewBox from '../../../../components/ViewBox';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useTasksCtxAPI, useTasksCtxFilters } from '../../../../context/TasksContext';
import batchWrite from '../../../../firebase/firestore/batchWrite';
import { escapeRegex, removeDiacritics } from '../../../../utils/common';
import { ACTIVE_PROJECT_TYPES, ERROR_MESSAGE_CHANGES_UNDONE } from '../../../../utils/constants';
import { sortByKey } from '../../../../utils/filters';

const classes = {
    fab: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
};

const activeProjectStatusOptions = [
    {value: 'accepted', label: 'Contratado', icon: <CheckCircleOutlineOutlinedIcon />},
    {value: 'pending', label: 'Pendente', icon: <PendingOutlinedIcon />}
];

const topHeight = 56;

const loading = signal(true);
const visibleBoards = signal([]);

const ActiveProjectsFilters = () => {
    useSignals();
    const filters = useTasksCtxFilters();
    const { setFilters } = useTasksCtxAPI();

    const handleFilterClick = (activeProjectType) => {
        setFilters(prevState => ({...prevState, workType: activeProjectType.value}));
    }

    const handleSearch = (newValue) => {
        setFilters(prevState => ({
            ...prevState,
            text: newValue
        }));
    };

    const handleClearSearchClick = () => {
        handleSearch('');
    };

    const handleClearFilters = () => {
        setFilters(prevState => ({...prevState, workType: ''}));
        handleClearSearchClick();
    }

    if(!loading.value){
        return (
            <Box
                sx={{
                    height: `${topHeight}px`,
                }}
            >
                <Grid container spacing={1} alignItems="center" justifyContent={{ xs: 'flex-start', md: 'center' }} wrap="nowrap" sx={{overflowX: 'auto',}}>
                    <Grid sx={{ maxWidth: '211.16px' }}>
                        <SearchEngineTextField onSearch={handleSearch} placeholder="Pesquisar" />
                    </Grid>
                    {
                        filters.text &&
                        <Grid sx={{ maxWidth: '100px' }}>
                            <Chip color="primary" label={filters.text} onDelete={handleClearSearchClick} />
                        </Grid>
                    }
                    <ActiveProjectsWorkTypeBoxes
                        activeProjectTypes={ACTIVE_PROJECT_TYPES}
                        onClick={handleFilterClick}
                        style={(activeProjectType) => !filters.workType || filters.workType === activeProjectType.value || filters.workType === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                        textStyle={(activeProjectType) => !filters.workType || filters.workType === activeProjectType.value || filters.workType === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                    />
                    <Grid>
                        <Zoom in={!!filters.text || !!filters.workType}>
                            <IconButton size="small" onClick={handleClearFilters}><ClearIcon /></IconButton>
                        </Zoom>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    return null;
}

const Boards = forwardRef(({ boxFilterFn, onBoxClick, onDragEnd }, ref) => {
    useSignals();

    return (
        <div ref={ref} style={{ height: `calc(100% - ${topHeight}px)` }}>

            {
                loading.value
                ? <LoaderEllipsis />
                :
                <DragDropContext onDragEnd={onDragEnd}>
                    <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                        <Grid size="grow" container spacing={1} alignItems="flex-start" wrap="nowrap" style={{height: '100%', overflowX: 'auto'}}
                            
                        >
                            {
                                visibleBoards.value.map((activeProject) => (
                                    <ActiveProjectsBoard
                                        key={activeProject.uid}
                                        activeProject={activeProject}
                                        boardsLength={visibleBoards.value.length}
                                        boxFilterFn={boxFilterFn}
                                        handleBoxClick={onBoxClick}
                                    />
                                ))
                            }
                        </Grid>
                    </Grid>
                </DragDropContext>
            }

        </div>
    );
}) 

const newActiveProjectViewOpen = signal(false);

const TasksAccounts = () => {
    useSignals();
    const { selectedWorkspace, workspaceActiveProjectBoards, workspaceOperators } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const shouldUpdateWorkspaceActiveProjects = useRef(true);
    const shouldScrollToBottomAfterUpdate = useRef(false);
    const boardsContainerRef = useRef(null);

    const [activeProjectViewSelectedBox, setActiveProjectViewSelectedBox] = useState(null);

    useEffect(() => {
        setFirestoreListener('workspaceActiveProjectBoards');
    }, []);

    useEffect(() => {
        if(shouldUpdateWorkspaceActiveProjects.current && workspaceActiveProjectBoards.value && workspaceOperators.value){
            let currentActiveClientActiveProjects = workspaceActiveProjectBoards.value.sort(sortByKey('orderNumber'));
            const currentActiveProjects = [{uid: 'newActiveProjects', screenName: 'NOVOS PROJETOS', activeProjects: [] }];
            workspaceOperators.value.forEach(operator => {
                currentActiveProjects.push({
                    uid: operator.uid,
                    img: operator.photoUrl,
                    name: operator.name,
                    activeProjects: [],
                });
            });
            currentActiveClientActiveProjects.forEach(activeProject => {
                const boardIndex = currentActiveProjects.findIndex(board => board.uid === activeProject.operatorId);
                if(boardIndex !== -1){
                    currentActiveProjects[boardIndex].activeProjects.push(activeProject);
                }
            });

            visibleBoards.value = currentActiveProjects;
            loading.value = false;
        }
        shouldUpdateWorkspaceActiveProjects.current = true;
    }, [workspaceActiveProjectBoards.value, workspaceOperators.value]);

    useLayoutEffect(() => {
        if(shouldScrollToBottomAfterUpdate.current){
            shouldScrollToBottomAfterUpdate.current = false;
            const foundBoard = document.getElementById('newActiveProjects');
            if(foundBoard) foundBoard.scrollTo(0, foundBoard.scrollHeight);
        }
    }, [workspaceActiveProjectBoards.value]);

    const handleActiveProjectDragEnd = (data) => {
        const { destination, source, draggableId } = data;

        if(!destination) return;

        if(destination.droppableId === source.droppableId && destination.index === source.index) return;
        
        shouldUpdateWorkspaceActiveProjects.current = false;
        
        //UPDATE VISIBLE BOARDS
        const prevState = [...visibleBoards.value];
        const bulkActions = [];
        const nextState = [...prevState];
        
        // SOURCE BOARD
        const sourceBoardIndex = nextState.findIndex(board => board.uid === source.droppableId);
        const deletedTask = nextState[sourceBoardIndex].activeProjects[source.index];
        nextState[sourceBoardIndex].activeProjects = [
            ...nextState[sourceBoardIndex].activeProjects.slice(0, source.index),
            ...nextState[sourceBoardIndex].activeProjects.slice(source.index + 1)
        ].map((nextStateActiveProject, nextStateActiveProjectIndex) => {
            const newOrderNumber = nextStateActiveProjectIndex;
            const prevStateSameActiveProject = prevState[sourceBoardIndex].activeProjects.find(prevStateActiveProject => prevStateActiveProject.uid === nextStateActiveProject.uid);
            if(prevStateSameActiveProject){
                if(prevStateSameActiveProject.orderNumber !== nextStateActiveProjectIndex){
                    nextStateActiveProject.orderNumber = newOrderNumber;
                    bulkActions.push({activeProjectId: nextStateActiveProject.uid, action: 'update', updates: { orderNumber: newOrderNumber }});
                }
            }
            return nextStateActiveProject;
        });

        // DESTINATION BOARD
        const destinationBoardIndex = nextState.findIndex(board => board.uid === destination.droppableId);
        const updates = {operatorId: destination.droppableId, orderNumber: destination.index};
        const updatedActiveProject = { ...deletedTask, ...updates };
        nextState[destinationBoardIndex].activeProjects = [
            ...nextState[destinationBoardIndex].activeProjects.slice(0, destination.index),
            updatedActiveProject,
            ...nextState[destinationBoardIndex].activeProjects.slice(destination.index)
        ].map((nextStateActiveProject, nextStateActiveProjectIndex) => {
            const newOrderNumber = nextStateActiveProjectIndex;
            const prevStateSameActiveProject = prevState[destinationBoardIndex].activeProjects.find(prevStateActiveProject => prevStateActiveProject.uid === nextStateActiveProject.uid);
            if(prevStateSameActiveProject){
                if(prevStateSameActiveProject.orderNumber !== nextStateActiveProjectIndex){
                    nextStateActiveProject.orderNumber = newOrderNumber;
                    bulkActions.push({activeProjectId: nextStateActiveProject.uid, action: 'update', updates: { orderNumber: newOrderNumber }});
                }
            }
            return nextStateActiveProject;
        });

        bulkActions.push({activeProjectId: draggableId, action: 'update', updates});

        bulkActionsAction(bulkActions, prevState);

        visibleBoards.value = nextState;
    };

    const bulkActionsAction = async (updates, prevState) => {
        const batchWriteRes = await batchWrite(updates.map(update => ({ operation: update.action === 'create' ? 'set' : update.action, path: `workspaces/${selectedWorkspace.value.uid}/active_projects/${update.activeProjectId}`, updates: update.updates })));
        if(batchWriteRes.error){
            visibleBoards.value = prevState
            toast(ERROR_MESSAGE_CHANGES_UNDONE, { type: 'error' });
        }
    };

    const handleAddActiveProjectConfirm = (activeProject) => {
        const activeProjectEndTime = activeProject.endTime;
        const activeProjectName = activeProject.name;
        const activeProjectNotes = activeProject.notes;
        const activeProjectShouldShowOnTimeline = activeProject.shouldShowOnTimeline;
        const activeProjectStartTime = activeProject.startTime;
        const activeProjectStatus = activeProject.status;
        const activeProjectWorkTypeId = activeProject.workType;
        
        if(activeProject.uid){
            shouldUpdateWorkspaceActiveProjects.current = false;

            //UPDATE VISIBLE BOARDS
            const prevState = [...visibleBoards.value];
            const bulkActions = [
                {
                    activeProjectId: activeProject.uid, action: 'update',
                    updates: {
                        endTime: activeProjectEndTime,
                        name: activeProjectName,
                        notes: activeProjectNotes,
                        shouldShowOnTimeline: activeProjectShouldShowOnTimeline,
                        startTime: activeProjectStartTime,
                        status: activeProjectStatus,
                        workType: activeProjectWorkTypeId
                    }
                }
            ];
            bulkActionsAction(bulkActions, prevState);
            const activeProjectBoardIndex = prevState.findIndex(board => board.uid === activeProject.operatorId);
            const activeProjectIndex = prevState[activeProjectBoardIndex].activeProjects.findIndex(boardActiveProject => boardActiveProject.uid === activeProject.uid);
            return visibleBoards.value = [
                ...prevState.slice(0, activeProjectBoardIndex),
                {
                    ...prevState[activeProjectBoardIndex],
                    activeProjects: [
                        ...prevState[activeProjectBoardIndex].activeProjects.slice(0, activeProjectIndex),
                        activeProject,
                        ...prevState[activeProjectBoardIndex].activeProjects.slice(activeProjectIndex + 1),
                    ]
                },
                ...prevState.slice(activeProjectBoardIndex + 1)
            ];
        }

        const newActiveProjectsBoardIndex = 0;
        // @ts-ignore
        const newActiveProjectId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
        const foundActiveProjectType = ACTIVE_PROJECT_TYPES.find(activeProjectType => activeProjectType.value === activeProjectWorkTypeId);
        
        shouldUpdateWorkspaceActiveProjects.current = false;
        shouldScrollToBottomAfterUpdate.current = true;

        //UPDATE VISIBLE BOARDS
        const prevState = [...visibleBoards.value];
        const newActiveProject = {
            uid: newActiveProjectId,
            endTime: activeProjectEndTime,
            name: activeProjectName,
            notes: activeProjectNotes,
            shouldShowOnTimeline: activeProjectShouldShowOnTimeline,
            startTime: activeProjectStartTime,
            status: activeProjectStatus,
            workType: activeProjectWorkTypeId,
            operatorId: 'newActiveProjects',
            orderNumber: prevState[newActiveProjectsBoardIndex].activeProjects.length,
            backgroundColor: foundActiveProjectType.color,
            viewed: true, viewedAt: new Date().toISOString()
        };
        const bulkActions = [
            { activeProjectId: newActiveProjectId, action: 'create', updates: newActiveProject }
        ];
        bulkActionsAction(bulkActions, prevState);
        return visibleBoards.value = [
            ...prevState.slice(0, newActiveProjectsBoardIndex),
            {
                ...prevState[newActiveProjectsBoardIndex],
                activeProjects: [...prevState[newActiveProjectsBoardIndex].activeProjects, newActiveProject]
            },
            ...prevState.slice(newActiveProjectsBoardIndex + 1)
        ];
    };

    const handleAddActiveProjectClick = () => {
        newActiveProjectViewOpen.value = true;
    };

    const handleDeleteActiveProjectConfirm = (activeProject) => {
        shouldUpdateWorkspaceActiveProjects.current = false;
        
        //UPDATE VISIBLE BOARDS
        const prevState = [...visibleBoards.value];
        const activeProjectBoardIndex = prevState.findIndex(board => board.uid === activeProject.operatorId);
        if(activeProjectBoardIndex !== -1){
            const activeProjectIndex = prevState[activeProjectBoardIndex].activeProjects.findIndex(boardActiveProject => boardActiveProject.uid === activeProject.uid);
            if(activeProjectIndex !== -1){
                const bulkActions = [
                    {activeProjectId: activeProject.uid, action: 'delete'}
                ];

                const nextState = [
                    ...prevState.slice(0, activeProjectBoardIndex),
                    {
                        ...prevState[activeProjectBoardIndex],
                        activeProjects: [
                            ...prevState[activeProjectBoardIndex].activeProjects.slice(0, activeProjectIndex),
                            ...prevState[activeProjectBoardIndex].activeProjects.slice(activeProjectIndex + 1),
                        ].map((nextStateActiveProject, nextStateActiveProjectIndex) => {
                            const newOrderNumber = nextStateActiveProjectIndex;
                            const prevStateSameActiveProject = prevState[activeProjectBoardIndex].activeProjects.find(prevStateActiveProject => prevStateActiveProject.uid === nextStateActiveProject.uid);
                            if(prevStateSameActiveProject){
                                if(prevStateSameActiveProject.orderNumber !== nextStateActiveProjectIndex){
                                    nextStateActiveProject.orderNumber = newOrderNumber;
                                    bulkActions.push({activeProjectId: nextStateActiveProject.uid, action: 'update', updates: { orderNumber: newOrderNumber }});
                                }
                            }
                            return nextStateActiveProject;
                        })
                    },
                    ...prevState.slice(activeProjectBoardIndex + 1)
                ];

                bulkActionsAction(bulkActions, prevState);

                visibleBoards.value = nextState;
            }
        }
    };

    const filterText = (activeProject, newValue) => {
        if(newValue){
            const regEx = new RegExp(escapeRegex(removeDiacritics(newValue)).toUpperCase()); 
            return regEx.test(removeDiacritics(activeProject.name).toUpperCase());
        }
        return true;
    }

    const filterWorkType = (activeProject, newValue) => {
        if(newValue){
            if(newValue === activeProject.workType) {
                return true;
            }
            return false;
        }
        return true;
    };

    const boxFilterFn = useCallback((activeProject, filters) => {
        return !(filterText(activeProject, filters.text) && filterWorkType(activeProject, filters.workType));
    }, []);

    const handleBoxClick = useCallback((activeProject) => {
        setActiveProjectViewSelectedBox(activeProject);
        newActiveProjectViewOpen.value = true;
    }, []);

    return (
        <ViewBox style={{height: '100%', paddingTop: 8, paddingBottom: 8}}>
            <ActiveProjectsFilters />
            <Boards ref={boardsContainerRef} boxFilterFn={boxFilterFn} onBoxClick={handleBoxClick} onDragEnd={handleActiveProjectDragEnd} />

            <NewActiveProject
                newActiveProjectViewOpen={newActiveProjectViewOpen}
                activeProjectViewSelectedBox={activeProjectViewSelectedBox} setActiveProjectViewSelectedBox={setActiveProjectViewSelectedBox}
                activeProjectStatusOptions={activeProjectStatusOptions}
                activeProjectTypes={ACTIVE_PROJECT_TYPES}
                handleAddActiveProjectConfirm={handleAddActiveProjectConfirm}
                handleDeleteActiveProjectConfirm={handleDeleteActiveProjectConfirm}
            />

            <Zoom in={true}>
                <Fab
                    variant="circular" color="primary"
                    sx={classes.fab}
                    onClick={handleAddActiveProjectClick}
                >
                    <AddIcon />
                </Fab>
            </Zoom>

        </ViewBox>
    );
};

export default TasksAccounts;