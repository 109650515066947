import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { doc, collection } from 'firebase/firestore';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from './AppState';

import Task from '../classes/Task';
import TimesheetRecord from '../classes/TimesheetRecord';
import { db } from '../firebase/config';
import batchWrite from '../firebase/firestore/batchWrite';
import useGetWorkspaceTasks from '../hooks/useGetWorkspaceTasks';
import CompleteTaskWindow from '../pages/Operator/Tasks/CompleteTaskDialog';
import TaskWindow from '../pages/Operator/Tasks/TaskWindow';
import { ERROR_MESSAGE_CHANGES_UNDONE, SUCCESS_MESSAGE_SAVED } from '../utils/constants';

const TasksCtxAPI = createContext();

const TasksCtxActiveUserTasks = createContext();
const TasksCtxCompleteTaskView = createContext();
const TasksCtxDragDisabled = createContext();
const TasksCtxFilters = createContext();
const TasksCtxNewTasks = createContext();
const TasksCtxSelectedTask = createContext();
const TasksCtxTasks = createContext();
const TasksCtxTaskView = createContext();
const TasksCtxTeamTasks = createContext();

const defaultFiltersValue = {client: null, project: ''};
const defaultState = {
    activeProjectBoards: [],
    activeUserTasks: null,
    completeTaskViewOpen: false,
    completeTaskViewSelectedTask: null,
    dragDisabled: false,
    filters: defaultFiltersValue,
    newTasks: null,
    nextActiveUserTask: null,
    shouldSetTasksSnapshot: false,
    tasks: null,
    taskViewOpen: false,
    taskViewSelectedTask: null,
    teamTasks: null,
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'HIDE COMPLETE TASK VIEW':
            return { ...state, completeTaskViewOpen: false };
        case 'HIDE TASK VIEW':
            return { ...state, taskViewOpen: false };
        case 'SET STATE':
            let newValue = payload.value;
            if(typeof newValue === 'function'){
                newValue = newValue(state[payload.key]);
            }
            return { ...state, [payload.key]: newValue };
        case 'SHOW COMPLETE TASK VIEW':
            return {
                ...state,
                completeTaskViewOpen: true,
                completeTaskViewSelectedTask: payload,
                completeTaskViewSelectedTaskUpdatedAt: new Date()
            };
        case 'SHOW TASK VIEW':
            return {
                ...state,
                taskViewOpen: !state.taskViewOpen,
                taskViewSelectedTask: payload.clickedTask,
                taskViewSelectedTaskUpdatedAt: new Date()
            };
        default: return state;
    }
};

const TasksProvider = ({children}) => {
    useSignals();
    const { activeUser, selectedWorkspace, workspaceClients, workspaceOperators, workspaceProjects } = useAppStateCtx();
    
    const activeUserBoardsIds = [
        'activeUserHighPriorityTasks',
        'activeUserMediumPriorityTasks',
        'activeUserLowPriorityTasks',
        'activeUserUnreadTasks'
    ];
    const activeUserBoardsPriorityLevels = {
        activeUserHighPriorityTasks: 2,
        activeUserMediumPriorityTasks: 1,
        activeUserLowPriorityTasks: 0,
        activeUserUnreadTasks: 1
    };
    const updatedTasksAt = useRef(null);
    const shouldOrganizeBoards = useRef(true);

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const shouldSetSnapshot = state.shouldSetTasksSnapshot && selectedWorkspace.value?.modules.includes('tasks') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role);
    const workspaceTasks = useGetWorkspaceTasks(shouldSetSnapshot);
    useEffect(() => {
        if(workspaceTasks.data && (!updatedTasksAt.current || workspaceTasks.tasksUpdatedAt)){
            setTasks(prevState => {
                if(!prevState || workspaceTasks.tasksUpdatedAt !== updatedTasksAt.current){
                    shouldOrganizeBoards.current = true;
                }
                return workspaceTasks.data || [];
            });
        }
    }, [workspaceTasks, activeUser.value?.uid]);

    const bulkUpdateTasks = async (updates, errorCallback) => {
        const now = new Date();
        const nowISOString = now.toISOString();
        updates.push({
            operation: 'set',
            documentPath: `workspaces/${selectedWorkspace.value.uid}/real_time/tasks`,
            updates: {
                updatedAt: nowISOString,
            }
        })
        updatedTasksAt.current = nowISOString;
        const batchWriteRes = await batchWrite(updates.map(update => ({ operation: update.operation || 'update', path: update.documentPath, updates: update.updates })));
        if(batchWriteRes.error){
            toast(ERROR_MESSAGE_CHANGES_UNDONE, { type: 'error' });
            if(errorCallback) errorCallback();
        }
    }

    const deleteTeamTask = (updatedBoardId, deletedTaskIndex, deletedTaskId) => {
        setTeamTasks(prevState => {
            const updatedBoardIndex = prevState.findIndex(board => board.uid === updatedBoardId);
            const updatedBoard = prevState[updatedBoardIndex];
            if(deletedTaskIndex === null) deletedTaskIndex = updatedBoard.tasks.findIndex(task => task.uid === deletedTaskId);
            return [
                ...prevState.slice(0, updatedBoardIndex),
                {
                    ...prevState[updatedBoardIndex],
                    tasks: [
                        ...prevState[updatedBoardIndex].tasks.slice(0, deletedTaskIndex),
                        ...prevState[updatedBoardIndex].tasks.slice(deletedTaskIndex + 1)
                    ]
                },
                ...prevState.slice(updatedBoardIndex + 1)
            ];
        });
    };

    const handleAddTaskButtonPress = () => {
        showTaskView(null);
    };

    const handleTaskChangeButtonPress = (e, task) => {
        if(task.viewed){
            showTaskView(task);
        }
    };

    const handleCompleteTaskConfirmButtonPress = useCallback(async (taskDescription, taskMinutes) => {
        hideCompleteTaskView();
        const prevActiveUserTasks = {...state.activeUserTasks};
        const boardId = state.completeTaskViewSelectedTask.boardId;
        const selectedTask = state.completeTaskViewSelectedTask.task;
        
        setTimeout(async () => {
            let timesheetId = '', timesheetMinutes = 0;
            let newTimesheetRecord;
            if(taskMinutes){
                const timesheetRef = doc(collection(db, `timesheet_records`));
                timesheetId = timesheetRef.id;
                timesheetMinutes = taskMinutes;
                newTimesheetRecord = new TimesheetRecord({
                    createdAt: new Date().toISOString(),
                    createdBy: activeUser.value.uid,
                    description: taskDescription,
                    minutes: taskMinutes,
                    projectId: selectedTask.projectId,
                    startAt: moment().subtract(taskMinutes, 'minutes').toISOString(),
                    taskId: selectedTask.uid,
                    workspaceId: selectedTask.workspaceId,
                });
            }
            const bulkUpdates = [
                {
                    documentPath: `tasks/${selectedTask.uid}`,
                    updates: {
                        completed: true, completedAt: new Date().toISOString(), subject: taskDescription, timesheetId, timesheetMinutes
                    }
                }
            ];
            if(newTimesheetRecord){
                const {uid: _, ...newTimesheetRecordWithoutUid} = newTimesheetRecord;
                bulkUpdates.push({
                    documentPath: `timesheet_records/${timesheetId}`,
                    operation: 'set',
                    updates: newTimesheetRecordWithoutUid
                });
            }
            setActiveUserTasks(prevState => {
                const taskIndex = prevState[boardId].findIndex(task => task.uid === selectedTask.uid);

                let updatedExistingTaskIndex = 0;
                prevState[boardId].forEach((task, prevTaskIndex) => {
                    if(task.uid !== selectedTask.uid){
                        prevState[boardId][prevTaskIndex].orderNumber = updatedExistingTaskIndex;
                        bulkUpdates.push({ documentPath: `tasks/${task.uid}`, updates: { orderNumber: updatedExistingTaskIndex } });
                        updatedExistingTaskIndex++;
                    }
                });

                bulkUpdateTasks(bulkUpdates,
                    () => {
                        setActiveUserTasks(prevActiveUserTasks);
                        toast(ERROR_MESSAGE_CHANGES_UNDONE, { type: 'error' });
                    });

                return {
                    
                    ...prevState,
                    [boardId]: [
                        ...prevState[boardId].slice(0, taskIndex),
                        ...prevState[boardId].slice(taskIndex + 1)
                    ]
                };
            });
    
            // const completeTaskRes = await selectedTask.completeTask({ activeUserId: activeUser.uid, minutes: taskMinutes, subject: taskDescription })
            // if(completeTaskRes.error){
            //     setActiveUserTasks(prevActiveUserTasks);
            //     toast(ERROR_MESSAGE_CHANGES_UNDONE, { type: 'error' });
            // }
        }, 300);
    }, [activeUser.value?.uid, activeUser.value?.name, state.activeUserTasks, state.completeTaskViewSelectedTask]);

    const handleTaskDoneButtonPress = (e, task, boardId, taskIndex) => {
        e.stopPropagation();
        showCompleteTaskView({ boardId, task, taskIndex });
    };

    const handleTaskDragEnd = useCallback(async (data) => {
        const { destination, source, draggableId } = data;

        if(!destination) return;

        if(destination.droppableId === source.droppableId && destination.index === source.index) return;
        
        const prevActiveUserTasks = {...state.activeUserTasks};
        const prevTeamTasks = [...state.teamTasks];

        if(activeUserBoardsIds.includes(destination.droppableId)){ // dropped in my board
            if(activeUserBoardsIds.includes(source.droppableId)){
                //sort
                setActiveUserTasks(prevState => {
                    const bulkUpdates = [];
                    const sourceBoardTasks = prevState[source.droppableId];
                    const sameBoard = source.droppableId === destination.droppableId;
                    const draggedTask = {...sourceBoardTasks[source.index]};
                    const updatedSourceBoardTasks = [];
                    let updatedSourceBoardTaskIndex = 0;
                    const addSameBoardDraggedTask = () => {
                        if(sameBoard && updatedSourceBoardTaskIndex === destination.index){
                            draggedTask.orderNumber = updatedSourceBoardTaskIndex;
                            updatedSourceBoardTasks.push(draggedTask);
                            bulkUpdates.push({ documentPath: `tasks/${draggedTask.uid}`, updates: { orderNumber: updatedSourceBoardTaskIndex } });
                            updatedSourceBoardTaskIndex++
                        }
                    };
                    sourceBoardTasks.forEach((sourceBoardTask, sourceBoardTaskIndex) => {
                        addSameBoardDraggedTask();
                        if(sourceBoardTaskIndex !== source.index){
                            if(sourceBoardTaskIndex !== updatedSourceBoardTaskIndex){
                                sourceBoardTask.orderNumber = updatedSourceBoardTaskIndex;
                                bulkUpdates.push({ documentPath: `tasks/${sourceBoardTask.uid}`, updates: { orderNumber: updatedSourceBoardTaskIndex } });
                            }
                            updatedSourceBoardTasks.push(sourceBoardTask);
                            updatedSourceBoardTaskIndex++
                        }
                        addSameBoardDraggedTask();
                    });
                    if(!sameBoard){
                        const destinationBoardTasks = prevState[destination.droppableId];
                        const updatedDestinationBoardTasks = [];
                        let updatedDestinationBoardTaskIndex = 0;
                        const addDestinationBoardDraggedTask = () => {
                            if(updatedDestinationBoardTaskIndex === destination.index){
                                draggedTask.orderNumber = updatedDestinationBoardTaskIndex;
                                draggedTask.priority = activeUserBoardsPriorityLevels[destination.droppableId];
                                const updates = { orderNumber: updatedDestinationBoardTaskIndex, priority: activeUserBoardsPriorityLevels[destination.droppableId] };
                                if(source.droppableId === 'activeUserUnreadTasks' && !draggedTask.viewed){
                                    draggedTask.viewed = true;
                                    draggedTask.viewedAt = new Date().toISOString();
                                    updates.viewed = true;
                                    updates.viewedAt = new Date().toISOString();
                                }
                                updatedDestinationBoardTasks.push(draggedTask);
                                bulkUpdates.push({ documentPath: `tasks/${draggedTask.uid}`, updates });
                                updatedDestinationBoardTaskIndex++
                            }
                        };
                        if(destinationBoardTasks.length === 0){
                            addDestinationBoardDraggedTask();
                        } else {
                            destinationBoardTasks.forEach((destinationBoardTask, destinationBoardTaskIndex) => {
                                addDestinationBoardDraggedTask();
                                if(destinationBoardTaskIndex !== updatedDestinationBoardTaskIndex){
                                    destinationBoardTask.orderNumber = updatedDestinationBoardTaskIndex;
                                    destinationBoardTask.updatedAt = new Date();
                                    bulkUpdates.push({ documentPath: `tasks/${destinationBoardTask.uid}`, updates: { orderNumber: updatedDestinationBoardTaskIndex } });
                                }
                                updatedDestinationBoardTasks.push(destinationBoardTask);
                                updatedDestinationBoardTaskIndex++
                                addDestinationBoardDraggedTask();
                            });
                        }
                        
                        bulkUpdateTasks(bulkUpdates,
                        () => {
                            setActiveUserTasks(prevActiveUserTasks);
                        });
                        return {
                            ...prevState,
                            [source.droppableId]: updatedSourceBoardTasks,
                            [destination.droppableId]: updatedDestinationBoardTasks
                        }
                    }
                    bulkUpdateTasks(bulkUpdates,
                    () => {
                        setActiveUserTasks(prevActiveUserTasks);
                    });
                    return {
                        ...prevState,
                        [source.droppableId]: updatedSourceBoardTasks
                    };
                });
            } else {
                // dragged from team member board to active user board
                if(source.droppableId === 'new-tasks'){ // is new task
                    
                } else { // is not new task
                    const updatedBoardIndex = state.teamTasks.findIndex(board => board.uid === source.droppableId);
                    const updatedBoard = state.teamTasks[updatedBoardIndex];
                    const deletedTask = updatedBoard.tasks[source.index];

                    deleteTeamTask(source.droppableId, source.index, draggableId);
                    
                    setActiveUserTasks(prevState => {
                        const bulkUpdates = [];
                        const updates = {operatorId: activeUser.value.uid, viewed: false, viewedAt: '', priority: 1, orderNumber: 0};
                        const updatedTask = { ...deletedTask, ...updates };
                        bulkUpdates.push({ documentPath: `tasks/${deletedTask.uid}`, updates });
                        const destinationBoardTasks = prevState.activeUserUnreadTasks;
                        const updatedDestinationBoardTasks = [];
                        updatedDestinationBoardTasks.push(updatedTask);
                        let updatedDestinationBoardTaskIndex = 1;
                        destinationBoardTasks.forEach((destinationBoardTask) => {
                            destinationBoardTask.orderNumber = updatedDestinationBoardTaskIndex;
                            updatedDestinationBoardTasks.push(destinationBoardTask);
                            bulkUpdates.push({ documentPath: `tasks/${destinationBoardTask.uid}`, updates: { orderNumber: updatedDestinationBoardTaskIndex } });
                            updatedDestinationBoardTaskIndex++;
                        });
                        bulkUpdateTasks(bulkUpdates,
                        () => {
                            setTeamTasks(prevTeamTasks);
                            setActiveUserTasks(prevActiveUserTasks);
                        });
                        return {
                            ...prevState,
                            activeUserUnreadTasks: updatedDestinationBoardTasks
                        };
                    });
                }
            }
        } else { // dropped in someone else's board
            const destinationBoardIndex = state.teamTasks.findIndex(board => board.uid === destination.droppableId);
            if(destination.droppableId !== source.droppableId){
                if(activeUserBoardsIds.includes(source.droppableId)){ // source is activeUser's board
                    const sourceBoardTasks = state.activeUserTasks[source.droppableId];
                    const draggedTask = sourceBoardTasks[source.index];

                    setActiveUserTasks(prevState => ({
                        ...prevState,
                        [source.droppableId]: [
                            ...prevState[source.droppableId].slice(0, source.index),
                            ...prevState[source.droppableId].slice(source.index + 1)
                        ]
                    }));

                    setTeamTasks(prevState => {
                        const bulkUpdates = [];
                        let orderNumber = 0;
                        const firstTask = prevState[destinationBoardIndex].tasks[0];
                        if(firstTask) orderNumber = (firstTask.orderNumber || 0) - 1;
                        const updates = {operatorId: destination.droppableId, viewed: false, viewedAt: '', priority: 1, orderNumber};
                        const updatedTask = { ...draggedTask, ...updates };
                        bulkUpdates.push({ documentPath: `tasks/${draggedTask.uid}`, updates });

                        bulkUpdateTasks(bulkUpdates,
                        () => {
                            setActiveUserTasks(prevActiveUserTasks);
                            setTeamTasks(prevTeamTasks);
                        });

                        if(draggedTask.hidden) return prevState;

                        return [
                            ...prevState.slice(0, destinationBoardIndex),
                            {
                                ...prevState[destinationBoardIndex],
                                tasks: [
                                    updatedTask,
                                    ...prevState[destinationBoardIndex].tasks
                                ]
                            },
                            ...prevState.slice(destinationBoardIndex + 1)
                        ];
                    });
                } else {  // source is someone else's board
                    setTeamTasks(prevState1 => {
                        const sourceBoardIndex = prevState1.findIndex(board => board.uid === source.droppableId);
                        const updatedBoard = prevState1[sourceBoardIndex];
                        const deletedTask = updatedBoard.tasks[source.index];

                        let updatedTask;
                        const bulkUpdates = [];
                        const nextState = [];
                        prevState1.forEach((board, boardIndex) => {
                            if(boardIndex === sourceBoardIndex){
                                board = {
                                    ...prevState1[sourceBoardIndex],
                                    tasks: [
                                        ...prevState1[sourceBoardIndex].tasks.slice(0, source.index),
                                        ...prevState1[sourceBoardIndex].tasks.slice(source.index + 1)
                                    ]
                                };
                            }
                            if(boardIndex === destinationBoardIndex){
                                let orderNumber = 0;
                                const firstTask = prevState1[destinationBoardIndex].tasks[0];
                                if(firstTask) orderNumber = (firstTask.orderNumber || 0) - 1;
                                const updates = {operatorId: destination.droppableId, viewed: false, viewedAt: '', priority: 1, orderNumber};
                                updatedTask = { ...deletedTask, ...updates };
                                bulkUpdates.push({ documentPath: `tasks/${deletedTask.uid}`, updates });
                                board = {
                                    ...prevState1[destinationBoardIndex],
                                    tasks: [
                                        updatedTask,
                                        ...prevState1[destinationBoardIndex].tasks
                                    ]
                                };
                            }
                            nextState.push(board);
                        });

                        bulkUpdateTasks(bulkUpdates,
                        () => {
                            setTeamTasks(prevTeamTasks);
                        });

                        return nextState;
                    });
                }
            }
        }
    }, [selectedWorkspace.value?.uid, selectedWorkspace.value?.name, activeUser.value?.uid, activeUser.value?.name, state.activeUserTasks, state.teamTasks]);

    const handleTaskReadButtonPress = useCallback(async (e, clickedTask, unreadTaskIndex) => {
        e.stopPropagation();
        const prevActiveUserTasks = {...state.activeUserTasks};
        const now = new Date();
        
        setActiveUserTasks(prevState => {
            const updates = { viewed: true, viewedAt: now.toISOString(), priority: 1 }
            const updatedTask = {
                ...prevState.activeUserUnreadTasks[unreadTaskIndex],
                ...updates
            };
            const bulkUpdates = [];
            bulkUpdates.push({ documentPath: `tasks/${updatedTask.uid}`, updates });
            const updatedActiveUserMediumPriorityTasks = [];
            updatedActiveUserMediumPriorityTasks.push(updatedTask);
            let updatedActiveUserMediumPriorityTaskIndex = 1;
            prevState.activeUserMediumPriorityTasks.forEach((activeUserMediumPriorityTask) => {
                activeUserMediumPriorityTask.orderNumber = updatedActiveUserMediumPriorityTaskIndex;
                bulkUpdates.push({ documentPath: `tasks/${activeUserMediumPriorityTask.uid}`, updates: { orderNumber: updatedActiveUserMediumPriorityTaskIndex } });
                updatedActiveUserMediumPriorityTasks.push(activeUserMediumPriorityTask);
                updatedActiveUserMediumPriorityTaskIndex++
            });
            bulkUpdateTasks(bulkUpdates,
            () => {
                setActiveUserTasks(prevActiveUserTasks);
            });
            return {
                ...prevState,
                activeUserUnreadTasks: [
                    ...prevState.activeUserUnreadTasks.slice(0, unreadTaskIndex),
                    ...prevState.activeUserUnreadTasks.slice(unreadTaskIndex + 1)
                ],
                activeUserMediumPriorityTasks: updatedActiveUserMediumPriorityTasks
            }
        });
    }, [activeUser.value?.name, state.activeUserTasks]);

    const handleTaskSubmit = useCallback(async (data, timesheet) => {
        const {
            deadline,
            hiddenTask,
            estimatedMinutesRequiredToComplete,
            questionDescription,
            questionSubject,
            selectedClient,
            selectedProject,
            taskOperatorId,
            taskPriority,
            taskUrl,
        } = data;

        const prevActiveUserTasks = {...state.activeUserTasks};
        let prevTeamTasks = null;
        if(state.teamTasks) prevTeamTasks = [...state.teamTasks];
        if(state.taskViewSelectedTask){
            const updates = {
                projectId: selectedProject !== 'none' ? selectedProject : selectedClient,
                subject: questionSubject,
                description: questionDescription,
                deadline: deadline ? moment(deadline).format() : null,
                estimatedMinutesRequiredToComplete: estimatedMinutesRequiredToComplete,
                taskUrl,
                operatorId: taskOperatorId || '',
                hidden: hiddenTask,
            };
            const updatedTask = new Task({...state.taskViewSelectedTask, ...updates});
            const boardId = state.taskViewSelectedTask.boardId;
            setActiveUserTasks(prevState => {
                const taskIndex = prevState[boardId].findIndex(task => task.uid === state.taskViewSelectedTask.uid);
                
                bulkUpdateTasks([{ documentPath: `tasks/${state.taskViewSelectedTask.uid}`, updates }],
                    () => {
                        setActiveUserTasks(prevActiveUserTasks);
                        toast(ERROR_MESSAGE_CHANGES_UNDONE, { autoClose: 5000, type: 'error' });
                    });

                return {
                    ...prevState,
                    [boardId]: [
                        ...prevState[boardId].slice(0, taskIndex),
                        {...updatedTask, updatedAt: new Date()},
                        ...prevState[boardId].slice(taskIndex + 1)
                    ]
                };
            });
        } else {
            if(timesheet){
                const newTimesheetRecord = new TimesheetRecord({
                    createdBy: activeUser.value.uid,
                    description: questionSubject,
                    minutes: estimatedMinutesRequiredToComplete,
                    projectId: selectedProject !== 'none' ? selectedProject : selectedClient,
                    startAt: moment().subtract(estimatedMinutesRequiredToComplete, 'minutes').toISOString(),
                    workspaceId: selectedWorkspace.value.uid,
                });
                const res = await newTimesheetRecord.createTimesheetRecord();
                if(res.error){
                    return toast(ERROR_MESSAGE_CHANGES_UNDONE, { autoClose: 5000, type: 'error' });
                }
                return toast(SUCCESS_MESSAGE_SAVED, { autoClose: 3000, type: 'success' });
            }

            const taskRef = doc(collection(db, `tasks`));
            
            const now = new Date();
            const nowISOString = now.toISOString();
            const newTask = new Task({
                createdAt: nowISOString,
                createdBy: activeUser.value.uid,
                deadline: deadline ? moment(deadline).format('YYYY-MM-DD') : '',
                description: questionDescription,
                estimatedMinutesRequiredToComplete: estimatedMinutesRequiredToComplete,
                hidden: hiddenTask,
                operatorId: taskOperatorId || '',
                orderNumber: -now.valueOf(),
                priority: taskPriority,
                projectId: selectedProject !== 'none' ? selectedProject : selectedClient,
                subject: questionSubject,
                taskUrl: taskUrl,
                uid: taskRef.id,
                workspaceId: selectedWorkspace.value.uid,
            });
            updatedTasksAt.current = nowISOString;
            if(taskOperatorId === activeUser.value.uid){
                newTask.viewed = true;
                newTask.viewedAt = new Date().toISOString();
                const {uid: _, ...taskWithoutUid} = newTask;
                const bulkUpdates = [
                    { operation: 'set', documentPath: `tasks/${taskRef.id}`, updates: {...taskWithoutUid} }
                ];
                if(!state.activeUserTasks){
                    return bulkUpdateTasks(bulkUpdates);
                }
                setActiveUserTasks(prevState => {
                    if(taskPriority === 2){
                        let updatedExistingTaskIndex = 1;
                        prevState.activeUserHighPriorityTasks.forEach((task, prevTaskIndex) => {
                            if(prevTaskIndex !== updatedExistingTaskIndex){
                                prevState.activeUserHighPriorityTasks[prevTaskIndex].orderNumber = updatedExistingTaskIndex;
                                bulkUpdates.push({ documentPath: `tasks/${task.uid}`, updates: { orderNumber: updatedExistingTaskIndex } });
                            }
                            updatedExistingTaskIndex++;
                        });
                        bulkUpdateTasks(bulkUpdates,
                            () => {
                                setActiveUserTasks(prevActiveUserTasks);
                                toast(ERROR_MESSAGE_CHANGES_UNDONE, { autoClose: 5000, type: 'error' });
                            });
                        return {
                            ...prevState,
                            activeUserHighPriorityTasks: [
                                newTask,
                                ...prevState.activeUserHighPriorityTasks
                            ]
                        };
                    } else if(taskPriority === 1){
                        let updatedExistingTaskIndex = 1;
                        prevState.activeUserMediumPriorityTasks.forEach((task, prevTaskIndex) => {
                            if(prevTaskIndex !== updatedExistingTaskIndex){
                                prevState.activeUserMediumPriorityTasks[prevTaskIndex].orderNumber = updatedExistingTaskIndex;
                                bulkUpdates.push({ documentPath: `tasks/${task.uid}`, updates: { orderNumber: updatedExistingTaskIndex } });
                            }
                            updatedExistingTaskIndex++;
                        });
                        bulkUpdateTasks(bulkUpdates,
                            () => {
                                setActiveUserTasks(prevActiveUserTasks);
                                toast(ERROR_MESSAGE_CHANGES_UNDONE, { autoClose: 5000, type: 'error' });
                            });
                        return {
                            ...prevState,
                            activeUserMediumPriorityTasks: [
                                newTask,
                                ...prevState.activeUserMediumPriorityTasks
                            ]
                        };
                    }
                    let updatedExistingTaskIndex = 1;
                    prevState.activeUserLowPriorityTasks.forEach((task, prevTaskIndex) => {
                        if(prevTaskIndex !== updatedExistingTaskIndex){
                            prevState.activeUserLowPriorityTasks[prevTaskIndex].orderNumber = updatedExistingTaskIndex;
                            bulkUpdates.push({ documentPath: `tasks/${task.uid}`, updates: { orderNumber: updatedExistingTaskIndex } });
                        }
                        updatedExistingTaskIndex++;
                    });
                    bulkUpdateTasks(bulkUpdates,
                        () => {
                            setActiveUserTasks(prevActiveUserTasks);
                            toast(ERROR_MESSAGE_CHANGES_UNDONE, { autoClose: 5000, type: 'error' });
                        });
                    return {
                        ...prevState,
                        activeUserLowPriorityTasks: [
                            newTask,
                            ...prevState.activeUserLowPriorityTasks
                        ]
                    };
                });
            } else {
                const {uid: _, ...taskWithoutUid} = newTask;
                const bulkUpdates = [
                    { operation: 'set', documentPath: `tasks/${taskRef.id}`, updates: {...taskWithoutUid} }
                ];
                if(!state.teamTasks){
                    return bulkUpdateTasks(bulkUpdates);
                }
                setTeamTasks(prevState => {
                    const updatedBoardIndex = prevState.findIndex(board => board.uid === taskOperatorId);
                    bulkUpdateTasks(bulkUpdates,
                        () => {
                            setTeamTasks(prevTeamTasks);
                            toast(ERROR_MESSAGE_CHANGES_UNDONE, { autoClose: 5000, type: 'error' });
                        });
                    
                    if(newTask.hidden) return prevState;
                    return [
                        ...prevState.slice(0, updatedBoardIndex),
                        {
                            ...prevState[updatedBoardIndex],
                            tasks: [
                                newTask,
                                ...prevState[updatedBoardIndex].tasks
                            ]
                        },
                        ...prevState.slice(updatedBoardIndex + 1)
                    ];
                });
            }
        }
    }, [selectedWorkspace.value?.uid, selectedWorkspace.value?.name, activeUser.value?.uid, activeUser.value?.name, state.activeUserTasks, state.taskViewSelectedTask, state.teamTasks]);

    const hideCompleteTaskView = (newValue) => {
        dispatch({type: 'HIDE COMPLETE TASK VIEW', payload: newValue});
    };

    const hideTaskView = (newValue) => {
        dispatch({type: 'HIDE TASK VIEW', payload: newValue});
    };

    const setSnapshot = (triggerKey) => {
        setState(triggerKey, true);
    }
    
    const setState = (key, newValue) => {
        dispatch({type: 'SET STATE', payload: { key, value: newValue }});
    };

    const setActiveProjectBoards = (newValue) => {
        setState('activeProjectBoards', newValue);
    };
    
    const setActiveUserTasks = (newValue) => {
        setState('activeUserTasks', newValue);
    };
    
    const setCompleteTaskViewSelectedTask = (newValue) => {
        setState('completeTaskViewSelectedTask', newValue);
    };

    const setFilters = (newValue) => {
        setState('filters', newValue);
    };

    const setNewTasks = (newValue) => {
        setState('newTasks', newValue);
    };

    const setNextActiveUserTask = (newValue) => {
        setState('nextActiveUserTask', newValue);
    };

    const setTasks = (newValue) => {
        setState('tasks', newValue);
    };

    const setTaskViewSelectedTask = (newValue) => {
        setState('taskViewSelectedTask', newValue);
    };

    const setTeamTasks = (newValue) => {
        setState('teamTasks', newValue);
    };

    const showCompleteTaskView = ({ boardId, task, taskIndex }) => {
        dispatch({type: 'SHOW COMPLETE TASK VIEW', payload: { boardId, task, taskIndex }});
    };

    const showTaskView = (clickedTask) => {
        dispatch({type: 'SHOW TASK VIEW', payload: { clickedTask }});
    };

    const sortTasks = (a, b) => {
        if(a.viewed && !b.viewed) return 1;
        if(!a.viewed && b.viewed) return -1;
        if(a.priority < b.priority) return 1;
        if(a.priority > b.priority) return -1;
        return (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0);
    };

    const api = useMemo(() => {

        const resetFilters = () => {
            setFilters(defaultFiltersValue);
        };

        return {
            dispatch,

            handleAddTaskButtonPress,
            handleCompleteTaskConfirmButtonPress,
            handleTaskChangeButtonPress,
            handleTaskDoneButtonPress,
            handleTaskDragEnd,
            handleTaskReadButtonPress,
            handleTaskSubmit,
            hideCompleteTaskView,
            hideTaskView,
            resetFilters,
            setActiveProjectBoards,
            setFilters,
            setSnapshot,
            showCompleteTaskView,
            showTaskView,
            sortTasks
        };
    }, [handleTaskSubmit, handleCompleteTaskConfirmButtonPress, handleTaskDragEnd, handleTaskReadButtonPress]);

    useEffect(() => {
        const keyPress = (e) => {
            if(workspaceClients.value && workspaceProjects.value && selectedWorkspace.value.modules.includes('tasks') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)){
                if((e.altKey || e.ctrlKey) && e.key === '1'){
                    e.preventDefault();
                    showTaskView(null);
                }
            }
        };
        document.addEventListener('keydown', keyPress);
        return () => {
            document.removeEventListener('keydown', keyPress);
        };
    }, [selectedWorkspace.value?.modules, selectedWorkspace.value?.role, workspaceClients.value, workspaceProjects.value]);
    
    const organizeBoards = (tasks, tasksTeam) => {
        const currentNewTasks = [];
        const currentActiveUserTasks = [];
        const currentActiveUnreadTasks = [];
        const currentActiveUserHighPriorityTasks = [];
        const currentActiveUserMediumPriorityTasks = [];
        const currentActiveUserLowPriorityTasks = [];
        let teamTasksUserIndex = 0;
        const currentTeamTasksUserIndex = {};
        const currentTeamTasks = [];
        tasksTeam.forEach(operator => {
            if(operator.uid !== activeUser.value.uid){
                if(currentTeamTasksUserIndex[operator.uid] === undefined){
                    currentTeamTasksUserIndex[operator.uid] = teamTasksUserIndex;
                    teamTasksUserIndex++;
                    currentTeamTasks.push({...operator, tasks: []});
                }
            }
        });
        tasks
        .sort(sortTasks)
        .forEach(task => {
            if(!task.deleted && !task.completed){
                if(!task.operatorId){
                    return currentNewTasks.push(task);
                }
                if(task.operatorId === activeUser.value.uid){
                    if(!task.viewed){
                        currentActiveUnreadTasks.push(task);
                    } else {
                        if(task.priority === 2){
                            currentActiveUserHighPriorityTasks.push(task);
                        } else if(task.priority === 1 || task.priority === true){
                            currentActiveUserMediumPriorityTasks.push(task);
                        } else {
                            currentActiveUserLowPriorityTasks.push(task); // !task.priority
                        }
                    }
                    return currentActiveUserTasks.push(task);
                }
                if(!task.hidden){
                    if(currentTeamTasksUserIndex[task.operatorId] === undefined){
                        const foundOperator = tasksTeam.find(operator => operator.uid === task.operatorId);
                        if(foundOperator){
                            currentTeamTasksUserIndex[task.operatorId] = teamTasksUserIndex;
                            teamTasksUserIndex++;
                            currentTeamTasks.push({...foundOperator, tasks: []});
                        }
                    }
                    if(currentTeamTasksUserIndex[task.operatorId] !== undefined) currentTeamTasks[currentTeamTasksUserIndex[task.operatorId]].tasks.push(task);
                }
            }
        });
        setNewTasks(currentNewTasks.length > 0 ? currentNewTasks : null);
        setActiveUserTasks({
            activeUserHighPriorityTasks: currentActiveUserHighPriorityTasks,
            activeUserMediumPriorityTasks: currentActiveUserMediumPriorityTasks,
            activeUserLowPriorityTasks: currentActiveUserLowPriorityTasks,
            activeUserUnreadTasks: currentActiveUnreadTasks,
        });
        if(currentActiveUserTasks[0]) setNextActiveUserTask(currentActiveUserTasks[0]);
        setTeamTasks(currentTeamTasks.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0));

        if(state.taskViewSelectedTask || state.completeTaskViewSelectedTask){
            let selectedTask = null;
            if(state.taskViewOpen){
                selectedTask = state.taskViewSelectedTask;
            } else if(state.completeTaskViewOpen){
                selectedTask = state.completeTaskViewSelectedTask;
            }
            if(selectedTask){
                const foundSelectedTask = state.tasks.find(task => task.uid === selectedTask.uid);
                if(foundSelectedTask){
                    if(foundSelectedTask.operatorId === activeUser.value.uid){
                        if(state.taskViewOpen){
                            setTaskViewSelectedTask(foundSelectedTask);
                        } else if(state.completeTaskViewOpen){
                            setCompleteTaskViewSelectedTask(foundSelectedTask);
                        }
                    } else {
                        setTaskViewSelectedTask(null);
                        setCompleteTaskViewSelectedTask(null);
                        hideTaskView();
                        hideCompleteTaskView();
                    }
                }
            }
        }
    };

    useEffect(() => {
        if(shouldOrganizeBoards.current && workspaceOperators.value && workspaceOperators.value.length !== 0 && state.tasks){
            shouldOrganizeBoards.current = false;
            updatedTasksAt.current = new Date().toISOString();
            organizeBoards(state.tasks, workspaceOperators.value);
        }
    }, [activeUser.value?.uid, workspaceOperators.value, state.tasks]);

    const tasksCtxCompleteTaskViewValue = useMemo(() => ({
        completeTaskViewOpen: state.completeTaskViewOpen,
        completeTaskViewSelectedTask: state.completeTaskViewSelectedTask,
    }), [state.completeTaskViewOpen, state.completeTaskViewSelectedTask]);

    const tasksCtxTaskViewValue = useMemo(() => ({
        taskViewOpen: state.taskViewOpen,
        taskViewSelectedTask: state.taskViewSelectedTask,
    }), [state.taskViewOpen, state.taskViewSelectedTask]);
    
    return (
        <TasksCtxAPI.Provider value={api}>
            <TasksCtxActiveUserTasks.Provider value={state.activeUserTasks}>
            <TasksCtxCompleteTaskView.Provider value={tasksCtxCompleteTaskViewValue}>
            <TasksCtxDragDisabled.Provider value={state.dragDisabled}>
            <TasksCtxFilters.Provider value={state.filters}>
            <TasksCtxNewTasks.Provider value={state.newTasks}>
            <TasksCtxTasks.Provider value={state.tasks}>
            <TasksCtxTaskView.Provider value={tasksCtxTaskViewValue}>
            <TasksCtxTeamTasks.Provider value={state.teamTasks}>

                {children}

                <TaskWindow />
                <CompleteTaskWindow />

            </TasksCtxTeamTasks.Provider>
            </TasksCtxTaskView.Provider>
            </TasksCtxTasks.Provider>
            </TasksCtxNewTasks.Provider>
            </TasksCtxFilters.Provider>
            </TasksCtxDragDisabled.Provider>
            </TasksCtxCompleteTaskView.Provider>
            </TasksCtxActiveUserTasks.Provider>
        </TasksCtxAPI.Provider>
    );
};

const useTasksCtxAPI = () => useContext(TasksCtxAPI);
const useTasksCtxActiveUserTasks = () => useContext(TasksCtxActiveUserTasks);
const useTasksCtxCompleteTaskView = () => useContext(TasksCtxCompleteTaskView);
const useTasksCtxDragDisabled = () => useContext(TasksCtxDragDisabled);
const useTasksCtxFilters = () => useContext(TasksCtxFilters);
const useTasksCtxNewTasks = () => useContext(TasksCtxNewTasks);
const useTasksCtxSelectedTask = () => useContext(TasksCtxSelectedTask);
const useTasksCtxTasks = () => useContext(TasksCtxTasks);
const useTasksCtxTaskView = () => useContext(TasksCtxTaskView);
const useTasksCtxTeamTasks = () => useContext(TasksCtxTeamTasks);

export {
    TasksProvider,
    
    useTasksCtxAPI,
    
    useTasksCtxActiveUserTasks,
    useTasksCtxCompleteTaskView,
    useTasksCtxDragDisabled,
    useTasksCtxFilters,
    useTasksCtxNewTasks,
    useTasksCtxSelectedTask,
    useTasksCtxTasks,
    useTasksCtxTaskView,
    useTasksCtxTeamTasks
};