import moment from 'moment';
import 'moment/locale/pt-br';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import './index.css';
import theme from './theme';
import Auth from './components/Auth';
import { AppStateProvider } from './context/AppState';
import { AppDataProvider } from './context/SystemContext';
import Connected from './pages/Connected';
import SentAgreement from './pages/SentAgreement';
import Start from './pages/Start';
import Suppliers from './pages/Suppliers';

moment.locale('pt-br');

function App() {
    return (
        <CssBaseline>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
                        <BrowserRouter>
                            <AppStateProvider>
                                <AppDataProvider>
                                    <ToastContainer />
                                    <Auth>
                                        <Routes>
                                            <Route path="/" element={<Start />} />
                                            <Route path="/proposta/:workspaceId/:proposalVersionId" element={<SentAgreement />} />
                                            <Route path="/ficha/:clientId" element={<Suppliers />} />
                                            <Route path="/*" element={<Connected />} />
                                        </Routes>
                                    </Auth>
                                </AppDataProvider>
                            </AppStateProvider>
                        </BrowserRouter>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </CssBaseline>
    );
}

export default App;