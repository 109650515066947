import { doc, runTransaction } from 'firebase/firestore';

import { db } from '../firebase/config';
import { JudicialCaseProgress as _JudicialCaseProgress } from '../shared/types';

export default class JudicialCaseProgress extends _JudicialCaseProgress {
    
    async delete(){
        let result = null, error = null;

        const judicialCaseProgressRef = doc(db, `judicial_cases_progress_records/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.delete(judicialCaseProgressRef);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;

        const judicialCaseRef = doc(db, `judicial_cases/${this.caseId}`);
        const judicialCaseProgressRef = doc(db, `judicial_cases_progress_records/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                const caseSnapshot = await transaction.get(judicialCaseRef);
                const updatedCase = caseSnapshot.data();
                if(updatedCase?.lastProgressRecord?.uid === this.uid){
                    const caseUpdates: { [key: string]: any } = {};
                    for(const key in updates){
                        caseUpdates[`lastProgressRecord.${key}`] = updates[key];
                    }
                    transaction.update(judicialCaseRef, caseUpdates);
                }
                transaction.update(judicialCaseProgressRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}