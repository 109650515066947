import { useEffect, useState } from 'react';
import { useSignals } from '@preact/signals-react/runtime';

import { Fab, Zoom } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import UsersGrid from '../../Tasks/UsersGrid';

import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import ViewBox from '../../../../components/ViewBox';
import { useAppStateCtx } from '../../../../context/AppState';
import { useTasksCtxAPI, useTasksCtxActiveUserTasks } from '../../../../context/TasksContext';

const classes = {
    fab: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
    },
};

function QuestionsOverview(){
    useSignals();
    const { workspaceClients, workspaceProjects } = useAppStateCtx();
    const { handleAddTaskButtonPress, resetFilters, setSnapshot: setTasksCtxSnapshot } = useTasksCtxAPI();
    const activeUserTasks = useTasksCtxActiveUserTasks()
    const [loading, setLoading] = useState(true);
    const [projectOptions, set_projectOptions] = useState([]);

    useEffect(() => {
        setTasksCtxSnapshot('shouldSetTasksSnapshot');
        resetFilters();
    }, []);
    
    useEffect(() => {
        if(activeUserTasks){
            setLoading(false);
        }
    }, [activeUserTasks]);

    useEffect(() => {
        if(workspaceClients.value && workspaceProjects.value){
            let currentProjectOptions = Object.entries(workspaceProjects.value).map(([_, project]) => {
                let projectName = project.name;
                if(project.clientId){
                    let projectClient = workspaceClients.value[project.clientId];
                    if(projectClient) projectName = `${projectClient.name} - ${projectName}`;
                }
                project = {...project, name: projectName};
                return project;
            });
            let clientsAllProjects = Object.entries(workspaceClients.value).map(([_, client]) => ({...client, _id: `all_${client.uid}`, name: `${client.name} - Todos`}));
            set_projectOptions([...currentProjectOptions, ...clientsAllProjects]);
        }
    }, [workspaceClients.value, workspaceProjects.value]);

    return (
        <ViewBox style={{height: '100%', paddingTop: 8, paddingBottom: 0}}>
            {
                loading
                ? <LoaderEllipsis />
                :
                <UsersGrid
                    layout="personal"
                    projectOptions={projectOptions} setProjectOptions={set_projectOptions}
                />
            }
            
            <Zoom in>
                <Fab
                    variant="circular" color="primary"
                    sx={classes.fab}
                    onClick={handleAddTaskButtonPress}
                >
                    <AddIcon />
                </Fab>
            </Zoom>
        </ViewBox>
    );
}

export default QuestionsOverview;