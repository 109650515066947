import { useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Fab, Grid, Slide, Typography } from '@mui/material';
import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI } from '../../context/SystemContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';

const classes = {
    notifications: {
        position: 'fixed',
        top: 0,
        right: 0,
        zIndex: 9999,
        padding: '8px'
    },
    root: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        zIndex: theme => theme.zIndex.snackbar + 1,
    },
    alert: {
        backgroundColor: '#f5f6fa', // theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.4)',
        padding: '8px'
    }
};

const Notifications = () => { 
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { handleNavigate } = useAppCtxAPI();
    const [receivingVideoCall, setReceivingVideoCall] = useState(null);

    // useEffect(() => {
    //     const videoCallventName = 'VIDEO CALL';
    //     const videoCallHungUpEventName = 'VIDEO CALL HUNG UP';

    //     function onVideoCallEvent(data){
    //         setReceivingVideoCall(data);
    //     };

    //     function onVideoCallHungUpEvent(data){
    //         setReceivingVideoCall(null);
    //     };

    //     socket.on(videoCallventName, onVideoCallEvent);
    //     socket.on(videoCallHungUpEventName, onVideoCallHungUpEvent);
    //     return () => {
    //         socket.off(videoCallventName, onVideoCallEvent);
    //         socket.off(videoCallHungUpEventName, onVideoCallHungUpEvent);
    //     };
    // }, []);

    const handleAcceptCallButtonPress = () => {
        const callRoomId = receivingVideoCall.callRoomId;
        // const callerSocketId = receivingVideoCall.callerSocketId;
        // const calleeUserId = receivingVideoCall.calleeUserId;
        // socket.emit('VIDEO CALL ACCEPTED', {callerSocketId, callRoomId, calleeUserId});
        if(selectedWorkspace.value) handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/salas/${callRoomId}`);
        setReceivingVideoCall(null);
    };

    const handleRejectCallButtonPress = () => {
        if(receivingVideoCall){
            // const callRoomId = receivingVideoCall.callRoomId;
            // const callerSocketId = receivingVideoCall.callerSocketId;
            // const calleeUserId = receivingVideoCall.calleeUserId;
            // socket.emit('VIDEO CALL REJECTED', {callerSocketId, callRoomId, calleeUserId});
            setReceivingVideoCall(null);
        }
    };

    if(receivingVideoCall){
        return (
            <Box sx={classes.root}>
                <Slide in direction="left">
                    <Box sx={classes.alert}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="h6">{receivingVideoCall.callerName} está chamando</Typography>
                            </Grid>
                            <Grid item>
                                <Fab color="primary" onClick={handleAcceptCallButtonPress}>
                                    <FontAwesomeIcon icon={faPhone} size="lg" />
                                </Fab>
                            </Grid>
                            <Grid item>
                                <Fab color="secondary" onClick={handleRejectCallButtonPress}>
                                    <FontAwesomeIcon icon={faPhoneSlash} size="lg" />
                                </Fab>
                            </Grid>
                        </Grid>
                    </Box>
                </Slide>
            </Box>
        );
    }
    return null;
};

export default Notifications;