import React, { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from '../context/AppState';
import { useAppContext } from '../context/SystemContext';

import { useForm } from '../pages/Form/context/FormContext';

const useDocumentFormQuestion = ({
    additionalValidationData,
    assignedField,
    question,
    questionType,
    setQuestionValid,
    typeSpecifics
}: {
    additionalValidationData?: React.MutableRefObject<{}>,
    assignedField: string;
    question: { [key: string]: any };
    questionType: string;
    setQuestionValid: React.Dispatch<React.SetStateAction<boolean>>;
    typeSpecifics?: string; 
}) => {
    useSignals();
    const { formForceCheckValidation, formInitialValues } = useAppStateCtx();
    const { formEffects } = useAppContext();
    const { applyLogicalBranching, values } = useForm();
    const [initialValue, setInitialValue] = useState<{ [key: string]: any } | null>(null);
    const [userFormQuestion, setUserFormQuestion] = useState<{ [key: string]: any } | null>(null);
    const touchedField = useRef(false);

    useEffect(() => {
        if(userFormQuestion){
            values.current = {...values.current, [question.name]: userFormQuestion};
            applyLogicalBranching();
        }
    }, [userFormQuestion]);

    useEffect(() => {
        setInitialValue(formInitialValues.value[question.name]);
        setUserFormQuestion(prevState => {
            let nextState: { [key: string]: any } = {
                ...prevState,
                id: question.id,
                type: questionType
            };
            if(typeSpecifics) nextState.typeSpecifics = typeSpecifics;
            if(assignedField) nextState.assignedField = assignedField;

            if(formInitialValues.value[question.name]) nextState = {...nextState, ...formInitialValues.value[question.name]};
            
            return nextState;
        });
    }, [formInitialValues.value]);

    const checkValidation = () => {
        const validationData = additionalValidationData ? {...userFormQuestion, ...additionalValidationData.current} : {...userFormQuestion};
        const questionValid = formEffects[questionType].checkValidation(validationData);
        setQuestionValid(questionValid);
    };

    useEffect(() => {
        if((userFormQuestion && userFormQuestion.input) || touchedField.current || formForceCheckValidation.value){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestion, formForceCheckValidation.value]);

    return { checkValidation, initialValue, touchedField, userFormQuestion, setUserFormQuestion };
};

export default useDocumentFormQuestion;