import { Chip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MenuChip = ({label, noChoiceValue, onPress, value, valueLabel}) => {
    return (
        <Chip
            variant={value !== (noChoiceValue || 'all') ? 'default' : 'outlined'}
            color="primary"
            label={`${label}${value !== (noChoiceValue || 'all') ? `: ${valueLabel}` : ''}`}
            deleteIcon={<ArrowDropDownIcon />}
            onDelete={onPress}
            clickable
            onClick={onPress}
        />
    );
};

export default MenuChip;