import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import FlagIcon from '@mui/icons-material/Flag';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import Tooltip from './Tooltip';
import { useAppStateCtx } from '../context/AppState';

const Span = styled('span')(unstable_styleFunctionSx);

const DocumentFlag = ({
    activeUserIsOperator,
    flag,
    iconClass
}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    
    const StyledFlagIcon = () => (
        <FlagIcon fontSize="small" sx={{color: theme => theme.palette.secondary.main}} />
    );

    const Flag2Span = ({children}) => (
        <Span sx={{color: theme => theme.palette.secondary.main}}>{children}</Span>
    );

    if(!!flag){
        const flaggedAt = flag.flaggedAt ? moment(flag.flaggedAt).format('D/M/YY H:mm') : '';
        let flaggedBy = '';
        if(flag.flaggedBy){
            const foundUser = workspaceUsers.value[flag.flaggedBy];
            if(foundUser) flaggedBy = foundUser.name || '';
        }
        const flagDescription = flag.type === 'client'
            ? (
                activeUserIsOperator
                    ? `Sinalizado ${flaggedBy ? `por ${flaggedBy}` : 'pelo solicitante'}${flaggedAt ? ` em ${flaggedAt}` : ''}`
                    : `Aguardando ${flaggedAt ? ` desde ${flaggedAt}` : ''}`
                )
            : (
                activeUserIsOperator
                    ? `Aguardando ${flaggedAt ? ` desde ${flaggedAt}` : ''}`
                    : `Sinalizado ${flaggedAt ? ` em ${flaggedAt}` : ''}`
                );
        return (
            <Tooltip text={flagDescription} enterDelay={400}>
                <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                    <Grid item style={{width: 24}}>
                        {flag.type === 'client' ? (activeUserIsOperator ? <StyledFlagIcon /> : <HourglassFullIcon fontSize="small" className={iconClass} />) : (activeUserIsOperator ? <HourglassFullIcon fontSize="small" className={iconClass} /> : <StyledFlagIcon />)}
                    </Grid>
                    <Grid item style={{width: `calc(100% - 24px)`}}>
                        <Typography variant="body2" noWrap>{flag.type === 'client' ? (activeUserIsOperator ? <Flag2Span>{flagDescription}</Flag2Span> : flagDescription)  : (activeUserIsOperator ? <em>{flagDescription}</em> : <Span sx={{color: theme => theme.palette.secondary.main}}>{flagDescription}</Span>)}</Typography>
                    </Grid>
                </Grid>
            </Tooltip>
        );
    }
    return null;
};

export default DocumentFlag;