import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
// import AddIcon from '@mui/icons-material/Add';
import SearchEngineTextField from '../../../../../components/SearchEngineTextField';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';
// import {
//     useAppCtxAPI,
// } from '../../../../../context/SystemContext';
import { useDocumentsAPI } from '../../../../../context/DocumentsContext';
// import { useFolderUserCtxUseAddDocument } from '../../../context/ClientContext';

const classes = {
    buttonsBox: {
        justifyContent: ['space-evenly', 'flex-end'],
    },
};

const DocumentsTop = (props) => {
    useSignals();
    const {
        filtersButtonRef,
        // getQuery,
        loading,
        searchValue
    } = props;
    // const { selectedFolder } = useAppStateCtx();
    // const { selectedFolderIsClient } = useAppStateCtxUtils();
    const {
        // showAddDocumentView,
        showDocumentFiltersView
    } = useDocumentsAPI();
    // const useAddDocument = useFolderUserCtxUseAddDocument();

    const customFilterButtonClick = (event) => {
        showDocumentFiltersView(filtersButtonRef.current);
    };

    // const handle_addDocumentClick = () => {
        // addDocumentWindowOpen.value = true
    //     showAddDocumentView([selectedFolder.value], selectedFolder.value);
    // };

    const handleEndIconClick = () => {
        customFilterButtonClick();
    };

    const handleSearch = (newValue) => {
        searchValue.value = newValue;
    };

    // const handleExportButtonClick = async () => {
    //     //TODO
    //     floatingAlert('Gerando o relatório... isso pode levar alguns minutos...', 'warning', 0);
    //     let filename = '';

    //     let getData = getQuery('get');
    //     if(selectedFolder.value){
    //         if(getData) getData += '&';
    //         getData += `folderId=${selectedFolder.value.uid}`;
    //     }
    //     // /data/client/documents/export${getData ? `?${getData}` : ''}
    //     // responseType: 'blob'
    //     // .then(response => {
    //     //     const disposition = response.headers['content-disposition'];
    //     //     filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
    //     //     if (filename.toLowerCase().startsWith("utf-8''"))
    //     //        filename = decodeURIComponent(filename.replace("utf-8''", ''));
    //     //     else
    //     //        filename = filename.replace(/['"]/g, '');
    //     //     return response.data;
    //     //  })
    //     //  .then(blob => {
    //     //     var url = window.URL.createObjectURL(blob);
    //     //     var a = document.createElement('a');
    //     //     a.href = url;
    //     //     a.download = filename;
    //     //     document.body.appendChild(a); // append the element to the dom
    //     //     a.click();
    //     //     a.remove(); // afterwards, remove the element
    //     //     floatingAlert('');
    //     // })
    //     // .catch(err => {
    //     //     floatingAlert(err.response.data, 'error', 0);
    //     // });
    // };

    return (
        <Box mb={1}>
            <Grid container spacing={1} alignItems="center">
                <Grid size={{ xs: searchValue.value ? 8 : 12, md: 4 }} ref={filtersButtonRef}>
                    <SearchEngineTextField
                        disabled={loading.value}
                        onEndIconClick={handleEndIconClick}
                        onSearch={handleSearch}
                    />
                </Grid>
                {
                    searchValue.value &&
                    <Grid>
                        <Chip label={searchValue.value} color="primary" onDelete={() => handleSearch('')} />
                    </Grid>
                }
                <Grid size="grow" container spacing={1} sx={classes.buttonsBox} alignItems="center">
                    {
                        // (useAddDocument && !selectedFolderIsClient.value) &&
                        // <Grid>
                        //     <Button disabled={loading.value} size="small" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handle_addDocumentClick}>Adicionar documento (UPLOAD)</Button>
                        // </Grid>
                    }
                    {/* <Grid>
                        <Button
                            disabled={loading.value}
                            variant="outlined" size="small"
                            onClick={handleExportButtonClick}
                        >XLS</Button>
                    </Grid> */}
                </Grid>
            </Grid>
        </Box>
    );
}

export default DocumentsTop;