import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import { useAppStateCtx } from '../../../../context/AppState';

const headerHeight = 50;
const classes = {
    header: {
        padding: '0 8px',
        zIndex: theme => theme.zIndex.drawer - 1,
        height: headerHeight,
        overflow: 'hidden',
        '&>div': {
            height: headerHeight
        },
    },
};

const Header = styled('header')(unstable_styleFunctionSx);

const HeaderComponent = ({dashboardOpen, initialSelectedFolder}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    return (
        <Header sx={classes.header}>
            <Grid container alignItems="center">
                <Grid size="grow">
                    <Fade in={dashboardOpen} timeout={1000}>
                        <Typography variant="h1" noWrap>Clearance &ldquo;{initialSelectedFolder?.name}&rdquo;</Typography>
                    </Fade>
                </Grid>
                <Grid>
                    {
                        !!activeUser.value &&
                        <Slide in direction="left">
                            <Avatar
                                style={{height: 32, width: 32}}
                                alt={activeUser.value.name}
                                src={activeUser.value.photoUrl || undefined}>{activeUser.value.name.substring(0, 1)}</Avatar>
                        </Slide>
                    }
                    {
                        (!activeUser.value && dashboardOpen) &&
                        <Slide in direction="left">
                            <Typography variant="body1"><em>Somente leitura</em>. Conecte-se para trabalhar.</Typography>
                        </Slide>
                    }
                </Grid>
            </Grid>
        </Header>
    );
}

export default HeaderComponent;