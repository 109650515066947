import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from '../../context/AppState';

const DocumentListItemTopClient = ({ clientId }) => {
    useSignals();
    const { workspaceClients } = useAppStateCtx();
    return (
        <span>{workspaceClients.value[clientId]?.name || ''}</span>
    );
};

export default DocumentListItemTopClient;