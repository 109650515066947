import { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Select from '../../../../components/Select';
import Typography from '@mui/material/Typography';
import { useAppStateCtx } from '../../../../context/AppState';
import { getUserNameById } from '../../../../utils/common';

const CustomSelect = ({doc, field, fieldId}) => {
    useSignals();
    const { activeUser, workspaceUsers } = useAppStateCtx();
    const [lastValue, set_lastValue] = useState(null);
    const [selectedValue, set_selectedValue] = useState('');
    
    useEffect(() => {
        const documentCustomField = getDocumentCustomFieldById();
        if(documentCustomField){
            set_lastValue(documentCustomField);
            set_selectedValue(documentCustomField.value);
        }
    }, []);

    const getDocumentCustomFieldById = () => {
        const documentCustomFields = doc.customFields || {};
        return documentCustomFields[fieldId] || null;
    };
    const handleChange = async (e) => {
        let value = e.target.value;
        set_selectedValue(value);
        const documentCustomField = getDocumentCustomFieldById();
        if(!documentCustomField || documentCustomField.value !== value){
            const newValue = {
                value,
                valueAt: moment().toISOString(),
                valueBy: activeUser.value.uid
            };
            if(documentCustomField){
                const res = doc.update({
                    updates: {
                        [`customFields.${fieldId}.value`]: newValue.value,
                        [`customFields.${fieldId}.valueAt`]: newValue.valueAt,
                        [`customFields.${fieldId}.valueBy`]: newValue.valueBy
                    }
                });
                if(res.error){
                    return toast(`Não foi possível salvar o texto "${newValue.value}". Tente novamente.`, { type: 'error' });
                }
            } else {
                const res = doc.update({
                    updates: {
                        [`customFields.${fieldId}`]: newValue
                    }
                });
                if(res.error){
                    return toast(`Não foi possível salvar o texto "${newValue.value}". Tente novamente.`, { type: 'error' });
                }
            }
            set_lastValue(newValue);
        }
    };

    return (
        <Box>
            <Box>
                <Select style={{fontSize: 12}} optionsStyle={{fontSize: 12}} options={field.options.map(option => ({value: option, label: option}))} value={selectedValue} onChange={handleChange} />
            </Box>
            {
                (lastValue && (lastValue.valueAt || lastValue.valueBy)) &&
                <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Box>
                        <EditIcon style={{fontSize: 11}} />
                    </Box>
                    <Box>
                        <Typography variant="body2" style={{fontSize: 9}}>
                            <em>
                                {lastValue.valueAt ? moment(lastValue.valueAt).format('L LT') : ''}
                                {(lastValue.valueBy && workspaceUsers.value) ? ` por ${getUserNameById(workspaceUsers.value, lastValue.valueBy)}` : ''}
                            </em>
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
    );
};

export default CustomSelect;