import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';

const useGetClearanceNotesComments = (clearanceNoteId) => {
    useSignals();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(clearanceNoteId){
            setHookState(defaultHookState);
            const unsubscribe = onSnapshot(
                query(
                    collection(db, `clearance_notes/${clearanceNoteId}/comments`),
                    where('deleted', '==', false),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({...data, uid: snapshot.id});
                    });
                    setHookState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: dataArray,
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [clearanceNoteId]);

    return hookState;
};

export default useGetClearanceNotesComments;