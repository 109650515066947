import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import Autocomplete from '../Autocomplete';
import LoaderEllipsis from '../LoaderEllipsis';
import FilesField from '../FilesField';
import Input from '../Input';
import Select from '../Select';
import UserComment from '../UserComment';

import { useAppStateCtx } from '../../context/AppState';
import updateDoc from '../../firebase/firestore/updateDoc';
import useGetClearanceQuestionsComments from '../../hooks/useGetClearanceQuestionsComments';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';
import { sortByKey } from '../../utils/filters';

const classes = {
    closeButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: theme => theme.palette.grey[500],
    },
};

const ReplyQuestionWindow = ({
    actionCallback,
    activeUserIsOperator,
    getQuestionTemplate,
    handleCommentInputKeyPress,
    imageViewUrl,
    projectClearanceSettings,
    replyQuestionWindowOpen,
    selectedQuestion,
    selectedQuestionClearanceSettings, setSelectedQuestionClearanceSettings,
    selectedTemplate,
}) => {
    useSignals();
    const { activeUser, activeUserClearanceAuthorityLevel, workspaceClearanceTemplates } = useAppStateCtx();
    const filesFieldId = 'filesField';
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [replyComment, setReplyComment] = useState('');
    const [filesList, set_filesList] = useState([]);
    const [selectedRightType, setSelectedRightType] = useState(null);
    const [useTypeOptions, setUseTypeOptions] = useState([]);
    const [selectedUseType, setSelectedUseType] = useState('');
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');
    const [questionName, setQuestionName] = useState('');
    const [riskApproved, setRiskApproved] = useState('no');
    const [selectedQuestionTemplate, setSelectedQuestionTemplate] = useState(null);
    const [clearanceQuestionComments, setClearanceQuestionComments] = useState(null);
    const [approvalsRequired, setApprovalsRequired] = useState(null);
    const riskLevelNumbers = {
        one: 1,
        two: 2,
        three: 3,
        four: 4
    };

    useEffect(() => {
        if(!replyQuestionWindowOpen.value){
            setLoading(true);
        }
    }, [replyQuestionWindowOpen.value]);

    const retrievedClearanceQuestionComments = useGetClearanceQuestionsComments(selectedQuestion.value);
    useEffect(() => {
        if(replyQuestionWindowOpen.value && retrievedClearanceQuestionComments.data){
            setClearanceQuestionComments(retrievedClearanceQuestionComments.data);
        }
    }, [replyQuestionWindowOpen.value, retrievedClearanceQuestionComments]);

    useEffect(() => {
        let currentSelectedQuestionTemplate = null;
        if(replyQuestionWindowOpen.value && selectedQuestion.value){
            const questionTemplate = getQuestionTemplate(selectedQuestion.value?.projectId);
            if(questionTemplate) currentSelectedQuestionTemplate = questionTemplate;
        }
        setSelectedQuestionTemplate(currentSelectedQuestionTemplate);
    }, [replyQuestionWindowOpen.value, selectedQuestion.value, selectedQuestionClearanceSettings, workspaceClearanceTemplates.value]);

    useEffect(() => {
        if(replyQuestionWindowOpen.value && activeUserIsOperator && selectedQuestion.value?.projectId && selectedQuestion.value.projectId !== selectedQuestionClearanceSettings.projectId){
            setSelectedQuestionClearanceSettings({ projectId: selectedQuestion.value.projectId, data: null });
        }
    }, [replyQuestionWindowOpen.value, selectedQuestion.value]);

    useEffect(() => {
        if(replyQuestionWindowOpen.value && selectedQuestion.value && selectedQuestionTemplate && clearanceQuestionComments && ((activeUserIsOperator && workspaceClearanceTemplates.value) || (!activeUserIsOperator && selectedTemplate))){
            setLoading(false);
        }
    }, [replyQuestionWindowOpen.value, selectedQuestion.value, selectedQuestionTemplate, clearanceQuestionComments, workspaceClearanceTemplates.value, selectedTemplate]);    

    useEffect(() => {
        clearForm();
        if(replyQuestionWindowOpen.value && selectedQuestion.value){
            let currentQuestionName = '';
            if(selectedQuestion.value.episode){
                currentQuestionName += `Ep. ${selectedQuestion.value.episode}`;
            }
            if(selectedQuestion.value.scriptTreatment){
                currentQuestionName += `${currentQuestionName ? ' - ' : ''}Tratamento ${selectedQuestion.value.scriptTreatment}`;
            }
            if(selectedQuestion.value.scene){
                currentQuestionName += `${currentQuestionName ? ' - ' : ''}Cena ${selectedQuestion.value.scene}`;
            }
            if(selectedQuestion.value.subject){
                currentQuestionName += `${currentQuestionName ? ' - ' : ''}${selectedQuestion.value.subject}`;
            }
            setQuestionName(currentQuestionName);

            let currentRightType = selectedQuestion.value.rightType || null;
            let currentUseType = selectedQuestion.value.useType || '';
            let currentRiskLevel = selectedQuestion.value.riskLevel || '';
            setSelectedRightType(currentRightType);
            setSelectedUseType(currentUseType);
            setSelectedRiskLevel(currentRiskLevel);

            let currentRiskApproved;
            if(activeUser.value) currentRiskApproved = selectedQuestion.value.approvals[activeUser.value.uid] || '';
            setRiskApproved(currentRiskApproved || 'withholdOpinion');
        }
    }, [activeUser.value, replyQuestionWindowOpen.value, selectedQuestion.value]);

    useEffect(() => {
        if(replyQuestionWindowOpen.value && projectClearanceSettings){
            const currentApprovalsRequired = projectClearanceSettings?.approvals?.numberOfApprovalsRequired;
            setApprovalsRequired(currentApprovalsRequired);
        }
    }, [replyQuestionWindowOpen.value, projectClearanceSettings]);

    useEffect(() => {
        if(selectedQuestion.value && selectedQuestionTemplate && selectedRightType){
            const itemType = selectedQuestionTemplate.rightTypes[selectedRightType];
            const riskLevels = itemType.riskLevels;
            let options = [];
            for(const riskLevelKey in riskLevels){
                const riskLevel = riskLevels[riskLevelKey];
                const riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === riskLevelKey);
                options = [...options, ...Object.entries(riskLevel).map(([itemId, item]) => ({value: itemId, label: `Risco ${riskOption.label} - ${item.name}`, riskLevel: riskLevelKey}))];
            }
            setUseTypeOptions(
                options.sort((a, b) => {
                    const levels = { one: 1, two: 2, three: 3, four: 4 };
                    if(levels[a.riskLevel] > levels[b.riskLevel]){
                        return 1;
                    } else if(levels[b.riskLevel] > levels[a.riskLevel]){
                        return -1;
                    } else {
                        return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
                    }
                })
            );
        }
    }, [selectedQuestion.value, selectedQuestionTemplate, selectedRightType]);

    const setRead = async (activeUserId, readAt) => {
        await updateDoc(
            `${selectedQuestion.value.isClearanceReportNote ? 'clearance_notes' : 'clearance_questions'}/${selectedQuestion.value.uid}`,
            {
                [`commentsReadAt.${activeUserId}`]: readAt
            }
        )
    };
    useEffect(() => {
        if(replyQuestionWindowOpen.value && activeUser.value && selectedQuestion.value && clearanceQuestionComments && clearanceQuestionComments.length !== 0){
            const lastComment = clearanceQuestionComments[clearanceQuestionComments.length - 1];
            if(!selectedQuestion.value.commentsReadAt || selectedQuestion.value.commentsReadAt[activeUser.value.uid] !== lastComment.createdAt){
                setRead(activeUser.value.uid, lastComment.createdAt);
            }
        }
    }, [replyQuestionWindowOpen.value, activeUser.value, selectedQuestion.value, clearanceQuestionComments]);

    const handleSubmit = async () => {
        setSaving(true);

        const toastId = toast.loading(`Respondendo a consulta "${selectedQuestion.value.subject}"...`);

        let files, riskApprovalDescription, riskLevelDescription, rightTypeDescription, useTypeDescription;

        const rightTypeId = selectedRightType || '';
        if(rightTypeId && selectedQuestionTemplate){
            const foundRightType = selectedQuestionTemplate.rightTypes[rightTypeId];
            if(foundRightType) rightTypeDescription = foundRightType.name;
        }

        if(selectedRiskLevel){
            const foundRiskLevel = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === selectedRiskLevel);
            if(foundRiskLevel) riskLevelDescription = foundRiskLevel.label;

            if(selectedQuestion.value.firstReplySentByOperator && !activeUserIsOperator && ((approvalsRequired || {})[selectedRiskLevel] || 0) > 0 && activeUserClearanceAuthorityLevel.value >= riskLevelNumbers[selectedRiskLevel]){
                if(riskApproved !== 'withholdOpinion'){
                    riskApprovalDescription = riskApproved;
                }
            }
        }

        if(selectedUseType){
            const foundUseType = useTypeOptions.find(option => option.value === selectedUseType);
            if(foundUseType) useTypeDescription = foundUseType.label;
        }

        const filesInput = document.getElementById(filesFieldId);
        if(filesList.length !== 0){
            files = filesInput.files;
        }
        
        if(selectedQuestion.value.isClearanceReportNote){
            const clearanceNoteReplyArgs = {
                activeUser: activeUser.value,
                activeUserIsOperator,
                clearanceNoteId: selectedQuestion.value.reportItemId,
                comment: replyComment,
                comments: clearanceQuestionComments,
                filesList: files,
                rightType: selectedRightType || '',
                rightTypeDescription,
                riskApprovalDescription,
                riskLevel: selectedRiskLevel,
                riskLevelDescription,
                useType: selectedUseType,
                useTypeDescription
            }
            if(approvalsRequired) clearanceNoteReplyArgs.numberOfApprovalsRequired = approvalsRequired[selectedRiskLevel];

            const res = await selectedQuestion.value.reply(clearanceNoteReplyArgs);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        } else {
            
            const clearanceQuestionReplyArgs = {
                activeUser: activeUser.value,
                activeUserIsOperator,
                comment: replyComment,
                comments: clearanceQuestionComments,
                filesList: files,
                rightType: selectedRightType || '',
                rightTypeDescription,
                riskApprovalDescription,
                riskLevel: selectedRiskLevel,
                riskLevelDescription,
                useType: selectedUseType,
                useTypeDescription
            }
            if(approvalsRequired) clearanceQuestionReplyArgs.numberOfApprovalsRequired = approvalsRequired[selectedRiskLevel];
    
            const res = selectedQuestion.value.reply(clearanceQuestionReplyArgs);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        }

        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });

        if(filesList.length !== 0){
            if(filesInput) filesInput.value = '';
            set_filesList([]);
        }
        clearForm();

        if(actionCallback) actionCallback();
        
        handleClose();

        setSaving(false);
    }

    const handleApproveRiskButtonPress = (newValue) => {
        setRiskApproved(newValue);
    };

    const clearForm = () => {
        setQuestionName('');
        set_filesList([]);
        setReplyComment('');
        setSelectedRightType(null);
        setUseTypeOptions([]);
        setSelectedUseType('');
        setSelectedRiskLevel('');
    };

    const handleRightTypeChange = (newValue) => {
        setSelectedRightType(newValue);
        setSelectedUseType('');
        setSelectedRiskLevel('');
    };

    const handleUseTypeChange = (newValue) => {
        setSelectedUseType(newValue);
        const useType = useTypeOptions.find(option => option.value === newValue);
        setSelectedRiskLevel(useType.riskLevel);
    };

    const triggerFileInputClick = () => {
        const fileInput = document.getElementById(filesFieldId);
        if(fileInput) fileInput.click();
    };

    const handleClose = () => {
        replyQuestionWindowOpen.value = false;
        setClearanceQuestionComments(null);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={replyQuestionWindowOpen.value}
            // TransitionComponent={Transition}
            // keepMounted
            fullWidth
            maxWidth="md"
            // fullScreen
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">{!!activeUser.value ? 'Responder: ' : ''}{questionName}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={classes.closeButton}
                            aria-label="close"
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    loading
                    ? <LoaderEllipsis />
                    :
                    <Box mb={2}>
                        <Box mb={1}>
                            {/* <UserComment
                                comment={selectedQuestion.}
                                commentFiles={commentFiles}
                                hasFiles={hasFiles}
                                imageViewUrl={imageViewUrl}
                                userId={comment.createdBy}
                                userImage={comment.createdByImg}
                                userName={comment.createdByName}
                            /> */}
                            <Divider />
                        </Box>
                        {
                            clearanceQuestionComments &&
                            <>
                                {
                                    clearanceQuestionComments.map((comment) => {
                                        const commentFiles = comment.files;
                                        const hasFiles = commentFiles && Object.keys(commentFiles).length !== 0;
                                        return (
                                            <Box key={comment.uid} mb={1}>
                                                <UserComment
                                                    comment={comment}
                                                    commentFiles={commentFiles}
                                                    hasFiles={hasFiles}
                                                    imageViewUrl={imageViewUrl}
                                                    userId={comment.createdBy}
                                                    userImage={comment.createdByImg}
                                                    userName={comment.createdByName}
                                                />
                                                <Divider />
                                            </Box>
                                        )
                                    })
                                }
                            </>
                        }
                        {
                            !!activeUser.value &&
                            <Box>
                                {
                                    (selectedQuestion.value.firstReplySentByOperator && selectedRiskLevel && ((approvalsRequired || {})[selectedRiskLevel] || 0) > 0 && activeUserClearanceAuthorityLevel.value >= riskLevelNumbers[selectedRiskLevel]) &&
                                    <Box mb={1}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={9} sm={8} md={7}>
                                                <Select
                                                    label="Deseja aprovar o risco?"
                                                    options={[
                                                        {value: 'withholdOpinion', label: 'Não responder (aguardar informações)'},
                                                        {value: 'yes', label: <Grid container spacing={1} alignItems="center"><Grid item><ThumbUpAltOutlinedIcon /></Grid><Grid item xs>Aprovar o risco</Grid></Grid>},
                                                        {value: 'no', label: <Grid container spacing={1} alignItems="center"><Grid item><ThumbDownAltOutlinedIcon /></Grid><Grid item xs>Reprovar o risco</Grid></Grid>}
                                                    ]}
                                                    value={riskApproved} onChange={(e) => handleApproveRiskButtonPress(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handleApproveRiskButtonPress('yes')}>
                                                    <ThumbUpAltOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handleApproveRiskButtonPress('no')}>
                                                    <ThumbDownAltOutlinedIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                                <Box mb={1}>
                                    <Input
                                        placeholder="Sua resposta"
                                        autoFocus fullWidth multiline minRows={5} maxRows={20}
                                        value={replyComment} onChange={(e) => setReplyComment(e.target.value)}
                                        onKeyPress={handleCommentInputKeyPress}
                                    />
                                </Box>
                                <FilesField
                                    attachFileButtonComponent="none"
                                    multiple
                                    filesFieldId={filesFieldId}
                                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                                    filesList={filesList} setFilesList={set_filesList}
                                />
                                {
                                    (activeUserIsOperator && selectedQuestionTemplate) &&
                                    <Box mt={1}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    label="Direito envolvido"
                                                    options={Object.entries(selectedQuestionTemplate.rightTypes).map((([rightTypeId, rightType]) => ({...rightType, uid: rightTypeId}))).sort(sortByKey('name')).map(rightType => rightType.uid)}
                                                    getOptionLabel={(option) => selectedQuestionTemplate.rightTypes[option]?.name}
                                                    value={selectedRightType} onChange={(e, newValue) => handleRightTypeChange(newValue)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    optionsStyle={{fontSize: 14}} multiline
                                                    label="Condição de uso"
                                                    options={useTypeOptions}
                                                    value={selectedUseType} onChange={(e) => handleUseTypeChange(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    label="Nível de risco"
                                                    options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                                                    value={selectedRiskLevel} onChange={(e) => setSelectedRiskLevel(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                            </Box>
                        }
                    </Box>
                }
            </DialogContent>
            {
                !!activeUser.value &&
                <DialogActions>
                    <IconButton disabled={saving} size="small" onClick={triggerFileInputClick}>
                        <AttachFileIcon />
                    </IconButton>
                    <Button
                        onClick={handleSubmit}
                        variant="contained" color="primary"
                        disabled={saving || !replyComment || (activeUserIsOperator && (!selectedRightType || !selectedUseType || !selectedRiskLevel))}
                        startIcon={saving && <CircularProgress size={20} />}
                    >
                        Responder
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default ReplyQuestionWindow;