import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Notices from '../../../components/Notices';
import ViewBox from '../../../components/ViewBox';

const ClientNotices = () => {
    useSignals();

    return (
        <ViewBox>
            <Container maxWidth="md">
                <Box>
                    <Notices />
                </Box>
            </Container>
        </ViewBox>
    );
}

export default ClientNotices;