import { useEffect, useLayoutEffect, useState } from 'react';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import ItemWindow from './components/ItemWindow';
import ListWindow from './components/ListWindow';

import FilterInput from '../../../../components/FilterInput';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import MenuChip from '../../../../components/MenuChip';
import MultilineText from '../../../../components/MultilineText';
import Pagination from '../../../../components/Pagination';
import TinyTable from '../../../../components/TinyTable';
import ViewBox from '../../../../components/ViewBox';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { escapeRegex } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const ListSelector = ({selectedListId, setSelectedListId}) => {
    useSignals();
    const { workspaceLists } = useAppStateCtx();
    const [listOptions, setListOptions] = useState([]);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if(workspaceLists.value){
            const mappedLists = Object.entries(workspaceLists.value).map(([listId, list]) => ({value: listId, label: list.name})).sort(sortByKey('label'));
            setListOptions(mappedLists);
            if(mappedLists[0]) setSelectedListId(mappedLists[0].value);
        }
    }, [workspaceLists.value]);

    const getSelectedListLabelById = (listId) => {
        const foundList = listOptions.find(listOption => listOption.value === listId);
        return foundList?.label || '';
    };

    const handleListChange = (newValue) => {
        setSelectedListId(newValue);
        setMenuOpen(false);
    };

    const handleMenuOpen = (e) => {
        setMenuAnchorEl(e.currentTarget);
        setMenuOpen(true);
    };

    return (
        <>
            <MenuChip
                label="Lista"
                onPress={(e) => handleMenuOpen(e)}
                value={selectedListId} valueLabel={getSelectedListLabelById(selectedListId)}
            />
            <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {
                    listOptions.map((option, optionIndex) => (
                        <MenuItem
                            key={optionIndex}
                            onClick={() => handleListChange(option.value)}
                            selected={option.value === selectedListId}
                        >{option.label}</MenuItem>
                    ))
                }
            </Menu>
        </>
    )
}

const filterText = signal('');

const FilterText = () => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value])

    return (
        <FilterInput value={value} setValue={setValue} placeholder="Digite para filtrar" />
    );
};

const Pages = ({ count, currentPage, perPage, handlePageClick }) => {
    if(filterText.value.length === 0){
        return (
            <Pagination count={count} page={currentPage} perPage={perPage} onChange={(_, page) => handlePageClick(page)} />
        );
    }
    return null;
};

const listItemWindowOpen = signal(false);
const selectedListItem = signal(null);
 
function Lists(){ 
    useSignals();
    const { selectedWorkspace, workspaceLists, workspaceListsItems } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const [selectedListId, set_selectedListId] = useState('');
    const [selectedListName, setSelectedListName] = useState('');
    const [selectedItem, set_selectedItem] = useState(null);
    const [listWindowOpen, set_listWindowOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const [availableListItems, setAvailableListItems] = useState([]);
    const [visibleListItems, setVisibleListItems] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setFirestoreListener('workspaceLists');
        return () => {
            listItemWindowOpen.value = false;
            selectedListItem.value = null;
        }
    }, []);

    useEffect(() => {
        if(workspaceLists.value && selectedListId){
            setFirestoreListener('workspaceListsItems', selectedListId);
        }
    }, [workspaceLists.value, selectedListId]);

    const setListWithItems = () => {
        if(workspaceLists.value && selectedListId && workspaceListsItems.value){
            const foundList = workspaceLists.value[selectedListId];
            if(foundList){
                const foundListItems = workspaceListsItems.value[selectedListId];
                if(foundListItems){
                    setAvailableListItems(foundListItems.sort(sortByKey('key')));
                    return setSelectedListName(foundList.name);
                }
            }
        }
        setAvailableListItems([]);
    };
    useEffect(() => {
        setListWithItems();
    }, [workspaceLists.value, selectedListId, workspaceListsItems.value]);

    useEffect(() => {
        if(workspaceListsItems.value && workspaceListsItems.value[selectedListId] && visibleListItems){
            setLoading(false);
        }
    }, [
        workspaceListsItems.value,
        selectedListId,
        visibleListItems
    ]);

    const perPage = 100;

    const showPage = () => {
        if(availableListItems){
            const firstIndex = (currentPage - 1) * perPage;
            const lastIndex = (currentPage * perPage);
            const pageArray = availableListItems.slice(firstIndex, lastIndex);
            setVisibleListItems(pageArray);
        }
    }

    useLayoutEffect(() => {
        showPage();
    }, [availableListItems, currentPage]);

    useLayoutEffect(() => {
        if(filterText.value.length !== 0){
            setVisibleListItems(
                availableListItems.filter(listItem => {
                    const textFields = `${listItem.key}${listItem.description}`;
                    if(filterText.value.length === 1){
                        const regEx = new RegExp(`^${escapeRegex(filterText.value).toUpperCase()}`);
                        return regEx.test(textFields.toUpperCase());
                    }
                    return textFields.toUpperCase().indexOf(filterText.value.toUpperCase()) !== -1;
                })
            )
        } else {
            showPage();
        }
    }, [filterText.value]);

    const handleListWindowOpenClick = (listId) => {
        set_selectedListId(listId);
        set_listWindowOpen(true);
    };
    const handleItemWindowOpenClick = (currentSelectedListItem) => {
        selectedListItem.value = currentSelectedListItem;
        listItemWindowOpen.value = true;
    };
    
    const handleAddListItemClick = () => {
        handleItemWindowOpenClick(null);
    };

    const handleAddListClick = () => {
        handleListWindowOpenClick(null);
    };

    const handleChangeListItemClick = (clickedListItem) => {
        handleItemWindowOpenClick(clickedListItem.data);
    };

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>

            <Box mb={2}>
                <Grid container spacing={1} justifyContent="flex-end" alignContent="flex-end">
                    <Grid>
                        <ListSelector selectedListId={selectedListId} setSelectedListId={set_selectedListId} />
                    </Grid>
                    <Grid size="grow">
                        <FilterText />
                    </Grid>
                    <Grid size={{ xs: 3 }}></Grid>
                    <Grid>
                        <Button variant="contained" onClick={handleAddListClick} startIcon={<AddIcon />}>Nova Lista</Button>
                    </Grid>
                    {
                        selectedListId &&
                        <Grid>
                            <Button disabled={selectedListId === 'special_list_audiovisual_services' && !['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)} variant="contained" onClick={handleAddListItemClick} startIcon={<PlaylistAddIcon />}>Novo item</Button>
                        </Grid>
                    }
                </Grid>
            </Box>

            {
                loading
                ? <LoaderEllipsis />
                :
                <>
                    <Pages count={availableListItems.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    <Box pb={5}>
                        <Container maxWidth="lg">
                            <TinyTable
                                head={[
                                    {content: <Typography variant="body1">Nome</Typography>},
                                    {content: <Typography variant="body1">Variações</Typography>},
                                    {content: <Typography variant="body1">Descrição</Typography>},
                                ]}
                                body={
                                    (visibleListItems || []).map(listItem => {
                                        const aliases = listItem.aliases || [];
                                        const columns = [
                                            {content: <Typography variant="body2">{listItem.key}</Typography>},
                                            {content: <Typography variant="body2">{aliases.map((alias, aliasIndex) => (<Typography variant="body2" key={aliasIndex}>{alias}</Typography>))}</Typography>},
                                            {content: <Typography variant="body2"><MultilineText text={listItem.value} breaker="<br />" /></Typography>},
                                        ];
                                        return ({
                                            data: listItem,
                                            updatedAt: listItem.updatedAt,
                                            columns
                                        });
                                    }
                                )}
                                handleBodyRow={handleChangeListItemClick}
                            />
                        </Container>
                        <Pages count={availableListItems.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    </Box>
                </>
            }

            <ItemWindow
                listItemWindowOpen={listItemWindowOpen}
                selectedListItem={selectedListItem}
                selectedListName={selectedListName}
                data={{
                    selectedListId, selectedList: visibleListItems, set_selectedList: setVisibleListItems
                }}
            />

            <ListWindow
                open={listWindowOpen} setOpen={set_listWindowOpen}
                data={{
                    selectedItem, set_selectedItem, selectedListId, selectedList: visibleListItems, set_selectedList: setVisibleListItems
                }}
            />

        </ViewBox>
    );
}

export default Lists;