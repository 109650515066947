import { useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import WorkspaceCustomField from '../classes/WorkspaceCustomField';
import { db } from '../firebase/config';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';

const useGetWorkspaceProjectsCustomFields = (shouldSetListener) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value){
            setHookState(defaultHookState);
            const unsubscribe = onSnapshot(
                query(collection(db, `workspaces/${selectedWorkspace.value.uid}/custom_fields`)),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newCustomField = new WorkspaceCustomField({ ...data, uid: snapshot.id });
                        dataArray.push(newCustomField);
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspaceProjectsCustomFields;