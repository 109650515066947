import { useEffect, useState } from 'react';
import update from 'immutability-helper';
import queryString from 'query-string';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import TitlePatternElementWindow from './TitlePatternElementWindow';

import DocumentsTemplate from '../../../../../classes/DocumentsTemplate';
import AlertDialog from '../../../../../components/AlertDialog';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import ViewBox from '../../../../../components/ViewBox';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';
import { useAppCtxAPI } from '../../../../../context/SystemContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';

const classes = {
    list: {
        // flex: 'none',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
            height: 6
        },
    },
    elementBox: {
        backgroundColor: '#FFF5EE',
        border: '1px solid #E8E8E8',
        borderRadius: '3px',
        marginBottom: '4px',
        padding: '4px',
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const TitlePattern = () => {
    useSignals();
    const { selectedWorkspace, workspaceProjectsCustomFields } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { handleNavigate } = useAppCtxAPI();
    const [titlePattern, set_titlePattern] = useState([]);
    const [templateQuestions, set_templateQuestions] = useState([]);
    const [backDialogOpen, set_backDialogOpen] = useState(false);
    const [selectedTitlePatternElement, setSelectedTitlePatternElement] = useState(null);
    const [titlePatternElementWindowOpen, setTitlePatternElementWindowOpen] = useState(false);
    const [templateUserFields, setTemplateUserFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const dateTitlePatternElementValueOptions = [
        {value: 'Y', label: 'Ano (AAAA)'}, // YYYY
        {value: 'YY', label: 'Ano (AA)'},
        {value: 'DD-MM-YYYY', label: 'Data (DD-MM-AAAA)'},
        {value: 'DD-MM-YY', label: 'Data (DD-MM-AA)'},
        {value: 'YYYY-MM-DD', label: 'Data (AAAA-MM-DD)'},
        {value: 'YYYYMMDD', label: 'Data (AAAAMMDD)'},
    ];

    useEffect(() => {
        setFirestoreListener('workspaceProjectsCustomFields');
    }, []);

    const getTemplateById = async (templateId) => {
        if(templateId){
            setLoading(true);

            const selectedTemplate = new DocumentsTemplate({ uid: templateId });

            const getTitlePatternRes = await selectedTemplate.getTitlePattern();
            if(getTitlePatternRes.error || !getTitlePatternRes.result){
                setLoading(false);
                return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }
            
            const getFormRes = await selectedTemplate.getForm();
            if(getFormRes.error || !getFormRes.result){
                setLoading(false);
                return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }

            const mappedPattern = getTitlePatternRes.result.map((titlePatternElement, titlePatternElementIndex) => ({...titlePatternElement, id: (titlePatternElementIndex + 1).toString()}));
            set_titlePattern(mappedPattern);

            const mapQuestions = getFormRes.result.filter(question => !['h', 'heading'].includes(question.type)).map(question => ({value: question.name, label: question.name}));
            set_templateQuestions(mapQuestions);

            setLoading(false);
        }
    };

    useEffect(() => {
        const templateId = queryString.parse(window.location.search)?.id;
        getTemplateById(templateId);
    }, []);

    useEffect(() => {
        const templateId = queryString.parse(window.location.search)?.id;
        if(templateId && workspaceProjectsCustomFields.value){
            const selectedTemplateUserFields = workspaceProjectsCustomFields.value
            .filter(field => field.template.includes(templateId) && ['string', 'select'].includes(field.type))
            .map(field => ({ value: field.uid, label: field.name }));
            setTemplateUserFields(selectedTemplateUserFields);

        }
    }, [workspaceProjectsCustomFields.value]);

    const handle_backDialogOkClick = () => {
        set_backDialogOpen(false);
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes`);
    };
    const handle_titlePatternSaveClick = async () => {
        setLoading(true);
        const templateId = queryString.parse(window.location.search)?.id;
        const selectedTemplate = new DocumentsTemplate({ uid: templateId });
        const res = await selectedTemplate.updateTitle({
            updatedTitlePattern: titlePattern.map(element => ({ type: element.type, value: element.value }))
        });
        if(res.error){
            setLoading(false);
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        setLoading(false);
        toast(SUCCESS_MESSAGE_SAVED, { autoClose: 3000, type: 'success' });
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes`);
        
    };

    const handleTitleElementDragEnd = (data) => {
        set_titlePattern(prevState => {
            return update(prevState, {
                $splice: [
                    [data.source.index, 1],
                    [data.destination.index, 0, prevState[data.source.index]],
                ],
            })
        });
    };

    const handleTitlePatternElementWindowOpen = (titlePatternElement) => {
        setSelectedTitlePatternElement(titlePatternElement);
        setTitlePatternElementWindowOpen(true);
    };

    const handleAddTitlePatternElement = () => {
        setSelectedTitlePatternElement(null);
        setTitlePatternElementWindowOpen(true);
    };

    return (
        <ViewBox>
            {
                loading
                ? <LoaderEllipsis />
                :
                <Box>
                    <Box mb={3}>
                        <Typography variant="h1">Padrão de título</Typography>
                    </Box>
                    <Box>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <DragDropContext onDragEnd={handleTitleElementDragEnd}>
                                    <Droppable droppableId={'title-pattern-droppable-container'} direction="horizontal">
                                        {(provided, snapshot) => (
                                            <Div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                sx={classes.list}
                                                style={snapshot.isDraggingOver ? { backgroundColor: '#C0CCD1' } : {backgroundColor: 'white'}}
                                            >
                                                {
                                                    titlePattern.map((titlePatternElement, titlePatternElementIndex) => {
                                                        let userTemplateFieldName = '';
                                                        if(titlePatternElement.type === 'userTemplateField' && titlePatternElement.value){
                                                            const foundUserTemplateField = templateUserFields.find(field => field.value === titlePatternElement.value);
                                                            if(foundUserTemplateField) userTemplateFieldName = foundUserTemplateField.label;
                                                        }
                                                        
                                                        return (
                                                            <div key={titlePatternElement.id}>
                                                                <Draggable
                                                                    draggableId={titlePatternElement.id}
                                                                    index={titlePatternElementIndex}
                                                                    isDragDisabled={false}
                                                                >
                                                                    {(provided, snapshot) => (
                                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                            <Box sx={classes.elementBox}
                                                                                style={
                                                                                    snapshot?.isDragging
                                                                                    ? { backgroundColor: '#434343', borderColor: '#434343', color: '#f5f5f5' }
                                                                                    : (titlePatternElement.type === 'text' ? null : { backgroundColor: '#bfedd2', borderRadius: 8 })
                                                                                }
                                                                                onClick={() => handleTitlePatternElementWindowOpen(titlePatternElement)}
                                                                            >
                                                                                {
                                                                                    titlePatternElement.type === 'text'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>{titlePatternElement.value}</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'date'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>{dateTitlePatternElementValueOptions.find(option => option.value === titlePatternElement.value).label}</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'count'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Quantidade</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'question'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>{Array.isArray(titlePatternElement.value) ? '(descontinuado)' : titlePatternElement.value.questionName}</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'ifQuestionResponseIs'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Lógica "Se"</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'ifQuestionResponseIsNot'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Lógica "Se não"</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'userTemplateField'
                                                                                    ?
                                                                                    <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>Informação especial "{userTemplateFieldName}"</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    titlePatternElement.type === 'shortId'
                                                                                    ? <Typography variant="body1" noWrap>&nbsp;&bull;&nbsp;<em>ID do documento</em>&nbsp;&bull;&nbsp;</Typography>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </Box>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {provided.placeholder}
                                            </Div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={2}>
                        <Grid container>
                            <Grid item>
                                <Button startIcon={<AddIcon />} onClick={handleAddTitlePatternElement}>Elemento</Button>
                            </Grid>
                            <Grid item xs></Grid>
                            <Grid item>
                                <Button disabled={loading || titlePattern.length === 0} variant="contained" color="primary" startIcon={<SaveAltIcon />} onClick={handle_titlePatternSaveClick}>Salvar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            }
            <AlertDialog
                id="title-pattern-section-back-dialog"
                open={backDialogOpen} onClose={() => set_backDialogOpen(false)}
                text={`Você quer sair sem salvar?`}
                okButtonOnClick={handle_backDialogOkClick}
            />
            <TitlePatternElementWindow
                open={titlePatternElementWindowOpen} setOpen={setTitlePatternElementWindowOpen} selectedTitlePatternElement={selectedTitlePatternElement}
                dateTitlePatternElementValueOptions={dateTitlePatternElementValueOptions}
                setTitlePattern={set_titlePattern}
                templateQuestions={templateQuestions}
                templateUserFields={templateUserFields}
            />
        </ViewBox>
    );
}

export default TitlePattern;