import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import SectionTitle from '../SectionTitle';
import Signer from './Signer';
import Switch from '../Switch';

const Signers = (props) => {
    const { defaultSigner, eSignaturePlatform, signers, set_signers, orderSignatures, setOrderSignatures } = props;

    const handleSignerChange = (newValue, index, key) => {
        set_signers(prevState => [...prevState.slice(0, index), {...prevState[index], [key]: newValue}, ...prevState.slice(index + 1)]);
    };
    const removeSigner = (index) => {
        set_signers(prevState => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
    };
    
    const onDragEnd = (result) => {
        const { destination, source } = result;

        if(!destination) return;

        if(destination.index === source.index) return;
        
        let currentSigners = [...signers];
        const [removed] = currentSigners.splice(source.index, 1);
        currentSigners.splice(destination.index, 0, removed);
        set_signers(currentSigners);
    };

    if(signers){
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Box>
                    <SectionTitle title="Signatários" />
                    <Box mb={2}>
                        <Typography variant="body2" color="secondary">* Todos os campos são obrigatórios</Typography>
                    </Box>
                    <Box mb={3}>
                        <Switch
                            label={`As assinaturas deverão ser feitas na ordem abaixo${orderSignatures ? '. Arraste as caixas para alterar a ordem.' : '?'}`}
                            checked={orderSignatures}
                            onChange={() => setOrderSignatures(!orderSignatures)}
                        />
                    </Box>
                    <Box mb={4}>
    
                        <Droppable droppableId="signers-list">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        signers?.map((signer, index) => (
                                            <Signer
                                                key={signer.uid}
                                                data={{
                                                    eSignaturePlatform,
                                                    handleSignerChange,
                                                    index,
                                                    removeSigner,
                                                    signer
                                                }}
                                                defaultSigner={defaultSigner}
                                            />
                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        
                    </Box>
                    <Box style={{textAlign: 'center'}} mb={4}>
                        <Button
                            startIcon={<AddIcon />}
                            onClick={() => set_signers([...signers, {...defaultSigner, type: 'witness', uid: uuidv4()}])}
                        >Signatário</Button>
                    </Box>
                </Box>
            </DragDropContext>
        );
    }
    return null;
};

export default Signers;