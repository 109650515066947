import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '../../../../../components/Dialog/Dialog';
import Input from '../../../../../components/Input';
import Switch from '../../../../../components/Switch';
import VirtualizedAutocomplete from '../../../../../components/VirtualizedAutocomplete';
import WorkspaceListItem from '../../../../../classes/WorkspaceListItem';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';

const ItemWindow = ({data, listItemWindowOpen, selectedListItem, selectedListName}) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { mappedCNAEs } = useAppStateCtxUtils();
    const { selectedListId } = data;
    const defaultListItem = {
        key: {
            value: '',
            dirty: false
        },
        aliases: [],
        value: {
            value: '',
            dirty: false
        },
        cnaes: [],
        meiAllowed: false
    };
    const [listItem, setListItemState] = useState(defaultListItem);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(listItemWindowOpen.value){
            let currentKey = '', currentAliases = [], currentValue = '', currentCnaes = [], currentMeiAllowed = false;
            if(selectedListItem.value){
                currentKey = selectedListItem.value.key;
                currentAliases = selectedListItem.value.aliases || [];
                currentValue = selectedListItem.value.value.replace(/<br \/>/g, '\n');
                currentCnaes = selectedListItem.value.cnaes ? selectedListItem.value.cnaes.map(item => parseInt(item)) : [];
                currentMeiAllowed = selectedListItem.value.meiAllowed || false;
            }
            setListItemState({
                key: {value: currentKey, dirty: false},
                aliases: currentAliases.map(alias => ({value: alias, dirty: false})),
                value: {value: currentValue, dirty: false},
                cnaes: currentCnaes,
                meiAllowed: currentMeiAllowed
            })
        }
    }, [listItemWindowOpen.value, selectedListItem.value]);

    const handleSave = async () => {
        setSaving(true);
        const toastId = toast.loading(`Salvando "${listItem.key.value}"...`);
        if(selectedListItem.value){
            const res = await selectedListItem.value.update({
                updates: {
                    aliases: listItem.aliases.map(alias => alias.value),
                    cnaes: listItem.cnaes,
                    meiAllowed: listItem.meiAllowed,
                    value: listItem.value.value.replace(/\n/g, '<br />'),
                },
                workspaceId: selectedWorkspace.value.uid
            });
            setSaving(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        } else {
            const newWorkspaceListItem = new WorkspaceListItem({
                aliases: listItem.aliases.map(alias => alias.value),
                cnaes: listItem.cnaes,
                key: listItem.key.value,
                listId: selectedListId,
                meiAllowed: listItem.meiAllowed,
                value: listItem.value.value.replace(/\n/g, '<br />'),
            });
            const res = await newWorkspaceListItem.createListItem(selectedWorkspace.value.uid);
            setSaving(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        }

        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        handleClose();
    };

    // const handleDeleteListItemButtonPress = async () => {
    //     if(selectedItem.id){
    //         if(window.confirm('Tem certeza?')){
    //             //TODO
    //         }
    //     }
    // };

    const handleClose = () => {
        listItemWindowOpen.value = false;
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSave}
            open={listItemWindowOpen.value}
            saveButtonDisabled={!listItem.key || listItem.aliases?.includes('') || !listItem.value || (selectedListId === 'special_list_audiovisual_services' && ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role))}
            saving={saving}
            title={selectedListItem.value ? `${selectedListName ? `${selectedListName} | ` : ''}${selectedListItem.value.key}`  : 'Novo item'}
        >
            
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Nome</Typography>
                </Box>
                <Input
                    disabled={selectedListItem.value}
                    fullWidth required
                    value={listItem.key.value} onChange={(e) => setListItemState(prevState => ({...prevState, key: {...prevState['key'], value: e.target.value}}))}
                    error={listItem.key.dirty && !listItem.key.value}
                    helperText={listItem.key.dirty && !listItem.key.value ? 'Esta pergunta é obrigatória' : ''}
                    onBlur={() => setListItemState(prevState => ({...prevState, key: {...prevState['key'], dirty: true}}))}
                />
            </Box>
            <Box mb={2}>
                {
                    listItem.aliases?.map((alias, index) => (
                        <Box key={index}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Input
                                        required
                                        label={`Outro nome ${index + 1}`}
                                        value={alias.value} onChange={(e) => setListItemState(prevState => ({...prevState, aliases: [...prevState.aliases.slice(0, index), {...prevState['aliases'][index], value: e.target.value}, ...prevState.aliases.slice(index + 1)]}))}
                                        error={alias.dirty && !alias.value}
                                        helperText={alias.dirty && !alias.value ? 'Esta pergunta é obrigatória' : ''}
                                        onBlur={() => setListItemState(prevState => ({...prevState, aliases: [...prevState.aliases.slice(0, index), {...prevState['aliases'][index], dirty: true}, ...prevState.aliases.slice(index + 1)]}))}
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        onClick={() => setListItemState(prevState => ({...prevState, aliases: [...prevState.aliases.slice(0, index), ...prevState.aliases.slice(index + 1)]}))}
                                    ><DeleteIcon /></IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    ))
                }
                <Button
                    onClick={() => setListItemState(prevState => ({...prevState, aliases: [...prevState.aliases, {value: '', dirty: false}]}))}
                >Acrescentar outro nome</Button>
            </Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Descrição</Typography>
                </Box>
                <Input
                    fullWidth required multiline
                    value={listItem.value.value} onChange={(e) => setListItemState(prevState => ({...prevState, value: {...prevState['value'], value: e.target.value}}))}
                />
            </Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">CNAEs</Typography>
                </Box>
                <VirtualizedAutocomplete
                    multiple
                    getOptionLabel={(option) => {
                        const foundCNAE = mappedCNAEs.value.find(foundCNAE => foundCNAE.code === parseInt(option));
                        if(foundCNAE){
                            return `${foundCNAE.code} - ${foundCNAE.description}`;
                        }
                        return option;
                    }}
                    options={mappedCNAEs.value.map(item => item.code)}
                    value={listItem.cnaes} onChange={(_, newValue) => setListItemState(prevState => ({...prevState, cnaes: newValue}))}
                    variant="standard"
                />
            </Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">MEI</Typography>
                </Box>
                <Switch label="Essa atividade pode ser exercida por MEI?" labelPosition="left"
                    checked={listItem.meiAllowed} onChange={(e) => setListItemState(prevState => ({...prevState, meiAllowed: e.target.checked}))}
                />
            </Box>
            {
                // selectedItem &&
                // <Box mb={2}>
                //     <Grid container justifyContent="flex-end">
                //         <Grid item>
                //             <Button onClick={handleDeleteListItemButtonPress}>Excluir</Button>
                //         </Grid>
                //     </Grid>
                // </Box>
            }
        </Dialog>
    );
};

export default ItemWindow;