import { toast } from 'react-toastify';

import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useAppCtxAPI, useAppCtxOperatorsMenu } from '../context/SystemContext';
import setDoc from '../firebase/firestore/setDoc';
import { useOperatorCtxOperators } from '../pages/Operator/context/OperatorContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';

const ChangeOperatorMenu = ({collection, operatorIdKey, operatorChangedCallback}) => {
    const { showOperatorsMenu } = useAppCtxAPI();
    const operators = useOperatorCtxOperators();
    const { operatorsMenuAnchorEl, operatorsMenuOpen, operatorsMenuSelectedDocument } = useAppCtxOperatorsMenu();

    const handleOperatorChangeClick = async (operatorId, operatorName) => {
        handleClose();

        const toastId = toast.loading(`Atribuindo a ${operatorName}...`);

        const res = await setDoc(`${collection}/${operatorsMenuSelectedDocument.uid}`, {
            [operatorIdKey]: operatorId
        });
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        if(operatorChangedCallback) operatorChangedCallback(operatorId, operatorsMenuSelectedDocument.subject, operatorsMenuSelectedDocument.uid, operatorName);
    };

    const handleClose = () => {
        showOperatorsMenu(false);
    };

    return (
        <Menu
            anchorEl={operatorsMenuAnchorEl}
            open={operatorsMenuOpen}
            onClose={handleClose}
        >
            {
                operators.map((operator, index) => (
                    <MenuItem key={index} onClick={() => handleOperatorChangeClick(operator.uid, operator.name)}>
                        <ListItemIcon>
                            <Avatar style={{height: 32, width: 32}} alt={operator.name} src={operator.photoUrl} />
                        </ListItemIcon>
                        <ListItemText primary={operator.name} />
                    </MenuItem>
                ))
            }
        </Menu>
    );
}

export default ChangeOperatorMenu;