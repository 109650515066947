import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import CommentLine from '../../../../../../../../components/Documents/CommentLine';
import { useAppStateCtx } from '../../../../../../../../context/AppState';
import { getDocumentLastCommentIfHuman } from '../../../../../../../../utils/common';

const PendingDocumentComment = (props) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    const { pendingDocument } = props;
    const defaultPendingDocumentCommentState = { comment: '', noHtmlComment: '' };
    const [pendingDocumentComment, setPendingDocumentComment] = useState(defaultPendingDocumentCommentState);

    const getLastComent = () => {
        return getDocumentLastCommentIfHuman(pendingDocument, workspaceUsers.value);
    };

    useEffect(() => {
        if(pendingDocument){
            setPendingDocumentComment(getLastComent());
        }
    }, [pendingDocument]);

    return (
        <CommentLine {...pendingDocumentComment} />
    );
};

export default PendingDocumentComment;