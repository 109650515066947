import { collection, doc, runTransaction } from 'firebase/firestore';

import { db } from '../firebase/config';

export default class ProjectDocumentFormDraft {
    createdAt = '';
    createdBy = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    form = '';
    groupId = '';
    name = '';
    templateId = '';
    uid = '';
    updatedAt = '';

    constructor({
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        form,
        groupId,
        name,
        templateId,
        uid,
        updatedAt
    }: ProjectDocumentFormDraft){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.deleted = !!deleted;
        this.deletedAt = deletedAt || '';
        this.deletedBy = deletedBy || '';
        this.form = form || '';
        this.groupId = groupId || '';
        this.name = name || '';
        this.templateId = templateId || '';
        this.uid = uid || '';
        this.updatedAt = updatedAt || this.createdAt;
    }

    async saveProjectDocumentFormDraft(projectId: string, savedFormId: string){
        let result = null, error = null;

        const projectDocumentFormDraftRef = doc(collection(db, `projects/${projectId}/documents_forms_drafts`));
        
        const {uid: _, ...newProjectDocumentFormDraft} = this;

        try {
            await runTransaction(db, async (transaction) => {
                if(savedFormId){
                    transaction.update(doc(db, `projects/${projectId}/documents_forms_drafts/${savedFormId}`), {
                        form: this.form,
                        groupId: this.groupId,
                        name: this.name,
                        updatedAt: this.updatedAt
                    });
                } else {
                    transaction.set(projectDocumentFormDraftRef, newProjectDocumentFormDraft);
                }
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}