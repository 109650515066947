import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import EffectsWindow from '../components/EffectsWindow';
import NewEffectWindow from '../components/NewEffect';
import TreeWindow from '../components/TreeWindow';
import { useAppContext } from '../../../../../../context/SystemContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxConstants, useOperatorTemplatesCtxTemplateDocumentEditorValue, useOperatorTemplatesCtxTemplateEffects } from '../../context/TemplatesContext';
import { v4 as uuidv4 } from 'uuid';

const DocumentEditorContext = createContext();

const DocumentEditorProvider = ({children}) => {
    const { formEffects } = useAppContext();
    const { addTemplateEffect, deleteTemplateEffect, setDocumentEditorValue, setTemplateEffects, updateTemplateEffect } = useOperatorTemplatesCtxAPI();
    const { specialEffects, userCreatedFieldsBooleanOptions } = useOperatorTemplatesCtxConstants();
    const documentEditorValue = useOperatorTemplatesCtxTemplateDocumentEditorValue();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const [selectedEffectOutput, set_selectedEffectOutput] = useState(null);
    const [levelEffects, set_levelEffects] = useState([]);
    const [secondaryEditorWindowOpen, set_secondaryEditorWindowOpen] = useState(false);
    const [mainContent, setMainContent] = useState('');
    const [mainContentOpen, setMainContentOpen] = useState(true);
    const [effectsWindowOpen, set_effectsWindowOpen] = useState(false);
    const [createEffectWindowOpen, setCreateEffectWindowOpen] = useState(false);
    const [effectsTreeWindowOpen, setEffectsTreeWindowOpen] = useState(false);
    const [clickedEffectId, set_clickedEffectId] = useState('');
    const [clickedEffect, set_clickedEffect] = useState(null);
    const [copiedEffect, set_copiedEffect] = useState(null);
    const doubleClickedEffectIdRef = useRef('');
    const [doubleClickedEffectTimestamp, setDoubleClickedEffectTimestamp] = useState(null);
    const [doubleClickedEffect, set_doubleClickedEffect] = useState(null);
    const [execCommand, setExecCommand] = useState(null);
    const [scrollPositions, setScrollPositions] = useState([
        { effectId: 'main', scrollTop: 0 }
    ]);
    const [scrollPositionUpdated, setScrollPositionUpdated] = useState(true);
    const tagPattern = /<span class="solida.*?<\/span>/g;
    const tagIdPattern = /<span class="solida.*?data-id="(.*?)"/;
    const listOutputOptions = [
        {value: 'key', label: 'Nome'},
        {value: 'value', label: 'Descrição'},
        {value: 'custom', label: 'Personalizar'},
    ];

    useEffect(() => {
        checkEffects(documentEditorValue);
    }, [documentEditorValue]);

    useEffect(() => {
        if(!scrollPositionUpdated){
            const tinymceActiveEditor = window.tinymce?.activeEditor;
            if(tinymceActiveEditor){
                const scrollPositionsIndex = getScrollPositionsIndex(scrollPositions);
                if(scrollPositionsIndex >= 0){
                    setTimeout(() => {
                        tinymceActiveEditor.contentDocument.documentElement.scrollTop = scrollPositions[scrollPositionsIndex].scrollTop;
                    }, 50);
                } else {
                    tinymceActiveEditor.getBody().firstChild.scrollIntoView();
                }
                setScrollPositionUpdated(true);
            }
        }
    }, [documentEditorValue, scrollPositionUpdated]);

    useEffect(() => {
        if(clickedEffectId){
            const effect = templateEffects.find(i => i.id === clickedEffectId);
            if(effect) set_clickedEffect(effect);
        } else {
            set_clickedEffect(null);
        }
    }, [clickedEffectId]);

    useEffect(() => {
        if(doubleClickedEffectIdRef.current){
            const effect = templateEffects.find(i => i.id === doubleClickedEffectIdRef.current);
            if(effect) set_doubleClickedEffect(effect);
        } else {
            set_doubleClickedEffect(null);
        }
    }, [doubleClickedEffectTimestamp]);

    useEffect(() => {
        if(selectedEffectOutput){
            let newValue = '';
            const effect = templateEffects.find(scannedEffect => scannedEffect.id === selectedEffectOutput.effect);
            if(effect){
                const foundValue = formEffects[effect.type].getSelectedEffectOutput({selectedEffect: effect, selectedEffectOutput});
                if(foundValue) newValue = formEffects[effect.type].getSelectedEffectOutput({selectedEffect: effect, selectedEffectOutput});
            }
            setDocumentEditorValue(newValue);
        }
    }, [selectedEffectOutput]);

    const tagTemplate = `<span class="solida-effect mceNonEditable" data-id="{{effectId}}">&nbsp;&bull; {{effectLabel}} &bull;&nbsp;</span>`;
    const party1NameEffect = { type: 'special', id: 'clientName', label: '[Cliente: Nome]' };
    const party1NameTag = tagTemplate.replace('{{effectId}}', party1NameEffect.id).replace('{{effectLabel}}', party1NameEffect.label);
    const party2IndividualNameEffect = {
        type: 'text', id: uuidv4(), question: 'Nome completo', label: 'Texto: Nome completo'
    };
    const party2IndividualNameTag = tagTemplate.replace('{{effectId}}', party2IndividualNameEffect.id).replace('{{effectLabel}}', party2IndividualNameEffect.label);
    const party2CorporationNameEffect = {
        type: 'text', id: uuidv4(), question: 'Razão social', label: 'Texto: Razão social'
    };
    const party2CorporationNameTag = tagTemplate.replace('{{effectId}}', party2CorporationNameEffect.id).replace('{{effectLabel}}', party2CorporationNameEffect.label);
    const party2CorporationRepresentativeNameEffect = {
        type: 'text', id: uuidv4(), question: 'Nome completo do(a) representante legal', label: 'Texto: Nome completo do(a) representante legal'
    };
    const party2CorporationRepresentativeNameTag = tagTemplate.replace('{{effectId}}', party2CorporationRepresentativeNameEffect.id).replace('{{effectLabel}}', party2CorporationRepresentativeNameEffect.label);
    const party2CorporationCoordinatorNameEffect = {
        type: 'text', id: uuidv4(), question: 'Nome completo do(a) profissional responsável', label: 'Texto: Nome completo do(a) profissional responsável'
    };
    const party2CorporationCoordinatorNameTag = tagTemplate.replace('{{effectId}}', party2CorporationCoordinatorNameEffect.id).replace('{{effectLabel}}', party2CorporationCoordinatorNameEffect.label);
    const getParty2CorporationCoordinatorEffect = (options) => {
        const party2CorporationCoordinatorEffect = {
            type: 'radio', id: uuidv4(),
            question: 'Profissional responsável', label: 'Profissional responsável',
            options: [
                { option: 'Não se aplica (é irrelevante quem da empresa executará o contrato)', output: options[0] },
                { option: 'O(A) representante legal da pessoa jurídica', output: options[1] },
                { option: 'Outro(a)', output: options[2] }
            ]
        };
        const party2CorporationCoordinatorTag = tagTemplate.replace('{{effectId}}', party2CorporationCoordinatorEffect.id).replace('{{effectLabel}}', party2CorporationCoordinatorEffect.label);
        return { party2CorporationCoordinatorEffect, party2CorporationCoordinatorTag };
    };
    const getParty2Effect = (options) => {
        const party2Effect = {
            type: 'radio', id: uuidv4(),
            question: 'A outra parte é pessoa física ou pessoa jurídica?', label: 'A outra parte é pessoa física ou pessoa jurídica?',
            options: [
                { option: 'Pessoa física', output: options[0] },
                { option: 'Pessoa jurídica', output: options[1] }
            ]
        };
        const party2Tag = tagTemplate.replace('{{effectId}}', party2Effect.id).replace('{{effectLabel}}', party2Effect.label);
        return { party2Effect, party2Tag };
    };
    const replaceTagId = (tag, newId) => {
        tag = tag.replace(/data-id=".*?"/, `data-id="${newId}"`);
        return tag;
    };
    const getEffectTag = (defaultEffect, defaultTag) => {
        const foundEffect = templateEffects.find(scannedEffect => scannedEffect.type === defaultEffect.type && scannedEffect.question === defaultEffect.question);    
        if(foundEffect){
            return replaceTagId(defaultTag, foundEffect.id);
        }
        return defaultTag;
    };
    const createEffectsIfNew = (newEffects) => {
        newEffects.forEach(newEffect => {
            let shouldCreateEffect = true;
            if(newEffect.type === 'text'){
                const foundEffect = templateEffects.find(scannedEffect => scannedEffect.id === newEffect.id);
                if(foundEffect) shouldCreateEffect = false;
            } else if(newEffect.type === 'special'){
                const foundEffect = templateEffects.find(scannedEffect => scannedEffect.type === newEffect.type && scannedEffect.id === newEffect.id);
                if(foundEffect) shouldCreateEffect = false;
            }
            if(shouldCreateEffect){
                addTemplateEffect(newEffect);
            }
        });
    };
    
    useEffect(() => {
        if(execCommand){
            const availableCommands = {
                copyEffect: (data) => {
                    const effectId = data.id;
                    const foundEffect = templateEffects.find(templateEffect => templateEffect.id === effectId);
                    set_copiedEffect(foundEffect);
                },
                createEffect: () => {
                    setCreateEffectWindowOpen(true);
                },
                createContractFooterEffects: () => {
                    const signatureLine = '______________________________';
                    const { party2CorporationCoordinatorEffect, party2CorporationCoordinatorTag } = getParty2CorporationCoordinatorEffect([
                        '',
                        `<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>${signatureLine}</p><p>${getEffectTag(party2CorporationRepresentativeNameEffect, party2CorporationRepresentativeNameTag)}</p><p>(INTERVENIENTE)</p>`,
                        `<p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>${signatureLine}</p><p>${getEffectTag(party2CorporationCoordinatorNameEffect, party2CorporationCoordinatorNameTag)}</p><p>(INTERVENIENTE)</p>`,
                    ]);
                    const { party2Effect, party2Tag } = getParty2Effect([
                        `<p>${getEffectTag(party2IndividualNameEffect, party2IndividualNameTag)}</p><p>(CONTRATADA)</p>`,
                        `<p>${getEffectTag(party2CorporationNameEffect, party2CorporationNameTag)}</p><p>(CONTRATADA)${party2CorporationCoordinatorTag}</p>`,
                    ]);

                    const newEffects = [
                        party1NameEffect,
                        party2IndividualNameEffect,
                        party2CorporationNameEffect,
                        party2CorporationRepresentativeNameEffect,
                        party2CorporationCoordinatorNameEffect,
                        party2CorporationCoordinatorEffect,
                        party2Effect
                    ];
                    createEffectsIfNew(newEffects);

                    window.tinymce.execCommand(
                        'mceInsertContent',
                        false,
                        `<table cellpadding="8" border="0" style="width: 100%; border-style: none"><tbody><tr><td style="width: 50%"><p>${signatureLine}</p><p>${party1NameTag}</p><p>(CONTRATANTE)</p></td><td style="width: 50%"><p>${signatureLine}</p><p>${party2Tag}</p></td></tr></tbody></table><p>&nbsp;</p><p>Testemunhas:</p><table cellpadding="8" border="0" style="width: 100%; border-style: none"><tbody><tr><td style="width: 50%"><p>${signatureLine}</p><p>Nome:</p><p>CPF:</p></td><td style="width: 50%"><p>${signatureLine}</p><p>Nome:</p><p>CPF:</p></td></tr></tbody></table>`
                    );
                },
                createContractHeaderEffects: () => {
                    const party1DescriptionEffect = { type: 'special', id: 'clientDataFlowingExceptName', label: '[Cliente: Dados completos em texto corrido, exceto o nome]' };
                    const party1DescriptionTag = tagTemplate.replace('{{effectId}}', party1DescriptionEffect.id).replace('{{effectLabel}}', party1DescriptionEffect.label);
                    const party2IndividualIdEffect = {
                        type: 'text', id: uuidv4(), question: 'CPF', label: 'Texto: CPF'
                    };
                    const party2IndividualIdTag = tagTemplate.replace('{{effectId}}', party2IndividualIdEffect.id).replace('{{effectLabel}}', party2IndividualIdEffect.label);
                    const party2CorporationIdEffect = {
                        type: 'text', id: uuidv4(), question: 'CNPJ', label: 'Texto: CNPJ'
                    };
                    const party2CorporationIdTag = tagTemplate.replace('{{effectId}}', party2CorporationIdEffect.id).replace('{{effectLabel}}', party2CorporationIdEffect.label);
                    const party2AddressEffect = {
                        type: 'text', id: uuidv4(), question: 'Endereço completo', label: 'Texto: Endereço completo'
                    };
                    const party2AddressTag = tagTemplate.replace('{{effectId}}', party2AddressEffect.id).replace('{{effectLabel}}', party2AddressEffect.label);
                    const party2CorporationRepresentativeIdEffect = {
                        type: 'text', id: uuidv4(), question: 'CPF do(a) representante legal', label: 'Texto: CPF do(a) representante legal'
                    };
                    const party2CorporationRepresentativeIdTag = tagTemplate.replace('{{effectId}}', party2CorporationRepresentativeIdEffect.id).replace('{{effectLabel}}', party2CorporationRepresentativeIdEffect.label);
                    const party2CorporationCoordinatorIdEffect = {
                        type: 'text', id: uuidv4(), question: 'CPF do(a) profissional responsável', label: 'Texto: CPF do(a) profissional responsável'
                    };
                    const party2CorporationCoordinatorIdTag = tagTemplate.replace('{{effectId}}', party2CorporationCoordinatorIdEffect.id).replace('{{effectLabel}}', party2CorporationCoordinatorIdEffect.label);
                    const { party2CorporationCoordinatorEffect, party2CorporationCoordinatorTag } = getParty2CorporationCoordinatorEffect([
                        '',
                        `, e <strong>${getEffectTag(party2CorporationRepresentativeNameEffect, party2CorporationRepresentativeNameTag)}</strong> ("INTERVENIENTE"), inscrito(a) no CPF sob o nº ${getEffectTag(party2CorporationRepresentativeIdEffect, party2CorporationRepresentativeIdTag)}`,
                        `, e <strong>${getEffectTag(party2CorporationCoordinatorNameEffect, party2CorporationCoordinatorNameTag)}</strong> ("INTERVENIENTE"), inscrito(a) no CPF sob o nº ${getEffectTag(party2CorporationCoordinatorIdEffect, party2CorporationCoordinatorIdTag)}`
                    ]);
                    const { party2Effect, party2Tag } = getParty2Effect([
                        `<strong>${getEffectTag(party2IndividualNameEffect, party2IndividualNameTag)}</strong> ("CONTRATADA"), inscrito(a) no CPF sob o nº ${getEffectTag(party2IndividualIdEffect, party2IndividualIdTag)}, residente e domiciliado(a) em/na ${getEffectTag(party2AddressEffect, party2AddressTag)}`,
                        `<strong>${getEffectTag(party2CorporationNameEffect, party2CorporationNameTag)}</strong> ("CONTRATADA"), inscrita no CNPJ sob o nº ${getEffectTag(party2CorporationIdEffect, party2CorporationIdTag)}, com endereço em/na ${getEffectTag(party2AddressEffect, party2AddressTag)}, representada por ${getEffectTag(party2CorporationRepresentativeNameEffect, party2CorporationRepresentativeNameTag)}, inscrito(a) no CPF sob o nº ${getEffectTag(party2CorporationRepresentativeIdEffect, party2CorporationRepresentativeIdTag)}${party2CorporationCoordinatorTag}`
                    ]);

                    const newEffects = [
                        party1NameEffect,
                        party1DescriptionEffect,
                        party2IndividualNameEffect,
                        party2CorporationNameEffect,
                        party2IndividualIdEffect,
                        party2CorporationIdEffect,
                        party2AddressEffect,
                        party2CorporationRepresentativeNameEffect,
                        party2CorporationRepresentativeIdEffect,
                        party2CorporationCoordinatorEffect,
                        party2CorporationCoordinatorNameEffect,
                        party2CorporationCoordinatorIdEffect,
                        party2CorporationCoordinatorEffect,
                        party2Effect,
                    ];
                    createEffectsIfNew(newEffects);

                    window.tinymce.execCommand(
                        'mceInsertContent',
                        false,
                        `De um lado, <strong>${party1NameTag}</strong> ("CONTRATANTE")${party1DescriptionTag}, e, de outro lado, ${party2Tag}.`
                    );
                },
                deleteEffect: (data) => {
                    const effectId = data.id;
                    const foundEffect = templateEffects.find(templateEffect => templateEffect.id === effectId);
                    deleteAllEffectInstances(foundEffect);
                },
                insertCopiedEffect: () => {
                    pasteEffect();
                },
                insertPageBreak: () => {
                    window.tinymce.execCommand(
                        'mceInsertContent',
                        false,
                        `<hr class="pb">`
                    );
                },
                insertSpecialEffect: (data) => {
                    createEffect(data);
                },
            };
            availableCommands[execCommand.command](execCommand.data);
            setExecCommand(null);
        }
    }, [execCommand]);
    
    const getDocumentContent = () => {
        // get content from all levels
        let documentContent = documentEditorValue;
        if(!mainContentOpen) documentContent += mainContent;
        templateEffects.forEach((scannedEffect) => {
            if(formEffects[scannedEffect.type].getOutputsContent){
                documentContent += formEffects[scannedEffect.type].getOutputsContent({ scannedEffect });
            }
        });
        return documentContent;
    };
    
    const checkEffects = () => {
        const documentContent = getDocumentContent();

        // search for effects
        if(documentContent){
            const documentEffects = documentContent.match(tagPattern);
            if(documentEffects){
                let uniqueEffects = [];
                documentEffects.forEach((documentEffect) => {
                    const foundEffect = tagIdPattern.exec(documentEffect);
                    if(foundEffect){
                        const foundEffectId = foundEffect[1];
                        if(!uniqueEffects.includes(foundEffectId)) uniqueEffects.push(foundEffectId);
                    }
                });
                templateEffects.forEach(scannedEffect => {
                    const foundEffect = uniqueEffects.includes(scannedEffect.id);
                    if(!foundEffect){
                        //if templateEffect not found in uniqueEffects array, remove from templateEffects list
                        deleteTemplateEffect(scannedEffect.id);
    
                        if(scannedEffect.id === doubleClickedEffectIdRef.current){
                            doubleClickedEffectIdRef.current = '';
                            setDoubleClickedEffectTimestamp(new Date());
                        }
                    }
                });
            } else {
                setTemplateEffects([]);
                doubleClickedEffectIdRef.current = '';
                setDoubleClickedEffectTimestamp(new Date());
            }
        }
    };
    
    const getMousePosition = (event) => {
        const mouseX = event.clientX - 2;
        const topUIHeight = 120 - 39;
        const mouseY = event.clientY + topUIHeight - 4;
        return {X: mouseX, Y: mouseY};
    };
    const onEffectDoubleClick = useCallback((e, effectId = null) => {
        if(!effectId){
            let el = e.target;
            if(el.classList.contains('solida-effect') && !el.classList.contains('textOption-output')) effectId = el.getAttribute('data-id');
        }
        if(effectId){
            doubleClickedEffectIdRef.current = effectId;
            setDoubleClickedEffectTimestamp(new Date());
            set_effectsWindowOpen(true);
        }
    }, []);
    const createEffect = (data, callback) => {
        const newEffectId = data.id || uuidv4();
        const { foundEffect, newEffect } = formEffects[data.type].createEffect({
            effectSettings: data, newEffectId, specialEffects, templateEffects, userCreatedFieldsBooleanOptions
        });

        if(foundEffect){
            insertEffect(foundEffect.id, foundEffect.label);
            if(callback) callback();
            return newEffect;
        } else {
            addTemplateEffect(newEffect);
            insertEffect(newEffect.id, newEffect.label);
            if(callback) callback();
            return newEffect;
        }   
    };
    const insertCurrentEffectById = (effectId, callback) => {
        let label = '';
        const foundEffect = templateEffects.find(templateEffect => templateEffect.id === effectId);
        if(foundEffect){
            if(foundEffect.type !== 'special'){
                label = foundEffect.question;
            } else {
                const specialEffect = specialEffects.find(specialEffect => specialEffect.value === effectId);
                label = specialEffect.label;
            }
            insertEffect(effectId, label);
            if(callback) callback();
        }
    };
    const insertEffect = (effectId, effectLabel) => {
        window.tinymce.execCommand(
            'mceInsertContent',
            false,
            `<span class="solida-effect mceNonEditable" data-id="${effectId}">&nbsp;&bull; ${effectLabel} &bull;&nbsp;</span>`
        );
    };
    
    const pasteEffect = async () => {
        if(copiedEffect){
            const getChildEffectNewId = (copiedEffect) => {
                copiedEffect = JSON.parse(JSON.stringify(copiedEffect));
                const copiedEffectType = copiedEffect.type;
                let createNewEffect = true;
                if(copiedEffectType === 'text'){
                    createNewEffect = true;
                } else if(copiedEffectType === 'radio'){
                    copiedEffect.options = copiedEffect.options.map((copiedEffectOption) => {
                        return mapEffectOption(copiedEffectOption);
                    });
                    if(copiedEffect.textOption && copiedEffect.textOption.show){
                        copiedEffect.textOption = mapEffectOption(copiedEffect.textOption);
                    }                    
                } else if(copiedEffectType === 'list'){
                    if(copiedEffect.typeSpecifics === 'custom'){
                        copiedEffect.options = copiedEffect.options.map((copiedEffectOption) => {
                            return mapEffectOption(copiedEffectOption);
                        });
                    } else {
                        createNewEffect = true;
                    }
                } else if(copiedEffectType === 'if'){
                    copiedEffect = mapEffectOption(copiedEffect, 'output');
                    copiedEffect = mapEffectOption(copiedEffect, 'elseOutput');
                } else if(copiedEffectType === 'ifNot'){
                    copiedEffect = mapEffectOption(copiedEffect, 'output');
                    copiedEffect = mapEffectOption(copiedEffect, 'elseOutput');
                } else if(copiedEffectType === 'ifTrue'){
                    copiedEffect = mapEffectOption(copiedEffect, 'output');
                    copiedEffect = mapEffectOption(copiedEffect, 'elseOutput');
                } else if(copiedEffectType === 'special'){
                    if(copiedEffect.special === 'if'){
                        copiedEffect = mapEffectOption(copiedEffect, '');
                        copiedEffect = mapEffectOption(copiedEffect, 'elseOutput');
                    } else if(copiedEffect.special === 'ifNot'){
                        copiedEffect = mapEffectOption(copiedEffect, 'output');
                        copiedEffect = mapEffectOption(copiedEffect, 'elseOutput');
                    } else if(copiedEffect.special === 'ifTrue'){
                        copiedEffect = mapEffectOption(copiedEffect, 'output');
                        copiedEffect = mapEffectOption(copiedEffect, 'elseOutput');
                    } else if(copiedEffect.special === 'customList'){
                        copiedEffect.options = copiedEffect.options.map((effectOption) => {
                            return mapEffectOption(effectOption);
                        });
                    } else {
                        createNewEffect = false;
                    }
                } else {
                    createNewEffect = false;
                }
                if(createNewEffect){
                    const newEffectId = uuidv4();
                    const effectObject = {...copiedEffect, id: newEffectId};
                    addTemplateEffect(effectObject);
                    return newEffectId;
                }
                return copiedEffect.id;
            };
            const mapEffectOption = (effectOption, outputKey = 'output') => {
                const effectOptionOutput = effectOption[outputKey];
                if(effectOptionOutput){
                    const effectOptionOutputTags = effectOptionOutput.match(tagPattern);
                    if(effectOptionOutputTags){
                        effectOptionOutputTags.forEach(effectOptionOutputTag => {
                            const effectOptionOutputTagRegExData = tagIdPattern.exec(effectOptionOutputTag);
                            if(effectOptionOutputTagRegExData){
                                const foundEffectId = effectOptionOutputTagRegExData[1];
                                const foundEffect = templateEffects.find(scannedEffect => scannedEffect.id === foundEffectId);
                                if(foundEffect){
                                    const childEffectNewId = getChildEffectNewId(foundEffect);
                                    if(childEffectNewId){
                                        effectOption[outputKey] = effectOption[outputKey].replace(foundEffectId, childEffectNewId);
                                    }
                                }
                            }
                        });
                    }
                }
                return effectOption;
            };
            
            const mainSheetNewEffectId = getChildEffectNewId(copiedEffect);
            insertEffect(mainSheetNewEffectId, copiedEffect.label);
        }
    };
    const storeEffectOutput = () => {
        if(selectedEffectOutput){
            const effect = templateEffects.find(i => i.id === selectedEffectOutput.effect);
            if(effect){
                const updatedEffect = formEffects[effect.type].setEffectOutput({documentEditorValue, selectedEffect: effect, selectedEffectOutput});
                updateTemplateEffect(updatedEffect);
            }
        }
    };
    const handleCopyEffect = (effect) => {
        const textArea = document.getElementById('tinyEditorBox');
        textArea.setAttribute('data-copiedEffectId', effect.id);
        set_copiedEffect(effect);
        set_effectsWindowOpen(false);
        setEffectsTreeWindowOpen(false);
    };
    const getScrollPositionsIndex = (scrollPositions) => {
        const scrollPositionsIndex = scrollPositions.findIndex(scrollPosition => {
            if(selectedEffectOutput){
                return scrollPosition.effectId === selectedEffectOutput.effect && scrollPosition.optionIndex === selectedEffectOutput.optionIndex;
            }
            return scrollPosition.effectId === 'main';
        });
        return scrollPositionsIndex;
    };
    const handle_effectRadioOutputButtonClick = useCallback((effectId, optionIndex) => {
        setScrollPositionUpdated(false);
        const tinymceActiveEditor = window.tinymce.activeEditor;
        const currentScrollPosition = tinymceActiveEditor.contentDocument.documentElement.scrollTop;
        const newScrollPosition = {
            effectId: 'main',
            scrollTop: currentScrollPosition
        };
        if(!mainContentOpen && selectedEffectOutput){
            newScrollPosition.effectId = selectedEffectOutput.effect;
            newScrollPosition.optionIndex = selectedEffectOutput.optionIndex;
        }
        const scrollPositionsIndex = getScrollPositionsIndex(scrollPositions);
        setScrollPositions(prevState => scrollPositionsIndex >= 0
            ? 
            [
                ...prevState.slice(0, scrollPositionsIndex),
                newScrollPosition,
                ...prevState.slice(scrollPositionsIndex + 1)
            ]
            : [...prevState, newScrollPosition]
        );

        if(mainContentOpen){
            if(effectId !== 'main'){
                setMainContentOpen(false);
                setMainContent(documentEditorValue);

                set_selectedEffectOutput({effect: effectId, optionIndex});
            }
        } else {
            storeEffectOutput();
            if(effectId !== 'main'){
                setTimeout(() => {
                    set_selectedEffectOutput({effect: effectId, optionIndex});    
                }, 200);
            } else {
                set_selectedEffectOutput(null);
                setDocumentEditorValue(mainContent);
                setMainContentOpen(true);
            }
        }
        set_effectsWindowOpen(false);
        setEffectsTreeWindowOpen(false);
    }, [mainContentOpen, mainContent, documentEditorValue, scrollPositions]);
    const editRadioEffect = (selectedEffect) => { // should re-implement this
        const updatedQuestionTitle = window.prompt('Qual será o novo título da pergunta?', selectedEffect.question);
        if(updatedQuestionTitle){

            const foundTemplateEffects = templateEffects.filter(effect => effect.question === selectedEffect.question).map(effect => effect.id);
            const regExp = new RegExp(`(<span class="solida-effect mceNonEditable" data-id="(${foundTemplateEffects.join('|')})".*?>).*?(<\/span>)`, 'g');
            const updatedSpan = `$1${updatedQuestionTitle}$3`;

            let updatedTemplateEffects = templateEffects.map(effect => {
                if(effect.id === selectedEffect.id){
                    effect.question = updatedQuestionTitle;
                    effect.label = updatedQuestionTitle;
                }
                if(effect.type === 'radio'){
                    effect.options = effect.options.map(option => {
                        option.output = option.output.replace(regExp, updatedSpan);
                        return option;
                    });
                    if(effect.textOption?.show){
                        effect.textOption.output = effect.textOption.output.replace(regExp, updatedSpan);
                    }
                } else if(effect.type === 'list'){
                    effect.options = effect.options.map(option => {
                        option.output = option.output.replace(regExp, updatedSpan);
                        return option;
                    });
                } else if(effect.type === 'if'){ // test these "special" effects when re-implementing this function
                    effect.if.output = effect.if.output.replace(regExp, updatedSpan);
                    effect.if.elseOutput = effect.if.elseOutput.replace(regExp, updatedSpan);    
                } else if(effect.type === 'ifNot'){
                    effect.ifNot.output = effect.ifNot.output.replace(regExp, updatedSpan);
                    effect.ifNot.elseOutput = effect.ifNot.elseOutput.replace(regExp, updatedSpan);    
                } else if(effect.type === 'ifTrue'){
                    effect.ifTrue.output = effect.ifTrue.output.replace(regExp, updatedSpan);
                    effect.ifTrue.elseOutput = effect.ifTrue.elseOutput.replace(regExp, updatedSpan);    
                } else if(effect.type === 'special'){
                    if(effect.special === 'if' || effect.special === 'ifNot' || effect.special === 'ifTrue'){
                        effect[effect.special].output = effect[effect.special].output.replace(regExp, updatedSpan);
                        effect[effect.special].elseOutput = effect[effect.special].elseOutput.replace(regExp, updatedSpan);    
                    } else if(effect.special === 'customList'){
                        effect.options = effect.options.map(option => {
                            option.output = option.output.replace(regExp, updatedSpan);
                            return option;
                        });
                    }
                }
                return effect;
            });
            setTemplateEffects(updatedTemplateEffects);
            
            const updatedDocumentEditorValue = documentEditorValue.replace(regExp, updatedSpan);
            setDocumentEditorValue(updatedDocumentEditorValue);

            if(!mainContentOpen){
                const updatedMainContent = mainContent.replace(regExp, updatedSpan);
                setMainContent(updatedMainContent);
            }
        }
    };
    const deleteAllEffectInstances = (selectedEffect) => {
        const deleteEffectFilter = (effect) => {
            // return effect.id === selectedEffect.id || (effect.question && selectedEffect.question && (effect.question === selectedEffect.question)) || (effect.label && selectedEffect.label && (effect.label === selectedEffect.label));
            if(effect.type === selectedEffect.type){
                if(selectedEffect.type === 'text'){
                    
                } else if(selectedEffect.type === 'radio'){

                } else if(selectedEffect.type === 'list'){

                } else if(selectedEffect.type === 'if'){

                } else if(selectedEffect.type === 'ifNot'){

                } else if(selectedEffect.type === 'ifTrue'){
                    return effect.typeId === selectedEffect.typeId;
                }
            }
            return false;
        };
        if(window.confirm('Tem certeza que quer excluir todas as ocorrências desse efeito?')){
            const foundTemplateEffects = templateEffects.filter(deleteEffectFilter).map(effect => effect.id);
            const regExp = new RegExp(`<span class="solida-effect mceNonEditable" data-id="(${foundTemplateEffects.join('|')})">.*?<\/span>`, 'g');
            let updatedTemplateEffects = templateEffects.map(effect => {
                if(deleteEffectFilter(effect)){
                    return null;
                }
                if(effect.type === 'radio'){
                    effect.options = effect.options.map(option => {
                        option.output = option.output.replace(regExp, '');
                        return option;
                    });
                    if(effect.textOption?.show){
                        effect.textOption.output = effect.textOption.output.replace(regExp, '');
                    }
                } else if(effect.type === 'list'){
                    effect.options = effect.options.map(option => {
                        option.output = option.output.replace(regExp, '');
                        return option;
                    });
                } else if(effect.type === 'if'){
                    effect.output = effect.output.replace(regExp, '');
                    effect.elseOutput = effect.elseOutput.replace(regExp, '');
                } else if(effect.type === 'ifNot'){
                    effect.output = effect.output.replace(regExp, '');
                    effect.elseOutput = effect.elseOutput.replace(regExp, '');
                } else if(effect.type === 'ifTrue'){
                    effect.output = effect.output.replace(regExp, '');
                    effect.elseOutput = effect.elseOutput.replace(regExp, '');
                } else if(effect.type === 'special'){
                    if(effect.special === 'if' || effect.special === 'ifNot' || effect.special === 'ifTrue'){
                        effect[effect.special].output = effect[effect.special].output.replace(regExp, '');
                        effect[effect.special].elseOutput = effect[effect.special].elseOutput.replace(regExp, '');    
                    } else if(effect.special === 'customList'){
                        effect.options = effect.options.map(option => {
                            option.output = option.output.replace(regExp, '');
                            return option;
                        });
                    }
                }
                return effect;
            });
            updatedTemplateEffects = updatedTemplateEffects.filter(effect => !!effect);

            setDocumentEditorValue(documentEditorValue.replace(regExp, ''));
            
            if(!mainContentOpen){
                setMainContent(prevMainContent => prevMainContent.replace(regExp, ''));
            }

            setTemplateEffects(updatedTemplateEffects);
        }
    };
    
    return (
        <DocumentEditorContext.Provider
            value={{
                tagPattern, tagIdPattern,
                setExecCommand,
                selectedEffectOutput, set_selectedEffectOutput,
                listOutputOptions,
                levelEffects, set_levelEffects,
                onEffectDoubleClick,
                checkEffects,
                secondaryEditorWindowOpen, set_secondaryEditorWindowOpen,
                handleCopyEffect,
                handle_effectRadioOutputButtonClick,
                effectsWindowOpen, set_effectsWindowOpen,
                setEffectsTreeWindowOpen,
                createEffect, pasteEffect,
                clickedEffectId, set_clickedEffectId, clickedEffect, set_clickedEffect, copiedEffect, set_copiedEffect,
                documentEditorValue,
                editRadioEffect, deleteAllEffectInstances,
                mainContentOpen, mainContent,
                getDocumentContent
            }}
        >
            {children}
            <NewEffectWindow open={createEffectWindowOpen} setOpen={setCreateEffectWindowOpen} />
            <EffectsWindow open={effectsWindowOpen} setOpen={set_effectsWindowOpen} selectedEffect={doubleClickedEffect} setSelectedEffect={set_doubleClickedEffect} />
            <TreeWindow
                open={effectsTreeWindowOpen} setOpen={setEffectsTreeWindowOpen}
                handle_effectRadioOutputButtonClick={handle_effectRadioOutputButtonClick}
                mainContent={mainContent}
                mainContentOpen={mainContentOpen}
                onEffectDoubleClick={onEffectDoubleClick}
                tagPattern={tagPattern}
                tagIdPattern={tagIdPattern}
            />
        </DocumentEditorContext.Provider>
    );
};

const useDocumentEditor = () => {
    const context = useContext(DocumentEditorContext);
    return context;
};

export {
    DocumentEditorProvider,
    useDocumentEditor
};