import { useState, useEffect } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';

import ListsWindow from './components/ListsWindow';

import DataListItem from '../../../../../components/DataListItem';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import ViewBox from '../../../../../components/ViewBox';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';
import { getTemplateNameById } from '../../../../../utils/common';
import { sortByKey } from '../../../../../utils/filters';

const Lists = () => {
    useSignals();
    const { workspaceDocumentsTemplates, workspaceTemplateLists } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const [loadingPage, setLoadingPage] = useState(true);
    const [lists, set_lists] = useState([]);
    const [selectedList, set_selectedList] = useState(null);
    const [listsWindowOpen, set_listsWindowOpen] = useState(false);

    useEffect(() => {
        setFirestoreListener('workspaceTemplateLists');
        setFirestoreListener('workspaceDocumentsTemplates');
    }, []);

    useEffect(() => {
        if(workspaceDocumentsTemplates.value && workspaceTemplateLists.value){
            set_lists(
                Object.entries(workspaceTemplateLists.value).map(([listId, list]) => {
                    const listTemplates = list.list
                    .map(templateId => ({ uid: templateId, name: getTemplateNameById(templateId, workspaceDocumentsTemplates.value, true) }))
                    .sort(sortByKey('name'));
                    return {
                        ...list,
                        uid: listId,
                        listTemplates
                    };
                })
                .sort(sortByKey('name'))
            );
            setLoadingPage(false);
        }
    }, [workspaceDocumentsTemplates.value, workspaceTemplateLists.value]);

    const handle_newListClick = () => {
        set_selectedList(null);
        set_listsWindowOpen(true);
    };
    const handle_editListClick = (clickedList) => {
        set_selectedList(clickedList);
        set_listsWindowOpen(true);
    };

    return (
        <ViewBox>
            {
                loadingPage
                ? <LoaderEllipsis />
                :
                <Box>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handle_newListClick}>Nova lista</Button>
                        </Grid>
                    </Grid>
                    {
                        !!lists &&
                        <List id="lists-list">
                            {
                                lists.map((list, index) => (
                                    <DataListItem key={index}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={10} sm={2}>
                                                <Typography variant="h6">{list.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm>
                                                <List>
                                                    {
                                                        list.listTemplates.map(listTemplate => (
                                                            <Typography key={listTemplate.uid} variant="body2">{listTemplate.name}</Typography>
                                                        ))
                                                    }
                                                </List>
                                            </Grid>
                                            <Grid item xs={2} sm={1}>
                                                <Fab size="small" color="primary" onClick={() => handle_editListClick(list)}><EditIcon /></Fab>
                                            </Grid>
                                        </Grid>
                                    </DataListItem>
                                ))
                            }
                        </List>
                    }
                </Box>
            }
            <ListsWindow
                open={listsWindowOpen}
                onClose={() => set_listsWindowOpen(false)}
                selectedList={selectedList}
                setLists={set_lists}
            />
        </ViewBox>
    );
};

export default Lists;