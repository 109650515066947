import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SaveIcon from '@mui/icons-material/Save';

import FormSigners from './components/FormSigners';
import LinkFormUser from './components/LinkFormUser';
import Question from './components/Question';
import SavedFormNameDialog from './components/SavedFormNameDialog';
import SuppliersWindow from './components/SuppliersWindow';
import Transition from './components/Transition';
import { useForm } from './context/FormContext';

import Document from '../../classes/Document';
import ProjectDocumentFormDraft from '../../classes/ProjectDocumentFormDraft';
import LoaderEllipsis from '../../components/LoaderEllipsis';
import QuestionBox from '../../components/QuestionBox';
import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI, useAppContext } from '../../context/SystemContext';
import useGetDocumentTemplateSettings from '../../hooks/useGetDocumentTemplateSettings';
import getDoc from '../../firebase/firestore/getDoc';
import callFunction from '../../firebase/functions/callFunction';
import { ERROR_MESSAGE_UNKNOWN } from '../../utils/constants';

const classes = {
    title: {
        padding: '24px',
        borderRadius: '8px',
        marginBottom: '16px',
        borderWidth: '4px 1px 1px 1px',
        borderStyle: 'solid',
        borderColor: theme => theme.palette.text.primary,
        backgroundColor: theme => theme.palette.background.paper
    },
};

const isFormActive = signal(false);
const saving = signal(false);
const suppliersList = signal([]);
const suppliersListOpen = signal(false);
const suppliersListLoading = signal(false);

const FormHeader = ({ replaceTitle }) => {
    useSignals();
    const { formSelectedDraft, selectedDocumentForm } = useAppStateCtx();
    const [title, setTitle] = useState('');

    useEffect(() => {
        if(selectedDocumentForm.value) setTitle(replaceTitle || selectedDocumentForm.value.name);
    }, [selectedDocumentForm.value]);

    return (
        <Collapse in={selectedDocumentForm.value}>
            <Box sx={classes.title}>
                <Typography variant="h2" color="primary">{title}</Typography>
                <Typography variant="body2" color="secondary">* Perguntas obrigatórias</Typography>
                {
                    formSelectedDraft.value &&
                    <Box mt={2}>
                        <Typography variant="body1" color="textSecondary">Você está preenchendo o formulário salvo como <span style={{fontSize: 18, fontWeight: 'bold'}}>{formSelectedDraft.value.name}</span>.</Typography>
                    </Box>
                }
            </Box>
        </Collapse>
    );
}

const FormGroupQuestion = ({ activeUserIsOperator }) => {
    useSignals();
    const { activeUserGroups, activeUserIsGroupsManager, loadingForm, projectGroupSettings, selectedFolder } = useAppStateCtx();
    if(!loadingForm.value && selectedFolder.value && projectGroupSettings.value?.enabled && activeUserGroups.value && isFormActive.value){
        return (
            <Question
                question={{
                    id: -1,
                    type: 'select',
                    name: 'Grupo',
                    mandatory: true,
                    options: activeUserIsOperator || activeUserIsGroupsManager.value || activeUserGroups.value.length === 0
                        ? [...activeUserGroups.value.map(g => ({value: g.id, label: g.name})), {value: 'none', label: 'Sem grupo (disponível para todos)'}]
                        : activeUserGroups.value.map(g => ({value: g.id, label: g.name}))
                }}
            />
        );
    }
    return null;
}

const FormSuppliersSection = ({ hideSuppliersButton }) => {
    useSignals();
    const {
        loadingForm,
        selectedFolder,
    } = useAppStateCtx();
    const { getSuppliers } = useAppCtxAPI();

    const handleOpenSuppliersListClick = async () => {
        suppliersListLoading.value = true;
        suppliersList.value = [];
        let currentSuppliers = [];
        if(selectedFolder.value.clientId){
            try {
                currentSuppliers = await getSuppliers(selectedFolder.value.clientId);
            } catch (error) {
                toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }
        } else {
            currentSuppliers = selectedFolder.value.suppliers
        }
        suppliersList.value = currentSuppliers;
        suppliersListOpen.value = true;
        suppliersListLoading.value = false;
    };

    if(!loadingForm.value && !hideSuppliersButton && isFormActive.value){
        return (
            <QuestionBox
                name="Você quer usar uma ficha cadastral?"
                questionValid
                type="heading"
            >
                <Box mt={2}>
                    <Box mb={1}>
                        <Typography variant="body1">Caso você já tenha cadastrado fichas, use o botão a seguir para escolher uma.</Typography>
                    </Box>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item>
                            <Button
                                disabled={suppliersListLoading.value}
                                variant="contained" color="primary"
                                startIcon={suppliersListLoading.value ? <CircularProgress size={20} /> : <FileCopyIcon />}
                                onClick={handleOpenSuppliersListClick}
                            >Fichas</Button>
                        </Grid>
                    </Grid>
                </Box>
            </QuestionBox>
        );
    }
    return null;
}

const FormQuestionsList = ({testForm}) => {
    useSignals();
    const {
        formAllQuestions,
        formVisibleQuestions,
        loadingForm,
        selectedDocumentForm
    } = useAppStateCtx();
    const { logicalBranchingQuestions } = useForm();
    const [selectedTemplateForm, setSelectedTemplateForm] = useState(null);

    useEffect(() => {
        if(selectedDocumentForm.value?.uid) isFormActive.value = false;
    }, [selectedDocumentForm.value?.uid]);

    useEffect(() => {
        if(selectedDocumentForm.value){
            const fetchData = async () => {
                if(selectedDocumentForm.value.uid === 'test'){
                    return setSelectedTemplateForm(testForm);
                }
                const getDocumentTemplateRes = await getDoc(`documents_templates/${selectedDocumentForm.value.uid}/_more/form`);
                if(getDocumentTemplateRes.error){
                    loadingForm.value = false;
                    return toast('Não foi possível carregar o formulário. Tente novamente mais tarde', { type: 'error' });
                }
                if(getDocumentTemplateRes.result.form) setSelectedTemplateForm(getDocumentTemplateRes.result.form);
            };

            fetchData();
        }
    }, [selectedDocumentForm.value]);

    useEffect(() => {
        if(selectedTemplateForm){

            const currentAllQuestions = selectedTemplateForm.map((question, questionIndex) => {
                question.id = questionIndex;
                if(!Array.isArray(question.logicalBranching)){
                    const concurrentConditionGroups = [];
                    if(question.logicalBranching && question.logicalBranching.question !== '~none;'){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: [question.logicalBranching]
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                    }
                    question.logicalBranching = concurrentConditionGroups;
                } else {
                    if(!question.logicalBranching.some(logicalBranchingCondition => logicalBranchingCondition.concurrentConditions)){
                        const concurrentConditionGroups = [];
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: question.logicalBranching
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                        question.logicalBranching = concurrentConditionGroups;
                    }
                }
                question.isLogicalBranchingQuestion = question.logicalBranching.some(logicalBranchingConditionsGroup => {
                    return logicalBranchingConditionsGroup.concurrentConditions.some(logicalBranchingCondition => logicalBranchingCondition.question !== '~none;');
                });
                return question;
            }).sort((a, b) => a.id - b.id);
            formAllQuestions.value = currentAllQuestions;

            const currentInitialQuestions = [];
            const currentLogicalBranchingQuestions = currentAllQuestions.filter(question => {
                if(question.isLogicalBranchingQuestion){
                    return true;
                }
                currentInitialQuestions.push(question);
                return false;
            });
            logicalBranchingQuestions.current = currentLogicalBranchingQuestions;
            formVisibleQuestions.value = currentAllQuestions;
        }
    }, [selectedTemplateForm]);

    const visibleQuestionsRef = useCallback((node) => {
        if (node !== null) {
            setTimeout(() => {
                loadingForm.value = false;
                isFormActive.value = true;
            }, 1000);
          }
    }, []);

    if(!selectedDocumentForm.value) return null;

    return (
        <>
            {
                (loadingForm.value || saving.value) &&
                <LoaderEllipsis />
            }
            <div style={{ display: !loadingForm.value && !saving.value ? '' : 'none' }}>
                {
                    formVisibleQuestions.value.length !== 0 &&
                    <div ref={visibleQuestionsRef}>
                        {
                            formVisibleQuestions.value.map((question) => (
                                <Transition key={question.name}>
                                    <Question question={question} />
                                </Transition>
                            ))
                        }
                    </div>
                }
            </div>
        </>
    );
}

const savedFormNameDialogOpen = signal(false);

const FormFooter = ({ hideSaveFormButton, isTest, linkForm, onFormSave, onFormSubmit, testFile, testForm }) => {
    useSignals();
    const {
        activeUser,
        formForceCheckValidation,
        formSelectedDraft,
        formVisibleQuestions,
        loadingForm,
        projectGroupSettings,
        selectedDocumentForm,
        selectedFolder,
        selectedWorkspace
    } = useAppStateCtx();
    const { formEffects } = useAppContext();
    const { questionElements, resetForm, values } = useForm();
    const signersRef = useRef();
    const [reCAPTCHAToken, setReCAPTCHAToken] = useState('');
    const captchaRef = useRef(null);
    const [templateSettings, setTemplateSettings] = useState(null);

    const retrievedDocumentTemplateSettings = useGetDocumentTemplateSettings(selectedDocumentForm.value?.uid);
    useEffect(() => {
        if(retrievedDocumentTemplateSettings.data){
            setTemplateSettings(retrievedDocumentTemplateSettings.data);
        }
    }, [retrievedDocumentTemplateSettings])
    
    const handleSubmit = () => {
        saving.value = true;
        isFormActive.value = false;
        submitForm(false,
            (fileUrl) => {
                resetForm({});
                saving.value = false;
                if(!linkForm) toast(templateSettings?.bypassDocumentOperatorReview ? 'O documento será disponibilizado na página "Documentos" em alguns minutos.' : 'O formulário foi enviado.', { autoClose: 3000, type: 'success' });
                if(onFormSubmit) onFormSubmit(fileUrl);
                resetReCAPTCHA();
            },
            (errorMessage) => {
                saving.value = false;
                isFormActive.value = true;
                toast(errorMessage || ERROR_MESSAGE_UNKNOWN, { type: 'error' });
                resetReCAPTCHA();
            });
    };

    const handleSave = (savedFormName) => {
        savedFormNameDialogOpen.value = false;
        saving.value = true;
        isFormActive.value = false;
        submitForm(savedFormName,
            () => {
                resetForm({});
                saving.value = false;
                toast('O formulário foi enviado.', { autoClose: 3000, type: 'success' });
                if(onFormSave) onFormSave();
                resetReCAPTCHA();
            },
            () => {
                saving.value = false;
                isFormActive.value = true;
                toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
                resetReCAPTCHA();
            });
    };

    const submitForm = async (savedFormName = null, successCallback, errorCallback) => {
        let allValid = true;
        const invalidQuestions = [];
        if(!savedFormName){
            formForceCheckValidation.value = true;

            formVisibleQuestions.value.forEach(visibleQuestion => {
                if(!['h', 'heading'].includes(visibleQuestion.type) && !questionElements.current[visibleQuestion.name]?.classList?.contains('hidden')){
                    const questionValid = formEffects[visibleQuestion.type].checkValidation({...values.current[visibleQuestion.name]});
                    if(!questionValid){
                        invalidQuestions.push(visibleQuestion.name);
                        allValid = false;
                    }
                }
            });
        }
        if(!allValid){
            const firstInvalidQuestion = questionElements.current[invalidQuestions[0]];
            firstInvalidQuestion.scrollIntoView({block: 'start',  behavior: 'smooth'});
            errorCallback('Preencha os campos obrigatórios.');
        } else {
            let finishedForm = {...values.current};

            let groupId = '';
            if(projectGroupSettings.value?.enabled && finishedForm['Grupo']){
                if(finishedForm['Grupo'].choice !== 'none') groupId = finishedForm['Grupo'].choice;
                delete finishedForm['Grupo'];
            }
            
            const fileQuestions = [];
            for(let key in finishedForm){
                if(key !== 'linkFormUser'){
                    const formQuestion = finishedForm[key];
                    if(formQuestion){
                        if(formQuestion.type === 'file') fileQuestions.push(formQuestion);
                        if(!formVisibleQuestions.value.find(visibleQuestion => visibleQuestion.name === key && !questionElements.current[visibleQuestion.name]?.classList?.contains('hidden'))) delete finishedForm[key];
                    } else {
                        delete finishedForm[key]
                    }
                }
            }
            
            // let formData = new FormData();
            // if(signersRef.current) formData.append('signer', signersRef.current);

            let fileIndex = 0;
            for(const fileQuestion of fileQuestions){
                if(fileQuestion.files.length > 0){
                    for (let fileQuestionFileIndex = 0; fileQuestionFileIndex < fileQuestion.files.length; fileQuestionFileIndex++) {
                        // formData.append('file' + fileIndex, fileQuestion.files[fileQuestionFileIndex]);
                        fileIndex++;
                    }
                }
            }

            finishedForm = JSON.stringify(finishedForm);

            if(savedFormName){

                const newDocumentFormDraft = new ProjectDocumentFormDraft({
                    createdBy: activeUser.value.uid,
                    form: finishedForm,
                    groupId,
                    name: savedFormName,
                    templateId: selectedDocumentForm.value.uid
                });

                const res = await newDocumentFormDraft.saveProjectDocumentFormDraft(selectedFolder.value.uid, formSelectedDraft.value?.uid);
                if(res.error){
                    return errorCallback();
                }

            } else {

                if(isTest){
                    const res = await callFunction('testDocumentTemplate', {
                        clientId: '61070bf959a35d26585902e4', // ABC Produções
                        activeUserId: activeUser.value.uid,
                        form: finishedForm,
                        projectId: '61070bae270bd24b6c793c07', // O Planeta Cristalino
                        template: {
                            file: testFile,
                            form: testForm,
                        },
                        workspaceId: selectedWorkspace.value.uid
                    });
                    if(res.error){
                        return errorCallback();
                    }
                    successCallback(res.result);
                    return;
                }

                const newDocument = new Document({
                    clientId: selectedFolder.value.clientId,
                    createdBy: activeUser.value.uid,
                    form: finishedForm,
                    groupId,
                    projectId: selectedFolder.value.uid,
                    templateId: selectedDocumentForm.value.uid,
                    workspaceId: selectedWorkspace.value.uid
                });
    
                const res = await newDocument.createDocument({ saveFormId: formSelectedDraft.value?.uid });
                if(res.error){
                    return errorCallback();
                }

            }

            successCallback();

            saving.value = false;
        }
    };

    const resetReCAPTCHA = () => {
        if(captchaRef.current){
            captchaRef.current.reset();
            setReCAPTCHAToken('');
        }
    };

    if(!loadingForm.value && formVisibleQuestions.value.length !== 0 && !saving.value && isFormActive.value){
        return (
            <>
                <LinkFormUser captchaRef={captchaRef} linkForm={linkForm} setReCAPTCHAToken={setReCAPTCHAToken} values={values} />

                {
                    (!isTest && formVisibleQuestions.value.length !== 0 && selectedFolder.value?.documentsAllowOutsideReview) &&
                    <FormSigners signersRef={signersRef} />
                }

                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained" color="primary"
                            // TODO values.current in line below to not notify changes, so this only works if "reCAPTCHAToken" is last value changed
                            disabled={linkForm && (!reCAPTCHAToken || (!activeUser.value && (!values.current.linkFormUser || !values.current.linkFormUser.name || !values.current.linkFormUser.email)))}
                            onClick={handleSubmit}
                        >Enviar</Button>
                    </Grid>
                    {
                        !hideSaveFormButton &&
                        <Grid item>
                            <Button
                                variant="text"
                                onClick={() => savedFormNameDialogOpen.value = true}
                                startIcon={<SaveIcon />}>Concluir depois</Button>
                        </Grid>
                    }

                </Grid>
                <SavedFormNameDialog open={savedFormNameDialogOpen} handleOk={handleSave} />
            </>
        );
    }
    return null;
}

function Form({
    activeUserIsOperator,
    hideSaveFormButton,
    hideSuppliersButton,
    isTest,
    linkForm,
    onFormSave,
    onFormSubmit,
    replaceTitle,
    testFile,
    testForm
}){
    useSignals();
    const { resetForm } = useForm();

    useEffect(() => {
        return () => {
            isFormActive.value = false;
            resetForm({});
        }
    }, []);

    return (
        <Container maxWidth="md">
            
            <FormHeader replaceTitle={replaceTitle}/>

            <form noValidate>

                <FormGroupQuestion activeUserIsOperator={activeUserIsOperator} />

                <FormSuppliersSection hideSuppliersButton={hideSuppliersButton} />

                <FormQuestionsList testForm={testForm} />

                <FormFooter
                    hideSaveFormButton={hideSaveFormButton}
                    isTest={isTest}
                    linkForm={linkForm}
                    onFormSave={onFormSave}
                    onFormSubmit={onFormSubmit}
                    testFile={testFile}
                    testForm={testForm}
                />
            </form>

            <SuppliersWindow suppliersListOpen={suppliersListOpen} suppliersList={suppliersList} />
        </Container>
    );
}

export default Form;