import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LoaderEllipsis from './LoaderEllipsis';
import FormQuestion from './FormQuestion';
import { useAppStateCtx } from '../context/AppState';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';
import getDoc from '../firebase/firestore/getDoc';

const ChangeFolderInitialResponsesWindow = ({changeProjectInitialResponsesWindowOpen, projectInitialResponses, selectedFolder, handleSelectedFolderInitialFormResponsesChange, templateId}) => {
    useSignals();
    const {  } = useAppStateCtx();
    const defaultFolderInitialFormResponseQuestion = {name: '', response: ''};
    const defaultFolderInitialFormResponse = {templateId: '', questions: {}};
    const [folderInitialFormResponses, setFolderInitialFormResponses] = useState([]);
    const [loadingTemplate, setLoadingTemplate] = useState(true);
    const [foundTemplate, setFoundTemplate] = useState(null);
    const [selectedFolderInitialFormResponse, setSelectedFolderInitialFormResponse] = useState(null);
    const responses = useRef([]);
    const newQuestionRef = useRef(null);
    const [newQuestionMenuAnchorEl, setNewQuestionAnchorEl] = useState(null);
    const [newQuestionMenuOpen, setNewQuestionMenuOpen] = useState(false);
    const [questionOptions, setQuestionOptions] = useState([]);

    const fetchTemplate = async (templateId) => {
        setLoadingTemplate(true);
        const res = await getDoc(`documents_templates/${templateId}/_more/form`);
        setLoadingTemplate(false);
        if(res.error){
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        setFoundTemplate(res.result);
    };
    
    useEffect(() => {
        if(changeProjectInitialResponsesWindowOpen.value){
            responses.current = [];
            setFolderInitialFormResponses(projectInitialResponses.value || [defaultFolderInitialFormResponse]);
            if(templateId) fetchTemplate(templateId);
        }
    }, [changeProjectInitialResponsesWindowOpen.value, templateId, projectInitialResponses.value]);

    useEffect(() => {
        let currentSelectedFolderInitialFormResponse = null;
        if(templateId && folderInitialFormResponses){
            const foundResponse = folderInitialFormResponses.find(response => response.templateId === templateId);
            currentSelectedFolderInitialFormResponse = foundResponse || {...defaultFolderInitialFormResponse, templateId};
        }
        setSelectedFolderInitialFormResponse(currentSelectedFolderInitialFormResponse);
    }, [templateId, folderInitialFormResponses]);

    useEffect(() => {
        let currentQuestionOptions = [];
        if(foundTemplate){
            let foundResponseQuestions = [];
            if(selectedFolderInitialFormResponse){
                foundResponseQuestions = selectedFolderInitialFormResponse.questions || {};
            }
            currentQuestionOptions = foundTemplate.form
            .filter(question => {
                const foundQuestion = Object.entries(foundResponseQuestions).some(([_, responseQuestion]) => responseQuestion.name === question.name);
                return !foundQuestion;
            })
            .map(question => ({value: question.name, label: question.name}));
        }
        setQuestionOptions(currentQuestionOptions);
    }, [foundTemplate, selectedFolderInitialFormResponse]);

    const handleQuestionValueChange = (questionName, newValue) => {
        const questionIndex = responses.current.findIndex(question => question.name === questionName);
        if(questionIndex !== -1){
            responses.current[questionIndex].response = newValue;
        } else {
            responses.current.push({name: questionName, response: newValue});
        }
    };
    
    const handleSave = () => {
        handleSelectedFolderInitialFormResponsesChange(templateId, responses.current);
        handleClose();
    };

    const handleNewQuestionButtonClick = () => {
        setNewQuestionAnchorEl(newQuestionRef.current);
        setNewQuestionMenuOpen(true);
    };

    const handleQuestionOptionClick = (newValue) => {
        setNewQuestionMenuOpen(false);
        setSelectedFolderInitialFormResponse(prevState => ({...prevState, questions: [...prevState.questions, {...defaultFolderInitialFormResponseQuestion, name: newValue}]}));
    };

    const handleQuestionDelete = (questionIndex) => {
        setSelectedFolderInitialFormResponse(prevState => ({...prevState, questions: [...prevState.questions.slice(0, questionIndex), ...prevState.questions.slice(questionIndex + 1)]}));
        responses.current = [...responses.current.slice(0, questionIndex), ...responses.current.slice(questionIndex + 1)];
    }

    const handleClose = () => {
        changeProjectInitialResponsesWindowOpen.value = false;
    };

    return (
        <>
            <Dialog
                open={changeProjectInitialResponsesWindowOpen.value} onClose={handleClose}
                fullWidth
            >
                {
                    selectedFolder?.name &&
                    <DialogTitle>{selectedFolder.name}{!loadingTemplate ? ` | Respostas iniciais para o formulário ${foundTemplate ? foundTemplate.name : ''}` : ''}</DialogTitle>
                }
                <DialogContent>
                {
                    (!selectedFolderInitialFormResponse || loadingTemplate || !foundTemplate)
                    ? <LoaderEllipsis />
                    :
                    <Box>
                        {
                            Object.entries(selectedFolderInitialFormResponse.questions)
                            .map(([questionId, question]) => {
                                let foundFormQuestion = foundTemplate.form.find(formQuestion => formQuestion.name === questionId);
                                return (
                                    <Box key={questionId}>
                                        <Grid container spacing={1} alignItems="center" sx={{ '&:hover': { backgroundColor: theme => theme.palette.action.hover } }}>
                                            <Grid item xs>
                                                {
                                                    foundFormQuestion &&
                                                    <Box>
                                                        <FormQuestion
                                                            question={{
                                                                ...foundFormQuestion
                                                            }}
                                                            onChange={handleQuestionValueChange}
                                                            initialValue={question.response}
                                                        />
                                                    </Box>
                                                }
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="small" onClick={() => handleQuestionDelete(questionId)}><ClearIcon /></IconButton>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                    </Box>
                                );
                            })
                        }
                    </Box>
                }
                </DialogContent>
                <DialogActions>
                    <Button ref={newQuestionRef} startIcon={<AddIcon />} onClick={handleNewQuestionButtonClick}>Pergunta</Button>
                    <Button variant="contained" color="primary" startIcon={<CheckIcon />} onClick={handleSave}>Confirmar</Button>
                </DialogActions>
            </Dialog>
            <Menu
                keepMounted
                anchorEl={newQuestionMenuAnchorEl}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                open={newQuestionMenuOpen}
                onClose={() => setNewQuestionMenuOpen(false)}
                sx={{
                    zIndex: theme => theme.zIndex.modal + 1
                }}
            >
                {
                    questionOptions.map((questionOption) => (
                        <MenuItem key={questionOption.value}
                            onClick={() => handleQuestionOptionClick(questionOption.value)}
                        >
                            <Typography>{questionOption.label}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    );
};

export default ChangeFolderInitialResponsesWindow;