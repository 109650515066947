import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import pkg from '../../package.json';

import { useAppStateCtx } from '../context/AppState';
import useGetAppVersion from '../hooks/useGetAppVersion';

import ButtonLink from './ButtonLink';

const classes = {
    root: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        zIndex: theme => theme.zIndex.snackbar + 1,
    },
    alert: {
        backgroundColor: '#f5f6fa', // theme.palette.background.paper,
        borderRadius: 8,
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.4)',
        padding: '8px'
    }
};

const CURRENT_VERSION = pkg.version;

const NewVersionAvailable = () => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const [showBox, setShowBox] = useState(false);

    const retrievedAppVersion = useGetAppVersion(!!activeUser.value?.uid);
    useEffect(() => {
        setShowBox(retrievedAppVersion?.latest && retrievedAppVersion?.latest > CURRENT_VERSION);
    }, [retrievedAppVersion]);

    const handleClick = () => {
        window.location.reload(true);
    };

    useEffect(() => {
        if(showBox){
            toast('Uma atualização do SOLIDA está disponível. Clique aqui para recarregar a página e atualizar o SOLIDA.', {
                autoClose: false,
                onClick: handleClick,
                type: 'info',
            })
        }
    }, [showBox]);

    if(showBox && retrievedAppVersion?.isCriticalUpdate){
        //TODO Block actions
        return (
            <Box sx={classes.root}>
                <Slide in direction="left">
                    <Box sx={classes.alert}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid size="grow">
                                <Typography variant="body2">Nova versão! <ButtonLink onClick={handleClick}>Clique para recarregar o aplicativo.</ButtonLink></Typography>
                            </Grid>
                            <Grid>
                                <img src={'/SOLIDA-50.png'} alt="Solida" /> 
                            </Grid>
                        </Grid>
                    </Box>
                </Slide>
            </Box>
        );
    }
    return null;
};

export default NewVersionAvailable;