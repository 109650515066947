import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import FormDialog from './FormDialog';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentNameView } from '../context/DocumentsContext';
import { getKeywords } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';

const UpdateNameDialog = () => {
    const { hideChangeDocumentNameView } = useDocumentsAPI();
    const { changeDocumentNameViewOpen, changeDocumentNameViewSelectedDocument } = useDocumentsCtxChangeDocumentNameView();
    const [textFieldValue, set_textFieldValue] = useState('');

    useEffect(() => {
        if(changeDocumentNameViewSelectedDocument){
            set_textFieldValue(changeDocumentNameViewSelectedDocument.name);
        }
    }, [changeDocumentNameViewSelectedDocument]);

    const handle_okClick = async () => {
        handleClose();

        const toastId = toast.loading(`Salvando...`);
        const res = changeDocumentNameViewSelectedDocument.update({
            updates: {
                keywords: getKeywords(textFieldValue),
                name: textFieldValue
            }
        })
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        hideChangeDocumentNameView();
    };
    
    return (
        <FormDialog
            still
            id="update-doc-name-dialog"
            open={changeDocumentNameViewOpen} onClose={handleClose}
            title="Alterar nome" text="Você quer alterar o nome desse contrato? Essa alteração afetará advogados e clientes." textFieldLabel="Nome"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handle_okClick}
            okButtonDisabled={!textFieldValue}
        />
    );
}

export default UpdateNameDialog;