import { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MenuChip = ({initialValue, label, onChange, onOpen, options, value}) => {
    const [valueLabel, setValueLabel] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if(value && options){
            const foundChoice = options.find(option => option.value === value);
            if(foundChoice) setValueLabel(foundChoice.label);
        }
    }, [value, options]);

    useEffect(() => {
        if(menuOpen){
            if(onOpen) onOpen();
        }
    }, [menuOpen]);

    const handleMenuClick = (e) => {
        setMenuAnchorEl(e.target);
        setMenuOpen(true);
    };

    const handleMenuItemClick = (newValue) => {
        setMenuOpen(false);
        if(onChange) onChange(newValue.value);
    };

    return (
        <>
            <Chip
                variant={value !== (initialValue || 'all') ? 'default' : 'outlined'}
                color="primary"
                label={`${label}${value !== (initialValue || 'all') ? `: ${valueLabel}` : ''}`}
                deleteIcon={<ArrowDropDownIcon />}
                onDelete={handleMenuClick}
                clickable
                onClick={handleMenuClick}
                style={{maxWidth: 200}}
            />
            <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {
                    options.map((option, optionIndex) => (
                        <MenuItem
                            key={optionIndex}
                            onClick={() => handleMenuItemClick(option)}
                            selected={option.value === value}
                        >{option.label}</MenuItem>
                    ))
                }
            </Menu>
        </>
    );
};

export default MenuChip;