import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const ClientDocumentLink = ({document}) => {
    if(document){
        if(document.lastVersion?.fileUrl){
            const documentFullName = `${document.name}${document.lastVersion.name ? ` | ${document.lastVersion.name}` : ''}`;
            return <Link variant="body1" href={document.lastVersion.fileUrl} target="_blank" underline="hover">{documentFullName}</Link>;
        }
        return <Typography variant="body1" noWrap>{document.name}</Typography>;
    }
    return null;
};

export default ClientDocumentLink;