import { collection, deleteField, doc, documentId, getDoc, getDocs, query, runTransaction, where } from 'firebase/firestore';

import { db } from '../firebase/config';
import callFunction from '../firebase/functions/callFunction';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';
import setUserCustomClaims from '../utils/setUserCustomClaims';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const uploadPhoto = async (folderPath: string, filesList: any) => {
    let newFileUrl = '';
    for(const file of filesList){
        const { contentType, fileBuffer } = await getFileContent(file);
        const { result } = await uploadFile(
            `${folderPath}/${file.name}`,
            fileBuffer,
            contentType
        );
        if(result){
            const { downloadUrl } = result;
            newFileUrl = downloadUrl;
        }
    }
    return newFileUrl;
};

export default class User {
    createdAt = '';
    createdBy = '';
    email = '';
    isWorkspacesManager = false;
    name = '';
    notificationsReatAt = '';
    photoUrl = '';
    uid = '';

    constructor({
        createdAt,
        createdBy,
        email,
        isWorkspacesManager,
        name,
        notificationsReatAt,
        photoUrl,
        uid
    }: User){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.email = email || '';
        this.isWorkspacesManager = !!isWorkspacesManager;
        this.name = name || '';
        if(notificationsReatAt) this.notificationsReatAt = notificationsReatAt;
        this.photoUrl = photoUrl || '';
        this.uid = uid || '';
    }

    async createUser({ clientsIds, projectsIds, role, workspaceId }: {
        clientsIds: string[],
        projectsIds: string[];
        role: string;
        workspaceId: string;
    }){
        let result = null, error = null;

        const {uid: _, ...newUser} = this;

        const res = await callFunction('createUser',
            {
                clientsIds,
                newUser,
                projectsIds,
                role,
                workspaceId
            }
        );
        if(res.error){
            error = res.error;
        } else {
            result = true;
        }

        return { result, error };
    }

    async getClaims(){
        let result = null, error = null;

        const documentRef = doc(db, `user_claims/${this.uid}`);

        try {
            const snapshot = await getDoc(documentRef);
            result = snapshot.data();
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getPermissions(){
        let result = null, error = null;

        const collectionRef = collection(db, `users/${this.uid}/workspaces`);

        try {
            const snapshots = await getDocs(collectionRef);
            result = snapshots.docs.map(snapshot => ({ ...snapshot.data(), uid: snapshot.id }));
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getProjectAndClients(selectedWorkspaceId: string, projectIds: string[]){
        let result = null, error = null;

        const res = await callFunction('getUserClientsAndProjects', {
            projectIds,
            selectedWorkspaceId
        });
        if(!res.error && res.result){
            result = res.result;
        } else {
            error = ERROR_MESSAGE_UNKNOWN;
        }

        return { result, error };
    }

    async getSelectedClientOrProject(activeUser: any, selectedWorkspaceId: string, clientOrProjectId: string, type: 'client' | 'project'){
        let result = null, error = null;

        const res = await callFunction('getUserSelectedClientOrProject', {
            activeUserId: activeUser.uid,
            clientOrProjectId,
            selectedWorkspaceId,
            type
        });
        if(!res.error && res.result){
            result = res.result;
        } else {
            error = ERROR_MESSAGE_UNKNOWN;
        }

        return { result, error };
    }

    async getWorkspacesByIds(ids: string[]){
        let result = null, error = null;
        
        const queryRef = query(collection(db, `workspaces`), where(documentId(), 'in', ids));

        try {
            const snapshots = await getDocs(queryRef);
            result = snapshots.docs.map(snapshot => ({ ...snapshot.data(), uid: snapshot.id }));
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async setClaims(workspaceId: string){
        let result = null, error = null;

        const setUserCustomClaimsRes = await setUserCustomClaims(workspaceId);
        if(setUserCustomClaimsRes.error){
            error = setUserCustomClaimsRes.error;
        }
        result = setUserCustomClaimsRes.result;

        return { result, error };
    }

    async update({ clientsIds, projectsIds, role, updates, workspaceId }: {
        clientsIds: string[] | undefined;
        projectsIds: string[] | undefined;
        role: string;
        updates: {[key: string]: any};
        workspaceId: string
    }){
        let result = null, error = null;
        
        const documentRef = doc(db, `users/${this.uid}`);
        const userPermissionsRef = doc(db, `users/${this.uid}/workspaces/${workspaceId}`);
        const workspaceUserRef = doc(db, `workspaces/${workspaceId}/_more/users`);

        try {
            await runTransaction(db, async (transaction) => {
                const permissionUpdates: { [key: string]: any } = {
                    role
                };
                if(clientsIds && projectsIds){
                    const userPermissionsSnapshot = await transaction.get(userPermissionsRef);
                    const userPermissions = userPermissionsSnapshot.data();
                    if(userPermissions){
                        const userWorkspaceFolders = userPermissions.folders || {};
                        for(const folderId in userWorkspaceFolders){
                            if(!projectsIds.includes(folderId)){ // Ids selected in UI not currently in 
                                permissionUpdates[`folders.${folderId}`] = deleteField();

                                transaction.set(doc(db, `projects/${folderId}/_more/users`), { [this.uid]: deleteField() }, { merge: true });
                            }
                        }
                        const userWorkspaceClients = userPermissions.clients || {};
                        for(const clientId in userWorkspaceClients){
                            if(!clientsIds.includes(clientId)){
                                permissionUpdates[`clients.${clientId}`] = deleteField();

                                transaction.set(doc(db, `clients/${clientId}/_more/users`), { [this.uid]: deleteField() }, { merge: true });
                            }
                        }
                        
                        for(const projectId of projectsIds){
                            if(!userWorkspaceFolders[projectId]){
                                permissionUpdates[`folders.${projectId}`] = { permissionEnabledManageClients: false, permissionEnabledManageProjects: false, permissionEnabledManageUsers: false, role: '' };
                                
                                transaction.set(doc(db, `projects/${projectId}/_more/users`), {
                                    [this.uid]: {
                                        email: this.email,
                                        emailNotificationSettings: {
                                            ...userPermissions.emails
                                        }
                                    }
                                }, { merge: true });
                            }
                        }
                        for(const clientId of clientsIds){
                            if(!userWorkspaceClients[clientId]){
                                permissionUpdates[`clients.${clientId}`] = { permissionEnabledManageClients: false, permissionEnabledManageProjects: false, permissionEnabledManageUsers: false, role: '' };
                                
                                transaction.set(doc(db, `clients/${clientId}/_more/users`), {
                                    [this.uid]: {
                                        email: this.email,
                                        emailNotificationSettings: {
                                            ...userPermissions.emails
                                        }
                                    }
                                }, { merge: true });
                            }
                        }
                    }

                }
                transaction.update(userPermissionsRef, permissionUpdates);
                transaction.update(documentRef, updates);
                transaction.update(workspaceUserRef, {
                    [`users.${this.uid}.name`]: updates.name,
                    [`users.${this.uid}.role`]: role
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateNotificationsReadAt(newValue: string){
        let result = null, error = null;
        
        const userRef = doc(db, `users/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(userRef, {
                    notificationsReatAt: newValue || new Date().toISOString()
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updatePhoto({ filesList }: {
        filesList: any
    }){
        let result = null, error = null;

        let newFileUrl = await uploadPhoto(
            `users/${this.uid}/photos`,
            filesList || []
        );

        let workspaceIds: string[] = [];
        const userWorkspacesSnapshot = await getDocs(collection(db, `users/${this.uid}/workspaces`));
        for(const snapshot of userWorkspacesSnapshot.docs){
            workspaceIds.push(snapshot.id);
        }

        const userRef = doc(db, `users/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(userRef, {
                    photoUrl: newFileUrl
                });
                for(const workspaceId of workspaceIds){
                    transaction.update(doc(db, `workspaces/${workspaceId}/_more/users`), {
                        [`users.${this.uid}.photoUrl`]: newFileUrl
                    }); 
                }
            });
            result = newFileUrl;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateWorkspaceEmailSettings({ emailSettings, workspaceId }: {
        emailSettings: {[key: string]: any};
        workspaceId: string
    }){
        let result = null, error = null;
        
        const userWorkspaceRef = doc(db, `users/${this.uid}/workspaces/${workspaceId}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                const userWorkspaceSnapshot = await transaction.get(userWorkspaceRef);
                let updates: { [key: string]: any } = {
                    emails: emailSettings
                }
                const userWorkspace = userWorkspaceSnapshot.data();
                if(userWorkspace){
                    const userProjects = userWorkspace.folders || {};
                    for(const userProjectId in userProjects){
                        updates[`folders.${userProjectId}.emails`] = emailSettings;
                        transaction.update(doc(db, `projects/${userProjectId}/_more/users`), {
                            [`${this.uid}.emailNotificationSettings`]: emailSettings
                        });
                    }
                }
                transaction.update(userWorkspaceRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateWorkspaceFavorites({ favoriteProjects, workspaceId }: {
        favoriteProjects: any[];
        workspaceId: string
    }){
        let result = null, error = null;
        
        const userWorkspaceRef = doc(db, `users/${this.uid}/workspaces/${workspaceId}`);
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(userWorkspaceRef, {
                    favoriteProjects
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}