import { memo, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Window from '../../../../../../components/Window';
import { useOperatorTemplatesCtxTemplateDocumentEditorValue, useOperatorTemplatesCtxTemplateEffects } from '../../context/TemplatesContext';
import { v4 as uuidv4 } from 'uuid';

const classes = {
    effect: {
        backgroundColor: '#bfedd2',
        color: theme => theme.palette.text.primary,
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'background-color .4s ease-out',
        '&:hover': {
            backgroundColor: '#a2d6b8',
        },
        '&:active': {
            color: theme => theme.palette.primary.main
        }
    },
    option: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    boxLeftMargin: {
        marginLeft: theme => theme.spacing(4)
    }
};

const TreeWindow = memo(({
    open, setOpen,
    getListById,
    handle_effectRadioOutputButtonClick,
    mainContent,
    mainContentOpen,
    onEffectDoubleClick,
    tagPattern,
    tagIdPattern
}) => {
    const documentEditorValue = useOperatorTemplatesCtxTemplateDocumentEditorValue();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const [effectsTree, setEffectsTree] = useState([]);
    
    useEffect(() => {
        if(open){
            getTemplateEffectsTree();
        }
    }, [open, templateEffects]);

    const getTemplateEffectsTree = () => {
        const mainSheet = mainContentOpen ? documentEditorValue : mainContent;

        let tree = [];

        const mapEffectOption = (effectOption, outputKey = 'output', effectsKey = 'effects') => {
            effectOption[effectsKey] = [];
            const effectOptionOutput = effectOption[outputKey];
            if(effectOptionOutput){
                const effectOptionOutputCodes = effectOptionOutput.match(tagPattern);
                if(effectOptionOutputCodes){
                    effectOptionOutputCodes.forEach(effectOptionOutputCode => {
                        const effectOptionOutputCodeRegExData = tagIdPattern.exec(effectOptionOutputCode);
                        if(effectOptionOutputCodeRegExData){
                            const foundEffectId = effectOptionOutputCodeRegExData[1];
                            let foundEffect = templateEffects.find(scannedEffect => scannedEffect.id === foundEffectId);
                            if(foundEffect){
                                foundEffect = getChildren(foundEffect);
                                effectOption[effectsKey].push(foundEffect);
                            }
                        }
                    });
                }
            }
            return effectOption;
        };
        
        const getChildren = (effect) => {
            if(effect.type === 'radio'){
                effect.options = effect.options.map((effectOption) => {
                    return mapEffectOption(effectOption);
                });
                if(effect.textOption && effect.textOption.show){
                    effect.textOption = mapEffectOption(effect.textOption);
                }
            } else if(effect.type === 'list'){
                if(effect.typeSpecifics === 'custom'){
                    effect.options = effect.options.map((effectOption) => {
                        return mapEffectOption(effectOption);
                    });
                }
            } else if(effect.type === 'if'){
                effect = mapEffectOption(effect, 'output', 'outputEffects');
                effect = mapEffectOption(effect, 'elseOutput', 'elseOutputEffects');
            } else if(effect.type === 'ifNot'){
                effect = mapEffectOption(effect, 'output', 'outputEffects');
                effect = mapEffectOption(effect, 'elseOutput', 'elseOutputEffects');
            } else if(effect.type === 'ifTrue'){
                effect = mapEffectOption(effect, 'output', 'outputEffects');
                effect = mapEffectOption(effect, 'elseOutput', 'elseOutputEffects');
            } else if(effect.type === 'special'){
                if(effect.special === 'if'){
                    effect.if = mapEffectOption(effect.if, 'output', 'outputEffects');
                    effect.if = mapEffectOption(effect.if, 'elseOutput', 'elseOutputEffects');
                } else if(effect.special === 'ifNot'){
                    effect.ifNot = mapEffectOption(effect.ifNot, 'output', 'outputEffects');
                    effect.ifNot = mapEffectOption(effect.ifNot, 'elseOutput', 'elseOutputEffects');
                } else if(effect.special === 'ifTrue'){
                    effect.ifTrue = mapEffectOption(effect.ifTrue, 'output', 'outputEffects');
                    effect.ifTrue = mapEffectOption(effect.ifTrue, 'elseOutput', 'elseOutputEffects');
                } else if(effect.special === 'customList'){
                    effect.options = effect.options.map((effectOption) => {
                        return mapEffectOption(effectOption);
                    });
                }
            } else {
                // console.log(effect.type)
            }
            return effect;
        };

        const mainSheetEffects = mainSheet.match(tagPattern);
        if(mainSheetEffects){
            mainSheetEffects.forEach((mainSheetEffect) => {
                mainSheetEffect = tagIdPattern.exec(mainSheetEffect);
                if(mainSheetEffect){
                    const effectId = mainSheetEffect[1];
                    if(effectId){
                        const foundEffect = templateEffects.find(scannedEffect => scannedEffect.id === effectId);
                        tree.push(foundEffect);
                    }
                }
            });
        }
        tree = tree.map(effect => {
            return getChildren(effect);
        });
        setEffectsTree(tree);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickEffect = (e, effectId) => {
        onEffectDoubleClick(e, effectId);
    };

    const TreeBranch = ({effect}) => {
        const effectType = effect.type;
        let hasChildren = false;
        
        const mapEffectOption = (effectOption, effectOptionName, optionIndex, effectsKey = 'effects') => {
            if(effectOption[effectsKey]?.length >= 1){
                if(!hasChildren) hasChildren = [];
                hasChildren.push({
                    ...effectOption,
                    name: effectOptionName,
                    optionIndex,
                    effects: effectOption[effectsKey],
                });
            }
        };

        if(effectType === 'radio'){
            effect.options.forEach((effectOption, optionIndex) => {
                mapEffectOption(effectOption, effectOption.option, optionIndex);
            });
            if(effect.textOption?.show){
                mapEffectOption(effect.textOption, '(Preencher)', 'textOption');
            }
        } else if(effectType === 'list' && effect.typeSpecifics === 'custom'){
            if(getListById){ //TODO use workspaceLists instead
                const currentList = getListById(effect.typeId);
                if(currentList){
                    effect.options.forEach(effectOption => {
                        const listItem = currentList.list.find(listItem => listItem.id == effectOption.option);
                        mapEffectOption(effectOption, listItem.key, effectOption.option);
                    });
                }
            }
        } else if(effectType === 'if'){
            mapEffectOption(effect, `Se...`, 'output', 'outputEffects');
            mapEffectOption(effect, `Caso contrário...`, 'elseOutput', 'elseOutputEffects');
        } else if(effectType === 'ifNot'){
            mapEffectOption(effect, `Se não...`, 'output', 'outputEffects');
            mapEffectOption(effect, `Caso contrário...`, 'elseOutput', 'elseOutputEffects');
        } else if(effectType === 'ifTrue'){
            mapEffectOption(effect, `Se verdadeiro...`, 'output', 'outputEffects');
            mapEffectOption(effect, `Se falso...`, 'elseOutput', 'elseOutputEffects');
        } else if(effectType === 'special'){
            if(effect.special === 'if'){
                mapEffectOption(effect.if, `Se...`, 'output', 'outputEffects');
                mapEffectOption(effect.if, `Caso contrário...`, 'elseOutput', 'elseOutputEffects');
            } else if(effect.special === 'ifNot'){
                mapEffectOption(effect.ifNot, `Se não...`, 'output', 'outputEffects');
                mapEffectOption(effect.ifNot, `Caso contrário...`, 'elseOutput', 'elseOutputEffects');
            } else if(effect.special === 'ifTrue'){
                mapEffectOption(effect.ifTrue, `Se verdadeiro...`, 'output', 'outputEffects');
                mapEffectOption(effect.ifTrue, `Se falso...`, 'elseOutput', 'elseOutputEffects');
            }
        }
        return (
            <Box mb={1} sx={classes.boxLeftMargin}>
                <Box mb={1}>
                    <Typography variant="body1" sx={classes.effect} component="span" onClick={(e) => handleClickEffect(e, effect.id)}>&nbsp;&bull;&nbsp;{effect.label || effect.question}&nbsp;&bull;&nbsp;</Typography>
                </Box>
                {
                    hasChildren &&
                    <Box mb={1}>
                        {
                            hasChildren.map(effectOption => (
                                <Box key={uuidv4()} mb={1} sx={classes.boxLeftMargin}>
                                    <Box mb={1}>
                                        <Grid container spacing={1} alignItems="center">
                                            {/* <Grid item>
                                                <DescriptionIcon />
                                            </Grid> */}
                                            <Grid item xs>
                                                <Typography variant="body1" sx={classes.option} onClick={() => handle_effectRadioOutputButtonClick(effect.id, effectOption.optionIndex)}>"{effectOption.name}"</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {
                                        effectOption.effects.map(effectOptionEffect => (
                                            <TreeBranch key={uuidv4()} effect={effectOptionEffect} />
                                        ))
                                    }
                                </Box>
                            ))
                        }
                    </Box>
                }
            </Box>
        );
    };

    return (
        <Window open={open} onClose={handleClose}
            title="Árvore de efeitos"
        >
            {
                effectsTree.map((effect) => {
                    return (
                        <TreeBranch key={uuidv4()} effect={effect} />
                    );
                })
            }
        </Window>
    );
}, (prevProps, nextProps) => {
    // if(nextProps.row.updatedOn !== prevProps.row.updatedOn){
    //     return false;
    // }
    if(nextProps.open !== prevProps.open){
        return false;
    }
    return true;
});

export default TreeWindow;