import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, TextField, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '../Autocomplete';
import FilesField from '../FilesField';
import Input from '../Input';
import Select from '../Select';
import ClearanceQuestion from '../../classes/ClearanceQuestion';
import { useAppStateCtx } from '../../context/AppState';
import useGetWorkspaceProjectsWithClearanceOptions from '../../hooks/useGetWorkspaceProjectsWithClearanceOptions';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';
import { sortByKey } from '../../utils/filters';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddClearanceQuestionWindow = ({
    open,

    actionCallback,
    activeUserIsOperator,
    getQuestionTemplate,
    projects,
    initialSelectedProject,
    selectedQuestionClearanceSettings, setSelectedQuestionClearanceSettings,
    selectedTemplate,
}) => {
    useSignals();
    const { activeUser, activeUserGroups, projectGroupSettings, selectedWorkspace, workspaceClearanceTemplates } = useAppStateCtx();
    const filesFieldId = 'filesField';
    const [loadingPage, setLoadingPage] = useState(false);
    const [selectedProject, set_selectedProject] = useState(null);
    const [groupOptions, set_groupOptions] = useState([]);
    const [selectedGroup, set_selectedGroup] = useState('');
    const [questionSubject, set_questionSubject] = useState('');
    const [episode, setEpisode] = useState('');
    const [scriptTreatment, setScriptTreatment] = useState('');
    const [scene, setScene] = useState('');
    const [questionContent, set_questionContent] = useState('');
    const [filesList, set_filesList] = useState([]);
    const [dialogTitle, setDialogTitle] = useState('');
    const [userName, setUserName] = useState('');
    const [rightTypesOptions, setRightTypesOptions] = useState([]);
    const [selectedRightType, setSelectedRightType] = useState(null);

    const projectOptions = useGetWorkspaceProjectsWithClearanceOptions(activeUserIsOperator, projects);

    useEffect(() => {
        return () => {
            clearForm();
        }
    }, [open.value]);

    useEffect(() => {
        if(open.value){
            if(initialSelectedProject){
                if(initialSelectedProject.clientId){
                    handle_selectedProjectChange(initialSelectedProject);
                } else {
                    handle_selectedProjectChange(null);
                }
            }
        }
    }, [open.value, projectGroupSettings.value, activeUserGroups.value]);

    useEffect(() => {
        if(selectedTemplate || (workspaceClearanceTemplates.value && selectedProject?.uid === selectedQuestionClearanceSettings.projectId || selectedQuestionClearanceSettings.data)){
            const questionTemplate = getQuestionTemplate(selectedProject?.uid);
            if(questionTemplate){
                const currentRightTypesOptions = Object.entries(questionTemplate.rightTypes)
                .map(([rightTypeId, rightType]) => ({...rightType, uid: rightTypeId}))
                .sort(sortByKey('name'));
                setRightTypesOptions(currentRightTypesOptions);
            }
        }
    }, [selectedTemplate, selectedProject, workspaceClearanceTemplates.value, selectedQuestionClearanceSettings]);

    const handle_selectedProjectChange = (project) => {
        set_selectedProject(project);
        let groups = [];
        if(project){
            if(activeUserIsOperator && project.uid !== selectedQuestionClearanceSettings.projectId){
                setSelectedQuestionClearanceSettings({ projectId: project.uid, data: null });
            }

            if(!activeUserIsOperator && initialSelectedProject && projectGroupSettings.value && activeUserGroups.value){
                groups = activeUserGroups.value ? activeUserGroups.value.map(group => ({ value: group.id, label: group.name })) : [];
            }
            //TODO AddClearanceQuestionView groups settings if operator changes project
            // if(projectGroupSettings.value.enabled){
            //     if(activeUser.value){
            //         const folderGroups = project.groups;
            //         const noGroupIds = ['', '&all', '&none', '~any;'];
            //         const activeUserInAdminGroup = folderGroups.find(folderGroup => folderGroup.id === '2' && folderGroup.users.includes(activeUser.value.uid));
            //         if(activeUserInAdminGroup){
            //             folderGroups.forEach(group => {
            //                 if(!noGroupIds.includes(group.id)) groups.push({value: group.id, label: group.name});
            //             });
            //         } else {
            //             const activeUserInManagementGroup = folderGroups.find(folderGroup => folderGroup.id === '1' && folderGroup.users.includes(activeUser.value.uid));
            //             if(activeUserInManagementGroup){
            //                 folderGroups.forEach(group => {
            //                     if(!noGroupIds.includes(group.id) && group.id !== '2') groups.push({value: group.id, label: group.name});
            //                 });
            //             } else {
            //                 folderGroups.forEach(group => {
            //                     if(!noGroupIds.includes(group.id) && group.users.includes(activeUser.value.uid)) groups.push({value: group.id, label: group.name});
            //                 });
            //             }
            //         }
            //     }
            // }
            set_groupOptions(groups);
        }
        set_selectedGroup(groups.length !== 0 ? groups[0].value : '');
    };

    const handleSubmit = async () => {
        setLoadingPage(true);

        const toastId = toast.loading(`Criando a consulta "${questionSubject}"...`);

        const rightTypeId = selectedRightType ? selectedRightType.uid : '';
        const newClearanceQuestionConstructorArgs = {
            awaitingOperatorReview: !activeUserIsOperator,
            createdBy: activeUser.value.uid,
            projectId: selectedProject.uid,
            groupId: selectedGroup || '',
            rightType: rightTypeId,
            scriptScene: scene || '',
            scriptTreatment: scriptTreatment || '',
            subject: questionSubject,
            workspaceId: selectedWorkspace.value.uid
        };
        if(selectedQuestionClearanceSettings.type === 'series' && episode) newClearanceQuestionConstructorArgs.episode = episode;

        const questionTemplate = getQuestionTemplate(selectedProject.uid);
        if(questionTemplate){
            const foundRightType = questionTemplate.rightTypes[rightTypeId];
            if(foundRightType) newClearanceQuestionConstructorArgs.rightTypeDescription = foundRightType.name;
        }

        const newClearanceQuestion = new ClearanceQuestion(newClearanceQuestionConstructorArgs);

        let files;
        const filesInput = document.getElementById(filesFieldId);
        if(filesInput && filesList.length !== 0){
            files = filesInput.files;
        }

        const res = await newClearanceQuestion.createClearanceQuestion(activeUser.value, questionContent, files);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });

        if(filesList.length !== 0){
            if(filesInput) filesInput.value = '';
            set_filesList([]);
        }
        clearForm();
        
        const newQuestion = res.data;
        if(actionCallback) actionCallback(newQuestion);
        
        handleClose();

        setLoadingPage(false);
    }
    const clearForm = () => {
        set_questionSubject('');
        setEpisode('');
        setScriptTreatment('');
        setScene('');
        setSelectedRightType(null);
        set_questionContent('');
        
        set_groupOptions([]);
        set_selectedGroup(null);
        set_selectedProject(null);
    };

    const triggerFileInputClick = () => {
        const fileInput = document.getElementById(filesFieldId);
        if(fileInput) fileInput.click();
    };

    const handleClose = () => {
        open.value = false;
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open.value}
            TransitionComponent={Transition}
            // keepMounted
            fullWidth
            maxWidth="md"
            // fullScreen
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h6">{dialogTitle || 'Enviar consulta'}</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                right: '8px',
                                top: '8px',
                                color: theme => theme.palette.grey[500],
                            }}
                            aria-label="close"
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    (!initialSelectedProject && projects) &&
                    <Box>
                        <Box mb={2}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        label="Projeto"
                                        options={projectOptions || []}
                                        getOptionLabel={(option) => option.name}
                                        value={selectedProject}
                                        onChange={(_, newValue) => handle_selectedProjectChange(newValue)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                }
                {
                    (groupOptions.length !== 0) &&
                    <Box mb={2}>
                        <Select label="Grupo" value={selectedGroup} onChange={(e) => set_selectedGroup(e.target.value)} options={groupOptions} />
                    </Box>
                }
                {
                    !activeUser.value &&
                    <Box mb={2}>
                        <Input
                            placeholder="Seu nome"
                            fullWidth
                            value={userName} onChange={(e) => setUserName(e.target.value)}
                        />
                    </Box>
                }
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={5}>
                            <TextField
                                variant="standard"
                                autoFocus
                                fullWidth
                                label="Assunto"
                                placeholder="Descrição do assunto"
                                value={questionSubject}
                                onChange={(e) => set_questionSubject(e.target.value)}
                                onBlur={() => setDialogTitle(questionSubject)} />
                        </Grid>
                        <Grid item xs container spacing={1} alignItems="center">
                            {
                                selectedQuestionClearanceSettings.type === 'series' &&
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Episódio"
                                        placeholder="Número"
                                        value={episode}
                                        onChange={(e) => setEpisode(e.target.value)} />
                                </Grid>
                            }
                            <Grid item xs container spacing={1} alignItems="center">
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Tratamento"
                                        placeholder="Número"
                                        value={scriptTreatment}
                                        onChange={(e) => setScriptTreatment(e.target.value)} />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Cena"
                                        placeholder="Número"
                                        value={scene}
                                        onChange={(e) => setScene(e.target.value)} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {
                    (activeUserIsOperator && (selectedTemplate || (workspaceClearanceTemplates.value && selectedProject?.uid === selectedQuestionClearanceSettings.projectId && selectedQuestionClearanceSettings.data))) &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={12}>
                                <Autocomplete
                                    label="Direito envolvido"
                                    options={rightTypesOptions}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedRightType} onChange={(_, newValue) => setSelectedRightType(newValue)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Box pb={2}>
                    <Box mb={1}>
                        <Input
                            placeholder="Descrição detalhada da sua dúvida (apresente informações sobre o material, o destaque e o contexto)"
                            fullWidth multiline minRows={5} maxRows={20}
                            value={questionContent} onChange={(e) => set_questionContent(e.target.value)}
                        />
                    </Box>
                    <FilesField
                        multiple
                        filesFieldId={filesFieldId}
                        attachFileButtonComponent="none"
                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                        filesList={filesList} setFilesList={set_filesList}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <IconButton size="small" onClick={triggerFileInputClick}>
                    <AttachFileIcon />
                </IconButton>
                <Button
                    onClick={handleSubmit}
                    variant="contained" color="primary"
                    disabled={
                        loadingPage ||
                        !selectedProject
                        || !questionSubject || !questionContent
                        || (selectedProject && projectGroupSettings.value?.enabled && !selectedGroup)
                        || (activeUserIsOperator && (!workspaceClearanceTemplates.value || selectedProject?.uid !== selectedQuestionClearanceSettings.projectId || !selectedQuestionClearanceSettings.data))
                        || (!activeUserIsOperator && !selectedTemplate)
                    }
                    startIcon={loadingPage && <CircularProgress size={20} />}
                >
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddClearanceQuestionWindow;