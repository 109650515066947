import { useState } from 'react';
//@ts-ignore
import { saveAs } from 'file-saver';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import JSZip from 'jszip';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';

import DownloadIcon from '@mui/icons-material/Download';

import { useAppStateCtx } from '../../context/AppState';
import { storage } from '../../firebase/config';
import { removeDiacritics } from '../../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../../utils/constants';

const DownloadSignedDocumentsButton = ({ projectId }: { projectId: string }) => {
    useSignals();
    const { selectedWorkspace, workspaceProjects } = useAppStateCtx();
    const [downloading, setDownloading] = useState(false);
    
    const handleClick = async () => {
        if(workspaceProjects.value){
            const foundProject = workspaceProjects.value[projectId];
            if(foundProject){
                setDownloading(true);
        
                const toastId = toast.loading('Carregando documentos assinados...');
        
                const zip = new JSZip();
        
                const folderRef = ref(storage, `workspaces/${selectedWorkspace.value?.uid}/projects/${projectId}/documents/signed`);
        
                try {
                    const res = await listAll(folderRef);
                    const totalFiles = res.items.length;
                    let downloadedFiles = 0;
        
                    if (totalFiles === 0) {
                        setDownloading(false);
                        toast.update(toastId, { autoClose: 5000, isLoading: false, render: 'Não foram encontrados documentos assinados na pasta do projeto.', type: 'info' })
                        return;
                    }
        
                    const filePromises = res.items.map(async (fileRef) => {
                        const url = await getDownloadURL(fileRef);
                        const response = await fetch(url);
                        const blob = await response.blob();
                        const fileName = fileRef.name.split('/').pop();
                        if(fileName) zip.file(fileName, blob);
                        
                        downloadedFiles++;
                        toast.update(toastId, { render: `Carregando ${downloadedFiles}/${totalFiles} (${Math.round((downloadedFiles / totalFiles) * 100)}%)...` });
                    });
            
                    await Promise.all(filePromises);
            
                    const zipBlob = await zip.generateAsync({ type: "blob" }, (metadata) => {
                        toast.update(toastId, { render: `Compactando (${metadata.percent}%)...` });
                    });
                    saveAs(zipBlob, `${removeDiacritics(foundProject.name)}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.zip`);
                    toast.update(toastId, { autoClose: 5000, isLoading: false, render: `Os documentos serão disponibilizados em instantes.`, type: 'success' })
                } catch (error) {
                    console.error("Error downloading folder:", error);
                    toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
                } finally {
                    setDownloading(false);
                }
            }
        }
    }

    return (
        <Box>
            <Grid container justifyContent="center">
                <Grid>
                    <Button disabled={downloading} variant="outlined" startIcon={<DownloadIcon />} onClick={handleClick}>Download dos documentos assinados</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DownloadSignedDocumentsButton;