import { useEffect, useState } from 'react';
import { and, collection, onSnapshot, or, query, orderBy, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import Document from '../classes/Document';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspacePendingDocuments = (shouldSetListener) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const [hookState, setHookState] = useState({ loading: true, data: null });

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.modules.includes('documents')){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'documents'),
                    and(
                        where('workspaceId', '==', selectedWorkspace.value.uid),
                        where('deleted', '==', false),
                        or(
                            where('awaitingOperatorReview', '==', true),
                            where('flag.type', '==', 'client'),
                        ),
                        or(
                            where('merged', '==', true),
                            and(
                                where('merged', '==', false),
                                where('templateId', '==', 'review')
                            )
                        )
                    ),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newPendingDocument = new Document({...data, uid: snapshot.id})
                        dataArray.push(newPendingDocument);
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspacePendingDocuments;