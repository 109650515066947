import { form as castComplete } from './talents-cast-complete';
import { form as castSimple } from './talents-cast-simple';
import { form as creativesComplete } from './talents-creatives-complete';
import { form as creativesSimple } from './talents-creatives-complete';
import { form as directorComplete } from './talents-director-complete';
import { form as directorSimple } from './talents-director-simple';
import { form as option } from './talents-option';

const forms = {
    castComplete,
    castSimple,
    creativesComplete,
    creativesSimple,
    directorComplete,
    directorSimple,
    option
};

const getForm = (type) => {
    return forms[type];
};

export {
    getForm
}