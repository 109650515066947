import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { useAppStateCtx } from '../../context/AppState';

const AppDialog = () => {
    useSignals();
    const { appDialog } = useAppStateCtx();
    const [textValue, setTextValue] = useState('');

    useEffect(() => {
        if(appDialog.value.open) setTextValue(appDialog.value.textFieldInitialValue || '');
    }, [appDialog.value.open]);

    const handleClose = () => {
        appDialog.value = {
            open: false
        };
    };

    const handleCancel = () => {
        if(appDialog.value.onCancelButtonClick) return appDialog.value.onCancelButtonClick();
        handleClose();
    };

    const handleConfirm = () => {
        if(appDialog.value.onConfirmButtonClick) appDialog.value.onConfirmButtonClick(textValue);
        handleClose();
    };

    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue(e.target.value);
    }

    return (
        <Dialog open={appDialog.value.open} onClose={handleClose}>
            <DialogTitle>{appDialog.value.title || 'Tem certeza?'}</DialogTitle>
            <DialogContent>
                <DialogContentText>{appDialog.value.text || 'Clique no botão "Sim" para confirmar.'}</DialogContentText>
                {
                    appDialog.value.type === 'form' &&
                    <TextField
                        variant="standard"
                        fullWidth
                        autoFocus
                        value={textValue}
                        onChange={handleTextFieldChange}
                    />
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{appDialog.value.cancelButtonLabel || 'Não'}</Button>
                <Button onClick={handleConfirm} variant="outlined" color="primary">{appDialog.value.confirmButtonLabel || 'Sim'}</Button>
            </DialogActions>
        </Dialog>
    )
};

export default AppDialog;