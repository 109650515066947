import Box from '@mui/material/Box';
import { useDocumentsCtxSelectedDocuments } from '../../../../../../../../context/DocumentsContext';

const classes = {
    documentInnerBox: {
        padding: '8px',
        border: '2px solid transparent',
        borderRadius: '7px'
    },
};

const PendingDocumentInnerBox = ({children, handleDocumentClick, pendingDocument}) => {
    const selectedDocuments = useDocumentsCtxSelectedDocuments();

    const getBorderColor = () => {
        let style = {};
        const documentId = pendingDocument.uid;
        const selectedDocument = selectedDocuments[documentId];
        if(selectedDocument){
            const color = selectedDocuments[documentId].color;
            if(color) style = { borderColor: color };
        }
        return style;
    };

    return (
        <Box
            sx={classes.documentInnerBox}
            style={{ width: '100%', ...getBorderColor(pendingDocument) }}
            onClick={() => handleDocumentClick(pendingDocument)}
        >
            {children}
        </Box>
    );
};

export default PendingDocumentInnerBox;