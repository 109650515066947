import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppStateCtx } from '../context/AppState';
import { useDocumentsAPI, useDocumentsCtxReviewerMenu } from '../context/DocumentsContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';

const ChangeReviewerMenu = () => {
    useSignals();
    const { workspaceOperators } = useAppStateCtx();
    const { setReviewerMenuOpen } = useDocumentsAPI();
    const { reviewerMenuAnchorEl, reviewerMenuOpen, reviewerMenuSelectedDocument: selectedDocument } = useDocumentsCtxReviewerMenu();

    const handleDocOperatorChangeClick = async (operatorId) => {
        handleClose();
        const toastId = toast.loading('Alterando a pessoa responsável...');
        
        if(operatorId !== selectedDocument.operatorId){
            const res = await selectedDocument.update({
                updates: {
                    operatorId
                }
            })
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
            toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        }

    };

    const handleClose = () => {
        setReviewerMenuOpen(false);
    };

    return (
        <Menu
            anchorEl={reviewerMenuAnchorEl}
            open={reviewerMenuOpen}
            onClose={handleClose}
        >
        {
            workspaceOperators.value.map((operator) => (
                <MenuItem key={operator.uid} onClick={(e) => handleDocOperatorChangeClick(operator.uid, operator.name)}>
                    <ListItemIcon>
                        <Avatar style={{height: 32, width: 32}} alt={operator.name} src={operator.photoUrl} />
                    </ListItemIcon>
                    <ListItemText primary={operator.name} />
                </MenuItem>
            ))
        }
        </Menu>
    );
}

export default ChangeReviewerMenu;