import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from './AppState';
import useGetWorkspacePendingDocuments from '../hooks/useGetWorkspacePendingDocuments';

const DocumentsAPIContext = createContext();
const DocumentsCtxActionMenu = createContext();
const DocumentsCtxAddDocumentAlert = createContext();
const DocumentsCtxAddDocumentStarredInformationView = createContext();
const DocumentsCtxAddDocumentView = createContext();
const DocumentsCtxCancelScheduledSendDocumentView = createContext();
const DocumentsCtxChangeDocumentFolderView = createContext();
const DocumentsCtxChangeDocumentGroupView = createContext();
const DocumentsCtxChangeDocumentNameView = createContext();
const DocumentsCtxChangeDocumentStarredInformationView = createContext();
const DocumentsCtxCommentView = createContext();
const DocumentsCtxDeleteDocumentView = createContext();
const DocumentsCtxDocumentAlertsView = createContext();
const DocumentsCtxDocumentFiltersView = createContext();
const DocumentsCtxDocumentFormView = createContext();
const DocumentsCtxFlagDocumentView = createContext();
const DocumentsCtxHistoryWindow = createContext();
const DocumentsCtxLoadingTemplates = createContext();
const DocumentsCtxLoadingPendingDocuments = createContext();
const DocumentsCtxLoadingSignatures = createContext();
const DocumentsCtxPendingDocuments = createContext();
const DocumentsCtxPendingDocumentsCount = createContext();
const DocumentsCtxPendingDocumentsReviewer = createContext();
const DocumentsCtxReviewerMenu = createContext();
const DocumentsCtxSelectedDocument = createContext();
const DocumentsCtxSelectedDocuments = createContext();
const DocumentsCtxSignatureEnvelope = createContext();
const DocumentsCtxSignDocumentView = createContext();
const DocumentsCtxSubmitDocumentView = createContext();

const defaultState = {
    actionMenuAnchorEl: null,
    actionMenuOpen: false,
    actionMenuSelectedDocument: null,
    addDocumentAlertViewOpen: false,
    addDocumentStarredInformationViewAnchorPosition: { left: 0, top: 0 },
    addDocumentStarredInformationViewOpen: false,
    addDocumentStarredInformationViewSelectedDocument: null,
    addDocumentViewOpen: false,
    addDocumentViewProjects: [],
    addDocumentViewSelectedFolder: null,
    cancelScheduledSendDocumentViewOpen: false,
    cancelScheduledSendDocumentViewSelectedDocument: null,
    changeDocumentFolderViewAnchorEl: null,
    changeDocumentFolderViewOpen: false,
    changeDocumentFolderViewSelectedDocument: null,
    changeDocumentGroupViewAnchorEl: null,
    changeDocumentGroupViewOpen: false,
    changeDocumentGroupViewSelectedDocument: null,
    changeDocumentNameViewOpen: false,
    changeDocumentNameViewSelectedDocument: null,
    changeDocumentStarredInformationViewAnchorPosition: { left: 0, top: 0 },
    changeDocumentStarredInformationViewOpen: false,
    changeDocumentStarredInformationViewSelectedDocument: null,
    commentViewComment: null,
    commentViewOpen: false,
    deleteDocumentViewOpen: false,
    deleteDocumentViewSelectedDocument: null,
    documentAlertSelectedDocument: null,
    documentAlertsViewAnchorPosition: { left: 0, top: 0 },
    documentAlertsViewOpen: false,
    documentAlertsViewSelectedDocument: null,
    documentFiltersViewOpen: false,
    documentFormViewOpen: false,
    documentFormViewSelectedDocument: null,
    documentHistoryViewActiveUserIsManager: false,
    documentHistoryViewActiveUserIsOperator: false,
    eSignatureEnvelope: null,
    flagDocumentViewOpen: false,
    historyWindowAnchorPosition: { left: 0, top: 0 },
    historyWindowOpen: false,
    historyWindowSelectedDocument: null,
    loadingPendingDocuments: true,
    loadingSignatures: false,
    loadingTemplates: true,
    pendingDocuments: null,
    pendingDocumentsCount: null,
    pendingDocumentsCountPerOperator: null,
    reviewerMenuAnchorEl: null,
    reviewerMenuOpen: false,
    reviewerMenuSelectedDocument: null,
    selectedDocuments: [],
    selectedDocumentsUpdatedAt: null,
    selectedPendingDocumentsReviewer: '',
    shouldSetWorkspacePendingDocumentsSnapshot: false,
    
    signDocumentViewAction: '',
    signDocumentViewCompanyFolder: '', //
    signDocumentViewDefaultSigners: null,
    signDocumentViewDocument: null,
    signDocumentViewDocumentFolder: '', //
    signDocumentViewFolderId: '',
    signDocumentViewDocumentName: '',
    signDocumentViewFolderFolder: '', //
    signDocumentViewFolderName: '',
    signDocumentViewOpen: false,
    signDocumentViewSignaturePlatform: '',
    
    submitDocumentViewOpen: false,
    submitDocumentViewSelectedDocument: null,
    selectedDocument: null,
}

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'ADD PENDING DOCUMENT':
            return { ...state, pendingDocuments: [...state.pendingDocuments, payload] };
        case 'HIDE ADD DOCUMENT ALERT VIEW':
            return { ...state, addDocumentAlertViewOpen: false };
        case 'HIDE ADD DOCUMENT STARRED INFORMATION VIEW':
            return { ...state, addDocumentStarredInformationViewOpen: false };
        case 'HIDE ADD DOCUMENT VIEW':
            return { ...state, addDocumentViewOpen: false };
        case 'HIDE CANCEL SCHEDULED SEND DOCUMENT VIEW':
            return { ...state, cancelScheduledSendDocumentViewOpen: false };
        case 'HIDE CHANGE DOCUMENT FOLDER VIEW':
            return { ...state, changeDocumentFolderViewOpen: false };
        case 'HIDE CHANGE DOCUMENT GROUP VIEW':
            return { ...state, changeDocumentGroupViewOpen: false };
        case 'HIDE CHANGE DOCUMENT NAME VIEW':
            return { ...state, changeDocumentNameViewOpen: false };
        case 'HIDE CHANGE DOCUMENT STARRED INFORMATION VIEW':
            return { ...state, changeDocumentStarredInformationViewOpen: false };
        case 'HIDE COMMENT VIEW':
            return { ...state, commentViewOpen: false };
        case 'HIDE DELETE DOCUMENT VIEW':
            return { ...state, deleteDocumentViewOpen: false };
        case 'HIDE DOCUMENT ALERTS VIEW':
            return { ...state, documentAlertsViewOpen: false };
        case 'HIDE DOCUMENT FILTERS VIEW':
            return { ...state, documentFiltersViewOpen: false };
        case 'HIDE DOCUMENT FORM VIEW':
            return { ...state, documentFormViewOpen: false };
        case 'HIDE FLAG DOCUMENT VIEW':
            return { ...state, flagDocumentViewOpen: false };
        case 'HIDE SIGN DOCUMENT VIEW':
            return { ...state, eSignatureEnvelope: null, signDocumentViewOpen: false, signDocumentViewDocument: null };
        case 'HIDE SUBMIT DOCUMENT VIEW':
            return { ...state, submitDocumentViewOpen: false };
        case 'LOAD TEMPLATES':
            return { ...state, loadingTemplates: true };
        case 'OPEN ACTION MENU':
            return { ...state, actionMenuOpen: true };
        case 'SET ACTION MENU OPEN':
            return { ...state, actionMenuOpen: payload };
        case 'SET SIGNATURE ENVELOPE':
            return { ...state, eSignatureEnvelope: payload };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET HISTORY WINDOW OPEN':
            return { ...state, historyWindowOpen: payload };
        case 'SET HISTORY WINDOW SELECTED DOCUMENT':
            return { ...state, historyWindowSelectedDocument: payload };
        case 'SET LOADING SIGNATURES':
            return { ...state, loadingSignatures: payload };
        case 'SET PENDING DOCUMENTS LOADER':
            return { ...state, loadingPendingDocuments: payload };
        case 'SET PENDING DOCUMENTS':
            return { ...state, pendingDocuments: payload };
        case 'SET PENDING DOCUMENTS COUNT':
            return { ...state, pendingDocumentsCount: payload };
        case 'SET REVIEWER MENU ANCHOR ELEMENT':
            return { ...state, reviewerMenuAnchorEl: payload.element, reviewerMenuOpen: true, reviewerMenuSelectedDocument: payload.clickedDocument };
        case 'SET REVIEWER MENU OPEN':
            return { ...state, reviewerMenuOpen: payload };
        case 'SET SELECTED DOCUMENT':
            return { ...state, selectedDocument: payload };
        case 'SET SELECTED DOCUMENTS':
            return { ...state, selectedDocuments: payload, selectedDocumentsUpdatedAt: new Date() };
        case 'SET TEMPLATES':
            return { ...state, templates: payload };
        case 'SHOW ACTION MENU':
            return { ...state, actionMenuAnchorEl: payload.element, actionMenuOpen: true, actionMenuSelectedDocument: payload.clickedDocument };
        case 'SHOW ADD DOCUMENT ALERT VIEW':
            return { ...state, addDocumentAlertViewOpen: true, documentAlertSelectedDocument: payload.clickedDocument };
        case 'SHOW ADD DOCUMENT STARRED INFORMATION VIEW':
            return { ...state, addDocumentStarredInformationViewOpen: true, addDocumentStarredInformationViewAnchorPosition: payload.position, addDocumentStarredInformationViewSelectedDocument: payload.clickedDocument };
        case 'SHOW ADD DOCUMENT VIEW':
            return { ...state, addDocumentViewOpen: true, addDocumentViewProjects: payload.projects, addDocumentViewSelectedFolder: payload.selectedFolder };
        case 'SHOW CANCEL SCHEDULED SEND DOCUMENT VIEW':
            return { ...state, cancelScheduledSendDocumentViewOpen: true, cancelScheduledSendDocumentViewSelectedDocument: payload };
        case 'SHOW CHANGE DOCUMENT FOLDER VIEW':
            return { ...state, changeDocumentFolderViewAnchorEl: payload.element, changeDocumentFolderViewOpen: true, changeDocumentFolderViewSelectedDocument: payload.clickedDocument };
        case 'SHOW CHANGE DOCUMENT GROUP VIEW':
            return { ...state, changeDocumentGroupViewAnchorEl: payload.element, changeDocumentGroupViewOpen: true, changeDocumentGroupViewSelectedDocument: payload.clickedDocument };
        case 'SHOW CHANGE DOCUMENT NAME VIEW':
            return { ...state, changeDocumentNameViewOpen: true, changeDocumentNameViewSelectedDocument: payload };
        case 'SHOW CHANGE DOCUMENT STARRED INFORMATION VIEW':
            return { ...state, changeDocumentStarredInformationViewOpen: true, changeDocumentStarredInformationViewAnchorPosition: payload.position, changeDocumentStarredInformationViewSelectedDocument: payload.clickedDocument };
        case 'SHOW COMMENT VIEW':
            return { ...state, commentViewOpen: true, commentViewComment: payload };
        case 'SHOW DELETE DOCUMENT VIEW':
            return { ...state, deleteDocumentViewOpen: true, deleteDocumentViewSelectedDocument: payload };
        case 'SHOW DOCUMENT ALERTS VIEW':
            return { ...state, documentAlertsViewOpen: true, documentAlertsViewAnchorPosition: payload.position, documentAlertsViewSelectedDocument: payload.clickedDocument };
        case 'SHOW DOCUMENT FILTERS VIEW':
            return { ...state, documentFiltersViewOpen: true, documentFiltersViewAnchorEl: payload.element };
        case 'SHOW DOCUMENT FORM VIEW':
            return { ...state, documentFormViewOpen: true, documentFormViewSelectedDocument: payload };
        case 'SHOW DOCUMENT HISTORY VIEW':
            return {
                ...state,
                documentHistoryViewActiveUserIsManager: payload.activeUserIsManager,
                documentHistoryViewActiveUserIsOperator: payload.activeUserIsOperator,
                historyWindowAnchorPosition: payload.position,
                historyWindowOpen: true,
                historyWindowSelectedDocument: payload.clickedDocument
            };
        case 'SHOW FLAG DOCUMENT VIEW':
            return { ...state, flagDocumentViewOpen: true, flagDocumentViewSelectedDocument: payload };
        case 'SHOW SIGN DOCUMENT VIEW':
            return {
                ...state,
                signDocumentViewOpen: true,
                signDocumentViewAction: payload.action,
                signDocumentViewDocument: payload.signDocumentViewDocument,
                signDocumentViewDocumentName: payload.signDocumentViewDocumentName,
                signDocumentViewCompanyFolder: payload.signDocumentViewCompanyFolder,
                signDocumentViewFolderFolder: payload.signDocumentViewFolderFolder,
                signDocumentViewFolderId: payload.folderId,
                signDocumentViewFolderName: payload.signDocumentViewFolderName,
                signDocumentViewDefaultSigners: payload.signDocumentViewDefaultSigners,
                signDocumentViewSignaturePlatform: payload.signDocumentViewSignaturePlatform,
                eSignatureEnvelope: payload.eSignatureEnvelope
            };
        case 'SHOW SUBMIT DOCUMENT VIEW':
            return { ...state, submitDocumentViewOpen: true, submitDocumentViewSelectedDocument: payload };
        default: return state;
    }
};

const DocumentsProvider = ({children}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const workspacePendingDocuments = useGetWorkspacePendingDocuments(state.shouldSetWorkspacePendingDocumentsSnapshot);
    useEffect(() => {
        if(!workspacePendingDocuments.loading && workspacePendingDocuments.data){
            dispatch({type: 'SET PENDING DOCUMENTS', payload: workspacePendingDocuments.data});
            dispatch({type: 'SET PENDING DOCUMENTS LOADER', payload: false});
        }
    }, [workspacePendingDocuments]);

    const loadTemplates = (payload) => {
        dispatch({type: 'LOAD TEMPLATES', payload});
    };

    const setSignDocumentViewSignaturePlatform = (newValue) => {
        dispatch({ type: 'SET STATE', payload: { key: 'signDocumentViewSignaturePlatform', value: newValue } });
    };

    const setState = (key, value) => {
        dispatch({ type: 'SET STATE', payload: { key, value } });
    };

    const api = useMemo(() => {
        
        const hideAddDocumentAlertView = (payload) => {
            dispatch({type: 'HIDE ADD DOCUMENT ALERT VIEW', payload});
        };

        const hideAddDocumentStarredInformationView = (payload) => {
            dispatch({type: 'HIDE ADD DOCUMENT STARRED INFORMATION VIEW', payload});
        };

        const hideAddDocumentView = (payload) => {
            dispatch({type: 'HIDE ADD DOCUMENT VIEW', payload});
        };

        const hideCancelScheduledSendDocumentView = (payload) => {
            dispatch({type: 'HIDE CANCEL SCHEDULED SEND DOCUMENT VIEW', payload});
        };

        const hideChangeDocumentFolderView = (payload) => {
            dispatch({type: 'HIDE CHANGE DOCUMENT FOLDER VIEW', payload});
        };

        const hideChangeDocumentGroupView = (payload) => {
            dispatch({type: 'HIDE CHANGE DOCUMENT GROUP VIEW', payload});
        };

        const hideChangeDocumentNameView = (payload) => {
            dispatch({type: 'HIDE CHANGE DOCUMENT NAME VIEW', payload});
        };

        const hideChangeDocumentStarredInformationView = (payload) => {
            dispatch({type: 'HIDE CHANGE DOCUMENT STARRED INFORMATION VIEW', payload});
        };

        const hideCommentView = (payload) => {
            dispatch({type: 'HIDE COMMENT VIEW', payload});
        };

        const hideDeleteDocumentView = (payload) => {
            dispatch({type: 'HIDE DELETE DOCUMENT VIEW', payload});
        };

        const hideDocumentAlertsView = (payload) => {
            dispatch({type: 'HIDE DOCUMENT ALERTS VIEW', payload});
        };

        const hideDocumentFiltersView = (payload) => {
            dispatch({type: 'HIDE DOCUMENT FILTERS VIEW', payload});
        };
        
        const hideDocumentFormView = (payload) => {
            dispatch({type: 'HIDE DOCUMENT FORM VIEW', payload});
        };

        const hideFlagDocumentView = (payload) => {
            dispatch({type: 'HIDE FLAG DOCUMENT VIEW', payload});
        };

        const hideSignDocumentView = (payload) => {
            dispatch({type: 'HIDE SIGN DOCUMENT VIEW', payload});
        };
        
        const hideSubmitDocumentView = (payload) => {
            dispatch({type: 'HIDE SUBMIT DOCUMENT VIEW', payload});
        };

        const setActionMenuAnchorEl = (element, clickedDocument) => {
            dispatch({type: 'SHOW ACTION MENU', payload: { element, clickedDocument }});
        };

        const setActionMenuOpen = (payload) => {
            dispatch({type: 'SET ACTION MENU OPEN', payload});
        };

        const setHistoryWindowOpen = (payload) => {
            dispatch({type: 'SET HISTORY WINDOW OPEN', payload});
        };

        const setHistoryWindowSelectedDocument = (payload) => {
            dispatch({type: 'SET HISTORY WINDOW SELECTED DOCUMENT', payload});
        };

        const setLoadingSignatures = (payload) => {
            dispatch({type: 'SET LOADING SIGNATURES', payload});
        };

        const setReviewerMenuAnchorEl = (element, clickedDocument) => {
            dispatch({type: 'SET REVIEWER MENU ANCHOR ELEMENT', payload: { element, clickedDocument }});
        };

        const setReviewerMenuOpen = (payload) => {
            dispatch({type: 'SET REVIEWER MENU OPEN', payload});
        };

        const setSelectedDocument = (payload) => {
            dispatch({type: 'SET SELECTED DOCUMENT', payload});
        };

        const setSelectedDocuments = (payload) => {
            dispatch({type: 'SET SELECTED DOCUMENTS', payload});
        };

        const setSnapshot = (triggerKey) => {
            setState(triggerKey, true);
        }

        const showAddDocumentAlertView = (clickedDocument) => {
            dispatch({type: 'SHOW ADD DOCUMENT ALERT VIEW', payload: { clickedDocument }});
        };

        const showAddDocumentStarredInformationView = (position, clickedDocument) => {
            dispatch({type: 'SHOW ADD DOCUMENT STARRED INFORMATION VIEW', payload: { position, clickedDocument }});
        };

        const showAddDocumentView = (projects, selectedFolder) => {
            dispatch({type: 'SHOW ADD DOCUMENT VIEW', payload: { projects, selectedFolder }});
        };

        const showCancelScheduledSendDocumentView = (clickedDocument) => {
            dispatch({type: 'SHOW CANCEL SCHEDULED SEND DOCUMENT VIEW', payload: clickedDocument});
        };

        const showChangeDocumentFolderView = (element, clickedDocument) => {
            dispatch({type: 'SHOW CHANGE DOCUMENT FOLDER VIEW', payload: { element, clickedDocument }});
        };

        const showChangeDocumentGroupView = (element, clickedDocument) => {
            dispatch({type: 'SHOW CHANGE DOCUMENT GROUP VIEW', payload: { element, clickedDocument }});
        };

        const showChangeDocumentNameView = (payload) => {
            dispatch({type: 'SHOW CHANGE DOCUMENT NAME VIEW', payload});
        };

        const showChangeDocumentStarredInformationView = (position, clickedDocument) => {
            dispatch({type: 'SHOW CHANGE DOCUMENT STARRED INFORMATION VIEW', payload: { position, clickedDocument }});
        };

        const showCommentView = (payload) => {
            dispatch({type: 'SHOW COMMENT VIEW', payload});
        };

        const showDeleteDocumentView = (payload) => {
            dispatch({type: 'SHOW DELETE DOCUMENT VIEW', payload});
        };

        const showDocumentAlertsView = (position, clickedDocument) => {
            dispatch({type: 'SHOW DOCUMENT ALERTS VIEW', payload: { position, clickedDocument }});
        };

        const showDocumentFiltersView = (element) => {
            dispatch({type: 'SHOW DOCUMENT FILTERS VIEW', payload: { element }});
        };

        const showDocumentFormView = (payload) => {
            dispatch({type: 'SHOW DOCUMENT FORM VIEW', payload});
        };

        const showFlagDocumentView = (payload) => {
            dispatch({type: 'SHOW FLAG DOCUMENT VIEW', payload});
        };

        const showDocumentHistoryView = (position, clickedDocument, activeUserIsOperator, activeUserIsManager) => {
            dispatch({type: 'SHOW DOCUMENT HISTORY VIEW', payload: { position, clickedDocument, activeUserIsOperator, activeUserIsManager }});
        };

        const showSignDocumentView = (args) => {
            dispatch({
                type: 'SHOW SIGN DOCUMENT VIEW',
                payload: {
                    action: args.action,
                    folderId: args.folderId,
                    signDocumentViewDocument: args.document,
                    signDocumentViewDocumentName: args.documentName,
                    signDocumentViewCompanyFolder: '',
                    signDocumentViewFolderFolder: args.documentLocation,
                    signDocumentViewFolderName: args.folderName,
                    signDocumentViewDefaultSigners: args.initialSigners,
                    signDocumentViewSignaturePlatform: args.platform,
                    eSignatureEnvelope: args.envelope
                }
            });
        };

        const showSubmitDocumentView = (payload) => {
            dispatch({type: 'SHOW SUBMIT DOCUMENT VIEW', payload});
        };

        return {
            dispatch,
            hideAddDocumentAlertView, hideAddDocumentStarredInformationView, hideAddDocumentView, hideCancelScheduledSendDocumentView, hideChangeDocumentFolderView, hideChangeDocumentGroupView, hideChangeDocumentNameView, hideChangeDocumentStarredInformationView, hideCommentView, hideDeleteDocumentView, hideDocumentAlertsView, hideDocumentFiltersView, hideDocumentFormView, hideFlagDocumentView, hideSignDocumentView, hideSubmitDocumentView,
            loadTemplates,
            setActionMenuAnchorEl, setActionMenuOpen, setHistoryWindowOpen, setHistoryWindowSelectedDocument,
            setLoadingSignatures,
            setReviewerMenuAnchorEl, setReviewerMenuOpen, setSelectedDocument, setSelectedDocuments,
            setSignDocumentViewSignaturePlatform,
            setSnapshot,
            setState,
            showAddDocumentAlertView, showAddDocumentStarredInformationView, showAddDocumentView, showCancelScheduledSendDocumentView, showChangeDocumentGroupView, showChangeDocumentFolderView, showChangeDocumentNameView, showChangeDocumentStarredInformationView, showCommentView, showDeleteDocumentView, showDocumentAlertsView, showDocumentFiltersView, showDocumentFormView, showFlagDocumentView, showDocumentHistoryView, showSignDocumentView, showSubmitDocumentView,
        };
    }, []);

    useEffect(() => {
        if(state?.pendingDocuments){
            dispatch({type: 'SET PENDING DOCUMENTS COUNT', payload: state.pendingDocuments.length});
            const pendingDocumentsCountPerOperator = {};
            workspacePendingDocuments.data.forEach(document => {
                if(!pendingDocumentsCountPerOperator[document.sentBy]) pendingDocumentsCountPerOperator[document.sentBy] = 0;
                pendingDocumentsCountPerOperator[document.sentBy]++;
            });
            setState('pendingDocumentsCountPerOperator', pendingDocumentsCountPerOperator);
        }
    }, [state?.pendingDocuments]);

    useEffect(() => {
        if(activeUser.value) dispatch({ type: 'SET STATE', payload: { key: 'selectedPendingDocumentsReviewer', value: activeUser.value.uid } });
    }, [activeUser.value]);

    const documentsCtxActionMenuValue = useMemo(() => ({
        actionMenuAnchorEl: state.actionMenuAnchorEl,
        actionMenuOpen: state.actionMenuOpen,
        actionMenuSelectedDocument: state.actionMenuSelectedDocument
    }), [state.actionMenuOpen]);

    const documentsCtxReviewerMenuValue = useMemo(() => ({
        reviewerMenuAnchorEl: state.reviewerMenuAnchorEl,
        reviewerMenuOpen: state.reviewerMenuOpen,
        reviewerMenuSelectedDocument: state.reviewerMenuSelectedDocument
    }), [state.reviewerMenuOpen]);

    const documentsCtxHistoryWindowValue = useMemo(() => ({
        documentHistoryViewActiveUserIsManager: state.documentHistoryViewActiveUserIsManager,
        documentHistoryViewActiveUserIsOperator: state.documentHistoryViewActiveUserIsOperator,
        historyWindowAnchorPosition: state.historyWindowAnchorPosition,
        historyWindowOpen: state.historyWindowOpen,
        historyWindowSelectedDocument: state.historyWindowSelectedDocument
    }), [state.historyWindowOpen]);

    const documentsCtxSubmitDocumentViewValue = useMemo(() => ({
        submitDocumentViewOpen: state.submitDocumentViewOpen,
        submitDocumentViewSelectedDocument: state.submitDocumentViewSelectedDocument
    }), [state.submitDocumentViewOpen, state.submitDocumentViewSelectedDocument]);

    const documentsCtxDocumentFormViewValue = useMemo(() => ({
        documentFormViewOpen: state.documentFormViewOpen,
        documentFormViewSelectedDocument: state.documentFormViewSelectedDocument
    }), [state.documentFormViewOpen]);

    const documentsCtxFlagDocumentViewValue = useMemo(() => ({
        flagDocumentViewOpen: state.flagDocumentViewOpen,
        flagDocumentViewSelectedDocument: state.flagDocumentViewSelectedDocument
    }), [state.flagDocumentViewOpen]);

    const documentsCtxAddDocumentAlertViewValue = useMemo(() => ({
        addDocumentAlertViewOpen: state.addDocumentAlertViewOpen,
        documentAlertSelectedDocument: state.documentAlertSelectedDocument
    }), [state.addDocumentAlertViewOpen]);

    const documentsCtxDeleteDocumentViewValue = useMemo(() => ({
        deleteDocumentViewOpen: state.deleteDocumentViewOpen,
        deleteDocumentViewSelectedDocument: state.deleteDocumentViewSelectedDocument
    }), [state.deleteDocumentViewOpen]);

    const documentsCtxChangeDocumentNameViewValue = useMemo(() => ({
        changeDocumentNameViewOpen: state.changeDocumentNameViewOpen,
        changeDocumentNameViewSelectedDocument: state.changeDocumentNameViewSelectedDocument
    }), [state.changeDocumentNameViewOpen]);

    const documentsCtxChangeDocumentFolderViewValue = useMemo(() => ({
        changeDocumentFolderViewAnchorEl: state.changeDocumentFolderViewAnchorEl,
        changeDocumentFolderViewOpen: state.changeDocumentFolderViewOpen,
        changeDocumentFolderViewSelectedDocument: state.changeDocumentFolderViewSelectedDocument
    }), [state.changeDocumentFolderViewOpen]);

    const documentsCtxChangeDocumentGroupViewValue = useMemo(() => ({
        changeDocumentGroupViewAnchorEl: state.changeDocumentGroupViewAnchorEl,
        changeDocumentGroupViewOpen: state.changeDocumentGroupViewOpen,
        changeDocumentGroupViewSelectedDocument: state.changeDocumentGroupViewSelectedDocument
    }), [state.changeDocumentGroupViewOpen]);

    const documentsCtxChangeDocumentStarredInformationViewValue = useMemo(() => ({
        changeDocumentStarredInformationViewAnchorPosition: state.changeDocumentStarredInformationViewAnchorPosition,
        changeDocumentStarredInformationViewOpen: state.changeDocumentStarredInformationViewOpen,
        changeDocumentStarredInformationViewSelectedDocument: state.changeDocumentStarredInformationViewSelectedDocument
    }), [state.changeDocumentStarredInformationViewOpen]);

    const documentsCtxAddDocumentStarredInformationViewValue = useMemo(() => ({
        addDocumentStarredInformationViewAnchorPosition: state.addDocumentStarredInformationViewAnchorPosition,
        addDocumentStarredInformationViewOpen: state.addDocumentStarredInformationViewOpen,
        addDocumentStarredInformationViewSelectedDocument: state.addDocumentStarredInformationViewSelectedDocument
    }), [state.addDocumentStarredInformationViewOpen]);

    const documentsCtxDocumentAlertsViewValue = useMemo(() => ({
        documentAlertsViewAnchorPosition: state.documentAlertsViewAnchorPosition,
        documentAlertsViewOpen: state.documentAlertsViewOpen,
        documentAlertsViewSelectedDocument: state.documentAlertsViewSelectedDocument
    }), [state.documentAlertsViewOpen]);

    const documentsCtxAddDocumentViewValue = useMemo(() => ({
        addDocumentViewOpen: state.addDocumentViewOpen,
        addDocumentViewProjects: state.addDocumentViewProjects,
        addDocumentViewSelectedFolder: state.addDocumentViewSelectedFolder
    }), [state.addDocumentViewOpen]);

    const documentsCtxCommentViewValue = useMemo(() => ({
        commentViewComment: state.commentViewComment,
        commentViewOpen: state.commentViewOpen
    }), [state.commentViewOpen]);

    const documentsCtxDocumentFiltersViewValue = useMemo(() => ({
        documentFiltersViewAnchorEl: state.documentFiltersViewAnchorEl,
        documentFiltersViewOpen: state.documentFiltersViewOpen
    }), [state.documentFiltersViewOpen]);

    const documentsCtxCancelScheduledSendDocumentViewValue = useMemo(() => ({
        cancelScheduledSendDocumentViewOpen: state.cancelScheduledSendDocumentViewOpen,
        cancelScheduledSendDocumentViewSelectedDocument: state.cancelScheduledSendDocumentViewSelectedDocument
    }), [state.cancelScheduledSendDocumentViewOpen]);

    const documentsCtxSignDocumentViewValue = useMemo(() => ({
        signDocumentViewOpen: state.signDocumentViewOpen,
        signDocumentViewAction: state.signDocumentViewAction,
        signDocumentViewDocument: state.signDocumentViewDocument,
        signDocumentViewDocumentName: state.signDocumentViewDocumentName,
        signDocumentViewCompanyFolder: state.signDocumentViewCompanyFolder,
        signDocumentViewFolderFolder: state.signDocumentViewFolderFolder,
        signDocumentViewFolderId: state.signDocumentViewFolderId,
        signDocumentViewFolderName: state.signDocumentViewFolderName,
        signDocumentViewDefaultSigners: state.signDocumentViewDefaultSigners,
        signDocumentViewSignaturePlatform: state.signDocumentViewSignaturePlatform,
        // eSignatureEnvelope: state.eSignatureEnvelope,
    }), [state.signDocumentViewOpen, state.signDocumentViewSignaturePlatform]);

    const documentsCtxPendingDocumentsCountValue = useMemo(() => ({
        pendingDocumentsCount: state.pendingDocumentsCount,
        pendingDocumentsCountPerOperator: state.pendingDocumentsCountPerOperator
    }), [state.pendingDocumentsCount, state.pendingDocumentsCountPerOperator]);

    return (
        <DocumentsAPIContext.Provider value={api}>
            <DocumentsCtxActionMenu.Provider value={documentsCtxActionMenuValue}>
            <DocumentsCtxReviewerMenu.Provider value={documentsCtxReviewerMenuValue}>
            <DocumentsCtxHistoryWindow.Provider value={documentsCtxHistoryWindowValue}>
            <DocumentsCtxSubmitDocumentView.Provider value={documentsCtxSubmitDocumentViewValue}>
            <DocumentsCtxDocumentFormView.Provider value={documentsCtxDocumentFormViewValue}>
            <DocumentsCtxFlagDocumentView.Provider value={documentsCtxFlagDocumentViewValue}>
            <DocumentsCtxAddDocumentAlert.Provider value={documentsCtxAddDocumentAlertViewValue}>
            <DocumentsCtxDeleteDocumentView.Provider value={documentsCtxDeleteDocumentViewValue}>
            <DocumentsCtxChangeDocumentNameView.Provider value={documentsCtxChangeDocumentNameViewValue}>
            <DocumentsCtxChangeDocumentFolderView.Provider value={documentsCtxChangeDocumentFolderViewValue}>
            <DocumentsCtxChangeDocumentGroupView.Provider value={documentsCtxChangeDocumentGroupViewValue}>
            <DocumentsCtxChangeDocumentStarredInformationView.Provider value={documentsCtxChangeDocumentStarredInformationViewValue}>
            <DocumentsCtxAddDocumentStarredInformationView.Provider value={documentsCtxAddDocumentStarredInformationViewValue}>
            <DocumentsCtxDocumentAlertsView.Provider value={documentsCtxDocumentAlertsViewValue}>
            <DocumentsCtxAddDocumentView.Provider value={documentsCtxAddDocumentViewValue}>
            <DocumentsCtxCommentView.Provider value={documentsCtxCommentViewValue}>
            <DocumentsCtxDocumentFiltersView.Provider value={documentsCtxDocumentFiltersViewValue}>
            <DocumentsCtxSignDocumentView.Provider value={documentsCtxSignDocumentViewValue}>
            <DocumentsCtxLoadingSignatures.Provider value={state.loadingSignatures}>
            <DocumentsCtxLoadingTemplates.Provider value={state.loadingTemplates}>
            <DocumentsCtxLoadingPendingDocuments.Provider value={state.loadingPendingDocuments}>
            <DocumentsCtxPendingDocuments.Provider value={state.pendingDocuments}>
            <DocumentsCtxPendingDocumentsCount.Provider value={documentsCtxPendingDocumentsCountValue}>
            <DocumentsCtxSelectedDocument.Provider value={state.selectedDocument}>
            <DocumentsCtxSignatureEnvelope.Provider value={state.eSignatureEnvelope}>
            <DocumentsCtxSelectedDocuments.Provider value={state.selectedDocuments}>
            <DocumentsCtxPendingDocumentsReviewer.Provider value={state.selectedPendingDocumentsReviewer}>
            <DocumentsCtxCancelScheduledSendDocumentView.Provider value={documentsCtxCancelScheduledSendDocumentViewValue}>
                {children}
            </DocumentsCtxCancelScheduledSendDocumentView.Provider>
            </DocumentsCtxPendingDocumentsReviewer.Provider>
            </DocumentsCtxSelectedDocuments.Provider>
            </DocumentsCtxSignatureEnvelope.Provider>
            </DocumentsCtxSelectedDocument.Provider>
            </DocumentsCtxPendingDocumentsCount.Provider>
            </DocumentsCtxPendingDocuments.Provider>
            </DocumentsCtxLoadingPendingDocuments.Provider>
            </DocumentsCtxLoadingTemplates.Provider>
            </DocumentsCtxLoadingSignatures.Provider>
            </DocumentsCtxSignDocumentView.Provider>
            </DocumentsCtxDocumentFiltersView.Provider>
            </DocumentsCtxCommentView.Provider>
            </DocumentsCtxAddDocumentView.Provider>
            </DocumentsCtxDocumentAlertsView.Provider>
            </DocumentsCtxAddDocumentStarredInformationView.Provider>
            </DocumentsCtxChangeDocumentStarredInformationView.Provider>
            </DocumentsCtxChangeDocumentGroupView.Provider>
            </DocumentsCtxChangeDocumentFolderView.Provider>
            </DocumentsCtxChangeDocumentNameView.Provider>
            </DocumentsCtxDeleteDocumentView.Provider>
            </DocumentsCtxAddDocumentAlert.Provider>
            </DocumentsCtxFlagDocumentView.Provider>
            </DocumentsCtxDocumentFormView.Provider>
            </DocumentsCtxSubmitDocumentView.Provider>
            </DocumentsCtxHistoryWindow.Provider>
            </DocumentsCtxReviewerMenu.Provider>
            </DocumentsCtxActionMenu.Provider>
        </DocumentsAPIContext.Provider>
    );
};

const useDocumentsAPI = () => useContext(DocumentsAPIContext);
const useDocumentsCtxActionMenu = () => useContext(DocumentsCtxActionMenu);
const useDocumentsCtxAddDocumentAlert = () => useContext(DocumentsCtxAddDocumentAlert);
const useDocumentsCtxAddDocumentStarredInformationView = () => useContext(DocumentsCtxAddDocumentStarredInformationView);
const useDocumentsCtxAddDocumentView = () => useContext(DocumentsCtxAddDocumentView);
const useDocumentsCtxCancelScheduledSendDocumentView = () => useContext(DocumentsCtxCancelScheduledSendDocumentView);
const useDocumentsCtxChangeDocumentFolderView = () => useContext(DocumentsCtxChangeDocumentFolderView);
const useDocumentsCtxChangeDocumentGroupView = () => useContext(DocumentsCtxChangeDocumentGroupView);
const useDocumentsCtxChangeDocumentNameView = () => useContext(DocumentsCtxChangeDocumentNameView);
const useDocumentsCtxChangeDocumentStarredInformationView = () => useContext(DocumentsCtxChangeDocumentStarredInformationView);
const useDocumentsCtxCommentView = () => useContext(DocumentsCtxCommentView);
const useDocumentsCtxDeleteDocumentView = () => useContext(DocumentsCtxDeleteDocumentView);
const useDocumentsCtxDocumentAlertsView = () => useContext(DocumentsCtxDocumentAlertsView);
const useDocumentsCtxDocumentFiltersView = () => useContext(DocumentsCtxDocumentFiltersView);
const useDocumentsCtxDocumentFormView = () => useContext(DocumentsCtxDocumentFormView);
const useDocumentsCtxFlagDocumentView = () => useContext(DocumentsCtxFlagDocumentView);
const useDocumentsCtxHistoryWindow = () => useContext(DocumentsCtxHistoryWindow);
const useDocumentsCtxLoadingTemplates = () => useContext(DocumentsCtxLoadingTemplates);
const useDocumentsCtxLoadingPendingDocuments = () => useContext(DocumentsCtxLoadingPendingDocuments);
const useDocumentsCtxLoadingSignatures = () => useContext(DocumentsCtxLoadingSignatures);
const useDocumentsCtxPendingDocuments = () => useContext(DocumentsCtxPendingDocuments);
const useDocumentsCtxPendingDocumentsCount = () => useContext(DocumentsCtxPendingDocumentsCount);
const useDocumentsCtxPendingDocumentsReviewer = () => useContext(DocumentsCtxPendingDocumentsReviewer);
const useDocumentsCtxReviewerMenu = () => useContext(DocumentsCtxReviewerMenu);
const useDocumentsCtxSelectedDocument = () => useContext(DocumentsCtxSelectedDocument);
const useDocumentsCtxSelectedDocuments = () => useContext(DocumentsCtxSelectedDocuments);
const useDocumentsCtxSignatureEnvelope = () => useContext(DocumentsCtxSignatureEnvelope);
const useDocumentsCtxSignDocumentView = () => useContext(DocumentsCtxSignDocumentView);
const useDocumentsCtxSubmitDocumentView = () => useContext(DocumentsCtxSubmitDocumentView);

export {
    DocumentsProvider,
    useDocumentsAPI,
    useDocumentsCtxActionMenu,
    useDocumentsCtxAddDocumentAlert,
    useDocumentsCtxAddDocumentStarredInformationView,
    useDocumentsCtxAddDocumentView,
    useDocumentsCtxCancelScheduledSendDocumentView,
    useDocumentsCtxChangeDocumentFolderView,
    useDocumentsCtxChangeDocumentGroupView,
    useDocumentsCtxChangeDocumentNameView,
    useDocumentsCtxChangeDocumentStarredInformationView,
    useDocumentsCtxCommentView,
    useDocumentsCtxDeleteDocumentView,
    useDocumentsCtxDocumentAlertsView,
    useDocumentsCtxDocumentFiltersView,
    useDocumentsCtxDocumentFormView,
    useDocumentsCtxFlagDocumentView,
    useDocumentsCtxHistoryWindow,
    useDocumentsCtxLoadingTemplates,
    useDocumentsCtxLoadingPendingDocuments,
    useDocumentsCtxLoadingSignatures,
    useDocumentsCtxPendingDocuments,
    useDocumentsCtxPendingDocumentsCount,
    useDocumentsCtxPendingDocumentsReviewer,
    useDocumentsCtxReviewerMenu,
    useDocumentsCtxSelectedDocument,
    useDocumentsCtxSelectedDocuments,
    useDocumentsCtxSignatureEnvelope,
    useDocumentsCtxSignDocumentView,
    useDocumentsCtxSubmitDocumentView,
};