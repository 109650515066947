import { Routes, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import DocsTemplates from './Templates';
import Form from './Form';
import Lists from './Lists';
import Pending from './Pending';
import Sent from './Sent';

function Docs(){
    return (
        <Box style={{height: '100%'}}>
            <Routes>
                <Route path="/" element={<Pending />} />
                <Route path="/formulario" element={<Form />} />
                <Route path="/listas" element={<Lists />} />
                <Route path="/pendentes" element={<Pending />} />
                <Route path="/pesquisar" element={<Sent />} />
                <Route path="/matrizes/*" element={<DocsTemplates />} />
                <Route path="*" element={<Pending />} />
            </Routes>
        </Box>
    );
}

export default Docs;