import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

import { useAppStateCtx } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceDocumentsTemplates = (shouldSetListener) => {
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && selectedWorkspace.value){
            setHookState(defaultHookState);
            const unsubscribe = onSnapshot(
                doc(db, `workspaces/${selectedWorkspace.value.uid}/_more/documents_templates`),
                (snapshot) => {
                    const data = snapshot.data();
                    setHookState({
                        loading: false,
                        data: data?.documentsTemplates || {}
                    });
                },
                // query(collection(db, 'documents_templates'), where('workspaceId', '==', selectedWorkspace.value.uid), orderBy('name', 'asc')),
                // (snapshots) => {
                //     const foundDocumentsTemplates = [];
                //     snapshots.forEach(snapshot => {
                //         const data = snapshot.data();
                //         foundDocumentsTemplates.push({
                //             ...data,
                //             uid: snapshot.id,
                //             label: `${data.name}${data.description ? ` (${data.description})` : ''}`
                //         })
                //     });
                //     setHookState({
                //         loading: false,
                //         data: foundDocumentsTemplates
                //     });
                // },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspaceDocumentsTemplates;