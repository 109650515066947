import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Container, Slide } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Dialog from './Dialog/Dialog';
import LoaderEllipsis from './LoaderEllipsis';
import ProjectWindowSignatureSection from '../pages/Operator/Folders/components/ProjectWindowSignatureSection';
import Select from './Select';

import useGetFolderESignatureSettings from '../hooks/useGetFolderESignatureSettings';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppStateCtx } from '../context/AppState';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxFolderSignatureView } from '../context/OperatorFoldersContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ESignaturePlatformSelect = ({ folderState, setFolderState }) => {
    const { selectedWorkspace } = useAppStateCtx();
    const eSignaturePlatformOptions = [{value: 'docuSign', label: 'DocuSign'}, {value: 'zapSign', label: 'ZapSign'}];
    return (
        <Select
            disabled={!['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)}
            label="Plataforma"
            options={eSignaturePlatformOptions}
            value={folderState.platform} onChange={(e) => setFolderState(prevState => ({...prevState, platform: e.target.value}))}
        />
    )
}

const ChangeProjectSignersWindow = ({ type }) => {
    useSignals();
    const { hideFolderSignatureView } = useOperatorFoldersCtxAPI();
    const { folderSignatureViewOpen, folderSignatureViewSelectedFolder: selectedFolder } = useOperatorFoldersCtxFolderSignatureView();
    const [loading, setLoading] = useState(false);
    const defaultFolderState = {
        platform: 'zapSign',
        signers: {},
    };
    
    const [folderState, setFolderState] = useState(defaultFolderState);
    const [saving, setSaving] = useState(false);

    const retrievedFolderESignatureSettings = useGetFolderESignatureSettings(selectedFolder?.uid ? `${type}s/${selectedFolder?.uid}` : '');
    useEffect(() => {
        if(retrievedFolderESignatureSettings.loading){
            setLoading(true);
        }
    }, [retrievedFolderESignatureSettings]);

    useEffect(() => {
        if(folderSignatureViewOpen && selectedFolder && retrievedFolderESignatureSettings.data){
            let platform;
            if(type !== 'client') platform = retrievedFolderESignatureSettings.data.platform || 'zapSign';
            let signers = retrievedFolderESignatureSettings.data.signers || {};
            setFolderState(prevState => ({
                ...prevState,
                platform,
                signers
            }));

            setLoading(false);
        }
    }, [folderSignatureViewOpen, selectedFolder, retrievedFolderESignatureSettings]);

    const handleSubmit = async () => {
        setSaving(true);

        const toastId = toast.loading(`Salvando o ${type === 'client' ? 'cliente' : 'projeto'} "${selectedFolder.name}"...`);

        const updates = { signers: folderState.signers };
        if(type !== 'client') updates.platform = folderState.platform;

        const res = await selectedFolder.updateESignatureSettings({ updates });
        setSaving(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        hideFolderSignatureView();
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={folderSignatureViewOpen}
            saving={saving}
            title={`${selectedFolder?.name} | Assinatura eletrônica`}
            TransitionComponent={Transition}
        >

            <Container maxWidth="md">
                {
                    folderSignatureViewOpen &&
                    <>
                        {
                            !selectedFolder || loading
                            ? <LoaderEllipsis />
                            :
                            <Box>
                                {
                                    type !== 'client' &&
                                    <Box mb={3}>
                                        <Grid container>
                                            <Grid size={{ xs: 12, sm: 6, md: 3}}>
                                                <ESignaturePlatformSelect
                                                    folderState={folderState}
                                                    setFolderState={setFolderState}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                                <ProjectWindowSignatureSection projectState={folderState} setProjectState={setFolderState} />
                            </Box>
                        }
                    </>
                }
            </Container>

        </Dialog>
    );
};

export default ChangeProjectSignersWindow;