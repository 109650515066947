import { useRef } from 'react';

import FormQuestionFile from '../../../../components/FormQuestionFile';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const FileQuestion = ({question, setQuestionValid}) => {
    const acceptedFilesRef = useRef([]);
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'file',
        setQuestionValid,
    });

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionFile
            acceptedFilesRef={acceptedFilesRef}
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
        />
    );
};

export default FileQuestion;