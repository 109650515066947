export type DocumentFieldValue = any;

export type DocumentData = {[field: string]: DocumentFieldValue};

export type _ClearanceNote = {
    approvals: { [userId: string]: string };
    approved: boolean;
    awaitingOperatorReview: boolean;
    commentsCount: number;
    commentsReadAt: {
        [userId: string]: string;
    };
    createdAt: string;
    createdBy: string;
    cutTimeCode: string;
    cutSeconds: number | null;
    deleted: boolean;
    deletedAt: string;
    deletedBy: string;
    description: string;
    filesCount: number;
    groupId: string;
    lastComment: (_ClearanceNoteComment & { uid: string; }) | null;
    legalRecommendation: string;
    licensed: boolean;
    licenseLink: string;
    originalReportId: string;
    projectId: string;
    reportId: string;
    requiresAdditionalInformation: boolean;
    rightType: string;
    rightTypeDescription: string;
    riskDescription: string;
    riskLevel: string;
    riskLevelDescription: string;
    screenShotFileUrl: string;
    screenShotWithMarkersFileUrl: string;
    screenShotMarkers: any;
    scriptScene: number | null;
    uid: string;
    useType: string;
    useTypeDescription: string;
    workspaceId: string;
};

export class ClearanceNote implements _ClearanceNote {
    approvals: _ClearanceNote['approvals'] = {};
    approved = false;
    awaitingOperatorReview = false;
    commentsCount = 0;
    commentsReadAt: _ClearanceNote['commentsReadAt'] = {};
    createdAt = '';
    createdBy = '';
    cutTimeCode = '';
    cutSeconds: _ClearanceNote['cutSeconds'] = null;
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    description = '';
    filesCount = 0;
    groupId = '';
    lastComment: _ClearanceNote['lastComment'] = null;
    legalRecommendation = '';
    licensed = false;
    licenseLink = '';
    originalReportId = '';
    projectId = '';
    reportId = '';
    requiresAdditionalInformation = false;
    rightType = '';
    rightTypeDescription = '';
    riskDescription = '';
    riskLevel = '';
    riskLevelDescription = '';
    screenShotFileUrl = '';
    screenShotWithMarkersFileUrl = '';
    screenShotMarkers: _ClearanceNote['screenShotMarkers'] = null;
    scriptScene: _ClearanceNote['scriptScene'] = null;
    uid = '';
    useType = '';
    useTypeDescription = '';
    workspaceId = '';

    constructor({
        awaitingOperatorReview,
        approvals,
        approved,
        commentsCount,
        commentsReadAt,
        createdAt,
        createdBy,
        cutTimeCode,
        cutSeconds,
        deleted,
        deletedAt,
        deletedBy,
        description,
        filesCount,
        groupId,
        lastComment,
        legalRecommendation,
        licensed,
        licenseLink,
        originalReportId,
        projectId,
        reportId,
        requiresAdditionalInformation,
        rightType,
        rightTypeDescription,
        riskDescription,
        riskLevel,
        riskLevelDescription,
        screenShotFileUrl,
        screenShotWithMarkersFileUrl,
        screenShotMarkers,
        scriptScene,
        uid,
        useType,
        useTypeDescription,
        workspaceId,
    }: _ClearanceNote | DocumentData){
        if(awaitingOperatorReview) this.awaitingOperatorReview = awaitingOperatorReview;
        if(approvals) this.approvals = approvals;
        if(approved) this.approved = approved;
        if(commentsCount) this.commentsCount = commentsCount;
        if(commentsReadAt) this.commentsReadAt = commentsReadAt;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(cutTimeCode) this.cutTimeCode = cutTimeCode;
        if(cutSeconds) this.cutSeconds = cutSeconds;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(description) this.description = description;
        if(filesCount) this.filesCount = filesCount;
        if(groupId) this.groupId = groupId;
        if(lastComment) this.lastComment = lastComment;
        if(legalRecommendation) this.legalRecommendation = legalRecommendation;
        if(licensed) this.licensed = licensed;
        if(licenseLink) this.licenseLink = licenseLink;
        if(originalReportId) this.originalReportId = originalReportId;
        if(projectId) this.projectId = projectId;
        if(reportId) this.reportId = reportId;
        if(requiresAdditionalInformation) this.requiresAdditionalInformation = requiresAdditionalInformation;
        if(rightType) this.rightType = rightType;
        if(rightTypeDescription) this.rightTypeDescription = rightTypeDescription;
        if(riskDescription) this.riskDescription = riskDescription;
        if(riskLevel) this.riskLevel = riskLevel;
        if(riskLevelDescription) this.riskLevelDescription = riskLevelDescription;
        if(screenShotFileUrl) this.screenShotFileUrl = screenShotFileUrl;
        if(screenShotWithMarkersFileUrl) this.screenShotWithMarkersFileUrl = screenShotWithMarkersFileUrl;
        if(screenShotMarkers) this.screenShotMarkers = screenShotMarkers;
        if(scriptScene) this.scriptScene = scriptScene;
        if(uid) this.uid = uid;
        if(useType) this.useType = useType;
        if(useTypeDescription) this.useTypeDescription = useTypeDescription;
        if(workspaceId) this.workspaceId = workspaceId;
    }
}

export type _ClearanceNoteComment = {
    approval?: string;
    createdAt: string;
    createdBy: string;
    createdByName?: string;
    comment: string;
    deleted?: boolean;
    deletedAt?: string;
    deletedBy?: string;
    files?: {
        [fileId: string]: {
            firebaseStoragePath: string;
            name: string;
            url: string;
        }
    };
    rightType?: string;
    rightTypeDescription?: string;
    riskLevel?: string;
    riskLevelDescription?: string;
    uid?: string;
    useType?: string;
    useTypeDescription?: string;
};

export default class ClearanceNoteComment implements _ClearanceNoteComment {
    approval? = '';
    createdAt = '';
    createdBy = '';
    createdByName? = '';
    comment = '';
    deleted? = false;
    deletedAt? = '';
    deletedBy? = '';
    files?: _ClearanceNoteComment['files'] = {};
    rightType? = '';
    rightTypeDescription? = '';
    riskLevel? = '';
    riskLevelDescription? = '';
    uid? = '';
    useType? = '';
    useTypeDescription? = '';

    constructor({
        approval,
        createdAt,
        createdBy,
        createdByName,
        comment,
        deleted,
        deletedAt,
        deletedBy,
        files,
        rightType,
        rightTypeDescription,
        riskLevel,
        riskLevelDescription,
        uid,
        useType,
        useTypeDescription,
    }: ClearanceNoteComment){
        if(approval) this.approval = approval;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(createdByName) this.createdByName = createdByName;
        if(comment) this.comment = comment;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(files) this.files = files;
        if(rightType) this.rightType = rightType;
        if(rightTypeDescription) this.rightTypeDescription = rightTypeDescription;
        if(riskLevel) this.riskLevel = riskLevel;
        if(riskLevelDescription) this.riskLevelDescription = riskLevelDescription;
        if(uid) this.uid = uid;
        if(useType) this.useType = useType;
        if(useTypeDescription) this.useTypeDescription = useTypeDescription;
    }
}

export type ClientDocumentsSettings = {
    address: string;
    city: string;
    fullName: string;
    googleDriveSignedDocumentsFolderId: string;
    jurisdiction: string;
    logoAlignment: 'left' | 'center' | 'right' | '';
    postCode: string;
    realId: string;
    representativeEmail: string;
    representativeName: string;
    representativePosition: string;
    representativeRealId: string;
    state: string;
    type: 'PF' | 'PJ';
};

export type _ClientSupplier = {
    address: string;
    createdAt: string;
    deleted: boolean;
    deletedAt: string;
    deletedBy: string;
    email: string;
    name: string;
    mobile: string;
    representative: string;
    representativeTaxpayerNumber: string;
    retrievedCNPJ: {
        addressBuildingNumber: string;
        addressCity: string;
        addressFirstLine: string;
        addressNeighbourhood: string;
        addressPostCode: string;
        addressState: string;
        addressStreet: string;
        addressStreetNumber: string;
        cnpj: string;
        companyName: string;
        fullAddress: string;
        legalRepresentativeFoundInCNPJ: boolean;
        legalRepresentativeNames: string[];
        partnerNames: string[];
    } | null;
    taxpayerNumber: string;
    type: string;
    uid?: string;
};

export class ClientSupplier implements _ClientSupplier {
    address = '';
    createdAt = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    email = '';
    name = '';
    mobile = '';
    representative = '';
    representativeTaxpayerNumber = '';
    retrievedCNPJ: _ClientSupplier['retrievedCNPJ'] = null;
    taxpayerNumber = '';
    type = '';
    uid = '';

    constructor({
        address,
        createdAt,
        deleted,
        deletedAt,
        deletedBy,
        email,
        name,
        mobile,
        representative,
        representativeTaxpayerNumber,
        retrievedCNPJ,
        taxpayerNumber,
        type,
        uid
    }: _ClientSupplier | DocumentData){
        if(address) this.address = address;
        this.createdAt = createdAt || new Date().toISOString();
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(email) this.email = email;
        if(name) this.name = name;
        if(mobile) this.mobile = mobile;
        if(representative) this.representative = representative;
        if(representativeTaxpayerNumber) this.representativeTaxpayerNumber = representativeTaxpayerNumber;
        if(retrievedCNPJ) this.retrievedCNPJ = retrievedCNPJ;
        if(taxpayerNumber) this.taxpayerNumber = taxpayerNumber;
        if(type) this.type = type;
        if(uid) this.uid = uid;
    }
}

export interface Comment {
    comment: string;
    createdAt: string;
    createdBy: string;
    readBy: { [userId: string]: string | boolean };
};

export interface Document {
    availableToClient: boolean;
    awaitingOperatorReview: boolean;
    bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: boolean;
    clientId: string;
    createdAt: string;
    createdBy: string;
    customFields: {
        [fieldId: string]: {
            value: string;
            valueAt: string;
            valueBy: string;
        }
    };
    deleted: boolean;
    deletedAt: string;
    deletedBy: string;
    files: { [fileId: string]: { firebaseStoragePath: string; format: string; name: string; uploadedAt: string; uploadedBy: string; url: string; } };
    flag: {
        comment: string;
        flaggedAt: string;
        flaggedBy: string;
        type: '' | 'client' | 'operator';
    };
    form: string;
    groupId: string;
    keywords: string[];
    lastComment: (Comment & { uid: string }) | null;
    lastVersion: (DocumentVersion & { uid: string }) | null;
    madeAvailableToClientAt: string;
    madeAvailableToClientBy: 'SOLIDA' | string;
    merged: boolean;
    name: string;
    operatorId: string;
    overrideBypassOperatorReviewReasons: string[] | null;
    projectId: string;
    shouldMakeAvailableToClientAt: string;
    shortId: string;
    talentForm: {
        form: { [key: string]: any; }[];
        type: string;
    } | null;
    templateId: string;
    uid: string;
    workspaceId: string;
};

export interface _DocumentsTemplate {
    createdAt: string;
    createdBy: string;
    description: string;
    disabled: boolean;
    disabledAt: string;
    disabledBy: string;
    name: string;
    updatedAt: string;
    updatedBy: string;
    uid: string;
    workspaceId: string
};

export type ESignatureSigners = {
    [signedId: string]: {
        email: string;
        emailRequestingSignatureSent?: boolean;
        emailRequestingSignatureSentAt?: string;
        mobile?: string;
        name: string;
        noEmail?: boolean;
        orderGroup?: number;
        realId: string;
        requireEmailToken?: boolean;
        solidaId?: string;
        status: string;
        statusAt: string;
    }
};

export interface _DocumentVersion {
    createdAt?: string;
    createdBy: string;
    documentId: string;
    eSignature?: {
        enabled: boolean;
        currentOrderGroup?: number;
        missingSignersEmails?: string[];
        platform?: string;
        platformDocumentId?: string;
        platformFileUrl?: string;
        sentAt?: string;
        sentBy?: string;
        shouldOrderSigners?: boolean;
        signers?: ESignatureSigners;
        status?: string;
        statusAt?: string;
        tabs?: { [key: string]: any }[];
    };
    fileFirebaseStoragePath?: string;
    fileFormat?: string;
    fileUrl?: string;
    googleDriveBackup?: {
        fileUrl: string;
        folderId: string;
    } | null;
    name?: string;
    workspaceId: string;
};

export class DocumentVersion implements _DocumentVersion {
    createdAt? = '';
    createdBy = '';
    documentId = '';
    eSignature?: _DocumentVersion['eSignature'] = { enabled: false };
    fileFirebaseStoragePath? = '';
    fileFormat? = '';
    fileUrl? = '';
    googleDriveBackup?: _DocumentVersion['googleDriveBackup'] = null;
    name? = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        documentId,
        fileFirebaseStoragePath,
        fileFormat,
        fileUrl,
        name,
        workspaceId
    }: DocumentVersion){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.documentId = documentId;
        this.fileFirebaseStoragePath = fileFirebaseStoragePath || '';
        this.fileFormat = fileFormat || '';
        this.fileUrl = fileUrl || '';
        this.name = name || '';
        if(workspaceId) this.workspaceId = workspaceId;
    }
};

export type _JudicialCaseProgress = {
    answered: boolean;
    answeredAt: string;
    answeredBy: string;
    caseId: string;
    comments: string;
    createdAt: string;
    createdBy: string;
    deadline: string;
    deleted: boolean;
    deletedAt: string;
    deletedBy: string;
    progress: string;
    progressOn: string;
    userIdToViewedAt: {
        [userId: string]: string
    };
    uid?: string;
    url: string;
    viewed: boolean;
    workspaceId: string;
};

export class JudicialCaseProgress implements _JudicialCaseProgress {
    answered = false;
    answeredAt = '';
    answeredBy = '';
    caseId = '';
    comments = '';
    createdAt = '';
    createdBy = '';
    deadline = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    progress = '';
    progressOn = '';
    uid = '';
    url = '';
    userIdToViewedAt = {};
    viewed = false;
    workspaceId = '';

    constructor({
        answered,
        answeredAt,
        answeredBy,
        caseId,
        comments,
        createdAt,
        createdBy,
        deadline,
        deleted,
        deletedAt,
        deletedBy,
        progress,
        progressOn,
        uid,
        url,
        userIdToViewedAt,
        viewed,
        workspaceId,
    }: _JudicialCaseProgress | DocumentData){
        if(answered) this.answered = answered;
        if(answeredAt) this.answeredAt = answeredAt;
        if(answeredBy) this.answeredBy = answeredBy;
        if(caseId) this.caseId = caseId;
        if(comments) this.comments = comments;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(deadline) this.deadline = deadline;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(progress) this.progress = progress;
        if(progressOn) this.progressOn = progressOn;
        if(uid) this.uid = uid;
        if(url) this.url = url;
        if(userIdToViewedAt) this.userIdToViewedAt = userIdToViewedAt;
        if(viewed) this.viewed = viewed;
        if(workspaceId) this.workspaceId = workspaceId;
    }
}

export type _Proposal = {
    accepted: string;
    actuallyReceivedValueInSharedContractWithRevenuesReceivedByPartner: number;
    agreementType: string;
    awaitingProposalReview: string;
    clientAcquiredBy: string;
    clientAcquisitionFee: string;
    clientId: string;
    commentsReadAt: { [userId: string]: string; };
    contractSupervisedBy: string;
    createdAt: string;
    createdBy: string;
    deleted: boolean;
    deletedAt: string;
    deletedBy: string;
    department: string;
    description: string;
    done: boolean; // done means all payments completed
    doneAt: string;
    doneBy: string;
    form: {
        caseAdditionalInformation?: string; // defesas
        caseDevelopments?: string; // defesas
        caseNumber?: string; // judicial
        caseObject?: string; // judicial (objetivo da representação) e defesas (objeto da decisão contra a qual a defesa será apresentada)
        caseOtherParty?: string; // judicial (ex adverso) e defesas (órgão contra o qual a defesa será apresentada)
        caseUnit?: string; // judicial (vara) e defesas (órgão)
        durationFee?: string;
        durationFeeFrequency?: string;
        howClientReachedUs?: string;
        otherSubjectMatter?: string;
        mainFee?: string;
        mainFeePaymentOn?: string;
        monthlyHours?: string;
        projectName?: string;
        secondSubjectMatter?: string;
        subjectMatter?: string;
        successFee?: string; // Tem êxito?
        term?: string;
    };
    hasClientAcquisitionFee: boolean;
    hoursBankAmount: number;
    hoursBankApproachingLimitNotificationSentAt: string;
    hoursUsedTotal: number;
    hoursUsedTrackingStrategy: string;
    isSharedContract: boolean;
    isSharedContractWithRevenuesReceivedByPartner: boolean;
    lastComment: {
        comment: string;
        createdAt: string;
        createdBy: string;
        mentionedUserIds: string[];
        plainText: string;
        uid: string;
    } | null;
    lastVersion: {
        createdAt?: string;
        createdBy: string;
        eSignature?: {
            enabled: boolean;
            sentAt?: string;
            sentBy?: string;
            status?: string;
            statusAt?: string;
            missingSignersEmails?: string[];
            platform?: string;
            platformDocumentId?: string;
            platformFileUrl?: string;
            shouldOrderSigners?: boolean;
            signers?: ESignatureSigners;
            tabs?: { [key: string]: any }[];
        };
        fileFirebaseStoragePath?: string;
        fileFormat?: string;
        fileUrl?: string;
        name?: string;
        uid: string;
    } | null;
    nextPayment: {
        grossValue: number;
        plannedTo: string;
        receivedOn: string;
        taxDocumentIssuedOn: string;
        taxDocumentNumber: string;
        taxWithheld: number;
        taxPercentage: number;
        uid: string;
    } | null;
    nonRetainableAmount: number;
    onGoingContractTerminatesOn: string;
    onGoingContractTerminationNotificationSentAt: string;
    payments: { [paymentId: string]: any; };
    paymentsPlannedToInYears: number[];
    paymentsPlannedToOrReceivedInYears: number[];
    paymentsReceivedInYears: number[];
    referenceNumber: number;
    shouldTrackHoursUsed: boolean;
    totalRevenue: number;
    totalValue: number;
    uid: string;
    workspaceId: string;
    yearRevenue: { [year: string]: number };
    yearValue: { [year: string]: number };
};

export type _ProposalVersion = {
    createdAt?: string;
    createdBy: string;
    deleted?: boolean;
    deletedAt?: string;
    deletedBy?: string;
    eSignature?: {
        enabled: boolean;
        sentAt?: string;
        sentBy?: string;
        status?: string;
        statusAt?: string;
        missingSignersEmails?: string[];
        platform?: string;
        platformDocumentId?: string;
        platformFileUrl?: string;
        shouldOrderSigners?: boolean;
        signers?: ESignatureSigners;
        tabs?: { [key: string]: any }[];
    };
    fileFirebaseStoragePath?: string;
    fileFormat?: string;
    fileUrl?: string;
    name?: string;
    type?: string;
};

export class ProposalVersion implements _ProposalVersion {
    createdAt? = '';
    createdBy = '';
    deleted? = false;
    deletedAt? = '';
    deletedBy? = '';
    eSignature?: _ProposalVersion['eSignature'] = {
        enabled: false,
    };
    fileFirebaseStoragePath? = '';
    fileFormat? = '';
    fileUrl? = '';
    name? = '';
    type? = '';
    uid? = '';

    constructor({
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        eSignature,
        fileFirebaseStoragePath,
        fileFormat,
        fileUrl,
        name,
        type,
        uid,
    }: ProposalVersion){
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(eSignature) this.eSignature = eSignature;
        if(fileFirebaseStoragePath) this.fileFirebaseStoragePath = fileFirebaseStoragePath;
        if(fileFormat) this.fileFormat = fileFormat;
        if(fileUrl) this.fileUrl = fileUrl;
        if(name) this.name = name;
        if(type) this.type = type;
        if(uid) this.uid = uid;
    }
}

export type _TimesheetRecord = {
    createdAt: string;
    createdBy: string;
    deleted?: boolean;
    deletedAt?: string;
    deletedBy?: string;
    description: string;
    minutes: number;
    minutesConsumedWithDocumentsCheckedViaTimesheet?: boolean;
    minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient?: boolean;
    projectId: string;
    startAt: string;
    taskId?: string;
    uid?: string;
    workspaceId: string;
}

export class TimesheetRecord implements _TimesheetRecord {
    createdAt = '';
    createdBy = '';
    deleted? = false;
    deletedAt? = '';
    deletedBy? = '';
    description = '';
    minutes = 0;
    minutesConsumedWithDocumentsCheckedViaTimesheet? = false;
    minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient? = false;
    projectId = '';
    startAt = '';
    taskId? = '';
    uid? = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        description,
        minutes,
        minutesConsumedWithDocumentsCheckedViaTimesheet,
        minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient,
        projectId,
        startAt,
        taskId,
        uid,
        workspaceId,
    }: _TimesheetRecord){
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(description) this.description = description;
        if(minutes) this.minutes = minutes;
        if(minutesConsumedWithDocumentsCheckedViaTimesheet) this.minutesConsumedWithDocumentsCheckedViaTimesheet = minutesConsumedWithDocumentsCheckedViaTimesheet;
        if(minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient) this.minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient = minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient;
        if(projectId) this.projectId = projectId;
        if(startAt) this.startAt = startAt;
        if(taskId) this.taskId = taskId;
        if(uid) this.uid = uid;
        if(workspaceId) this.workspaceId = workspaceId;
    }
}

export type _UserNotification = {
    createdAt?: string;
    description: string;
    read?: boolean;
    readAt?: string;
    title: string;
    type?: string;
};

export class UserNotification implements _UserNotification {
    createdAt = '';
    description = '';
    read = false;
    readAt = '';
    title = '';
    type = 'info';

    constructor({
        createdAt,
        description,
        title,
        type
    }: _UserNotification){
        this.createdAt = createdAt || new Date().toISOString();
        this.description = description || '';
        this.title = title || '';
        this.type = type || '';
    }

}

export type _UserWorkspace = {
    clients?: { [clientId: string]: any; };
    emails?: { // E-MAIL SETTINGS STORED ONLY UNDER USER WORKSPACE
        clearanceQuestionOrNoteDisabled: boolean;
        clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser: boolean;
        documentDeliveredByOperatorDisabled: boolean;
        documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser: boolean;
        documentFullySignedDisabled: boolean;
        documentSignedByThisUserEnabled: boolean;
    };
    favoriteClientsIds?: string[];
    favoriteProjectsIds?: string[];
    folders?: { [projectId: string]: any; };
    role: string;
};

export class UserWorkspace implements _UserWorkspace {
    clients: _UserWorkspace['clients'] = {};
    emails = {
        clearanceQuestionOrNoteDisabled: false,
        clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser: false,
        documentDeliveredByOperatorDisabled: false,
        documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser: false,
        documentFullySignedDisabled: false,
        documentSignedByThisUserEnabled: false,
    };
    favoriteClientsIds = [];
    favoriteProjectsIds = [];
    folders: _UserWorkspace['folders'] = {};
    role = '';

    constructor({
        clients,
        folders,
        role
    }: _UserWorkspace){
        this.clients = clients || {};
        this.folders = folders || {};
        this.role = role || '';
    }
}

export type _WorkspaceDocumentsTemplatesList = {
    createdAt?: string;
    createdBy: string;
    list: string[];
    name: string;
    // shortName?: string;
};

export class WorkspaceDocumentsTemplatesList implements _WorkspaceDocumentsTemplatesList {
    createdAt = '';
    createdBy = '';
    list: _WorkspaceDocumentsTemplatesList['list'] = [];
    name = '';
    // shortName = '';

    constructor({
        createdAt,
        createdBy,
        list,
        name,
        // shortName
    }: _WorkspaceDocumentsTemplatesList){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.list = list || [];
        this.name = name || '';
        // this.shortName = shortName || '';
    }
}

export type _WorkspaceUser = {
    disabled?: boolean;
    email: string;
    name: string;
    photoUrl?: string;
    role: string;
};

export class WorkspaceUser implements _WorkspaceUser {
    disabled = false;
    email = '';
    name = '';
    photoUrl = '';
    role = '';

    constructor({
        email,
        name,
        role
    }: _WorkspaceUser){
        this.email = email || '';
        this.name = name || '';
        this.role = role || '';
    }
}