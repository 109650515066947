import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import Grid from '@mui/material/Grid2';

import TaskBoard from '../../../../Tasks/TaskBoard';

const ActiveProjectsBoard = React.memo(({ activeProject, boardsLength, boxFilterFn, handleBoxClick }) => {
    return (
        <Grid style={{height: '100%', width: `${(100 / (boardsLength))}%`, minWidth: '100px'}}>
            <Droppable droppableId={activeProject.uid}>
                {(provided, snapshot) => {
                    let boardTitle, boardUser;
                    if(activeProject.uid === 'newActiveProjects'){
                        boardTitle = 'NOVOS PROJETOS';
                    } else {
                        boardUser = { img: activeProject.img, screenName: activeProject.name };
                    }
                    return (
                        <TaskBoard
                            boardId={activeProject.uid}
                            boxFilterFn={boxFilterFn}
                            innerRef={provided.innerRef}
                            isDraggingOver={snapshot.isDraggingOver}
                            onBoxClick={handleBoxClick}
                            nameKey="name"
                            tasks={activeProject.activeProjects}
                            title={boardTitle}
                            user={boardUser}

                            {...provided.droppableProps}
                        >   
                        </TaskBoard>
                    )
                }}
            </Droppable>
        </Grid>
    )
}, (prevProps, nextProps) => {
    if(nextProps.activeProject.activeProjects !== prevProps.activeProject.activeProjects){
        return false; // FALSE means rerender
    }
    return true;
})

export default ActiveProjectsBoard;