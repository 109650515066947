import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AttachmentIcon from '@mui/icons-material/Attachment';
import Tooltip from './Tooltip';

const DocumentFilesList = ({doc}) => {
    const [documentFiles, setDocumentFiles] = useState(null);

    useEffect(() => {
        if(doc){
            getDocumentFiles(doc);
        } else {
            setDocumentFiles(null);
        }
    }, [doc]);

    const getDocumentFiles = (doc) => {
        let currentFiles = null;
        for(let fileId in doc.files){
            if(doc.files[fileId]) currentFiles = [];
            currentFiles.push({...doc.files[fileId], uid: fileId});
        }
        setDocumentFiles(currentFiles);
    };

    return (
        documentFiles
        ?
        <Box mt={1}>
            {
                documentFiles.map((file, index) => (
                    <Tooltip key={file.uid} text={`Anexo ${index + 1} - ${file.name}`}>
                        <IconButton size="small" color="primary"
                            href={file.url} target="_blank"
                        ><AttachmentIcon /></IconButton>
                    </Tooltip>
                ))
            }
        </Box>
        :
        null
    );
};

export default DocumentFilesList;