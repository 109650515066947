import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import Form from '../../../Form';
import Select from '../../../../components/Select';
import ViewBox from '../../../../components/ViewBox';

import { useForm } from '../../../Form/context/FormContext';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { startDocumentsForm } from '../../../../utils/common';

const FormFile = () => {
    useSignals();
    const { formFile } = useAppStateCtx();
    if(formFile.value){
        return (
            <Box style={{textAlign: 'center'}}>
                Clique no ícone para acessar o documento:<br />
                <IconButton
                    href={formFile.value.link}
                    target="_blank"
                    size="large"><DescriptionIcon /></IconButton>
            </Box>
        );
    }
    return null;
}

const FormSelector = ({ formOptions, onChange }) => {
    useSignals();
    const { selectedDocumentsFormId } = useAppStateCtx();

    const handleChange = (e) => {
        onChange(e.target.value)
    }

    return (
        <Select
            label="Formulário"
            options={formOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
            value={selectedDocumentsFormId.value} onChange={handleChange}
        />
    );
}

function RequestDoc(){
    useSignals();
    const {
        loadingForm,
        formFile,
        formInitialValues,
        formSelectedDraft,
        projectDocumentSettings,
        projectDocumentsTemplateOptions,
        projectMisc,
        selectedDocumentForm,
        selectedDocumentsFormId,
        selectedFolder,
        workspaceDocumentsTemplates
    } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { resetForm, values } = useForm();
    const [formOptions, set_formOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setFirestoreListener('projectDocumentSettings');
        setFirestoreListener('workspaceTemplateLists');
    }, []);

    useEffect(() => {
        formFile.value = null;
    }, []);

    useEffect(() => {
        if(projectDocumentsTemplateOptions.value){
            set_formOptions(projectDocumentsTemplateOptions.value);
            setLoading(false);
        }
    }, [projectDocumentsTemplateOptions.value]);

    useEffect(() => {
        if(formSelectedDraft.value){
            handle_selectedFormIdChange(formSelectedDraft.value.templateId);
        }
    }, [formSelectedDraft.value]);

    const handle_selectedFormIdChange = async (selectedTemplateId) => {
        formFile.value = null;
        
        startDocumentsForm({
            initialValues: formInitialValues,
            loadingForm: loadingForm,
            projectDocumentsSettings: projectDocumentSettings,
            projectId: selectedFolder.value.uid,
            projectMisc: projectMisc,
            resetForm: resetForm,
            selectedDraft: formSelectedDraft,
            selectedForm: selectedDocumentForm,
            selectedFormId: selectedDocumentsFormId,
            templateId: selectedTemplateId,
            templates: workspaceDocumentsTemplates.value,
            values: values,
        });
    };

    const handleFormSubmit = () => {
        selectedDocumentsFormId.value = '';
        formSelectedDraft.value = null;
    }

    return (
        <ViewBox>
            <FormFile />
            {
                !loading &&
                <Box mb={2}>
                    {
                        formOptions.length === 0
                        ?
                        <Box style={{textAlign: 'center'}}>
                            <Typography variant="body1">Não há formulários cadastrados nesta pasta.</Typography>
                        </Box>
                        :
                        <Box>
                            <Container maxWidth="xs">
                                <FormSelector formOptions={formOptions} onChange={handle_selectedFormIdChange} />
                            </Container>
                        </Box>
                    }
                </Box>
            }
            <Collapse in={loading}><LoaderEllipsis /></Collapse>
            <Form
                onFormSave={handleFormSubmit}
                onFormSubmit={handleFormSubmit}
            />
        </ViewBox>
    );
}

export default RequestDoc;