import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';

import ImageIcon from '@mui/icons-material/Image';

import Tooltip from '../Tooltip';

import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const ChangeLogoButton = ({ selectedClientOrProject, callback }) => {
    useSignals();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        let currentImageUrl = ''
        if(selectedClientOrProject){
            currentImageUrl = selectedClientOrProject.logoUrl;
        }
        setImageUrl(currentImageUrl);
    }, [selectedClientOrProject]);

    const fileButtonId = 'logoFile';

    const triggerFileButton = () => {
        document.getElementById(fileButtonId).click();
    };

    const handleSelectedFileChange = async () => {
        const filesInput = document.getElementById(fileButtonId);
        if(filesInput){
            let files = filesInput.files;
            if(files.length !== 0){
                setLoading(true);
                const toastId = toast.loading(`Salvando a imagem...`);
                
                const res = await selectedClientOrProject.updateImage({ filesList: files })
                setLoading(false);
                if(res.error){
                    return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
                }
                
                filesInput.value = '';

                setImageUrl(res.result);
                if(callback) callback(res.result);

                toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
            }
        }
    };

    if(selectedClientOrProject){
        return (
            <Box mb={2}>
                <Grid container spacing={1} justifyContent="flex-end">
                    <Grid>
                        {
                            (selectedClientOrProject && imageUrl)
                            ?
                            <Tooltip text="Alterar logo">
                                <img
                                    src={imageUrl} alt={selectedClientOrProject.name}
                                    style={{
                                        maxWidth: 100,
                                        cursor: 'pointer'
                                    }}
                                    onClick={triggerFileButton}
                                />
                            </Tooltip>
                            :
                            <Button
                                disabled={loading}
                                variant="outlined" color="primary"
                                startIcon={<ImageIcon />}
                                onClick={triggerFileButton}
                            >Escolher logo</Button>
                        }
                        <input style={{display: 'none'}} id={fileButtonId} type="file" accept="image/*" onChange={handleSelectedFileChange} />
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
};

export default ChangeLogoButton;