import { collection, doc, getDoc, getDocs, orderBy, query, runTransaction, updateDoc, where } from 'firebase/firestore';

import { db } from '../firebase/config';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';

const uploadImage = async (folderPath: string, filesList: any) => {
    let newFileUrl = '';
    for(const file of filesList){
        const { contentType, fileBuffer } = await getFileContent(file);
        const { result } = await uploadFile(
            `${folderPath}/${file.name}`,
            fileBuffer,
            contentType
        );
        if(result){
            const { downloadUrl } = result;
            newFileUrl = downloadUrl;
        }
    }
    return newFileUrl;
};

export default class Client {
    createdAt = '';
    createdBy = '';
    disabled = false;
    disabledAt = '';
    disabledBy = '';
    logoFirebaseStoragePath = '';
    logoUrl = '';
    groupName = '';
    name = '';
    uid = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        disabled,
        disabledAt,
        disabledBy,
        logoFirebaseStoragePath,
        logoUrl,
        groupName,
        name,
        uid,
        workspaceId
    }: Client){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.disabled = disabled || false;
        this.disabledAt = disabledAt || '';
        this.disabledBy = disabledBy || '';
        this.logoFirebaseStoragePath = logoFirebaseStoragePath || '';
        this.logoUrl = logoUrl || '';
        if(groupName) this.groupName = groupName;
        this.name = name || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async createClient({
        address,
        city,
        fullName,
        jurisdiction,
        postCode,
        realId,
        representativeEmail,
        representativeName,
        representativePosition,
        representativeRealId,
        state,
        type
    }: {
        address: string;
        city: string;
        fullName: string;
        jurisdiction: string;
        logoAlignment: string;
        postCode: string;
        realId: string;
        representativeEmail: string;
        representativeName: string;
        representativePosition: string;
        representativeRealId: string;
        state: string;
        type: string;
    }){
        let result = null, error = null;

        const clientRef = doc(collection(db, `clients`));
        const workspaceClientsRef = doc(db, `workspaces/${this.workspaceId}/_more/clients`);
        const clientDocumentsSettingsRef = doc(db, `clients/${clientRef.id}/_more/documents`);
        const clientESignatureSettingsRef = doc(db, `clients/${clientRef.id}/_more/eSignature`);
        const clientUsersSettingsRef = doc(db, `clients/${clientRef.id}/_more/users`);

        const {uid: _, ...newClient} = this;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(clientRef, newClient);
                transaction.update(workspaceClientsRef, {
                    [`clients.${clientRef.id}`]: {
                        disabled: this.disabled,
                        location: state,
                        name: this.name
                    }
                });
                transaction.set(clientDocumentsSettingsRef, {
                    address,
                    city,
                    fullName,
                    googleDriveSignedDocumentsFolderId: '',
                    jurisdiction,
                    logoAlignment: '',
                    postCode,
                    realId,
                    representativeEmail,
                    representativeName,
                    representativePosition,
                    representativeRealId,
                    state,
                    type
                });
                transaction.set(clientESignatureSettingsRef, { signers: {} });
                transaction.set(clientUsersSettingsRef, {});
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async deleteSupplier({ activeUser, supplierId }: {
        activeUser: any;
        supplierId: string;
    }){
        let result = null, error = null;
        
        const clientSuppliersRef = doc(db, `clients/${this.uid}/suppliers/${supplierId}`);
        
        try {
            result = await updateDoc(clientSuppliersRef, {
                deleted: true,
                deletedAt: new Date().toISOString(),
                deletedBy: activeUser.uid
            });
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getElectronicSignatureSettings(){
        let result = null, error = null;
        
        const clientElectronicSignatureRef = doc(db, `clients/${this.uid}/_more/eSignature`);
        
        try {
            const snapshot = await getDoc(clientElectronicSignatureRef);
            const data = snapshot.data();
            result = data;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getSuppliers(){
        let result = null, error = null;
        
        const clientSuppliersRef = collection(db, `clients/${this.uid}/suppliers`);
        
        try {
            const snapshots = await getDocs(query(clientSuppliersRef, where('deleted', '==', false), orderBy('name', 'asc')));
            const dataArray: { [key: string]: any }[] = [];
            snapshots.forEach(snapshot => {
                const data = snapshot.data();
                dataArray.push({
                    ...data,
                    uid: snapshot.id,
                })
            });
            result = dataArray;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ documentsSettingsUpdates, updates, workspaceId }: {
        documentsSettingsUpdates: {[key: string]: any};
        updates: {[key: string]: any};
        workspaceId: string
    }){
        let result = null, error = null;

        const clientRef = doc(db, `clients/${this.uid}`);
        const workspaceClientsRef = doc(db, `workspaces/${workspaceId}/_more/clients`);
        const clientDocumentsSettingsRef = doc(db, `clients/${this.uid}/_more/documents`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clientRef, updates);
                const workspaceClientsUpdates: { [key: string]: any } = {
                    [`clients.${this.uid}.location`]: documentsSettingsUpdates.state
                };
                for(const updateKey in updates){
                    workspaceClientsUpdates[`clients.${this.uid}.${updateKey}`] = updates[updateKey];
                }
                transaction.update(workspaceClientsRef, workspaceClientsUpdates);
                transaction.update(clientDocumentsSettingsRef, documentsSettingsUpdates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateESignatureSettings({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;

        const clientESignatureSettingsRef = doc(db, `clients/${this.uid}/_more/eSignature`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clientESignatureSettingsRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateImage({ filesList }: {
        filesList: any
    }){
        let result = null, error = null;

        let newFileUrl = await uploadImage(
            `workspaces/${this.workspaceId}/clients/${this.uid}/images`,
            filesList || []
        );

        const clientRef = doc(db, `clients/${this.uid}`);
        const workspaceClientsRef = doc(db, `workspaces/${this.workspaceId}/_more/clients`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clientRef, {
                    logoUrl: newFileUrl
                });
                transaction.update(workspaceClientsRef, {
                    [`clients.${this.uid}.logoUrl`]: newFileUrl
                });
            });
            result = newFileUrl;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateGroupName({ updatedGroupName, workspaceId }: {
        updatedGroupName: string;
        workspaceId: string
    }){
        let result = null, error = null;

        const clientRef = doc(db, `clients/${this.uid}`);
        const workspaceClientsRef = doc(db, `workspaces/${workspaceId}/_more/clients`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clientRef, { groupName: updatedGroupName });
                transaction.update(workspaceClientsRef, {
                    [`clients.${this.uid}.groupName`]: updatedGroupName
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}