import { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import FavoriteProjects from '../../../../components/FavoriteProjects';
import Form from '../../../Form';
import Autocomplete from '../../../../components/Autocomplete';
import Select from '../../../../components/Select';
import ViewBox from '../../../../components/ViewBox';

import Project from '../../../../classes/Project';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import SavedFormsWindow from '../../../../components/SavedFormsWindow';
import { useForm } from '../../../Form/context/FormContext';
import { getProjectTemplates, startDocumentsForm } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const selectedTemplateId = signal('');

function Main(){
    useSignals();
    const {
        activeUser,
        formInitialValues,
        formSelectedDraft,
        loadingForm,
        projectDocumentSettings,
        projectMisc,
        selectedDocumentWithCompleteForm,
        selectedDocumentForm,
        selectedFolder,
        workspaceClients,
        workspaceProjects,
        workspaceDocumentsTemplates,
        workspaceTemplateLists
    } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { getUserGroups } = useAppCtxAPI();
    const { resetForm, values } = useForm();
    const [visibleTemplates, setVisibleTemplates] = useState([]);
    const [selectedClient, set_selectedClient] = useState(null);
    const [projectOptions, set_projectOptions] = useState([]);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [formOptions, set_formOptions] = useState([]);
    const [savedFormsWindowOpen, setSavedFormsWindowOpen] = useState(false);
    const [loadingProject, setLoadingProject] = useState(false);
    const [savedFormsWindowSelectedProject, setSavedFormsWindowSelectedProject] = useState(null);
    const shouldStartSelectedDocumentWithCompleteForm = useRef(true);

    useEffect(() => {
        setFirestoreListener('workspaceTemplateLists');
        setFirestoreListener('workspaceDocumentsTemplates');
    }, []);

    useEffect(() => {
        if(workspaceDocumentsTemplates.value){
            const currentTemplates = Object.entries(workspaceDocumentsTemplates.value).map(([templateId, template]) => ({...template, uid: templateId})).sort(sortByKey('name'));
            setVisibleTemplates(currentTemplates);
        }
    }, [workspaceDocumentsTemplates.value]);
    
    useEffect(() => {
        if(selectedDocumentWithCompleteForm.value && workspaceTemplateLists.value && shouldStartSelectedDocumentWithCompleteForm.current && visibleTemplates){
            shouldStartSelectedDocumentWithCompleteForm.current = false;
            handle_selectedClientChange({...workspaceClients.value[selectedDocumentWithCompleteForm.value.clientId], uid: selectedDocumentWithCompleteForm.value.clientId});
            handle_selectedProjectChange(selectedDocumentWithCompleteForm.value.projectId || 'none');
            const userForm = JSON.parse(selectedDocumentWithCompleteForm.value.form);
            formInitialValues.value = userForm;
            handle_selectedTemplateChange(selectedDocumentWithCompleteForm.value.templateId);
        }
    }, [selectedDocumentWithCompleteForm.value, workspaceTemplateLists.value, visibleTemplates]);

    const clearLocalFormFields = () => {
        selectedTemplateId.value = '';
    };

    const handle_selectedClientChange = (newValue) => {
        clearLocalFormFields();

        set_selectedClient(newValue);
        if(newValue){
            const clientProjects = Object.entries(workspaceProjects.value).filter(([_, project]) => project.clientId === newValue.uid);
            const newProjectOptions = clientProjects.map(([projectId, project]) => ({value: projectId, label: project.name}));
            set_projectOptions(newProjectOptions);
        } else {
            set_projectOptions([]);
        }
        setSelectedProjectId('none');
    };
    
    const handle_selectedProjectChange = async (newSelectedProjectId) => {
        clearLocalFormFields();

        if(newSelectedProjectId !== 'none'){
            const currentSelectedProject = new Project({...workspaceProjects.value[newSelectedProjectId], uid: newSelectedProjectId});
    
            setSelectedProjectId(newSelectedProjectId);
            selectedFolder.value = currentSelectedProject;
            setLoadingProject(true);
    
            const groupsSettingsRes = await currentSelectedProject.getGroups();
            if(groupsSettingsRes.error){
                return console.log(groupsSettingsRes.error);
            }
            const groupsSettings = groupsSettingsRes.result;
            getProjectGroups(groupsSettings);
            
            const documentsSettingsRes = await currentSelectedProject.getDocumentsSettings();
            if(documentsSettingsRes.error){
                return console.log(documentsSettingsRes.error);
            }
            const projectTemplates = getProjectTemplates(documentsSettingsRes.result.templates, workspaceTemplateLists.value)
            .map(templateId => {
                const foundTemplate = visibleTemplates.find(t => t.uid === templateId);
                if(!foundTemplate) return null;
                return {value: foundTemplate.uid, label: foundTemplate.name};
            });
            set_formOptions(projectTemplates);
            setLoadingProject(false);
        }
    };
    
    const getProjectGroups = (groupSettings) => {
        let groups = {};
        if(groupSettings.enabled){
            groups = groupSettings.groups;
        }
        getUserGroups(activeUser.value, groups, true);
    };

    const handle_selectedTemplateChange = async (clickedTemplateId) => {
        startDocumentsForm({
            initialValues: formInitialValues,
            loadingForm: loadingForm,
            projectDocumentsSettings: projectDocumentSettings,
            projectId: selectedFolder.value.uid,
            projectMisc: projectMisc,
            resetForm: resetForm,
            selectedDraft: formSelectedDraft,
            selectedForm: selectedDocumentForm,
            selectedFormId: selectedTemplateId,
            templateId: clickedTemplateId,
            templates: workspaceDocumentsTemplates.value,
            values: values,
        });
    }
    const handleFormSubmit = () => {
        selectedTemplateId.value = '';
    };
    const setFavoriteFolder = (type, clientOrProjectId) => {
        let favoriteClient, favoriteProject;
        if(type === 'clients'){
            favoriteClient = {...workspaceClients.value[clientOrProjectId], uid: clientOrProjectId};
            favoriteProject = 'none';
        } else {
            favoriteProject = clientOrProjectId;
            const clientId = workspaceProjects.value[clientOrProjectId].clientId;
            favoriteClient = {...workspaceClients.value[clientId], uid: clientId};
        }
        handle_selectedClientChange(favoriteClient);
        handle_selectedProjectChange(favoriteProject);
    };

    const handleSavedFormsButtonClick = () => {
        const currentSelectedProject = new Project({...workspaceProjects.value[selectedProjectId], uid: selectedProjectId});
        setSavedFormsWindowSelectedProject(currentSelectedProject);
        setSavedFormsWindowOpen(true);
    }

    return (
        <ViewBox>
            <Box mb={2}>
                <FavoriteProjects handleFavoriteClick={setFavoriteFolder} />
                <Grid container spacing={1} alignItems="center">
                    {
                        workspaceClients.value &&
                        <Grid item xs={12} sm={4} md={3}>
                            <Autocomplete
                                label="Cliente" options={Object.entries(workspaceClients.value).map(([clientId, client]) => ({...client, uid: clientId})).sort(sortByKey('name'))} getOptionLabel={(option) => option.name}
                                value={selectedClient} onChange={(_, value) => handle_selectedClientChange(value)}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={4} md={3}>
                        <Slide in={!!selectedClient} direction="up">
                            <Box>
                                <Select
                                    label="Projeto"
                                    options={[{value: 'none', label: 'Nenhum'}, ...projectOptions]}
                                    value={selectedProjectId} onChange={(e) => handle_selectedProjectChange(e.target.value)}
                                />
                            </Box>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Slide in={!!selectedProjectId} direction="up">
                            <Box>
                                <Grid container spacing={1} alignItems="flex-end">
                                    {
                                        loadingProject &&
                                        <Grid item>
                                            <CircularProgress size={20} />
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        {
                                            (selectedProjectId !== 'none' && formOptions && formOptions.length !== 0) &&
                                            <Select
                                                disabled={loadingProject}
                                                label="Formulário" options={formOptions.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                                                value={selectedTemplateId.value} onChange={(e) => handle_selectedTemplateChange(e.target.value)}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Slide>
                    </Grid>
                    <Grid item xs>
                        <Fade in={!!selectedProjectId}>
                            <Box style={{textAlign: 'center'}}>
                                {
                                    selectedProjectId !== 'none' &&
                                    <Button
                                        onClick={handleSavedFormsButtonClick}
                                    >Formulários salvos</Button>
                                }
                            </Box>
                        </Fade>
                    </Grid>
                </Grid>
            </Box>
            
            <Form
                activeUserIsOperator
                onFormSave={handleFormSubmit}
                onFormSubmit={handleFormSubmit}
            />
            
            <SavedFormsWindow
                onSelectedDocumentsTemplateIdChange={handle_selectedTemplateChange}
                savedFormsWindowOpen={savedFormsWindowOpen} setSavedFormsWindowOpen={setSavedFormsWindowOpen}
                savedFormsWindowSelectedProject={savedFormsWindowSelectedProject}
            />
        </ViewBox>
    );
}

export default Main;