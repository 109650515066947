import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { Box, Button, CircularProgress, Collapse, Container, Dialog, DialogActions, DialogContent, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';

import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import callFunction from '../../../../firebase/functions/callFunction';
import { validateEmail } from '../../../../utils/common';
import { CNPJ_REGEXP } from '../../../../utils/constants';

const defaultClientState = {
    type: 'PJ',
    corporationId: '',
    fullAddress: '',
    fullName: '',
    individualId: '',
    observers: '',
    observersError: '',
    proposalType: 'proposal',
    representativeFullName: '',
    representativeIndividualId: '',
    signerEmail: '',
};

const SentProposalAgreementInformation = (props) => {
    const {
        // proposalVersionId,
        sentProposalAgreementInformationViewOpen, setSentProposalAgreementInformationViewOpen, includesContract, shouldStartAcceptedProposalESignatureVersionId } = props;
    const [clientState, setClientState] = useState({...defaultClientState});
    const [checkingCorporationId, setCheckingCorporationId] = useState(false);
    const [showFieldsAfterCorporationId, setShowFieldsAfterCorporationId] = useState(false);
    const [acceptProposalStatus, setAcceptProposalStatus] = useState('');
    
    useEffect(() => {
        if(sentProposalAgreementInformationViewOpen){
            if(shouldStartAcceptedProposalESignatureVersionId){
                setAcceptProposalStatus('success');
            } else {
                setAcceptProposalStatus('');
                if(includesContract){
                    setClientState(() => ({
                        ...defaultClientState,
                        proposalType: 'contract'
                    }));
                } else {
                    setClientState(() => ({
                        ...defaultClientState,
                        type: 'PF',
                        proposalType: 'proposal'
                    }));
                    setShowFieldsAfterCorporationId(true);
                }
            }
        }
    }, [sentProposalAgreementInformationViewOpen, includesContract, shouldStartAcceptedProposalESignatureVersionId]);

    const handleAcceptProposalConfirmClick = async () => {
        let allValid = true;

        if(!validateEmail(clientState.signerEmail.trim())){
            allValid = false;
        }

        let observerEmails = [];

        if(clientState.observers){
            observerEmails = clientState.observers.split(',');
            observerEmails.forEach(email => {
                if(!validateEmail(email.trim())){
                    // toast(`O endereço de e-mail ${email} não é válido.`);
                    allValid = false;
                    return;
                }
            });
        }

        if(allValid){
            setAcceptProposalStatus('accepting');
            try {
                //TODO `/propostas/${proposalVersionId}/accept`
                // {
                //     proposalClient: {
                //         ...clientState,
                //         observers: observerEmails
                //     }
                // }
                return setAcceptProposalStatus('success');
            } catch (error) {
                return setAcceptProposalStatus(error);
            }
        }
        setAcceptProposalStatus('E-mail inválido.');
    };

    const capitalizeFirstLetter = (phrase) => {
        const phraseWords = phrase.split(' ').map(phraseWord => {
            phraseWord = phraseWord.toLowerCase();
            if(/^(das?|de|dos?)$/.test(phraseWord)){
                return phraseWord;
            }
            return phraseWord.charAt(0).toUpperCase() + phraseWord.slice(1);
        });
        const newValue = phraseWords.join(' ');
        return newValue;
    }

    const getCNPJ = async (newValue) => {
        if(newValue){
            if(CNPJ_REGEXP.test(newValue)){
                setCheckingCorporationId(true);
                
                const res = await callFunction('getCNPJ', { cnpj: newValue, simples: true })
                if(res.error){
                    // error retrieving CNPJ, so reset CNPJ data
                    return;
                }
                const data = res.result;
                if(data){
                    const addressStreet = capitalizeFirstLetter(data.address.street);
                    const addressStreetNumber = capitalizeFirstLetter(data.address.number);
                    const addressBuildingNumber = data.address.details ? capitalizeFirstLetter(data.address.details) : '';
                    const addressNeighbourhood = capitalizeFirstLetter(data.address.district);
                    const addressCity = capitalizeFirstLetter(data.address.city);
                    const addressState = data.address.state;
                    const addressPostCode = capitalizeFirstLetter(data.address.zip);
                    const addressFirstLine = `${addressStreet}, ${addressStreetNumber}${addressBuildingNumber ? `, ${addressBuildingNumber}` : ''}, ${addressNeighbourhood}`;
                    const fullAddress = `${addressFirstLine} - ${addressCity}/${addressState}, CEP ${addressPostCode}`;
    
                    const currentPartners = data.company.members;
                    const mappedLegalRepresentatives = currentPartners.filter(partner => partner.role.id === 49).map(partner => capitalizeFirstLetter(partner.person.name));
    
                    setClientState(prevState => ({
                        ...prevState,
                        fullAddress,
                        fullName: data.company.name,
                        representativeFullName: mappedLegalRepresentatives[0]
                    }));
                    setCheckingCorporationId(false);
                    setShowFieldsAfterCorporationId(true);

                } else {
                    // CNPJ not found, so reset CNPJ data
                }
            }
        }
    };

    const handleClientTypeChange = (e) => {
        setClientState(() => ({...defaultClientState, type: e.target.value }));
        setShowFieldsAfterCorporationId(e.target.value === 'PF');
    };

    const handleClientCorporationIdChange = (e) => {
        const newValue = e.target.value;
        setClientState(prevState => ({...prevState, corporationId: newValue}));
        getCNPJ(newValue);
    };
    
    return (
        <Dialog
            open={sentProposalAgreementInformationViewOpen} onClose={() => setSentProposalAgreementInformationViewOpen(false)}
            disableEscapeKeyDown={['accepting', 'success'].includes(acceptProposalStatus)}
            fullScreen
        >
            <DialogContent>
                
                <Collapse in={acceptProposalStatus === 'accepting'}>
                    <LoaderEllipsis />
                </Collapse>
                <Collapse in={acceptProposalStatus === 'success'}>
                    <Container maxWidth="xs">
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid>
                                <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                            </Grid>
                            <Grid>
                                <Typography variant="body1" style={{color: 'green'}}>Obrigado! {clientState.proposalType === 'proposal' ? 'A proposta será disparada' : 'O contrato de prestação de serviços jurídicos será disparado'} para assinatura em breve.</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Collapse>
                <Collapse in={!['', 'accepting', 'success'].includes(acceptProposalStatus)}>
                    <Container maxWidth="xs">
                        <Grid container spacing={1} alignItems="center" justifyContent="center">
                            <Grid>
                                <CancelIcon htmlColor="red" fontSize={'small'} />
                            </Grid>
                            <Grid>
                                <Typography variant="body1" style={{color: 'red'}}>{acceptProposalStatus}</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Collapse>
                <Collapse in={!['accepting', 'success'].includes(acceptProposalStatus)}>
                    <Grid container spacing={2}>
                        <Grid size={{ xs: 12, md: 3, lg: 4 }}>
                            <Typography variant="h2">Dados para assinatura eletrônica</Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                            <Box mb={1}>
                                <Typography variant="h3">Apresente, por gentileza, os seguintes dados. {clientState.proposalType === 'proposal' ? 'A proposta será enviada' : 'O contrato de prestação de serviços jurídicos será enviado'} para assinatura em seguida.</Typography>
                            </Box>
                            {
                                clientState.proposalType !== 'proposal' &&
                                <Box mb={1}>
                                    <RadioGroup
                                        // aria-label={name} name={name}
                                        value={clientState.type}
                                        onChange={handleClientTypeChange}
                                    >
                                        {
                                            [
                                                {value: 'PF', label: 'O contrato será assinado por uma pessoa física'},
                                                {value: 'PJ', label: 'O contrato será assinado por uma pessoa jurídica'}
                                            ].map((option) => (
                                                <FormControlLabel
                                                    key={option.value}
                                                    value={option.value}
                                                    control={<Radio />}
                                                    label={option.label}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </Box>
                            }
                            <Box mb={1}>
                                {
                                    clientState.type === 'PF'
                                    ?
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={clientState.individualId}
                                        onChange={(e) => setClientState(prevState => ({...prevState, individualId: e.target.value}))}
                                        maskChar=""
                                    >
                                        {() => (
                                            <TextField variant="standard" fullWidth label={`CPF`} placeholder="Sua resposta" />
                                        )}
                                    </InputMask>
                                    :
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid size="grow">
                                            <InputMask
                                                disabled={checkingCorporationId}
                                                mask="99.999.999/9999-99"
                                                value={clientState.corporationId}
                                                onChange={handleClientCorporationIdChange}
                                                maskChar=""
                                            >
                                                {() => (
                                                    <TextField variant="standard" fullWidth label={`CNPJ`} placeholder="Sua resposta" />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        {
                                            checkingCorporationId &&
                                            <Grid>
                                                <CircularProgress size={20} />
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </Box>
                            <Collapse in={showFieldsAfterCorporationId}>
                                <Box mb={1}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        label={clientState.type === 'PF' ? `Nome completo` : `Razão social`}
                                        placeholder="Sua resposta"
                                        value={clientState.fullName}
                                        onChange={(e) => setClientState(prevState => ({...prevState, fullName: e.target.value}))}
                                    />
                                </Box>
                                {
                                    clientState.proposalType !== 'proposal' &&
                                    <Box mb={1}>
                                        <TextField
                                            variant="standard"
                                            fullWidth
                                            label={`Endereço completo`}
                                            placeholder="Sua resposta"
                                            value={clientState.fullAddress}
                                            onChange={(e) => setClientState(prevState => ({...prevState, fullAddress: e.target.value}))}
                                        />
                                    </Box>
                                }
                                {
                                    clientState.type === 'PJ' &&
                                    <>
                                        <Box mb={1}>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                label={`Nome completo do(a) representante legal`}
                                                placeholder="Sua resposta"
                                                value={clientState.representativeFullName}
                                                onChange={(e) => setClientState(prevState => ({...prevState, representativeFullName: e.target.value}))}
                                            />
                                        </Box>
                                        <Box mb={1}>
                                            <InputMask
                                                mask="999.999.999-99"
                                                value={clientState.representativeIndividualId}
                                                onChange={(e) => setClientState(prevState => ({...prevState, representativeIndividualId: e.target.value}))}
                                                maskChar=""
                                            >
                                                {() => (
                                                    <TextField variant="standard" fullWidth label={`CPF do(a) representante legal`} placeholder="Sua resposta" />
                                                )}
                                            </InputMask>
                                        </Box>
                                    </>
                                }
                                <Box mb={1}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        label="E-mail do(a) signatário(a)"
                                        placeholder="Sua resposta"
                                        value={clientState.signerEmail}
                                        onChange={(e) => setClientState(prevState => ({...prevState, signerEmail: e.target.value}))}
                                    />
                                </Box>
                                {/* <Box mb={1}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        label="Outros e-mails (separados por vírgula) para acompanhar o processo de assinatura"
                                        placeholder="Sua resposta"
                                        value={clientState.observers}
                                        onChange={(e) => setClientState(prevState => ({...prevState, observers: e.target.value}))}
                                    />
                                    {
                                        clientState.observersError &&
                                        <FormHelperText error>{clientState.observersError}</FormHelperText>
                                    }
                                </Box> */}
                            </Collapse>
                        </Grid>
                    </Grid>
                </Collapse>

            </DialogContent>
            {
                acceptProposalStatus !== 'success' &&
                <DialogActions>

                    <Button variant="text" startIcon={<ArrowBackIcon />} onClick={() => setSentProposalAgreementInformationViewOpen(false)}>Voltar</Button>
                    <Button
                        disabled={['accepting', 'success'].includes(acceptProposalStatus)}
                        variant="contained"
                        color="primary"
                        endIcon={<CheckIcon />}
                        onClick={handleAcceptProposalConfirmClick}
                    >Confirmar</Button>

                </DialogActions>
            }
        </Dialog>
    );
};

export default SentProposalAgreementInformation;