import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

import AlertDialog from './AlertDialog';
import LoaderEllipsis from './LoaderEllipsis';
import DocumentFilesList from './DocumentFilesList';
import PopOver from './PopOver';
import Tooltip from './Tooltip';
import AddDocumentVersionPopOver from './AddDocumentVersionPopOver';

import useGetDocumentComments from '../hooks/useGetDocumentComments';
import useGetDocumentVersions from '../hooks/useGetDocumentVersions';
import { useAppStateCtx } from '../context/AppState';
import { useDocumentsAPI, useDocumentsCtxHistoryWindow } from '../context/DocumentsContext';
import { useAppCtxAPI } from '../context/SystemContext';
import { getUserNameById } from '../utils/common';
import { sortByKey } from '../utils/filters';

const classes = {
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const Em = styled('em')(unstable_styleFunctionSx);
const Span = styled('span')(unstable_styleFunctionSx);

const selectedVersion = signal(null);

const Versions = ({
    setDocumentLastVersion,
    setLoading,
    shouldShowSuccessToast
}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    const { showCommentView } = useDocumentsAPI();
    const { historyWindowSelectedDocument: selectedDocument } = useDocumentsCtxHistoryWindow();
    const [historyItems, setHistoryItems] = useState(null);

    const documentComments = useGetDocumentComments(selectedDocument?.uid);
    const documentVersions = useGetDocumentVersions(selectedDocument?.uid);
    useEffect(() => {
        if(!documentComments.loading && documentComments.data && !documentVersions.loading && documentVersions.data){
            setDocumentLastVersion(documentVersions.lastVersion);
            const currentHistoryItems = [
                ...documentVersions.data,
                ...documentComments.data.map(comment => ({...comment, type: 'comment'}))
            ]
            .sort(sortByKey('createdAt'));
            setHistoryItems(currentHistoryItems);
            if(shouldShowSuccessToast.current){
                setLoading(false);
                toast.update(shouldShowSuccessToast.current.toastId, {
                    autoClose: 3000, 
                    isLoading: false,
                    position: 'top-center',
                    render: shouldShowSuccessToast.current.message,
                    type: 'success'
                });
                shouldShowSuccessToast.current = '';
            }
        }
    }, [documentComments, documentVersions]);

    const getNoHtmlComment = (comment) => {
        let noHtmlComment = comment.replace(/<[^>]+>/g, '').replace(/\n/g, ' ');
        if(noHtmlComment.length > 200) noHtmlComment = `${noHtmlComment.substring(0, 200)}...`;
        return noHtmlComment;
    };

    const handleReadComment = (comment) => {
        showCommentView(comment);
    };

    if(historyItems){
        return historyItems.map((historyItem, historyItemIndex) => (
            <Version
                key={historyItem.uid}
                content={
                    <Box>
                        {
                            historyItem.type === 'comment'
                            ?
                            <>
                                {
                                    historyItem.auto
                                    ? <Typography variant="body2">{historyItem.createdBy ? getUserNameById(workspaceUsers.value, historyItem.createdBy) : 'Alguém'}{historyItem.auto ? ` ${historyItem.auto}` : ''}{historyItem.comment ? <> e comentou: “{historyItem.comment.length > 200 ? <Em sx={classes.link} onClick={() => handleReadComment(historyItem)}>{getNoHtmlComment(historyItem.comment)}</Em> : <Em>{historyItem.comment}</Em>}”</> : ''}</Typography>
                                    : <Typography variant="body2">{historyItem.comment ? <>{historyItem.comment.length > 200 ? <Span sx={classes.link} onClick={() => handleReadComment(historyItem)}>{getNoHtmlComment(historyItem.comment)}</Span> : <Span>{historyItem.comment}</Span>}</> : ''}</Typography>
                                }
                            </>
                            :
                            <Link
                                href={historyItem.fileUrl}
                                target="_blank"
                                underline="hover"
                                variant="body2"
                            >
                                {historyItem.name || <em>Primeira versão</em>}
                            </Link>
                        }
                    </Box>
                }
                documentVersion={historyItem}
                icon={
                    historyItem.type === 'comment'
                    ? <InfoIcon fontSize="small" color="info" />
                    :
                        historyItem.fileFormat === 'pdf'
                        ? <InsertDriveFileIcon fontSize="small" color="secondary" />
                        : <DescriptionIcon fontSize="small" color="primary" />
                }
                itemType={historyItem.type}
                last={historyItems.length === historyItemIndex + 1}
                oppositeContent={
                    historyItem.type === 'comment'
                    ?
                    <VersionSentAtBy createdAt={historyItem.createdAt} />
                    :
                    <VersionSentAtBy
                        createdAt={historyItem.createdAt}
                        createdBy={historyItem.createdBy}
                    />
                }
            />
        ));
    }
    return (
        <LoaderEllipsis />
    );
};

const deleteDocumentVersionDialogOpen = signal(false);
const deleteDocumentVersionWithESignatureDialogOpen = signal(false);

const Version = ({
    content,
    documentVersion,
    icon,
    itemType,
    last,
    oppositeContent
}) => {
    useSignals();

    const handleDeleteVersionButtonClick = async () => {
        selectedVersion.value = documentVersion;
        
        if(!documentVersion.eSignature.enabled){
            deleteDocumentVersionDialogOpen.value = true;
        } else if(documentVersion.eSignature.status !== 'signed') {
            deleteDocumentVersionWithESignatureDialogOpen.value = true;
        }

    };
    
    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ pt: 0 }} variant="body2">
                {oppositeContent}
            </TimelineOppositeContent>
            <TimelineSeparator>
                {
                    icon
                    ? icon
                    : <TimelineDot color="grey" />
                }
                {
                    !last &&
                    <TimelineConnector />
                }
            </TimelineSeparator>
            <TimelineContent sx={{ pt: 0 }}>
                <Grid container spacing={1} alignItems="flex-start">
                    <Grid size="grow">
                        {content}
                    </Grid>
                    {
                        (itemType !== 'comment' && documentVersion && documentVersion.eSignature.status !== 'signed') &&
                        <Grid>
                            {
                                (selectedVersion.value?.uid === documentVersion.uid && selectedVersion.value.deleting)
                                ? <CircularProgress size={20} />
                                :
                                <Tooltip text="Excluir documento">
                                    <IconButton size="small" edge="end" onClick={handleDeleteVersionButtonClick}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    }
                </Grid>
            </TimelineContent>
        </TimelineItem>
    )
};

const VersionSentAtBy = ({createdAt, createdBy}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    return (
        <>
            {
                createdAt &&
                <Typography variant="body2"><em>{moment(createdAt).format('L LT')}</em></Typography>
            }
            {
                createdBy &&
                <Typography variant="body2"><em>{getUserNameById(workspaceUsers.value, createdBy)}</em></Typography>
            }
        </>
    )
};

const DeleteDocumentVersionDialog = ({ onDeleteVersionConfirm }) => {
    useSignals();

    const handleClose = () => {
        deleteDocumentVersionDialogOpen.value = false;
    };

    const handleConfirm = () => {
        handleClose();
        onDeleteVersionConfirm(false);
    };

    return (
        <AlertDialog
            open={deleteDocumentVersionDialogOpen.value} onClose={handleClose}
            text={`Tem certeza que quer excluir esse documento? Essa ação é irreversível.`}
            okButtonOnClick={handleConfirm}
            cancelButtonOnClick={handleClose}
        />
    );
};

const DeleteDocumentVersionWithESignatureDialog = ({ onDeleteVersionConfirm }) => {
    useSignals();

    const handleClose = () => {
        deleteDocumentVersionWithESignatureDialogOpen.value = false;
    };

    const handleConfirm = () => {
        handleClose();
        onDeleteVersionConfirm(true);
    };

    return (
        <AlertDialog
            open={deleteDocumentVersionWithESignatureDialogOpen.value} onClose={handleClose}
            text={`Tem certeza que quer excluir esse documento? Isso excluirá também o processo de assinatura eletrônica em andamento. Essa ação é irreversível.`}
            okButtonOnClick={handleConfirm}
            cancelButtonOnClick={handleClose}
        />
    );
};

const addDocumentVersionWindowAnchorPosition = signal({});
const addDocumentVersionWindowOpen = signal(false);

const HistoryPopOver = () => {
    useSignals();
    const { setLoading } = useAppCtxAPI();
    const { setHistoryWindowOpen, setHistoryWindowSelectedDocument, showDocumentFormView } = useDocumentsAPI();
    const {
        documentHistoryViewActiveUserIsOperator: activeUserIsOperator,
        historyWindowAnchorPosition, historyWindowOpen, historyWindowSelectedDocument: selectedDocument
    } = useDocumentsCtxHistoryWindow();
    const [selectedDocumentFiles, setSelectedDocumentFiles] = useState(null);
    const [documentLastVersion, setDocumentLastVersion] = useState(null);
    const shouldShowSuccessToast = useRef('');

    useEffect(() => {
        if(selectedDocument){
            setSelectedDocumentFiles(selectedDocument.files);
        }
    }, [historyWindowOpen, selectedDocument]);

    const handle_docFormViewOpenButtonClick = () => {
        showDocumentFormView(selectedDocument);
        handleClose();
    };

    const handleDeleteVersionAction = async (shouldDeleteESignatureDocument = false) => {
        selectedVersion.value.deleting = true;
        const toastId = toast.loading('Excluindo...', { position: 'top-center' });

        let errorMessage = '';

        const DEFAULT_ERROR_MESSAGE = 'Ocorreu um erro na operação de exclusão. Tente novamente mais tarde.';
        const versionId = selectedVersion.value.uid;
        const deleteVersionRes = await selectedDocument.deleteVersion(documentLastVersion, versionId, shouldDeleteESignatureDocument);
        if(deleteVersionRes.error || !deleteVersionRes.result) errorMessage = deleteVersionRes.error.message || DEFAULT_ERROR_MESSAGE;

        if(errorMessage){
            return toast.update(toastId, { autoClose: 5000, render: errorMessage, type: 'error', isLoading: false })
        }

        shouldShowSuccessToast.current = {
            message: shouldDeleteESignatureDocument ? `O documento enviado para assinatura foi excluído.` : `A versão "${selectedVersion.value.name}" foi excluída.`,
            toastId,
        };
        selectedVersion.value.deleting = false;
    };

    const handle_uploadExecutedAgreementClick = (event) => {
        addDocumentVersionWindowAnchorPosition.value = {
            left: event.clientX - 2,
            top: event.clientY - 4,
        };
        addDocumentVersionWindowOpen.value = true;
    };

    const handleClose = () => {
        setHistoryWindowOpen(false);
    };

    return (
        <>
            <PopOver open={historyWindowOpen} onClose={handleClose} anchorPosition={historyWindowAnchorPosition}
                transformOrigin={{vertical: 'center', horizontal: 'left'}}
                firstBoxSx={{ maxHeight: '75%' }}
            >
                <Box>
                    <Typography variant="h6">{selectedDocument?.name}</Typography>
                    <Timeline>
                        {
                            selectedDocument?.form !== '' &&
                            <Version
                                content={
                                    <>
                                        <Typography variant="body2"><Span sx={classes.link} onClick={() => handle_docFormViewOpenButtonClick(selectedDocument)}>Formulário</Span></Typography>
                                        {
                                            selectedDocumentFiles &&
                                            <DocumentFilesList doc={selectedDocument} />
                                        }
                                    </>
                                }
                                icon={<QuestionAnswerIcon fontSize="small" htmlColor="grey" />}
                                oppositeContent={<VersionSentAtBy createdAt={selectedDocument?.createdAt} createdBy={selectedDocument?.createdBy} />}
                            />
                        }
                        {
                            (activeUserIsOperator || (selectedDocument?.availableToClient && !selectedDocument?.deleted)) &&
                            <Versions
                                setDocumentLastVersion={setDocumentLastVersion}
                                setLoading={setLoading}
                                shouldShowSuccessToast={shouldShowSuccessToast}
                            />
                        }
                    </Timeline>
                </Box>
                {
                    (activeUserIsOperator || (selectedDocument?.availableToClient && !selectedDocument?.deleted)) &&
                    <Grid container justifyContent="center">
                        <Grid>
                            <Button
                                onClick={handle_uploadExecutedAgreementClick}
                                startIcon={<NoteAddIcon />}
                            >Adicionar documento</Button>
                        </Grid>
                    </Grid>
                }

                <AddDocumentVersionPopOver
                    addDocumentVersionWindowAnchorPosition={addDocumentVersionWindowAnchorPosition}
                    addDocumentVersionWindowOpen={addDocumentVersionWindowOpen}
                    selectedDocument={selectedDocument} setSelectedDocument={setHistoryWindowSelectedDocument}
                    shouldShowSuccessToast={shouldShowSuccessToast}
                />
            </PopOver>
            <DeleteDocumentVersionDialog onDeleteVersionConfirm={handleDeleteVersionAction} />
            <DeleteDocumentVersionWithESignatureDialog onDeleteVersionConfirm={handleDeleteVersionAction} />
        </>
    );
};

export default HistoryPopOver;