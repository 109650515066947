import { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { toast as toastify } from 'react-toastify';
import {
    // useLocation,
    useNavigate
} from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx, useAppStateCtxUtils } from './AppState';

import { scrollToTop } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';
import AppDialog from '../components/AppDialog';
import FloatingAlert from '../components/FloatingAlert';
import LoadingSystemScreen from '../components/LoadingSystemScreen';
import NewVersionAvailable from '../components/NewVersionAvailable';
import Toast from '../components/Toast';
import { corporationId as documentsTemplatesCorporationId } from '../documents/templates/settings/documents-templates-corporation-id';
import { currency as documentsTemplatesCurrency } from '../documents/templates/settings/documents-templates-currency';
import { date as documentsTemplatesDate } from '../documents/templates/settings/documents-templates-date';
import { file as documentsTemplatesFile } from '../documents/templates/settings/documents-templates-file';
import { ifCondition as documentsTemplatesIf } from '../documents/templates/settings/documents-templates-if';
import { ifNotCondition as documentsTemplatesIfNot } from '../documents/templates/settings/documents-templates-if-not';
import { ifTrueCondition as documentsTemplatesIfTrue } from '../documents/templates/settings/documents-templates-if-true';
import { individualId as documentsTemplatesIndividualId } from '../documents/templates/settings/documents-templates-individual-id';
import { list as documentsTemplatesList } from '../documents/templates/settings/documents-templates-list';
import { movablePropertyLeaseDetails as documentsTemplatesMovablePropertyLeaseDetails } from '../documents/templates/settings/documents-templates-movable-property-lease-details';
import { number as documentsTemplatesNumber } from '../documents/templates/settings/documents-templates-number';
import { radio as documentsTemplatesRadio } from '../documents/templates/settings/documents-templates-radio';
import { radioGrid as documentsTemplatesRadioGrid } from '../documents/templates/settings/documents-templates-radio-grid';
import { paymentSchedule as documentsTemplatesPaymentSchedule } from '../documents/templates/settings/documents-templates-payment-schedule';
import { schedule as documentsTemplatesSchedule } from '../documents/templates/settings/documents-templates-schedule';
import { actorSchedule as documentsTemplatesActorSchedule } from '../documents/templates/settings/documents-templates-actor-schedule';
import { special as documentsTemplatesSpecial } from '../documents/templates/settings/documents-templates-special';
import { text as documentsTemplatesText } from '../documents/templates/settings/documents-templates-text';
import authService from '../services/authService';
import signOut from '../firebase/auth/signout';
import { setOffline } from '../firebase/realtime_database/presence';

import setUserCustomClaims from '../utils/setUserCustomClaims';

import useGetClientSuppliers from '../hooks/useGetClientSuppliers';
import useGetFolderESignatureSettings from '../hooks/useGetFolderESignatureSettings';
import useGetFolderUsers from '../hooks/useGetFolderUsers';
import useGetProposals from '../hooks/useGetProposals';
import useGetProposalsWithPaymentsAsOfPreviousYear from '../hooks/useGetProposalsWithPaymentsAsOfPreviousYear';
import useGetProjectClearanceSettings from '../hooks/useGetProjectClearanceSettings';
import useGetProjectDocumentsSettings from '../hooks/useGetProjectDocumentsSettings';
import useGetProjectFormDrafts from '../hooks/useGetProjectFormDrafts';
import useGetProjectGroupsSettings from '../hooks/useGetProjectGroupsSettings';
import useGetProjectTalentSettings from '../hooks/useGetProjectTalentSettings';
import useGetUserDocumentsSettings from '../hooks/useGetUserDocumentsSettings';
import useGetUserESignatureSettings from '../hooks/useGetUserESignatureSettings';
import useGetWorkspaceActiveProjects from '../hooks/useGetWorkspaceActiveProjects';
import useGetWorkspaceChatMessages from '../hooks/useGetWorkspaceChatMessages';
import useGetWorkspaceClearanceTemplates from '../hooks/useGetWorkspaceClearanceTemplates';
import useGetWorkspaceDocumentsTemplates from '../hooks/useGetWorkspaceDocumentsTemplates';
import useGetWorkspaceFinance from '../hooks/useGetWorkspaceFinance';
import useGetWorkspaceJudicialCases from '../hooks/useGetWorkspaceJudicialCases';
import useGetWorkspaceListItems from '../hooks/useGetWorkspaceListItems';
import useGetWorkspaceLists from '../hooks/useGetWorkspaceLists';
import useGetWorkspacePendingJudicialCasesProgress from '../hooks/useGetWorkspacePendingJudicialCasesProgress';
import useGetWorkspaceProjectsCustomFields from '../hooks/useGetWorkspaceProjectsCustomFields';
import useGetWorkspaceProjectsWithClearance from '../hooks/useGetWorkspaceProjectsWithClearance';
import useGetWorkspaceTemplateLists from '../hooks/useGetWorkspaceTemplateLists';
import useGetWorkspaceTimesheetRecords from '../hooks/useGetWorkspaceTimesheetRecords';
import useGetWorkspaceUsers from '../hooks/useGetWorkspaceUsers';
import useGetYearExpenses from '../hooks/useGetYearExpenses';
import Client from '../classes/Client';
import Workspace from '../classes/Workspace';

const AppCtxAPI = createContext();
const AppContext = createContext();
const AppCtxAvailableClientsCompaniesFolders = createContext();
const AppCtxChangeFolderGroupsView = createContext();
const AppCtxFloatingAlert = createContext();
const AppCtxLoading = createContext();
const AppCtxLoadingApp = createContext();
const AppCtxListServicosDoAudiovisual = createContext();
const AppCtxOnlineUsers = createContext();
const AppCtxOperatorsMenu = createContext();
const AppCtxSidenavOpen = createContext();
const AppCtxToast = createContext();

const defaultState = {
    activeUserGroups: null,
    activeUserGroupsUpdatedAt: null,
    availableAppClients: null,
    availableAppClientsUpdatedAt: null,
    availableAppCompanies: null,
    availableAppFolders: null,
    availableAppCompaniesPlusFoldersUpdatedAt: null,
    changeFolderGroupsViewOpen: false,
    changeFolderGroupsViewSelectedFolder: null,
    changeFolderGroupsViewSelectedFolderId: '',
    changeFolderGroupsViewUpdatedAt: null,
    floatingAlertVisible: false,
    loading: false,
    mainUserGroup: null,
    onlineUsers: [],
    onlineUsersUpdatedAt: null,
    onlineOperators: [],
    onlineOperatorsUpdatedAt: null,
    operatorsMenuAnchorEl: null,
    operatorsMenuOpen: false,
    operatorsMenuSelectedDocument: null,
    sidenavOpen: false,
    toastVisible: false,
    special_list_audiovisual_services: [],
    userPosition: null
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'HIDE CHANGE FOLDER GROUPS VIEW':
            return { ...state, changeFolderGroupsViewOpen: false, changeFolderGroupsViewSelectedFolder: null, changeFolderGroupsViewSelectedFolderId: '' };
        case 'SET ACTIVE USER GROUPS':
            return { ...state, activeUserGroups: payload, activeUserGroupsUpdatedAt: new Date() };
        case 'SET ACTIVE USER GROUPS + MAIN USER GROUP':
            return { ...state, activeUserGroups: payload.activeUserGroups, activeUserGroupsUpdatedAt: new Date(), mainUserGroup: payload.mainActiveUserGroup };
        case 'SET ACTIVE USER GROUPS POSITION':
            return { ...state, userPosition: payload };
        case 'SET AVAILABLE CLIENTS':
            return { ...state, availableAppClients: payload, availableAppClientsUpdatedAt: new Date() };
        case 'SET AVAILABLE COMPANIES + FOLDERS':
            return { ...state, availableAppCompanies: payload.companies, availableAppFolders: payload.folders, availableAppCompaniesPlusFoldersUpdatedAt: new Date() };
        case 'SET CHANGE FOLDER GROUPS VIEW SELECTED FOLDER':
            let selectedFolderId = '';
            if(payload) selectedFolderId = payload.uid;
            return { ...state, changeFolderGroupsViewSelectedFolder: payload, changeFolderGroupsViewSelectedFolderId: selectedFolderId, changeFolderGroupsViewUpdatedAt: new Date() };
        case 'SET FLOATING ALERT VISIBLE':
            return { ...state, floatingAlertVisible: payload };
        case 'SET LOADING':
            return { ...state, loading: payload };
        case 'SET ONLINE OPERATORS':
            return { ...state, onlineOperators: payload, onlineOperatorsUpdatedAt: new Date() };
        case 'SET ONLINE USERS':
            return { ...state, onlineUsers: payload, onlineUsersUpdatedAt: new Date() };
        case 'SET SIDENAV OPEN':
            return { ...state, sidenavOpen: payload };
        case 'SET STATE':
            let newValue = payload.value;
            if(typeof newValue === 'function'){
                newValue = newValue(state[payload.key]);
            }
            return { ...state, [payload.key]: newValue };
        case 'SET TOAST VISIBLE':
            return { ...state, toastVisible: payload };
        case 'SET USER POSITION':
            return { ...state, userPosition: payload };
        case 'SHOW CHANGE FOLDER GROUPS VIEW':
            return { ...state, changeFolderGroupsViewOpen: true, changeFolderGroupsViewSelectedFolder: payload.selectedFolder, changeFolderGroupsViewSelectedFolderId: payload.selectedFolder.uid };
        case 'SHOW OPERATORS MENU':
            if(payload.element){
                return {
                    ...state,
                    operatorsMenuAnchorEl: payload.element,
                    operatorsMenuOpen: true,
                    operatorsMenuSelectedDocument: payload.clickedDocument,
                };
            }
            return {
                ...state,
                operatorsMenuOpen: false,
            };
        case 'RESET':
            return {...defaultState};
        case 'RESET USER':
            return { ...state, selectedFolder: null, activeUserGroups: null, activeUserGroupsUpdatedAt: new Date(), userPosition: false };
        default: return state;
    }
    
};

const shouldNotRedirectModules = ['senha', 'ficha', 'documento', 'proposta', 'clearance', 'formulario'];

const AppDataProvider = ({children}) => {
    useSignals();
    const {
        activeUser,
        activeUserESignatureSettings,
        clientSuppliers,
        eSignatureStatusExpanded,
        firestoreListeners,
        folderESignatureSettings,
        folderUsers,
        loadingApp,
        loadingProposals,
        onlineUserWindowId,
        pendingProposals,
        projectClearanceSettings,
        projectDocumentSettings,
        projectFormDrafts,
        projectGroupSettings,
        projectTalentSettings,
        proposalsFilters,
        proposalsWithPaymentsAsOfPreviousYear,
        resolvedProposals,
        selectedFolder,
        selectedWorkspace,
        selectedYear,
        workspaceActiveProjectBoards,
        workspaceChatMessages,
        workspaceClearanceTemplates,
        workspaceDisabledJudicialCases,
        workspaceDocumentsTemplates,
        workspaceFinanceSettings,
        workspaceJudicialCases,
        workspaceLists,
        workspaceListsItems,
        workspacePendingJudicialCasesProgress,
        workspacePendingJudicialCasesProgressCount,
        workspaceProjectsCustomFields,
        workspaceProjectsWithClearanceIds,
        workspaceTemplateLists,
        workspaceTimesheetRecords,
        workspaceUsers,
        yearExpenses
    } = useAppStateCtx();
    const APP_PATH = `${window.location.origin}/`;
    const { activeUserIsOperator, resetState, setFirestoreListener } = useAppStateCtxUtils();
    const navigate = useNavigate();
    const floatingAlertProps = useRef({});
    const toastProps = useRef({});
    const mainRef = useRef(null);
    // const location = useLocation();
    
    const [state, dispatch] = useReducer(reducer, {...defaultState});

    const setWindowId = (newValue) => {
        onlineUserWindowId.value = newValue;
    };

    const retrievedClientSuppliers = useGetClientSuppliers(firestoreListeners.value.clientSuppliers);
    useEffect(() => {
        if(retrievedClientSuppliers.data){
            clientSuppliers.value = retrievedClientSuppliers.data || [];
        }
    }, [retrievedClientSuppliers]);

    const retrievedProjectESignatureSettings = useGetFolderESignatureSettings(firestoreListeners.value.folderESignatureSettings);
    useEffect(() => {
        if(retrievedProjectESignatureSettings.data){
            const folderId = firestoreListeners.value.folderESignatureSettings.split('/').pop();
            folderESignatureSettings.value = { ...retrievedProjectESignatureSettings.data, folderId };
        }
    }, [retrievedProjectESignatureSettings]);

    const retrievedFolderUsers = useGetFolderUsers(firestoreListeners.value.folderUsers);
    useEffect(() => {
        if(retrievedFolderUsers.data){
            folderUsers.value = retrievedFolderUsers.data || {};
        }
    }, [retrievedFolderUsers]);
    
    const retrievedProjectClearanceSettings = useGetProjectClearanceSettings(firestoreListeners.value.projectClearanceSettings, selectedFolder.value?.uid);
    useEffect(() => {
        if(retrievedProjectClearanceSettings.data){
            projectClearanceSettings.value = retrievedProjectClearanceSettings.data || {};
        }
    }, [retrievedProjectClearanceSettings]);

    const retrievedProjectDocumentSettings = useGetProjectDocumentsSettings(firestoreListeners.value.projectDocumentSettings, selectedFolder.value?.uid);
    useEffect(() => {
        if(retrievedProjectDocumentSettings.data){
            projectDocumentSettings.value = retrievedProjectDocumentSettings.data || {};
        }
    }, [retrievedProjectDocumentSettings]);

    const retrievedProjectFormDrafts = useGetProjectFormDrafts(firestoreListeners.value.projectFormDrafts, selectedFolder.value?.uid);
    useEffect(() => {
        if(retrievedProjectFormDrafts.data){
            projectFormDrafts.value = retrievedProjectFormDrafts.data || [];
        }
    }, [retrievedProjectFormDrafts]);

    const retrievedProjectGroupSettings = useGetProjectGroupsSettings(firestoreListeners.value.projectGroupSettings, firestoreListeners.value.projectGroupSettings);
    useEffect(() => {
        if(retrievedProjectGroupSettings.data){
            projectGroupSettings.value = {...retrievedProjectGroupSettings.data, projectId: firestoreListeners.value.projectGroupSettings};
        }
    }, [retrievedProjectGroupSettings]);

    const retrievedProjectTalentSettings = useGetProjectTalentSettings(firestoreListeners.value.projectTalentSettings, selectedFolder.value?.uid);
    useEffect(() => {
        if(retrievedProjectTalentSettings.data){
            projectTalentSettings.value = {...retrievedProjectTalentSettings.data, projectId: selectedFolder.value?.uid};
        }
    }, [retrievedProjectTalentSettings]);

    const retrievedClearanceTemplates = useGetWorkspaceClearanceTemplates({ selectedWorkspace: selectedWorkspace.value, shouldSetSnapshot: firestoreListeners.value.workspaceClearanceTemplates })
    useEffect(() => {
        if(retrievedClearanceTemplates.data) workspaceClearanceTemplates.value = retrievedClearanceTemplates.data;
    }, [retrievedClearanceTemplates]);

    const retrievedPendingProposals = useGetProposals(firestoreListeners.value.pendingProposals, false, proposalsFilters.value.contractSupervisedBy);
    useEffect(() => {
        if(retrievedPendingProposals.loading){
            loadingProposals.value = true;
        } else if(retrievedPendingProposals.data) {
            pendingProposals.value = retrievedPendingProposals.data;
            loadingProposals.value = false;
        }
    }, [retrievedPendingProposals]);

    const retrievedResolvedProposals = useGetProposals(firestoreListeners.value.resolvedProposals, proposalsFilters.value.year);
    useEffect(() => {
        if(retrievedResolvedProposals.loading){
            loadingProposals.value = true;
        } else if(retrievedResolvedProposals.data) {
            resolvedProposals.value = retrievedResolvedProposals.data;
            loadingProposals.value = false;
        }
    }, [retrievedResolvedProposals]);

    const retrievedUserDocumentsSettings = useGetUserDocumentsSettings(!activeUserIsOperator.value, activeUser.value?.uid);
    useEffect(() => {
        if(retrievedUserDocumentsSettings.data) eSignatureStatusExpanded.value = !!retrievedUserDocumentsSettings.data.eSignatureStatusExpanded;
    }, [retrievedUserDocumentsSettings]);

    const retrievedUserESignatureSettings = useGetUserESignatureSettings(firestoreListeners.value.activeUserESignatureSettings, activeUser.value?.uid);
    useEffect(() => {
        if(retrievedUserESignatureSettings.data) activeUserESignatureSettings.value = retrievedUserESignatureSettings.data;
    }, [retrievedUserESignatureSettings]);

    const retrievedWorkspaceActiveProjects = useGetWorkspaceActiveProjects(firestoreListeners.value.workspaceActiveProjectBoards);
    useEffect(() => {
        if(retrievedWorkspaceActiveProjects.data) workspaceActiveProjectBoards.value = retrievedWorkspaceActiveProjects.data;
    }, [retrievedWorkspaceActiveProjects]);

    const retrievedWorkspaceChatMessages = useGetWorkspaceChatMessages(firestoreListeners.value.workspaceChatMessages);
    useEffect(() => {
        if(retrievedWorkspaceChatMessages.data) workspaceChatMessages.value = retrievedWorkspaceChatMessages.data;
    }, [retrievedWorkspaceChatMessages]);

    const retrievedProposalsWithPaymentsAsOfPreviousYear = useGetProposalsWithPaymentsAsOfPreviousYear(firestoreListeners.value.proposalsWithPaymentsAsOfPreviousYear, selectedYear.value);
    useEffect(() => {
        if(retrievedProposalsWithPaymentsAsOfPreviousYear.loading){
            //
        } else {
            proposalsWithPaymentsAsOfPreviousYear.value = retrievedProposalsWithPaymentsAsOfPreviousYear.data || [];
        }
    }, [retrievedProposalsWithPaymentsAsOfPreviousYear]);

    const retrievedWorkspaceDocumentsTemplates = useGetWorkspaceDocumentsTemplates(firestoreListeners.value.workspaceDocumentsTemplates, selectedWorkspace.value);
    useEffect(() => {
        if(retrievedWorkspaceDocumentsTemplates.data){
            workspaceDocumentsTemplates.value = retrievedWorkspaceDocumentsTemplates.data;
        }
    }, [retrievedWorkspaceDocumentsTemplates]);

    const retrievedWorkspaceFinanceSettings = useGetWorkspaceFinance();
    useEffect(() => {
        if(retrievedWorkspaceFinanceSettings.data) workspaceFinanceSettings.value = retrievedWorkspaceFinanceSettings.data;
    }, [retrievedWorkspaceFinanceSettings]);

    const retrievedWorkspaceJudicialCases = useGetWorkspaceJudicialCases(firestoreListeners.value.workspaceJudicialCases);
    useEffect(() => {
        if(retrievedWorkspaceJudicialCases.data) workspaceJudicialCases.value = retrievedWorkspaceJudicialCases.data;
    }, [retrievedWorkspaceJudicialCases]);
    const retrievedWorkspaceDisabledJudicialCases = useGetWorkspaceJudicialCases(firestoreListeners.value.workspaceDisabledJudicialCases, true);
    useEffect(() => {
        if(retrievedWorkspaceDisabledJudicialCases.data) workspaceDisabledJudicialCases.value = retrievedWorkspaceDisabledJudicialCases.data;
    }, [retrievedWorkspaceDisabledJudicialCases]);

    const retrievedWorkspaceLists = useGetWorkspaceLists(firestoreListeners.value.workspaceLists, selectedWorkspace.value);
    useEffect(() => {
        if(retrievedWorkspaceLists.data) workspaceLists.value = retrievedWorkspaceLists.data;
    }, [retrievedWorkspaceLists]);
    
    // const retrievedWorkspaceListItems = useGetWorkspaceListItems(firestoreListeners.value.workspaceListsItems, workspaceLists.value);
    const retrievedWorkspaceListItems = useGetWorkspaceListItems(firestoreListeners.value.workspaceListsItems, firestoreListeners.value.workspaceListsItems);
    useEffect(() => {
        if(retrievedWorkspaceListItems.data) workspaceListsItems.value = retrievedWorkspaceListItems.data;
    }, [retrievedWorkspaceListItems]);

    const retrievedWorkspacePendingJudicialCasesProgress = useGetWorkspacePendingJudicialCasesProgress(firestoreListeners.value.workspacePendingJudicialCasesProgress);
    useEffect(() => {
        if(retrievedWorkspacePendingJudicialCasesProgress.data){
            workspacePendingJudicialCasesProgress.value = retrievedWorkspacePendingJudicialCasesProgress.data;
            workspacePendingJudicialCasesProgressCount.value = retrievedWorkspacePendingJudicialCasesProgress.data.length;
            setFirestoreListener('workspaceJudicialCases'); 
        }
    }, [retrievedWorkspacePendingJudicialCasesProgress]);

    const retrievedWorkspaceProjectsWithClearance = useGetWorkspaceProjectsWithClearance(firestoreListeners.value.workspaceProjectsWithClearanceIds);
    useEffect(() => {
        if(!retrievedWorkspaceProjectsWithClearance.loading && retrievedWorkspaceProjectsWithClearance.data){
            workspaceProjectsWithClearanceIds.value = retrievedWorkspaceProjectsWithClearance.data;
        }
    }, [retrievedWorkspaceProjectsWithClearance]);

    const retrievedWorkspaceTemplateLists = useGetWorkspaceTemplateLists(firestoreListeners.value.workspaceTemplateLists, selectedWorkspace.value);
    useEffect(() => {
        if(retrievedWorkspaceTemplateLists.data){
            workspaceTemplateLists.value = retrievedWorkspaceTemplateLists.data;
        }
    }, [retrievedWorkspaceTemplateLists]);

    const retrievedTimesheetRecords = useGetWorkspaceTimesheetRecords(firestoreListeners.value.workspaceTimesheetRecords);
    useEffect(() => {
        if(retrievedTimesheetRecords.data) workspaceTimesheetRecords.value = retrievedTimesheetRecords.data;
    }, [retrievedTimesheetRecords]);

    const retrievedWorkspaceUsers = useGetWorkspaceUsers(firestoreListeners.value.workspaceUsers);
    useEffect(() => {
        if(retrievedWorkspaceUsers.users){
            workspaceUsers.value = retrievedWorkspaceUsers.users;
        }
    }, [retrievedWorkspaceUsers]);

    const retrievedYearExpenses = useGetYearExpenses(firestoreListeners.value.yearExpenses);
    useEffect(() => {
        if(!retrievedYearExpenses.loading && retrievedYearExpenses.expenses && retrievedYearExpenses.yearExpenses){
            yearExpenses.value = {
                expenses: retrievedYearExpenses.expenses,
                year: retrievedYearExpenses.year,
                yearExpenses: retrievedYearExpenses.yearExpenses,
            }
        }
    }, [retrievedYearExpenses]);

    const retrievedWorkspaceProjectsCustomFields = useGetWorkspaceProjectsCustomFields(firestoreListeners.value.workspaceProjectsCustomFields);
    useEffect(() => {
        if(retrievedWorkspaceProjectsCustomFields.data) workspaceProjectsCustomFields.value = retrievedWorkspaceProjectsCustomFields.data;
    }, [retrievedWorkspaceProjectsCustomFields]);

    // FUNCTIONS

    const getActiveUserWorkspacesAndClaims = async (activeUser) => {
        let error = null, result = null;

        const getActiveUserWorkspacesRes = await activeUser.getPermissions();
        if(getActiveUserWorkspacesRes.error){
            return;
        }
        const activeUserWorkspaces = getActiveUserWorkspacesRes.result || [];

        const getAvailableWorkspacesRes = await activeUser.getWorkspacesByIds(activeUserWorkspaces.map(workspace => workspace.uid));
        if(getAvailableWorkspacesRes.error){
            console.log(getAvailableWorkspacesRes.error);
            return;
        }
        const availableWorkspaces = (getAvailableWorkspacesRes.result || []).map(workspace => {
            const foundActiveUserWorkspace = activeUserWorkspaces.find(activeUserWorkspace => activeUserWorkspace.uid === workspace.uid);
            return {
                ...workspace,
                emails: foundActiveUserWorkspace.emails || {},
                favoriteProjects: foundActiveUserWorkspace.favoriteProjects || [],
                projects: foundActiveUserWorkspace.folders || [],
                role: foundActiveUserWorkspace.role || '',
            }
        });
        result = { workspaces: availableWorkspaces };

        const getActiveUserClaimsRes = await activeUser.getClaims();
        if(getActiveUserClaimsRes.error){
            return console.log(getActiveUserClaimsRes.error);
        }
        if(getActiveUserClaimsRes.result){
            const workspaceId = getActiveUserClaimsRes.result.workspaceId;
            const activeUserWorkspace = availableWorkspaces.find(activeUserWorkspace => activeUserWorkspace.uid === workspaceId);
            result.selectedWorkspace = activeUserWorkspace;
        }

        return { error, result };
    };

    const getListById = (lists, listIdOrShortName) => {
        if(lists){
            let foundListId = listIdOrShortName;
            let foundList = lists[listIdOrShortName];
            if(!foundList){
                for(const listId in lists){
                    if(lists[listId].shortName === listIdOrShortName){
                        foundList = lists[listId];
                        foundListId = listId;
                        break;
                    }
                }
            }
            return {...foundList, uid: foundListId};
        }
        return null;
    }

    const goToWorkspace = (selectedWorkspace, availableWorkspaces) => {
        const pathname = window.location.pathname;
        const parts = pathname.split('/');
        const module = parts[1];
        if(!shouldNotRedirectModules.includes(module) && selectedWorkspace){
            if(!module || module !== selectedWorkspace.shortName){
                if(availableWorkspaces.length === 1){
                    return `/${selectedWorkspace.shortName}/${['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.role) ? 'juridico' : 'pastas'}`;
                } else {
                    return '/paineis';
                }
            } else {
                const section = parts[2];
                if(section && section === (['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.role) ? 'juridico' : 'pastas')){
                    return '';
                }
                return `/${selectedWorkspace.shortName}/${['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.role) ? 'juridico' : 'pastas'}`
            }
        }
        return '';
    };

    const handleNavigate = (path, didNavigateAction, willNavigateAction) => {
        if(willNavigateAction) willNavigateAction();
        navigate(path);
        scrollToTop('main');
        if(didNavigateAction) didNavigateAction();
        stopVideo();
    };

    const logout = async (navigate, setLoading) => {
        if(setLoading) setLoading(true);
        const toastId = toastify.loading(`Saindo com segurança...`);
        const activeUserId = activeUser.value.uid;
        const { error } = await signOut();
        if(error){
            return toastify.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        setOffline(onlineUserWindowId.value, setWindowId);
        setUserCustomClaims('', activeUserId);
        toastify.dismiss(toastId);
        loadingApp.value = false;
        navigate('/');
        resetStates();
        resetState();
    };

    const redirect = async (newActiveUser) => {
        let path = '/paineis';
        const res = await getActiveUserWorkspacesAndClaims(newActiveUser);
        if(!res.error && res.result){
            
            const availableWorkspaces = res.result.workspaces || [];

            if(availableWorkspaces.length !== 0){

                if(res.result.selectedWorkspace){
                    const currentSelectedWorkspace = new Workspace({...res.result.selectedWorkspace});
                    selectedWorkspace.value = currentSelectedWorkspace;
                    setFirestoreListener('workspaceUsers', currentSelectedWorkspace.uid);
                    path = goToWorkspace(res.result.selectedWorkspace, availableWorkspaces);
                }
        
                if(availableWorkspaces.length === 1){
                    await activeUser.value.setClaims(availableWorkspaces[0].uid);
                    const currentSelectedWorkspace = new Workspace({...availableWorkspaces[0]});
                    selectedWorkspace.value = currentSelectedWorkspace;
                    setFirestoreListener('workspaceUsers', currentSelectedWorkspace.uid);
                    path = goToWorkspace(availableWorkspaces[0], availableWorkspaces);
                }

            }

        }
        if(path) handleNavigate(path);
    };

    const resetStates = () => {
        firestoreListeners.value = {};
        dispatch({type: 'RESET'});
    };

    const setSnapshot = (triggerKey) => {
        setState(triggerKey, true);
    }

    const setState = (key, newValue) => {
        dispatch({type: 'SET STATE', payload: { key, value: newValue }});
    };

    const stopVideo = () => {
        if(activeUserVideo.current){
            const stream = activeUserVideo.current.srcObject;
            if(stream){
                const tracks = stream.getTracks();
                tracks.forEach((track) => {
                    track.stop();
                });
            }
        }
    };

    const api = useMemo(() => {

        const floatingAlert = (message, messageSeverity = 'warning', messageTimeout = 1000) => {
            setFloatingAlertVisible(false);
            if(message){
                floatingAlertProps.current = {message, severity: messageSeverity};
                setTimeout(() => {
                    setFloatingAlertVisible(true);
                }, messageTimeout);
            }
        };

        const hideChangeFolderGroupsView = (payload) => {
            dispatch({type: 'HIDE CHANGE FOLDER GROUPS VIEW', payload});
        };

        const getSuppliers = (clientId) => {
            return new Promise(async (resolve, reject) => {
                const newClient = new Client({ uid: clientId });
                const res = await newClient.getSuppliers();
                if(res.error){
                    toast(ERROR_MESSAGE_UNKNOWN);
                    return reject(false);
                }

                resolve(res.result);
            });
        };

        const getUserGroups = (activeUser, groups, isOperator = false) => {
            let mainGroup;
            let userGroups = [];
            let userPosition = isOperator ? 2 : userManagerTest(groups, activeUser.uid);
            if(userPosition && userPosition === 2){
                if(groups['2']){
                    mainGroup = 2;
                } else {
                    mainGroup = 1;
                }
                for(const groupId in groups){
                    if(!['~all;', '&all', '~none;', '&none'].includes(groupId)) userGroups.push({...groups[groupId], id: groupId});
                }
            } else if(userPosition && userPosition === 1){
                mainGroup = 1;
                for(const groupId in groups){
                    if(!['~all;', '&all', '~none;', '&none', '2'].includes(groupId)) userGroups.push({...groups[groupId], id: groupId});
                }
            } else {
                groups.forEach(g => {
                    for(const groupId in groups){
                        if(!['~all;', '&all', '~none;', '&none', '2', '1'].includes(groupId) && groups[groupId].users.includes(activeUser.uid)){
                            userGroups.push({...groups[groupId], id: groupId});
                            if(!mainGroup) mainGroup = groupId;
                        }
                    }
                });
            }
            // group_any always in first position of array
            dispatch({type: 'SET ACTIVE USER GROUPS + MAIN USER GROUP', payload: { activeUserGroups: userGroups, mainActiveUserGroup: mainGroup }});
        };

        const resetUser = () => {
            dispatch({type: 'RESET USER'});
        };

        const setActiveUserGroups = (payload) => {
            dispatch({type: 'SET ACTIVE USER GROUPS', payload});
        }

        const setAvailableClients = (payload) => {
            dispatch({type: 'SET AVAILABLE CLIENTS', payload});
        };

        const setChangeFolderGroupsViewSelectedFolder = (selectedFolder) => {
            dispatch({type: 'SET CHANGE FOLDER GROUPS VIEW SELECTED FOLDER', payload: selectedFolder});
        };

        const setFloatingAlertVisible = (payload) => {
            dispatch({ type: 'SET FLOATING ALERT VISIBLE', payload });
        };

        const setLoading = (payload) => {
            dispatch({ type: 'SET LOADING', payload });
        };

        const setOnlineOperators = (payload) => {
            dispatch({ type: 'SET ONLINE OPERATORS', payload });
        };
        
        const setOnlineUsers = (payload) => {
            dispatch({ type: 'SET ONLINE USERS', payload });
        };

        const setSidenavOpen = (payload) => {
            dispatch({ type: 'SET SIDENAV OPEN', payload });
        };

        const setToastVisible = (payload) => {
            dispatch({ type: 'SET TOAST VISIBLE', payload });
        };

        const setUserPosition = (payload) => {
            dispatch({ type: 'SET USER POSITION', payload });
        };

        const showChangeFolderGroupsView = (selectedFolder) => {
            dispatch({type: 'SHOW CHANGE FOLDER GROUPS VIEW', payload: { selectedFolder }});
        };

        const showOperatorsMenu = (element, clickedDocument) => {
            dispatch({type: 'SHOW OPERATORS MENU', payload: { element, clickedDocument }});
        };

        const signIn = async (email, password) => {
            const user = await authService.login(email, password);
            return user;
        };

        const toast = (message, vertical) => {
            toastProps.current = {message, vertical};
            setToastVisible(true);
        };

        const updateActiveUser = (payload) => {
            dispatch({ type: 'UPDATE ACTIVE USER', payload });
        };

        const userManagerTest = (groups, userId) => {
            let position = false;
            if(groups){
                const group_admin = groups['2'];
                if(group_admin){
                    const userAdmin = group_admin.users.includes(userId);
                    if(userAdmin) position = 2;
                }
                const group_manager = groups['1'];
                if(group_manager){
                    const userManager = group_manager.users.includes(userId);
                    if(userManager) position = 1;
                }
            }
            dispatch({type: 'SET ACTIVE USER GROUPS POSITION', payload: position})
            return position;
        };

        return {
            dispatch,
            APP_PATH,
            floatingAlert,
            getActiveUserWorkspacesAndClaims,
            getListById, getSuppliers, getUserGroups,
            goToWorkspace,
            handleNavigate,
            hideChangeFolderGroupsView,
            logout,
            redirect,
            resetUser,
            setActiveUserGroups, setAvailableClients,
            setChangeFolderGroupsViewSelectedFolder,
            setFloatingAlertVisible,
            setLoading,
            setOnlineOperators, setOnlineUsers,
            setSidenavOpen,
            setSnapshot,
            setState,
            setToastVisible,
            setUserPosition,
            setWindowId,
            showChangeFolderGroupsView,
            showOperatorsMenu,
            signIn, stopVideo,
            toast,
            updateActiveUser, userManagerTest
        };
    }, []);

    const activeUserVideo = useRef(null);

    const formEffects = {
        date: documentsTemplatesDate,
        file: documentsTemplatesFile,
        list: documentsTemplatesList,
        actorSchedule: documentsTemplatesActorSchedule,
        movablePropertyLeaseDetails: documentsTemplatesMovablePropertyLeaseDetails,
        paymentSchedule: documentsTemplatesPaymentSchedule,
        radio: documentsTemplatesRadio,
        radioGrid: documentsTemplatesRadioGrid,
        schedule: documentsTemplatesSchedule,
        text: documentsTemplatesText,
        currency: documentsTemplatesCurrency,
        number: documentsTemplatesNumber,
        individualId: documentsTemplatesIndividualId,
        corporationId: documentsTemplatesCorporationId,
        if: documentsTemplatesIf,
        ifNot: documentsTemplatesIfNot,
        ifTrue: documentsTemplatesIfTrue,
        special: documentsTemplatesSpecial,
    };

    const appCtxValue = {
        activeUserVideo,
        formEffects, 
        logout,
        mainRef
    }

    const appCtxToastValue = useMemo(() => ({
        toastProps: toastProps.current, toastVisible: state.toastVisible
    }), [state.toastVisible]);

    const appCtxFloatingAlertValue = useMemo(() => ({
        floatingAlertProps: floatingAlertProps.current, floatingAlertVisible: state.floatingAlertVisible
    }), [state.floatingAlertVisible]);

    const appCtxAvailableAppCompaniesPlusFoldersValue = useMemo(() => ({
        availableAppCompanies: state.availableAppCompanies,
        availableAppFolders: state.availableAppFolders
    }), [state.availableAppCompaniesPlusFoldersUpdatedAt]);

    const appCtxOnlineUsersValue = useMemo(() => ({
        onlineOperators: state.onlineOperators,
        onlineUsers: state.onlineUsers
    }), [state.onlineOperatorsUpdatedAt, state.onlineUsersUpdatedAt]);

    const appCtxChangeFolderGroupsViewValue = useMemo(() => ({
        changeFolderGroupsViewOpen: state.changeFolderGroupsViewOpen,
        changeFolderGroupsViewSelectedFolder: state.changeFolderGroupsViewSelectedFolder,
        changeFolderGroupsViewSelectedFolderId: state.changeFolderGroupsViewSelectedFolderId,
    }), [state.changeFolderGroupsViewOpen, state.changeFolderGroupsViewUpdatedAt]);

    const appCtxOperatorsMenuValue = useMemo(() => ({
        operatorsMenuAnchorEl: state.operatorsMenuAnchorEl,
        operatorsMenuOpen: state.operatorsMenuOpen,
        operatorsMenuSelectedDocument: state.operatorsMenuSelectedDocument
    }), [state.operatorsMenuOpen]);
    
    return (
        <AppCtxAPI.Provider value={api}>
            <AppContext.Provider value={appCtxValue}>
                <AppCtxLoading.Provider value={state.loading}>
                    <AppCtxToast.Provider value={appCtxToastValue}>
                        <AppCtxFloatingAlert.Provider value={appCtxFloatingAlertValue}>
                                <AppCtxSidenavOpen.Provider value={state.sidenavOpen}>
                                <AppCtxListServicosDoAudiovisual.Provider value={state.special_list_audiovisual_services}>
                                <AppCtxAvailableClientsCompaniesFolders.Provider value={appCtxAvailableAppCompaniesPlusFoldersValue}>
                                <AppCtxOnlineUsers.Provider value={appCtxOnlineUsersValue}>
                                <AppCtxChangeFolderGroupsView.Provider value={appCtxChangeFolderGroupsViewValue}>
                                <AppCtxOperatorsMenu.Provider value={appCtxOperatorsMenuValue}>
                                    {children}
                                </AppCtxOperatorsMenu.Provider>
                                </AppCtxChangeFolderGroupsView.Provider>
                                </AppCtxOnlineUsers.Provider>
                                </AppCtxAvailableClientsCompaniesFolders.Provider>
                                </AppCtxListServicosDoAudiovisual.Provider>
                                </AppCtxSidenavOpen.Provider>

                                <NewVersionAvailable />

                            <FloatingAlert />

                        </AppCtxFloatingAlert.Provider>
                        
                        <Toast />

                    </AppCtxToast.Provider>
                    </AppCtxLoading.Provider>

                    <AppDialog />
                    <LoadingSystemScreen />

            </AppContext.Provider>
        </AppCtxAPI.Provider>
    );
};

const useAppContext = () => useContext(AppContext);
const useAppCtxAPI = () => useContext(AppCtxAPI);
const useAppCtxAvailableClientsCompaniesFolders = () => useContext(AppCtxAvailableClientsCompaniesFolders);
const useAppCtxChangeFolderGroupsView = () => useContext(AppCtxChangeFolderGroupsView);
const useAppCtxFloatingAlert = () => useContext(AppCtxFloatingAlert);
const useAppCtxListServicosDoAudiovisual = () => useContext(AppCtxListServicosDoAudiovisual);
const useAppCtxLoading = () => useContext(AppCtxLoading);
const useAppCtxLoadingApp = () => useContext(AppCtxLoadingApp);
const useAppCtxOnlineUsers = () => useContext(AppCtxOnlineUsers);
const useAppCtxOperatorsMenu = () => useContext(AppCtxOperatorsMenu);
const useAppCtxSidenavOpen = () => useContext(AppCtxSidenavOpen);
const useAppCtxToast = () => useContext(AppCtxToast);

export {
    AppDataProvider,
    useAppCtxAPI,
    useAppContext,
    useAppCtxAvailableClientsCompaniesFolders,
    useAppCtxChangeFolderGroupsView,
    useAppCtxFloatingAlert,
    useAppCtxListServicosDoAudiovisual,
    useAppCtxLoading,
    useAppCtxLoadingApp,
    useAppCtxOnlineUsers,
    useAppCtxOperatorsMenu,
    useAppCtxSidenavOpen,
    useAppCtxToast,
};