import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';
import { useAppStateCtx } from '../context/AppState';

const useGetUserNotifications = (userId, notificationsReatAt) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(userId && selectedWorkspace.value){
            setHookState(defaultHookState);
            let conditions = [];
            if(notificationsReatAt) conditions.push(where('createdAt', '>', notificationsReatAt));
            const unsubscribe = onSnapshot(
                query(
                    collection(db, `users/${userId}/notifications`),
                    ...conditions,
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const foundUserNotifications = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        foundUserNotifications.push({
                            ...data,
                            uid: snapshot.id,
                        })
                    });
                    setHookState({
                        loading: false,
                        data: foundUserNotifications
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [userId, selectedWorkspace.value, notificationsReatAt]);

    return hookState;
};

export default useGetUserNotifications;