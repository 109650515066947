import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import MenuItem from '@mui/material/MenuItem';
import Account from '../../components/Account';
import { useAppCtxAPI } from '../../context/SystemContext';

const userMenuOpen = signal(null);

const FolderUserAccount = ({size}) => {
    useSignals();
    const { workspaceId } = useParams();
    const { handleNavigate } = useAppCtxAPI();
    const userAvatarRef = useRef(null);

    useEffect(() => {
        return () => {
            userMenuOpen.value = false;
        };
    }, []);
    
    return (
        <Account size={size} userAvatarRef={userAvatarRef} userMenuOpen={userMenuOpen}> 
            <MenuItem onClick={() => handleNavigate(`/${workspaceId}/pastas/preferencias`)}>Preferências</MenuItem>
        </Account>
    );
};

export default FolderUserAccount;