import { useState } from 'react';

import TextField from '@mui/material/TextField';

import { useOperatorTemplatesCtxTemplateName } from '../context/TemplatesContext';

function AddTemplate(){
    const templateName = useOperatorTemplatesCtxTemplateName();
    const [newTemplateName, setNewTemplateName] = useState(templateName.current || '');

    const handleChange = (e) => {
        const newValue = e.target.value;
        setNewTemplateName(newValue);
        templateName.current = newValue;
    }

    return (
        <TextField
            variant="standard"
            required
            fullWidth
            label="Nome da matriz"
            value={newTemplateName}
            onChange={handleChange}
        />
    );
}

export default AddTemplate;