import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DescriptionIcon from '@mui/icons-material/Description';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';

const classes = {
    attachedFile: {
        border: `1px solid rgba(180, 180, 180, 0.5)`,
        borderRadius: '20px',
        alignItems: 'center',
        display: 'flex', 
        height: '75px',
        justifyContent: 'center',
        position: 'relative',
        width: '75px',
    },
    attachedFileLabel: {
        color: theme => theme.palette.grey[800],
    },
    attachedImage: {
        borderRadius: '20px',
        maxHeight: '75px',
        maxWidth: '75px',
    },
    fileBox: {
        position: 'relative',
    },
    overlay: {
        backgroundColor: 'rgba(250, 250, 250, 0.5)',
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        transition: 'background-color .4s',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'rgba(250, 250, 250, 0)',
        },
    },
};

const Image = styled('img')(unstable_styleFunctionSx);

const DetailedFilesList = ({list}) => {

    const handleFileClick = (file) => {
        if(file.format === 'googleDoc'){
            return window.open(file.url || file.path, '_blank');
        }

        window.open(file.url || file.path, '_blank');
    };

    const Overlay = ({file}) => {
        return (
            <Box sx={classes.overlay} onClick={() => handleFileClick(file)} />
        )
    };
    
    const FileIcon = ({fileExtension}) => {
        if(fileExtension === 'pdf'){
            return (
                <FontAwesomeIcon icon={faFilePdf} size="2x" color={'#b30a00'} />
            );
        } else if(fileExtension === 'doc' || fileExtension === 'docx'){
            return (
                <FontAwesomeIcon icon={faFileWord} size="2x" color={'#115abe'} />
            );
        } else if(fileExtension === 'googleDoc'){
            return (
                <DescriptionIcon fontSize="large" color="primary" />
            );
        } else {
            return (
                <AttachmentIcon />
            );
        }
    };

    return (
        <Box>
            {
                list.map((file, fileIndex) => {
                    const fileName = (file.name || file).match(/[^/]+$/);
                    const filePath = file.path || file;
                    const fileExtension = file.format || filePath.split('.').pop().toLowerCase();
                    if(['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(fileExtension)){
                        return (
                            <Box
                                sx={classes.attachedFile}
                                mb={1}
                            >
                                <Image sx={classes.attachedImage} src={filePath} />
                                <Overlay file={file} />
                            </Box>
                        );
                    }
                    
                    return (
                        <Box key={fileIndex} sx={classes.fileBox} mb={1}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Box sx={classes.attachedFile}>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <FileIcon fileExtension={fileExtension} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Typography sx={classes.attachedFileLabel}>{fileName}</Typography>
                                </Grid>
                            </Grid>
                            <Overlay file={file} />
                        </Box>
                    );
                })
            }
        </Box>
    );
};

export default DetailedFilesList;