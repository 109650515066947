import { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateIcon from '@mui/icons-material/Create';
import DescriptionIcon from '@mui/icons-material/Description';
import MovieIcon from '@mui/icons-material/Movie';
import PeopleIcon from '@mui/icons-material/People';

import ChangeLogoButton from '../../../../../components/projects/ChangeLogoButton';
import DownloadSignedDocumentsButton from '../../../../../components/DownloadSignedDocumentsButton';
import VirtualizedAutocomplete from '../../../../../components/VirtualizedAutocomplete';
// import ProjectWindowImportSettingsSection from '../../components/ProjectWindowImportSettingsSection';

import { useOperatorCtxOperators } from '../../../context/OperatorContext';

import Project from '../../../../../classes/Project';
import Dialog from '../../../../../components/Dialog/Dialog';
import LoaderEllipsis from '../../../../../components/LoaderEllipsis';
import ResponsiveButton from '../../../../../components/ResponsiveButton/ResponsiveButton';
import Select from '../../../../../components/Select';
import Switch from '../../../../../components/Switch';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxChangeFolderView } from '../../../../../context/OperatorFoldersContext';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';
import { useAppCtxAPI } from '../../../../../context/SystemContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';
import useGetFolderESignatureSettings from '../../../../../hooks/useGetFolderESignatureSettings';
import useGetProject from '../../../../../hooks/useGetProject';
import useGetProjectClearanceSettings from '../../../../../hooks/useGetProjectClearanceSettings';
import useGetProjectDocumentsSettings from '../../../../../hooks/useGetProjectDocumentsSettings';
import useGetProjectGroupsSettings from '../../../../../hooks/useGetProjectGroupsSettings';
import useGetProjectTalentSettings from '../../../../../hooks/useGetProjectTalentSettings';

const DownloadSignedDocumentsSection = ({ projectId }) => {
    useSignals();

    return (
        <DownloadSignedDocumentsButton projectId={projectId.value} />
    );
}

const DataDrawer = ({ clickedProjectId, open }) => {
    useSignals();
    const { activeUser, folderESignatureSettings, projectClearanceSettings, projectDocumentSettings, projectGroupSettings, projectTalentSettings, selectedWorkspace } = useAppStateCtx();
    const { mappedWorkspaceClients } = useAppStateCtxUtils();
    const { showChangeFolderGroupsView } = useAppCtxAPI();
    const operators = useOperatorCtxOperators();
    const { showFolderClearanceView, showFolderSignatureView, showFolderTemplatesView } = useOperatorFoldersCtxAPI();
    const { changeFolderViewClickedFolder: selectedProject } = useOperatorFoldersCtxChangeFolderView();
    const [saving, setSaving] = useState(false);
    const defaultProjectState = {
        clientId: '',
        googleDocDefaultPermission: 'writer',
        // minimumUserLevel: 'all',
        name: '',
        operatorId: activeUser.value.uid,
        options: {
            allowClientToAddDocumentWithoutForm: false,
            clearance: false,
            customFields: false,
            eSignature: false,
            groups: false,
            starredFields: false,
            talent: false
        },
    };
    const [foundProject, setFoundProject] = useState(null);
    const [projectState, setProjectState] = useState(defaultProjectState);
    const [loadingProject, setLoadingProject] = useState(true);
    // const [transferringSignedDocumentsToGoogleDrive, setTransferringSignedDocumentsToGoogleDrive] = useState(false);

    const retrievedProject = useGetProject(clickedProjectId.value);
    useEffect(() => {
        let currentFoundProject = null;
        if(clickedProjectId.value && retrievedProject.data){
            currentFoundProject = new Project({...retrievedProject.data, uid: clickedProjectId.value});
        }
        setFoundProject(currentFoundProject);
    }, [retrievedProject]);

    const retrievedProjectClearanceSettings = useGetProjectClearanceSettings(true, clickedProjectId.value);
    useLayoutEffect(() => {
        let data = retrievedProjectClearanceSettings.data || {};
        setProjectState(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                clearance: !!data.enabled
            },
        }));
        projectClearanceSettings.value = {
            ...data,
            projectId: clickedProjectId.value
        }
    }, [retrievedProjectClearanceSettings]);

    const retrievedProjectGroupSettings = useGetProjectGroupsSettings(true, clickedProjectId.value);
    useLayoutEffect(() => {
        let data = retrievedProjectGroupSettings.data || {};
        projectGroupSettings.value = { ...data, projectId: clickedProjectId.value };
        setProjectState(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                groups: !!data.enabled
            },
            groups: data.groups || null
        }));
    }, [retrievedProjectGroupSettings]);

    const retrievedProjectTalentSettings = useGetProjectTalentSettings(true, clickedProjectId.value);
    useLayoutEffect(() => {
        let data = retrievedProjectTalentSettings.data || {};
        setProjectState(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                talent: !!data.enabled
            },
        }));
        projectTalentSettings.value = {
            ...data,
            projectId: clickedProjectId.value
        }
    }, [retrievedProjectTalentSettings]);

    const retrievedProjectESignatureSettings = useGetFolderESignatureSettings(clickedProjectId.value ? `projects/${clickedProjectId.value}` : '');
    useLayoutEffect(() => {
        let data = retrievedProjectESignatureSettings.data || {};
        folderESignatureSettings.value = { ...data, folderId: clickedProjectId.value };
        setProjectState(prevState => ({
            ...prevState,
            options: {
                ...prevState.options,
                eSignature: !!data.enabled
            },
            eSignature: {...data}
        }));
    }, [retrievedProjectESignatureSettings]);

    const retrievedProjectDocumentsSettings = useGetProjectDocumentsSettings(true, clickedProjectId.value);
    useLayoutEffect(() => {
        let data = retrievedProjectDocumentsSettings.data || {};
        projectDocumentSettings.value = { ...data, projectId: clickedProjectId.value };
        setProjectState(prevState => ({
            ...prevState,
            googleDocDefaultPermission: data.googleDocDefaultPermission || 'writer',
            options: {
                ...prevState.options,
                allowClientToAddDocumentWithoutForm: !!data.allowClientToAddDocumentWithoutForm,
                customFields: !!data.customFields?.enabled,
                starredFields: !!data.starredFields?.enabled
            },
        }));
    }, [retrievedProjectDocumentsSettings]);

    useLayoutEffect(() => {
        if(open.value && clickedProjectId.value){
            setLoadingProject(!foundProject || retrievedProjectClearanceSettings.loading || retrievedProjectGroupSettings.loading || retrievedProjectTalentSettings.loading || retrievedProjectESignatureSettings.loading || retrievedProjectDocumentsSettings.loading);
        }
    }, [open.value, foundProject, retrievedProjectClearanceSettings, retrievedProjectGroupSettings, retrievedProjectTalentSettings, retrievedProjectESignatureSettings, retrievedProjectDocumentsSettings]);

    useEffect(() => {
        if(open.value && !clickedProjectId.value){
            setLoadingProject(false);
        }
    }, [open.value]);
    
    useLayoutEffect(() => {
        if(!clickedProjectId.value){
            clearForm();
        } else if(clickedProjectId.value && foundProject) {
            setProjectState(prevState => ({
                ...prevState,
                clientId: foundProject.clientId,
                // googleDriveFolder: selectedProject.googleDriveFolder, //TODO
                // minimumUserLevel: selectedProject.minimumUserLevel || 'all', //TODO
                name: foundProject.name,
                operatorId: foundProject.operatorId || '',
            }));
        }
    }, [clickedProjectId.value, foundProject]);

    const handleSubmit = async () => {
        setSaving(true);

        if(!clickedProjectId.value){
            const workspaceId = selectedWorkspace.value.uid;
            const newProject = new Project({
                clientId: projectState.clientId,
                createdBy: activeUser.value.uid,
                name: projectState.name,
                operatorId: activeUser.value.uid,
                workspaceId
            });
            const res = await newProject.createProject();
            setSaving(false);
            if(res.error){
                return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }
            handleClose();
        } else {
            const root = {
                name: projectState.name
            };
            if(projectState.clientId) root.clientId = projectState.clientId;
            if(projectState.operatorId) root.operatorId = projectState.operatorId;
            const options = {};
            if(projectClearanceSettings.value?.projectId === clickedProjectId.value && projectClearanceSettings.value.enabled !== projectState.options.clearance){
                options.clearance = projectState.options.clearance;
            }
            if(folderESignatureSettings.value?.folderId === clickedProjectId.value && folderESignatureSettings.value.enabled !== projectState.options.eSignature){
                options.eSignature = projectState.options.eSignature;
            }
            if(projectGroupSettings.value?.projectId === clickedProjectId.value && projectGroupSettings.value.enabled !== projectState.options.groups){
                options.groups = projectState.options.groups;
            }
            if(projectTalentSettings.value?.projectId === clickedProjectId.value && projectTalentSettings.value.enabled !== projectState.options.clearance){
                options.talent = projectState.options.talent;
            }
            const updatedDocumentsSettings = {};
            if(projectDocumentSettings.value?.projectId === clickedProjectId.value){
                if(!!projectDocumentSettings.value.starredFields?.enabled !== projectState.options.starredFields) options.starredFields = projectState.options.starredFields;
                if(!!projectDocumentSettings.value.customFields?.enabled !== projectState.options.customFields) options.customFields = projectState.options.customFields;
                if(!!projectDocumentSettings.value.allowClientToAddDocumentWithoutForm !== projectState.options.allowClientToAddDocumentWithoutForm) options.allowClientToAddDocumentWithoutForm = projectState.options.allowClientToAddDocumentWithoutForm;
                if(!!projectDocumentSettings.value.googleDocDefaultPermission !== projectState.googleDocDefaultPermission) updatedDocumentsSettings.googleDocDefaultPermission = projectState.googleDocDefaultPermission;
            }

            const updateRes = await foundProject.update({ options, root, updatedDocumentsSettings, workspaceId: selectedWorkspace.value.uid });
            setSaving(false);
            if(updateRes.error){
                return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }
        }
        toast(SUCCESS_MESSAGE_SAVED, { autoClose: 3000, type: 'success' });
        
        setSaving(false);
    };
    const clearForm = () => {
        setProjectState(defaultProjectState);
    };

    // const handleTransferSignedDocumentsToGoogleDriveButtonPress = async () => {
    //     setTransferringSignedDocumentsToGoogleDrive(true);
    //TODO /data/client/documents/download?folderId=${selectedProject.uid}
    //     setTransferringSignedDocumentsToGoogleDrive(false);
    // };

    const handleChangeClearanceButtonPress = () => {
        showFolderClearanceView(foundProject);
    };

    const handleChangeSignatureButtonPress = () => {
        showFolderSignatureView(foundProject);
    };

    const handleChangeTemplatesButtonPress = () => {
        showFolderTemplatesView(foundProject);
    };

    const handleChangeGroupsButtonPress = () => {
        showChangeFolderGroupsView(foundProject);
    };

    const handleClose = () => {
        open.value = false;
    };

    const handleGroupsEnabledChange = (e) => {
        const newValue = e.target.checked;
        let shouldUpdate = true;
        if(!newValue){
            if(!window.confirm('Tem certeza? Isso abrirá o acesso aos documentos do projeto para todas as pessoas com acesso ao projeto, independemente do grupo em que estejam alocadas.')) shouldUpdate = false;
        }
        if(shouldUpdate) setProjectState(prevState => ({...prevState, options: {...prevState.options, groups: e.target.checked}}))
    }

    return (
        <Dialog
            buttons={[
                foundProject &&
                <ResponsiveButton
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<DescriptionIcon />}
                    onClick={handleChangeTemplatesButtonPress}
                    label="Matrizes"
                />
            ]}
            maxWidth="lg"
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={open.value}
            saveButtonDisabled={!projectState.clientId || !projectState.name}
            saving={saving}
            title={!!foundProject ? foundProject.name : 'Novo projeto'}
        >

            {
                loadingProject
                ?
                <LoaderEllipsis />
                :
                <Container maxWidth="md">
                    <Box>

                        {
                            foundProject &&
                            <Box mb={3}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid size="grow"></Grid>
                                    <Grid>
                                        <ChangeLogoButton selectedClientOrProject={foundProject} />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Box my={3}>
                            <Box mb={2}>
                                <Typography variant="h5">Dados gerais</Typography>
                            </Box>
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    <Grid size={{ xs: 12, sm: 6 }}>
                                        <TextField
                                            variant="standard"
                                            label="Nome do projeto"
                                            fullWidth
                                            required
                                            value={projectState.name}
                                            onChange={(e) => setProjectState(prevState => ({...prevState, name: e.target.value}))} />
                                    </Grid>
                                    {
                                        mappedWorkspaceClients.value &&
                                        <Grid size={{ xs: 12, sm: 6 }}>
                                            <VirtualizedAutocomplete
                                                label="Cliente"
                                                variant="standard"
                                                options={mappedWorkspaceClients.value.map(client => client.uid)}
                                                getOptionLabel={
                                                    (option) => {
                                                        const foundClient = mappedWorkspaceClients.value.find(client => client.uid === option);
                                                        if(foundClient){
                                                            return foundClient.label;
                                                        }
                                                        return option;
                                                    }
                                                }
                                                value={projectState.clientId}
                                                onChange={(_, newValue) => setProjectState(prevState => ({...prevState, clientId: newValue}))}
                                            />
                                        </Grid>
                                    }
                                    <Grid size={{ xs: 12, sm: 6 }}>
                                        <Select
                                            label="Responsável"
                                            options={operators.map(operator => ({value: operator.uid, label: operator.name}))}
                                            value={projectState.operatorId}
                                            onChange={(e) => setProjectState(prevState => ({...prevState, operatorId: e.target.value}))}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box my={3}>
                            <Box mb={2}>
                                <Typography variant="h5">Documentos</Typography>
                            </Box>
                            <Box mb={1}>
                                <Grid container spacing={1}>
                                    <Grid size={{ xs: 12, sm: 6 }}>
                                        <Select
                                            label="Permissão para Google Docs"
                                            options={[{value: 'writer', label: 'Qualquer um pode editar'}, {value: 'commenter', label: 'Qualquer um pode comentar'}]}
                                            value={projectState.googleDocDefaultPermission}
                                            onChange={(e) => setProjectState(prevState => ({...prevState, googleDocDefaultPermission: e.target.value}))}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        {
                            clickedProjectId.value &&
                            <Box my={3}>
                                <Box mb={2}>
                                    <Typography variant="h5">Opções</Typography>
                                </Box>
                                <Box mb={1}>
                                    <Grid container alignItems="center">
                                        <Grid size="grow">
                                            <Switch
                                                label={`Ativar assinatura eletrônica`}
                                                noWrap
                                                checked={projectState.options.eSignature}
                                                onChange={(e) => setProjectState(prevState => ({...prevState, options: {...prevState.options, eSignature: e.target.checked}}))}
                                            />
                                        </Grid>
                                        <Grid>
                                            {
                                                (clickedProjectId.value && folderESignatureSettings.value?.folderId === clickedProjectId.value && folderESignatureSettings.value.enabled && projectState.options.eSignature)
                                                ?
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<CreateIcon />}
                                                    endIcon={<ArrowForwardIcon />}
                                                    onClick={handleChangeSignatureButtonPress}
                                                >
                                                    Assinatura
                                                </Button>
                                                :
                                                projectState.options.eSignature
                                                ?
                                                <FormHelperText error>Clique em SALVAR para acessar configurações adicionais de assinatura eletrônica.</FormHelperText>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center">
                                        <Grid size="grow">
                                            <Switch
                                                label={`Ativar grupos`}
                                                noWrap
                                                checked={projectState.options.groups}
                                                onChange={handleGroupsEnabledChange}
                                            />
                                        </Grid>
                                        <Grid>
                                            {
                                                (clickedProjectId.value && projectGroupSettings.value?.projectId === clickedProjectId.value && projectGroupSettings.value.enabled && projectState.options.groups)
                                                ?
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<PeopleIcon />}
                                                    endIcon={<ArrowForwardIcon />}
                                                    onClick={handleChangeGroupsButtonPress}
                                                >
                                                    Grupos
                                                </Button>
                                                :
                                                projectState.options.groups
                                                ?
                                                <FormHelperText error>Clique em SALVAR para acessar configurações adicionais de grupos.</FormHelperText>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </Grid>
                                    <Switch
                                        label={`Mostrar informações em destaque (favoritadas) em cada documento`}
                                        noWrap
                                        checked={projectState.options.starredFields}
                                        onChange={(e) => setProjectState(prevState => ({...prevState, options: {...prevState.options, starredFields: e.target.checked}}))}
                                    />
                                    <Switch
                                        label={`Mostrar campos adicionais ("Anotações") em cada documento na interface do usuário de pasta`}
                                        noWrap
                                        checked={projectState.options.customFields}
                                        onChange={(e) => setProjectState(prevState => ({...prevState, options: {...prevState.options, customFields: e.target.checked}}))}
                                    />
                                    <Switch
                                        label={`Permitir que o cliente adicione documentos sem formulário`}
                                        noWrap
                                        checked={projectState.options.allowClientToAddDocumentWithoutForm}
                                        onChange={(e) => setProjectState(prevState => ({...prevState, options: {...prevState.options, allowClientToAddDocumentWithoutForm: e.target.checked}}))}
                                    />

                                    <Grid container alignItems="center">
                                        <Grid size="grow">
                                            <Switch
                                                label={`Ativar clearance`}
                                                noWrap
                                                checked={projectState.options.clearance}
                                                onChange={(e) => setProjectState(prevState => ({...prevState, options: {...prevState.options, clearance: e.target.checked}}))}
                                            />
                                        </Grid>
                                        <Grid>
                                        {
                                                (clickedProjectId.value && projectClearanceSettings.value?.projectId === clickedProjectId.value && projectClearanceSettings.value.enabled && projectState.options.clearance)
                                                ?
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    size="small"
                                                    startIcon={<MovieIcon />}
                                                    endIcon={<ArrowForwardIcon />}
                                                    onClick={handleChangeClearanceButtonPress}
                                                >
                                                    Clearance
                                                </Button>
                                                :
                                                projectState.options.clearance
                                                ?
                                                <FormHelperText error>Clique em SALVAR para acessar configurações adicionais de clearance.</FormHelperText>
                                                :
                                                null
                                            }
                                        </Grid>
                                    </Grid>
                                    {
                                        projectTalentSettings.value &&
                                        <Switch
                                            label={`Disponibilizar formulários de talentos`}
                                            noWrap
                                            checked={projectState.options.talent}
                                            onChange={(e) => setProjectState(prevState => ({...prevState, options: {...prevState.options, talent: e.target.checked}}))}
                                        />
                                    }
                                </Box>
                            </Box>
                        }
                        {
                            ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                            <DownloadSignedDocumentsSection projectId={clickedProjectId} />
                        }
                        {
                            // ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                            // <Box my={3}>
                            //     <Box mb={2}>
                            //         <Typography variant="h5">Configurações adicionais</Typography>
                            //     </Box>
                            //     <Box mb={1}>
                            //         <Grid container spacing={1}>
                            //             <Grid size={{ xs: 12, sm: 6 }} container spacing={1} alignItems="flex-end" wrap="nowrap">
                            //                 <Grid size="grow">
                            //                     <Select
                            //                         label="Visibilidade"
                            //                         options={[
                            //                             {value: 'all', label: 'Todos os advogados'},
                            //                             {value: 5, label: 'Apenas para os sócios quotistas'}
                            //                         ]}
                            //                         value={projectState.minimumUserLevel}
                            //                         onChange={(e) => setProjectState(prevState => ({...prevState, minimumUserLevel: e.target.value}))}
                            //                     />
                            //                 </Grid>
                            //             </Grid>
                            //         </Grid>
                            //     </Box>
                            // </Box>
                        }
                        {
                            (selectedProject && ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)) &&
                            <Box>
                                <Box my={3}>
                                    <Box mb={2}>
                                        <Typography variant="h5">Transferir documentos assinados para o Google Drive</Typography>
                                    </Box>
                                    {
                                        selectedProject?.googleDriveSignedDocumentsFolderId &&
                                        <Box mb={1}>
                                            <Typography variant="body1">Pasta criada em {moment(selectedProject.googleDriveSignedDocumentsFolderCreatedAt).format('L LTS')} - <Link
                                                variant="body2"
                                                href={`https://drive.google.com/drive/folders/${selectedProject.googleDriveSignedDocumentsFolderId}`}
                                                target="_blank"
                                                underline="hover">https://drive.google.com/drive/folders/{selectedProject.googleDriveSignedDocumentsFolderId}</Link></Typography>
                                        </Box>
                                    }
                                    
                                    {/* <Box mb={1}>
                                        <Button
                                            variant="outlined" size="small"
                                            startIcon={transferringSignedDocumentsToGoogleDrive && <CircularProgress size={20} />}
                                            onClick={handleTransferSignedDocumentsToGoogleDriveButtonPress}
                                        >Transferir documentos assinados para Google Drive</Button>
                                    </Box> */}
                                </Box>
                            </Box>
                        }
                        {/* <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <ProjectWindowImportSettingsSection setProjectState={setProjectState} setUserCreatedFieldsValues={set_userCreatedFieldsValues} />
                            </Grid>
                        </Grid> */}
                    </Box>
                </Container>
            }

        </Dialog>
    );
}

export default DataDrawer;