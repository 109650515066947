import { useRef, useEffect } from 'react';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';

import User from '../classes/User';
import NoResults from '../components/NoResults';
import { useAppStateCtx } from '../context/AppState';

const classes = {
    icon: {
        backgroundColor: theme => theme.palette.grey[200],
        color: theme => theme.palette.grey[800]
    },
    notification: {
        backgroundColor: '#bbdefb',
        borderRadius: '8px',
        transition: 'background-color 2s ease-out'
    },
    notificationRead: {
        backgroundColor: 'transparent',
    }
};

function Notifications({
    notifications,
    open,
    popOverRef
}){
    useSignals();
    const { activeUser } = useAppStateCtx();
    const itemRefs = useRef({});

    function scrollToBottom(){
        if(itemRefs.current){
            let foundNotification = null;
            notifications.value.forEach(notification => {
                if(!notification.read){
                    if(foundNotification){
                        if(notification.createdAt < foundNotification.createdAt) foundNotification = notification;
                    } else {
                        foundNotification = notification;
                    }
                }
            });
            if(foundNotification){
                const element = itemRefs.current[foundNotification.uid];
                if(element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    useEffect(() => {
        if(open.value){
            scrollToBottom();
        }
    }, [open.value]);

    function handleClose(){
        if(activeUser.value){
            const newValue = new Date().toISOString();
            activeUser.value.updateNotificationsReadAt(newValue);
            activeUser.value = new User({
                ...activeUser.value,
                notificationsReatAt: newValue
            });
        }
        open.value = false;
    }

    const iconsMap = {
        clearance: MovieOutlinedIcon,
        documents: DescriptionOutlinedIcon,
        message: MessageOutlinedIcon,
        task: AssignmentOutlinedIcon
    }

    return (
        <Popover
            PaperProps={{style: {maxWidth: 599}}}
            open={open.value}
            onClose={handleClose}
            anchorEl={popOverRef.current}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            keepMounted
        >
            <Box p={2}>
                <Box mb={2}>
                    <Typography variant="h4">Notificações</Typography>
                </Box>
                {
                    notifications.value.length !== 0
                    ?
                    <List>
                        {
                            notifications.value
                            .sort((a, b) => a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0)
                            .map((notification) => {
                                const Icon = iconsMap[notification.type] || iconsMap.message;
                                return (
                                    <ListItem
                                        key={notification.uid}
                                        ref={el => (itemRefs.current[notification.uid] = el)}
                                        sx={[classes.notification, notification.read && classes.notificationRead]}
                                    >
                                        <ListItemAvatar>
                                            <Avatar sx={classes.icon}>
                                                <Icon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={notification.title || 'Notificação'}
                                            primaryTypographyProps={{variant: 'subtitle2'}}
                                            secondary={<Typography variant="body2">{notification.description} {moment(notification.createdAt).fromNow()}</Typography>}
                                        />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    :
                    <NoResults text={'Você não tem notificações novas.'} />
                }
            </Box>
        </Popover>
    );
}

export default Notifications;