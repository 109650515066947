import { useEffect, useState } from 'react';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import AvatarGroup from '@mui/material/AvatarGroup';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';

import { useAppStateCtx } from '../context/AppState';
import getPresence from '../firebase/realtime_database/getPresence';

const classes = {
    usersItem: {
        backgroundColor: theme => theme.palette.background.paper,
        borderRadius: '4px',
        margin: '0 8px 8px 0',
        padding: '4px 8px',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
        }
    },
    commandsColumn: {
        order: [1, 2],
    },
    peopleColumn: {
        order: [2, 1],
    }
};

const UsersItem = ({user}) => {
    return (
        <Box sx={classes.usersItem}>
            <Grid container spacing={1} alignItems="center">
                <Grid>
                    <Avatar style={{height: 32, width: 32}} alt={user.name} src={user?.photoUrl || undefined} />
                </Grid>
                <Grid size="grow">
                    <Typography variant="body1">{user.name}</Typography>
                </Grid>
                <Grid>
                    <Typography variant="body1">{moment(user.last_changed).format('L LTS')}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
};

const OnlineUsersListPopover = () => {
    useSignals();
    const { onlineClients, onlineOperators, onlineUsersListPopoverAnchorEl, onlineUsersListPopoverOpen, selectedWorkspace } = useAppStateCtx();

    const handleClose = () => {
        onlineUsersListPopoverOpen.value = false;
    };

    return (
        <Popover
            open={onlineUsersListPopoverOpen.value}
            //@ts-ignore
            anchorEl={onlineUsersListPopoverAnchorEl.value}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            slotProps={{ paper: { sx: { padding: '8px', width: '500px' } } }}
        >
            <Box mb={1}>
                <Typography variant="h2">Pessoas conectadas</Typography>
            </Box>
            <Box mb={2}>
                <Typography variant="h4">{selectedWorkspace.value?.shortName.toUpperCase()} ({onlineOperators.value.length})</Typography>
            </Box>
            <Box mb={2}>
                {
                    onlineOperators.value.length === 0 &&
                    <Typography variant="body2">Nenhum</Typography>
                }
                {
                    onlineOperators.value.map((user, index) => (
                        <UsersItem key={index} user={user} />
                    ))
                }
            </Box>
            <Box mb={2}>
                <Typography variant="h4">Clientes ({onlineClients.value.length})</Typography>
            </Box>
            <Box mb={2}>
                {
                    onlineClients.value.length === 0 &&
                    <Typography variant="body2">Nenhum</Typography>
                }
                {
                    onlineClients.value.map((user, index) => (
                        <UsersItem key={index} user={user} />
                    ))
                }
            </Box>
        </Popover>
    );
}

const OnlineUsers = () => {
    useSignals();
    const { activeUser, onlineClients, onlineOperators, onlineUsers, onlineUsersListPopoverAnchorEl, onlineUsersListPopoverOpen, selectedWorkspace, workspaceUsers } = useAppStateCtx();
    
    const [presence, setPresence] = useState(null);

    useEffect(() => {
        if(selectedWorkspace.value?.uid) getPresence(selectedWorkspace.value.uid, setPresence);
    }, [selectedWorkspace.value?.uid]);

    useEffect(() => {
        if(presence && workspaceUsers.value){
            let currentOnlineOperatorsIds = [];
            let currentOnlineOperators = [];
            let currentOnlineClientsIds = [];
            let currentOnlineClients = [];
            for(const area in presence){
                if(area === 'operators'){
                    for(const windowId in presence[area]){
                        const user = presence[area][windowId];
                        const userId = user.userId;
                        if(!currentOnlineOperatorsIds.includes(userId)){
                            currentOnlineOperatorsIds.push(userId);
                            const foundUser = workspaceUsers.value[userId];
                            if(foundUser && foundUser.role !== 'client') currentOnlineOperators.push({...foundUser, last_changed: user.last_changed, type: 'operator', uid: userId});
                        }
                    }    
                } else {
                    for(const clientOrProjectId in presence[area]){
                        for(const windowId in presence[area][clientOrProjectId]){
                            const user = presence[area][clientOrProjectId];
                            const userId = user[windowId].userId;
                            if(!currentOnlineClientsIds.includes(userId)){
                                currentOnlineClientsIds.push(userId);
                                const foundUser = workspaceUsers.value[userId];
                                if(foundUser && foundUser.role === 'client') currentOnlineClients.push({...foundUser, last_changed: user[windowId].last_changed, type: 'client', uid: userId});
                            }
                        }    
                    }
                }
            }
            onlineClients.value = currentOnlineClients;
            onlineOperators.value = currentOnlineOperators;
            onlineUsers.value = [...currentOnlineOperators, ...currentOnlineClients];
        }
    }, [presence, workspaceUsers.value]);

    const handleOnlineUsersClick = (e) => {
        onlineUsersListPopoverAnchorEl.value = e.target;
        onlineUsersListPopoverOpen.value = true;
    };

    if(onlineOperators.value){
        return (
            <>
                <div onClick={handleOnlineUsersClick}>
                    <AvatarGroup max={12} sx={{ cursor: 'pointer' }}>
                        {
                            onlineOperators.value
                            .filter(operator => !!operator && operator.uid !== activeUser.value?.uid)
                            .map((user, index) => (
                                <Avatar
                                    key={index}
                                    style={{ height: 32, width: 32 }}
                                    alt={user?.name}
                                    src={user?.photoUrl}
                                />
                            ))
                        }
                    </AvatarGroup>
                </div>
                <OnlineUsersListPopover />
            </>
        );
    }
    return null;
};

export default OnlineUsers;