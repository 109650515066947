import React, { useEffect, useState } from 'react';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

import { db } from '../../../../../firebase/config';

import DashboardCounter from '../../../../../components/DashboardCounter';

type CountState = number | null;

const getCount = async (workspaceId: string, setCount: React.Dispatch<React.SetStateAction<CountState>>) => {
    const result = await getCountFromServer(query(collection(db, 'documents'), where('workspaceId', '==', workspaceId), where('availableToClient', '==', true)));
    const data = result.data();
    setCount(data.count);
};

const NumberOfSentDocuments = ({ workspaceId }: { workspaceId: string }) => {
    const [count, setCount] = useState<CountState>(null);

    useEffect(() => {
        getCount(workspaceId, setCount);
    }, [workspaceId]);
    
    return (
        <DashboardCounter
            icon={<SendOutlinedIcon />}
            label="contratos enviados até hoje"
            loading={count === null}
        >{count}</DashboardCounter>
    )
}

export default NumberOfSentDocuments;