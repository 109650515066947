import { useEffect, useState } from 'react';
import { and, collection, doc, onSnapshot, or, query, orderBy, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import Task from '../classes/Task';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceTasks = (shouldSetListener) => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const [hookState, setHookState] = useState({ loading: true, data: null, tasksUpdatedAt: '' });

    useEffect(() => {
        if(shouldSetListener && activeUser.value?.uid && activeUserIsOperator.value && selectedWorkspace.value?.uid){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'tasks'),
                    and(
                        where('workspaceId', '==', selectedWorkspace.value.uid),
                        where('deleted', '==', false),
                        where('completed', '==', false),
                        or(
                            where('hidden', '==', false),
                            and(
                                where('hidden', '==', true),
                                where('operator', '==', activeUser.value.uid),
                            )
                            
                        )
                    ),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const currentTask = new Task({ ...data, uid: snapshot.id });
                        dataArray.push(currentTask);
                    });
                    setHookState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: dataArray
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUser.value?.uid, activeUserIsOperator.value, selectedWorkspace.value?.uid]);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.uid){
            const unsubscribe = onSnapshot(
                doc(db, `workspaces/${selectedWorkspace.value?.uid}/real_time/tasks`),
                (snapshot) => {
                    const data = snapshot.data();
                    setHookState(prevState => ({
                        ...prevState,
                        tasksUpdatedAt: data.updatedAt
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value?.uid]);

    return hookState;
};

export default useGetWorkspaceTasks;