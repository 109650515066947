import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
// import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import BigButton from '../../../../components/BigButton';

import { useAppCtxAPI } from '../../../../context/SystemContext';
import { SIDENAV_WIDTH } from '../../../../utils/constants';

const classes = {
    logo: {
        cursor: 'pointer',
    },
    root: theme => ({
        backgroundColor: theme.palette.details,
        height: '100%',
        position: 'fixed',
        top: 0,
        width: `${SIDENAV_WIDTH}px`,
        zIndex: theme.zIndex.drawer,
        
    }),
    rootDesktop: {
        '&:hover .more-navigation': {
            maxWidth: '200px',
            opacity: 1
        }
    },
    moreNavigation: {
        position: 'fixed',
        top: '50px',
        left: '0',
        height: 'calc(100% - 50px)',
        maxWidth: '0',
        opacity: 0,
        padding: '8px 0 8px 0',
        transition: 'max-width .2s ease-out, opacity .4s ease-out',
        overflowX: 'hidden',
        overflowY: 'auto',

        background: 'rgba(255, 255, 255, 0.2)',
        backdropFilter: 'blur(6px)',
        borderRadius: '8px',
        WebkitBackdropFilter: 'blur(6px)',
    }
};

const Img = styled('img')(unstable_styleFunctionSx);

const Navigation = ({dashboardOpen}) => {
    const params = useParams();
    const theme = useTheme();
    const folderId = params.folderId;
    const iconColor = theme.palette.getContrastText(theme.palette.details);
    const pathname = window.location.pathname;
    const { APP_PATH, handleNavigate } = useAppCtxAPI();
    const screenXsOrSmaller = useMediaQuery(theme.breakpoints.down('xs'));

    const clearanceRoutes = [
        {
            path: `/clearance/${folderId}`, regExp: new RegExp(`clearance/${folderId}/?$`),
            highlight: [`/clearance/${folderId}/`],
            icon: (iconColor) => <HelpOutlineIcon htmlColor={iconColor} />,
            label: `Orientações gerais`,
        },
        {
            path: `/clearance/${folderId}/consultas`, regExp: new RegExp(`clearance\/${folderId}\/consultas\/?$`),
            icon: (iconColor) => <QuestionAnswerOutlinedIcon htmlColor={iconColor} />,
            label: `Consultas`
        },
        {
            path: `/clearance/${folderId}/relatorios`, regExp: new RegExp(`clearance\/${folderId}\/relatorios\/?$`),
            icon: (iconColor) => <MovieCreationOutlinedIcon htmlColor={iconColor} />,
            label: `Relatórios`
        },
        // {
        //     path: `/clearance/${folderId}/pesquisa`, regExp: new RegExp(`clearance\/${folderId}\/pesquisa\/?$`),
        //     icon: (iconColor) => <FindInPageOutlinedIcon htmlColor={iconColor} />,
        //     label: `Pesquisa`
        // }
    ];

    const handleRouteClick = (routePath) => {
        handleNavigate(routePath);
    };
    
    return (
        <Box
            sx={[classes.root, !screenXsOrSmaller && classes.rootDesktop]}
        >
            <Box mt={0.5} mb={2} sx={{textAlign: 'center'}}>
                <Img src={'/SOLIDA-50.png'} alt="Solida" sx={classes.logo} onClick={() => window.open(APP_PATH, '_blank')} /> 
            </Box>
            <Slide in={dashboardOpen} direction="right">
                <Box>
                    {
                        clearanceRoutes.map((route) => (
                            <Box key={route.path} mb={2} sx={{textAlign: 'center'}}>
                                {
                                    screenXsOrSmaller
                                    ?
                                    <Box
                                        sx={{ borderLeft: `3px solid ${route.path === pathname ? iconColor : 'transparent'}`, cursor: 'pointer' }}
                                        onClick={() => handleRouteClick(route.path)}
                                    >
                                        {route.icon(iconColor)}
                                    </Box>
                                    : route.icon(iconColor)
                                }
                            </Box>
                        ))
                    }
                    <Box
                        sx={classes.moreNavigation}
                        className="more-navigation"
                    >
                        {
                            clearanceRoutes
                            .map((route) => {
                                return (
                                    <Box key={route.path} ml={1} mb={1}>
                                        <BigButton
                                            active={route.path === pathname}
                                            btn={route}
                                            icon={route.icon()}
                                            label={route.label}
                                            onClick={() => handleRouteClick(route.path)}
                                        />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Slide>
        </Box>
    )
};

export default Navigation;