import { doc, writeBatch } from 'firebase/firestore';

import { db } from '../config';

export default async function batchWrite(updates: { operation: 'delete' | 'set' | 'update'; path: string; updates: { [key: string]: any } }[]) {
    let result = null, error = null;

    const batch = writeBatch(db);

    updates.forEach(update => {
        const ref = doc(db, update.path);
        if(update.operation === 'delete'){
            batch.delete(ref);
        } else if(update.operation === 'set'){
            batch.set(ref, update.updates, { merge: true });
        } else {
            batch.update(ref, update.updates);
        }
    });
    
    try {
        result = await batch.commit();
    } catch (e) {
        error = e;
    }

    return { result, error };
}