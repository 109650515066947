import { useLayoutEffect, useState, useRef } from 'react';
import { Typography } from '@mui/material';
import MultilineText from '../MultilineText';

const ClearanceItemRiskDescriptionText = (props) => {
    const { clearanceItem } = props;
    const [expandRiskDescription, setExpandRiskDescription] = useState(false);
    const [isTextClamped, setTextClamped] = useState(false);
    const [textHeight, setTextHeight] = useState(null);
    const textRef = useRef(null);

    useLayoutEffect(() => {
        if(textRef.current){
            setTextClamped(expandRiskDescription || (textRef.current.scrollHeight > textRef.current.clientHeight));
            setTextHeight(textRef.current.clientHeight);
        }
    }, [clearanceItem.riskDescription]);

    const handleRiskDescriptionClick = (e) => {
        if(isTextClamped){
            e.stopPropagation();
            setExpandRiskDescription(!expandRiskDescription);
        }
    }

    const maxHeight = textHeight ? { maxHeight: textHeight } : {};

    const clickableStyles = isTextClamped
        ?
        {
            borderRadius: 2,
            padding: '0 8px',
            ...maxHeight,
            transition: 'background-color, max-height .2s ease-out',
            '&:hover': {
                backgroundColor: '#fff9c4'
            }
        }
        :
        { padding: '0 8px' };

    return (
        <Typography
            ref={textRef}
            variant="body2"
            sx={
                expandRiskDescription
                ?
                {...clickableStyles, maxHeight: '100em'}
                :
                {
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    ...clickableStyles
                }
            }
            onClick={handleRiskDescriptionClick}
        >{clearanceItem.licensed ? '' : <MultilineText text={clearanceItem.riskDescription} />}</Typography>
    );

};

export default ClearanceItemRiskDescriptionText;