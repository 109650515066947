import { useEffect, useLayoutEffect, useState } from 'react';
import moment from 'moment';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChangeTemplateView from './components/TemplateWindow';
import FilterInput from '../../../../components/FilterInput';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import Pagination from '../../../../components/Pagination';
import TinyTable from '../../../../components/TinyTable';
import ViewBox from '../../../../components/ViewBox';
import DocumentsTemplate from '../../../../classes/DocumentsTemplate';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { useOperatorTemplatesCtxAPI } from './context/TemplatesContext';
import { escapeRegex, removeDiacritics } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const filterText = signal('');

const FilterText = () => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value])

    return (
        <FilterInput value={value} setValue={setValue} placeholder="Digite para filtrar" />
    );
};

const Pages = ({ count, currentPage, perPage, handlePageClick }) => {
    if(filterText.value.length === 0){
        return (
            <Pagination count={count} page={currentPage} perPage={perPage} onChange={(_, page) => handlePageClick(page)} />
        );
    }
    return null;
};

const Main = () => {
    useSignals();
    const { selectedWorkspace, workspaceDocumentsTemplates } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { handleNavigate } = useAppCtxAPI();
    const { showChangeTemplateView, showAddTemplateView } = useOperatorTemplatesCtxAPI();
    // const [searchType, setSearchType] = useState('default');
    const [loading, setLoading] = useState(true);
    const [availableTemplates, setAvailableTemplates] = useState(null);
    const [visibleTemplates, setVisibleTemplates] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setFirestoreListener('workspaceDocumentsTemplates');
    }, []);

    useEffect(() => {
        if(workspaceDocumentsTemplates.value){
            const currentTemplates = Object.entries(workspaceDocumentsTemplates.value).map(([templateId, template]) => {
                const currentTemplate = new DocumentsTemplate({...template, uid: templateId});
                return currentTemplate;
            })
            // .filter(template => {
            //     if(searchType === 'disabled') return template.disabled;
            //     return !template.disabled;
            // })
            .sort((a, b) => {
                let itemA = removeDiacritics(a.name.toLowerCase());
                let itemB = removeDiacritics(b.name.toLowerCase());
                if(itemA > itemB){
                    return 1;
                } else if(itemB > itemA){
                    return -1;
                } else {
                    itemA = removeDiacritics(a.description.toLowerCase());
                    itemB = removeDiacritics(b.description.toLowerCase());
                    return itemA > itemB ? 1 : itemB > itemA ? -1 : 0;
                }
            });
            setAvailableTemplates(currentTemplates);
        }
    }, [
        // searchType,
        workspaceDocumentsTemplates.value
    ]);

    useEffect(() => {
        if(workspaceDocumentsTemplates.value && visibleTemplates){
            setLoading(false);
        }
    }, [workspaceDocumentsTemplates.value, visibleTemplates]);

    const perPage = 100;

    const showPage = () => {
        if(availableTemplates){
            const firstIndex = (currentPage - 1) * perPage;
            const lastIndex = (currentPage * perPage);
            const pageArray = availableTemplates.slice(firstIndex, lastIndex);
            setVisibleTemplates(pageArray);
        }
    }

    useLayoutEffect(() => {
        showPage();
    }, [availableTemplates, currentPage]);

    useLayoutEffect(() => {
        if(availableTemplates && filterText.value.length !== 0){
            setVisibleTemplates(
                availableTemplates.filter(template => {
                    const textFields = removeDiacritics(`${template.name}${template.description}`);
                    if(filterText.value.length === 1){
                        const regEx = new RegExp(`^${escapeRegex(removeDiacritics(filterText.value)).toUpperCase()}`);
                        return regEx.test(textFields.toUpperCase());
                    }
                    return textFields.toUpperCase().indexOf(removeDiacritics(filterText.value).toUpperCase()) !== -1;
                })
            )
        } else {
            showPage();
        }
    }, [filterText.value]);

    const handleListsClick = () => {
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes/listas`);
    };

    const handleTemplateRowClick = (clickedTemplate) => {
        showChangeTemplateView(clickedTemplate.data);
    };

    const handleAddTemplateButtonClick = () => {
        showAddTemplateView();
    };

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <ViewBox additionalSx={[{
                // height: [`calc(100% - ${86}px)`, `calc(100% - ${86}px)`, `calc(100% - ${110.4}px)`]
                height: '100%'
            }]}>

            <Box mb={2}>
                <Grid container spacing={1} justifyContent="flex-end" alignContent="flex-end">
                    <Grid size="grow">
                        <FilterText />
                    </Grid>
                    <Grid size={{ xs: 6 }}></Grid>
                    <Grid>
                        <Button variant="contained" onClick={handleAddTemplateButtonClick} startIcon={<AddIcon />}>Nova matriz</Button>
                    </Grid>
                    <Grid>
                        <Button variant="outlined" onClick={handleListsClick} endIcon={<ArrowForwardIcon />}>Listas</Button>
                    </Grid>
                </Grid>
            </Box>

            {
                loading || !availableTemplates
                ? <LoaderEllipsis />
                :
                <>
                    <Pages count={availableTemplates.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    <Box pb={5}>
                        <Container maxWidth="lg">
                            <TinyTable
                                head={[
                                    { content: <Typography variant="body1">Nome</Typography> },
                                    { content: <Typography variant="body1">Descrição</Typography> },
                                    { content: <Typography variant="body1">Última atualização</Typography> }
                                ]}
                                body={
                                    visibleTemplates
                                    .sort(sortByKey('name'))
                                    .map((mappedTemplate) => {
                                        const columns = [
                                            { content: <Typography variant="body2">{mappedTemplate.name}</Typography> },
                                            { content: <Typography variant="body2">{mappedTemplate.description}</Typography> },
                                            { content: <Typography variant="body2">{moment(mappedTemplate.updatedAt).format('L LTS')}</Typography> }
                                        ];
                                        return ({
                                            data: mappedTemplate,
                                            columns
                                        })
                                    })
                                }
                                handleBodyRow={handleTemplateRowClick}
                            />
                        </Container>
                        <Pages count={availableTemplates.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    </Box>
                </>
            }
            
            <ChangeTemplateView />

        </ViewBox>
    );
}

export default Main;