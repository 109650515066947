import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AttachmentIcon from '@mui/icons-material/Attachment';
import DescriptionIcon from '@mui/icons-material/Description';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';

import AddOrSubtractDial from './AddOrSubtractDial';
import Window from './Window';

import { useAppStateCtx } from '../context/AppState';
import { useDocumentsAPI, useDocumentsCtxSubmitDocumentView } from '../context/DocumentsContext';
import { useAppCtxLoading } from '../context/SystemContext';
import getDoc from '../firebase/firestore/getDoc';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const SubmitDocDialog = ({setSaving, actionConfirmCallback, actionDoneCallback}) => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const loading = useAppCtxLoading();
    const { hideSubmitDocumentView } = useDocumentsAPI();
    const { submitDocumentViewOpen, submitDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxSubmitDocumentView();
    const [loadingPage, setLoadingPage] = useState(true);
    const [documentName, set_documentName] = useState('');
    const [minimumNumberOfMinutesRequiredForDocumentDelivery, set_minimumNumberOfMinutesRequiredForDocumentDelivery] = useState(15);
    const [numberOfMinutesTakenForDocumentDelivery, set_numberOfMinutesTakenForDocumentDelivery] = useState(15);
    const [isStaticDocument, set_isStaticDocument] = useState(false);
    const [staticFileReviewUserWillProvideUrl, setStaticFileReviewUserWillProvideUrl] = useState(false);
    const [staticFileReviewUrlProvidedByUser, setStaticFileReviewUrlProvidedByUser] = useState('');
    const [customText, set_customText] = useState('');
    const [filesList, set_filesList] = useState([]);
    const [selectedDocumentTemplateIdIsReview, setSelectedDocumentTemplateIdIsReview] = useState(false);

    useEffect(() => {
        if(submitDocumentViewOpen){
            set_customText('');
            if(selectedDocument){
                set_documentName(selectedDocument.name);

                let currentIsStaticDocument = false;
                
                const currentSelectedDocumentTemplateIdIsReview = selectedDocument.templateId === 'review';
                setSelectedDocumentTemplateIdIsReview(currentSelectedDocumentTemplateIdIsReview);
                
                if(!currentSelectedDocumentTemplateIdIsReview){
                    currentIsStaticDocument = selectedDocument.lastVersion && selectedDocument.lastVersion.fileFormat !== 'googleDoc';
                } else {
                    currentIsStaticDocument = true; 
                }
                set_isStaticDocument(currentIsStaticDocument);
                
                set_minimumNumberOfMinutesRequiredForDocumentDelivery(15);
                set_numberOfMinutesTakenForDocumentDelivery(15);
            }
        }
    }, [submitDocumentViewOpen, selectedDocument]);

    useEffect(() => {
        if(selectedDocument?.templateId){
                
            const fetchData = async () => {
                setLoadingPage(true);
                const getTemplateSettingsRes = await getDoc(`documents_templates/${selectedDocument.templateId}/_more/settings`);
                if(getTemplateSettingsRes.error){

                }
                
                if(getTemplateSettingsRes.result?.averageNumberOfMinutesRequiredForDocumentDelivery){
                    set_minimumNumberOfMinutesRequiredForDocumentDelivery(getTemplateSettingsRes.result.averageNumberOfMinutesRequiredForDocumentDelivery);
                    set_numberOfMinutesTakenForDocumentDelivery(getTemplateSettingsRes.result.averageNumberOfMinutesRequiredForDocumentDelivery);
                }
                
                setLoadingPage(false);
            };

            fetchData();
        }
    }, [selectedDocument]);

    const handleSubmitButtonClick = async () => {
        if(actionConfirmCallback) actionConfirmCallback();
        if(setSaving) setSaving(true);
        const toastId = toast.loading('Enviando...');
        handleClose();

        let staticFileReviewFilesProvidedByUser = null;
        const filesInput = document.getElementById('reviewFile');
        if(filesInput) staticFileReviewFilesProvidedByUser = filesInput.files;

        const res = await selectedDocument.makeAvailableToClient({
            activeUser: activeUser.value,
            comment: customText || '',
            documentName,
            minutesSpentInOperatorReview: numberOfMinutesTakenForDocumentDelivery,
            selectedWorkspace: selectedWorkspace.value,
            staticFileReviewFilesProvidedByUser: !staticFileReviewUserWillProvideUrl && staticFileReviewFilesProvidedByUser && staticFileReviewFilesProvidedByUser.length !== 0 ? staticFileReviewFilesProvidedByUser : null,
            staticFileReviewUrlProvidedByUser: staticFileReviewUserWillProvideUrl ? staticFileReviewUrlProvidedByUser : ''
        });

        if(res.error){
            if(setSaving) setSaving(false);
            return toast.update(toastId, { autoClose: 5000, render: ERROR_MESSAGE_UNKNOWN, type: 'error', isLoading: false });
        }

        if(actionDoneCallback) actionDoneCallback(res.result);

        if(setSaving) setSaving(false);
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: `O documento foi enviado.`, type: 'success' });
    };

    const triggerFileInputClick = () => {
        const fileInput = document.getElementById('reviewFile');
        if(fileInput) fileInput.click();
    }
    const handleFileInputChange = (e) => {
        const el = e.target;
        let files = el.files;
        if(files && files.length >= 1){
            let list = [];
            for (let file of files) {
                let fileName = file.name ? file.name : 'NOT SUPPORTED';
                list.push(fileName);
            }
            set_filesList(list);
        } else {
            set_filesList([]);
        }
    };

    const handleClose = () => {
        hideSubmitDocumentView();
    };
    
    return (
        <Window
            open={submitDocumentViewOpen} onClose={handleClose}
            title="Enviar documento"
            subTitle={selectedDocument?.name}
            subTitleIcon={<DescriptionIcon />}
            actionLoading={loadingPage}
            handleSubmitButtonClick={handleSubmitButtonClick}
            submitButtonText="Enviar"
            submitButtonStartIcon={<SendIcon />}
            submitButtonDisabled={!documentName || loading}
        >
            <Box mb={2}>
                <Typography variant="h6">Você quer disponibilizar o documento para o(a) solicitante?</Typography>
            </Box>
            {
                isStaticDocument &&
                <>
                    {
                        staticFileReviewUserWillProvideUrl
                        ?
                        <Box mb={2}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="Link do documento revisado"
                                value={staticFileReviewUrlProvidedByUser}
                                onChange={(e) => setStaticFileReviewUrlProvidedByUser(e.target.value)} />
                        </Box>
                        :
                        <Box style={{textAlign: 'center'}} mb={2}>
                            <Button variant="text" onClick={triggerFileInputClick}>Escolher arquivo</Button>
                            <input
                                style={{display: 'none'}}
                                id="reviewFile" type="file"
                                accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                onChange={handleFileInputChange}
                            />
                        </Box>
                    }
                    {
                        (!staticFileReviewUserWillProvideUrl && filesList.length >= 1) &&
                        <Box mb={2}>
                            <Box mt={1} style={{display: 'flex', alignItems: 'center', backgroundColor: '#e8e8e8', borderRadius: '8px'}}>
                                <Box pl={1}>
                                    <AttachmentIcon />
                                </Box>
                                <div style={{flex: 1}}>
                                    <ul>
                                        {
                                            filesList.map((fileListItem, index) => (
                                                <li key={index}>{fileListItem}</li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Box>
                        </Box>
                    }
                    <Box mb={2} style={{textAlign: 'center'}}>
                        <Typography variant="body2" sx={{
                            color: theme => theme.palette.primary.main,
                            cursor: 'pointer',
                            '&:hover': {
                                textDecoration: 'underline'
                            }}}
                        onClick={(e) => setStaticFileReviewUserWillProvideUrl(!staticFileReviewUserWillProvideUrl)}>Quero enviar um {staticFileReviewUserWillProvideUrl ? 'arquivo' : 'link'}</Typography>
                    </Box>
                </>
            }
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    multiline
                    label="Comentários, se houver"
                    value={customText}
                    onChange={(e) => set_customText(e.target.value)} />
            </Box>
            <Box mb={2}>
                <TextField
                    variant="standard"
                    fullWidth
                    label="Renomear"
                    value={documentName}
                    onChange={(e) => set_documentName(e.target.value)} />
            </Box>
            {
                (selectedWorkspace.value?.modules?.includes('timesheet')) &&
                <>
                {
                    selectedDocumentTemplateIdIsReview
                    ?
                    <Box mb={3}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <WarningIcon />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body1">Entregar uma revisão não lança automaticamente as respectivas horas no relatório do cliente.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Box mb={3}>
                        <Typography variant="h6">Você levou mais que {minimumNumberOfMinutesRequiredForDocumentDelivery} minutos para revisar o contrato?</Typography>
                        <AddOrSubtractDial
                            subtractDisabled={!numberOfMinutesTakenForDocumentDelivery || numberOfMinutesTakenForDocumentDelivery === 15 || numberOfMinutesTakenForDocumentDelivery <= minimumNumberOfMinutesRequiredForDocumentDelivery}
                            subtractOnClick={() => set_numberOfMinutesTakenForDocumentDelivery(prevState => prevState - 15)}
                            number={numberOfMinutesTakenForDocumentDelivery || 15}
                            addOnClick={() => set_numberOfMinutesTakenForDocumentDelivery(prevState => prevState + 15)}
                        />
                    </Box>
                }
                </>
            }
        </Window>
    );
}

export default SubmitDocDialog;