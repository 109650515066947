import FormQuestionIndividualId from '../../../../components/FormQuestionIndividualId';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const IndividualIdQuestion = ({question, setQuestionValid}) => {
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'individualId',
        setQuestionValid,
    });

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionIndividualId
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
        />
    );
};

export default IndividualIdQuestion;