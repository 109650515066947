import ClearanceQuestionsListPage from '../../../components/clearance/ClearanceQuestionsListPage';

const Questions = ({
    initialProjectClearanceSettings,
    initialProjectGroupSettings,
    restrictedToProject
}) => {
    return (
        <ClearanceQuestionsListPage
            initialProjectClearanceSettings={initialProjectClearanceSettings}
            initialProjectGroupSettings={initialProjectGroupSettings}
            projects={[restrictedToProject]}
            restrictedToProject={restrictedToProject}
        />
    );
};

export default Questions;