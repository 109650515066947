import { useEffect, useRef, useState } from 'react';
import { collection, documentId, limit as _limit, onSnapshot, query, orderBy as _orderBy, startAfter as _startAfter, startAt as _startAt, where, orderBy } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import Document from '../classes/Document';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';
import _getDocsCount from '../firebase/firestore/getDocsCount';
import callFunction from '../firebase/functions/callFunction';

const useGetDocumentsAsClient = (shouldSetListener, documentsListenerSettings) => {
    useSignals();
    const { activeUserGroups, activeUserIsGroupsManager, projectGroupSettings, selectedFolder, selectedWorkspace } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: [], count: 0 };
    const [hookState, setHookState] = useState(defaultHookState);
    const firstSnapshot = useRef(null);
    const lastSnapshot = useRef(null);
    const prevFirstSnapshot = useRef(null);

    const searchDocuments = async () => {
        let deleted = false;
        let projectId = selectedFolder.value.uid;
        let availableToClient, clientId, createdAt, createdBy, flagType, groupId, lastVersionESignatureStatus, madeAvailableToClientAt, madeAvailableToClientBy, operatorId, templateId, workspaceId;
        if(!projectGroupSettings.value?.enabled || activeUserIsGroupsManager.value){
            if(documentsListenerSettings.groupId !== 'all'){
                groupId = [documentsListenerSettings.groupId];
            }
        } else if(projectGroupSettings.value?.enabled && !activeUserIsGroupsManager.value && activeUserGroups.value) {
            let queryGroupIds = activeUserGroups.value.map(group => group.id);
            if(documentsListenerSettings.groupId !== 'all'){
                if(queryGroupIds.includes(documentsListenerSettings.groupId)){
                    queryGroupIds = [documentsListenerSettings.groupId];
                } else {
                    queryGroupIds = ['ACTIVE USER NOT IN SELECTED GROUP'];
                }
            }
            if(queryGroupIds.length === 0) queryGroupIds = [''];
            groupId = queryGroupIds;
        }
        if(documentsListenerSettings.createdBy !== 'all'){
            createdBy = documentsListenerSettings.createdBy
        }
        if(documentsListenerSettings.status === 'pending'){
            availableToClient = false;
        } else if(documentsListenerSettings.status === 'sent'){
            availableToClient = true;
        } else if(documentsListenerSettings.status === 'flag1'){
            flagType = 'operator';
        } else if(documentsListenerSettings.status === 'flag2'){
            flagType = 'client';
        }
        if(documentsListenerSettings.templateId !== 'all'){
            templateId = documentsListenerSettings.templateId;
        }
        if(documentsListenerSettings.signatureStatus === 'pending'){
            lastVersionESignatureStatus = 'pending';
        } else if(documentsListenerSettings.signatureStatus === 'signed'){
            lastVersionESignatureStatus = 'signed';
        }
        const res = await callFunction('searchDocuments', {
            projectId,

            availableToClient,
            clientId,
            createdAt,
            createdBy,
            deleted,
            flagType,
            groupId,
            lastVersionESignatureStatus,
            madeAvailableToClientAt,
            madeAvailableToClientBy,
            operatorId,
            templateId,
            text: documentsListenerSettings.text,
            workspaceId,
        })
        if(res.result.length !== 0){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'documents'),
                    where(documentId(), 'in', res.result.map(foundDocument => foundDocument.documentId)),
                    orderBy('createdAt', 'desc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newDocument = new Document({...data, uid: snapshot.id});
                        dataArray.push(newDocument);
                    });
                    setHookState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: dataArray,
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
        setHookState(prevState => ({
            ...prevState,
            loading: false,
            data: [],
        }));
    }

    const getDocsCount = async (queryConstraints) => {
        const res = await _getDocsCount(`documents`, { queryConstraints });
        if(res.error){
            console.error(res.error);
        }
        setHookState(prevState => ({...prevState, count: res.result}));
    };

    useEffect(() => {
        if(shouldSetListener && selectedWorkspace.value && projectGroupSettings.value && documentsListenerSettings){
            setHookState(defaultHookState);
            if(documentsListenerSettings.text){
                searchDocuments();
            } else {
                const queryConstraints = [
                    where('deleted', '==', false),
                    where('workspaceId', '==', selectedWorkspace.value.uid),
                ];
        
                let orderBy = ['createdAt', 'desc'];
                let startAfter = null;
                let startAt = null;
                let limit = 25;
    
                if(selectedFolderIsClient.value){
                    if(documentsListenerSettings.projectId){
                        queryConstraints.push(where('projectId', '==', documentsListenerSettings.projectId));
                    } else {
                        queryConstraints.push(where('clientId', '==', selectedFolder.value.uid));
                    }
                } else {
                    queryConstraints.push(where('projectId', '==', selectedFolder.value.uid));
                }
    
                if(!projectGroupSettings.value?.enabled || activeUserIsGroupsManager.value){
                    if(documentsListenerSettings.groupId !== 'all'){
                        queryConstraints.push(where('groupId', '==', documentsListenerSettings.groupId));
                    }
                } else if(projectGroupSettings.value?.enabled && !activeUserIsGroupsManager.value && activeUserGroups.value) {
                    let queryGroupIds = activeUserGroups.value.map(group => group.id);
                    if(documentsListenerSettings.groupId !== 'all'){
                        if(queryGroupIds.includes(documentsListenerSettings.groupId)){
                            queryGroupIds = [documentsListenerSettings.groupId];
                        } else {
                            queryGroupIds = ['ACTIVE USER NOT IN SELECTED GROUP'];
                        }
                    }
                    if(queryGroupIds.length === 0) queryGroupIds = [''];
                    queryConstraints.push(where('groupId', 'in', queryGroupIds))
                }
    
                if(documentsListenerSettings.createdBy !== 'all'){
                    queryConstraints.push(where('createdBy', '==', documentsListenerSettings.createdBy));
                }
    
                if(documentsListenerSettings.templateId !== 'all'){
                    queryConstraints.push(where('templateId', '==', documentsListenerSettings.templateId));
                }
    
                if(documentsListenerSettings.status === 'pending'){
                    queryConstraints.push(where('availableToClient', '==', false));
                } else if(documentsListenerSettings.status === 'sent'){
                    queryConstraints.push(where('availableToClient', '==', true));
                    // queryConstraints.push(where('awaitingOperatorReview', '==', false));
                } else if(documentsListenerSettings.status === 'flag1'){
                    queryConstraints.push(where('flag.type', '==', 'operator'));
                } else if(documentsListenerSettings.status === 'flag2'){
                    queryConstraints.push(where('flag.type', '==', 'client'));
                }
                
                if(documentsListenerSettings.signatureStatus === 'pending'){
                    queryConstraints.push(where('lastVersion.eSignature.status', '!=', 'signed'));
                } else if(documentsListenerSettings.signatureStatus === 'signed'){
                    queryConstraints.push(where('lastVersion.eSignature.status', '==', 'signed'));
                }
    
                // if(documentsListenerSettings.text){
                //     queryConstraints.push(where('keywords', 'array-contains', toLowerCaseWithoutSpecialCharacters(documentsListenerSettings.text)));
                // }
    
                if(documentsListenerSettings.page !== 1){
                    if(documentsListenerSettings.action === 'nextPage'){
                        startAfter = lastSnapshot.current;
                    } else if(documentsListenerSettings.action === 'prevPage') {
                        startAt = prevFirstSnapshot.current;
                    }
                }
    
                getDocsCount(queryConstraints);
        
                queryConstraints.push(_orderBy(...orderBy));
                
                if(startAfter) queryConstraints.push(_startAfter(startAfter));
                if(startAt) queryConstraints.push(_startAt(startAt));
        
                queryConstraints.push(_limit(limit));
                const unsubscribe = onSnapshot(
                    query(
                        collection(db, 'documents'),
                        ...queryConstraints
                    ),
                    (snapshots) => {
                        const dataArray = [];
                        snapshots.forEach(snapshot => {
                            const data = snapshot.data();
                            const newDocument = new Document({...data, uid: snapshot.id});
                            dataArray.push(newDocument);
                        });
                        if(snapshots.docs){
                            prevFirstSnapshot.current = firstSnapshot.current;
                            firstSnapshot.current = snapshots.docs[0];
                            lastSnapshot.current = snapshots.docs[snapshots.docs.length - 1];
                        }
                        setHookState(prevState => ({
                            ...prevState,
                            loading: false,
                            data: dataArray,
                        }));
                    },
                    (error) => {
                        console.error(error.message);
                    }
                );
                return () => unsubscribe();
            }
        }
    }, [
        shouldSetListener,
        selectedWorkspace.value?.uid,
        projectGroupSettings.value?.enabled,
        activeUserGroups.value,
        activeUserIsGroupsManager.value,
        documentsListenerSettings
    ]);

    return hookState;
};

export default useGetDocumentsAsClient;