import React, { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DetailedFilesList from '../DetailedFilesList';
import RiskLevel from './RiskLevel';
import SectionTitle from '../SectionTitle';
import ViewBox from '../ViewBox';

import { useAppStateCtx } from '../../context/AppState';
import { getUserNameById } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS } from '../../utils/constants';

const RiskApprovalRequired = ({level, settings}) => {
    if(settings[level]){
        const riskLevel = CLEARANCE_RISK_OPTIONS.find(option => option.value === level);
        const numberOfApprovals = settings[level];
        return (
            <Box>
                <Typography variant="body1"><span style={{color: riskLevel.color}}>&#11044;</span>&nbsp;{riskLevel.label} &rarr; {numberOfApprovals} aprovaç{numberOfApprovals === 1 ? 'ão' : 'ões'} exigida{numberOfApprovals === 1 ? '' : 's'}</Typography>
            </Box>
        );
    }
    return null;
};

const ClearanceGuidelines = ({ folderClearanceInformation }) => {
    useSignals();
    const { projectGroupSettings, workspaceUsers } = useAppStateCtx();
    const theme = useTheme();
    const [showFiles, setShowFiles] = useState(false);
    const [files, setFiles] = useState(false);
    const [approvalsEnabled, setApprovalsEnabled] = useState(false);
    const screenXsOrSmaller = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(folderClearanceInformation){
            let currentFiles = null;
            if(folderClearanceInformation.guidelinesUrl){
                currentFiles = [
                    {
                        name: 'Diretrizes gerais de clearance',
                        path: folderClearanceInformation.guidelinesUrl,
                        format: 'googleDoc'
                    }
                ]
            }
            if(folderClearanceInformation.files){
                if(!currentFiles) currentFiles = [];
                for(let fileId in folderClearanceInformation.files){
                    const file = folderClearanceInformation.files[fileId];
                    currentFiles.push({
                        name: file.name,
                        path: file.url || file.path,
                        format: file.format
                    })
                }
                setFiles(currentFiles);
                setShowFiles(currentFiles.length !== 0);
            }
            let approvalsRequired = false;
            if(folderClearanceInformation.approvals.approvalsRequired){
                for(const level in folderClearanceInformation.approvals.approvalsRequired){
                    if(folderClearanceInformation.approvals.approvalsRequired[level]){
                        approvalsRequired = true;
                        break;
                    }
                }
            }
            setApprovalsEnabled(approvalsRequired);
        }
    }, [folderClearanceInformation]);
    return (
        <ViewBox>
            <Box mb={3}>
                <SectionTitle title={`Níveis de risco`} />
                <Box mb={2}>
                    {
                        CLEARANCE_RISK_OPTIONS.map(riskLevel => (
                            <React.Fragment key={riskLevel.value}>
                                <Grid container spacing={1} alignItems="center" wrap={screenXsOrSmaller ? undefined : 'nowrap'}>
                                    <Grid item xs={12} sm="auto">
                                        <RiskLevel riskLevel={{label: riskLevel.label, color: riskLevel.color}} textVariant="body1" />
                                    </Grid>
                                    {
                                        !screenXsOrSmaller &&
                                        <Grid item>
                                            <Typography variant="body1">&rarr;</Typography>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Typography variant="body1">{riskLevel.description}</Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </React.Fragment>
                        ))
                    }
                </Box>
            </Box>

            {
                folderClearanceInformation &&
                <Box>
                    {
                        approvalsEnabled &&
                        <Box mb={3}>
                            <SectionTitle title="Aprovações" />
                            <Box mb={2}>
                                <RiskApprovalRequired level="one" settings={folderClearanceInformation.approvals.approvalsRequired} />
                                <RiskApprovalRequired level="two" settings={folderClearanceInformation.approvals.approvalsRequired} />
                                <RiskApprovalRequired level="three" settings={folderClearanceInformation.approvals.approvalsRequired} />
                                <RiskApprovalRequired level="four" settings={folderClearanceInformation.approvals.approvalsRequired} />
                            </Box>
                            {
                                folderClearanceInformation.approvals.authorities &&
                                <Box mb={2}>
                                    <Box mb={1}>
                                        <Typography variant="h6">Autoridades que podem aprovar riscos</Typography>
                                    </Box>
                                    {
                                        folderClearanceInformation.approvals.authorities.map((authority) => {
                                            const riskLevel = CLEARANCE_RISK_OPTIONS.find(option => option.value === authority.maximumRiskLevel);
                                            let authorityName = '';
                                            if(authority.type === 'group'){
                                                const foundGroup = projectGroupSettings.value.groups[authority.authorityId];
                                                if(foundGroup) authorityName = `Grupo ${foundGroup.name}`;
                                            } else {
                                                authorityName = getUserNameById(workspaceUsers.value, authority.authorityId);
                                            }
                                            return (
                                                <React.Fragment key={authority.authorityId}>
                                                    <Box>
                                                        <Grid container spacing={1} alignItems="flex-end">
                                                            <Grid item>
                                                                <Typography variant="body1">&bull; {authorityName} aprova anotações com risco <span style={{color: riskLevel.color}}>&#11044;</span>&nbsp;{riskLevel.label} ou menor</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Box>
                            }
                        </Box>
                    }
                    {
                        showFiles &&
                        <Box mb={3}>
                            <SectionTitle title="Arquivos" />
                            <Box mb={2}>
                                <DetailedFilesList list={files} />
                            </Box>
                        </Box>
                    }
                </Box>
            }
        </ViewBox>
    );
};

export default ClearanceGuidelines;