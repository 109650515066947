import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import FloatingNotification from './FloatingNotification';

const classes = {
    root: {
        padding: [`8px 8px 8px 8px`, '8px 16px 8px 16px', `16px 24px 16px 24px`],
    },
};

const ViewBox = ({
    additionalSx = [],
    children,
    TitleIcon,
    id,
    message,
    style,
    title
}: {
    additionalSx?: any;
    children?: any;
    TitleIcon?: any;
    id?: any;
    message?: any;
    style?: any;
    title?: string;
}) => {
    
    return (
        <>
            {
                title && 
                <AppBar position="static" color="transparent" sx={{ mb: 2 }}>
                    <Toolbar>
                        {
                            !!TitleIcon &&
                            <TitleIcon sx={{ mr: 1 }} fontSize="small" />
                        }
                        <Typography variant="h3">{title}</Typography>
                    </Toolbar>
                </AppBar>
            }
            <Box
                id={id}
                sx={[classes.root, ...additionalSx]}
                style={style}
            >
                {
                    message &&
                    <FloatingNotification notification={message} />
                }
                {children}
            </Box>
        </>
    );
};

export default ViewBox;