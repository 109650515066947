import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { textTransform, unstable_styleFunctionSx } from '@mui/system';
import BigButton from '../components/BigButton';
import Tooltip from '../components/Tooltip';
import { useAppCtxAPI } from '../context/SystemContext';

const classes = {
    navBox: {
        color: '#b2bfdc',
        cursor: 'pointer',
        borderLeft: '2px solid #30426a',
        height: '60px',
        margin: '2px 0',
        position: 'relative',
        textAlign: 'center',
        transition: 'border-left .2s ease-out, color .2s ease-out',
        '&:hover, &.active': {
            borderLeft: '2px solid #fbfbfc',
            color: '#fbfbfc',
        },
        '&:hover .more-navigation': {
            maxWidth: '200px',
            opacity: 1
        }
    },
    navBoxActive: {
        borderLeft: '2px solid #fbfbfc',
        color: '#fbfbfc',
        cursor: 'pointer',
    },
    link: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        transition: 'color .2s ease-out',
        '&:active, &:visited': {
            color: '#b2bfdc'
        }
    },
    label: {
        display: 'block',
        fontFamily: `"Titillium Web", sans-serif`,
        fontSize: 10,
        lineHeight: 1,
    },
};

const A = styled('a')(unstable_styleFunctionSx);

const Span = styled('span')(unstable_styleFunctionSx);

function MenuBtn({highlights, path, label, icon, badge, tooltip, children, onClick, onClickAdditionalAction, routes, willNavigateAction}){
    const { handleNavigate } = useAppCtxAPI();
    
    const handleClick = () => {
        if(onClick){
            onClick();
        } else {
            handleNavigate(path, onClickAdditionalAction, willNavigateAction);
        }
    };

    const handleSecondaryButtonClick = (e, secondaryButtonPath) => {
        e.stopPropagation();
        handleNavigate(secondaryButtonPath);
    };

    const pathname = window.location.pathname;

    const getShouldHighlight = () => {
        if(highlights){
            const highlightsTest = new RegExp(highlights.join('|'));
            return highlightsTest.test(pathname);
        }
        return false;
    };

    return (
        <Box sx={[classes.navBox, getShouldHighlight() && classes.navBoxActive]} onClick={handleClick}>
            <Tooltip text={tooltip} placement="right">
                <A sx={classes.link}>
                    {
                        badge
                        ?
                        <>
                            <Badge badgeContent={children} color="secondary"
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                max={999}
                            >
                                {icon}
                            </Badge>
                            <Span sx={classes.label}>{label}</Span>
                        </>
                        :
                        <>
                            {icon}
                            <Span sx={classes.label}>{label}</Span>
                        </>
                    }
                </A>
            </Tooltip>
            {
                routes &&
                <Box
                    sx={{
                        position: 'fixed',
                        top: '50px',
                        left: '52px',
                        height: 'calc(100% - 50px)',
                        maxWidth: '0',
                        opacity: 0,
                        padding: '8px 0 8px 0',
                        transition: 'max-width .2s ease-out, opacity .4s ease-out',
                        overflowX: 'hidden',
                        overflowY: 'auto',

                        /* From https://css.glass */
                        background: 'rgba(255, 255, 255, 0.2)',
                        backdropFilter: 'blur(6px)',
                        borderRadius: '8px',
                        WebkitBackdropFilter: 'blur(6px)',
                    }}
                    className="more-navigation"
                >
                    {
                        routes
                        .filter(i => (i.visible === undefined || i.visible)) 
                        .map((btn, index) => {
                            const views = btn.highlight ? [btn.path, ...btn.highlight] : [btn.path];
                            return (
                                <Box key={index} ml={1} mb={1}>
                                    <BigButton
                                        active={views.includes(pathname)}
                                        btn={btn}
                                        icon={btn.icon}
                                        label={btn.label}
                                        onClick={(e) => handleSecondaryButtonClick(e, btn.path)}
                                    />
                                </Box>
                            )
                        })
                    }
                </Box>
            }
        </Box>
    );
}
export default MenuBtn;