import { useState } from 'react';
import { toast } from 'react-toastify';
import FormDialog from '../../../components/FormDialog';
import { useAppCtxLoading } from '../../../context/SystemContext';
import sendPasswordResetEmail from '../../../firebase/auth/sendPasswordResetEmail';

const ForgotPassword = ({open, setOpen, errorMessage, setErrorMessage}) => {
    const loading = useAppCtxLoading();
    const [textFieldValue, set_textFieldValue] = useState('');
    
    const handle_okClick = async () => {
        setOpen(false);
        const toastId = toast.loading('Aguarde...');

        const result = await sendPasswordResetEmail(textFieldValue.trim());
        if(result.error){
            setErrorMessage('Não foi possível encontrar esse e-mail na nossa base de dados.');
            setOpen(true);
            return;
        }
        
        set_textFieldValue('');
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: 'Enviamos instruções para o seu e-mail.', type: 'success' });
    };

    return (
        <FormDialog
            still
            id="retrieve-pw-dialog"
            open={open} onClose={() => setOpen(false)}
            title="Esqueceu sua senha?" text="Informe o endereço de e-mail abaixo." textFieldLabel="E-mail"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={handle_okClick}
            okButtonDisabled={textFieldValue === '' || loading}
            errorMessage={errorMessage}
        />
    );
}

export default ForgotPassword;