import { useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import DeleteIcon from '@mui/icons-material/Delete';
import AlertDialog from '../../../../components/AlertDialog';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import Checkbox from '../../../../components/Checkbox';
import NoResults from '../../../../components/NoResults';
import Select from '../../../../components/Select';
import TinyTable from '../../../../components/TinyTable';
// import Tooltip from '../../../../components/Tooltip';
import ViewBox from '../../../../components/ViewBox';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { getGroupNameById, getTemplateNameById, getUserNameById } from '../../../../utils/common';

const deleteFormDraftDialogOpen = signal(false);
const selectedFormDraft = signal(null);

const DeleteFormDraftDialog = ({ onConfirm }) => {
    useSignals();

    const handleClose = () => {
        deleteFormDraftDialogOpen.value = false;
    };

    return (
        <AlertDialog
            title="Tem certeza?"
            text={`Quer excluir o formulário "${selectedFormDraft.value?.name}"? Essa ação é irreversível.`}
            open={deleteFormDraftDialogOpen.value} onClose={handleClose}
            okButtonOnClick={onConfirm}
        />
    );
}

const selectedUserId = signal('');
const UserSelector = () => {
    useSignals();
    const { activeUser } = useAppStateCtx();

    useEffect(() => {
        return () => {
            selectedUserId.value = '';
        };
    }, []);

    const handleChange = (e) => {
        selectedUserId.value = e.target.checked ? activeUser.value.uid : '';
    };

    return (
        <Checkbox checked={!!selectedUserId.value} onChange={handleChange} label={activeUser.value.name} />
    );
}

const selectedGroupId = signal('all');
const GroupSelector = () => {
    useSignals();
    const { activeUserGroups, projectGroupSettings, } = useAppStateCtx();

    useEffect(() => {
        return () => {
            selectedGroupId.value = 'all';
        };
    }, []);

    const handleChange = (e) => {
        selectedGroupId.value = e.target.value;
    }

    if(projectGroupSettings.value?.enabled){
        let groupOptions = [{ value: 'all', label: 'Todos' }];
        activeUserGroups.value.forEach(activeUserGroup => {
            groupOptions.push({value: activeUserGroup.id, label: activeUserGroup.name});
        });
        return (
            <Grid item xs={12} sm={4}>
                <Select label="Grupo" options={groupOptions} value={selectedGroupId.value} onChange={handleChange} />
            </Grid>
        );
    }
    return null;
}

const Filters = () => {
    useSignals();

    return (
        <Box>
            <Box mb={2}>
                <Grid container spacing={1} alignItems="flex-end">
                    <GroupSelector />
                    <Grid item xs={12} sm={2} container alignItems="flex-end" justifyContent="center">
                        <UserSelector />
                    </Grid>
                    {/* <Grid item xs>
                        <Input label="Filtrar" value={filterInputValue} onChange={(e) => setFilterInputValue(e.target.value)} />
                    </Grid> */}
                    {/* <Grid item xs>
                        <FilterTextField id="forms-filter-input" filterFunction={() => filter()} />
                    </Grid> */}
                </Grid>
            </Box>

            
        </Box>
    );
}

const allDrafts = signal(null);
const loading = signal(true);
const visibleDrafts = signal([]);

const LocalList = () => {
    useSignals();
    const { workspaceId } = useParams();
    const {
        activeUser,
        activeUserGroups,
        activeUserIsGroupsManager,
        formSelectedDraft,
        projectDocumentsTemplateOptions,
        projectFormDrafts,
        projectGroups,
        projectGroupSettings,
        selectedFolder,
        workspaceDocumentsTemplates,
        workspaceUsers
    } = useAppStateCtx();
    const { selectedFolderIsClient, setFirestoreListener } = useAppStateCtxUtils();
    const { handleNavigate } = useAppCtxAPI();

    useEffect(() => {
        return () => {
            visibleDrafts.value = [];
            allDrafts.value = null;
            loading.value = true;
        };
    }, []);

    useEffect(() => {
        if(selectedFolder.value?.uid){
            setFirestoreListener('projectDocumentSettings');
            setFirestoreListener('workspaceTemplateLists');
            setFirestoreListener('projectFormDrafts', selectedFolder.value?.uid);
        }
    }, [selectedFolder.value?.uid]);

    useEffect(() => {
        if(projectFormDrafts.value){
            allDrafts.value = projectFormDrafts.value;
        }
    }, [projectFormDrafts.value]);

    const filterUser = (item) => {
        if(!!selectedUserId.value && item.createdBy !== activeUser.value?.uid) return false;
        return true;
    };

    const filterGroup = (item) => {
        if(selectedGroupId.value !== 'all'){
            return selectedGroupId.value === item.groupId;
        }
        if(projectGroupSettings.value?.enabled){
            const isActiveUserGroup = activeUserGroups.value.some(group => group.id === item.groupId);
            return isActiveUserGroup;
        }
        return true;
    };

    useEffect(() => {
        if(allDrafts.value){
            visibleDrafts.value = allDrafts.value.filter(draft => !draft.deleted && filterUser(draft) && filterGroup(draft));
            loading.value = false;
        }
    }, [allDrafts.value, selectedUserId.value, selectedGroupId.value]);

    const handleDraftListItemClick = async (draft) => {
        if(projectDocumentsTemplateOptions.value){
            const foundTemplate = projectDocumentsTemplateOptions.value.some(template => template.value === draft.templateId);
            if(foundTemplate){
                formSelectedDraft.value = draft;
                return handleNavigate(`/${workspaceId}/pastas/${selectedFolder.value?.uid}/formularios?draft=${draft.uid}`);
            }
            toast(`O modelo associado ao formulário "${draft.name}" não está disponível para o projeto "${selectedFolder.value.name}".`, { type: 'error' });
        }
    };

    const tableHead = [
        {content: 'Data'},
        {content: 'Solicitante'}
    ];
    if(selectedFolderIsClient.value){
        tableHead.push({content: 'Projeto'});
        tableHead.push({content: 'Grupo'});
    } else if(projectGroupSettings.value?.enabled){
        tableHead.push({content: 'Grupo'});
    }
    tableHead.push({content: 'Formulário'});
    tableHead.push({content: 'Nome'});
    tableHead.push({content: 'Detalhamento'});
    tableHead.push({content: 'Completar'});
    if(activeUserIsGroupsManager.value){
        tableHead.push({content: 'Excluir'});
    }

    if(loading.value){
        return (
            <LoaderEllipsis />
        );
    }

    if(visibleDrafts.value.length === 0){
        return (
            <NoResults text="Não há formulários salvos." />
        );
    }
    
    return (
        <TinyTable
            head={tableHead}
            body={
                visibleDrafts.value
                .map((item) => {
                    const userForm = JSON.parse(item.form);
                    let visibleForm = '';
                    for(const question in userForm){
                        if(question !== 'linkFormUser' && !userForm[question].choice) visibleForm += `${question}: ${userForm[question].input}; `;
                    }
                    const columns = [
                        {content: <Typography variant="body2">{moment(item.createdAt).format('D/M/YY H:mm')}</Typography>},
                        {content: <Typography variant="body2">{getUserNameById(workspaceUsers.value, item.createdBy, true)}</Typography>},
                    ];
                    if(selectedFolderIsClient.value){
                        columns.push({content: <Typography variant="body2">{item.project}</Typography>});
                        columns.push({content: <Typography variant="body2">{getGroupNameById(item.groupId, projectGroups.value)}</Typography>});
                    } else if(projectGroupSettings.value?.enabled){
                        columns.push({content: <Typography variant="body2">{getGroupNameById(item.groupId, projectGroups.value)}</Typography>});
                    }
                    columns.push({content: <Typography variant="body2">{getTemplateNameById(item.templateId, workspaceDocumentsTemplates.value)}</Typography>});
                    columns.push({content: <Typography variant="body2">{item.name}</Typography>});
                    columns.push({content: <Typography variant="body2">{visibleForm}</Typography>});
                    columns.push({
                        content: 
                            <Box>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Button
                                            endIcon={<ArrowForwardIcon />}
                                            variant="outlined" color="primary"
                                            onClick={() => handleDraftListItemClick(item)}
                                        >Completar</Button>
                                    </Grid>
                                </Grid>
                            </Box>
                    });
                    if(activeUserIsGroupsManager.value){
                        columns.push({
                            content:
                                <Box>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            {/* <Tooltip text="Excluir">
                                                <IconButton size="medium" onClick={() => handle_deleteFormClick(item)}><DeleteIcon /></IconButton>
                                            </Tooltip> */}
                                        </Grid>
                                    </Grid>
                                </Box>
                        });
                    }
                    return ({
                        updatedAt: item.updatedAt,
                        columns
                    });
                })
            }
        />
    );
}

function SavedRequestDocForms(){
    useSignals();

    // const handle_deleteFormClick = (clickedFormDraft) => {
    //     selectedFormDraft.value = clickedFormDraft;
    //     deleteFormDraftDialogOpen.value = true;
    // };
    const handleDeleteFormDraftConfirm = () => {
        // setLoading(true);
        deleteFormDraftDialogOpen.value = false;
        // let postData = {
        //     collection: 'Projects',
        //     documentId: selectedFolder.value.uid,
        //     subDocumentKey: 'forms',
        //     subDocumentId: selectedFormDraft.value.uid,
        //     projectId: selectedFolder.value?.uid
        // }
        //TODO /data/delete/nested
        // let projectNewValue = res.data;
        // updateStateChangeArrayItemWith_id({...selectedFormDraft.value, deleted: true}, set_savedForms);
        // setSelectedClientOrProject({...selectedClientOrProject, forms: projectNewValue.forms});
        // setLoading(false);
    };

    return (
        <ViewBox>
            
            <Filters />
        
            <LocalList />

            <DeleteFormDraftDialog onConfirm={handleDeleteFormDraftConfirm} />
        </ViewBox>
    );
}

export default SavedRequestDocForms;