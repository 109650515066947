import Grid from '@mui/material/Grid2';

const SearchFieldContainer = ({ children, ...props }) => {
    return (
        <Grid
            {...props}
            sx={{
                background: theme => theme.palette.background.paper,
                border: '1px solid transparent',
                borderRadius: '8px',
                boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
                '&:hover': {
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.24)'
                },
            }}
        >
            {children}
        </Grid>
    );
};

export default SearchFieldContainer;