import { useSignals } from '@preact/signals-react/runtime';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import Tooltip from '../../../../../../../../../../components/Tooltip';
import { useAppStateCtx } from '../../../../../../../../../../context/AppState';

const classes = {
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const Span = styled('span')(unstable_styleFunctionSx);

const PendingDocumentListItemTopProjectName = ({ onClick, projectId }) => {
    useSignals();
    const { workspaceProjects } = useAppStateCtx();
    const project = workspaceProjects.value[projectId];
    const projectName = project?.name || '';
    return (
        <Tooltip text={`Pesquisar outros documentos da pasta ${projectName}`} enterDelay={300}>
            <Typography variant="body2" noWrap><Span sx={classes.link} onClick={() => onClick(project.clientId, projectId)}>{projectName}</Span></Typography>
        </Tooltip>
    );
};

export default PendingDocumentListItemTopProjectName;