
import { useEffect, useRef, useState } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import Input from '../../components/Input';
import Guidelines from '../../components/clearance/ClearanceGuidelines';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Questions from './Questions';
import Reports from './Reports';
// import Search from './Search';

import Project from '../../classes/Project';
import { useAppStateCtx, useAppStateCtxUtils } from '../../context/AppState';
import { useAppContext } from '../../context/SystemContext';
import callFunction from '../../firebase/functions/callFunction';
import useGetProject from '../../hooks/useGetProject';
import useGetProjectClearanceSettings from '../../hooks/useGetProjectClearanceSettings';
import { ERROR_MESSAGE_UNKNOWN, SIDENAV_WIDTH } from '../../utils/constants';

const classes = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowX: 'hidden',
        background: '#f5f6fa',
    }
};

const loadingSettings = signal(true);

const ClearanceDashboard = () => {
    useSignals();
    const {
        activeUser,
        activeUserAuth,
        activeUserGroups,
        activeUserIsGroupsManager,
        loadingApp,
        projectClearanceSettings,
        projectGroupSettings,
        selectedFolder
    } = useAppStateCtx();
    const { activeUserIsOperator, resetState, setFirestoreListener } = useAppStateCtxUtils();
    const { mainRef } = useAppContext();
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const dashboardOpenPrevState = useRef(false);
    const [dashboardOpen, setDashboardOpen] = useState(false);
    const [initialSelectedFolder, setInitialSelectedFolder] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldReload, setShouldReload] = useState(false);
    const passwordInputRef = useRef();
    const params = useParams();
    const folderId = params.folderId;

    useEffect(() => {
        if(folderId){
            setFirestoreListener('projectGroupSettings', folderId);
        } else {
            setShouldReloadErrorMessage();
        }
    }, [folderId]);

    const retrievedProject = useGetProject(folderId)
    useEffect(() => {
        if(retrievedProject.data){
            setInitialSelectedFolder(retrievedProject.data);
            const currentProject = new Project({...retrievedProject.data, uid: folderId});
            selectedFolder.value = currentProject;
            setFirestoreListener('workspaceUsers', retrievedProject.data.workspaceId);
        }
    }, [retrievedProject]);

    const retrievedProjectClearanceSettings = useGetProjectClearanceSettings(!!initialSelectedFolder, folderId);
    useEffect(() => {
        if(retrievedProjectClearanceSettings.data) projectClearanceSettings.value = retrievedProjectClearanceSettings.data;
    }, [retrievedProjectClearanceSettings]);

    const checkUser = () => {
        if(activeUser.value && (activeUserIsOperator.value || activeUserIsGroupsManager.value)){
            openDashboard();
        } else {
            if(projectClearanceSettings.value.groupsBehavior === 'restrict'){
                if(!activeUser.value){
                    setErrorMessage('Você precisa fazer login para acessar a Central de Clearance.');
                } else {
                    if(projectGroupSettings.value.enabled && activeUserGroups.value.length === 0){
                        setErrorMessage('Você não tem permissão para acessar a Central de Clearance.');
                    }
                }
            }
        }
    };
    useEffect(() => {
        if(projectClearanceSettings.value){
            loadingSettings.value = false;
            loadingApp.value = false;
            checkUser();
        }
    }, [projectClearanceSettings.value, activeUser.value, activeUserGroups.value, activeUserIsGroupsManager.value]);

    useEffect(() => {
        if(!loadingSettings.value){
            if(passwordInputRef.current) passwordInputRef.current.focus();
        }
    }, [loadingSettings.value]);

    const setShouldReloadErrorMessage = (newErrorMessage) => {
        setShouldReload(true);
        setErrorMessage(newErrorMessage || `Não foi possível carregar as informações do projeto. Por favor, recarregue a página.`);
        loadingSettings.value = false;
    };

    const openDashboard = () => {
        setFirestoreListener('folderUsers', `projects/${folderId}`);
        setDashboardOpen(true);
        dashboardOpenPrevState.current = true;
    };

    const handlePasswordForwardPress = async (newValue) => {
        if(!projectClearanceSettings.value || !projectClearanceSettings.value.groupsBehavior){
            return setErrorMessage('Não foi possível carregar os dados do projeto. Tente novamente.');
        }
        setLoading(true);
        setErrorMessage('');

        const res = await callFunction('openClearanceDashboard', {
            activeUserId: activeUser.value?.uid,
            password: newValue || password,
            projectId: folderId
        });
        setLoading(false);
        if(res.error || !res.result){
            return setErrorMessage(res.error?.message || ERROR_MESSAGE_UNKNOWN);
        }
        openDashboard();
    };

    useEffect(() => {
        if(!activeUserAuth.value && dashboardOpenPrevState.current){
            setDashboardOpen(false);
            dashboardOpenPrevState.current = false;
            resetState(['folderUsers', 'loadingApp', 'projectClearanceSettings', 'projectGroupSettings', 'selectedFolder', 'workspaceUsers']);
        }
    }, [activeUserAuth.value]);

    return (
        <Box sx={classes.root} ref={mainRef}>
            <Navigation dashboardOpen={dashboardOpen} />
            
            <Box
                sx={{
                    height: '100%',
                    left: `${SIDENAV_WIDTH}px`,
                    position: 'relative',
                    width: `calc(100% - ${SIDENAV_WIDTH}px)`
                }}
            >
                <Header dashboardOpen={dashboardOpen} initialSelectedFolder={initialSelectedFolder} />

                {
                    (!dashboardOpen)
                    ?
                    <Box style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: '50px'
                    }}>
                        <Box mb={2}>
                            <Box mb={1}>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Collapse in={loadingSettings.value}>
                                            <LinearProgress />
                                        </Collapse>
                                        <Typography variant="h2">CENTRAL DE CLEARANCE</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Collapse in={initialSelectedFolder?.name}>
                                <Box mb={2}>
                                    <Grid container alignItems="center" justifyContent="center">
                                        <Grid item>
                                            <Typography variant="h4">&ldquo;{initialSelectedFolder?.name}&rdquo;</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Collapse>
                        </Box>
                        <Collapse in={!loadingSettings.value}>
                            <Box>
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <Input
                                            autoFocus
                                            inputRef={passwordInputRef}
                                            disabled={loading || shouldReload}
                                            label="Senha"
                                            value={password} onChange={(e) => setPassword(e.target.value)}
                                            startIcon={<LockIcon />}
                                            endIcon={loading ? <CircularProgress size={20} /> : <ArrowForwardIcon />}
                                            endIconOnClick={() => handlePasswordForwardPress()}
                                            onKeyPress={(e) => {
                                                if(e.key === 'Enter') handlePasswordForwardPress();
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Collapse in={errorMessage}>
                                    <FormHelperText error>{errorMessage}</FormHelperText>
                                </Collapse>
                            </Box>
                        </Collapse>
                    </Box>
                    :
                    <Box sx={{ height: '100%'}}>
                        <Routes>
                            <Route
                                path={`/`}
                                element={<Guidelines folderClearanceInformation={projectClearanceSettings.value} />}
                            />
                            <Route
                                path={`/consultas`}
                                element={
                                    <Questions
                                        initialProjectClearanceSettings={projectClearanceSettings.value}
                                        initialProjectGroupSettings={projectGroupSettings.value}
                                        restrictedToProject={selectedFolder.value}
                                    />
                                }
                            />
                            <Route
                                path={`/relatorios`}
                                element={
                                    <Reports
                                        initialProjectClearanceSettings={projectClearanceSettings.value}
                                        restrictedToProject={selectedFolder.value}
                                    />
                                }
                            />
                            {/* <Route
                                path={`/pesquisa`}
                                element={
                                    <Search
                                        numberOfApprovalsRequired={clearanceNumberOfApprovalsRequired}
                                        selectedFolder={selectedFolder.value}
                                    />
                                }
                            /> */}
                        </Routes>
                    </Box>
                }
            </Box>
        </Box>
    );
};

export default ClearanceDashboard;