import { useEffect, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import ChangeUserView from './components/ChangeUserView';
import FilterInput from '../../../components/FilterInput';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import MenuChip from '../../../components/MenuChip'
import Pagination from '../../../components/Pagination';
import TinyTable from '../../../components/TinyTable';
import ViewBox from '../../../components/ViewBox';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import useGetProjectUsers from '../../../hooks/useGetProjectUsers';
import { useOperatorUsersCtxAPI } from '../../../context/OperatorUsersContext';
import { escapeRegex, removeDiacritics } from '../../../utils/common';
import { sortByKey } from '../../../utils/filters';
import User from '../../../classes/User';

const filterText = signal('');

const FilterText = () => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value]);

    return (
        <FilterInput value={value} setValue={setValue} placeholder="Digite para filtrar" />
    );
};

const filterProjectIdAnchorEl = signal(null);
const filterProjectIdOpen = signal(false);
const filterProjectId = signal('all');

const Row = ({ data, index, style }) => {
    useSignals();
    const dataSet = data[index];

    const handleOptionClick = () => {
        filterProjectId.value = dataSet.value;
        filterProjectIdOpen.value = false;
    };
    
    return (
        <MenuItem 
            key={dataSet.value}
            onClick={() => handleOptionClick(dataSet.value)}
            selected={dataSet.value === filterProjectIdOpen.value}
            style={style}
            
        >
            <Typography variant="inherit" noWrap>
                {dataSet.label}
            </Typography>
        </MenuItem>
    )
}

const projectFilterTextFilter = signal('');

const ProjectFilterTextFilter = ({ textFieldRef }) => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            projectFilterTextFilter.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value])

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    return (
        <TextField
            inputRef={textFieldRef} fullWidth variant="standard" size="small" 
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment style={{ marginRight: '8px'}}>
                            <FilterListIcon />
                        </InputAdornment>
                    ),
                    style: {
                        height: '35px',
                    }
                }
            }}
            sx={{
                padding: '0 8px 8px 8px'
            }}
            value={value}
            onChange={handleChange}
        />
    )
}

const FilterProjectId = () => {
    useSignals();
    const { mappedWorkspaceProjects } = useAppStateCtxUtils();
    const textFieldRef = useRef(null);
    const [visibleProjects, setVisibleProjects] = useState([]);

    useEffect(() => {
        if(mappedWorkspaceProjects.value){
            setVisibleProjects([
                { value: 'all', label: 'Todos' },
                ...mappedWorkspaceProjects.value.filter(project => {
                    if(projectFilterTextFilter.value){
                        return removeDiacritics(project.name).toUpperCase().indexOf(removeDiacritics(projectFilterTextFilter.value).toUpperCase()) !== -1;
                    }
                    return true;
                })
            ]);
        }
    }, [mappedWorkspaceProjects.value, projectFilterTextFilter.value]);

    useEffect(() => {
        if(filterProjectIdOpen.value){
            const textFieldEl = textFieldRef.current;
            if(textFieldEl) textFieldEl.focus();
        }
    }, [filterProjectIdOpen.value]);

    const handleClose = () => {
        filterProjectIdOpen.value = false;
    }

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemSize = smUp ? 36 : 48;

    if(visibleProjects){
        return (
            <Menu
                id="simple-menu"
                anchorEl={filterProjectIdAnchorEl.value}
                keepMounted
                open={filterProjectIdOpen.value}
                onClose={handleClose}
            >
                <ProjectFilterTextFilter textFieldRef={textFieldRef} />
                <FixedSizeList
                    itemData={visibleProjects}
                    height={500}
                    itemCount={visibleProjects.length}
                    itemSize={itemSize}
                    width={300}
                >
                    {Row}
                </FixedSizeList>
            </Menu>
        )
    }
    return null;
}

const loadingProjectUsers = signal(false);
const projectUsers = signal([]);

const LoadingProjectUsers = () => {
    useSignals();
    if(loadingProjectUsers.value){
        return (
            <Grid>
                <CircularProgress size={20} />
            </Grid>
        );
    }
    return null;
}

const FilterProjectIdPicker = () => {
    useSignals();
    const { mappedWorkspaceProjects } = useAppStateCtxUtils();

    const retrievedProjectUsers = useGetProjectUsers(filterProjectId.value);
    useEffect(() => {
        if(filterProjectId.value && filterProjectId.value !== 'all' && retrievedProjectUsers.loading){
            loadingProjectUsers.value = true;
        } else if(retrievedProjectUsers.data) {
            const ids = Object.keys(retrievedProjectUsers.data);
            if(ids.length !== 0){
                projectUsers.value = ids;
            } else {
                projectUsers.value = ['none'];
            }
        } else {
            loadingProjectUsers.value = false;
        }
    }, [retrievedProjectUsers]);

    useEffect(() => {
        if(filterProjectId.value === 'all'){
            projectUsers.value = [];
        }
    }, [filterProjectId.value]);

    useEffect(() => {
        if(projectUsers.value){
            loadingProjectUsers.value = false;
        }
    }, [projectUsers.value]);

    const handleProjectFilterClick = (e) => {
        filterProjectIdAnchorEl.value = e.currentTarget;
        filterProjectIdOpen.value = true;
    }

    const getProjectFilterLabelByValue = () => {
        const currentFilterOption = mappedWorkspaceProjects.value?.find(option => option.value === filterProjectId.value);
        return currentFilterOption?.label || '';
    };

    return (
        <>
            <Grid>
                <MenuChip
                    label="Projeto"
                    onPress={handleProjectFilterClick}
                    value={filterProjectId.value} valueLabel={getProjectFilterLabelByValue()}
                />
            </Grid>
            <LoadingProjectUsers />
        </>
    );
}

const Pages = ({ count, currentPage, perPage, handlePageClick }) => {
    useSignals();
    if(filterText.value.length === 0 && projectUsers.value.length === 0){
        return (
            <Pagination count={count} page={currentPage} perPage={perPage} onChange={(_, page) => handlePageClick(page)} />
        );
    }
    return null;
};

const loadingWorkspaceUsers = signal(true);

function Users(){
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    const { showAddUserView, showChangeUserView } = useOperatorUsersCtxAPI();
    const [loading, setLoading] = useState(true);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [visibleUsers, setVisibleUsers] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(workspaceUsers.value){
            loadingWorkspaceUsers.value = false;
        }
    }, [workspaceUsers.value]);

    useEffect(() => {
        if(workspaceUsers.value){
            const currentUsers = Object.entries(workspaceUsers.value)
            .map(([userId, user]) => ({...user, uid: userId}))
            .sort(sortByKey('name'));
            setAvailableUsers(currentUsers);
        }
    }, [workspaceUsers.value]);

    useEffect(() => {
        if(workspaceUsers.value && visibleUsers){
            setLoading(false);
        }
    }, [workspaceUsers.value, visibleUsers]);

    const perPage = 100;

    const showPage = () => {
        if(availableUsers){
            const firstIndex = (currentPage - 1) * perPage;
            const lastIndex = (currentPage * perPage);
            const pageArray = availableUsers.slice(firstIndex, lastIndex);
            setVisibleUsers(pageArray);
        }
    }

    const filterByText = (user) => {
        if(filterText.value){
            const textFields = `${user.name}${user.email}`;
            if(filterText.value.length === 1){ //alphabet
                const regEx = new RegExp(`^${escapeRegex(removeDiacritics(filterText.value)).toUpperCase()}`);
                return regEx.test(removeDiacritics(textFields).toUpperCase());
            }
            return removeDiacritics(textFields).toUpperCase().indexOf(removeDiacritics(filterText.value).toUpperCase()) !== -1;
        }
        return true;
    }

    const filterByProject = (user) => {
        if(projectUsers.value.length !== 0){
            return projectUsers.value.includes(user.uid);
        }
        return true;
    }

    useEffect(() => {
        if(filterText.value.length !== 0 || projectUsers.value.length !== 0){
            setVisibleUsers(
                availableUsers.filter(user => filterByText(user) && filterByProject(user))
            )
        } else {
            showPage();
        }
    }, [availableUsers, currentPage, filterText.value, projectUsers.value]);

    const handleAddUserButtonClick = () => {
        showAddUserView();
    };

    const handleUserRowClick = (clickedUser) => {
        showChangeUserView(new User(clickedUser.data));
    };

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <ViewBox style={{ height: '100%' }}>

            <Box mb={2}>
                <Grid container spacing={1} justifyContent="flex-end" alignContent="center">
                    <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                        <FilterText />
                    </Grid>
                    <FilterProjectIdPicker />
                    <Grid size="grow"></Grid>
                    <Grid>
                        <Button variant="contained" onClick={handleAddUserButtonClick} startIcon={<AddIcon />}>Cadastrar pessoa</Button>
                    </Grid>
                </Grid>
            </Box>

            {
                loading
                ? <LoaderEllipsis />
                :
                <>
                    <Pages count={availableUsers.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    <Box pb={5}>
                        <Container maxWidth="md">
                            <TinyTable
                                head={[
                                    { content: '' },
                                    { content: <Typography variant="body1">Nome</Typography> },
                                    { content: <Typography variant="body1">E-mail</Typography> },
                                ]}
                                body={
                                    visibleUsers?.map((mappedUser) => {
                                        const columns = [
                                            { content: <Box><Avatar alt={mappedUser.name} src={mappedUser.photoUrl} /></Box>, justify: 'center' },
                                            { content: <Typography variant="body2" color={mappedUser.disabled ? 'textDisabled' : ''}>{mappedUser.name}</Typography> },
                                            { content: <Typography variant="body2" color={mappedUser.disabled ? 'textDisabled' : ''}>{mappedUser.email}</Typography> },
                                        ];
                
                                        return ({
                                            data: mappedUser,
                                            columns
                                        })
                                    })
                                }
                                handleBodyRow={handleUserRowClick}
                            />
                        </Container>
                        <Pages count={availableUsers.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    </Box>
                </>
            }
            
            <ChangeUserView />

            <FilterProjectId />
            
        </ViewBox>
    );
}

export default Users;