import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';

import Modal from '../../../../../../components/Modal';
import MultipleAutocomplete from '../../../../../../components/MultipleAutocomplete';
import { useAppStateCtx } from '../../../../../../context/AppState';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../../utils/constants';
import { sortByKey } from '../../../../../../utils/filters';

const ListsWindow = ({open, onClose, selectedList}) => {
    useSignals();
    const { activeUser, selectedWorkspace, workspaceDocumentsTemplates } = useAppStateCtx();
    const [saving, setSaving] = useState(false);
    const [listName, set_listName] = useState('');
    const [listItems, set_listItems] = useState([]);
    const [templatesOptions, setTemplatesOptions] = useState([]);

    useEffect(() => {
        if(open && workspaceDocumentsTemplates.value && selectedList){
            set_listName(selectedList.name);
            const listItemsNewValue = selectedList.list
            .map(templateId => {
                const foundTemplate = workspaceDocumentsTemplates.value[templateId];
                if(foundTemplate) return {...foundTemplate, uid: templateId};
                return null;
            })
            .filter(template => !!template);
            set_listItems(listItemsNewValue);
        } else {
            set_listName('');
            set_listItems([]);
        }
    }, [open, workspaceDocumentsTemplates.value]);

    useEffect(() => {
        if(workspaceDocumentsTemplates.value){
            const currentTemplatesOptions = Object.entries(workspaceDocumentsTemplates.value).map(([templateId, template]) => ({...template, uid: templateId})).sort(sortByKey('name'));
            setTemplatesOptions(currentTemplatesOptions);
        }
    }, [workspaceDocumentsTemplates.value]);
    
    const handleSubmit = async () => {
        setSaving(true);

        let error = null;
        if(!selectedList){
            const res = await selectedWorkspace.value.createDocumentsTemplatesList({
                activeUserId: activeUser.value.uid,
                list: listItems.map(listItem => listItem.uid),
                name: listName
            });
            error = res.error;
        } else {
            const res = await selectedWorkspace.value.updateDocumentsTemplatesList({
                listId: selectedList.uid,
                updates: {
                    list: listItems.map(listItem => listItem.uid),
                    name: listName
                }
            });
            error = res.error;
        }
        if(error){
            setSaving(false);
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        
        setSaving(false);
        toast(SUCCESS_MESSAGE_SAVED, { autoClose: 3000, type: 'success' });
        onClose();
    }
    
    return (
        <Modal open={open} onClose={onClose}>
            <Box p={2} style={{minWidth: 280, maxWidth: '98%', maxHeight: '98%', overflow: 'auto'}}>
                <Box mb={2}>
                    <Box>
                        <Typography variant="h6">{selectedList ? 'Alterar lista' : 'Nova lista'}</Typography>
                    </Box>
                </Box>
                <Box mb={2}>
                    <TextField
                        variant="standard"
                        fullWidth
                        label="Nome"
                        value={listName}
                        onChange={(e) => set_listName(e.target.value)} />
                </Box>
                <Box mb={2}>
                    <MultipleAutocomplete
                        label="Lista"
                        options={templatesOptions} getOptionLabel={(option) => `${option.name}${option.description ? ` (${option.description})` : ''}`}
                        value={listItems} onChange={(e, newValue) => set_listItems(newValue)}
                    />
                </Box>
                <Box style={{textAlign: 'center'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={saving ? <CircularProgress size={20} /> : <SendIcon />}
                        onClick={handleSubmit}
                        disabled={saving}
                    >Enviar</Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ListsWindow;