import { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import MessagesWindow from '../../../components/Messages/MessagesWindow';
import { useAppStateCtx } from '../../../context/AppState';
import useGetWorkspaceClients from '../../../hooks/useGetWorkspaceClients';
import useGetWorkspaceProjects from '../../../hooks/useGetWorkspaceProjects';
import { sortByKey } from '../../../utils/filters';
import moment from 'moment';

const OperatorCtxAPI = createContext();
const OperatorCtxClients = createContext();
const OperatorCtxCounters = createContext();
const OperatorCtxFavoriteProjects = createContext();
const OperatorCtxProjects = createContext();
const OperatorCtxLawsuits = createContext();
const OperatorCtxINPIItems = createContext();
const OperatorCtxINPIProgressItems = createContext();
const OperatorCtxMenuItems = createContext();
const OperatorCtxMobileSidenav = createContext();
const OperatorCtxOperators = createContext();
const OperatorCtxRefs = createContext();
const OperatorCtxSidenavMobile = createContext();
const OperatorCtxTimesheet = createContext();

const defaultState = {
    clients: null,
    companies: null,
    favoriteFolders: [],
    hideTimesheetTop: false,
    hoursCount: null,
    inpiPendingProgress: [],
    inpiRecords: [],
    judicialCases: null,
    lawsuits: null,
    lawsuitsCount: null,
    messages: [],
    mobileSidenavDocumentsMenuOpen: false,
    mobileSidenavFoldersMenuOpen: false,
    mobileSidenavProcessosMenuOpen: false,
    mobileSidenavTasksMenuOpen: false,
    operators: [],
    pendingClearanceCount: null,
    pendingJudicialCases: null,
    pendingJudicialCasesProgress: null,
    projects: null,
    questionsCount: null,
    sidenavMobileOpen: false,
    tasksCount: null,
    timesheet: [],
    timesheetPanelOpen: false,
    timesheetSelectedDate: moment(),
    timesheetSelectedUser: '',
    users: null,
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'ADD CHAT MESSAGE':
            return { ...state, messages: [...state.messages, payload] };
        case 'ADD INPI ITEM':
            return { ...state, inpiRecords: [...state.inpiRecords, payload] };
        case 'ADD LAWSUIT':
            return { ...state, lawsuits: [...state.lawsuits, payload] };
        case 'ADD PROJECT':
            return { ...state, projects: {...state.projects, payload} };
        case 'ADD USER':
            return { ...state, users: [...state.users, payload] };
        case 'DELETE CHAT MESSAGES':
            const deletedChatMessageIndex = state.messages.findIndex(item => item.uid === payload);
            if(deletedChatMessageIndex !== -1){
                return { ...state, messages: [...state.messages.slice(0, deletedChatMessageIndex), ...state.messages.slice(deletedChatMessageIndex + 1)] };
            }
            return state;
        case 'DELETE INPI ITEM':
            const deletedINPIItemIndex = state.inpiRecords.findIndex(item => item.uid === payload);
            if(deletedINPIItemIndex !== -1){
                return { ...state, inpiRecords: [...state.inpiRecords.slice(0, deletedINPIItemIndex), ...state.inpiRecords.slice(deletedINPIItemIndex + 1)] };
            }
            return state;
        case 'DELETE INPI PROGRESS ITEM':
            const deletedINPIProgressItemIndex = state.inpiPendingProgress.findIndex(item => item.uid === payload);
            if(deletedINPIProgressItemIndex !== -1){
                return { ...state, inpiPendingProgress: [...state.inpiPendingProgress.slice(0, deletedINPIProgressItemIndex), ...state.inpiPendingProgress.slice(deletedINPIProgressItemIndex + 1)] };
            }
            return state;
        case 'DELETE LAWSUIT':
            const deletedLawsuitIndex = state.lawsuits.findIndex(item => item.uid === payload);
            if(deletedLawsuitIndex !== -1){
                return { ...state, lawsuits: [...state.lawsuits.slice(0, deletedLawsuitIndex), ...state.lawsuits.slice(deletedLawsuitIndex + 1)] };
            }
            return state;
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'UPDATE INPI PROGRESS ITEM':
            const INPIItemProgressIndex = state.inpiPendingProgress.findIndex(item => item.uid === (payload.itemId || payload.newValue.uid));
            if(INPIItemProgressIndex !== -1){
                return { ...state, inpiPendingProgress: [...state.inpiPendingProgress.slice(0, INPIItemProgressIndex), {...state.inpiPendingProgress[INPIItemProgressIndex], ...payload.newValue, updatedAt: new Date()}, ...state.inpiPendingProgress.slice(INPIItemProgressIndex + 1)] };
            }
            return state;
        case 'UPDATE INPI ITEM':
            const INPIItemIndex = state.inpiRecords.findIndex(item => item.uid === (payload.itemId || payload.newValue.uid));
            if(INPIItemIndex !== -1){
                return { ...state, inpiRecords: [...state.inpiRecords.slice(0, INPIItemIndex), {...state.inpiRecords[INPIItemIndex], ...payload.newValue, updatedAt: new Date()}, ...state.inpiRecords.slice(INPIItemIndex + 1)] };
            }
            return state;
        case 'UPDATE LAWSUIT':
            const lawsuitIndex = state.lawsuits.findIndex(item => item.uid === (payload.itemId || payload.newValue.uid));
            if(lawsuitIndex !== -1){
                return { ...state, lawsuits: [...state.lawsuits.slice(0, lawsuitIndex), {...state.lawsuits[lawsuitIndex], ...payload.newValue, updatedAt: new Date()}, ...state.lawsuits.slice(lawsuitIndex + 1)] };
            }
            return state;
        case 'UPDATE PROJECT':
            if(state.projects[payload.uid]){
                return { ...state, projects: { ...state.projects, [payload.uid]: { ...state.projects[payload.uid], ...payload } } };
            }
            return state;
        case 'UPDATE USER':
            const userIndex = state.users.findIndex(item => item.uid === (payload.itemId || payload.newValue.uid));
            if(userIndex !== -1){
                return { ...state, users: [...state.users.slice(0, userIndex), {...state.users[userIndex], ...payload.newValue, updatedAt: new Date()}, ...state.users.slice(userIndex + 1)] };
            }
            return state;
        default: return state;
    }
    
};

const classes = {
    content: {
        height: '100%',
        width: '100%',
    },
};

const Div = styled('div')(unstable_styleFunctionSx);

const OperatorProvider = ({children}) => {
    useSignals();
    const location = useLocation();
    const {
        activeUser, chatViewOpen, loadingApp, pageTitle, selectedWorkspace, workspaceClients, workspaceDocumentsTemplates, workspaceProjects, workspaceUsers
    } = useAppStateCtx();

    const [state, dispatch] = useReducer(reducer, {...defaultState});

    // FIRESTORE HOOKS
    
    const retrievedWorkspaceClients = useGetWorkspaceClients();
    useEffect(() => {
        if(retrievedWorkspaceClients.clients){
            setState('clients', retrievedWorkspaceClients.clients);
            workspaceClients.value = retrievedWorkspaceClients.clients;
        }
    }, [retrievedWorkspaceClients]);
    
    const retrievedWorkspaceProjects = useGetWorkspaceProjects();
    useEffect(() => {
        if(retrievedWorkspaceProjects.data){
            setState('projects', retrievedWorkspaceProjects.data);
            workspaceProjects.value = retrievedWorkspaceProjects.data;
        }
    }, [retrievedWorkspaceProjects]);

    const setSnapshot = (triggerKey) => {
        setState(triggerKey, true);
    }

    const setState = (key, value) => {
        dispatch({ type: 'SET STATE', payload: { key, value } });
    };

    const api = useMemo(() => {

        const addChatMessage = (payload) => {
            dispatch({ type: 'ADD CHAT MESSAGE', payload });
        };
        
        const addINPIItem = (payload) => {
            dispatch({ type: 'ADD INPI ITEM', payload });
        };

        const addLawsuit = (payload) => {
            dispatch({ type: 'ADD LAWSUIT', payload });
        };
        
        const addProject = (payload) => {
            dispatch({ type: 'ADD PROJECT', payload });
        };

        const addUser = (payload) => {
            dispatch({ type: 'ADD USER', payload });
        };

        const closeTimesheetPanel = () => {
            dispatch({ type: 'SET STATE', payload: { key: 'timesheetPanelOpen', value: false } });
            setTimeout(() => dispatch({ type: 'SET STATE', payload: { key: 'hideTimesheetTop', value: false } }), 400);
        };

        const deleteChatMessage = (payload) => {
            dispatch({ type: 'DELETE CHAT MESSAGE', payload });
        };

        const deleteINPIItem = (payload) => {
            dispatch({ type: 'DELETE INPI ITEM', payload });
        };

        const deleteINPIProgressItem = (payload) => {
            dispatch({ type: 'DELETE INPI PROGRESS ITEM', payload });
        };

        const deleteLawsuit = (payload) => {
            dispatch({ type: 'DELETE LAWSUIT', payload });
        };

        const updateINPIItem = (newValue, itemId) => {
            dispatch({ type: 'UPDATE INPI ITEM', payload: { newValue, itemId } });
        };

        const updateINPIProgressItem = (newValue, itemId) => {
            dispatch({ type: 'UPDATE INPI PROGRESS ITEM', payload: { newValue, itemId } });
        };

        const updateLawsuit = (newValue, itemId) => {
            dispatch({ type: 'UPDATE LAWSUIT', payload: { newValue, itemId } });
        };

        const updateProject = (payload) => {
            dispatch({ type: 'UPDATE PROJECT', payload });
        };

        const updateUser = (newValue, itemId) => {
            dispatch({ type: 'UPDATE USER', payload: { newValue, itemId } });
        };

        return {
            dispatch,
            addChatMessage,
            addINPIItem,
            addLawsuit,
            addProject,
            addUser,
            closeTimesheetPanel,
            deleteChatMessage,
            deleteINPIItem,
            deleteINPIProgressItem,
            deleteLawsuit,
            setSnapshot,
            setState,
            updateINPIItem,
            updateINPIProgressItem,
            updateLawsuit,
            updateProject,
            updateUser
        };
    }, []);

    const updatedProject = useRef(null);
    const shouldEmitUpdatedProjects = useRef(false);
    const shouldUpdateProjects = useRef(false);
    const updatedUser = useRef(null);
    const shouldEmitUpdatedUsers = useRef(false);
    const shouldUpdateUsers = useRef(false);

    const getPageTitle = (pathname) => {

        const getTemplatePageTitle = (additionalText) => {
            let title = `Matriz para documento`;
            if(workspaceDocumentsTemplates.value){
                const data = /juridico\/documentos\/matrizes\/(\w+?)\//.exec(pathname);
                if(data){
                    const templateId = data[1];
                    if(templateId){
                        const foundTemplate = workspaceDocumentsTemplates.value[templateId];
                        if(foundTemplate) title += ` ${foundTemplate.name}`;
                    }
                }
            }
            title += ` - ${additionalText}`;
            return title;
        }

        const availablePages = [
            { test: 'juridico\/admin', getTitle: () => 'Sistema' },
            { test: 'juridico\/atendimento', getTitle: () => 'Painel de projetos' },
            { test: 'juridico\/clearance\/consultas', getTitle: () => 'Consultas de Clearance' },
            { test: 'juridico\/clearance\/matrizes', getTitle: () => 'Matrizes de Clearance' },
            { test: 'juridico\/clearance\/relatorios', getTitle: () => 'Relatórios de Clearance' },
            { test: 'juridico\/demandas\/geral', getTitle: () => 'Minhas tarefas' },
            { test: 'juridico\/documentos\/alertas', getTitle: () => 'Alertas de documentos' },
            { test: 'juridico\/documentos\/formulario', getTitle: () => 'Formulários de documentos' },
            { test: 'juridico\/documentos\/listas', getTitle: () => 'Listas para documentos' },
            { test: 'juridico\/documentos\/matrizes\/?$', getTitle: () => 'Matrizes para documentos' },
            { test: 'juridico\/documentos\/pendentes', getTitle: () => 'Documentos em aberto' },
            { test: 'juridico\/documentos\/pesquisar', getTitle: () => 'Todos os documentos' },
            { test: 'juridico\/documentos\/matrizes\/\\w+?\/documento', getTitle: () => getTemplatePageTitle('Documento') },
            { test: 'juridico\/documentos\/matrizes\/\\w+?\/formulario', getTitle: () => getTemplatePageTitle('Formulário') },
            { test: 'juridico\/documentos\/matrizes\/listas', getTitle: () => 'Conjuntos de matrizes para documentos' },
            { test: 'juridico\/documentos\/matrizes\/titulo', getTitle: () => getTemplatePageTitle('Padrão de título') },
            { test: 'juridico\/financeiro\/areas', getTitle: () => 'Financeiro | Áreas' },
            { test: 'juridico\/financeiro\/composicao', getTitle: () => 'Financeiro | Composição financeira' },
            { test: 'juridico\/financeiro\/despesas', getTitle: () => 'Financeiro | Despesas' },
            { test: 'juridico\/financeiro\/estados', getTitle: () => 'Financeiro | Regiões' },
            { test: 'juridico\/financeiro\/lucros', getTitle: () => 'Financeiro | Distribuição de lucros' },
            { test: 'juridico\/financeiro\/nova-proposta', getTitle: () => 'Financeiro | Nova proposta' },
            { test: 'juridico\/financeiro\/planejamento-mensal', getTitle: () => 'Financeiro | Planejamento mensal' },
            { test: 'juridico\/financeiro\/propostas', getTitle: () => 'Financeiro | Propostas' },
            { test: 'juridico\/financeiro\/ranking', getTitle: () => 'Financeiro | Ranking' },
            { test: 'juridico\/preferencias', getTitle: () => 'Preferências' },
            { test: 'juridico\/processos\/administrativos\/acompanhamento', getTitle: () => 'Acompanhamento de processos administrativos' },
            { test: 'juridico\/processos\/administrativos\/andamentos', getTitle: () => 'Andamentos de processos administrativos' },
            { test: 'juridico\/processos\/administrativos\/consultar', getTitle: () => 'Consulta a processos administrativos' },
            { test: 'juridico\/processos\/administrativos\/todos', getTitle: () => 'Lista de processos administrativos' },
            { test: 'juridico\/processos\/inpi\/acompanhamento', getTitle: () => 'INPI | Acompanhamento' },
            { test: 'juridico\/processos\/inpi\/andamentos', getTitle: () => 'INPI | Andamentos' },
            { test: 'juridico\/processos\/inpi\/todos', getTitle: () => 'INPI | Lista de processos' },
            { test: 'juridico\/processos\/judicial\/acompanhamento', getTitle: () => 'Acompanhamento de ações judiciais' },
            { test: 'juridico\/processos\/judicial\/andamentos', getTitle: () => 'Andamentos de ações judiciais' },
            { test: 'juridico\/processos\/judicial\/consultar', getTitle: () => 'Consulta a ações judiciais' },
            { test: 'juridico\/processos\/judicial\/todos', getTitle: () => 'Lista de ações judiciais' },
            { test: 'juridico\/pessoas', getTitle: () => 'Pessoas' },
            { test: 'juridico\/projetos\/clientes', getTitle: () => 'Áreas de trabalho' },
            { test: 'juridico\/projetos\/empresas', getTitle: () => 'Clientes' },
            { test: 'juridico\/projetos\/informacoes-especiais', getTitle: () => 'Informações especiais para projetos' },
            { test: 'juridico\/projetos\/pastas', getTitle: () => 'Projetos' },
            { test: 'juridico\/relatorios\/advogados', getTitle: () => 'Relatórios por advogado' },
            { test: 'juridico\/relatorios\/clientes', getTitle: () => 'Relatórios por projeto' },
            { test: 'juridico\/relatorios\/empresa', getTitle: () => 'Relatórios por cliente' },
            { test: 'juridico\/relatorios\/equipe', getTitle: () => 'Relatórios da equipe' },
            { test: 'juridico\/salas', getTitle: () => 'Salas' },
            { test: 'juridico\/tarefas\/equipe', getTitle: () => 'Tarefas da equipe' },
            { test: 'juridico\/timesheet', getTitle: () => 'Timesheet' },
        ];

        const path = pathname.replace(/^\/\w+?\//, '');
        let title;
        const foundPage = availablePages.find(option => {
            const titleTest = new RegExp(option.test);
            return titleTest.test(path);
        });
        if(foundPage) title = foundPage.getTitle();
        return title || '';
    }
    
    useEffect(() => {
        const pathname = location.pathname;
        pageTitle.value = getPageTitle(pathname);
    }, [location, workspaceDocumentsTemplates.value]);

    useEffect(() => {
        if(activeUser.value && selectedWorkspace.value && workspaceUsers.value && workspaceClients.value && workspaceProjects.value){
            setTimeout(() => {
                loadingApp.value = false;
            }, 2000);
        }
    }, [activeUser.value, selectedWorkspace.value, workspaceUsers.value, workspaceClients.value, workspaceProjects.value]);

    useEffect(() => {
        const keyPress = (e) => {
            if(e.ctrlKey && e.key === '2'){
                e.preventDefault();
                chatViewOpen.value = true;
            }
        };
        document.addEventListener('keydown', keyPress);
        return () => {
            document.removeEventListener('keydown', keyPress);
        };
    }, []);

    useEffect(() => {
        if(workspaceUsers.value){
            const sortedOperators = Object.entries(workspaceUsers.value)
            .filter(([_, user]) => !user.disabled && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer'].includes(user.role))
            .map(([userId, user]) => ({...user, uid: userId}))
            .sort(sortByKey('name'));
            dispatch({ type: 'SET STATE', payload: { key: 'operators', value: sortedOperators } });
        } 
    }, [workspaceUsers.value]);

    useEffect(() => {
        if(selectedWorkspace.value && state.clients && state.projects){
            let favoriteFolders = [];
            if(selectedWorkspace.value.favoriteProjects){
                for(let projectId of selectedWorkspace.value.favoriteProjects){
                    let folder = {...state.clients, ...state.projects}[projectId];
                    if(folder) favoriteFolders.push({type: folder.clientId ? 'projects' : 'clients', id: projectId, name: folder.name});
                }
            }
            favoriteFolders = favoriteFolders.sort(sortByKey('name'));
            dispatch({ type: 'SET STATE', payload: { key: 'favoriteFolders', value: favoriteFolders } });
        }
    }, [selectedWorkspace.value, state.clients, state.projects]);

    return (
        <OperatorCtxAPI.Provider value={api}>
        <OperatorCtxRefs.Provider
            value={{
                shouldEmitUpdatedProjects,
                shouldEmitUpdatedUsers,
                shouldUpdateProjects,
                shouldUpdateUsers,
                updatedProject,
                updatedUser,
            }}
        >
        <OperatorCtxMenuItems.Provider
            value={{}}
            // value={menuItems}
        >
        <OperatorCtxSidenavMobile.Provider
            value={{ sidenavMobileOpen: state.sidenavMobileOpen }}
        >
        <OperatorCtxClients.Provider value={state.clients}>
        <OperatorCtxProjects.Provider value={state.projects}>
        <OperatorCtxOperators.Provider value={state.operators}>
        <OperatorCtxTimesheet.Provider
            value={{
                hideTimesheetTop: state.hideTimesheetTop,
                timesheet: state.timesheet,
                timesheetPanelOpen: state.timesheetPanelOpen,
                timesheetSelectedDate: state.timesheetSelectedDate,
                timesheetSelectedUser: state.timesheetSelectedUser,
            }}
        >
        <OperatorCtxLawsuits.Provider
            value={{
                judicialCases: state.judicialCases,
                pendingJudicialCasesProgress: state.pendingJudicialCasesProgress,
                pendingJudicialCases: state.pendingJudicialCases
            }}
        >
        <OperatorCtxINPIItems.Provider value={state.inpiRecords}>
        <OperatorCtxINPIProgressItems.Provider value={state.inpiPendingProgress}>
        <OperatorCtxMobileSidenav.Provider
            value={{
                mobileSidenavDocumentsMenuOpen: state.mobileSidenavDocumentsMenuOpen,
                mobileSidenavFoldersMenuOpen: state.mobileSidenavFoldersMenuOpen,
                mobileSidenavProcessosMenuOpen: state.mobileSidenavProcessosMenuOpen,
                mobileSidenavTasksMenuOpen: state.mobileSidenavTasksMenuOpen
            }}
        >
        <OperatorCtxCounters.Provider
            value={{
                hoursCount: state.hoursCount,
                lawsuitsCount: state.lawsuitsCount,
                pendingClearanceCount: state.pendingClearanceCount,
                questionsCount: state.questionsCount,
                tasksCount: state.tasksCount,
            }}
        >
        <OperatorCtxFavoriteProjects.Provider value={state.favoriteFolders}>

            <Div
                // style={{height: '100%'}}
                // sx={!timesheetPanelOpen ? classes.content : classes.contentShift}
                sx={classes.content}
            >
                {children}
            </Div>

            <MessagesWindow />

        </OperatorCtxFavoriteProjects.Provider>
        </OperatorCtxCounters.Provider>
        </OperatorCtxMobileSidenav.Provider>
        </OperatorCtxINPIProgressItems.Provider>
        </OperatorCtxINPIItems.Provider>
        </OperatorCtxLawsuits.Provider>
        </OperatorCtxTimesheet.Provider>
        </OperatorCtxOperators.Provider>
        </OperatorCtxProjects.Provider>
        </OperatorCtxClients.Provider>
        </OperatorCtxSidenavMobile.Provider>
        </OperatorCtxMenuItems.Provider>
        </OperatorCtxRefs.Provider>
        </OperatorCtxAPI.Provider>
    );
};

const useOperatorCtxAPI = () => useContext(OperatorCtxAPI);
const useOperatorCtxRefs = () => useContext(OperatorCtxRefs);
const useOperatorCtxClients = () => useContext(OperatorCtxClients);
const useOperatorCtxCounters = () => useContext(OperatorCtxCounters);
const useOperatorCtxFavoriteProjects = () => useContext(OperatorCtxFavoriteProjects);
const useOperatorCtxLawsuits = () => useContext(OperatorCtxLawsuits);
const useOperatorCtxINPIItems = () => useContext(OperatorCtxINPIItems);
const useOperatorCtxINPIProgressItems = () => useContext(OperatorCtxINPIProgressItems);
const useOperatorCtxMenuItems = () => useContext(OperatorCtxMenuItems);
const useOperatorCtxMobileSidenav = () => useContext(OperatorCtxMobileSidenav);
const useOperatorCtxOperators = () => useContext(OperatorCtxOperators);
const useOperatorCtxProjects = () => useContext(OperatorCtxProjects);
const useOperatorCtxSidenavMobile = () => useContext(OperatorCtxSidenavMobile);
const useOperatorCtxTimesheet = () => useContext(OperatorCtxTimesheet);

export {
    OperatorProvider,
    useOperatorCtxAPI,
    useOperatorCtxRefs,
    useOperatorCtxClients,
    useOperatorCtxCounters,
    useOperatorCtxFavoriteProjects,
    useOperatorCtxProjects,
    useOperatorCtxLawsuits,
    useOperatorCtxINPIItems,
    useOperatorCtxINPIProgressItems,
    useOperatorCtxMenuItems,
    useOperatorCtxMobileSidenav,
    useOperatorCtxOperators,
    useOperatorCtxSidenavMobile,
    useOperatorCtxTimesheet
};