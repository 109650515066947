import { Routes, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import DocumentEditor from './DocumentEditor';
import FormEditor from './FormEditor';
import Lists from './Lists';
import Main from './Main';
import TitlePattern from './TitlePattern';
import { TemplatesProvider } from './context/TemplatesContext';
import { FormEditorProvider } from './FormEditor/FormEditorContext';

function Templates(){
    return (
        <Box style={{height: '100%'}}>
            <TemplatesProvider>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/:templateId/documento" element={<DocumentEditor />} />
                    <Route path="/:templateId/formulario" element={<FormEditorProvider><FormEditor /></FormEditorProvider>} />
                    <Route path="/listas" element={<Lists />} />
                    <Route path="/titulo" element={<TitlePattern />} />
                    <Route path="*" element={<Main />} />
                </Routes>
            </TemplatesProvider>
        </Box>
    )
}

export default Templates;