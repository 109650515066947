import { initializeApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
    getDatabase
} from 'firebase/database';
import {
    getFirestore,
    // connectFirestoreEmulator
} from 'firebase/firestore';
import {
    getFunctions,
    // connectFunctionsEmulator
} from 'firebase/functions';
import {
    getStorage,
    // connectStorageEmulator
} from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyA0bwIl72UnI8jY7f0ug0C0Om26zNXxuWA",
    authDomain: "solida-juridico.firebaseapp.com",
    databaseURL: "https://solida-juridico-default-rtdb.firebaseio.com",
    projectId: "solida-juridico",
    storageBucket: "solida-juridico.appspot.com",
    messagingSenderId: "408532950155",
    appId: "1:408532950155:web:ec7aef7aa05c80f3ab088e"
};

const firebaseApp = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, 'southamerica-east1');
// connectFunctionsEmulator(functions, '127.0.0.1', 5001);
const db = getFirestore(firebaseApp);
// connectFirestoreEmulator(db, '127.0.0.1', 8080);
const storage = getStorage(firebaseApp);
// connectStorageEmulator(storage, '127.0.0.1', 9199);
const database = getDatabase(firebaseApp);

export {
    auth,
    database,
    db,
    firebaseApp,
    functions,
    storage
}