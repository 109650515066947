import { useSignals } from '@preact/signals-react/runtime';

import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid2';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import Tooltip from './Tooltip';
import ChipWithMenu from '../components/ChipWithMenu';
import { useAppStateCtx } from '../context/AppState';

const UsersSelector = ({includeNone, label, loading, selectedUser, setSelectedUser}) => {
    useSignals();
    const { activeUser, workspaceOperators } = useAppStateCtx();
    let options = [{value: 'all', label: 'Todos'}];
    if(includeNone) options.push({value: 'none', label: 'Nenhum'});
    if(workspaceOperators.value) options = [...options, ...workspaceOperators.value.map(user => ({value: user.uid, label: user.name}))];

    return (
        <Grid container spacing={1} wrap="nowrap">
            <Grid> 
                <ChipWithMenu
                    initialValue="all"
                    label={label}
                    onChange={setSelectedUser}
                    options={options}
                    value={selectedUser}
                />
            </Grid>
            <Grid size="grow" container alignItems="flex-end" justifyContent="flex-start" wrap="nowrap">
                {
                    includeNone &&
                    <Grid>
                        <Tooltip text="Nenhum">
                            <span>
                                <IconButton disabled={loading} size="small" color="primary" onClick={() => setSelectedUser('none')}><PersonOutlineIcon /></IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                }
                <Grid>
                    <Tooltip text={activeUser.value?.name}>
                        <span>
                            <IconButton disabled={loading} size="small" color="primary" onClick={() => setSelectedUser(activeUser.value?.uid)}><PersonIcon /></IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid>
                    <Tooltip text="Todos">
                        <span>
                            <IconButton disabled={loading} size="small" color="primary" onClick={() => setSelectedUser('all')}><PeopleIcon /></IconButton>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UsersSelector;