import { onValue, onDisconnect, ref, remove, set,  } from 'firebase/database';
import { nanoid } from 'nanoid';

import { database } from '../config';

export async function setOffline(path: string, setWindowId: (newValue: any) => void){
    const presenceRef = ref(database, `online_users/${path}`);
    remove(presenceRef).then(() => {
        setWindowId('');
    });
}

export async function detectPresence(activeUserId: string, path: string, setWindowId: (newValue: any) => void){
    const windowId = nanoid();

    const presenceRef = ref(database, `online_users/${path}/${windowId}`);

    onValue(ref(database, '.info/connected'), function(snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() == false) {
            setWindowId('');
            return;
        };

        onDisconnect(presenceRef).remove();
        
        set(presenceRef, {
            last_changed: new Date().toISOString(),
            userId: activeUserId,
        });

        setWindowId(`${path}/${windowId}`);

    })
}