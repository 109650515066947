import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { db } from '../firebase/config';

const useGetWorkspaceClearanceTemplates = ({
    selectedWorkspace,
    shouldSetSnapshot
}) => {
    const [hookState, setHookState] = useState({ loading: true, data: null });

    useEffect(() => {
        if(shouldSetSnapshot && selectedWorkspace?.modules.includes('clearance')){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'clearance_templates'),
                    where('workspaceId', '==', selectedWorkspace.uid),
                    where('deleted', '==', false),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            uid: snapshot.id,
                        })
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetSnapshot, selectedWorkspace]);

    return hookState;
};

export default useGetWorkspaceClearanceTemplates;