import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

const options = [
    { value: 'none', label: 'A resposta apresentada pelo solicitante' },
    { value: 'aiGeneratedSuggestion', label: 'A sugestão gerada por IA (com eventuais alterações do solicitante)' },
];

const DocumentsTemplateTextEffectOutputTypeSelector = ({ onChange, value, }: { onChange: (event: React.ChangeEvent<HTMLInputElement>, newValue: string) => void, value: string; }) => {
    return (
        <>
            <Box mb={1}>
                <Typography variant="h6">O que aparecerá no documento?</Typography>
            </Box>
            <RadioGroup
                value={value}
                onChange={onChange}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        options.map((option) => (
                            <Grid key={option.value}>
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
        </>
    )
}

export default DocumentsTemplateTextEffectOutputTypeSelector;