import Box from '@mui/material/Box';

import {SelectChangeEvent} from '@mui/material';
import TextField from '@mui/material/TextField';

import { useOperatorTemplatesCtxConstants } from '../../pages/Operator/Docs/Templates/context/TemplatesContext';

import Select from '../Select';
import Switch from '../Switch';

const DocumentTemplateEditorAISubjectMatterSection = ({ question, setQuestion, updateQuestionInList }: {
    question: { [key: string]: any; };
    setQuestion: (newValue: any) => void;
    updateQuestionInList: (newValue: any) => void;
}) => {
    const { selectedTemplateCustomTextFieldsArray } = useOperatorTemplatesCtxConstants();

    const handleChangeField = (key: string, newValue: string | boolean) => {
        const updatedQuestion = {...question, [key]: newValue};
        setQuestion(updatedQuestion);
        updateQuestionInList(updatedQuestion);
    }

    const handleShouldGenerateAISuggestionFromTextChange = () => {
        handleChangeField('shouldGetAISuggestionFromText', !question.shouldGetAISuggestionFromText);
    }

    const handleShouldHideGenerateAISuggestionFromTextInputChange = () => {
        handleChangeField('shouldHideAISuggestionInput', !question.shouldHideAISuggestionInput);
    }

    const handleGenerateAISuggestionFromTextCustomFieldIdChange = (event: SelectChangeEvent<string>) => {
        const newValue = event.target.value;
        handleChangeField('AISuggestionCustomFieldId', newValue);
    }

    const handleGenerateAISuggestionFromTextOutputFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        handleChangeField('AISuggestionOutputFormat', newValue);
    }

    const handleGenerateAISuggestionFromTextAdditionalInstructionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        handleChangeField('AISuggestionAdditionalInstructions', newValue);
    }

    return (
        <Box>
            <Box mb={2}>
                <Switch
                    checked={!!question.shouldGetAISuggestionFromText}
                    color="primary"
                    disabled={false}
                    label={`Gerar sugestão com I.A.`}
                    name="switch"
                    noWrap
                    onChange={handleShouldGenerateAISuggestionFromTextChange}
                    required={false}
                />
            </Box>
            {
                question.shouldGetAISuggestionFromText &&
                <>
                    <Box mb={2}>
                        <Switch
                            checked={!!question.shouldHideAISuggestionInput}
                            color="primary"
                            disabled={false}
                            label={`Esconder a caixa de texto com a sugestão gerada por I.A. no formulário`}
                            name="switch"
                            noWrap
                            onChange={handleShouldHideGenerateAISuggestionFromTextInputChange}
                            required={false}
                        />
                        <Select
                            label="Informação especial com a descrição do projeto"
                            options={selectedTemplateCustomTextFieldsArray.value}
                            value={question.AISuggestionCustomFieldId || 'select'}
                            onChange={handleGenerateAISuggestionFromTextCustomFieldIdChange}
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Formato da resposta"
                            fullWidth
                            multiline
                            value={question.AISuggestionOutputFormat || ''}
                            onChange={handleGenerateAISuggestionFromTextOutputFormatChange}
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Instruções adicionais"
                            fullWidth
                            multiline
                            value={question.AISuggestionAdditionalInstructions || ''}
                            onChange={handleGenerateAISuggestionFromTextAdditionalInstructionsChange}
                        />
                    </Box>
                </>
            }
        </Box>
    )
}

export default DocumentTemplateEditorAISubjectMatterSection;