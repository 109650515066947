import { sendPasswordResetEmail as _sendPasswordResetEmail } from 'firebase/auth';

import { auth } from '../config';

export default async function sendPasswordResetEmail(email: string) {
    let result = null, error = null;
    
    try {
        result = await _sendPasswordResetEmail(auth, email);
    } catch (e) {
        error = e;
    }

    return { result, error };
}