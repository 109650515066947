import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';

import TextCaseRadioOptions from '../../../components/TextCaseRadioOptions';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { useAppCtxAPI } from '../../../context/SystemContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateEffects } from '../../../pages/Operator/Docs/Templates/context/TemplatesContext';
import { useDocumentEditor } from '../../../pages/Operator/Docs/Templates/DocumentEditor/context/DocumentEditorContext';
import { formEffectTypes } from '../../../utils/constants';
import { sortByKey } from '../../../utils/filters';

const ListOption = ({label, onClick}) => {
    return (
        <Box>
            <Divider />
            <Box
                sx={{
                    '&:hover': {
                        backgroundColor: theme => theme.palette.action.hover
                    }
                }}
                mb={0.5} mt={0.5}
            >
                <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
                    <Grid size="grow">
                        <Typography variant="body2">{label}</Typography>
                    </Grid>
                    <Grid>
                        <IconButton size="small" onClick={onClick}><DescriptionIcon /></IconButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

const EditorEffect = (props) => {
    useSignals();
    const { selectedEffect, setSelectedEffect } = props;
    const { workspaceLists, workspaceListsItems } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { getListById } = useAppCtxAPI();
    const { setTemplateEffects } = useOperatorTemplatesCtxAPI();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const { handle_effectRadioOutputButtonClick, listOutputOptions } = useDocumentEditor(); 
    const defaultListValue = { name: '', list: [] };
    const [list, setList] = useState(defaultListValue);

    useEffect(() => {
        setFirestoreListener('workspaceLists');
    }, []);

    useEffect(() => {

        let currentList = {...defaultListValue};
        if(selectedEffect && selectedEffect.type === 'list'){
            setFirestoreListener('workspaceListsItems', selectedEffect.typeId);
            const foundList = getListById(workspaceLists.value, selectedEffect.typeId);
            if(foundList){
                let listItems = workspaceListsItems.value[selectedEffect.typeId];
                if(!listItems && foundList.shortName) listItems = workspaceListsItems.value[foundList.shortName];
                if(listItems){
                    currentList.name = foundList.name;
                    if(selectedEffect.options){
                        currentList.list = listItems.map(listItem => {
                            const output = selectedEffect.options.find(option => option.option === listItem.uid);
                            return ({...listItem, priority: !!output});
                        }).sort(sortByKey('key'));
                    }
                }
            }
        }
        setList(currentList);
    }, [selectedEffect, workspaceLists.value, workspaceListsItems.value]);

    const getListOutputName = (listOutputValue) => {
        let name = '';
        let currentListOutput = listOutputOptions.find(output => output.value === listOutputValue);
        if(currentListOutput) name = currentListOutput.label;
        return name;
    };

    const handleTextCaseChange = (_, newValue) => {
        setSelectedEffect(prevState => ({...prevState, textCase: newValue}))
        const nextState = templateEffects.map(templateEffect => {
            if(templateEffect.id === selectedEffect.id){
                return {
                    ...templateEffect,
                    textCase: newValue
                }
            }
            return templateEffect;
        })
        setTemplateEffects(nextState);
    }

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Lista:</strong> {list.name} ({getListOutputName(selectedEffect.typeSpecifics)})</Typography>
            </Box>
            <TextCaseRadioOptions value={selectedEffect.textCase || 'none'} onChange={handleTextCaseChange} />
            {
                selectedEffect.typeSpecifics === 'custom' &&
                <>
                    {
                        list.list
                        .sort((a, b) => !a.priority && b.priority ? 1 : (a.priority && !b.priority ? -1 : 0))
                        .map(option => (
                            <ListOption
                                key={option.uid}
                                label={<span>{option.priority ? <span><strong>{option.key}</strong></span> : <span>{option.key} (opção vazia)</span>}</span>}
                                onClick={() => handle_effectRadioOutputButtonClick(selectedEffect.id, option.uid)}
                            />
                        ))
                    }
                </>
            }
        </Box>
    )
};

export default EditorEffect;