import { createContext, useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';

const FormContext = createContext();

const FormProvider = ({children}) => {
    useSignals();
    const {
        formAllQuestions,
        formFoundCNPJ,
        formFoundCNPJs,
        formForceCheckValidation,
        formInitialValues,
        formLists,
        formQuestionNameToCNPJ,
        formQuestionsStatus,
        formSelectedDraft,
        formSelectedSupplier,
        formVisibleQuestions,
        projectMisc,
        selectedDocumentWithCompleteForm,
        selectedDocumentForm,
        selectedFolder,
    } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const logicalBranchingQuestions = useRef([]);
    const questionElements = useRef({});
    const usedSupplierInformation = useRef(false);
    const values = useRef({});

    useEffect(() => {
        if(selectedFolder.value?.uid){
            setFirestoreListener('projectMisc', selectedFolder.value.uid);
        }
    }, [selectedFolder.value?.uid]);

    const checkLogicalBranching = (question, logicalBranchingQuestions) => {
        
        const checkLogicalBranchingAction = (questionLogicalBranchingConditions) => {
            if(questionLogicalBranchingConditions){
                if(!Array.isArray(questionLogicalBranchingConditions)){
                    const concurrentConditionGroups = [];
                    if(questionLogicalBranchingConditions && questionLogicalBranchingConditions.question !== '~none;'){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: [questionLogicalBranchingConditions]
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                    }
                    questionLogicalBranchingConditions = concurrentConditionGroups;
                } else {
                    const concurrentConditionGroups = [];
                    if(!questionLogicalBranchingConditions.some(logicalBranchingCondition => logicalBranchingCondition.concurrentConditions)){
                        const concurrentConditionGroup = {
                            id: uuidv4(),
                            concurrentConditions: questionLogicalBranchingConditions
                        };
                        concurrentConditionGroups.push(concurrentConditionGroup);
                        questionLogicalBranchingConditions = concurrentConditionGroups;
                    }
                }
                const validQuestionLogicalBranchingConditionGroups = questionLogicalBranchingConditions.filter(logicalBranchingConditionsGroup => {
                    return logicalBranchingConditionsGroup.concurrentConditions.some(logicalBranchingCondition => logicalBranchingCondition.question !== '~none;');
                });

                if(validQuestionLogicalBranchingConditionGroups.length === 0){
                    return true;
                } else {
                    for(const questionLogicalBranchingConditionsGroup of validQuestionLogicalBranchingConditionGroups){
                        let conditionTest = true;
                        const concurrentConditions = questionLogicalBranchingConditionsGroup.concurrentConditions;
                        for(const questionLogicalBranchingCondition of concurrentConditions){
                            if(!conditionTest) break;
                            if(questionLogicalBranchingCondition.type === 'userCreatedBooleanField'){
                                let customField;
                                if(projectMisc.value){
                                    customField = projectMisc.value[questionLogicalBranchingCondition.question];
                                }
                                
                                if(questionLogicalBranchingCondition.choice !== 'false'){
                                    if(!customField) conditionTest = false;
                                } else {
                                    if(!!customField) conditionTest = false;
                                }
                            } else {
                                const questionLogicalBranchingConditionQuestion = questionLogicalBranchingCondition.question;
                                const questionLogicalBranchingConditionChoice = questionLogicalBranchingCondition.choice;
                                const questionLogicalBranchingConditionOperation = questionLogicalBranchingCondition.operation || 'if';
                                const userFormLogicalBranchingQuestion = values.current[questionLogicalBranchingConditionQuestion];
                                if(!userFormLogicalBranchingQuestion){
                                    if(questionLogicalBranchingConditionOperation === 'if') conditionTest = false;
                                } else {
                                    if(questionLogicalBranchingCondition.type === 'list'){
                                        let listId = questionLogicalBranchingCondition.typeId;
                                        let currentList;
                                        if(formLists.value) currentList = formLists.value[listId];
                                        if(currentList){
                                            let listItem = currentList.find(item => userFormLogicalBranchingQuestion.input && (item.key.toLowerCase() === userFormLogicalBranchingQuestion.input.toLowerCase() || (Array.isArray(item.aliases) && item.aliases.map(alias => alias.toLowerCase()).includes(userFormLogicalBranchingQuestion.input.toLowerCase()))));
                                            if(listItem?.listItemId) listItem = currentList.find(item => item.uid === listItem.listItemId);
                                            if(!listItem){
                                                if(questionLogicalBranchingConditionOperation === 'if') conditionTest = false;
                                            } else {
                                                if(questionLogicalBranchingConditionOperation === 'if' && listItem.uid !== questionLogicalBranchingConditionChoice && listItem.key !== questionLogicalBranchingConditionChoice){
                                                    conditionTest = false;
                                                } else if(questionLogicalBranchingConditionOperation === 'ifNot' && (listItem.uid === questionLogicalBranchingConditionChoice || listItem.key === questionLogicalBranchingConditionChoice)){
                                                    conditionTest = false;
                                                }
                                            }
                                        } else {
                                            conditionTest = false;
                                        }
                                    } else if(questionLogicalBranchingCondition.type === 'special'){ // Type "special" is deprecated, but some templates still use it
                                        let listId = 'special_list_audiovisual_services';
                                        let currentList;
                                        if(formLists.value) currentList = formLists.value[listId];
                                        if(currentList){
                                            let listItem = currentList.find(item => userFormLogicalBranchingQuestion.input && (item.key.toLowerCase() === userFormLogicalBranchingQuestion.input.toLowerCase() || (Array.isArray(item.aliases) && item.aliases.map(alias => alias.toLowerCase()).includes(userFormLogicalBranchingQuestion.input.toLowerCase()))));
                                            if(listItem?.listItemId) listItem = currentList.find(item => item.uid === listItem.listItemId);
                                            if(!listItem){
                                                if(questionLogicalBranchingConditionOperation === 'if') conditionTest = false;
                                            } else {
                                                if(questionLogicalBranchingConditionOperation === 'if' && listItem.key !== questionLogicalBranchingConditionChoice){
                                                    conditionTest = false;
                                                } else if(questionLogicalBranchingConditionOperation === 'ifNot' && listItem.key === questionLogicalBranchingConditionChoice){
                                                    conditionTest = false;
                                                }
                                            }
                                        } else {
                                            conditionTest = false;
                                        }
                                    } else {
                                        if(questionLogicalBranchingConditionOperation === 'if' && userFormLogicalBranchingQuestion.choice !== questionLogicalBranchingConditionChoice){
                                            conditionTest = false;
                                        } else if(questionLogicalBranchingConditionOperation === 'ifNot' && userFormLogicalBranchingQuestion.choice === questionLogicalBranchingConditionChoice){
                                            conditionTest = false;
                                        }
                                    }
                                }
                                
                                if(!conditionTest) break;
        
                                const nextQuestionLogicalBranchingConditionQuestion = logicalBranchingQuestions.find(item => item.name === questionLogicalBranchingConditionQuestion);
                                if(nextQuestionLogicalBranchingConditionQuestion){
                                    const currentGroupTest = checkLogicalBranchingAction(nextQuestionLogicalBranchingConditionQuestion.logicalBranching);
                                    if(!currentGroupTest) return false;
                                }
                            }
                        }
                        if(conditionTest){
                            return true;
                        }
                    }
                }
            }
            return false;
        };

        return checkLogicalBranchingAction(question.logicalBranching);
    };

    const applyLogicalBranching = () => {
        logicalBranchingQuestions.current.forEach(question => {
            if(questionElements.current[question.name]){
                const showQuestion = checkLogicalBranching(question, logicalBranchingQuestions.current);
                if(showQuestion){
                    questionElements.current[question.name].classList.remove('hidden');
                } else {
                    questionElements.current[question.name].classList.add('hidden');
                }
            }
        });
    };

    const resetForm = ({ preserveFormInitialValues = false, preserveFormSelectedDraft = false }) => {
        logicalBranchingQuestions.current = [];
        questionElements.current = {};
        formAllQuestions.value = [];
        formVisibleQuestions.value = [];
        if(!preserveFormInitialValues) formInitialValues.value = {};
        formFoundCNPJ.value = null;
        formFoundCNPJs.value = {};
        formQuestionNameToCNPJ.value = {};
        formQuestionsStatus.value = {};
        if(!preserveFormSelectedDraft) formSelectedDraft.value = null;
        formSelectedSupplier.value = null;
        selectedDocumentForm.value = null;
        usedSupplierInformation.current = false;
        values.current = {};
        formForceCheckValidation.value = false;
        selectedDocumentWithCompleteForm.value = null;
        formLists.value = {};
    };

    return (
        <FormContext.Provider
            value={{
                applyLogicalBranching,
                logicalBranchingQuestions,
                questionElements,
                resetForm,
                usedSupplierInformation,
                values
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

const useForm = () => useContext(FormContext);

export {
    FormProvider,
    useForm
};