import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Form from '../Form';
import { useForm } from '../Form/context/FormContext';

import Workspace from '../../classes/Workspace';
import LoaderEllipsis from '../../components/LoaderEllipsis';
import ViewBox from '../../components/ViewBox';
import { useAppStateCtx, useAppStateCtxUtils } from '../../context/AppState';
import useGetProject from '../../hooks/useGetProject';
import useGetProjectDocumentsFormLink from '../../hooks/useGetProjectDocumentsFormLink';
import { startDocumentsForm } from '../../utils/common';

const LinkForm = () => {
    useSignals();
    const params = useParams();
    const { formInitialValues, formSelectedDraft, loadingApp, loadingForm, projectDocumentSettings, projectMisc, selectedDocumentForm, selectedFolder, selectedWorkspace, workspaceDocumentsTemplates } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { resetForm, values } = useForm();
    const [formSent, setFormSent] = useState(false);
    const [foundLink, setFoundLink] = useState(null);
    const folderFormId = params.folderFormId;

    const retrievedProjectDocumentsFormLink = useGetProjectDocumentsFormLink(folderFormId);
    useEffect(() => {
        if(retrievedProjectDocumentsFormLink.data){
            setFoundLink(retrievedProjectDocumentsFormLink.data);
        }
    }, [retrievedProjectDocumentsFormLink]);

    const retrievedProject = useGetProject(foundLink?.projectId);
    useEffect(() => {
        if(retrievedProject.data){
            selectedFolder.value = {...retrievedProject.data, uid: foundLink.projectId};
            setFirestoreListener('projectDocumentSettings');
            selectedWorkspace.value = new Workspace({ uid: retrievedProject.data.workspaceId });
            setFirestoreListener('workspaceDocumentsTemplates');
        }
    }, [retrievedProject]);

    useEffect(() => {
        if(foundLink && selectedFolder.value && projectDocumentSettings.value && workspaceDocumentsTemplates.value){
            openForm(foundLink.templateId);
            loadingApp.value = false;
        }
    }, [foundLink, selectedFolder.value, projectDocumentSettings.value, workspaceDocumentsTemplates.value]);

    const openForm = (templateId) => {
        startDocumentsForm({
            initialValues: formInitialValues,
            loadingForm: loadingForm,
            projectDocumentsSettings: projectDocumentSettings,
            projectId: selectedFolder.value.uid,
            projectMisc: projectMisc,
            resetForm: resetForm,
            selectedDraft: formSelectedDraft,
            selectedForm: selectedDocumentForm,
            // setSelectedTemplateId,
            templateId: templateId,
            templates: workspaceDocumentsTemplates.value,
            values: values,
        });
    };

    const handleFormSubmit = () => {
        setFormSent(true);
    };

    return ( 
        <ViewBox>
            {
                !folderFormId &&
                <Grid container justifyContent="center">
                    <Grid item>
                        <CancelIcon htmlColor="red" fontSize={'small'} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">O link fornecido não corresponde a um formulário!</Typography>
                    </Grid>
                </Grid>
            }
            {
                formSent &&
                <Box mb={2}>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">O formulário foi enviado!</Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            {
                (selectedFolder.value && selectedDocumentForm.value) &&
                <Fade in timeout={1500}>
                    <Box>
                        <Form
                            hideSaveFormButton
                            hideSuppliersButton
                            linkForm
                            onFormSubmit={handleFormSubmit}
                            replaceTitle={`${selectedFolder.value.name} - ${selectedDocumentForm.value.name}`}
                        />
                    </Box>
                </Fade>
            } 
        </ViewBox>
    );
}

export default LinkForm;