import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { db } from '../firebase/config';

const useGetProjectFormDrafts = (shouldSetListener, projectId) => {
    const [hookState, setHookState] = useState({ loading: true, data: null });

    useEffect(() => {
        if(shouldSetListener && projectId){
            const unsubscribe = onSnapshot(
                query(
                    collection(db, `projects/${projectId}/documents_forms_drafts`),
                    where('deleted', '==', false),
                    //TODO GROUPS FILTER SUCH AS IN useGetDocumentsAsClient
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const dataWithId = { ...data, uid: snapshot.id };
                        dataArray.push(dataWithId);
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, projectId]);

    return hookState;
};

export default useGetProjectFormDrafts;