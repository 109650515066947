import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid2';

import BackgroundLetterAvatar from '../BackgroundLetterAvatar/BackgroundLetterAvatar';
import { useAppStateCtx } from '../../context/AppState';
import { FIRST_CLIENT_ID } from '../../utils/constants';

const HeaderWorkspaceImage = () => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();

    return (
        <Grid display="flex" alignItems="center">
            {
                selectedWorkspace.value &&
                <>
                    {
                        selectedWorkspace.value.logoUrl
                        ?
                        <>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Fade in={!!selectedWorkspace.value.logoUrl} timeout={1000}>
                                    <img src={selectedWorkspace.value.logoUrl} style={{maxHeight: 34}} />
                                </Fade>
                            </Box>
                            <Box
                                style={{
                                    width: 66,
                                    overflow: 'hidden'
                                }}
                                sx={{ display: { xs: 'block', sm: 'none' } }}
                            >
                                <Fade in={!!selectedWorkspace.value.logoUrl} timeout={1000}>
                                    <img src={selectedWorkspace.value.logoUrl} style={{maxHeight: 34, maxWidth: selectedWorkspace.value?.uid === FIRST_CLIENT_ID ? undefined : 66}} />
                                </Fade>
                            </Box>
                        </>
                        :
                        //@ts-ignore
                        <BackgroundLetterAvatar name={selectedWorkspace.value.name || ''} />
                    }
                </>
            }
        </Grid>
    );
}

export default HeaderWorkspaceImage;