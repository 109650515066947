import { collection, deleteField, doc, runTransaction } from 'firebase/firestore';
import moment from 'moment';
import ShortUniqueId from 'short-unique-id';

import { db } from '../firebase/config';
import callFunction from '../firebase/functions/callFunction';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';
import { _Proposal, ProposalVersion } from '../shared/types';

const getProposalInfoFromCurrentPayments = (currentPayments: { [paymentId: string]: any }) => {
    let totalRevenue = 0, yearRevenue: Proposal['yearRevenue'] = {};
    let paymentsPlannedToInYears: number[] = [], paymentsPlannedToOrReceivedInYears: number[] = [], paymentsReceivedInYears: number[] = [];
    let nextPayment: { [paymentId: string]: any } | null = null;
    Object.entries(currentPayments).forEach(([paymentId, payment]) => {
        if(!payment.receivedOn && (!nextPayment || payment.plannedTo < nextPayment.plannedTo)){
            nextPayment = {...payment, uid: paymentId};
        }
        if(payment.plannedTo){
            const paymentYear = moment(payment.plannedTo).year();
            if(!paymentsPlannedToInYears.includes(paymentYear)){
                paymentsPlannedToInYears.push(paymentYear);
            }
            if(!paymentsPlannedToOrReceivedInYears.includes(paymentYear)){
                paymentsPlannedToOrReceivedInYears.push(paymentYear);
            }
        }
        if(payment.receivedOn){
            totalRevenue += payment.grossValue;
            const paymentYear = moment(payment.receivedOn).year();
            if(!yearRevenue[paymentYear]) yearRevenue[paymentYear] = 0;
            yearRevenue[paymentYear] += payment.grossValue;

            if(!paymentsReceivedInYears.includes(paymentYear)){
                paymentsReceivedInYears.push(paymentYear);
            }
            if(!paymentsPlannedToOrReceivedInYears.includes(paymentYear)){
                paymentsPlannedToOrReceivedInYears.push(paymentYear);
            }
        }
    });
    return {
        nextPayment,
        paymentsPlannedToInYears,
        paymentsPlannedToOrReceivedInYears,
        paymentsReceivedInYears,
        totalRevenue,
        yearRevenue,
    }
}

export default class Proposal implements _Proposal {
    accepted = 'notAcceptedYet';
    actuallyReceivedValueInSharedContractWithRevenuesReceivedByPartner = 0;
    agreementType = '';
    awaitingProposalReview = '';
    clientAcquiredBy = '';
    clientAcquisitionFee = '';
    clientId = '';
    commentsReadAt: _Proposal['commentsReadAt'] = {};
    contractSupervisedBy = '';
    createdAt = '';
    createdBy = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    department = '';
    description = '';
    done = false; // done means all payments completed
    doneAt = '';
    doneBy = '';
    form: _Proposal['form'] = {};
    hasClientAcquisitionFee = false;
    hoursBankAmount = 0;
    hoursBankApproachingLimitNotificationSentAt = '';
    hoursUsedTotal = 0;
    hoursUsedTrackingStrategy = '';
    isSharedContract = false;
    isSharedContractWithRevenuesReceivedByPartner = false;
    lastComment: _Proposal['lastComment'] = null;
    lastVersion: _Proposal['lastVersion'] = null;
    nextPayment: _Proposal['nextPayment'] = null;
    nonRetainableAmount = 0;
    onGoingContractTerminatesOn = '';
    onGoingContractTerminationNotificationSentAt = '';
    payments: _Proposal['payments'] = {};
    paymentsPlannedToInYears: _Proposal['paymentsPlannedToInYears'] = [];
    paymentsPlannedToOrReceivedInYears: _Proposal['paymentsPlannedToOrReceivedInYears'] = [];
    paymentsReceivedInYears: _Proposal['paymentsReceivedInYears'] = [];
    referenceNumber = 0;
    shouldTrackHoursUsed = false;
    totalRevenue = 0;
    totalValue = 0;
    uid = '';
    workspaceId = '';
    yearRevenue: _Proposal['yearRevenue'] = {};
    yearValue: _Proposal['yearValue'] = {};

    constructor({
        accepted,
        actuallyReceivedValueInSharedContractWithRevenuesReceivedByPartner,
        agreementType,
        awaitingProposalReview,
        clientAcquiredBy,
        clientAcquisitionFee,
        clientId,
        commentsReadAt,
        contractSupervisedBy,
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        department,
        description,
        done,
        doneAt,
        doneBy,
        form,
        hasClientAcquisitionFee,
        hoursBankAmount,
        hoursBankApproachingLimitNotificationSentAt,
        hoursUsedTotal,
        hoursUsedTrackingStrategy,
        isSharedContract,
        isSharedContractWithRevenuesReceivedByPartner,
        lastComment,
        lastVersion,
        nextPayment,
        nonRetainableAmount,
        onGoingContractTerminatesOn,
        onGoingContractTerminationNotificationSentAt,
        payments,
        paymentsPlannedToInYears,
        paymentsPlannedToOrReceivedInYears,
        paymentsReceivedInYears,
        referenceNumber,
        shouldTrackHoursUsed,
        totalRevenue,
        totalValue,
        uid,
        workspaceId,
        yearRevenue,
        yearValue,
    }: Proposal){
        if(accepted) this.accepted = accepted;
        if(actuallyReceivedValueInSharedContractWithRevenuesReceivedByPartner) this.actuallyReceivedValueInSharedContractWithRevenuesReceivedByPartner = actuallyReceivedValueInSharedContractWithRevenuesReceivedByPartner;
        if(agreementType) this.agreementType = agreementType;
        if(awaitingProposalReview) this.awaitingProposalReview = awaitingProposalReview;
        if(clientAcquiredBy) this.clientAcquiredBy = clientAcquiredBy;
        if(clientAcquisitionFee) this.clientAcquisitionFee = clientAcquisitionFee;
        if(clientId) this.clientId = clientId;
        if(commentsReadAt) this.commentsReadAt = commentsReadAt;
        if(contractSupervisedBy) this.contractSupervisedBy = contractSupervisedBy;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(department) this.department = department;
        if(description) this.description = description;
        if(done) this.done = done;
        if(doneAt) this.doneAt = doneAt;
        if(doneBy) this.doneBy = doneBy;
        if(form) this.form = form;
        if(hasClientAcquisitionFee) this.hasClientAcquisitionFee = hasClientAcquisitionFee;
        if(hoursBankAmount) this.hoursBankAmount = hoursBankAmount;
        if(hoursBankApproachingLimitNotificationSentAt) this.hoursBankApproachingLimitNotificationSentAt = hoursBankApproachingLimitNotificationSentAt;
        if(hoursUsedTotal) this.hoursUsedTotal = hoursUsedTotal;
        if(hoursUsedTrackingStrategy) this.hoursUsedTrackingStrategy = hoursUsedTrackingStrategy;
        if(isSharedContract) this.isSharedContract = isSharedContract;
        if(isSharedContractWithRevenuesReceivedByPartner) this.isSharedContractWithRevenuesReceivedByPartner = isSharedContractWithRevenuesReceivedByPartner;
        if(lastComment) this.lastComment = lastComment;
        if(lastVersion) this.lastVersion = lastVersion;
        if(nextPayment) this.nextPayment = nextPayment;
        if(nonRetainableAmount) this.nonRetainableAmount = nonRetainableAmount;
        if(onGoingContractTerminatesOn) this.onGoingContractTerminatesOn = onGoingContractTerminatesOn;
        if(onGoingContractTerminationNotificationSentAt) this.onGoingContractTerminationNotificationSentAt = onGoingContractTerminationNotificationSentAt;
        if(payments) this.payments = payments;
        if(paymentsPlannedToInYears) this.paymentsPlannedToInYears = paymentsPlannedToInYears;
        if(paymentsPlannedToOrReceivedInYears) this.paymentsPlannedToOrReceivedInYears = paymentsPlannedToOrReceivedInYears;
        if(paymentsReceivedInYears) this.paymentsReceivedInYears = paymentsReceivedInYears;
        if(referenceNumber) this.referenceNumber = referenceNumber;
        if(shouldTrackHoursUsed) this.shouldTrackHoursUsed = shouldTrackHoursUsed;
        if(totalRevenue) this.totalRevenue = totalRevenue;
        if(totalValue) this.totalValue = totalValue;
        if(uid) this.uid = uid;
        if(workspaceId) this.workspaceId = workspaceId;
        if(yearRevenue) this.yearRevenue = yearRevenue;
        if(yearValue) this.yearValue = yearValue;
    }

    async comment({ activeUser, comment, mentions, plainText, workspaceId, workspaceName }: {
        activeUser: any;
        comment: string;
        mentions: any[];
        plainText: string;
        workspaceId: string;
        workspaceName: string;
    }){
        let result = null, error = null;

        const now = new Date();
        const nowISOString = now.toISOString();
        const newComment = {
            createdAt: nowISOString,
            createdBy: activeUser.uid,
            comment,
            plainText,
            mentionedUserIds: mentions.map(mention => mention.id)
        };
        
        const proposalRef = doc(db, `proposals/${this.uid}`);
        const proposalCommentRef = doc(collection(db, `proposals/${this.uid}/comments`));
        
        try {
            await runTransaction(db, async (transaction) => {
                const foundProposalSnapshot = await transaction.get(proposalRef);
                const foundProposal = foundProposalSnapshot.data();
                const contractSupervisedBy = foundProposal?.contractSupervisedBy || '';
                let contractSupervisedByEmail = '';
                if(contractSupervisedBy){
                    const foundUserSnapshot = await transaction.get(doc(db, `users/${contractSupervisedBy}`));
                    const foundUser = foundUserSnapshot.data();
                    contractSupervisedByEmail = foundUser?.email || '';
                }

                const foundWorkspacePaymentsEmailsSnapshot = await transaction.get(doc(db, `workspaces/${workspaceId}/_more/payments_emails`));
                const foundWorkspacePaymentsEmails = foundWorkspacePaymentsEmailsSnapshot.data();
                const controllerEmails: string[] = foundWorkspacePaymentsEmails?.controllersEmails || [];

                transaction.set(proposalCommentRef, {...newComment});
                transaction.update(proposalRef, {
                    [`commentsReadAt.${activeUser.uid}`]: nowISOString,
                    lastComment: { ...newComment, uid: proposalCommentRef.id }
                });

                let emailRecipients = [];
                controllerEmails.forEach(email => {
                    if(email !== activeUser.email) emailRecipients.push(email);
                });
                if(contractSupervisedByEmail && contractSupervisedByEmail !== activeUser.email) emailRecipients.push(contractSupervisedByEmail);
                //TODO: add last commenter

                if(newComment.mentionedUserIds.length !== 0){
                    for(const mentionedUserId of newComment.mentionedUserIds){
                        transaction.set(doc(collection(db, `users/${mentionedUserId}/notifications`)), {
                            createdAt: new Date().toISOString(),
                            description: `${activeUser.name} mencionou você em um comentário na proposta ${foundProposal?.description}: "${plainText.length > 100 ? `${plainText.substring(0, 200)}...` : plainText}"`,
                            title: `${workspaceName} | Financeiro | Comentário em proposta`,
                            type: 'message',
                            url: '',
                        });
                    }
                }

                transaction.set(doc(collection(db, 'emails')), {
                    createdAt: nowISOString,
                    to: emailRecipients.map(emailRecipient => ({ email: emailRecipient })),
                    reply_to: {
                        email: activeUser.email
                    },
                    subject: `Novo comentário na proposta ${this.description}`,
                    template_id: '0r83ql32pym4zw1j',
                    variables: emailRecipients.map(emailRecipient => ({
                        email: emailRecipient,
                        substitutions: [
                            { var: 'preheader', value: `${activeUser.name} fez um novo comentário.` },
                            { var: 'title', value: 'Novo comentário' },
                            { var: 'actionDescription', value: `Em ${moment(now).format('L')}, ${activeUser.name} comentou a proposta <strong>${this.description}</strong>${this.referenceNumber ? ` (${this.referenceNumber})` : ''}:` },
                            { var: 'contentRow2', value: '' },
                            { var: 'contentRow3', value: '' },
                            { var: 'comment', value: `"<em>${plainText}</em>"` },
                            { var: 'bottomRow', value: `Acesse o SOLIDA para tomar as providências necessárias.` },
                        ]
                    }))
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async createPayment({
        plannedTo,
        grossValue,
        taxDocumentNumber,
        taxDocumentIssuedOn,
        taxWithheld,
        receivedOn,
        taxPercentage,
    }: {
        grossValue: number;
        plannedTo: Date;
        receivedOn: Date;
        taxDocumentIssuedOn: Date;
        taxDocumentNumber: string;
        taxPercentage: number;
        taxWithheld: number;
    }){
        let result = null, error = null;
        
        const proposalRef = doc(db, `proposals/${this.uid}`);

        const newPayment = {
            plannedTo: plannedTo ? moment(plannedTo).format('YYYY-MM-DD') : '',
            grossValue,
            taxDocumentNumber,
            taxDocumentIssuedOn: taxDocumentIssuedOn ? moment(taxDocumentIssuedOn).format('YYYY-MM-DD') : '',
            taxWithheld,
            receivedOn: receivedOn ? moment(receivedOn).format('YYYY-MM-DD') : '',
            taxPercentage,
        };

        // @ts-ignore
        const newPaymentId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();

        const updates: { [key: string]: any; } = {
            [`payments.${newPaymentId}`]: newPayment,
        };

        let currentPayments = {...this.payments};
        currentPayments[newPaymentId] = newPayment;
        const {
            nextPayment,
            paymentsPlannedToInYears,
            paymentsPlannedToOrReceivedInYears,
            paymentsReceivedInYears,
            totalRevenue,
            yearRevenue
        } = getProposalInfoFromCurrentPayments(currentPayments);

        updates.nextPayment = nextPayment;
        updates.paymentsPlannedToInYears = paymentsPlannedToInYears;
        updates.paymentsPlannedToOrReceivedInYears = paymentsPlannedToOrReceivedInYears;
        updates.paymentsReceivedInYears = paymentsReceivedInYears;
        updates.totalRevenue = totalRevenue;
        updates.yearRevenue = yearRevenue;
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(proposalRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async createProposalVersion({
        activeUser,
        method,
        selectedWorkspace,
        versionFiles,
        versionName,
        versionType,
        versionUrl
    }: {
        activeUser: any;
        method: string;
        selectedWorkspace: any;
        versionFiles: any;
        versionName: string;
        versionType: string;
        versionUrl: string;
    }){
        let result = null, error = null;
        
        const proposalRef = doc(db, `proposals/${this.uid}`);
        const newProposalVersionRef = doc(collection(db, `proposals/${this.uid}/versions`));

        let fileFirebaseStoragePath = '';
        let fileFormat = '';
        let fileUrl = '';

        if(method === 'link'){
            fileUrl = versionUrl;
            const isGoogleDocUrlRegExTest = new RegExp(/docs\.google\.com\/document\/d\/[-\w]+\/edit/);
            fileFormat = isGoogleDocUrlRegExTest.test(versionUrl) ? 'googleDoc' : '';
        } else {
            for(const versionFile of versionFiles){
                const { contentType, fileBuffer } = await getFileContent(versionFile);
                const { result } = await uploadFile(
                    `workspaces/${selectedWorkspace.uid}/proposals/${this.clientId}/uploads/${versionFile.name}`, // storageFilePath
                    fileBuffer,
                    contentType
                );
                if(result){
                    const { downloadUrl, newFileFormat, storageFilePath } = result;
                    fileFirebaseStoragePath = storageFilePath;
                    fileFormat = newFileFormat;
                    fileUrl = downloadUrl;
                }
            }
        }

        const newVersion = new ProposalVersion({
            createdBy: activeUser.uid,
            fileFirebaseStoragePath,
            fileFormat,
            fileUrl,
            name: versionName,
            type: versionType,
        });
        const {uid: _, ...newVersionWithoutUid} = newVersion;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(newProposalVersionRef, {...newVersionWithoutUid});
                transaction.update(proposalRef, {
                    lastVersion: {...newVersionWithoutUid, uid: newProposalVersionRef.id}
                });
            });
            result = newProposalVersionRef.id;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async deleteDocumentESignature(versionId: string){
        let error = null, result = null;

        const callFunctionRes = await callFunction('deleteProposalVersionESignature', {
            documentId: this.uid,
            versionId
        });
        if(callFunctionRes.error || !callFunctionRes.result){
            error = true;
        } else {
            result = callFunctionRes.result;
        }

        return { error, result };
    }

    async deletePayment({
        deletedPaymentId,
    }: {
        deletedPaymentId: string;
    }){
        let result = null, error = null;

        const proposalRef = doc(db, `proposals/${this.uid}`);

        const updates: { [key: string]: any } = {
            [`payments.${deletedPaymentId}`]: deleteField(),
        };

        let currentPayments = {...this.payments};
        delete currentPayments[deletedPaymentId];
        const {
            nextPayment,
            paymentsPlannedToInYears,
            paymentsPlannedToOrReceivedInYears,
            paymentsReceivedInYears,
            totalRevenue,
            yearRevenue
        } = getProposalInfoFromCurrentPayments(currentPayments);

        updates.nextPayment = nextPayment;
        updates.paymentsPlannedToInYears = paymentsPlannedToInYears;
        updates.paymentsPlannedToOrReceivedInYears = paymentsPlannedToOrReceivedInYears;
        updates.paymentsReceivedInYears = paymentsReceivedInYears;
        updates.totalRevenue = totalRevenue;
        updates.yearRevenue = yearRevenue;
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(proposalRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async deleteProposalVersion({
        eSignaturePlatformDocumentId,
        versionId,
        versions
    }: {
        eSignaturePlatformDocumentId: boolean | string;
        versionId: string;
        versions: { [key: string]: any }[];
    }){
        let result = null, error = null;

        const proposalRef = doc(db, `proposals/${this.uid}`);
        const proposalVersionRef = doc(db, `proposals/${this.uid}/versions/${versionId}`);

        try {
            await runTransaction(db, async (transaction) => {
                if(versionId === this.lastVersion?.uid){
                    const versionIndex = versions.findIndex(currentVersion => currentVersion.uid === versionId);
                    const newLastVersion = versions[versionIndex - 1];
                    if(newLastVersion){
                        transaction.update(proposalRef, {
                            lastVersion: {...newLastVersion}
                        });
                    }
                }
                transaction.delete(proposalVersionRef);

            });
        } catch (e) {
            error = e;
        }
            
        if(eSignaturePlatformDocumentId){
            // PREVIOUSLY /data/accounting/proposals/document/delete 
            const res = await callFunction('deleteSignaturePlatformDocument', {
                documentToken: eSignaturePlatformDocumentId
            });
            if(res.error){
                console.log(error);
            }
        }

        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;
        
        const proposalRef = doc(db, `proposals/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(proposalRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateMonth({ months, updates }: {
        months: any[];
        updates: {[key: string]: any};
    }){
        let result = null, error = null;

        let hoursUsedTotal = 0;
        months.forEach(month => {
            hoursUsedTotal += (month.amount || 0);
        });
        
        const proposalRef = doc(db, `proposals/${this.uid}`);
        const proposalMonthRef = doc(db, `proposals/${this.uid}/months/${updates.year} - ${updates.month}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(proposalMonthRef, updates, { merge: true });
                transaction.update(proposalRef, { hoursUsedTotal });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updatePayment({
        grossValue,
        plannedTo,
        receivedOn,
        taxDocumentIssuedOn,
        taxDocumentNumber,
        taxPercentage,
        taxWithheld,
        updatedPaymentId,
    }: {
        grossValue: number;
        plannedTo: Date;
        receivedOn: Date;
        taxDocumentIssuedOn: Date;
        taxDocumentNumber: string;
        taxPercentage: number;
        taxWithheld: number;
        updatedPaymentId: string;
    }){
        let result = null, error = null;

        const proposalRef = doc(db, `proposals/${this.uid}`);

        const currentPayment = {
            plannedTo: plannedTo ? moment(plannedTo).format('YYYY-MM-DD') : '',
            grossValue,
            taxDocumentNumber,
            taxDocumentIssuedOn: taxDocumentIssuedOn ? moment(taxDocumentIssuedOn).format('YYYY-MM-DD') : '',
            taxWithheld,
            receivedOn: receivedOn ? moment(receivedOn).format('YYYY-MM-DD') : '',
            taxPercentage,
        };

        const updates: { [key: string]: any } = {
            [`payments.${updatedPaymentId}`]: currentPayment,
        };

        let currentPayments = {...this.payments};
        currentPayments[updatedPaymentId] = currentPayment;
        const {
            nextPayment,
            paymentsPlannedToInYears,
            paymentsPlannedToOrReceivedInYears,
            paymentsReceivedInYears,
            totalRevenue,
            yearRevenue
        } = getProposalInfoFromCurrentPayments(currentPayments);

        updates.nextPayment = nextPayment;
        updates.paymentsPlannedToInYears = paymentsPlannedToInYears;
        updates.paymentsPlannedToOrReceivedInYears = paymentsPlannedToOrReceivedInYears;
        updates.paymentsReceivedInYears = paymentsReceivedInYears;
        updates.totalRevenue = totalRevenue;
        updates.yearRevenue = yearRevenue;
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(proposalRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}