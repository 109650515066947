import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FlagIcon from '@mui/icons-material/Flag';

import Input from '../Input';
import Window from '../Window';

import { useAppStateCtx } from '../../context/AppState';
import { useDocumentsAPI, useDocumentsCtxFlagDocumentView } from '../../context/DocumentsContext';
import { useAppCtxAPI } from '../../context/SystemContext';
import { flagDocument } from '../../utils/common';

const FlagDocumentWindow = ({ activeUserIsOperator }) => {
    useSignals();
    const { activeUser, workspaceProjects, workspaceUsers } = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();
    const { hideFlagDocumentView } = useDocumentsAPI();
    const { flagDocumentViewOpen, flagDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxFlagDocumentView();
    const [flagComment, set_flagComment] = useState('');

    useEffect(() => {
        if(flagDocumentViewOpen){
            set_flagComment('');
        }
    }, [flagDocumentViewOpen]);

    const handleConfirm = async () => { 
        handleClose();
        const type = activeUserIsOperator ? 'operator' : 'client';
        flagDocument({
            activeUser: activeUser.value, 
            comment: flagComment,
            projects: workspaceProjects.value,
            selectedDocument,
            setLoading,
            toast,
            type,
            users: workspaceUsers.value
        });
    };

    const handleClose = () => {
        hideFlagDocumentView();
    };

    return (
        <Window
            open={flagDocumentViewOpen} onClose={handleClose}
            title="Sinalizar documento"
            submitButtonStartIcon={<FlagIcon />}
            submitButtonText="Sinalizar"
            handleSubmitButtonClick={handleConfirm}
        >
            <Box mb={3}>
                <Box mb={2}>
                    <Typography variant="body1">Você quer sinalizar o documento "<strong>{selectedDocument?.name}</strong>"? Isso notificará {activeUserIsOperator ? 'o(a) solicitante' : 'o Jurídico'}.</Typography>
                </Box>
                <Input
                    autoFocus
                    fullWidth multiline
                    label="Comentário (opcional)"
                    value={flagComment} onChange={(e) => set_flagComment(e.target.value)}
                />
            </Box>
        </Window>
    );
};

export default FlagDocumentWindow;