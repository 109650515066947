import React, { useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Autocomplete from '../../../Autocomplete';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { getProjectNameById } from '../../../../utils/common';

const CompanyField = ({addDocumentForm, handleSelectedCompanyChange}) => {
    useSignals();
    const { workspaceClients } = useAppStateCtx();
    const { mappedWorkspaceClients } = useAppStateCtxUtils();
    const [selectedCompany, setSelectedCompany] = useState(addDocumentForm.current.companyId || '');
    const handleChange = (newValue) => {
        setSelectedCompany(newValue);
        handleSelectedCompanyChange(newValue);
    };

    if(workspaceClients.value && mappedWorkspaceClients.value){
        return (
            <Autocomplete
                label="Cliente"
                value={selectedCompany}
                onChange={(_, newValue) => handleChange(newValue)}
                options={mappedWorkspaceClients.value.map(client => client.uid)}
                getOptionLabel={(option) => getProjectNameById(workspaceClients.value, option)}
            />
        );
    }
};

export default CompanyField;