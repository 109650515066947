import { useEffect, useState } from 'react';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import FilterInput from '../../../../components/FilterInput';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import Pagination from '../../../../components/Pagination';
import TinyTable from '../../../../components/TinyTable';
import ViewBox from '../../../../components/ViewBox';
import AddUserFieldWindow from './components/AddUserFieldWindow';
import { escapeRegex, getTemplateNameById } from '../../../../utils/common';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { sortByKey } from '../../../../utils/filters';

const userCreatedFieldTypes = {
    'string': {name: 'Texto'},
    'boolean': {name: 'Verdadeiro ou falso'},
    'select': {name: 'Múltipla escolha'}
};

const filterText = signal('');

const FilterText = () => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value])

    return (
        <FilterInput value={value} setValue={setValue} placeholder="Digite para filtrar" />
    );
};

const Pages = ({ count, currentPage, perPage, handlePageClick }) => {
    if(filterText.value.length === 0){
        return (
            <Pagination count={count} page={currentPage} perPage={perPage} onChange={(_, page) => handlePageClick(page)} />
        );
    }
    return null;
};

const loading = signal(true);
const availableFields = signal(null);
const visibleFields = signal(null);

const WorkspaceProjectsCustomFieldsList = ({ selectedField }) => {
    useSignals();
    const { workspaceDocumentsTemplates } = useAppStateCtx();
    const [currentPage, setCurrentPage] = useState(1);

    const perPage = 100;

    const showPage = () => {
        if(availableFields.value){
            const firstIndex = (currentPage - 1) * perPage;
            const lastIndex = (currentPage * perPage);
            const pageArray = availableFields.value.slice(firstIndex, lastIndex);
            visibleFields.value = pageArray;
        }
    }

    useEffect(() => {
        if(filterText.value.length !== 0){
            visibleFields.value = availableFields.value.filter(field => {
                const textFields = `${field.name}`;
                if(filterText.value.length === 1){
                    const regEx = new RegExp(`^${escapeRegex(filterText.value).toUpperCase()}`);
                    return regEx.test(textFields.toUpperCase());
                }
                return textFields.toUpperCase().indexOf(filterText.value.toUpperCase()) !== -1;
            })
        } else {
            showPage();
        }
    }, [filterText.value, availableFields.value, currentPage]);

    const handleFieldSelect = (field) => {
        selectedField.value = field.data;
        workspaceProjectCustomFieldViewOpen.value = true;
    };

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };

    if(loading.value){
        return (
            <LoaderEllipsis />
        )
    }
    return (
        <>
            <Pages count={availableFields.value.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
            <Box pb={5}>
                <Container maxWidth="md">
                    <TinyTable
                        head={[
                            { content: <Typography variant="body1">Nome</Typography> },
                            { content: <Typography variant="body1">Tipo</Typography> },
                            { content: <Typography variant="body1">Matrizes</Typography> }
                        ]}
                        body={
                            visibleFields.value.map((customField) => {
        
                                const templateIds = customField.template;
                                let templateNames = '';
                                if(templateIds && workspaceDocumentsTemplates.value){
                                    if(Array.isArray(templateIds)){
                                        let templateNamesArray = [];
                                        templateIds.forEach(templateId => {
                                            let templateName = getTemplateNameById(templateId, workspaceDocumentsTemplates.value, true);
                                            if(templateName) templateNamesArray.push(templateName);
                                        });
                                        templateNames = templateNamesArray.join('; ');
                                    } else {
                                        templateNames = getTemplateNameById(templateIds, workspaceDocumentsTemplates.value, true);
                                    }
                                }
        
                                const columns = [
                                    { content: <Typography variant="body2">{customField.name}</Typography> },
                                    { content: <Typography variant="body2">{userCreatedFieldTypes[customField.type]?.name || ''}</Typography> },
                                    { content: <Typography variant="body2">{templateNames}</Typography> },
                                ];
        
                                return ({
                                    data: customField,
                                    columns
                                })
                            })
                        }
                        handleBodyRow={handleFieldSelect}
                    />
                </Container>
                <Pages count={availableFields.value.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
            </Box>
        </>
    );
}

const selectedField = signal(null);
const workspaceProjectCustomFieldViewOpen = signal(false);

function UserCreatedFields(){
    useSignals();
    const { workspaceProjectsCustomFields } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();

    useEffect(() => {
        return () => {
            loading.value = true;
            selectedField.value = null;
        };
    }, []);
    
    useEffect(() => {
        setFirestoreListener('workspaceProjectsCustomFields');
        setFirestoreListener('workspaceDocumentsTemplates');
    }, []);

    useEffect(() => {
        if(workspaceProjectsCustomFields.value){
            const currentAvailableFields = workspaceProjectsCustomFields.value.sort(sortByKey('name'));
            availableFields.value = currentAvailableFields;
        };
    }, [workspaceProjectsCustomFields.value]);

    useEffect(() => {
        if(workspaceProjectsCustomFields.value && visibleFields.value){
            loading.value = false;
        }
    }, [workspaceProjectsCustomFields.value, visibleFields.value]);
    
    const addField = () => {
        selectedField.value = null;
        workspaceProjectCustomFieldViewOpen.value = true;
    };

    const handleAddItemButtonClick = () => {
        addField();
    };
    
    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>

            <Box mb={2}>
                <Grid container justifyContent="flex-end" alignContent="flex-end">
                    <Grid size="grow">
                        <FilterText />
                    </Grid>
                    <Grid size={{ xs: 6 }}></Grid>
                    <Grid>
                        <Button variant="contained" onClick={handleAddItemButtonClick} startIcon={<AddIcon />}>Nova informação especial</Button>
                    </Grid>
                </Grid>
            </Box>

            <WorkspaceProjectsCustomFieldsList selectedField={selectedField} />

            <AddUserFieldWindow
                open={workspaceProjectCustomFieldViewOpen}
                selectedField={selectedField}
            />

        </ViewBox>
    );
}

export default UserCreatedFields;