import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import DocumentFormAIGeneratedSuggestionInput from '../components/DocumentFormAIGeneratedSuggestionInput';
import { useAppStateCtx } from '../context/AppState';
import { CNAEs } from '../utils/cnaes';

import Autocomplete from './Autocomplete';

const generateAISuggestionTriggered = signal(false);
const generatingAISuggestion = signal(false);

const ListQuestion = ({
    activityCodes,
    CNPJContainsActivityCode,
    activityAllowedForMEI,
    foundActivityCodeInCNPJ,
    foundCNPJ, setFoundCNPJ,
    handleUserAwareOfErrorsChange,
    initialValue,
    onChange,
    question,
    selectedList,
    showCorporationIdActivityError,
    showMEIActivityError,
    userAwareOfErrors,
}) => {
    useSignals();
    const { formQuestionNameToCNPJ } = useAppStateCtx();
    const defaultValue = { choice: '', input: '', listItemAliases: [], listItemDescription: '', listItemId: '', selectedListItem: null };
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(prevState => {
            if(initialValue && selectedList){
                const selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === initialValue.input.toUpperCase());
                return {...prevState, ...initialValue, selectedListItem};
            }
            return prevState;
        });
    }, [initialValue, selectedList]);

    useEffect(() => {
        if(onChange){
            const selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === value.input.toUpperCase());
            onChange(question.name, {...value, selectedListItem});
        }
    }, [value]);

    useEffect(() => {
        if(setFoundCNPJ && formQuestionNameToCNPJ.value && question.corporationIdQuestion){
            setFoundCNPJ(formQuestionNameToCNPJ.value[question.corporationIdQuestion] || null);
        }
    }, [formQuestionNameToCNPJ.value]);
    
    const handleChoiceChange = (newValue) => {
        let choice = '', listItemAliases = [], listItemDescription = '', listItemId = '', selectedListItem = null;
        if(newValue){
            selectedListItem = newValue;
            choice = newValue.key;
            listItemAliases = [newValue.key, ...(newValue.aliases || [])];
            listItemId = newValue.listItemId;
            listItemDescription = newValue.value.replace(/<br \/>/g, '\n').trim();
        }
        setValue(prevState => ({...prevState, choice, listItemAliases, listItemDescription, listItemId, selectedListItem}));
    };
    const handleInputChange = (newValue) => {
        generateAISuggestionTriggered.value = false;
        let selectedListItem = null;
        if(newValue) selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === newValue.toUpperCase());
        let choice = '', listItemAliases = [], listItemDescription = '', listItemId = '';
        if(selectedListItem){
            choice = selectedListItem.key;
            listItemAliases = [selectedListItem.key, ...(selectedListItem.aliases || [])];
            listItemId = selectedListItem.listItemId;
            listItemDescription = selectedListItem.value.replace(/<br \/>/g, '\n').trim();
        }
        setValue(prevState => ({...prevState, input: newValue, choice, listItemAliases, listItemDescription, listItemId}));
    };
    const handleListItemDescriptionChange = (newValue) => {
        let selectedListItem = null;
        if(value.choice) selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === value.choice.toUpperCase());
        let listItemDescriptionChanged;
        if(selectedListItem) listItemDescriptionChanged = newValue !== selectedListItem.value.replace(/<br \/>/g, '\n').trim();
        setValue(prevState => ({...prevState, listItemDescription: newValue, listItemDescriptionChanged}));
    };

    const handleBlur = () => {
        if(!value.choice && value.input && question.shouldGetAISuggestionFromText) generateAISuggestionTriggered.value = true;
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs>
                    <Autocomplete
                        placeholder="Sua resposta"
                        options={selectedList} getOptionLabel={(option) => option.key}
                        freeSolo
                        value={value.selectedListItem}
                        onChange={(_, newValue) => handleChoiceChange(newValue)}
                        inputValue={value.input}
                        onInputChange={(_, newValue) => handleInputChange(newValue)}
                        onBlur={handleBlur}
                    />

                    <Collapse in={question.showListDescription && !!value.choice && !!value.selectedListItem && !!value.selectedListItem.value.trim()}>
                        <Box mt={2}>
                            <Box mb={1}>
                                <Typography variant="h6">Detalhamento:</Typography>
                            </Box>
                            <TextField
                                variant="standard"
                                fullWidth
                                multiline
                                value={value.listItemDescription}
                                onChange={(e) => handleListItemDescriptionChange(e.target.value)} />
                            <Box>
                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <Typography variant="body2">Se você alterar a opção na pergunta "{question.name}", o detalhamento também será alterado.</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Collapse>
                </Grid>
                {
                    foundActivityCodeInCNPJ &&
                    <Grid item xs={12} sm={6} md={4} container spacing={1} alignItems="center" wrap="nowrap">
                        <Grid item xs>
                            <Typography variant="body2">O código CNAE correspondente ao serviço foi encontrato no CNPJ da empresa.</Typography>
                        </Grid>
                        <Grid item>
                            <CheckCircleIcon fontSize="small" htmlColor="green" />
                        </Grid>
                    </Grid>
                }
            </Grid>
            {
                (handleUserAwareOfErrorsChange && foundCNPJ && value.input && activityCodes && (showCorporationIdActivityError || showMEIActivityError)) &&
                <Box>
                    {
                        (showCorporationIdActivityError && !CNPJContainsActivityCode) &&
                        <Box mt={1}>
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid item>
                                    <CancelIcon htmlColor="red" fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{color: 'red'}}>Os principais códigos da CNAE associados a essa atividade não foram encontrados no CNPJ da empresa. Códigos principais:</Typography>
                                    {
                                        activityCodes.map(activityCode => {
                                            let description = '';
                                            const foundCode = CNAEs.find(cnae => cnae.code === activityCode);
                                            if(foundCode) description = foundCode.description;
                                            return (
                                                <Typography key={activityCode} variant="body1" style={{color: 'red'}}>&bull; {activityCode} ({description})</Typography>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    {
                        (showMEIActivityError && foundCNPJ.isMEI && !activityAllowedForMEI) &&
                        <Box mt={1}>
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid item>
                                    <CancelIcon htmlColor="red" fontSize="small" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body1" style={{color: 'red'}}>O CNPJ pertence a um Microempreendedor Individual (MEI), mas essa atividade não está listada entre aquelas geralmente permitidas para MEI.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                        <Grid item>
                            <Checkbox size="small" color="primary"
                                checked={userAwareOfErrors}
                                onChange={(e) => handleUserAwareOfErrorsChange(e.target.checked)}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Estou ciente, mas quero enviar o formulário mesmo assim.</Typography>
                        </Grid>
                    </Grid>
                </Box>
            }
            <DocumentFormAIGeneratedSuggestionInput
                aiGeneratedSuggestionKey="listItemDescription"
                generateAISuggestionTriggered={generateAISuggestionTriggered}
                generatingAISuggestion={generatingAISuggestion}
                question={question}
                mainValue={value.input}
                setQuestionValue={setValue}
            />
        </Box>
    );
}

export default ListQuestion;