import { Buffer } from 'buffer';

import { uploadFile } from '../firebase/storage/uploadFile';

type ConstructorArgs = {
    fileFirebaseStoragePath?: string;
    fileUrl?: string;
};

export default class DocumentsTemplate_MoreFile {
    fileFirebaseStoragePath = '';
    fileUrl = '';

    constructor({
        fileFirebaseStoragePath,
        fileUrl,
    }: ConstructorArgs){
        this.fileFirebaseStoragePath = fileFirebaseStoragePath || '';
        this.fileUrl = fileUrl || '';
    }

    async update({ updates, workspaceId }: {
        updates: {[key: string]: any};
        workspaceId: string
    }){
        // let result = null, error = null;
        
        // const projectRef = doc(db, `projects/${this.uid}`);
        // const workspaceProjectsRef = doc(db, `workspaces/${workspaceId}/_more/projects`);
        
        // try {
        //     await runTransaction(db, async (transaction) => {
        //         transaction.update(projectRef, updates);
        //         const workspaceProjectsUpdates: { [key: string]: any } = {};
        //         for(const updateKey in updates){
        //             workspaceProjectsUpdates[`projects.${this.uid}.${updateKey}`] = updates[updateKey];
        //         }
        //         transaction.update(workspaceProjectsRef, workspaceProjectsUpdates);
        //     });
        //     result = true;
        // } catch (e) {
        //     error = e;
        // }

        // return { result, error };
    }

    async uploadFile(workspaceId: string, templateId: string, fileContent: { effects: { [effectId: string]: any }[]; main: string; lists?: string[]; }){
        let error = null, result = null;

        const fileBuffer = Buffer.from(JSON.stringify(fileContent));
        const contentType = 'application/json';
        let fileFirebaseStoragePath = `workspaces/${workspaceId}/documents_templates/${templateId}/${new Date().getTime()}.json`;
        const uploadFileRes = await uploadFile(
            fileFirebaseStoragePath,
            fileBuffer,
            contentType
        );
        if(uploadFileRes.error || !uploadFileRes.result){
            error = uploadFileRes.error || new Error('X');
        } else {
            result = { fileFirebaseStoragePath: uploadFileRes.result.storageFilePath, fileUrl: uploadFileRes.result.downloadUrl };
        }
        
        return { error, result };
    }
    
}