import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PopOver from '../../../../../components/PopOver';
import Select from '../../../../../components/Select';
import VirtualizedAutocomplete from '../../../../../components/VirtualizedAutocomplete';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../../../context/AppState';

const ClientSelector = ({ filterClient, loading, onChange }) => {
    useSignals();
    const { workspaceClients } = useAppStateCtx();
    const { mappedWorkspaceClients } = useAppStateCtxUtils();

    if(workspaceClients.value){
        return (
            <Grid item xs={12} sm={6}>
                <VirtualizedAutocomplete
                    disabled={loading.value}
                    label="Cliente"
                    variant="standard"
                    options={['all', ...mappedWorkspaceClients.value.map(client => client.uid)]}
                    getOptionLabel={
                        (option) => {
                            if(option === 'all') return '(Todas)';
                            const foundClient = mappedWorkspaceClients.value.find(client => client.uid === option);
                            if(foundClient){
                                return foundClient.label;
                            }
                            return option;
                        }
                    }
                    value={filterClient.value}
                    onChange={(_, newValue) => onChange(newValue)}
                />
            </Grid>
        );
    }
    return null;
}

const ProjectSelector = ({ filterProject, loading, onChange, projectOptions }) => {
    useSignals();

    return (
        <VirtualizedAutocomplete
            disabled={loading.value}
            label="Projeto"
            variant="standard"
            options={['all', ...projectOptions.value.map(project => project.uid)]}
            getOptionLabel={
                (option) => {
                    if(option === 'all') return '(Todas)';
                    const foundClient = projectOptions.value.find(project => project.uid === option);
                    if(foundClient){
                        return foundClient.label;
                    }
                    return option;
                }
            }
            value={filterProject.value}
            onChange={(_, newValue) => onChange(newValue)}
        />
    );
}

const StatusSelector = ({ filterStatus, loading, onChange }) => {
    useSignals();

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <Select
            disabled={loading.value}
            label="Situação"
            options={[
                {value: 'sent', label: 'Enviados'},
                {value: 'scheduledSent', label: 'Enviados automaticamente'},
                {value: 'deleted', label: 'Cancelados'},
                {value: 'all', label: 'Todos'}
            ]}
            value={filterStatus.value} onChange={handleChange}
        />
    );
}

const OrderBySelector = ({ filterOrderBy, filterStatus, loading, onChange }) => {
    useSignals();

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    if(filterStatus.value === 'sent'){
        return (
            <Grid item xs={12} sm={6}>
                <Select
                    disabled={loading.value}
                    label="Ordem"
                    options={[
                        {value: 'sentOn', label: 'Enviados recentemente'},
                        {value: 'timestamp', label: 'Solicitados recentemente'}
                    ]}
                    value={filterOrderBy.value} onChange={handleChange}
                />
            </Grid>
        );
    }
    return null;
}

const SignatureStatusSelector = ({ filterSignatureStatus, loading, onChange }) => {
    useSignals();

    const handleChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <Select
            disabled={loading.value}
            label="Assinatura eletrônica"
            options={[
                {value: 'none', label: 'Nenhum'},
                {value: 'eSignaturePending', label: 'Assinatura eletrônica não concluída'},

                {value: 'eSignatureSigned', label: 'Documentos assinados eletronicamente'}
            ]}
            value={filterSignatureStatus.value || 'select'} onChange={handleChange}
        />
    );
}

const FiltersPopOver = ({
    clearFilters,
    filtersPopOverAnchorEl, filtersPopOverOpen,
    filterClient,
    filterOrderBy,
    filterProject,
    filterSignatureStatus,
    filterStatus,
    handleClientChange,
    handleProjectChange,
    loading,
    projectOptions,
}) => {
    useSignals();

    const handleClearFiltersClick = () => {
        handleClose();
        clearFilters();
    };
    const handleClose = () => {
        filtersPopOverOpen.value = false;
    };
    
    const handleLocalProjectChange = (newValue) => {
        handleClose();
        handleProjectChange(newValue);
    };
    const handleFiltersStatusChange = (newValue) => {
        handleClose();
        filterStatus.value = newValue;
    };
    const handleFiltersOrderChange = (newValue) => {
        handleClose();
        filterOrderBy.value = newValue;
    };
    const handleFiltersSignatureStatusChange = (newValue) => {
        handleClose();
        filterSignatureStatus.value = newValue;
    };

    return (
        <PopOver open={filtersPopOverOpen.value} onClose={handleClose} anchorEl={filtersPopOverAnchorEl.value}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Typography variant="h5">Filtros</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={handleClearFiltersClick}
                        >Limpar</Button>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <ClientSelector filterClient={filterClient} loading={loading} onChange={handleClientChange} />
                    <Grid item xs={12} sm={6}>
                        <ProjectSelector filterProject={filterProject} loading={loading} onChange={handleLocalProjectChange} projectOptions={projectOptions} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StatusSelector filterStatus={filterStatus} loading={loading} onChange={handleFiltersStatusChange} />
                    </Grid>
                    <OrderBySelector filterOrderBy={filterOrderBy} filterStatus={filterStatus} loading={loading} onChange={handleFiltersOrderChange} />
                    <Grid item xs={12} sm={6}>
                        <SignatureStatusSelector filterSignatureStatus={filterSignatureStatus} loading={loading} onChange={handleFiltersSignatureStatusChange} />
                    </Grid>
                </Grid>
            </Box>
        </PopOver>
    );
};

export default FiltersPopOver;