import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';

const useGetDocumentTemplateSettings = (templateId) => {
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(templateId){
            setHookState(defaultHookState);
            const unsubscribe = onSnapshot(doc(db, `documents_templates/${templateId}/_more/settings`),
                (snapshot) => {
                    const data = snapshot.data();
                    setHookState({
                        loading: false,
                        data: data
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [templateId]);

    return hookState;
};

export default useGetDocumentTemplateSettings;