import { useEffect, useState } from 'react';
import { and, collection, onSnapshot, or, query, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';

const useGetProposalsWithPaymentsAsOfPreviousYear = (shouldSetListener, selectedYear) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value?.role !== 'operator' && selectedYear){
            setHookState(defaultHookState);
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'proposals'),
                    and(
                        where(`deleted`, '==', false),
                        where('workspaceId', '==', selectedWorkspace.value.uid),
                        where(`accepted`, '==', 'accepted'),
                        or(
                            where(`yearValue.${selectedYear}`, '>', 0),
                            where(`paymentsPlannedToOrReceivedInYears`, 'array-contains-any', [selectedYear - 1, selectedYear]),
                        )
                    )
                ),
                (snapshot) => {
                    const dataArray = [];
                    snapshot.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            uid: snapshot.id,
                        });
                    });
                    setHookState(prevState => ({...prevState, loading: false, data: dataArray }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value, selectedYear]);

    return hookState;
};

export default useGetProposalsWithPaymentsAsOfPreviousYear;