import { useEffect, useState, useRef } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { Button, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import ClearIcon from '@mui/icons-material/Clear';
import ChipWithMenu from '../../../../components/ChipWithMenu';
import FilterListIcon from '@mui/icons-material/FilterList';
import FiltersPopOver from '../Pending/components/FiltersPopOver';
import PendingDocumentReviewerSelector from './PendingDocumentReviewerSelector';
import FilterInput from '../../../../components/FilterInput';
import Tooltip from '../../../../components/Tooltip';
import { useAppStateCtx } from '../../../../context/AppState';
import { getProjectNameById } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';


const TextFilter = ({ filterText }) => {
    useSignals();
    const [value, setValue] = useState('');
    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value]);
    
    const handleChange = (newValue) => {
        setValue(newValue);
    }

    return (
        <FilterInput value={value} setValue={handleChange} />
    );
}

const ClientSelector = ({ filterClientId, clientOptions, onChange, setClientOptions, visibleDocuments }) => {
    useSignals();
    const { workspaceClients } = useAppStateCtx();

    const handleOpen = () => {
        if(visibleDocuments.value){
            let currentClientOptions = [];
            visibleDocuments.value.forEach(document => {
                if(!currentClientOptions.includes(document.clientId)) currentClientOptions.push(document.clientId);
            });
            currentClientOptions = currentClientOptions.map(projectId => ({value: projectId, label: getProjectNameById(workspaceClients.value, projectId)})).sort(sortByKey('label'));
            setClientOptions(currentClientOptions);
        }
    }

    return (
        <ChipWithMenu
            label="Cliente"
            onChange={onChange}
            onOpen={handleOpen}
            options={[{value: 'all', label: 'Todas'}, ...clientOptions]}
            value={filterClientId.value}
        />
    )
}

const ProjectSelector = ({ filterProjectId, projectOptions, setDocumentProjects, visibleDocuments }) => {
    useSignals();

    const handleChange = (newValue) => {
        filterProjectId.value = newValue;
    }

    const handleOpen = () => {
        if(visibleDocuments.value){
            let currentDocumentProjects = [];
            visibleDocuments.value.forEach(document => {
                if(document.projectId && !currentDocumentProjects.includes(document.projectId)) currentDocumentProjects.push(document.projectId);
            });
            setDocumentProjects(currentDocumentProjects);
        }
    }

    return (
        <ChipWithMenu
            label="Projeto"
            onChange={handleChange}
            onOpen={handleOpen}
            options={[{value: 'all', label: 'Todas'}, ...projectOptions]}
            value={filterProjectId.value}
        />
    );
}

const ClearFiltersButton = ({ clearFilters, filterClientId, filterOperatorId, filterProjectId, filterText }) => {
    if(filterText.value || filterClientId.value !== 'all' || filterProjectId.value !== 'all' || filterOperatorId.value !== 'all'){
        return (
            <Grid item>
                <Tooltip text="Limpar filtros">
                    <IconButton size="small" onClick={clearFilters}><ClearIcon /></IconButton>
                </Tooltip>
            </Grid>
        )
    }
    return null;

}

const NumberOfVisibleDocuments = ({numberOfVisibleDocuments}) => {
    useSignals();
    return (
        <Typography variant="body2">({numberOfVisibleDocuments.value || 0} documentos)</Typography>
    );
}

const OperatorDocumentsSinglePageFilters = ({
    clearFilters,
    filterClientId,
    filterProjectId,
    filterOperatorId,
    filterText,
    handleClientChange,
    loading,
    numberOfVisibleDocuments,
    visibleDocuments
}) => {
    useSignals();
    const { workspaceProjects } = useAppStateCtx();
    const theme = useTheme();
    const screenMDDownExclusive = useMediaQuery(theme.breakpoints.down('md'));
    const filtersButtonRef = useRef();
    const [filtersPopOverAnchorEl, set_filtersPopOverAnchorEl] = useState(null);
    const [filtersPopOverOpen, set_filtersPopOverOpen] = useState(false);
    const [clientOptions, setClientOptions] = useState([]);
    const [documentProjects, setDocumentProjects] = useState([]);
    const [projectOptions, set_projectOptions] = useState([]);

    useEffect(() => {
        if(documentProjects.length !== 0){
            getProjectOptions();
        }
    }, [documentProjects]);

    const getProjectOptions = (clientId) => {
        let currentProjectOptions = documentProjects;
        if(clientId && clientId !== 'all'){
            currentProjectOptions = currentProjectOptions.filter(projectId => {
                const foundProject = workspaceProjects.value[projectId];
                if(foundProject) return foundProject.clientId === clientId;
                return false;
            });
        }
        currentProjectOptions = currentProjectOptions.map(projectId => ({value: projectId, label: getProjectNameById(workspaceProjects.value, projectId)})).sort(sortByKey('label'));
        set_projectOptions(currentProjectOptions);
    };

    const handleFiltersButtonClick = () => {
        set_filtersPopOverAnchorEl(filtersButtonRef.current);
        set_filtersPopOverOpen(true);
    };

    return (
        <>
            {
                screenMDDownExclusive
                ?
                <Grid item>
                    <Button
                        disabled={loading}
                        ref={filtersButtonRef}
                        variant="outlined" startIcon={<FilterListIcon />}
                        onClick={handleFiltersButtonClick}
                    >Filtros</Button>
                </Grid>
                :
                <>
                    <Grid item>
                        <TextFilter filterText={filterText} />
                    </Grid>
                    <Grid item>
                        <ClientSelector filterClientId={filterClientId} clientOptions={clientOptions} onChange={handleClientChange} setClientOptions={setClientOptions} visibleDocuments={visibleDocuments} />
                    </Grid>
                    <Grid item>
                        <ProjectSelector filterProjectId={filterProjectId} projectOptions={projectOptions} setDocumentProjects={setDocumentProjects} visibleDocuments={visibleDocuments} />
                    </Grid>
                    <Grid item>
                        <PendingDocumentReviewerSelector filterOperatorId={filterOperatorId} />
                    </Grid>
                    <Grid item>
                        <NumberOfVisibleDocuments numberOfVisibleDocuments={numberOfVisibleDocuments} />
                    </Grid>
                    <ClearFiltersButton
                        clearFilters={clearFilters}
                        filterClientId={filterClientId}
                        filterProjectId={filterProjectId}
                        filterOperatorId={filterOperatorId}
                        filterText={filterText}
                    />
                </>
            }

            <FiltersPopOver
                filterClientId={filterClientId}
                filterProjectId={filterProjectId}
                filterOperatorId={filterOperatorId}
                filterText={filterText}

                data={{
                    filtersPopOverOpen, set_filtersPopOverOpen, filtersPopOverAnchorEl,

                    clientOptions,
                    handleClientChange,
                    loadingPage: loading,
                    projectOptions,
                }}
            />
        </>
    );
};

export default OperatorDocumentsSinglePageFilters;