const formEffectTypes = [
    {value: 'text', label: 'Texto'},
    {value: 'radio', label: 'Múltipla escolha'},
    {value: 'radioGrid', label: 'Grade de múltipla escolha'},
    {value: 'list', label: 'Lista (autocompletar)'},
    {value: 'date', label: 'Data'},
    {value: 'currency', label: 'Valor ($)'},
    {value: 'number', label: 'Número'},
    {value: 'individualId', label: 'CPF'},
    {value: 'corporationId', label: 'CNPJ'},
    {value: 'file', label: 'Anexar arquivos'},
    {value: 'if', label: 'Se'},
    {value: 'ifNot', label: 'Se não'},
    {value: 'ifTrue', label: 'Se verdadeiro'},
    {value: 'special', label: 'Importar dados'},
    {value: 'schedule', label: '[Avançado] Cronograma de execução do contrato'},
    {value: 'actorSchedule', label: '[Avançado] Cronograma do contrato de elenco'},
    {value: 'paymentSchedule', label: '[Avançado] Cronograma de pagamento'},
    {value: 'movablePropertyLeaseDetails', label: '[Avançado] Detalhamento da locação de bens móveis'},
];
const hiddenEffectTypes = [
    {value: 'heading', label: 'Cabeçalho'}
];
const allEffectTypes = [...formEffectTypes, ...hiddenEffectTypes];
const ACTIVE_PROJECT_TYPES = [
    {value: 'atendimento', label: 'Atendimento', color: '#f6b26b', minWidth: '96.95px'},
    {value: 'contratos', label: 'Contratos', color: '#6d9eeb', minWidth: '77.91px'},
    {value: 'clearance', label: 'Clearance', color: '#93c47d', minWidth: '78.03px'},
    {value: 'alvaras', label: 'Alvarás', color: '#ffd966', minWidth: '62.89px'},
    {value: 'defesa', label: 'Defesa', color: '#e06666', minWidth: '59.88px'},
    {value: 'marca', label: 'Registro de marca', color: '#8e7cc3', minWidth: '126.55px'},
    {value: 'coproducaoInternacional', label: 'Coprodução internacional', color: '#38761d', minWidth: '173.11px'},
    {value: 'other', label: 'Outro', color: '#b7b7b7', minWidth: '54.28px'},
];
const APP_USER_ID = '621257169023272acc8ba287'; // app@solidasistemas.com.br
const ASSIGNABLE_FIELDS = [
    { id: 'type', name: 'Pessoa física ou pessoa jurídica' }, // se for associado a radio, permitir vincular uma resposta a uma string
    { id: 1, name: 'Nome ou razão social' },
    { id: 2, name: 'CPF ou CNPJ' },
    { id: 3, name: 'Endereço' },
    { id: 4, name: 'Nome do representante legal' },
    { id: 5, name: 'CPF do representante legal' },
    { id: 'email', name: 'E-mail' },
    { id: 'signerEmail', name: 'E-mail do(a) signatário' },
    { id: 'mobile', name: 'Celular' },
    { id: 'signerMobile', name: 'Celular do(a) signatário' },
    { id: 'stageName', name: 'Nome artístico' },
    { id: 'consentingSignatoryName', name: 'Nome do(a) interveniente/anuente' }
];
const BRAZILIAN_STATES = ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'];
const CLEARANCE_RISK_OPTIONS = [
    {value: 'one', label: 'Remoto', color: 'blue', rgbaColor: 'rgba(0, 0, 255, 0.54)', description: 'aparentemente não há utilização de qualquer direito de terceiro OU o direito está em domínio público'},
    {value: 'two', label: 'Improvável', color: 'green', description: 'há utilização de direito de terceiro, mas o uso poderia ser considerado livre'},
    {value: 'three', label: 'Possível', color: 'yellow', rgbaColor: 'rgba(255, 255, 0, 0.54)', description: 'há utilização de direito de terceiro mas a chance de a parte se sentir lesada parece pequena'},
    {value: 'four', label: 'Provável', color: 'red', description: 'há utilização de direito de terceiro e a chance de a parte se sentir lesada parece grande'},
];
const CLIENT_MODULES = [
    { id: 'accounting', name: 'Financeiro'},
    { id: 'clearance', name: 'Clearance'},
    { id: 'documents', name: 'Documentos'},
    { id: 'lawsuits', name: 'Processos'},
    { id: 'reports', name: 'Relatórios'},
    { id: 'tasks', name: 'Tarefas'},
    { id: 'templates', name: 'Matrizes'},
    { id: 'timesheet', name: 'Timesheet'},
    { id: 'meetings', name: 'Salas'},
];
const CEP_REGEXP = /^[0-9]{2}\.?[0-9]{3}-?[0-9]{3}$/;
const CNPJ_REGEXP = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}$/;
const CPF_REGEXP = /^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-?[0-9]{2}$/;
const currentYear = new Date().getFullYear();
const EMAIL_REGEXP = /^[\w-.]+@([\w-]+\.)+[\w-]+$/;
const ERROR_MESSAGE_CHANGES_UNDONE = 'Ocorreu um erro na comunicação com a base de dados. As alterações foram desfeitas.';
const ERROR_MESSAGE_UNKNOWN = 'Algo deu errado. Tente novamente mais tarde.';
const ERROR_MESSAGE_MANDATORY_FIELD_EMPTY = 'Preencha os campos obrigatórios.';
const FIRM_NAME = 'Drummond, Neumayr, Ragonezi & Falcão Advocacia';
const FIRST_CLIENT_ID = '60d73efec059310794fea79d';
const INPISoftwareStatusOptions = [
    {id: 'aguardandoExameFormal', name: 'Aguardando exame formal'},
    {id: 'emVigor', name: 'Registro do programa de computador em vigor'},
    {id: 'outro', name: 'Outros (especificar nas observações)'}
];
const INPITrademarkStatusOptions = [
    {id: 'aguardandoExameFormal', name: 'Aguardando exame formal'},
    {id: 'aguardandoPrazoOposicao', name: 'Aguardando prazo de apresentação de oposição'},
    {id: 'aguardandoExameMerito', name: 'Aguardando exame de mérito'},
    {id: 'sobrestamento', name: 'Sobrestamento'},
    {id: 'aguardandoPagamentoConcessaoOrdinario', name: 'Aguardando pagamento da concessão (em prazo ordinário)'},
    {id: 'aguardandoPagamentoConcessaoExtraordinario', name: 'Aguardando pagamento da concessão (em prazo extraordinário)'},
    {id: 'indeferido', name: 'Pedido de registro de marca indeferido'},
    {id: 'aguardandoExameRecurso', name: 'Pedido em exame de recurso (Ped.Ex.Rec)'},
    {id: 'emVigor', name: 'Registro de marca em vigor'},
    {id: 'extinto', name: 'Registro de marca extinto'},
    {id: 'definitivamenteArquivado', name: 'Pedido definitivamente arquivado'},
    {id: 'processoNulidade', name: 'Processo de Nulidade'},
    {id: 'outro', name: 'Outros (especificar nas observações)'}
];
const INPITrademarkTypes = [
    {id: 'nominativa', name: 'Nominativa'},
    {id: 'mista', name: 'Mista'},
    {id: 'figurativa', name: 'Figurativa'},
    {id: 'tridimensional', name: 'Tridimensional'}
];
const INPITypes = [
    {id: 'trademark', name: 'Marca'},
    {id: 'software', name: 'Software'}
];
const MEI_REGEXP = /^((\d{2}\.?\d{3}\.?\d{3} )([A-zÀ-ú ]+)|([A-zÀ-ú ]+)( \d{11}))$/;
const MOBILE_REGEXP = /^\(?[0-9]{2}\)?\s?[0-9]{5}-?[0-9]{4}$/;
const monthOptions = [
    {value: 0, label: 'Janeiro'},
    {value: 1, label: 'Fevereiro'},
    {value: 2, label: 'Março'},
    {value: 3, label: 'Abril'},
    {value: 4, label: 'Maio'},
    {value: 5, label: 'Junho'},
    {value: 6, label: 'Julho'},
    {value: 7, label: 'Agosto'},
    {value: 8, label: 'Setembro'},
    {value: 9, label: 'Outubro'},
    {value: 10, label: 'Novembro'},
    {value: 11, label: 'Dezembro'}
];
const QUESTION_TYPES = [
    { id: 'clearance', name: 'Clearance'},
    { id: 'other', name: 'Outros'},
];
const SPECIAL_STRINGS_ALL = '~all;';
const SPECIAL_STRINGS_NONE = ['~none;', '&none'];
const SUCCESS_MESSAGE_SAVED = 'Os dados foram gravados.';

//STYLES
const BIG_BUTTON_XS_SIZE = 80;
const BIG_BUTTON_SM_SIZE = 80;
const BIG_BUTTON_MD_SIZE = 100;
const BIG_BUTTON_BOTTOM_PADDING = 10;
const HEADER_HEIGHT = 50;
const SIDENAV_WIDTH = 75;

export {
    ACTIVE_PROJECT_TYPES,
    allEffectTypes,
    APP_USER_ID,
    ASSIGNABLE_FIELDS,
    BIG_BUTTON_XS_SIZE,
    BIG_BUTTON_SM_SIZE,
    BIG_BUTTON_MD_SIZE,
    BIG_BUTTON_BOTTOM_PADDING,
    BRAZILIAN_STATES,
    CLEARANCE_RISK_OPTIONS,
    CLIENT_MODULES,
    CEP_REGEXP,
    CNPJ_REGEXP,
    CPF_REGEXP,
    currentYear,
    EMAIL_REGEXP,
    ERROR_MESSAGE_CHANGES_UNDONE,
    ERROR_MESSAGE_UNKNOWN,
    ERROR_MESSAGE_MANDATORY_FIELD_EMPTY,
    FIRM_NAME,
    FIRST_CLIENT_ID,
    formEffectTypes,
    HEADER_HEIGHT,
    hiddenEffectTypes, 
    INPISoftwareStatusOptions,
    INPITrademarkStatusOptions,
    INPITrademarkTypes,
    INPITypes,
    MEI_REGEXP,
    MOBILE_REGEXP,
    monthOptions,
    QUESTION_TYPES,
    SIDENAV_WIDTH,
    SPECIAL_STRINGS_ALL,
    SPECIAL_STRINGS_NONE,
    SUCCESS_MESSAGE_SAVED
}