import { useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { Emoji } from 'emoji-picker-react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import ClearIcon from '@mui/icons-material/Clear';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';

import ChatMessage from '../../../classes/ChatMessage';

import ChatTextField from '../../../components/ChatTextField';
import DashboardBox from '../../../components/DashboardBox';
import MultilineText from '../../../components/MultilineText';

import { getUserImageById, getUserNameById, updateArrayItem } from '../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../../../utils/constants';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';

const classes = {
    message: {
        backgroundColor: theme => theme.palette.background.default,
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        marginBottom: '8px',
        padding: '8px',
    }
};

const ChatMessagesItemReactions = ({ reactions }) => {
    useSignals();

    if(reactions){
        let reactionIdToCount = {};
        Object.entries(reactions)
        .sort((a, b) => a[1].reactionAt > b[1].reactionAt ? 1 : a[1].reactionAt < b[1].reactionAt ? -1 : 0)
        .forEach(([_, reaction]) => {
            if(!reactionIdToCount[reaction.unifiedId]) reactionIdToCount[reaction.unifiedId] = 0;
            reactionIdToCount[reaction.unifiedId]++;
        });

        return (
            <Grid container spacing={1} alignItems="center">
                {
                    Object.entries(reactionIdToCount)
                    .map(([reactionId, count]) => (
                        <Grid container spacing={0} alignItems="center">
                            <Grid>
                                <Emoji key={reactionId} size={20} unified={reactionId} />
                            </Grid>
                            <Grid>
                                <Typography variant="body1">{count}</Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            </Grid>
        );
    }
    return null;
}

const ChatAvatar = ({userId, pl, pr}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    return (
        <Box pt={1} pl={pl || 0} pr={pr || 0}>
            <Avatar style={{height: 32, width: 32}} alt={getUserNameById(workspaceUsers.value, userId)} src={getUserImageById(workspaceUsers.value, userId)}>{getUserNameById(workspaceUsers.value, userId).substring(0, 1)}</Avatar>
        </Box>
    );
};

const emojiPickerAnchorEl = signal(null);
const emojiPickerOpen = signal(false);
const selectedMessage = signal(null);
const shouldScrollToBottom = signal(true);
const shouldUpdateRetrievedMessages = signal(true);
const visibleMessages = signal(null);

const LocalEmojiPicker = () => {
    useSignals();
    const { activeUser } = useAppStateCtx();

    useEffect(() => {
        return () => {
            emojiPickerAnchorEl.value = null;
            emojiPickerOpen.value = false;
            selectedMessage.value = null;
            shouldScrollToBottom.value = true;
            shouldUpdateRetrievedMessages.value = true;
            visibleMessages.value = null;
        }
    }, []);

    const saveReaction = async (unifiedId) => {
        handleClose();
        let updatedMessage = {...selectedMessage.value};
        if(!updatedMessage.reactions) updatedMessage.reactions = {};
        if(unifiedId){
            updatedMessage.reactions[activeUser.value.uid] = {
                reactionAt: new Date().toISOString(),
                unifiedId: unifiedId
            }
        } else {
            delete updatedMessage.reactions[activeUser.value.uid];
        }
        updatedMessage = new ChatMessage(updatedMessage);
        shouldScrollToBottom.value = false;
        updateArrayItem(visibleMessages, updatedMessage);
        shouldUpdateRetrievedMessages.value = false;
        const res = await selectedMessage.value.react(unifiedId, activeUser.value.uid);
        if(res.error || !res.result){
            shouldScrollToBottom.value = true;
            shouldUpdateRetrievedMessages.value = true;
            return toast(ERROR_MESSAGE_UNKNOWN, { autoClose: 5000, type: 'error', isLoading: false })
        }
    }

    const handleEmojiClick = (emojiData) => {
        const unifiedId = emojiData.unified;
        saveReaction(unifiedId);
    }

    const handleClose = () => {
        emojiPickerOpen.value = false;
    }

    return (
        <Popover
            open={emojiPickerOpen.value} onClose={handleClose}
            anchorEl={emojiPickerAnchorEl.value}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        backgroundColor: 'transparent'
                    }
                }
            }}
        >
            <Box>
                <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    open
                    reactionsDefaultOpen
                    skinTonesDisabled
                    style={{ transform: 'scale(0.75)' }}
                />
            </Box>
        </Popover>
    );
}

const deletingMessage = signal(false);

const ChatMessagesItem = ({ message, messageIndex }) => {
    useSignals();
    const { activeUser, selectedWorkspace, workspaceUsers } = useAppStateCtx();
    const ref = useRef(null);
    
    useEffect(() => {
        return () => {
            deletingMessage.value = false;
        }
    }, []);

    const handleInsertReacionClick = () => {
        selectedMessage.value = message;
        emojiPickerAnchorEl.value = ref.current;
        emojiPickerOpen.value = true;
    }

    const deleteMessage = async () => {
        deletingMessage.value = true;
        await message.deleteMessage();
        deletingMessage.value = false;
    };

    return (
        <Box>
            <Grid container justifyContent={message.createdBy === activeUser.value.uid ? 'flex-end' : 'flex-start'}>
                <Grid size={{ xs: 11, sm: 10, md: 9 }}>
                    <Box sx={classes.message}>
                        <Box mb={1}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid>
                                    <ChatAvatar userId={message.createdBy} />
                                </Grid>
                                <Grid>
                                    <Typography variant="h6">{getUserNameById(workspaceUsers.value, message.createdBy)}</Typography>
                                </Grid>
                                <Grid size="grow" container justifyContent="flex-end">
                                    <Grid>
                                        <Typography variant="body2" style={{fontSize: 10}}>{moment(message.createdAt).format('L LT')}</Typography>
                                    </Grid>
                                </Grid>
                                {
                                    (message.uid && (['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) || (message.createdBy === activeUser.value.uid && messageIndex === visibleMessages.value.length - 1))) &&
                                    <Grid>
                                        <IconButton disabled={deletingMessage.value} size="small" onClick={deleteMessage}><ClearIcon /></IconButton>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Box mb={1}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid>
                                    <Typography variant="body1"><MultilineText text={message.message} /></Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={1} alignItems="center">
                            <Grid>
                                <IconButton ref={ref} size="small" onClick={handleInsertReacionClick}>
                                    <InsertEmoticonIcon />
                                </IconButton>
                            </Grid>
                            <Grid>
                                <ChatMessagesItemReactions reactions={message.reactions} />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

function Chat({ height }){
    useSignals();
    const { workspaceChatMessages, workspaceUsers } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();

    useEffect(() => {
        setFirestoreListener('workspaceChatMessages');
    }, []);

    const scrollToBottom = () => {
        const chatBox = document.getElementById('chat-box');
        if(chatBox) chatBox.scrollTop = chatBox.scrollHeight;
    }

    useEffect(() => {
        if(workspaceChatMessages.value && shouldUpdateRetrievedMessages.value){
            visibleMessages.value = workspaceChatMessages.value;
        }
        shouldUpdateRetrievedMessages.value = true;
    }, [workspaceChatMessages.value]);

    useEffect(() => {
        if(visibleMessages.value && visibleMessages.value.length !== 0 && shouldScrollToBottom.value){
            scrollToBottom();
        }
        shouldScrollToBottom.value = true;
    }, [visibleMessages.value]); 

    return (
        <Box>
            <DashboardBox p="0" id="chat-box" style={{height: height || 200}}>
                {
                    !workspaceUsers.value
                    ? <Skeleton variant="rectangular" animation="wave" height="100%" />
                    :
                    <Box p={1}>
                        {
                            visibleMessages.value
                            ?.map((message, messageIndex) => (
                                <ChatMessagesItem key={message.uid} message={message} messageIndex={messageIndex} />
                            ))
                        }
                    </Box>
                }
            </DashboardBox>
            <ChatTextField />
            <LocalEmojiPicker />
        </Box>
    );
}

export default Chat;