import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import AlertDialog from '../AlertDialog';
import Input from '../Input';
import Select from '../Select';
import Window from '../Window';
import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI, useAppCtxLoading } from '../../context/SystemContext';
import callFunction from '../../firebase/functions/callFunction';
import { validateEmail } from '../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const ChangeSignerWindow = ({open, setOpen, documentVersion, selectedDocumentRef, selectedSigner}) => {
    useSignals();
    const {} = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const defaultSigner = {
        id: '',
        name: '',
        email: '',
        mobile: '',
        requireEmailToken: true,
        type: 'party',
        locked: false
    };
    const [signer, set_signer] = useState(defaultSigner);
    const [changeSignerConfirmDialogOpen, setChangeSignerConfirmDialogOpen] = useState(false);

    useEffect(() => {
        if(open){
            let currentSigner = {...defaultSigner};
            if(selectedSigner){
                currentSigner = {
                    ...currentSigner,
                    
                    ...selectedSigner
                };
            }
            set_signer(currentSigner);
        }
    }, [open]);

    const handleSaveButtonClick = () => {
        setChangeSignerConfirmDialogOpen(true);
    };
    
    const handleSubmit = async () => {
        setChangeSignerConfirmDialogOpen(false);
        setLoading(true); 
        const toastId = toast.loading('Salvando...');
        const res = await callFunction('updateSigner', {
            documentId: selectedDocumentRef.current.uid,
            documentVersionId: documentVersion.uid,
            eSignaturePlatform: documentVersion.eSignature.platform,
            signer,
            signerId: selectedSigner.uid,
        })
        setLoading(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        setOpen(false);
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        
    };

    const handleWindowClose = () => {
        setOpen(false);
    };
    
    return (
        <>
            <Window open={open} onClose={handleWindowClose}
                submitButtonDisabled={loading || !signer.name || !validateEmail(signer.email.trim())}
                submitButtonStartIcon={loading ? <CircularProgress size={20} /> : <CheckIcon />}
                handleSubmitButtonClick={handleSaveButtonClick}
                closeButtonText="Cancelar"
            >
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item>
                            <Typography variant="h2">Alterar signatário(a)</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1">Você deseja alterar o nome ou o e-mail do(a) signatário(a)? Não é possível reposicionar as assinaturas.</Typography>
                </Box>
                <Box mb={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={12} container>
                            <Grid item xs={6} sm={5} md={3}>
                                <Select
                                    disabled={signer.type === 'contratante' || loading}
                                    label="Assinar como"
                                    options={[
                                        {value: 'party', label: 'Parte'},
                                        {value: 'witness', label: 'Testemunha'},
                                        ...(signer.type === 'contratante' ? [{value: 'contratante', label: 'Parte (padrão)'}] : [])
                                    ]}
                                    value={signer.type} onChange={(e) => set_signer({...signer, type: e.target.value})}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                fullWidth
                                disabled={loading}
                                label="Nome do(a) signatário(a)"
                                value={signer.name} onChange={(e) => set_signer({...signer, name: e.target.value})}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Input
                                fullWidth
                                disabled={loading}
                                label="E-mail"
                                value={signer.email} onChange={(e) => set_signer({...signer, email: e.target.value})}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Window>
            <AlertDialog
                open={changeSignerConfirmDialogOpen} onClose={() => setChangeSignerConfirmDialogOpen(false)}
                text={`Tem certeza? Essa ação alterará os dados de ${selectedSigner ? `${selectedSigner.name} (${selectedSigner.email})` : ''}.`}
                okButtonOnClick={handleSubmit}
            />
        </>
    );
};

export default ChangeSignerWindow;