import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Switch from './Switch';
import { useAppStateCtx } from '../context/AppState';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';

const ChangeUserBooleanField = ({userState, setUserState, fieldKey}) => {
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const [fieldValue, setFieldValue] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(userState){
            setFieldValue(fields[fieldKey].setOppositeValueOnMount ? !userState[fieldKey] : userState[fieldKey]);
        }
    }, [userState]);

    const handleChange = async (newValue) => {
        setSaving(true);
        setFieldValue(newValue);
        const updates = {
            ...userState,
            [fieldKey]: fields[fieldKey].setOppositeValueOnMount ? !newValue : newValue
        };
        if(fields[fieldKey].shouldAlsoChange){
            if(fields[fieldKey].shouldAlsoChange.changeIf === undefined || newValue === fields[fieldKey].shouldAlsoChange.changeIf){
                updates[fields[fieldKey].shouldAlsoChange.fieldKey] = fields[fieldKey].shouldAlsoChange.fieldValue;
            }
        }
        setUserState(updates);

        const toastId = toast.loading('Salvando as alterações...');

        const res = await activeUser.value.updateWorkspaceEmailSettings({
            emailSettings: updates,
            workspaceId: selectedWorkspace.value.uid
        })
        setSaving(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };
    
    const defaultIconColor = 'action';
    const fields = {
        documentDeliveredByOperatorDisabled: {
            setOppositeValueOnMount: true,
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails com documentos enviados',
            shouldAlsoChange: {
                changeIf: false,
                fieldKey: 'documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser',
                fieldValue: false
            }
        },
        documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser: {
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails só se eu tiver solicitado o documento',
            showIf: {
                fieldKey: 'documentDeliveredByOperatorDisabled',
                fieldValue: false
            }
        },
        documentSignedByThisUserEnabled: {
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails com comprovante de assinatura de documentos'
        },
        documentFullySignedDisabled: {
            setOppositeValueOnMount: true,
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails avisando que todas as assinaturas de um documento foram concluídas'
        },
        clearanceQuestionOrNoteDisabled: {
            setOppositeValueOnMount: true,
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails com consultas e notificações de clearance',
            shouldAlsoChange: {
                changeIf: false,
                fieldKey: 'clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser',
                fieldValue: false
            }
        },
        clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser: {
            icon: <NotificationsIcon color={defaultIconColor} />,
            label: 'Receber e-mails só se eu tiver apresentado a consulta ou respondido a anotação de clearance',
            showIf: {
                fieldKey: 'clearanceQuestionOrNoteDisabled',
                fieldValue: false
            }
        }
    };

    if(userState && (!fields[fieldKey].showIf || userState[fields[fieldKey].showIf.fieldKey] === fields[fieldKey].showIf.fieldValue)){
        return (
            <Box>
                <Box py={0.5}>
                    <Grid
                        container spacing={1} alignItems="center"
                        sx={{
                            cursor: 'pointer',
                            transition: 'background-color .4s',
                            borderRadius: theme => theme.spacing(1),
                            '&:hover': {
                                backgroundColor: theme => theme.palette.action.hover
                            }
                        }}
                        onClick={() => handleChange(!fieldValue)}
                    >
                        <Grid item>
                            {
                                saving
                                ? <CircularProgress size={20} />
                                : fields[fieldKey].icon
                            }
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body1" color="textPrimary">{fields[fieldKey].label}</Typography>
                        </Grid>
                        <Grid item>
                            <Switch checked={fieldValue} />
                        </Grid>
                    </Grid>
                </Box>
                <Divider variant="middle" />
            </Box>
        );
    }
    return null;
}

export default ChangeUserBooleanField;