import { useSignals } from '@preact/signals-react/runtime';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useAppStateCtx } from '../context/AppState';

const PendingDocumentLink = ({ loading, selectedDocument }) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    let documentName = selectedDocument.name;
    if(!loading && selectedDocument.lastVersion?.uid){
        if(selectedDocument.lastVersion.name) documentName += ` | ${selectedDocument.lastVersion.name}`;
        return <Link onClick={() => window.open(`/${selectedWorkspace.value.shortName}/juridico/documento/${selectedDocument.uid}?v=${selectedDocument.lastVersion.uid}`, '_blank')} component="p" variant="body1" underline="hover" style={{cursor: 'pointer'}} noWrap>{documentName}</Link>;
    }
    return (
        <Typography variant="body1" noWrap>{documentName}</Typography>
    );
};

export default PendingDocumentLink;