import * as React from 'react';
import PropTypes from 'prop-types';
import { VariableSizeList } from 'react-window';

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const LISTBOX_PADDING = 8; // px

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    const { key, ...optionProps } = dataSet[0];

    if(dataSet[3]){
        return (
            <li {...props} key={key} {...optionProps} style={inlineStyle}>
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={dataSet[2].selected}
                />
                <Typography variant="body1" noWrap>
                    {dataSet[1]}
                </Typography>
          </li>
        );
    }
    return (
        <Typography key={key} component="li" {...optionProps} noWrap style={inlineStyle}>
            {`#${dataSet[2].index + 1} - ${dataSet[1]}`}
        </Typography>
    )
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
          return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                  itemData={itemData}
                  height={getHeight() + 2 * LISTBOX_PADDING}
                  width="100%"
                  ref={gridRef}
                  outerElementType={OuterElementType}
                  innerElementType="ul"
                  itemSize={(index) => getChildSize(itemData[index])}
                  overscanCount={5}
                  itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListboxComponent.propTypes = {
    children: PropTypes.node,
};

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

const VirtualizedAutocomplete = ({ getOptionLabel, groupBy, label, multiple, onChange, onFocus, options, renderGroup, sx, value, variant }) => {
    return (
        <Autocomplete
            disableListWrap
            multiple={multiple}
            onChange={onChange}
            options={options}
            getOptionLabel={getOptionLabel}
            groupBy={groupBy}
            renderInput={(params) => <TextField {...params} label={label} variant={variant || 'outlined'} onFocus={onFocus} />}
            renderOption={
                (props, option, state) => {
                    let optionLabel = option;
                    if(getOptionLabel) optionLabel = getOptionLabel(option);
                    return [props, optionLabel, state, multiple];
                }
            }
            renderGroup={renderGroup}
            slots={{
                popper: StyledPopper,
                listbox: ListboxComponent,
            }}
            sx={sx}
            value={value}
        />
    )
}

export default VirtualizedAutocomplete;