import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearanceTimeCodeScreenshot from '../ClearanceTimeCodeScreenshot/ClearanceTimeCodeScreenshot';
import ClearanceWindowRiskFields from './ClearanceWindowRiskFields';
import LoadingButton from '../LoadingButton';

import ClearanceNote from '../../classes/ClearanceNote';
import ClearanceNoteComment from '../../classes/ClearanceNoteComment';
import { useAppStateCtx } from '../../context/AppState';
import { sortClearanceReportNotes } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const SaveButton = ({
    clearanceItemState,
    clearanceWindowOpen,
    disabled,
    handleClose,
    selectedClearanceReportNote,
    selectedProject,
    selectedProjectClearanceTemplate,
    selectedReport
}) => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setSaving(false);
    }, [clearanceWindowOpen.value]);

    const handleSubmit = async () => {
        setSaving(true);
        let rightTypeDescription = '', useTypeDescription = '', riskLevelDescription = '';
        if(selectedProjectClearanceTemplate){
            if(clearanceItemState.rightType){
                const reportItemRightType = selectedProjectClearanceTemplate.rightTypes[clearanceItemState.rightType];
                rightTypeDescription = reportItemRightType.name;
                const riskLevels = reportItemRightType.riskLevels;
                for(const riskLevelKey in riskLevels){
                    const riskLevel = riskLevels[riskLevelKey];
                    const reportItemUseType = riskLevel[clearanceItemState.useType];
                    if(reportItemUseType){
                        useTypeDescription = reportItemUseType.name;
                        break;
                    }
                }
                const reportItemRiskLevel = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === clearanceItemState.riskLevel);
                riskLevelDescription = reportItemRiskLevel.label;

            }
        }

        let newComment;
        if(riskLevelDescription){
            newComment = new ClearanceNoteComment({
                createdBy: activeUser.value.uid,
                createdByName: activeUser.value.name,
                comment: clearanceItemState.legalRecommendation || `Risco: ${riskLevelDescription}`,
                reportId: selectedReport.uid,
                riskLevel: clearanceItemState.riskLevel,
                riskLevelDescription,
            });
            newComment = {...newComment};
        }

        let scriptScene = null;
        if(selectedReport.type === 'script') scriptScene = clearanceItemState.scriptScene;

        if(selectedClearanceReportNote.value){

            const res = await selectedClearanceReportNote.value.update({
                newComment,
                updates: {
                    cutTimeCode: clearanceItemState.cutTimeCode,
                    description: clearanceItemState.description,
                    legalRecommendation: clearanceItemState.legalRecommendation,
                    licensed: clearanceItemState.licensed,
                    licenseLink: clearanceItemState.licenseLink,
                    requiresAdditionalInformation: clearanceItemState.requiresAdditionalInformation,
                    rightType: clearanceItemState.rightType,
                    rightTypeDescription,
                    riskDescription: clearanceItemState.riskDescription,
                    riskLevel: clearanceItemState.riskLevel,
                    riskLevelDescription,
                    scriptScene,
                    useType: clearanceItemState.useType,
                    useTypeDescription
                },
            });
            if(res.error){
                setSaving(false);
                return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }
            
        } else {

            const newClearanceNote = new ClearanceNote({
                createdBy: activeUser.value.uid,
                cutTimeCode: clearanceItemState.cutTimeCode,
                description: clearanceItemState.description,
                legalRecommendation: clearanceItemState.legalRecommendation,
                licensed: clearanceItemState.licensed,
                licenseLink: clearanceItemState.licenseLink,
                originalReportId: selectedReport.uid,
                projectId: selectedProject.uid,
                reportId: selectedReport.uid,
                requiresAdditionalInformation: clearanceItemState.requiresAdditionalInformation,
                rightType: clearanceItemState.rightType,
                rightTypeDescription,
                riskDescription: clearanceItemState.riskDescription,
                riskLevel: clearanceItemState.riskLevel,
                riskLevelDescription,
                scriptScene,
                useType: clearanceItemState.useType,
                useTypeDescription,
                workspaceId: selectedWorkspace.value.uid
            });
            const res = await newClearanceNote.createClearanceNote(newComment);
            if(res.error){
                setSaving(false);
                return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
            }
        }

        // orderClearanceReport(nextClearanceReportItems);

        handleClose();

        toast(SUCCESS_MESSAGE_SAVED, { autoClose: 3000, type: 'success' });
    };

    return (
        <LoadingButton
            color="primary"
            disabled={disabled || saving}
            loading={saving}
            onClick={handleSubmit}
            variant="contained"
        >Salvar</LoadingButton>
    )
}

const ClearanceWindow = ({
    open, activeUserIsOperator, clearanceItems, setClearanceItems, projectClearanceSettings, handleDeleteReportItemPress,
    selectedProject, selectedProjectClearanceTemplate, selectedClearanceReportNote, selectedReport
}) => {
    useSignals();
    const defaultClearanceItem = {
        description: '',
        rightType: null,
        useType: '',
        riskLevel: '',
        riskDescription: '',
        licensed: false,
        licenseLink: '',
        additionalInformation: '',
        scriptScene: 1,
        cutTimeCode: '',
        legalRecommendation: '',
        requiresAdditionalInformation: false
    };
    const { selectedWorkspace } = useAppStateCtx();
    const [clearanceItemState, setClearanceItemState] = useState(defaultClearanceItem);

    useEffect(() => {
        if(open.value){
            clearForm();
            if(selectedReport){
                if(selectedClearanceReportNote.value){
                    setClearanceItemState({
                        ...defaultClearanceItem, ...selectedClearanceReportNote.value,
                        scriptScene: selectedClearanceReportNote.value?.scriptScene,
                        cutTimeCode: selectedClearanceReportNote.value?.cutTimeCode,
                        rightType: selectedClearanceReportNote.value?.rightType || null,
                        useType: selectedClearanceReportNote.value?.useType || '',
                        riskLevel: selectedClearanceReportNote.value?.riskLevel || '',
                        riskDescription: selectedClearanceReportNote.value?.riskDescription || '',
                        screenShot: selectedClearanceReportNote.value?.screenShotFileUrl || '',
                        screenShotWithMarkersFileUrl: selectedClearanceReportNote.value?.screenShotWithMarkersFileUrl || '',
                        screenShotMarkers: selectedClearanceReportNote.value?.screenShotMarkers || null,
                        licensed: !!selectedClearanceReportNote.value.licensed,
                        licenseLink: selectedClearanceReportNote.value.licenseLink || '',
                        legalRecommendation: selectedClearanceReportNote.value.legalRecommendation || '',
                        requiresAdditionalInformation: !!selectedClearanceReportNote.value.requiresAdditionalInformation,
                    });
                } else {
                    clearForm();
                    if(clearanceItems && clearanceItems.length !== 0){
                        const sortedClearanceReportNotes = clearanceItems.sort(sortClearanceReportNotes(selectedReport));
                        const lastReportItem = sortedClearanceReportNotes[sortedClearanceReportNotes.length - 1];
                        if(selectedReport.type === 'script'){
                            if(lastReportItem.scriptScene) setClearanceItemState(prevState => ({...prevState, scriptScene: lastReportItem.scriptScene}));
                        } else {
                            if(lastReportItem.cutTimeCode) setClearanceItemState(prevState => ({...prevState, cutTimeCode: lastReportItem.cutTimeCode}));
                        }
                    }
                }
            }
        }
    }, [open.value, selectedClearanceReportNote.value, selectedReport]);

    const handleClose = () => {
        open.value = false;
    };

    const handleFieldChange = (fieldId, newValue) => {
        setClearanceItemState(prevState => ({...prevState, [fieldId]: newValue}));
    };

    const clearForm = () => {
        setClearanceItemState(defaultClearanceItem);
    };

    return (
        <Dialog
            open={open.value}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{selectedClearanceReportNote.value ? clearanceItemState.description : 'Cadastrar direito'}</DialogTitle>
            <DialogContent>
                
                <IconButton
                    style={{position: 'absolute', top: 2, right: 2}}
                    onClick={handleClose}
                    size="large"><CloseIcon /></IconButton>

                {
                    (selectedClearanceReportNote.value && selectedClearanceReportNote.value.originalReportId !== selectedReport?.uid) &&
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid>
                                <Typography variant="body1">Esta anotação é uma cópia de lançamento em relatório anterior.</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                }

                <ClearanceTimeCodeScreenshot 
                    allowAnnotations
                    selectedClearanceReportNote={selectedClearanceReportNote}
                    selectedReport={selectedReport}
                    setClearanceItems={setClearanceItems}
                    windowOpen={open}
                />

                <Box mb={2}>
                    {
                        clearanceItemState &&
                        <ClearanceWindowRiskFields
                            open={open}
                            activeUserIsOperator={activeUserIsOperator}
                            projectClearanceSettings={projectClearanceSettings}
                            selectedProject={selectedProject}
                            selectedProjectClearanceTemplate={selectedProjectClearanceTemplate}
                            selectedReport={selectedReport}
                            clearanceItem={clearanceItemState}
                            handleFieldChange={handleFieldChange}
                        />
                    }
                </Box>

            </DialogContent>
            {
                activeUserIsOperator &&
                <DialogActions>
                    {
                        (selectedClearanceReportNote.value && ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)) &&
                        <Grid>
                            <Button startIcon={<DeleteIcon />} onClick={handleDeleteReportItemPress}>Excluir</Button>
                        </Grid>
                    }
                    <SaveButton
                        clearanceItemState={clearanceItemState}
                        clearanceWindowOpen={open}
                        disabled={!selectedReport}
                        handleClose={handleClose}
                        selectedClearanceReportNote={selectedClearanceReportNote}
                        selectedProject={selectedProject}
                        selectedProjectClearanceTemplate={selectedProjectClearanceTemplate}
                        selectedReport={selectedReport}
                    />
                </DialogActions>
            }
        </Dialog>
    );
};

export default ClearanceWindow;