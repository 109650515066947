import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/config';

const useGetProjectDocumentsSettings = (shouldSetListener, projectId) => {
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && projectId){
            setHookState(prevState => ({...prevState, loading: true}));
            const unsubscribe = onSnapshot(doc(db, `projects/${projectId}/_more/documents`),
                (snapshot) => {
                    const data = snapshot.data();
                    setHookState({
                        loading: false,
                        data
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        } else {
            setHookState(defaultHookState);
        }
    }, [shouldSetListener, projectId]);

    return hookState;
};

export default useGetProjectDocumentsSettings;