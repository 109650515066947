import styled from '@mui/material/styles/styled';
import { unstable_styleFunctionSx } from '@mui/system';

import { escapeRegex } from '../utils/common';

const classes = {
    link: {
        color: (theme: {[key: string]: any}) => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const ASpan = styled('a')(unstable_styleFunctionSx);
const Span = styled('span')(unstable_styleFunctionSx);

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const mentionRegex = /@\[(.+?)\]\((.+?)\)/; // Captures [name] and (id)

const MultilineText = ({text, breaker}: { text: string; breaker?: string }) => {
    if(text){
        const parts = text.split(new RegExp(`(${breaker ? escapeRegex(breaker) : '\n'}|@\\[.+?\\]\\(.+?\\)|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})`, 'g'));
        return (
            <>
                {
                    parts.map((part, index) => {
                        if((breaker && part === breaker) || part === '\n'){
                            return (<br key={index} />);
                        } else if (mentionRegex.test(part)){
                            const mention = mentionRegex.exec(part);
                            if(mention){
                                return (
                                    <Span key={index} style={{ backgroundColor: 'lightgrey', padding: '0 2px', borderRadius: '2px', fontStyle: 'italic', whiteSpace: 'nowrap' }}>
                                        @{mention[1]}
                                    </Span>
                                )
                            }
                        } else if(emailRegex.test(part)){
                            return (
                                <ASpan key={index} sx={classes.link} href={`mailto:${part}`}>
                                    {part}
                                </ASpan>
                            )
                        } else {
                            return (
                                <span key={index}>
                                    {part}
                                </span>
                            )
                        }
                    })
                }
            </>
        );
    }
    return null;
};

export default MultilineText;