import { collection, doc, DocumentData, DocumentReference, runTransaction } from 'firebase/firestore';
import moment from 'moment';

import TimesheetRecord from './TimesheetRecord';

import { db } from '../firebase/config';

export default class Task {
    completed = false;
    completedAt = '';
    createdAt = '';
    createdBy = '';
    deadline = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    description = '';
    estimatedMinutesRequiredToComplete = 0;
    hidden = false;
    operatorId = '';
    orderNumber = 0;
    priority = 0;
    projectId = '';
    subject = '';
    taskUrl = '';
    timesheetId = '';
    timesheetMinutes = 0;
    viewed = false;
    viewedAt = '';
    uid = '';
    workspaceId = '';

    constructor({
        completed,
        completedAt,
        createdAt,
        createdBy,
        deadline,
        deleted,
        deletedAt,
        deletedBy,
        description,
        estimatedMinutesRequiredToComplete,
        hidden,
        operatorId,
        orderNumber,
        priority,
        projectId,
        subject,
        taskUrl,
        timesheetId,
        timesheetMinutes,
        viewed,
        viewedAt,
        uid,
        workspaceId
    }: Task){
        this.completed = completed || false;
        this.completedAt = completedAt || '';
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.deadline = deadline || '';
        this.deleted = deleted || false;
        this.deletedAt = deletedAt || '';
        this.deletedBy = deletedBy || '';
        this.description = description || '';
        this.estimatedMinutesRequiredToComplete = estimatedMinutesRequiredToComplete || 0;
        this.hidden = hidden || false;
        this.operatorId = operatorId || '';
        this.orderNumber = orderNumber || 0;
        this.priority = priority || 0;
        this.projectId = projectId || '';
        this.subject = subject || '';
        this.taskUrl = taskUrl || '';
        this.timesheetId = timesheetId || '';
        this.timesheetMinutes = timesheetMinutes || 0;
        this.viewed = viewed || false;
        this.viewedAt = viewedAt || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async createTask(taskRef: DocumentReference<DocumentData, DocumentData>){
        let result = null, error = null;
        
        const workspaceRealTimeTasksRef = doc(db, `workspaces/${this.workspaceId}/real_time/tasks`);

        const {uid: _, ...taskWithoutUid} = this;
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(taskRef, {...taskWithoutUid});
                transaction.update(workspaceRealTimeTasksRef, {
                    updatedAt: this.createdAt
                });
            });

            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async completeTask({ activeUserId, minutes, subject }: {
        activeUserId: string;
        minutes: number;
        subject: string;
    }){
        let result = null, error = null;
        
        const taskRef = doc(db, `tasks/${this.uid}`);
        const workspaceRealTimeTasksRef = doc(db, `workspaces/${this.workspaceId}/real_time/tasks`);
        
        try {
            await runTransaction(db, async (transaction) => {
                let timesheetId = '', timesheetMinutes = 0;
                if(minutes){
                    const timesheetRef = doc(collection(db, `timesheet_records`));
                    timesheetId = timesheetRef.id;
                    timesheetMinutes = minutes;
                    const newTimesheetRecordWithUid = new TimesheetRecord({
                        createdAt: new Date().toISOString(),
                        createdBy: activeUserId,
                        description: subject,
                        minutes,
                        projectId: this.projectId,
                        startAt: moment().subtract(minutes, 'minutes').toISOString(),
                        taskId: this.uid,
                        workspaceId: this.workspaceId,
                    });
                    const {uid: _, ...newTimesheetRecord} = newTimesheetRecordWithUid;
                    transaction.set(timesheetRef, newTimesheetRecord);
                }
                transaction.update(taskRef, { completed: true, completedAt: new Date().toISOString(), subject, timesheetId, timesheetMinutes });
                transaction.update(workspaceRealTimeTasksRef, {
                    updatedAt: new Date().toISOString()
                })
            });

            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async deleteTask(activeUserId: string){
        let result = null, error = null;
        
        try {
            await this.update({ updates: { deleted: true, deletedAt: new Date().toISOString(), deletedBy: activeUserId } });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;
        
        const taskRef = doc(db, `tasks/${this.uid}`);
        const workspaceRealTimeTasksRef = doc(db, `workspaces/${this.workspaceId}/real_time/tasks`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(taskRef, updates);
                transaction.update(workspaceRealTimeTasksRef, {
                    updatedAt: new Date().toISOString()
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}