import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Client from '../../../classes/Client';
import Project from '../../../classes/Project';
import { ERROR_MESSAGE_UNKNOWN } from '../../../utils/constants';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { useAppCtxAPI } from '../../../context/SystemContext';
import GroupsWindow from '../../../components/GroupsWindow';

const FolderUserCtxAPI = createContext();
const FolderUserCtxCompanyFolders = createContext();
const FolderUserCtxFolderUsers = createContext();
const FolderUserCtxLoadFolder = createContext();
const FolderUserCtxProjects = createContext();
const FolderUserCtxSidenavMobile = createContext();
const FolderUserCtxUseAddDocument = createContext();
const FolderUserCtxUsers = createContext();

const defaultState = {
    clientProjects: [],
    docNotifications: [],
    folderSettings: {},
    folderUsers: [],
    projects: [],
    selectedFolderCustomDocCols: [],
    sidenavMobileOpen: false,
    useAddDocument: false,
    users: [],
    usersUpdatedAt: null
};

const reducer = (state, action) => {
    const { payload, type } = action;

    switch (type) {
        case 'ADD COMPANY FOLDER':
            return { ...state, clientProjects: [...state.clientProjects, payload] };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SET STATE + UPDATED AT':
            return { ...state, [payload.key]: payload.value, [payload.updatedAt]: new Date() };
        case 'UPDATE STATE':
            return { ...state, ...payload };
        default: return state;
    }
    
};

const FolderUserProvider = ({children}) => {
    useSignals();
    const { activeUser, loadingApp, loadingAppText, pageTitle, selectedFolder, selectedProjectClient, selectedWorkspace, workspaceClients, workspaceProjects } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { resetUser } = useAppCtxAPI();
    const location = useLocation();

    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const addCompanyFolder = (payload) => {
            dispatch({ type: 'ADD COMPANY FOLDER', payload });
        };

        const setCompanyFolders = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'clientProjects', value: newValue } });
        };
        
        const setProjects = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'projects', value: newValue } });
        };

        const setSidenavMobileOpen = (newValue) => {
            dispatch({ type: 'SET STATE', payload: { key: 'sidenavMobileOpen', value: newValue } });
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        return {
            dispatch,
            addCompanyFolder,
            setCompanyFolders,
            setProjects,
            setSidenavMobileOpen,
            setState
        };
    }, []);

    useEffect(() => {
        if(activeUser.value && selectedWorkspace.value && selectedFolder.value){
            setTimeout(() => {
                loadingApp.value = false;
                loadingAppText.value = '';
            }, 2000);
        }
    }, [activeUser.value, selectedWorkspace.value, selectedFolder.value]);

    const loadFolder = useCallback(async ({ activeUser, clientOrProjectId, selectedWorkspaceId, type }, callback) => {
        resetUser();
        if(activeUser){
            const res = await activeUser.getSelectedClientOrProject(activeUser, selectedWorkspaceId, clientOrProjectId, type);
            if(!res.error || res.result){
                const clientOrProject = res.result.project || res.result.client;
                clientOrProject.type = clientOrProject.clientId ? 'projects' : 'clients';
                const currentClientOrProject = new Project({...clientOrProject, uid: clientOrProjectId});
                selectedFolder.value = currentClientOrProject;
                setFirestoreListener('workspaceDocumentsTemplates');
                setFirestoreListener('projectGroupSettings', clientOrProjectId);
                setFirestoreListener('projectClearanceSettings');
                setFirestoreListener('folderESignatureSettings', `${clientOrProject.type}/${currentClientOrProject.uid}`);
                setFirestoreListener('activeUserESignatureSettings');
                const currentProjectClient = res.result.projectClient ? new Client({ ...res.result.projectClient, uid: currentClientOrProject.clientId }) : null
                selectedProjectClient.value = currentProjectClient;
                workspaceClients.value = {
                    [currentClientOrProject.clientId]: currentProjectClient
                };
                const projects = {
                    [clientOrProjectId]: currentClientOrProject
                }
                workspaceProjects.value = projects;
    
                const stateUpdates = {
                    users: res.result.users,
                    usersUpdatedAt: new Date()
                };
                stateUpdates.useAddDocument = !!clientOrProject.folderUsersCanAddDocuments;
                if(res.result.clientProjects) stateUpdates.clientProjects = res.result.clientProjects;
                dispatch({ type: 'UPDATE STATE', payload: stateUpdates });
    
                if(callback) callback();
                return;
            }
        }
        
        toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });

    }, [activeUser.value]);

    const getPageTitle = (pathname) => {

        const availablePages = [
            { test: 'pastas\/\\w+?\/avisos', getTitle: () => 'Avisos' },
            { test: 'pastas\/\\w+?\/documentos\/?$', getTitle: () => 'Documentos' },
            { test: 'pastas\/\\w+?\/formularios\/?$', getTitle: () => 'Formulários' },
            { test: 'pastas\/\\w+?\/formularios\/salvos', getTitle: () => 'Rascunhos de formulários' },
            { test: 'pastas\/\\w+?\/fichas', getTitle: () => 'Fichas cadastrais' },
            { test: 'pastas\/\\w+?\/revisao', getTitle: () => 'Enviar documento para revisão' },
            { test: 'pastas\/\\w+?\/assinar', getTitle: () => 'Assinatura eletrônica' },
            { test: 'pastas\/\\w+?\/documentos\/alertas', getTitle: () => 'Alertas de documentos' },
            { test: 'pastas\/\\w+?\/clearance\/?$', getTitle: () => 'Clearance - Orientações gerais' },
            { test: 'pastas\/\\w+?\/clearance\/consultas', getTitle: () => 'Consultas de Clearance' },
            { test: 'pastas\/\\w+?\/clearance\/relatorios', getTitle: () => 'Relatórios de Clearance' },
            { test: 'pastas\/\\w+?\/documentos\/lixeira', getTitle: () => 'Documentos excluídos' },
        ];

        const path = pathname.replace(/^\/\w+?\//, '');
        let title;
        const foundPage = availablePages.find(option => {
            const titleTest = new RegExp(option.test);
            return titleTest.test(path);
        });
        if(foundPage) title = foundPage.getTitle();
        return title || '';
    }
    
    useEffect(() => {
        const pathname = location.pathname;
        pageTitle.value = getPageTitle(pathname);
    }, [location]);
    
    return (
        <FolderUserCtxAPI.Provider value={api}>
        <FolderUserCtxProjects.Provider value={state.projects}>
        <FolderUserCtxUsers.Provider value={state.users}>
        <FolderUserCtxSidenavMobile.Provider value={state.sidenavMobileOpen}>
        <FolderUserCtxUseAddDocument.Provider value={state.useAddDocument}>
        <FolderUserCtxCompanyFolders.Provider value={state.clientProjects}>
        <FolderUserCtxFolderUsers.Provider value={state.folderUsers}>
        <FolderUserCtxLoadFolder.Provider value={loadFolder}>

            {children}

            <GroupsWindow activeUserIsOperator={false} />

        </FolderUserCtxLoadFolder.Provider>
        </FolderUserCtxFolderUsers.Provider>
        </FolderUserCtxCompanyFolders.Provider>
        </FolderUserCtxUseAddDocument.Provider>
        </FolderUserCtxSidenavMobile.Provider>
        </FolderUserCtxUsers.Provider>
        </FolderUserCtxProjects.Provider>
        </FolderUserCtxAPI.Provider>
    );
};

const useFolderUserCtxAPI = () => useContext(FolderUserCtxAPI);
const useFolderUserCtxCompanyFolders = () => useContext(FolderUserCtxCompanyFolders);
const useFolderUserCtxFolderUsers = () => useContext(FolderUserCtxFolderUsers);
const useFolderUserCtxLoadFolder = () => useContext(FolderUserCtxLoadFolder);
const useFolderUserCtxProjects = () => useContext(FolderUserCtxProjects);
const useFolderUserCtxSidenavMobile = () => useContext(FolderUserCtxSidenavMobile);
const useFolderUserCtxUseAddDocument = () => useContext(FolderUserCtxUseAddDocument);
const useFolderUserCtxUsers = () => useContext(FolderUserCtxUsers);

export {
    FolderUserProvider,
    useFolderUserCtxAPI,
    useFolderUserCtxCompanyFolders,
    useFolderUserCtxFolderUsers,
    useFolderUserCtxLoadFolder,
    useFolderUserCtxProjects,
    useFolderUserCtxSidenavMobile,
    useFolderUserCtxUseAddDocument,
    useFolderUserCtxUsers
};