import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ClearIcon from '@mui/icons-material/Clear';
import FlagIcon from '@mui/icons-material/Flag';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SettingsIcon from '@mui/icons-material/Settings';
import TitleIcon from '@mui/icons-material/Title';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AlertDialog from '../../../../components/AlertDialog';
import FormDialog from '../../../../components/FormDialogUncontrolled';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useAppCtxAPI } from '../../../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxActionMenu } from '../../../../context/DocumentsContext';
import { useOperatorCtxProjects } from '../../context/OperatorContext';
import { flagDocument } from '../../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../utils/constants';

const setAwaitingOperatorReviewDialogOpen = signal(false);

const SetAwaitingOperatorReviewDialog = ({ closeMenu, selectedDocument }) => {

    const handleClose = () => {
        setAwaitingOperatorReviewDialogOpen.value = false;
    }

    const handleConfirm = async () => {
        handleClose();
        closeMenu();
        const toastId = toast.loading(`O documento ${selectedDocument.name} será disponibilizado na seção "aguardando revisão"...`);
        const res = await selectedDocument.update({
            updates: {
                availableToClient: false,
                awaitingOperatorReview: true,
                madeAvailableToClientAt: '',
                madeAvailableToClientBy: ''
            }
        });
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    }

    return (
        <AlertDialog
            open={setAwaitingOperatorReviewDialogOpen.value} onClose={handleClose}
            text={`Você quer reabrir o documento "${selectedDocument?.name}"? O documento será disponibilizado na página de documentos em aberto.`}
            okButtonOnClick={handleConfirm}
        />
    );
}

const ChangeGroupButton = ({ handleClose }) => {
    useSignals();
    const { projectGroupSettings } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { showChangeDocumentGroupView } = useDocumentsAPI();
    const { actionMenuOpen, actionMenuSelectedDocument: selectedDocument } = useDocumentsCtxActionMenu();
    const [groupsEnabled, setGroupsEnabled] = useState(false);

    useEffect(() => {
        if(actionMenuOpen){
            setFirestoreListener('projectGroupSettings', selectedDocument.projectId);
        }
    }, [actionMenuOpen]);

    useEffect(() => {
        if(projectGroupSettings.value && projectGroupSettings.value.projectId === selectedDocument.projectId){
            setGroupsEnabled(projectGroupSettings.value.enabled);
        }
    }, [projectGroupSettings.value]);

    const handleChangeDocumentGroupViewOpenButtonClick = (e) => {
        handleClose();
        showChangeDocumentGroupView(e.target, selectedDocument);
    };

    if(groupsEnabled){
        return (
            <MenuItem onClick={handleChangeDocumentGroupViewOpenButtonClick}>
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Alterar grupo" />
            </MenuItem>
        )
    }
    return null;
}

const ActionMenu = () => {
    useSignals();
    const { activeUser, selectedDocumentWithCompleteForm, selectedWorkspace, workspaceUsers } = useAppStateCtx();
    const { handleNavigate, setLoading } = useAppCtxAPI();
    const projects = useOperatorCtxProjects();
    const { setActionMenuOpen, showChangeDocumentFolderView, showChangeDocumentNameView, showDeleteDocumentView, showFlagDocumentView } = useDocumentsAPI();
    const { actionMenuAnchorEl, actionMenuOpen, actionMenuSelectedDocument: selectedDocument } = useDocumentsCtxActionMenu();
    const [generateDocumentDialogOpen, setGenerateDocumentDialogOpen] = useState(false);
    const [updateDocumentSignaturesDialogOpen, setUpdateDocumentSignatures] = useState(false);

    const handle_editNameButtonClick = () => {
        handleClose();
        showChangeDocumentNameView(selectedDocument);
    };
    const handle_deleteButtonClick = () => {
        handleClose();
        showDeleteDocumentView(selectedDocument);
    };
    const handleFlagDocDialogOpen = () => {
        handleClose();
        showFlagDocumentView(selectedDocument);
    };

    const handleOpenFormClick = () => {
        selectedDocumentWithCompleteForm.value = selectedDocument;
        handleClose();
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/formulario`);
    };
    const handleEditTemplateClick = () => {
        handleClose();
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes/${selectedDocument.templateId}/documento`);
    };
    const handleGenerateAgainClick = () => {
        setGenerateDocumentDialogOpen(true);
    };
    const handleGenerateDocumentConfirm = async () => {
        setGenerateDocumentDialogOpen(false);
        handleClose();
        const toastId = toast.loading(`Gerando arquivo a partir do formulário do documento "${selectedDocument.name}"...`);
        const res = await selectedDocument.createVersionFromForm();
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleUpdateSignatureStatus = () => {
        setUpdateDocumentSignatures(true);
    };
    const handleUpdateDocumentSignaturesConfirm = async (eSignaturePlatformId) => {
        setUpdateDocumentSignatures(false);
        handleClose();
        // const postData = {
        //     documentId: selectedDocument.uid,
        //     eSignaturePlatform: 'zapSign',
        //     eSignaturePlatformId
        // };
        ///TODO
        // /data/documents/sign/update
        toast(ERROR_MESSAGE_UNKNOWN);
    };

    const handleChangeDocumentFolderViewOpenButtonClick = (e) => {
        handleClose();
        showChangeDocumentFolderView(e.target, selectedDocument);
    };

    const handleSetAwaitingOperatorReviewClick = async () => {
        setAwaitingOperatorReviewDialogOpen.value = true;
    }

    const handleRemoveFlagClick = async () => {
        handleClose();
        const type = '';
        flagDocument({
            activeUser: activeUser.value, 
            comment: '',
            projects,
            selectedDocument,
            setLoading,
            toast,
            type,
            users: workspaceUsers.value
        });
    };

    const handleGetDocumentUserEmails = () => {
        handleClose();
        selectedDocument.getUsers();
    }
    
    const handleClose = () => {
        setActionMenuOpen(false);
    };
    
    return (
        <>
            <Menu
                anchorEl={actionMenuAnchorEl}
                open={actionMenuOpen}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handle_editNameButtonClick()}>
                    <ListItemIcon>
                        <TitleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Renomear" />
                </MenuItem>
                {
                    !selectedDocument?.awaitingOperatorReview &&
                    <MenuItem onClick={handleSetAwaitingOperatorReviewClick}>
                        <ListItemIcon>
                            <WorkHistoryIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Marcar "aguardando revisão"`} />
                    </MenuItem>
                }
                {
                    (!selectedDocument?.flag?.type || selectedDocument?.flag?.type !== 'operator') &&
                    <MenuItem onClick={handleFlagDocDialogOpen}>
                        <ListItemIcon>
                            <FlagIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sinalizar" />
                    </MenuItem>
                }
                {
                    (!!selectedDocument?.flag?.type) &&
                    <MenuItem onClick={handleRemoveFlagClick}>
                        <ListItemIcon>
                            <FlagIcon />
                        </ListItemIcon>
                        <ListItemText primary="Desmarcar sinalização" />
                    </MenuItem>
                }
                {
                    (selectedDocument?.form !== '{}' && selectedDocument?.templateId !== 'review') &&
                    <MenuItem onClick={handleOpenFormClick}>
                        <ListItemIcon>
                            <QuestionAnswerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Abrir formulário" />
                    </MenuItem>
                }
                {
                    (selectedWorkspace.value?.modules?.includes('templates') && selectedDocument?.templateId !== 'review') &&
                    <MenuItem onClick={handleEditTemplateClick}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alterar matriz" />
                    </MenuItem>
                }
                {
                    (selectedDocument?.form !== '{}' && selectedDocument?.templateId !== 'review') &&
                    <MenuItem onClick={handleGenerateAgainClick}>
                        <ListItemIcon>
                            <FlashOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Gerar novamente" />
                    </MenuItem>
                }
                {
                    ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                    <MenuItem onClick={handleChangeDocumentFolderViewOpenButtonClick}>
                        <ListItemIcon>
                            <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary="Alterar pasta" />
                    </MenuItem>
                }
                <ChangeGroupButton handleClose={handleClose} />
                {
                    ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                    <>
                        <MenuItem onClick={handleUpdateSignatureStatus}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Atualizar situação de assinatura" />
                        </MenuItem>
                        <MenuItem onClick={handleGetDocumentUserEmails}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Verificar e-mails de usuários do documento" />
                        </MenuItem>
                    </>
                }
                <MenuItem onClick={handle_deleteButtonClick}>
                    <ListItemIcon>
                        <ClearIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cancelar" />
                </MenuItem>
            </Menu>

            <AlertDialog
                open={generateDocumentDialogOpen} onClose={() => setGenerateDocumentDialogOpen(false)}
                text={`Você quer gerar um documento a partir do formulário?`}
                okButtonOnClick={handleGenerateDocumentConfirm}
            />
            <FormDialog
                open={updateDocumentSignaturesDialogOpen} onClose={() => setUpdateDocumentSignatures(false)}
                title="Atualizar situação do processo de assinatura eletrônica"
                text={`Qual é o token da ZapSign? (Essa funcionalidade não está disponível para DocuSign)`}
                onConfirm={handleUpdateDocumentSignaturesConfirm}
            />
            <SetAwaitingOperatorReviewDialog
                closeMenu={handleClose}
                selectedDocument={selectedDocument}
            />
        </>
    );
}

export default ActionMenu;