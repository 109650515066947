export default class ClearanceNoteComment {
    approval? = '';
    createdAt = '';
    createdBy = '';
    createdByName? = '';
    comment = '';
    deleted? = false;
    deletedAt? = '';
    deletedBy? = '';
    files?: {
        [fileId: string]: {
            firebaseStoragePath: string;
            name: string;
            url: string;
        }
    } = {};
    rightType? = '';
    rightTypeDescription? = '';
    riskLevel? = '';
    riskLevelDescription? = '';
    uid? = '';
    useType? = '';
    useTypeDescription? = '';

    constructor({
        approval,
        createdAt,
        createdBy,
        createdByName,
        comment,
        deleted,
        deletedAt,
        deletedBy,
        files,
        rightType,
        rightTypeDescription,
        riskLevel,
        riskLevelDescription,
        uid,
        useType,
        useTypeDescription,
    }: ClearanceNoteComment){
        if(approval) this.approval = approval;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(createdByName) this.createdByName = createdByName;
        if(comment) this.comment = comment;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(files) this.files = files;
        if(rightType) this.rightType = rightType;
        if(rightTypeDescription) this.rightTypeDescription = rightTypeDescription;
        if(riskLevel) this.riskLevel = riskLevel;
        if(riskLevelDescription) this.riskLevelDescription = riskLevelDescription;
        if(uid) this.uid = uid;
        if(useType) this.useType = useType;
        if(useTypeDescription) this.useTypeDescription = useTypeDescription;
    }
}