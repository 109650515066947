import {
    collection,
    FieldPath,
    getCountFromServer,
    query,
    QueryConstraint,
    where as _where,
    WhereFilterOp
} from 'firebase/firestore';

import { db } from '../config';

export default async function getDocsCount(
    path: string,
    queryConstraints: {
        queryConstraints: QueryConstraint[];
        wheres: [string | FieldPath, WhereFilterOp, unknown][]
    }
) {
    let result = null, error = null;
    
    const currentQueryConstraints: QueryConstraint[] = queryConstraints.queryConstraints || [];
    
    if(queryConstraints.wheres) queryConstraints.wheres.forEach(where => currentQueryConstraints.push(_where(...where)));
    
    const docsRef = query(collection(db, path), ...currentQueryConstraints);

    try {
        const snapshot = await getCountFromServer(docsRef);
        result = snapshot.data().count;
    } catch (e) {
        error = e;
    }

    return { result, error };
}