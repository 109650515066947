import React, { useEffect } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PopOver from '../../../../components/PopOver';
import Select from '../../../../components/Select';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useAppCtxLoading } from '../../../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxDocumentFiltersView } from '../../../../context/DocumentsContext';
import { getProjectTemplates } from '../../../../utils/common';
import { sortByKey } from '../../../../utils/filters';

const StyledSelect = ({disabled, label, options = [], value, onChange}) => (
    <Select
        disabled={disabled}
        style={{fontSize: 12}}
        optionsStyle={{fontSize: 12}}
        label={label}
        options={[
            {value: 'all', label: 'Todos'},
            ...options
        ]}
        value={value} onChange={onChange}
    />
);

const CreatedBySelector = ({ filters, onChange }) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    return (
        <StyledSelect
            // disabled={loading}
            label="Solicitante"
            options={[
                {value: activeUser.value.uid, label: 'Eu'}
            ]}
            value={filters.createdBy} onChange={onChange}
        />
    );
}

const SignatureStatusSelector = ({ filters, onChange }) => {
    useSignals();
    const { folderESignatureSettings } = useAppStateCtx();

    if(folderESignatureSettings.value?.enabled){
        return (
            <Grid item xs={6}>
                <Select
                    // disabled={loading}
                    style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                    label="Assinatura"
                    options={[
                        {value: 'all', label: 'Todos'},
                        {value: 'pending', label: 'Aguardando assinaturas'},
                        {value: 'signed', label: 'Assinados por todos'},
                    ]}
                    value={filters.signatureStatus} onChange={onChange}
                />
            </Grid>
        );
    }
    return null;         
}

const GroupSelector = ({ clientProjects, defaultGroupOptions, filters, onChange }) => {
    useSignals();
    const { activeUserGroups, projectGroupSettings } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();
    
    let folderGroups = null;
    if(selectedFolderIsClient.value){
        if(filters.projectId !== 'all'){
            const foundFolder = clientProjects.find(folder => folder.uid === filters.projectId);
            if(foundFolder?.useGroups){
                folderGroups = [
                    ...defaultGroupOptions,
                    ...foundFolder.groups.map(group => ({value: group.id, label: group.name}))
                ];
            }
        }
    } else {
        if(projectGroupSettings.value?.enabled && activeUserGroups.value){
            const mappedProjectGroups = activeUserGroups.value.map(group => ({ value: group.id, label: group.name }))
            folderGroups = [...defaultGroupOptions, ...mappedProjectGroups];
        }
    }
    if(folderGroups){
        return (
            <Grid item xs={6}>
                <Select
                    // disabled={loading}
                    style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                    label="Grupo" options={folderGroups} value={filters.groupId}
                    onChange={onChange} />
            </Grid>
        );
    }
    return null;
};

const CustomFiltersPopOver = ({data, filters, setFilters}) => {
    useSignals();
    const {
        selectedFolder,
        clientProjects,
        customFiltersCount, set_customFiltersCount
    } = data;
    const {
        projectDocumentsTemplateOptions,
        // projectTalentSettings,
        workspaceTemplateLists
    } = useAppStateCtx();
    const { selectedFolderIsClient, setFirestoreListener } = useAppStateCtxUtils();
    const { hideDocumentFiltersView } = useDocumentsAPI();
    const { documentFiltersViewAnchorEl, documentFiltersViewOpen } = useDocumentsCtxDocumentFiltersView();
    const loading = useAppCtxLoading();

    const defaultAllOption = {value: 'all', label: 'Todos'};
    const defaultGroupOptions = [defaultAllOption];
    const defaultFormOptions = [defaultAllOption];
    // const [currentTextFields, setCurrentTextFields] = useState({
    //     productionCompany: '',
    //     workPlayer: '',
    // });

    useEffect(() => {
        if(selectedFolder.value){
            setFirestoreListener('projectTalentSettings');
        }
    }, [selectedFolder.value]);
 
    useEffect(() => {
        let count = 0;
        if(filters.createdBy !== 'all') count++;
        if(filters.templateId !== 'all') count++;
        if(filters.groupId !== 'all') count++;
        if(filters.status !== 'all') count++;
        if(filters.signatureStatus !== 'all') count++;
        if(selectedFolderIsClient.value && filters.projectId !== 'all') count++;
        set_customFiltersCount(count);
    }, [filters]);

    const handleClose = () => {
        hideDocumentFiltersView();
    };

    const clearFilters = () => {
        handleClose();
        setFilters(prevState => {
            const nextState = {
                ...prevState,
                action: '',
                createdBy: 'all',
                groupId: 'all',
                signatureStatus: 'all',
                status: 'all',
                templateId: 'all',
            }
            if(selectedFolderIsClient.value) nextState.projectId = 'all';

            return nextState;
        });
        // set_selectedForm('all');
        // set_selectedGroup('all');
        // set_selectedStatus('all');
        // set_selectedSignatureStatus('all');
        // if(selectedFolderIsClient.value) set_selectedProject('all');
        // setFilters({});
    };
    const handleUserChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            action: '',
            createdBy: newValue,
        }));
    };
    const handleFormChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            action: '',
            templateId: newValue
        }));
    };
    const handleProjectChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            action: '',
            groupId: 'all',
            projectId: newValue,
            templateId: 'all'
        }));
    };
    const handleGroupChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            action: '',
            groupId: newValue
        }));
    };
    const handleStatusChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            action: '',
            status: newValue
        }));
    };
    const handleSignatureStatusChange = (newValue) => {
        handleClose();
        setFilters(prevState => ({
            ...prevState,
            action: '',
            signatureStatus: newValue
        }));
    };
    
    // const handleInputKeyDown = (e, filtersKey) => {
    //     if(e.key === 'Enter'){
    //         setFilters(prevState => ({...prevState, [filtersKey]: currentTextFields[filtersKey]}));
    //         handleClose();
    //     }
    // }

    const FormsField = () => {
        let formsOptions = null;
        if(selectedFolderIsClient.value){
            if(filters.projectId !== 'all'){
                const foundFolder = clientProjects.find(folder => folder.uid === filters.projectId);
                if(foundFolder){
                    // foundFolder => documentsSettingsRes.result.templates
                    const foundFolderTemplates = getProjectTemplates(foundFolder, workspaceTemplateLists.value);
                    formsOptions = foundFolderTemplates;
                }
            }
        } else if(projectDocumentsTemplateOptions.value) {
            formsOptions = projectDocumentsTemplateOptions.value;
        }
        if(formsOptions){
            return (
                <Grid item xs={6}>
                    <Select
                        disabled={loading}
                        style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                        label="Formulário"
                        options={[...defaultFormOptions, ...formsOptions.sort(sortByKey('label'))]}
                        value={filters.templateId}
                        onChange={(e) => handleFormChange(e.target.value)}
                    />
                </Grid>
            );
        }
        return null;
    };
    
    return (
        <PopOver open={documentFiltersViewOpen} onClose={handleClose} anchorEl={documentFiltersViewAnchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Box mb={2} style={{display: 'flex', alignItems: 'center'}}>
                <Box style={{flex: 1}}>
                    <Typography variant="h6">Filtros{customFiltersCount ? ` (${customFiltersCount})` : ''}</Typography>
                </Box>
                <Box>
                    <Button
                        onClick={clearFilters}
                    >Limpar</Button>
                </Box>
            </Box>
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <CreatedBySelector filters={filters} onChange={(e) => handleUserChange(e.target.value)} />
                    </Grid>
                    {
                        selectedFolderIsClient.value &&
                        <Grid item xs={6}>
                            <Select
                                disabled={loading}
                                style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                                label="Projeto"
                                options={
                                    [
                                        {value: 'all', label: 'Todos'},
                                        ...clientProjects.map(p => ({value: p.uid, label: p.name}))
                                    ]
                                }
                                value={filters.projectId}
                                onChange={(e) => handleProjectChange(e.target.value)}
                            />
                        </Grid>
                    }
                    <FormsField />
                    <GroupSelector clientProjects={clientProjects} defaultGroupOptions={defaultGroupOptions} filters={filters} onChange={(e) => handleGroupChange(e.target.value)} />
                    <Grid item xs={6}>
                        <Select
                            disabled={loading}
                            style={{fontSize: 12}} optionsStyle={{fontSize: 12}}
                            label="Jurídico"
                            options={[
                                {value: 'all', label: 'Todos'},
                                {value: 'pending', label: 'Em análise'},
                                {value: 'sent', label: 'Entregue'},
                                {value: 'flag1', label: 'Sinalizado pelo Jurídico'},
                                {value: 'flag2', label: 'Sinalizado para o Jurídico (aguardando retorno)'},
                            ]}
                            value={filters.status} onChange={(e) => handleStatusChange(e.target.value)}
                        />
                    </Grid>
                    <SignatureStatusSelector
                        filters={filters}
                        onChange={(e) => handleSignatureStatusChange(e.target.value)}
                    />
                    {
                        // projectTalentSettings.value?.enabled &&
                        // <>
                        //     <Grid item xs={6}>
                        //         <Input shrinkLabel fullWidth label="Produtora"
                        //             value={currentTextFields.productionCompany}
                        //             onChange={(e) => setCurrentTextFields(prevState => ({...prevState, productionCompany: e.target.value}))}
                        //             onKeyDown={(e) => handleInputKeyDown(e, 'productionCompany')}
                        //         />
                        //     </Grid>
                        //     <Grid item xs={6}>
                        //         <Input shrinkLabel fullWidth label="Player"
                        //             value={currentTextFields.workPlayer}
                        //             onChange={(e) => setCurrentTextFields(prevState => ({...prevState, workPlayer: e.target.value}))}
                        //             onKeyDown={(e) => handleInputKeyDown(e, 'workPlayer')}
                        //         />
                        //     </Grid>
                        // </>
                    }
                </Grid>
            </Box>
        </PopOver>
    );
};

export default CustomFiltersPopOver;