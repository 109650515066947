import { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import ActionMenu from './components/ActionMenu';
import ChangeStarredInfoPopOver from './components/ChangeStarredInfoPopOver';
import CustomFiltersPopOver from './components/CustomFiltersPopOver';

import AddDocumentWindow from '../../../components/AddDocumentWindow';
import AddAlertWindow from '../../../components/AddAlertWindow';
import ChangePageButton from '../../../components/ChangePageButton';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import DeleteDocDialog from '../../../components/DeleteDocDialog';
import DocFormView from '../../../components/DocFormView';
import DocumentListItem from '../../../components/DocumentListItem';
import DocumentsTop from './components/DocumentsTop';
import HistoryPopOver from '../../../components/HistoryPopOver';
import NewStarredInfoPopOver from '../../../components/NewStarredInfoPopOver';
import NoResults from '../../../components/NoResults';
import SignDocumentWindow from '../../../components/eSignature/SignDocumentWindow';
import SubmitDocDialog from '../../../components/SubmitDocDialog';
import UpdateNameDialog from '../../../components/UpdateNameDialog';
import ViewBox from '../../../components/ViewBox';
import { getGroupNameById } from '../../../utils/common';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { useDocumentsAPI } from '../../../context/DocumentsContext';
import { useFolderUserCtxCompanyFolders, useFolderUserCtxUsers } from '../context/ClientContext';
import FlagDocumentWindow from '../../../components/Documents/FlagDocumentWindow';
import {
    // getFolderCustomDocumentFields,
    getFolderGroups,
    // resetCustomDocumentFieldsMapOptions
} from  '../../../utils/common';
import CommentWindow from '../../../components/CommentWindow';
import { getDocumentLastCommentIfHuman } from '../../../utils/common';

import useGetDocumentsAsClient from '../../../hooks/useGetDocumentsAsClient';

const addDocumentViewOpen = signal(false);
const documentsListenerSettings = signal(null);
const filtersText = signal('');
const loading = signal(true);
const numberOfDocuments = signal(0);
const shouldSetListener = signal(false);
const visibleDocuments = signal(null);

const NumberOfDocuments = () => {
    useSignals();
    const MAX_TEXT_RESULTS = 25;

    if(!!numberOfDocuments.value){
        return (
            <Box mb={2}>
                <Grid container justifyContent="center">
                    <Grid>
                        <Typography variant="body1">({numberOfDocuments.value} documento{numberOfDocuments.value > 1 ? 's' : ''}{filtersText.value && numberOfDocuments.value >= MAX_TEXT_RESULTS ? `. A pesquisa textual exibe até ${MAX_TEXT_RESULTS} documentos.` : ''})</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
}

const LocalChangePageButton = () => {
    useSignals();

    const handleNextClick = () => {
        documentsListenerSettings.value = {
            ...documentsListenerSettings.value,
            action: 'nextPage',
            page: documentsListenerSettings.value.page + 1,
        }
    };

    const handlePrevClick = () => {
        documentsListenerSettings.value = {
            ...documentsListenerSettings.value,
            action: 'prevPage',
            page: documentsListenerSettings.value.page - 1
        };
    };

    return (
        <ChangePageButton numberOfRows={numberOfDocuments.value} onNextClick={handleNextClick} onPrevClick={handlePrevClick} page={documentsListenerSettings.value?.page || 1} />
    );
}

const DocumentsList = () => {
    useSignals();
    const theme = useTheme();
    const { activeUserIsGroupsManager, folderESignatureSettings, projectGroups, projectGroupSettings, selectedFolder, selectedProjectClient, workspaceUsers } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();
    const { setActionMenuAnchorEl, setSelectedDocument, showFlagDocumentView } = useDocumentsAPI();
    const clientProjects = useFolderUserCtxCompanyFolders();

    const handle_flagDocClick = (record) => {
        showFlagDocumentView(record);
    };
    
    const handleActionMenuClick = (event, clickedDocument) => {
        setActionMenuAnchorEl(event.target, clickedDocument);
    };
    
    const handleSetSelectedDocument = (selectedDocument) => {
        // let documentFolder = selectedFolder.value;
        // if(selectedFolderIsClient.value){
        //     const folderId = selectedDocument.project;
        //     documentFolder = clientProjects.find(folder => folder.uid === folderId);
        // }
        setSelectedDocument(selectedDocument);
    };

    if(loading.value){
        return (
            <LoaderEllipsis />
        );
    }

    if(visibleDocuments.value){
        if(visibleDocuments.value.length !== 0){
            return (
                <>
                    <NumberOfDocuments />
                    <LocalChangePageButton />
                    <List dense>
                        {
                            visibleDocuments.value
                            .map((item) => {
                                let documentFolder = selectedFolder.value;
                                let documentFolderName = '';
                                let useGroups = projectGroupSettings.value?.enabled;
                                let documentFolderGroups = projectGroups.value;
                                let groupName = '';
                                if(selectedFolderIsClient.value){
                                    const folderId = item.project;
                                    documentFolder = clientProjects.find(folder => folder.uid === folderId);
                                    documentFolderName = documentFolder?.name;
                                    useGroups = documentFolder?.useGroups;
                                    documentFolderGroups = getFolderGroups(documentFolder, documentFolder?.team?.all);
                                    documentFolderGroups = documentFolderGroups?.folderGroups || [];
                                } else {
                                    documentFolderName = selectedFolder.value?.name;
                                }
                                if(useGroups) groupName = getGroupNameById(item.groupId, documentFolderGroups);
                                
                                const { comment: documentComment, noHtmlComment: documentNoHtmlComment } = getDocumentLastCommentIfHuman(item, workspaceUsers.value);
                                
                                return (
                                    <DocumentListItem
                                        key={item.uid}
                                        activeUserIsManager={documentFolder && (!documentFolder.useGroups || activeUserIsGroupsManager.value)}
                                        boxStyle={item.flag === 1 ? {border: `2px solid ${theme.palette.secondary.main}`} : null}
                                        comment={documentComment}
                                        //companyName
                                        documentFolder={documentFolder}
                                        eSignature={folderESignatureSettings.value?.enabled}
                                        folderName={documentFolderName}
                                        groupName={groupName}
                                        handleActionMenuOpen={handleActionMenuClick}
                                        handleFlagDocumentButtonClick={handle_flagDocClick}
                                        listDocument={item}
                                        noHtmlComment={documentNoHtmlComment}
                                        projects={selectedFolderIsClient.value ? [selectedFolder.value, ...clientProjects] : (selectedProjectClient.value ? [selectedFolder.value, selectedProjectClient.value] : [selectedFolder.value])}
                                        setSelectedDocument={handleSetSelectedDocument}
                                        showCompany={selectedFolderIsClient.value}
                                        useGroups={useGroups}
                                    />
                                )
                            })
                        }
                    </List>
                    <LocalChangePageButton />
                </>
            );
        }
        return (
            <NoResults text="Nenhum documento foi encontrado." />
        );
    }

    return null;
}

function Docs(){
    useSignals();
    const filtersButtonRef = useRef();
    const { selectedFolder } = useAppStateCtx();
    const { selectedFolderIsClient, setFirestoreListener } = useAppStateCtxUtils();
    const clientProjects = useFolderUserCtxCompanyFolders();
    const users = useFolderUserCtxUsers();
    const [changeStarredInfoList, set_changeStarredInfoList] = useState([]);
    // const [customFiltersValues, set_customFiltersValues] = useState([]);
    const [customFiltersCount, set_customFiltersCount] = useState(0);
    // const [currentFolderClientFields, set_currentFolderClientFields] = useState([]);

    const defaultFilterValue = {
        createdBy: 'all',
        groupId: 'all',
        projectId: 'all',
        signatureStatus: 'all',
        status: 'all',
        templateId: 'all',
    };
    const [filters, setFilters] = useState(defaultFilterValue);

    useEffect(() => {
        return () => {
            documentsListenerSettings.value = null;
            filtersText.value = '';
            loading.value = true;
        };
    }, []);

    useEffect(() => {
        setFirestoreListener('projectDocumentSettings');
    }, []);

    useEffect(() => {
        if(selectedFolder.value?.uid){
            setFirestoreListener('folderESignatureSettings', `${selectedFolderIsClient.value ? 'clients' : 'projects'}/${selectedFolder.value?.uid}`);
        }
    }, [selectedFolder.value?.uid, selectedFolderIsClient.value]);

    useEffect(() => {
        if(selectedFolder.value?.uid){
            loading.value = true;
            documentsListenerSettings.value = {
                action: '',
                createdBy: filters.createdBy || 'all',
                groupId: filters.groupId || 'all',
                page: 1,
                projectId: filters.projectId || 'all',
                signatureStatus: filters.signatureStatus || 'all',
                status: filters.status || 'all',
                templateId: filters.templateId || 'all',
                text: filtersText.value
            }
            shouldSetListener.value = true;
        }
    }, [selectedFolder.value?.uid, filters, filtersText.value]);

    const retrievedDocuments = useGetDocumentsAsClient(shouldSetListener.value, documentsListenerSettings.value);
    useEffect(() => {
        if(retrievedDocuments.loading){
            loading.value = true;
        } else if(retrievedDocuments.data) {
            numberOfDocuments.value = retrievedDocuments.count || retrievedDocuments.data.length;
            visibleDocuments.value = retrievedDocuments.data || [];
            loading.value = false;
        }
    }, [retrievedDocuments]);

    // useEffect(() => {
    //     if(selectedFolder.value){
    //         // const folderCustomDocumentFields = getFolderCustomDocumentFields(selectedFolder.value);
    //         // set_currentFolderClientFields(folderCustomDocumentFields);

    //         // const options = currentFolderClientFields.filter(i => i.type === 'select');
    //         // const customDocumentFieldsValues = resetCustomDocumentFieldsMapOptions(options);
    //         // set_customFiltersValues(customDocumentFieldsValues);
    //     }
    // }, [selectedFolder.value]);

    return (
        <ViewBox>
            <DocumentsTop
                filtersButtonRef={filtersButtonRef}
                // getQuery={getQuery}
                loading={loading}
                searchValue={filtersText}
            />

            <DocumentsList />

            <ActionMenu />
            <AddAlertWindow />
            <AddDocumentWindow
                // actionCallback={() => handle_pageClick(1)}
                open={addDocumentViewOpen}
            />
            <ChangeStarredInfoPopOver
                list={changeStarredInfoList}
                data={{set_changeStarredInfoList}}
            />
            <CommentWindow users={users} />
            <CustomFiltersPopOver
                data={{
                    selectedFolder: selectedFolder.value,
                    clientProjects,
                    customFiltersCount, set_customFiltersCount
                }}
                filters={filters}
                setFilters={setFilters}
            />
            <DeleteDocDialog />
            <DocFormView />
            <FlagDocumentWindow />
            <HistoryPopOver />
            <NewStarredInfoPopOver list={changeStarredInfoList} />
            <SignDocumentWindow />
            <SubmitDocDialog />
            <UpdateNameDialog />
        </ViewBox>
    );
}

export default Docs;