import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

const ActiveProjectsStatusBox = ({activeProjectStatus, onClick, style, textStyle}) => {
    
    const handleActiveProjectStatusBoxClick = (activeProjectStatus) => {
        if(onClick) onClick(activeProjectStatus);
    };

    const getBoxStyle = (activeProjectStatus) => {
        if(style){
            if(typeof style === 'function'){
                return style(activeProjectStatus);
            }
            return style;
        }
        return undefined;
    };
    
    const getTextStyle = (activeProjectStatus) => {
        if(textStyle){
            if(typeof textStyle === 'function'){
                return textStyle(activeProjectStatus);
            }
            return textStyle;
        }
        return undefined;
    };

    return (
        <Grid container>
            <Grid>
                <Box
                    sx={{
                        border: '2px solid transparent',
                        borderRadius: '4px',
                        boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
                        cursor: onClick ? 'pointer' : undefined,
                        opacity: 0.6,
                        padding: '2px 4px',
                        transition: 'border .2s ease-out, opacity .2s ease-out',
                        '&:hover': {
                            border: '2px solid #313330',
                            opacity: 1,
                        },
                        '&:hover .active-project-status-box-text': {
                            opacity: 1
                        },
                    }}
                    style={getBoxStyle(activeProjectStatus)}
                    onClick={() => handleActiveProjectStatusBoxClick(activeProjectStatus)}
                    title={activeProjectStatus.label}
                >
                    <Grid container>
                        <Grid display="flex" alignItems="center">
                            {activeProjectStatus.icon}
                        </Grid>
                        <Grid size="grow" display="flex" alignItems="center">
                            <Typography variant="body2" noWrap className="active-project-status-box-text" sx={{opacity: 0.7, transition: 'opacity .2s ease-out'}} style={getTextStyle(activeProjectStatus)}><strong>{activeProjectStatus.label}</strong></Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
}

export default ActiveProjectsStatusBox;