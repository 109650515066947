import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { useSignals } from '@preact/signals-react/runtime';

import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';

import LoaderEllipsis from '../LoaderEllipsis';
import Files from './Files';
import PositionSignaturesWindow from './PositionSignaturesWindow';
import Signers from './Signers';

import Client from '../../classes/Client';
import DocumentsTemplate from '../../classes/DocumentsTemplate';
import Project from '../../classes/Project';
import { useAppStateCtx } from '../../context/AppState';
import { useDocumentsAPI, useDocumentsCtxLoadingSignatures, useDocumentsCtxSignatureEnvelope, useDocumentsCtxSignDocumentView } from '../../context/DocumentsContext';
import { validateEmail } from '../../utils/common';
import { ERROR_MESSAGE_UNKNOWN, CPF_REGEXP } from '../../utils/constants';

const SignDocumentWindow = () => {
    useSignals();
    const { activeUser, workspaceClients, workspaceProjects } = useAppStateCtx();
    const { hideSignDocumentView, setLoadingSignatures, setSignDocumentViewSignaturePlatform } = useDocumentsAPI();
    const loadingSignatures = useDocumentsCtxLoadingSignatures();
    const eSignatureEnvelope = useDocumentsCtxSignatureEnvelope();
    const {
        signDocumentViewCompanyFolder,
        signDocumentViewDefaultSigners,
        signDocumentViewDocument,
        signDocumentViewDocumentName,
        signDocumentViewFolderFolder,
        signDocumentViewFolderId,
        signDocumentViewFolderName,
        signDocumentViewOpen,
        signDocumentViewSignaturePlatform,
        // eSignatureEnvelope
    } = useDocumentsCtxSignDocumentView();
    const [originalBase64String, setOriginalBase64String] = useState(null);
    const [base64String, set_base64String] = useState(null);
    const [files, setFiles] = useState([]);
    const [availableFiles, setAvailableFiles] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [signers, set_signers] = useState(null);
    const defaultSigner = {
        brazilian: true,
        email: '',
        locked: false,
        mobile: '',
        name: '',
        realId: '',
        type: 'party',
    };
    const [boxes, set_boxes] = useState([]);
    const [positionSignaturesWindowOpen, set_positionSignaturesWindowOpen] = useState(false);
    const [orderSignatures, set_orderSignatures] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const [loadingESignatureProgress, set_loadingESignatureProgress] = useState(null);
    const [formError, setFormError] = useState('');

    const getFormSigner = (foundDocument, documentTemplateForm) => {
        let formSignerName = '', formSignerEmail = '', formSignerRealId = '';
        if(documentTemplateForm){
            let assinedFieldQuestionsRepresentative = documentTemplateForm.filter(q => q.assignedField === 4);
            let assinedFieldQuestionsRepresentativeId = documentTemplateForm.filter(q => q.assignedField === 5);
            let assinedFieldQuestionsName = documentTemplateForm.filter(q => q.assignedField === 1);
            let assinedFieldQuestionsId = documentTemplateForm.filter(q => q.assignedField === 2);
            let assinedFieldQuestionsEmail = documentTemplateForm.filter(q => q.assignedField === 'signerEmail' || q.assignedField === 'email');
            try {
                const documentForm = foundDocument.form;
                const userForm = JSON.parse(documentForm);
                if(assinedFieldQuestionsRepresentative.length >= 1){
                    let question = assinedFieldQuestionsRepresentative[0];
                    if(userForm[question.name]){
                        formSignerName = userForm[question.name].input;
                    }
                } else if(assinedFieldQuestionsName.length >= 1){
                    let question = assinedFieldQuestionsName[0];
                    if(userForm[question.name]){
                        formSignerName = userForm[question.name].input;
                    }
                }
                if(assinedFieldQuestionsRepresentativeId.length >= 1){
                    let question = assinedFieldQuestionsRepresentativeId[0];
                    if(userForm[question.name]){
                        if(CPF_REGEXP.test(userForm[question.name].input)) formSignerRealId = userForm[question.name].input;
                    }
                } else if(assinedFieldQuestionsId.length >= 1){
                    let question = assinedFieldQuestionsId[0];
                    if(userForm[question.name]){
                        formSignerRealId = userForm[question.name].input;
                    }
                }
                if(assinedFieldQuestionsEmail.length >= 1){
                    let question = assinedFieldQuestionsEmail[0];
                    if(userForm[question.name]){
                        formSignerEmail = userForm[question.name].input;
                    }
                }
            } catch (error) {
                //
            }
        }
    
        if(!formSignerName && foundDocument.signerName) formSignerName = foundDocument.signerName;
        if(!formSignerEmail && foundDocument.signerEmail) formSignerEmail = foundDocument.signerEmail;
        
        const formSigner = {
            email: formSignerEmail,
            name: formSignerName,
            realId: formSignerRealId,
            type: 'party'
        }
        return formSigner;
    };

    const getSigners = (projectSigners = {}, formSigner) => {
        let currentSigners = [
            {...defaultSigner, id: uuidv4()},
            {...defaultSigner, id: uuidv4()},
            {...defaultSigner, type: 'witness', id: uuidv4()},
            {...defaultSigner, type: 'witness', id: uuidv4()}
        ];
        let initialSigners = [];
        if(projectSigners) initialSigners = Object.entries(projectSigners).map(([signerId, signer]) => ({...signer, uid: signerId}));
        if(formSigner){
            initialSigners.push(formSigner);
        } else {
            initialSigners.push({...defaultSigner});
        }
        const witnesses = initialSigners.filter(signer => signer.type === 'witness');
        if(witnesses.length === 0){
            initialSigners.push({type: 'witness'}, {type: 'witness'});
        } else if(witnesses.length === 1){
            initialSigners.push({type: 'witness'});
        }
        if(initialSigners.length !== 0){
            currentSigners = initialSigners.map(initialSigner => {
                const signer = {...defaultSigner, ...initialSigner};
                if(!signer.uid) signer.uid = uuidv4();
                signer.name = signer.name ? signer.name.trim() : '';
                signer.email = signer.email ? signer.email.trim() : '';
                signer.realId = signer.taxpayerNumber ? signer.taxpayerNumber.trim() : '';
                return signer;
            });
        }

        set_signers(currentSigners);
    };

    const getDocumentElectronicSignatureSettings = async () => {
        if(signDocumentViewDocument){
            const fileFormat = signDocumentViewDocument.lastVersion?.fileFormat;
            if(fileFormat && /^\.?(googleDoc|pdf)$/.test(fileFormat)){
                let formSigner = null;
                const templateId = signDocumentViewDocument.templateId;
                if(templateId !== 'review'){
                    const template = new DocumentsTemplate({ uid: templateId });
                    const getTemplateFormRes = await template.getForm();
                    console.log('templateId', templateId)
                    if(getTemplateFormRes.error || !getTemplateFormRes.result){
                        console.log('getTemplateFormRes.error', getTemplateFormRes.error)
                        return toast(getTemplateFormRes.error?.message || ERROR_MESSAGE_UNKNOWN);
                    } else {
                        const documentTemplateForm = getTemplateFormRes.result;
                        formSigner = getFormSigner(signDocumentViewDocument, documentTemplateForm);
                    }
                }
    
                const projectId = signDocumentViewDocument.projectId;
                const workspaceProject = workspaceProjects.value[projectId];
                const project = new Project({...workspaceProject, uid: projectId})
                const getElectronicSignatureSettingsRes = await project.getElectronicSignatureSettings();
                if(getElectronicSignatureSettingsRes.result){
                    let projectSigners = getElectronicSignatureSettingsRes.result.signers;
                    if(!projectSigners || Object.keys(projectSigners).length === 0){
                        const clientId = signDocumentViewDocument.clientId;
                        const workspaceClient = workspaceClients.value[clientId];
                        const client = new Client({...workspaceClient, uid: clientId})
                        const getClientESignatureSettingsRes = await client.getElectronicSignatureSettings();
                        if(getClientESignatureSettingsRes.result) projectSigners = getClientESignatureSettingsRes.result.signers;
                    }
                    getSigners(projectSigners, formSigner);
                    setSignDocumentViewSignaturePlatform(getElectronicSignatureSettingsRes.result.platform || 'zapSign');
    
                    const getBase64StringRes = await signDocumentViewDocument.getDocumentVersionFileBase64String();
                    setOriginalBase64String(getBase64StringRes.result);
                    set_base64String(getBase64StringRes.result);
                    setLoadingDocument(false);
                }
    
                // const documentFiles = signDocumentViewDocument.files.map(file => {
                //     const fileName = file.split(/\\|\//).pop();
                //     return { path: file, fileName };
                // });
                // setFiles(documentFiles);
                // setAvailableFiles(documentFiles);
                setSelectedFiles([]);

            } else {
                toast(`Não é possível enviar para assinatura documentos ${fileFormat ? `no formato ${fileFormat}` : 'nesse formato'}. Converta o arquivo para GoogleDoc ou PDF e tente novamente.`, { type: 'error' });
                handleClose();
            }
        }
    };

    useEffect(() => {
        if(signDocumentViewDocument){
            setLoadingDocument(true);
            setFormError('');
            set_positionSignaturesWindowOpen(false);
            setOriginalBase64String(null);
            set_base64String(null);
            set_boxes([]);
            getSigners();
            getDocumentElectronicSignatureSettings();
        }
    }, [signDocumentViewDocument]);

    useEffect(() => {
        if(signDocumentViewOpen && eSignatureEnvelope){
            if(eSignatureEnvelope.documents[0]){
                // getBase64String();
            }
            if(['UPDATE DOCUMENT', 'UPDATE TABS'].includes(eSignatureEnvelope.action)){

                if(eSignatureEnvelope.action === 'UPDATE TABS' && eSignatureEnvelope.tabs){
                    set_boxes(eSignatureEnvelope.tabs.filter(eSignatureTab => eSignatureTab.left && eSignatureTab.signer && eSignatureTab.top));
                }
                
                set_positionSignaturesWindowOpen(true);
            }
        }
    }, [signDocumentViewOpen, eSignatureEnvelope]);

    const positionSignatures = async () => {
        setFormError('');
        let errorMessage = '';
        let oneSigner = false;
        if(signers.length === 0){
            return setFormError(`É preciso especificar pelo menos um signatário.`);
        }
        signers.forEach((signer, index) => {
            const signerName = signer.name ? `de ${signer.name}` : `do(a) signatário ${signer.email || (index + 1)}` ;
            if(!signer.name){
                errorMessage = `É preciso preencher o nome completo ${signerName}.`
                return;
            }
            if(!validateEmail(signer.email)){
                errorMessage = `O endereço de e-mail ${signerName} não é válido.`;
                return;
            }
            if(signer.type !== 'observer'){
                oneSigner = true;
                if(signer.brazilian && !signer.realId){
                    errorMessage = `É preciso preencher o CPF ${signerName}.`;
                    return;
                }
            }
        });
        if(errorMessage){
            return setFormError(errorMessage);
        }
        if(!oneSigner){
            return setFormError(`Todas as pessoas foram marcadas como observadoras. É preciso especificar pelo menos um signatário.`);
        }
        if(selectedFiles.length !== 0){
            // setLoadingDocument(true);
            //TODO
            // /data/documents/merge-pdfs-base64
            // {
            //     base64String: originalBase64String,
            //     files: selectedFiles
            // }
            // set_base64String(res.data);
            // setTimeout(() => {
            //     set_positionSignaturesWindowOpen(true);
            //     setLoadingDocument(false);
            // }, 2000);
        } else {
            set_base64String(originalBase64String);
            set_positionSignaturesWindowOpen(true);
        }
    };

    const signDocument = async () => {
        setLoadingSignatures(true);
        set_loadingESignatureProgress(0);
        setTimeout(() => {
            set_loadingESignatureProgress(1);
        }, 2000);
        try {
            // const parseDocument = JSON.parse(eSignatureEnvelope.stringifiedDocument);
            let rubricas = [];

            boxes.forEach(box => {
                let rubrica = {
                    ...box,
                    page: signDocumentViewSignaturePlatform === 'docuSign' ? box.page : box.page - 1,
                    relative_position_bottom: box.pageRelative_position_bottom,
                    relative_position_left: box.pageRelative_position_left,
                    relative_size_x: 19.55, 
                    relative_size_y: 9.42,
                    docuSignPositionY: box.pageDocuSignPositionY,
                    docuSignPositionX: box.pageDocuSignPositionX,
                    type: box.type === 'signature' ? 'signature' : 'visto',
                    signer_token: box.signer
                };
                rubricas.push(rubrica);
            });
            
            // if(eSignatureEnvelope?.action === 'UPDATE DOCUMENT'){
            //     path = '/data/documents/sign/document/update';
            // } else if(eSignatureEnvelope?.action === 'UPDATE TABS'){
            //     method = 'put';
            //     path = `/data/documents/${eSignatureEnvelope.selectedDocument.uid}/versions/${eSignatureEnvelope.updatedVersionId}/signatures/tabs`;
            // }
            
            const startDocumentSignatureRes = await signDocumentViewDocument.startDocumentSignature({
                activeUserEmail: activeUser.value.email,
                base64String,
                platform: signDocumentViewSignaturePlatform,
                projectName: signDocumentViewFolderName,
                shouldOrderSignatures: orderSignatures,
                signers,
                tabs: rubricas
            });
            if(startDocumentSignatureRes.error || !startDocumentSignatureRes.result){
                return;
            }

            set_loadingESignatureProgress(2);
            setTimeout(() => {
                handleClose();
                setLoadingSignatures(false);
                if(eSignatureEnvelope?.action === 'UPDATE TABS'){
                    toast('Posições de assinaturas alteradas.', { autoClose: 3000, type: 'success' });
                    return;
                }
    
                toast(`O documento foi enviado por e-mail para assinatura.`, { autoClose: 3000, type: 'success' });
            }, 1000);

        } catch (error) {
            setLoadingSignatures(false);
            let errorMessage = error?.message || ERROR_MESSAGE_UNKNOWN;
            toast(errorMessage, { type: 'error' });
        }
    };

    const handleBackButtonClick = () => {
        if(['UPDATE DOCUMENT', 'UPDATE TABS'].includes(eSignatureEnvelope?.action)){
            hideSignDocumentView();
        } else {
            set_positionSignaturesWindowOpen(false);
        }
    }

    const handleClose = () => {
        hideSignDocumentView();
    };

    const platforms = {
        docuSign: <Link href="https://www.docusign.com/" target="_blank" underline="hover">DocuSign</Link>,
        zapSign: <Link href="https://zapsign.com.br/" target="_blank" underline="hover">ZapSign</Link>
    };

    return (
        <Dialog
            open={signDocumentViewOpen} onClose={handleClose}
            disableEscapeKeyDown={loadingSignatures}
            fullScreen
        >
            <AppBar style={{position: 'relative'}}>
                <Toolbar>
                    {
                        (positionSignaturesWindowOpen && !loadingDocument)
                        ?
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleBackButtonClick}
                            aria-label="close"
                            size="large"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        :
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large"
                        >
                            <ClearIcon />
                        </IconButton>
                    }
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Assinatura eletrônica
                    </Typography>
                    {
                        !loadingDocument &&
                        <>
                            {
                                positionSignaturesWindowOpen
                                ?
                                <>
                                    {
                                        !loadingSignatures &&
                                        <Button
                                            color="inherit"
                                            endIcon={<FontAwesomeIcon icon={faFileSignature} />}
                                            onClick={signDocument}
                                        >
                                            Enviar
                                        </Button>
                                    }
                                </>
                                :
                                <Button
                                    disabled={!base64String}
                                    color="inherit"
                                    endIcon={loadingDocument ? <CircularProgress size={16} /> : <ArrowForwardIcon />}
                                    onClick={positionSignatures}
                                >
                                    Continuar
                                </Button>
                            }
                        </>
                    }
                </Toolbar>
            </AppBar>
            <DialogContent>

                {
                    loadingDocument
                    ? <LoaderEllipsis />
                    :
                    <Box>
                        <Box mb={2}>
                            <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                                <Grid>
                                    <Typography variant="body1">A assinatura será realizada via {platforms[signDocumentViewSignaturePlatform]}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="h2">{eSignatureEnvelope?.name || signDocumentViewDocumentName}</Typography>
                        </Box>
                        
                        {
                            !positionSignaturesWindowOpen
                            ?
                            <Box>
                                <Files availableFiles={availableFiles} setAvailableFiles={setAvailableFiles} files={files} setFiles={setFiles} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                                <Signers
                                    defaultSigner={defaultSigner}
                                    eSignaturePlatform={signDocumentViewSignaturePlatform}
                                    orderSignatures={orderSignatures} setOrderSignatures={set_orderSignatures}
                                    signers={signers} set_signers={set_signers}
                                />
                                <Collapse in={!!formError}>
                                    <Box mb={2}>
                                        <Grid container justifyContent="center">
                                            <Grid>
                                                <FormHelperText error>{formError}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Collapse>
                                <Box style={{textAlign: 'center'}}>
                                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                                        <Grid>
                                            <Button variant="text" onClick={handleClose}>Cancelar</Button>
                                        </Grid>
                                        <Grid>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!base64String}
                                                startIcon={loadingDocument ? <CircularProgress size={16} /> : <ArrowForwardIcon />}
                                                onClick={positionSignatures}
                                            >Continuar</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                            :
                            <PositionSignaturesWindow
                                base64String={base64String}
                                boxes={boxes} setBoxes={set_boxes}
                                handleBackButtonClick={handleBackButtonClick}
                                handleSignDocumentButtonClick={signDocument}
                                loadingESignatureProgress={loadingESignatureProgress}
                                positions={boxes} setPositions={set_boxes}
                                selectedFiles={selectedFiles}
                                signers={signers}
                            />
                        }

                    </Box>
                }
            
            </DialogContent>
        </Dialog>
    );
};

export default SignDocumentWindow;