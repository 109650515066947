import { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid2';
// import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
// import EditIcon from '@mui/icons-material/Edit';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// import * as markerjs2 from 'markerjs2';

const Img = styled('img')(unstable_styleFunctionSx);

const ClearanceTimeCodeScreenshot = (props) => {
    useSignals();
    const {
        // allowAnnotations,
        selectedClearanceReportNote, selectedReport, windowOpen
    } = props;
    // const [usingImageEditor, setUsingImageEditor] = useState(false);
    const [mouseIsOver, setMouseIsOver] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    // const [errorText, setErrorText] = useState('');
    const imgParentRef = useRef(null);
    const imgRef = useRef(null);
    const markerAreaState = useRef(null);

    const showScreenshot = () => {
        if(selectedClearanceReportNote.value){
            markerAreaState.current = selectedClearanceReportNote.value.screenShotMarkers || null;
            if(selectedClearanceReportNote.value.screenShotFileUrl){
                //TODO selectedClearanceReportNote.value.screenShotWithMarkers
                // screenShotFileUrl
                // screenShotWithMarkersFileUrl
                // screenShotMarkers
                return setImgSrc(selectedClearanceReportNote.value.screenShotFileUrl);
            }
        }
        setImgSrc('');
    };

    useEffect(() => {
        showScreenshot();
    }, [selectedClearanceReportNote.value, windowOpen.value]);

    const handleMouseOver = () => {
        setMouseIsOver(true);
    };

    const handleMouseOut = () => {
        setMouseIsOver(false);
    };

    // const saveMarkers = async (screenShotMarkers, screenShotWithMarkers) => {
    //     try {
    //         const project = selectedClearanceReportNote.value.project;
    //         const reportId = selectedClearanceReportNote.value.reportId;
    //         const bookmarkId = selectedClearanceReportNote.value.uid;
    //         const updates = {
    //             screenShotMarkers,
    //             screenShotWithMarkersBase64String: screenShotWithMarkers
    //         }
    //         selectedClearanceReportNote.value = updatedBookmark;
    //     } catch (error) {
    //         setErrorText(error);
    //     }
    // };

    // const showMarkerArea = (e) => {
    //     e.stopPropagation();
    //     setUsingImageEditor(true);
    //     if (imgRef.current !== null) {
    //         setErrorText('');
    //         const markerArea = new markerjs2.MarkerArea(imgRef.current);
    //         markerArea.targetRoot = imgParentRef.current;
    //         markerArea.renderImageType = 'image/webp';
    //         markerArea.renderAtNaturalSize = true;
    //         markerArea.renderImageQuality = 1;
    //         markerArea.addEventListener('render', event => {
    //             const screenShotMarkers = event.state;
    //             markerAreaState.current = screenShotMarkers;
    //             const screenShotWithMarkers = event.dataUrl;
    //             setImgSrc(screenShotWithMarkers);
    //             saveMarkers(screenShotMarkers, screenShotWithMarkers);
    //         });
    //         markerArea.addEventListener('beforeclose', event => {
    //             if(!window.confirm('Descartar alterações?')){
    //                 event.preventDefault();
    //             }
    //         });
    //         markerArea.show();
    //         if (markerAreaState.current) {
    //             setImgSrc(selectedClearanceReportNote.value.screenShot);
    //             markerArea.restoreState(markerAreaState.current);
    //         }
    //       }
    // };

    const handlePlayButtonClick = () => {
        // if(usingImageEditor) return;

        let playButtonUrl = '';
        if(selectedReport.videoPlatform === 'youTube'){
            playButtonUrl = `https://www.youtube.com/watch?v=${selectedReport.videoPlatformId}&t=${selectedClearanceReportNote.value.cutSeconds}s`;
        } else if(selectedReport.videoPlatform === 'vimeo'){
            playButtonUrl = `https://vimeo.com/${selectedReport.videoPlatformId}#t=${selectedClearanceReportNote.value.cutSeconds}s`;
        } else {
            playButtonUrl = selectedReport.videoPlatformUrl;
        }
        if(playButtonUrl){
            window.open(playButtonUrl, '_blank');
        }
    }

    if(
        selectedReport && selectedReport.videoPlatform && selectedReport.videoPlatformId
        && selectedClearanceReportNote.value && selectedClearanceReportNote.value.cutSeconds && selectedClearanceReportNote.value.cutSeconds !== -1){

        if(selectedClearanceReportNote.value.screenShotFileUrl || selectedClearanceReportNote.value.screenShotWithMarkersFileUrl){
            return (
                <Box mb={2}>
                    <Grid container justifyContent="center">
                        <Grid
                            sx={{
                                position: 'relative',
                                cursor: 'pointer',
                                opacity: 1,
                                transition: 'opacity .2s linear',
                                '&:hover': {
                                    // opacity: usingImageEditor ? 1 : .85,
                                },
                            }}
                            onClick={handlePlayButtonClick}
                            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                        >
                            <div ref={imgParentRef}>
                                {
                                    imgSrc &&
                                    <Img
                                        ref={imgRef}
                                        src={imgSrc}
                                        alt={selectedClearanceReportNote.value.description}
                                        style={{ maxWidth: '100%'}}
                                    />
                                }
                            </div>
                            {
                                (
                                    // !usingImageEditor &&
                                    selectedReport.videoPlatform && selectedReport.videoPlatformId && selectedClearanceReportNote.value.cutSeconds
                                ) &&
                                <PlayCircleIcon
                                    sx={{
                                        opacity: 0,
                                        transition: 'opacity .2s linear',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}
                                    style={{ opacity: mouseIsOver ? 1 : 0 }}
                                    fontSize="large" htmlColor="#f5f5f5" />
                            }
                            {
                                //TODO
                                // allowAnnotations &&
                                // <IconButton
                                //     sx={{
                                //         backgroundColor: 'background.paper',
                                //         cursor: 'pointer',
                                //         position: 'absolute',
                                //         bottom: '8px',
                                //         right: '8px',
                                //         opacity: 0.6,
                                //         transform: 'translate(8px)',
                                //         transition: 'opacity .2s linear',
                                //         '&:hover': {
                                //             opacity: 0.9,
                                //             backgroundColor: 'background.paper',
                                //         }
                                //     }}
                                //     onClick={showMarkerArea}
                                //     size="large" color="primary"
                                // >
                                //     <EditIcon />
                                // </IconButton>
                            }
                        </Grid>
                    </Grid>
                </Box>
            );
        }

        return (
            <Box mb={2}>
                <Grid container justifyContent="center">
                    <Grid>
                        <Button startIcon={<PlayCircleIcon />} onClick={handlePlayButtonClick}>{selectedClearanceReportNote.value.cutTimeCode}</Button>
                    </Grid>
                </Grid>
            </Box>
        );

    }

    return null;
};

export default ClearanceTimeCodeScreenshot;