import { useParams } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

// import AssessmentIcon from '@mui/icons-material/Assessment';
// import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
// import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
// import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import MovieIcon from '@mui/icons-material/Movie';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SaveIcon from '@mui/icons-material/Save';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { useAppCtxAPI } from '../../../context/SystemContext';
import { useFolderUserCtxAPI, useFolderUserCtxSidenavMobile } from '../../../pages/Client/context/ClientContext';

const useMenuItems = () => {
    useSignals();
    const { workspaceId } = useParams();
    const { activeUserIsGroupsManager, folderESignatureSettings, projectClearanceSettings, projectGroupSettings, selectedFolder } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();
    const { showChangeFolderGroupsView } = useAppCtxAPI();
    const { setSidenavMobileOpen } = useFolderUserCtxAPI();
    
    const sidenavMobileOpen = useFolderUserCtxSidenavMobile();

    const handleCloseMobileSidenav = () => {
        setSidenavMobileOpen(false);
    };

    const handleChangeGroupViewOpenButtonClick = () => {
        showChangeFolderGroupsView(selectedFolder.value);
    };

    const projectPath = selectedFolder.value ? `${selectedFolder.value.shortName || selectedFolder.value.uid}` : '';

    const iconColor = !sidenavMobileOpen ? '#b2bfdc' : undefined;

    const menuItems = [
        {
            label: 'Projetos',
            tooltip: 'Escolher projeto',
            path: `/${workspaceId}/pastas`,
            icon: <ViewListIcon htmlColor={iconColor} />,
            mobileIcon: <ViewListOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: true,
        },
        {
            label: 'Avisos',
            path: `/${workspaceId}/pastas/${projectPath}/avisos`,
            icon: <CampaignIcon htmlColor={iconColor} />,
            mobileIcon: <CampaignOutlinedIcon />,
            visible: !!selectedFolder.value,
        },
        {
            label: 'Documentos',
            tooltip: 'Ver todos os documentos',
            path: `/${workspaceId}/pastas/${projectPath}/documentos`,
            icon: <DescriptionIcon htmlColor={iconColor} />,
            mobileIcon: <DescriptionOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value,
        },
        {
            label: 'Formulários',
            tooltip: 'Preencher formulário',
            path: `/${workspaceId}/pastas/${projectPath}/formularios`,
            icon: <EditIcon htmlColor={iconColor} />,
            mobileIcon: <EditOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && !selectedFolderIsClient.value,
        },
        {
            label: 'Rascunhos',
            tooltip: 'Continuar a preencher formulário de onde parou',
            path: `/${workspaceId}/pastas/${projectPath}/formularios/salvos`,
            icon: <SaveIcon htmlColor={iconColor} />,
            mobileIcon: <SaveOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && !selectedFolderIsClient.value,
        },
        {
            label: 'Fichas',
            tooltip: 'Consultar fichas cadastrais',
            path: `/${workspaceId}/pastas/${projectPath}/fichas`,
            icon: <FileCopyIcon htmlColor={iconColor} />,
            mobileIcon: <FileCopyOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && !selectedFolderIsClient.value,
        },
        {
            label: 'Revisão',
            tooltip: 'Enviar um documento para revisão',
            path: `/${workspaceId}/pastas/${projectPath}/revisao`,
            icon: <AssignmentIcon htmlColor={iconColor} />,
            mobileIcon: <AssignmentOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && !selectedFolderIsClient.value,
        },
        {
            label: 'Assinaturas',
            tooltip: 'Ver documentos aguardando a minha assinatura',
            path: `/${workspaceId}/pastas/${projectPath}/assinar`,
            icon: <FontAwesomeIcon icon={faFileSignature} color={iconColor} />,
            mobileIcon: <AssignmentIndOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && (folderESignatureSettings.value?.enabled || selectedFolderIsClient.value),
        },
        {
            label: 'Alertas',
            tooltip: 'Ver todos os alertas',
            path: `/${workspaceId}/pastas/${projectPath}/documentos/alertas`,
            icon: <NotificationsIcon htmlColor={iconColor} />,
            mobileIcon: <NotificationsOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value,
        },
        {
            label: 'Clearance',
            path: `/${workspaceId}/pastas/${projectPath}/clearance`,
            highlight: [
                { path: `/${workspaceId}/pastas/${projectPath}/clearance` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/roteiro` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/relatorios` },
                { path: `/${workspaceId}/pastas/${projectPath}/clearance/pesquisa` },
            ],
            routes: [
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/?$`),
                    icon: <HelpOutlineIcon />, label: `Orientações`
                },
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance/consultas`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/consultas\/?$`),
                    icon: <QuestionAnswerOutlinedIcon />, label: `Consultas`
                },
                {
                    path: `/${workspaceId}/pastas/${projectPath}/clearance/relatorios`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/relatorios\/?$`),
                    icon: <MovieCreationOutlinedIcon />, label: `Relatórios`
                },
                // {
                //     path: `/${workspaceId}/pastas/${projectPath}/clearance/pesquisa`, regExp: new RegExp(`${workspaceId}\/pastas\/${projectPath}\/clearance\/pesquisa\/?$`),
                //     icon: <FindInPageOutlinedIcon />, label: `Pesquisa`
                // }
            ],
            icon: <MovieIcon htmlColor={iconColor} />,
            mobileIcon: <MovieCreationOutlinedIcon />,
            visible: !!selectedFolder.value && projectClearanceSettings.value
        },
        {
            label: 'Grupos',
            tooltip: 'Gerenciar grupos de permissões',
            icon: <GroupWorkIcon htmlColor={iconColor} />,
            mobileIcon: <GroupWorkOutlinedIcon />,
            onButtonClick: handleChangeGroupViewOpenButtonClick,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && (activeUserIsGroupsManager.value) && projectGroupSettings.value?.enabled,
        },
        // {
        //     label: 'Relatórios',
        //     tooltip: 'Gerar relatórios de documentos',
        //     path: `/${workspaceId}/pastas/${projectPath}/relatorios`,
        //     icon: <AssessmentIcon htmlColor={iconColor} />,
        //     mobileIcon: <AssessmentOutlinedIcon />,
        //     didNavigateAction: handleCloseMobileSidenav,
        //     visible: !!selectedFolder.value && (activeUserIsGroupsManager.value),
        // },
        {
            label: 'Lixeira',
            tooltip: 'Ver e restaurar documentos excluídos',
            path: `/${workspaceId}/pastas/${projectPath}/documentos/lixeira`,
            icon: <DeleteIcon htmlColor={iconColor} />,
            mobileIcon: <DeleteOutlineIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && (activeUserIsGroupsManager.value) && !selectedFolderIsClient.value,
        },
        {
            label: 'Criar pasta',
            tooltip: 'Criar pasta',
            path: `/${workspaceId}/pastas/${projectPath}/pastas`,
            icon: <CreateNewFolderIcon htmlColor={iconColor} />,
            mobileIcon: <CreateNewFolderOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: !!selectedFolder.value && selectedFolderIsClient.value,
        },
    ];

    return menuItems;
};

export default useMenuItems;