import { collection, doc, DocumentData, getCountFromServer, getDoc, getDocs, orderBy, query, runTransaction, where } from 'firebase/firestore';
import moment from 'moment';
import ShortUniqueId from 'short-unique-id';

import TimesheetRecord from '../classes/TimesheetRecord';
import { db } from '../firebase/config';
import callFunction from '../firebase/functions/callFunction';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';
import { Document as _Document, DocumentVersion } from '../shared/types';
import { getFileFormat, getKeywords, isGoogleDocUrlRegExTest } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const getDocumentName = async ({
    form,
    namePatternString = null,
    projectId,
    shortId,
    templateId,
}: {
    form: any;
    namePatternString?: string | null;
    projectId: string;
    shortId: string;
    templateId: string;
}) => {
    
    let namePattern;
    if(namePatternString === null){
        try {
            const documentTemplateRef = doc(db, `documents_templates/${templateId}/_more/title`)
            const documentTemplateSnapshot = await getDoc(documentTemplateRef);
            const documentTemplate = documentTemplateSnapshot.data();
            if(documentTemplate) namePattern = documentTemplate.title;
        } catch (error) {
            Promise.reject(error);
        }
    } else {
        namePattern = JSON.parse(namePatternString);
    }

    form = JSON.parse(form);

    let projectFields: { [key: string]: any } | null = null;

    let documentName = '';

    const titlePatternTypes: { [typeId: string]: (element: any) => Promise<string> } = {
        'text': async (element) => {
            return element.value;
        },
        'date': async (element) => {
            return moment().format(element.value);
        },
        'count': async () => {
            try {
                const snapshot = await getCountFromServer(query(collection(db, 'documents'), where('projectId', '==', projectId)))
                const data = snapshot.data();
                const count = data.count || 0;
                return (count + 1).toString();
            } catch (error) {
                return Promise.reject(error);
            }
        },
        'question': async (element) => {
            let titlePatternElement = '';
            try {
                if(Array.isArray(element.value)){
                    element.value = {
                        questionName: element.value[0]
                    };
                }
                if(element.value.questionName){
                    let userResponse = form[element.value.questionName];
                    if(userResponse.choice){
                        userResponse = ['&p', '&t;', '~t;'].includes(userResponse.choice) ? userResponse.input : userResponse.choice;
                    } else {
                        userResponse = userResponse.input || '';
                    }
                    titlePatternElement += userResponse ? userResponse.replace(/\n/g, '').substring(0, 149).trim() : '';
                }
            } catch (error) {
                
            }
            return titlePatternElement;
        },
        'ifQuestionResponseIs': async (element) => {
            const { conditions, outputType, output, elseOutputType, elseOutput } = element.value;
            if(Array.isArray(conditions)){
                let conditionsTest = false;
                for(const condition of conditions){
                    let userResponse = form[condition.questionName] || '';
                    if(userResponse) userResponse = userResponse.choice || userResponse.input || '';
                    if(userResponse.toUpperCase() === condition.response.toUpperCase()){
                        conditionsTest = true;
                        break;
                    }
                }
                if(conditionsTest){
                    if(outputType === 'text'){
                        return output;
                    } else if(outputType === 'response' && output){
                        const userResponse = form[output];
                        if(userResponse) return userResponse.choice || userResponse.input || '';
                    }
                } else {
                    if(elseOutputType === 'text'){
                        return elseOutput;
                    } else if(elseOutputType === 'response' && elseOutput){
                        const userResponse = form[elseOutput];
                        if(userResponse) return userResponse.choice || userResponse.input || '';
                    }
                }
            }
            return '';
        },
        'ifQuestionResponseIsNot': async (element) => {
            const { conditions, outputType, output, elseOutputType, elseOutput } = element.value;
            if(Array.isArray(conditions)){
                let conditionsTest = true;
                for(const condition of conditions){
                    let userResponse = form[condition.questionName] || '';
                    if(userResponse) userResponse = userResponse.choice || userResponse.input || '';
                    if(userResponse.toUpperCase() === condition.response.toUpperCase()){
                        conditionsTest = false;
                    }
                }
                if(conditionsTest){
                    if(outputType === 'text'){
                        return output;
                    } else if(outputType === 'response' && output){
                        const userResponse = form[output];
                        if(userResponse) return userResponse.choice || userResponse.input || '';
                    }
                } else {
                    if(elseOutputType === 'text'){
                        return elseOutput;
                    } else if(elseOutputType === 'response' && elseOutput){
                        const userResponse = form[elseOutput];
                        if(userResponse) return userResponse.choice || userResponse.input || '';
                    }
                }
            }
            return '';
        },
        'userTemplateField': async (element) => {
            if(!projectFields){
                const projectMiscRef = doc(db, `projects/${projectId}/_more/misc`);
                try {
                    const projectMiscSnapshot = await getDoc(projectMiscRef);
                    projectFields = projectMiscSnapshot.data() || null;
                } catch (error) {
                    return Promise.reject(error);
                }
            }
            if(projectFields && element.value){
                const foundUserTemplateField = projectFields[element.value];
                return foundUserTemplateField || '';
            }
            return '';
        },
        'shortId': async () => {
            return shortId || '';
        }
    };

    for(const element of namePattern){
        const elementType = element.type;
        try {
            documentName += await titlePatternTypes[elementType](element);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    return documentName;
};

const getEmailRecipients = (selectedDocument: Document, projectUsers: DocumentData, projectGroups: DocumentData | undefined) => {
    let recipients: string[] = [];
    let managementGroups = [1, 2, '1', '2'];
    for(const projectUserId in projectUsers){
        const projectUser = projectUsers[projectUserId];
        const projectUserEmailNotificationSettings = projectUser.emailNotificationSettings || {}
        if(
            !projectUserEmailNotificationSettings.documentDeliveredByOperatorDisabled
            &&
            (
                !projectUserEmailNotificationSettings.documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser
                ||
                (
                    projectUserEmailNotificationSettings.documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser
                    && projectUserId === selectedDocument.createdBy
                )
            )
        ){
            if(!projectGroups || !projectGroups.enabled){
                recipients.push(projectUser.email);
            } else {
                //now check groups
                for(const groupId in projectGroups.groups){
                    const currentGroup = projectGroups.groups[groupId];
                    if(currentGroup.users.includes(projectUserId)){
                        if(groupId !== '&none'){ // continue only if user not in "none" group
                            if(!selectedDocument.groupId){
                                //
                                recipients.push(projectUser.email);
                                //
                            } else if(managementGroups.includes(selectedDocument.groupId)){ //if management doc
                                if(managementGroups.includes(groupId)){ // if user in management
                                    if(groupId >= selectedDocument.groupId && !recipients.includes(projectUser.email)) recipients.push(projectUser.email);
                                }
                            } else { //if other groups
                                if(managementGroups.includes(groupId) && !recipients.includes(projectUser.email)){ // if user in management
                                    recipients.push(projectUser.email);
                                } else if(groupId == selectedDocument.groupId && !recipients.includes(projectUser.email)) {
                                    recipients.push(projectUser.email);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // if(!folder.documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser){
        // users.forEach(user => {
        //     const userClients = user.clients;
        //     const userClientPermissions = userClients.find(permission => permission.id === clientId.toString());
        //     if(userClientPermissions && userClientPermissions.type === 1){
        //         const userClientFolders = userClientPermissions.folders || [];
                   
        //     }
        // });
    // } else {
    //     let requestedBy = users.find(u => u.uid == doc.user);
    //     if(requestedBy) recipients.push(requestedBy.email);
    // }
    return recipients;
};

const getFileFormatFromUrl = (fileUrl: string) => {
    let fileFormat = '';
    if(/docs\.google\.com\/document\/d\/[-\w]+?\//.test(fileUrl)){
        fileFormat = 'googleDoc';
    } else {
        const fileUrlWithoutQuery = fileUrl.replace(/\?.+/, '');
        const fileExtension = fileUrlWithoutQuery.split('.').pop();
        fileFormat = fileExtension || '';
    }
    return fileFormat;
}

type DocumentFile = {
    firebaseStoragePath: string;
    format: string;
    name: string;
    uploadedAt: string;
    uploadedBy: string;
    url: string;
}
const uploadFiles = async (folderPath: string, filesList: any, activeUserId: string) => {
    let newFiles: {
        [newFileId: string]: DocumentFile
    } = {};
    let newFilesArray: (DocumentFile & { uid: string })[] = [];
    for(const file of filesList){
        const { contentType, fileBuffer } = await getFileContent(file);
        const { result } = await uploadFile(
            `${folderPath}/${file.name}`,
            fileBuffer,
            contentType
        );
        if(result){
            const { downloadUrl, newFileFormat, storageFilePath } = result;
            // @ts-ignore
            const fileId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
            let newFile = {
                firebaseStoragePath: storageFilePath,
                format: newFileFormat,
                name: file.name,
                uploadedAt: new Date().toISOString(),
                uploadedBy: activeUserId,
                url: downloadUrl
            };
            newFiles[fileId] = newFile;
            newFilesArray.push({...newFile, uid: fileId});
        }
    }
    return { newFiles, newFilesArray };
};

export default class Document implements _Document {
    availableToClient = false;
    awaitingOperatorReview = false;
    bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText = false;
    clientId = '';
    createdAt = '';
    createdBy = '';
    customFields: _Document['customFields'] = {};
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    files: _Document['files'] = {};
    flag: _Document['flag'] = {
        comment: '',
        flaggedAt: '',
        flaggedBy: '',
        type: ''
    };
    form: _Document['form'] = '{}';
    groupId = '';
    keywords: _Document['keywords'] = [];
    lastComment: _Document['lastComment'] = null;
    lastVersion: _Document['lastVersion'] = null;
    madeAvailableToClientAt = '';
    madeAvailableToClientBy = '';
    merged = false;
    name = '';
    operatorId = '';
    overrideBypassOperatorReviewReasons = null;
    projectId = '';
    shouldMakeAvailableToClientAt = '';
    shortId = '';
    talentForm: _Document['talentForm'] = null;
    templateId = '';
    uid = '';
    workspaceId = '';

    constructor({
        availableToClient,
        awaitingOperatorReview,
        bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText,
        clientId,
        createdAt,
        createdBy,
        customFields,
        deleted,
        deletedAt,
        deletedBy,
        files,
        flag,
        form,
        groupId,
        keywords,
        lastComment,
        lastVersion,
        madeAvailableToClientBy,
        merged,
        name,
        operatorId,
        overrideBypassOperatorReviewReasons,
        projectId,
        shouldMakeAvailableToClientAt,
        shortId,
        talentForm,
        templateId,
        uid,
        workspaceId
    }: Document | DocumentData){
        this.availableToClient = availableToClient || false;
        this.awaitingOperatorReview = awaitingOperatorReview || false;
        this.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText = !!bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText;
        this.clientId = clientId || '';
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.customFields = customFields || {};
        this.deleted = deleted || false;
        this.deletedAt = deletedAt || '';
        this.deletedBy = deletedBy || '';
        this.files = files || [];
        this.flag = flag || { comment: '', flaggedAt: '', flaggedBy: '', type: '' };
        if(form) this.form = form;
        if(groupId) this.groupId = groupId;
        if(keywords) this.keywords = keywords;
        if(lastComment) this.lastComment = lastComment;
        this.lastVersion = lastVersion || null;
        this.madeAvailableToClientBy = madeAvailableToClientBy || '';
        this.merged = merged || false;
        this.name = name || '';
        this.operatorId = operatorId || '';
        if(overrideBypassOperatorReviewReasons) this.overrideBypassOperatorReviewReasons = overrideBypassOperatorReviewReasons;
        this.projectId = projectId || '';
        this.shouldMakeAvailableToClientAt = shouldMakeAvailableToClientAt || '';
        // @ts-ignore
        this.shortId = shortId || new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
        if(talentForm) this.talentForm = talentForm;
        this.templateId = templateId || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async addVersion({ createdBy, fileUrl, filesList, name }: {
        createdBy: string;
        fileUrl: string;
        filesList: any[];
        name: string;
    }){
        let error = null, result = null;

        let fileFirebaseStoragePath = '', fileFormat = '';

        if(filesList){
            const { newFilesArray } = await uploadFiles(
                `workspaces/${this.workspaceId}/projects/${this.projectId}/documents`,
                filesList || [],
                createdBy
            );
            if(newFilesArray){
                const newFile = newFilesArray[0];
                fileFirebaseStoragePath = newFile.firebaseStoragePath;
                fileFormat = newFile.format;
                fileUrl = newFile.url;
            }
        } else if(fileUrl){
            fileFormat = getFileFormatFromUrl(fileUrl);
        }

        const newVersion = new DocumentVersion({
            createdAt: new Date().toISOString(),
            createdBy,
            documentId: this.uid,
            fileFirebaseStoragePath,
            fileFormat,
            fileUrl,
            name,
            workspaceId: this.workspaceId
        });

        const documentRef = doc(db, `documents/${this.uid}`);
        const documentVersionRef = doc(collection(db, `documents_versions`));
                    
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(documentRef, {
                    lastVersion: {...newVersion, uid: documentVersionRef.id}
                });
                transaction.set(documentVersionRef, {...newVersion});
            });
            result = true;
        } catch (e) {
            error = e;
        }            

        return { error, result };
    }

    async createDocument({
        activeUserIsOperator = false,
        awaitingOperatorReview,
        fileUrl,
        filesList,
        newVersionName = '',
        saveFormId,
    }: {
        awaitingOperatorReview?: boolean;
        saveFormId: string;
        fileUrl: string;
        filesList: any[];
        newVersionName: string;
        activeUserIsOperator: boolean;
    }){
        let error = null, result = null;

        const newDocumentRef = doc(collection(db, 'documents'));
        const documentVersionRef = doc(collection(db, `documents_versions`));

        let newVersion: DocumentVersion | null = null;
        if(this.templateId === 'review'){
            this.awaitingOperatorReview = awaitingOperatorReview || !!activeUserIsOperator;
            this.merged = false;
            let fileFirebaseStoragePath = '', fileFormat = '';
            if(fileUrl){
                fileFormat = getFileFormatFromUrl(fileUrl);
            } else if(filesList){
                const { newFiles, newFilesArray } = await uploadFiles(
                    `workspaces/${this.workspaceId}/projects/${this.projectId}/documents/id/${newDocumentRef.id}/files`,
                    filesList,
                    this.createdBy
                );
                if(newFiles){
                    this.files = newFiles;
                    const firstFile = newFilesArray[0];
                    fileFirebaseStoragePath = firstFile.firebaseStoragePath;
                    fileFormat = firstFile.format;
                    fileUrl = firstFile.url;
                }
            }
            newVersion = new DocumentVersion({
                createdAt: new Date().toISOString(),
                createdBy: this.createdBy,
                documentId: newDocumentRef.id,
                fileFirebaseStoragePath,
                fileFormat,
                fileUrl,
                name: newVersionName || 'Arquivo enviado para revisão',
                workspaceId: this.workspaceId
            });
            this.lastVersion = {...newVersion, uid: documentVersionRef.id};
            
        } else {

            const documentName = await getDocumentName({
                form: this.form,
                projectId: this.projectId,
                shortId: this.shortId,
                templateId: this.templateId
            });
            
            this.name = documentName;

        }

        this.keywords = getKeywords(this.name);

        const {uid: _, ...newDocument} = this;

        try {
            await runTransaction(db, async (transaction) => {
                if(saveFormId){
                    const saveFormRef = doc(db, `projects/${this.projectId}/documents_forms_drafts/${saveFormId}`);
                    const saveFormSnapshot = await transaction.get(saveFormRef);
                    if(saveFormSnapshot.exists()){
                        transaction.delete(saveFormRef);
                    }
                }
                transaction.set(newDocumentRef, newDocument);
                transaction.set(documentVersionRef, {...newVersion});
            });
            result = newDocumentRef.id;
        } catch (e) {
            error = e;
        }

        return { error, result };
    }

    async createVersionFromForm(){
        let error = null, result = null;
        const callFunctionRes = await callFunction('createDocumentVersionFromForm', {
            documentId: this.uid
        });
        if(callFunctionRes.error || !callFunctionRes.result){
            error = true;
        } else {
            result = callFunctionRes.result;
        }
        return { error, result };
    }

    async deleteDocumentESignature(versionId: string){
        let error = null, result = null;

        const callFunctionRes = await callFunction('deleteDocumentESignature', {
            documentId: this.uid,
            versionId
        });
        if(callFunctionRes.error || !callFunctionRes.result){
            error = true;
        } else {
            result = callFunctionRes.result;
        }

        return { error, result };
    }

    async deleteVersion(currentLastVersion: DocumentData | null, versionId: string, shouldDeleteVersionESignature: boolean){
        let error = null, result = null;

        let updatedLastVersion: DocumentData | null | boolean = false;
        
        if(shouldDeleteVersionESignature){
            const deleteDocumentESignatureRes = await this.deleteDocumentESignature(versionId);
            if(deleteDocumentESignatureRes.error){
                error = deleteDocumentESignatureRes.error;
            }
        }

        if(!error){
            if(currentLastVersion?.uid === versionId){
                
                const snapshots = await getDocs(query(collection(db, `documents_versions`), where('documentId', '==', this.uid), orderBy('createdAt', 'asc')));
                const remainingSnapshots = snapshots.docs.filter(snapshot => snapshot.id !== versionId);
                const lastSnapshot = remainingSnapshots[remainingSnapshots.length - 1];
                if(lastSnapshot){
                    updatedLastVersion = {...lastSnapshot.data(), uid: lastSnapshot.id};
                } else {
                    updatedLastVersion = null;
                }
    
            }
    
            const documentVersionRef = doc(db, `documents_versions/${versionId}`);
    
            try {
                await runTransaction(db, async (transaction) => {
                    if(updatedLastVersion !== false){
                        transaction.update(doc(db, `documents/${this.uid}`), { lastVersion: updatedLastVersion });
                    }
                    transaction.delete(documentVersionRef);
                });
                result = true;
            } catch (e) {
                error = e;
            }

        }

        return { error, result };
    }

    async flagDocument({ activeUser, comment, projects, type, users }: {
        activeUser: any;
        comment: string;
        projects: { [projectId: string]: any };
        type: '' | 'operator' | 'client'; // what type of user flagged the document? 
        users: { [projectId: string]: any };
    }){
        let result = null, error = null;

        const foundProject = projects[this.projectId];
        if(foundProject){

            const now = new Date();
            const nowISOString = now.toISOString();

            const documentUpdates: { [key: string]: any } = {}

            let autoComment = '';
            if(type){
                documentUpdates.flag = {
                    comment,
                    flaggedAt: nowISOString,
                    flaggedBy: activeUser.uid,
                    type
                }
                const foundUser = users[activeUser.uid];
                if(foundUser) autoComment = `${foundUser.name} sinalizou o documento`;
            } else {
                documentUpdates.flag = {
                    comment: '',
                    flaggedAt: '',
                    flaggedBy: '',
                    type: ''
                }
            }

            if(type === 'client'){
                documentUpdates.awaitingOperatorReview = true;
            } else if(type === ''){
                if(this.flag.type === 'client'){
                    documentUpdates.awaitingOperatorReview = false;
                }
            }

            const newCommentRef = doc(collection(db, `documents/${this.uid}/comments`));

            let newComment: {
                comment: string; createdAt: string; createdBy: string; readBy: { [userId: string]: string }; uid?: string;
            };
            if(comment || autoComment){
                newComment = {
                    comment: `${autoComment || ''}${comment ? ` e comentou "${comment}"` : ''}`,
                    createdAt: nowISOString,
                    createdBy: activeUser.uid,
                    readBy: { [activeUser.uid]: nowISOString },
                };
                if(comment){
                    documentUpdates.lastComment = {...newComment, uid: newCommentRef.id};
                }
            }
            
            try {
                await runTransaction(db, async (transaction) => {
                    const projectUsersSnapshot = await transaction.get(doc(db, `projects/${this.projectId}/_more/users`));
                    const projectGroupsSnapshot = await transaction.get(doc(db, `projects/${this.projectId}/_more/groups`));
                    transaction.update(doc(db, `documents/${this.uid}`), documentUpdates);
                    if(newComment) transaction.set(newCommentRef, newComment);
                    if(type){
                        const projectUsersData = projectUsersSnapshot.data();
                        if(projectUsersData){
                            const projectName = foundProject.name;
                            const projectGroupsData = projectGroupsSnapshot.data();
                            const recipients = getEmailRecipients(this, projectUsersData, projectGroupsData);
                            let groupName = '';
                            if(this.groupId && projectGroupsData?.enabled){
                                const availableGroups = projectGroupsData.groups;
                                const documentGroup = availableGroups[this.groupId];
                                if(documentGroup) groupName = documentGroup.name;
                            }
                            let title = `Documento sinalizado${type === 'operator' ? ' pelo Jurídico' : ''}`;
                            transaction.set(doc(collection(db, 'emails')), {
                                createdAt: new Date().toISOString(),
                                to: recipients.map(emailRecipient => ({ email: emailRecipient })),
                                reply_to: {
                                    email: activeUser.email
                                },
                                subject: `🔴 ${projectName} - ${title} - ${this.name}`,
                                template_id: '0r83ql32pym4zw1j',
                                variables: recipients.map(emailRecipient => ({
                                    email: emailRecipient,
                                    substitutions: [
                                        { var: 'preheader', value: `O documento \"${this.name}\" foi sinalizado.` },
                                        { var: 'title', value: title },
                                        { var: 'actionDescription', value: `O seguinte documento foi sinalizado por ${activeUser.name}:` },
                                        { var: 'contentRow2', value: `Projeto <strong>${projectName}${groupName ? ` (grupo \"${groupName}\")` : ''}</strong>` },
                                        { var: 'contentRow3', value: `Documento <strong><a href="${this.lastVersion?.fileUrl}">${this.name}</a></strong>` },
                                        { var: 'comment', value: comment ? `"<em>${comment.replace(/\</g, '&lt;').replace(/\>/g, '&gt;').replace(/\n/g, '<br />')}</em>" (<strong>${activeUser.name}</strong>)` : '' },
                                        { var: 'bottomRow', value: '' },
                                    ]
                                }))
                            });
                        }
                    }
                });
                result = true;
            } catch (e) {
                error = e;
            }
        }

        return { result, error };
    }

    async getDocumentVersionFileBase64String(){
        let error = null, result = null;

        const getDocumentPdfBase64StringRes = await callFunction('getDocumentPdfBase64String', {
            documentId: this.uid,
            selectedDocumentVersions: [{...this.lastVersion}]
        });
        if(getDocumentPdfBase64StringRes.error || !getDocumentPdfBase64StringRes.result){
            error = getDocumentPdfBase64StringRes.error;
        } else {
            result = getDocumentPdfBase64StringRes.result;
        }

        return { error, result };
    }

    async getForm(){
        let result = null, error = null;
        
        const templateFormRef = doc(db, `documents_templates/${this.uid}/_more/form`);
        
        try {
            const snapshot = await getDoc(templateFormRef);
            const data = snapshot.data();
            result = data;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getUsers(){
        console.log('Procurando usuários...');
        try {
            const projectUsersSnapshot = await getDoc(doc(db, `projects/${this.projectId}/_more/users`));
            const projectGroupsSnapshot = await getDoc(doc(db, `projects/${this.projectId}/_more/groups`));
            const projectUsersData = projectUsersSnapshot.data();
            if(projectUsersData){
                const projectGroupsData = projectGroupsSnapshot.data();
                const recipients = getEmailRecipients(this, projectUsersData, projectGroupsData);
                console.log('recipients', recipients);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    async makeAvailableToClient({
        activeUser,
        comment,
        documentName,
        minutesSpentInOperatorReview,
        selectedWorkspace,
        staticFileReviewFilesProvidedByUser,
        staticFileReviewUrlProvidedByUser
    }: {
        activeUser: any;
        comment: string;
        documentName: string;
        minutesSpentInOperatorReview: number;
        selectedWorkspace: any;
        staticFileReviewFilesProvidedByUser: FileList;
        staticFileReviewUrlProvidedByUser: string;
    }){
        let result = null, error = null;

        const now = new Date();
        const nowISOString = now.toISOString();

        let fullComment = `${activeUser.name} entregou o documento`;

        let lastVersion: DocumentVersion | null = null;
        
        const updates: Pick<_Document, 'availableToClient' | 'awaitingOperatorReview' | 'flag' | 'keywords' | 'madeAvailableToClientAt' | 'madeAvailableToClientBy' | 'name' | 'shouldMakeAvailableToClientAt'> & { lastVersion?: _Document['lastVersion'] } = {
            availableToClient: true,
            awaitingOperatorReview: false,
            flag: {
                comment: '',
                flaggedAt: '',
                flaggedBy: '',
                type: ''
            },
            keywords: getKeywords(documentName),
            madeAvailableToClientAt: nowISOString,
            madeAvailableToClientBy: activeUser.uid,
            name: documentName,
            // scheduledSent,
            shouldMakeAvailableToClientAt: ''
        };
                
        if(this.flag.type !== ''){
            updates.flag.flaggedAt = nowISOString;
            updates.flag.flaggedBy = activeUser.uid;
            
            if(this.flag.type === 'client'){
                fullComment = `${activeUser.name} devolveu o documento sinalizado`;
            }
        }
                
        if(comment) fullComment += ` e comentou: "${comment}"`

        const newComment = {
            comment: fullComment,
            createdAt: nowISOString,
            createdBy: activeUser.uid,
            readBy: true,
        };

        let lastVersionFileUrl = this.lastVersion?.fileUrl || '';
        let lastVersionFileFormat = this.lastVersion?.fileFormat || '';

        let newDocumentVersion: DocumentVersion | null = null;
        if(this.templateId === 'review' && (staticFileReviewUrlProvidedByUser || staticFileReviewFilesProvidedByUser)){
            newDocumentVersion = new DocumentVersion({
                createdBy: activeUser.uid,
                documentId: this.uid,
                name: 'Revisão',
                workspaceId: this.workspaceId,
            });
            if(staticFileReviewUrlProvidedByUser){
                let fileFormat = '';
                if(isGoogleDocUrlRegExTest.test(staticFileReviewUrlProvidedByUser)){
                    fileFormat = 'googleDoc';
                } else {
                    fileFormat = getFileFormat(staticFileReviewUrlProvidedByUser);
                }
                newDocumentVersion.fileFormat = fileFormat;
                newDocumentVersion.fileUrl = staticFileReviewUrlProvidedByUser;
                lastVersionFileUrl = staticFileReviewUrlProvidedByUser;
                lastVersionFileFormat = fileFormat;
                
            } else if(staticFileReviewFilesProvidedByUser && staticFileReviewFilesProvidedByUser.length !== 0){
                const { contentType, fileBuffer } = await getFileContent(staticFileReviewFilesProvidedByUser[0]);
                const { result } = await uploadFile(
                    `workspaces/${this.workspaceId}/projects/${this.projectId}/documents/id/${this.uid}/files/${staticFileReviewFilesProvidedByUser[0].name}`, // storageFilePath
                    fileBuffer,
                    contentType
                );
                if(result){
                    const { downloadUrl, newFileFormat, storageFilePath } = result;
                    newDocumentVersion.fileFirebaseStoragePath = storageFilePath;
                    newDocumentVersion.fileFormat = newFileFormat;
                    newDocumentVersion.fileUrl = downloadUrl;
                    lastVersionFileUrl = downloadUrl;
                    lastVersionFileFormat = newFileFormat;
                }
            }
            lastVersion = newDocumentVersion;
        }

        const newDocumentVersionRef = doc(collection(db, `documents_versions`));

        if(lastVersion) updates.lastVersion = {...lastVersion, uid: newDocumentVersionRef.id};

        let newTimesheetRecord: TimesheetRecord | null = null;
        if(this.templateId !== 'review' && selectedWorkspace?.modules?.includes('timesheet')){ // not review
            minutesSpentInOperatorReview = minutesSpentInOperatorReview || 15;
            const startedAtISOString = moment().subtract(minutesSpentInOperatorReview, 'minutes').toISOString();
            newTimesheetRecord = new TimesheetRecord({
                createdAt: nowISOString,
                createdBy: activeUser.uid,
                description: `${this.name}${this.flag.type === 'client' ? ' - Alterações em documento sinalizado pelo solicitante' : ''}`,
                minutes: minutesSpentInOperatorReview,
                minutesConsumedWithDocumentsSetWhenMakingDocumentAvailableToClient: true,
                projectId: this.projectId,
                startAt: startedAtISOString,
                workspaceId: this.workspaceId
            });
        }

        try {
            await runTransaction(db, async (transaction) => {
                const projectSnapshot = await transaction.get(doc(db, `projects/${this.projectId}`));
                const projectUsersSnapshot = await transaction.get(doc(db, `projects/${this.projectId}/_more/users`));
                const projectGroupsSnapshot = await transaction.get(doc(db, `projects/${this.projectId}/_more/groups`));
                transaction.update(doc(db, `documents/${this.uid}`), updates);
                transaction.set(doc(collection(db, `documents/${this.uid}/comments`)), newComment);
                if(newDocumentVersion) transaction.set(newDocumentVersionRef, {...newDocumentVersion});
                if(newTimesheetRecord) transaction.set(doc(collection(db, `timesheet_records`)), {...newTimesheetRecord});
                const projectData = projectSnapshot.data();
                if(projectData){
                    const projectUsersData = projectUsersSnapshot.data();
                    if(projectUsersData){
                        const projectName = projectData.name;
                        const projectGroupsData = projectGroupsSnapshot.data();
                        const recipients = getEmailRecipients(this, projectUsersData, projectGroupsData);
                        let groupName = '';
                        if(this.groupId && projectGroupsData?.enabled){
                            const availableGroups = projectGroupsData.groups;
                            const documentGroup = availableGroups[this.groupId];
                            if(documentGroup) groupName = documentGroup.name;
                        }
                        transaction.set(doc(collection(db, 'emails')), {
                            createdAt: new Date().toISOString(),
                            to: recipients.map(emailRecipient => ({ email: emailRecipient })),
                            reply_to: {
                                email: activeUser.email
                            },
                            subject: `${projectName} - Contrato entregue - ${this.name}`,
                            template_id: '0r83ql32pym4zw1j',
                            variables: recipients.map(emailRecipient => ({
                                email: emailRecipient,
                                substitutions: [
                                    { var: 'preheader', value: `O contrato \"${this.name}\" está pronto` },
                                    { var: 'title', value: 'Contrato entregue' },
                                    { var: 'actionDescription', value: `${activeUser.name} ${this.flag.type === 'client' ? 'devolveu o seguinte documento sinalizado' : 'entregou o seguinte documento'}:` },
                                    { var: 'contentRow2', value: `Projeto <strong>${projectName}${groupName ? ` (grupo \"${groupName}\")` : ''}</strong>` },
                                    { var: 'contentRow3', value: `Documento <strong><a href="${lastVersionFileUrl}">${this.name}</a></strong>` },
                                    { var: 'comment', value: comment ? `"<em>${comment.replace(/\n/g, '<br/>')}</em>" (<strong>${activeUser.name}</strong>)` : '' },
                                    { var: 'bottomRow', value: lastVersionFileUrl && lastVersionFileFormat === 'googleDoc' ? 'O Jurídico não recebe notificações automáticas quando você responde um comentário no Google Doc. Caso tenha considerações, faça comentários no Google Doc e, em seguida, clique no botão SINALIZAR do SOLIDA. Não é necessário avisar o Jurídico por outros meios.' : '' },
                                ]
                            }))
                        });
                    }
                }
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async startDocumentSignature({ activeUserEmail, base64String, platform, projectName, shouldOrderSignatures, signers, tabs }: {
        activeUserEmail: string;
        base64String: string;
        platform: string;
        projectName: string;
        shouldOrderSignatures: boolean;
        signers: any;
        tabs: any;
    }){
        let error = null, result = null;

        const res = await callFunction('startDocumentElectronicSignature', {
            base64String,
            documentId: this.uid,
            documentName: this.name,
            eSignaturePlatform: platform,
            projectName,
            senderEmail: activeUserEmail,
            shouldOrderSignatures,
            signers,
            tabs,
        });
        if(res.error){
            error = res.error;
        } else if(!res.result){
            error = ERROR_MESSAGE_UNKNOWN;
        } else {
            result = res.result;
        }

        return { error, result };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;
        
        const documentRef = doc(db, `documents/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(documentRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateDocumentESignatureStatus({
        documentId,
        documentVersionId,
        eSignaturePlatform,
        eSignaturePlatformDocumentId
    }: {
        documentId: string,
        documentVersionId: string,
        eSignaturePlatform: string,
        eSignaturePlatformDocumentId: string
    }){
        return await callFunction('updateDocumentESignatureStatus', {
            documentId,
            documentVersionId,
            eSignaturePlatform,
            eSignaturePlatformDocumentId
        });
    }
    
}