import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '../../../../../../../../components/Tooltip';
import { useAppStateCtx } from '../../../../../../../../context/AppState';

const PendingDocumentListItemOperator = ({ loading, onClick, operatorId }) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    const operator = workspaceUsers.value[operatorId];
    const operatorName = operator?.name || '';
    return (
        <IconButton disabled={loading} size="small" color="primary" onClick={onClick}>
            <Tooltip text={operatorName}>
                <Avatar style={{height: 30, width: 30}} alt={operatorName} src={operator.photoUrl} />
            </Tooltip>
        </IconButton>
    );
};

export default PendingDocumentListItemOperator;