import { useSignals } from '@preact/signals-react/runtime';

import Typography from '@mui/material/Typography';
import { useAppStateCtx } from '../../../../../../../../../../context/AppState';

const PendingDocumentListItemTopCreatorName = ({ userId }) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    return (
        <Typography variant="body2" noWrap>
            {workspaceUsers.value[userId]?.name || ''}
        </Typography>
    );
};

export default PendingDocumentListItemTopCreatorName;