import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { faCircle, faEnvelope, faFilePdf, faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import useTheme from '@mui/material/styles/useTheme';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';

import ChangeDocumentWindow from './ChangeDocumentWindow';
import ChangeSignerWindow from './ChangeSignerWindow';

import AlertDialog from '../AlertDialog';
import Tooltip from '../Tooltip';
import UpdateSignatureStatusButton from '../UpdateSignatureStatusButton';

import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI, useAppCtxLoading } from '../../context/SystemContext';
import callFunction from '../../firebase/functions/callFunction';
import { copy } from '../../utils/common';

const classes = {
    box: {
        backgroundColor: 'rgba(255, 255, 255, 0.25)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        maxWidth: '100%',
        position: 'relative',
    },
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    signedButton: {
        // backgroundColor: theme.palette.background.paper,
        color: 'green',
        '&:hover': {
            cursor: 'default',
            // background: theme.palette.background.paper
        }
    }
};

const StyledAccordion = (props) => <Accordion sx={{
    root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}} {...props} />;

const SignDocumentButton = ({ disabled, loading, loadingSignature, onClick }) => {
    return (
        <Grid container justifyContent="center">
            <Grid>
                <Button
                    startIcon={loadingSignature ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faFileSignature} />}
                    variant="outlined" size="small" color="primary"
                    disabled={loadingSignature || disabled || loading}
                    onClick={onClick}
                >Enviar para assinatura</Button>
            </Grid>
        </Grid>
    );
};

const SignatureStatus = (props) => {
    const {
        activeUserIsManager,
        activeUserIsOperator,
        disabled,
        documentType,
        documentVersion,
        hideSignButton,
        loadingSignature,
        onSignDocumentClick,
        project,
        projectName,
        selectedDocument,
        setSelectedDocument
    } = props;
    useSignals();
    const theme = useTheme();
    const { activeUser, eSignatureStatusExpanded } = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const [changeDocumentWindowOpen, setChangeDocumentWindowOpen] = useState(false);
    const [changeSignerWindowOpen, set_changeSignerWindowOpen] = useState(false);
    const [selectedSigner, set_selectedSigner] = useState(null);
    const [numberOfSigners, setNumberOfSigners] = useState(0);
    const [numberOfSignersWhoSigned, setNumberOfSignersWhoSigned] = useState(0);
    const [signersVisible, setSignersVisible] = useState(!documentVersion?.eSignature?.status === 'signed' ? eSignatureStatusExpanded.value || false : false);
    const [deleteSignaturesDialogOpen, setDeleteSignaturesDialogOpen] = useState(false);
    const selectedDocumentRef = useRef(null);

    useEffect(() => {
        let currentNumberOfSigners = 0;
        let currentNumberOfSignersWhoSigned = 0;
        if(documentVersion?.eSignature?.enabled){
            Object.entries(documentVersion.eSignature.signers).forEach(([eSignatureSignerId, eSignatureSigner]) => {
                currentNumberOfSigners++;
                eSignatureSigner.uid = eSignatureSignerId;
                if(eSignatureSigner.status === 'signed'){
                    currentNumberOfSignersWhoSigned++;
                }
            });
        }
        setNumberOfSigners(currentNumberOfSigners);
        setNumberOfSignersWhoSigned(currentNumberOfSignersWhoSigned);
    }, [documentVersion]);

    const handleCopyLink = (signerId) => {
        const signer = documentVersion.eSignature.signers[signerId];
        const signerLink = `https://app.zapsign.com.br/verificar/${signerId}`;
        
        if(signer.email === activeUser.value.email){
            window.open(signerLink, '_blank').focus();
        } else {
            copy(signerLink, toast);
        }
    };

    const handleDeleteSignaturePlatformDocument = () => {
        setDeleteSignaturesDialogOpen(true);
    };
    const handleDeleteSignaturePlatformDocumentConfirm = async () => {
        setDeleteSignaturesDialogOpen(false);
        setLoading(true);
        await selectedDocument.deleteDocumentESignature(documentVersion.uid);
        setLoading(false);
    };

    const handleChangeSignerWindowOpenClick = (signerId, signer) => {
        selectedDocumentRef.current = selectedDocument;
        // setSelectedDocument(selectedDocument);
        set_selectedSigner(signer);
        set_changeSignerWindowOpen(true);
    };

    const handleReSendSignDocumentEmail = async (signer) => {
        setLoading(true);
        const toastId = toast.loading('Enviando...');
        const res = await callFunction('resendSignDocumentEmail', {
            documentName: documentVersion.name,
            projectName: projectName || project?.name,
            senderEmail: activeUser.value?.email,
            signerEmail: signer.email,
            signerName: signer.name,
            signerUrl: documentVersion.eSignature.platform.eSignaturePlatform !== 'docuSign' ? `https://app.zapsign.com.br/verificar/${signer.uid}` : ''
        })
        setLoading(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: 'Não foi possível enviar o e-mail. Tente novamente mais tarde.', type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: `O e-mail com o link para assinatura foi disparado para ${signer.email}.`, type: 'success' });
    };
    
    return <>
        {
            documentVersion
            ?
            <Box>
                {
                    documentVersion?.eSignature?.enabled
                    ?
                        (
                            <Box sx={classes.box}>
                                {
                                    documentVersion.eSignature.status === 'signed'
                                    ?
                                    <StyledAccordion expanded={false}>
                                        <AccordionSummary>
                                            <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                                <Grid style={{height: 20, padding: 0}}>
                                                    <CheckCircleIcon fontSize="small" htmlColor="green" />
                                                </Grid>
                                                <Grid>
                                                    <Typography variant="h6" noWrap>Assinado</Typography>
                                                </Grid>
                                                {
                                                    documentVersion.googleDriveBackup?.fileUrl &&
                                                    <>
                                                        <Grid>
                                                            <Tooltip text="Acessar local do arquivo no Google Drive">
                                                                <IconButton size="small" href={`https://drive.google.com/drive/folders/${documentVersion.googleDriveBackup.folderId}`} target="_blank">
                                                                    <FontAwesomeIcon icon={faGoogleDrive} size="1x" color={theme.palette.primary.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid>
                                                            <Tooltip text="Acessar arquivo no Google Drive">
                                                                <IconButton size="small" href={documentVersion.googleDriveBackup.fileUrl} target="_blank">
                                                                    <FontAwesomeIcon icon={faFilePdf} size="1x" color={theme.palette.primary.main} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Grid>
                                                    </>
                                                }
                                            </Grid>
                                        </AccordionSummary>
                                    </StyledAccordion>
                                    :
                                    <StyledAccordion expanded={signersVisible} onChange={() => setSignersVisible(!signersVisible)}>
                                        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
                                            <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                                <Grid style={{height: 20, padding: 0}}>
                                                    <FontAwesomeIcon icon={faCircle} color="orange" />
                                                </Grid>
                                                <Grid>
                                                    <Typography variant="h6">{numberOfSignersWhoSigned}/{numberOfSigners} assinatura{numberOfSigners >= 2 ? 's' : ''}</Typography>
                                                </Grid>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box style={{width: '100%'}}>
                                                {
                                                    Object.entries(documentVersion.eSignature.signers).map(([signerId, signer]) => (
                                                        <Box key={signerId} style={{width: '100%'}}>
                                                            <Grid container spacing={1} alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                                                <Grid style={{width: 22}}>
                                                                    {
                                                                        signer.type !== 'observer'
                                                                        ?
                                                                        <FontAwesomeIcon icon={faCircle} color={signer.status === 'signed' ? 'green' : 'orange'} />
                                                                        :
                                                                        <VisibilityIcon fontSize="small" />
                                                                    }
                                                                </Grid>
                                                                <Grid style={{width: `calc(100% - 22px${signer.status !== 'signed' ? ' - 30px' : ''}${activeUserIsManager && signer.status !== 'signed' ? ' - 30px' : ''})`}}>
                                                                    {
                                                                        (signer.status !== 'signed' && documentVersion.eSignature.platform !== 'docuSign')
                                                                        ?
                                                                        <Box>
                                                                            <Tooltip text={signer.email === activeUser.value?.email ? 'Assinar' : `Copiar link para assinatura para ${signer.name} (${signer.email})`}>
                                                                                <Typography variant="body2" sx={classes.link} onClick={() => handleCopyLink(signerId)} noWrap>{signer.name}</Typography>
                                                                            </Tooltip>
                                                                            <Typography variant="body2" sx={signer.name ? { fontSize: 10 } : classes.link} onClick={() => handleCopyLink(signerId)} noWrap>{signer.email}</Typography>
                                                                        </Box>
                                                                        :
                                                                        <Tooltip text={`${signer.name} (${signer.email})`}>
                                                                            <Typography variant="body2" noWrap>{signer.name || signer.email}</Typography>
                                                                        </Tooltip>
                                                                    }
                                                                </Grid>
                                                                {
                                                                    (signer.type !== 'observer' && signer.status !== 'signed') &&
                                                                    <>
                                                                        <Grid style={{width: 30}}>
                                                                            <Tooltip text="Reenviar e-mail">
                                                                                <Box>
                                                                                    <IconButton
                                                                                        disabled={disabled || loading}
                                                                                        size="small" onClick={() => handleReSendSignDocumentEmail(signer)}><FontAwesomeIcon icon={faEnvelope} /></IconButton>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        </Grid>
                                                                        {
                                                                            activeUserIsManager &&
                                                                            <Grid style={{width: 30}}>
                                                                                <Tooltip text="Editar pessoa">
                                                                                    <Box>
                                                                                        <IconButton
                                                                                            disabled={disabled || loading}
                                                                                            size="small" onClick={() => handleChangeSignerWindowOpenClick(signerId, signer)}><EditIcon /></IconButton>
                                                                                    </Box>
                                                                                </Tooltip>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Box>
                                                    ))
                                                }
                                                {
                                                    (documentVersion.eSignature.platformFileUrl || activeUserIsManager) &&
                                                    <Box mt={1}>
                                                        <Grid container justifyContent="center" alignItems="center">
                                                            {
                                                                (documentVersion.eSignature.platformFileUrl && documentVersion.eSignature.status !== 'new') &&
                                                                <Grid>
                                                                    <Tooltip text="Documento parcialmente assinado">
                                                                        <IconButton
                                                                            size="small" color="primary"
                                                                            href={documentVersion.eSignature.platformFileUrl} target="_blank"
                                                                        ><CloudDownloadIcon /></IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            }

                                                            <Grid size="grow"></Grid>
                                                            <Grid>
                                                                <UpdateSignatureStatusButton document={selectedDocument} version={documentVersion} />
                                                            </Grid>
                                                            {
                                                                activeUserIsManager &&
                                                                <Grid>
                                                                    <Tooltip text="Cancelar assinaturas">
                                                                        <IconButton
                                                                            disabled={disabled || loading}
                                                                            size="small" color="secondary"
                                                                            onClick={handleDeleteSignaturePlatformDocument}
                                                                        ><DeleteIcon /></IconButton>
                                                                    </Tooltip>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Box>
                                                }
                                            </Box>
                                        </AccordionDetails>
                                    </StyledAccordion>
                                }
                                <ChangeSignerWindow
                                    open={changeSignerWindowOpen} setOpen={set_changeSignerWindowOpen}
                                    documentVersion={documentVersion}
                                    selectedDocumentRef={selectedDocumentRef}
                                    selectedSigner={selectedSigner}
                                />
                                <ChangeDocumentWindow
                                    {...{
                                        activeUserIsOperator,
                                        changeDocumentWindowOpen, setChangeDocumentWindowOpen,
                                        selectedDocument, setSelectedDocument,
                                        selectedDocumentVersion: documentVersion
                                    }}
                                />
                            </Box>
                        )
                    :
                    <>
                        {
                            !hideSignButton &&
                            <>
                                {
                                    documentType === 'document'
                                    ? <SignDocumentButton disabled={disabled} loading={loading} loadingSignature={loadingSignature} onClick={onSignDocumentClick} />
                                    :
                                        (
                                            (documentVersion?.fileFormat === 'googleDoc' || documentVersion?.fileFormat === 'pdf')
                                            ? <SignDocumentButton disabled={disabled} loading={loading} loadingSignature={loadingSignature} onClick={onSignDocumentClick} />
                                            :
                                            <Tooltip text="Você só pode assinar um Google Doc ou um PDF.">
                                                <IconButton size="small" color="primary" disabled>
                                                    <FontAwesomeIcon icon={faFileSignature} />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                }
                            </>
                        }
                    </>
                }
            </Box>
            :
            null
        }
        <AlertDialog
            open={deleteSignaturesDialogOpen} onClose={() => setDeleteSignaturesDialogOpen(false)}
            text={`Você quer cancelar o processo de assinatura do documento "${selectedDocument ? selectedDocument.name || selectedDocument.description : ''}"? Essa ação é irreversível.`}
            okButtonOnClick={handleDeleteSignaturePlatformDocumentConfirm}
        />
    </>;
};

export default SignatureStatus;