import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, Container, Grid, IconButton, Link, Slide, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import AddOrSubtractDial from './AddOrSubtractDial';
import Dialog from './Dialog/Dialog';
import FilesField from './FilesField';
import FilesList from './FilesList';
import LoaderEllipsis from './LoaderEllipsis';
import Select from './Select';
import Switch from './Switch';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { useAppCtxAPI } from '../context/SystemContext';
import { useOperatorFoldersCtxAPI, useOperatorFoldersCtxFolderClearanceView } from '../context/OperatorFoldersContext';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ChangeProjectClearanceInformationWindow = () => { 
    useSignals();
    const { activeUser, folderUsers, projectClearanceSettings, projectGroupSettings, workspaceClearanceTemplates, workspaceUsers } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { APP_PATH } = useAppCtxAPI();
    const { hideFolderClearanceView } = useOperatorFoldersCtxAPI();
    const { folderClearanceViewOpen, folderClearanceViewSelectedFolder: selectedFolder } = useOperatorFoldersCtxFolderClearanceView();
    const [loading, setLoading] = useState(true);
    const defaultClearanceAuthority = { type: 'user', uid: '', maximumRiskLevel: 'four' };
    const defaultClearanceNumberOfApprovalsRequired = {
        one: 0,
        two: 0,
        three: 0,
        four: 0
    };
    const defaultFolderState = {
        availableAuthorities: {
            group: [],
            user: []
        },

        clearanceApprovalAuthorities: [],
        clearanceNumberOfApprovalsRequired: defaultClearanceNumberOfApprovalsRequired,
        clearanceRequireApproval: false,

        dashboardPassword: '',
        files: [],
        groupsBehavior: 'restrict',
        guidelinesUrl: '',
        reportsRestrictedToManagers: false,
        show2FieldsForClearanceNoteRecommendation: false,
        templateId: '',
        type: ''
    };
    const [folderState, setFolderState] = useState(defaultFolderState);
    const [clearanceFiles, setClearanceFiles] = useState([]);
    const [saving, setSaving] = useState(false);
    const workTypeOptions = [
        {value: 'film', label: 'Filme'},
        {value: 'series', label: 'Série'}
    ];

    useEffect(() => {
        if(folderClearanceViewOpen && selectedFolder && projectClearanceSettings.value?.projectId === selectedFolder.uid){
            setFirestoreListener('workspaceClearanceTemplates');
            setFolderState(prevState => ({
                ...prevState,
                clearanceApprovalAuthorities: projectClearanceSettings.value.approvals?.authorities || [],
                clearanceNumberOfApprovalsRequired: {...defaultClearanceNumberOfApprovalsRequired, ...projectClearanceSettings.value.approvals?.numberOfApprovalsRequired},
                dashboardPassword: projectClearanceSettings.value.dashboardPassword || '',
                files: projectClearanceSettings.value.files,
                guidelinesUrl: projectClearanceSettings.value.guidelinesUrl || '',
                groupsBehavior: projectClearanceSettings.value.groupsBehavior || 'restrict',
                reportsRestrictedToManagers: !!projectClearanceSettings.value.reportsRestrictedToManagers,
                show2FieldsForClearanceNoteRecommendation: !!projectClearanceSettings.value.show2FieldsForClearanceNoteRecommendation,
                templateId: projectClearanceSettings.value.templateId || '',
                type: projectClearanceSettings.value.type || '',
            }));
            setLoading(false);
        }
    }, [folderClearanceViewOpen, selectedFolder, projectClearanceSettings.value]);

    // CHECK IF APPROVALS REQUIRED, IF SO THEN SET RESPECTIVE STATE FIELD AND SET "folderUsers" AND "projectGroupSettings" LISTENERS
    useEffect(() => {
        let approvalRequired = false;
        for(let riskLevelKey in folderState.clearanceNumberOfApprovalsRequired){
            if(folderState.clearanceNumberOfApprovalsRequired[riskLevelKey] >= 1){
                approvalRequired = true;
                setFirestoreListener('folderUsers', `projects/${selectedFolder.uid}`);
                setFirestoreListener('projectGroupSettings', selectedFolder.uid);
            }
        }
        setFolderState(prevState => ({...prevState, clearanceRequireApproval: approvalRequired}));
    }, [folderState.clearanceNumberOfApprovalsRequired]);

    // USE "folderUsers" AND "projectGroupSettings" TO COMPUTE AUTHORITIES AND SET THE "authorityOptions" STATE
    useEffect(() => {
        if(folderUsers.value && projectGroupSettings.value?.groups && workspaceUsers.value){
            const group = Object.entries(projectGroupSettings.value.groups).map(([groupId, group]) => ({ value: groupId, label: group.name }));
            const user = Object.entries(folderUsers.value).map(([userId]) => ({ value: userId, label: workspaceUsers.value[userId].name }));
            let currentAvailableAuthorities = {
                group,
                user
            };
            setFolderState(prevState => ({...prevState, availableAuthorities: currentAvailableAuthorities}));
        }
    }, [folderUsers.value, projectGroupSettings.value, workspaceUsers.value]);

    const handleSubmit = async () => {
        setSaving(true);

        const toastId = toast.loading(`Salvando configurações de clearance do projeto "${selectedFolder.name}"...`);

        let filesList;
        const filesInput = document.getElementById('filesField');
        if(clearanceFiles.length !== 0){
            filesList = filesInput.files;
        }

        const res = await selectedFolder.updateClearance({
            activeUserId: activeUser.value.uid,
            authorities: folderState.clearanceApprovalAuthorities,
            enabled: folderState.clearanceRequireApproval,
            dashboardPassword: folderState.dashboardPassword,
            filesList,
            groupsBehavior: folderState.groupsBehavior,
            guidelinesUrl: folderState.guidelinesUrl,
            numberOfApprovalsRequired: folderState.clearanceNumberOfApprovalsRequired,
            reportsRestrictedToManagers: folderState.reportsRestrictedToManagers,
            show2FieldsForClearanceNoteRecommendation: folderState.show2FieldsForClearanceNoteRecommendation,
            templateId: folderState.templateId,
            type: folderState.type,
        });
        setSaving(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }

        if(clearanceFiles.length !== 0){
            if(filesInput) filesInput.value = '';
            setClearanceFiles([]);
        }

        toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        hideFolderClearanceView();
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={folderClearanceViewOpen}
            saving={saving}
            title={`${selectedFolder ? `${selectedFolder.name} | ` : ''}Clearance`}
            TransitionComponent={Transition}
        >

            <Container maxWidth="md">
                {
                    folderClearanceViewOpen &&
                    <>
                        {
                            !selectedFolder || !workspaceClearanceTemplates.value || loading
                            ? <LoaderEllipsis />
                            :
                            <Box>
                                <Box mb={3}>
                                    <Box mb={2}>
                                        {/* <Typography variant="h6">Endereço da central de clearance</Typography> */}
                                    </Box>
                                    <Box mb={1}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={loading || folderState.type}
                                                    label="Tipo"
                                                    options={workTypeOptions}
                                                    value={folderState.type} onChange={(e) => setFolderState(prevState => ({...prevState, type: e.target.value}))}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Select
                                                    disabled={loading || folderState.templateId}
                                                    label="Matriz"
                                                    options={workspaceClearanceTemplates.value.map(template => ({value: template.uid, label: template.name}))}
                                                    value={folderState.templateId} onChange={(e) => setFolderState(prevState => ({...prevState, templateId: e.target.value}))}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                                {
                                    (folderState.type && folderState.templateId) &&
                                    <Box>
                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Endereço da central de clearance</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Typography variant="body1" noWrap><Link
                                                    href={`${APP_PATH}clearance/${selectedFolder.uid}`}
                                                    target="_blank"
                                                    underline="hover">{APP_PATH}clearance/{selectedFolder.uid}</Link></Typography>
                                            </Box>
                                        </Box>

                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Configurações gerais</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} md={4}>
                                                        <TextField
                                                            variant="standard"
                                                            label="Senha da central de clearance"
                                                            fullWidth
                                                            value={folderState.dashboardPassword}
                                                            onChange={(e) => setFolderState(prevState => ({...prevState, dashboardPassword: e.target.value}))} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={8}>
                                                        <TextField
                                                            variant="standard"
                                                            label="Link para diretrizes de clearance"
                                                            fullWidth
                                                            value={folderState.guidelinesUrl}
                                                            onChange={(e) => setFolderState(prevState => ({...prevState, guidelinesUrl: e.target.value}))} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Comportamento dos grupos (se for o caso)</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            label="Acesso às consultas de clearance"
                                                            options={[
                                                                {value: 'restrict', label: 'Os integrantes dos grupos terão acesso restrito às consultas criadas em seu próprio grupo'},
                                                                {value: 'categorize', label: 'Os grupos se prestam à organização das consultas, mas todos podem acessar todas as consultas, inclusive usuários não conectados (no modo "somente leitura")'}
                                                            ]}
                                                            value={folderState.groupsBehavior} onChange={(e) => setFolderState(prevState => ({...prevState, groupsBehavior: e.target.value}))}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Switch
                                                    label={`Relatórios de clearance visíveis apenas para Gerentes`}
                                                    checked={folderState.reportsRestrictedToManagers}
                                                    onChange={(e) => setFolderState(prevState => ({...prevState, reportsRestrictedToManagers: e.target.checked}))}
                                                />
                                                <Switch
                                                    label={`As anotações de relatórios de clearance devem conter os campos "Justificativa" e "Orientação" (ao invés de só um campo)`}
                                                    checked={folderState.show2FieldsForClearanceNoteRecommendation}
                                                    onChange={(e) => setFolderState(prevState => ({...prevState, show2FieldsForClearanceNoteRecommendation: e.target.checked}))}
                                                />
                                            </Box>
                                        </Box>

                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Arquivos</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Box mb={2}>
                                                    <FilesList list={folderState.files || {}} />
                                                </Box>
                                                <Box mb={1}>
                                                    <FilesField
                                                        attachFileButtonComponent="Button" horizontalAlign="flex-start"
                                                        accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/*"
                                                        filesList={clearanceFiles} setFilesList={setClearanceFiles}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box mb={3}>
                                            <Box mb={2}>
                                                <Typography variant="h6">Número de aprovações exigidas por nível de risco</Typography>
                                            </Box>
                                            <Box mb={1}>
                                                <Box mb={1}>
                                                    {
                                                        CLEARANCE_RISK_OPTIONS.map(option => {
                                                            return (
                                                                <Box key={option.value}>
                                                                    <Grid container spacing={1} alignItems="center">
                                                                        <Grid item>
                                                                            <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <AddOrSubtractDial
                                                                                subtractDisabled={folderState.clearanceNumberOfApprovalsRequired[option.value] === 0}
                                                                                subtractOnClick={() => setFolderState(prevState => ({...prevState, clearanceNumberOfApprovalsRequired: {...prevState.clearanceNumberOfApprovalsRequired, [option.value]: folderState.clearanceNumberOfApprovalsRequired[option.value] - 1}}))}
                                                                                number={folderState.clearanceNumberOfApprovalsRequired[option.value]}
                                                                                addOnClick={() => setFolderState(prevState => ({...prevState, clearanceNumberOfApprovalsRequired: {...prevState.clearanceNumberOfApprovalsRequired, [option.value]: folderState.clearanceNumberOfApprovalsRequired[option.value] + 1}}))}
                                                                                shouldShowNumber
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                </Box>
                                            </Box>
                                        </Box>

                                        {
                                            folderState.clearanceRequireApproval &&
                                            <Box mb={3}>
                                                <Box mb={2}>
                                                    <Typography variant="h6">Autoridades que aprovam riscos</Typography>
                                                </Box>
                                                <Box mb={1}>
                                                    <Box mb={2}>
                                                        <Typography variant="body1">Se houver mais uma autoridade, qualquer uma delas poderá aprovar anotações de clearance do mesmo tipo. Se a autoridade for um grupo, qualquer pessoa do grupo poderá realização a aprovação.</Typography>
                                                    </Box>
                                                    {
                                                        (folderUsers.value && projectGroupSettings.value && workspaceUsers.value) && 
                                                        <Box mb={1}>
                                                            {
                                                                folderState.clearanceApprovalAuthorities.map((authority, authorityIndex) => (
                                                                    <Box key={authorityIndex}>
                                                                        <Grid container spacing={1} alignItems="flex-end">
                                                                            <Grid item xs={6} sm={6} md={2} lg={2}>
                                                                                <Select
                                                                                    label="Tipo"
                                                                                    options={[{ value: 'user', label: 'Pessoa' }, { value: 'group', label: 'Grupo' }]}
                                                                                    value={authority.type} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), {...prevState[authorityIndex], type: e.target.value}, ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                                                                <Select
                                                                                    label={authority.type === 'user' ? 'Pessoa' : 'Grupo'}
                                                                                    options={folderState.availableAuthorities[authority.type]}
                                                                                    value={authority.uid} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), {...prevState.clearanceApprovalAuthorities[authorityIndex], uid: e.target.value}, ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item xs={6} sm={6} md={6} lg={5}>
                                                                                <Select
                                                                                    label="Nível de risco que pode aprovar"
                                                                                    options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                                                                                    value={authority.maximumRiskLevel} onChange={(e) => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), {...prevState.clearanceApprovalAuthorities[authorityIndex], maximumRiskLevel: e.target.value}, ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <IconButton size="small"
                                                                                    onClick={() => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities.slice(0, authorityIndex), ...prevState.clearanceApprovalAuthorities.slice(authorityIndex + 1)]}))}
                                                                                ><ClearIcon /></IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {/* <Divider /> */}
                                                                    </Box>
                                                                ))
                                                            }
                                                        </Box>
                                                    }
                                                    <Box mb={1}>
                                                        <Grid container justifyContent="flex-end">
                                                            <Grid item>
                                                                <Button
                                                                    startIcon={<AddIcon />}
                                                                    onClick={() => setFolderState(prevState => ({...prevState, clearanceApprovalAuthorities: [...prevState.clearanceApprovalAuthorities, defaultClearanceAuthority]}))}
                                                                >Cadastrar autoridade</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                        }
                    </>
                }
            </Container>

        </Dialog>
    );
};

export default ChangeProjectClearanceInformationWindow;