import { useSignals } from '@preact/signals-react/runtime';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

import Header from '../../../../components/Header';
import HeaderWorkspaceImage from '../../../../components/HeaderWorkspaceImage';
import OnlineUsers from '../../../../components/OnlineUsers';
import { HEADER_HEIGHT } from '../../../../utils/constants';

import { useAppStateCtx } from '../../../../context/AppState';
import { useOperatorCtxAPI, useOperatorCtxSidenavMobile } from '../../context/OperatorContext';
import Account from '../../Account';

const classes = {
    title: {
        color: 'grey',
        fontFamily: `"Titillium Web", sans-serif`,
        fontWeight: 700,
        fontStyle: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
    },
};

const PageTitle = () => {
    useSignals();
    const { pageTitle } = useAppStateCtx();
    const theme = useTheme();
    const screenMDDownExclusive = useMediaQuery(theme.breakpoints.down('md'));

    if(pageTitle.value){
        return (
            <Typography variant="h1" sx={[classes.title, screenMDDownExclusive && { textTransform: 'none'}]}>{pageTitle.value}</Typography>
        )
    }
    return null;
}

const OperatorHeader = () => { 
    useSignals();
    const { setState: setOperatorCtxState } = useOperatorCtxAPI();
    const { sidenavMobileOpen } = useOperatorCtxSidenavMobile();

    const set_sidenavMobileOpen = (newValue) => {
        setOperatorCtxState('sidenavMobileOpen', newValue);
    };

    return (
        <Header
            leftBox={
                <Grid container spacing={2} alignItems="center" wrap="nowrap" sx={{ height: HEADER_HEIGHT }}>
                    <HeaderWorkspaceImage />
                    <PageTitle />
                </Grid>
            }
            onlineUsers={
                <OnlineUsers />
            }
            account={<Account />}
            data={{
                sidenavMobileOpen, set_sidenavMobileOpen
            }}
        />
    );
};

export default OperatorHeader;