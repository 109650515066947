import { doc, getDoc as _getDoc } from 'firebase/firestore';

import { db } from '../config';

export default async function getDoc(path: string) {
    let result = null, error = null;
    
    const docRef = doc(db, path);

    try {
        const snapshot = await _getDoc(docRef);
        result = snapshot.data();
    } catch (e) {
        error = e;
    }

    return { result, error };
}