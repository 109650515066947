import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DocumentsTemplateTextEffectOutputTypeSelector from '../../../components/DocumentsTemplateTextEffectOutputTypeSelector';
import TextCaseRadioOptions from '../../../components/TextCaseRadioOptions';

const NewEffect = ({ handleChange, setDisabled }) => {
    const [value, setValue] = useState({ question: '', textCase: 'none' });

    useEffect(() => {
        handleChange(value);
        if(setDisabled){
            let isDisabled = false;
            if(!value.question) isDisabled = true;
            setDisabled(isDisabled);
        }
    }, [value]);

    return (
        <Box>
            <Box mb={2}>
                <Box mb={1}>
                    <Typography variant="h6">Pergunta</Typography>
                </Box>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder="Sua pergunta"
                    value={value.question}
                    onChange={(e) => setValue(prevState => ({...prevState, question: e.target.value}))} />
            </Box>
            <TextCaseRadioOptions
                value={value.textCase}
                onChange={(e) => setValue(prevState => ({...prevState, textCase: e.target.value}))}
            />
            <DocumentsTemplateTextEffectOutputTypeSelector
                value={value.outputType || 'none'}
                onChange={(e) => setValue(prevState => ({...prevState, outputType: e.target.value}))}
            />
        </Box>
    );
};

export default NewEffect;