import { collection, doc, runTransaction } from 'firebase/firestore';

import { db } from '../firebase/config';

export default class JudicialCase {
    caseNumber = '';
    chamber = '';
    children?: JudicialCase[] = []; // only local
    clientId = '';
    clientName = '';
    createdAt = '';
    createdBy = '';
    disabled = false;
    disabledAt = '';
    disabledBy = '';
    fileUrl = '';
    folderUrl = '';
    lastProgressRecord = null;
    name = '';
    officialInformationUrlPublic = '';
    officialInformationUrlRestricted = '';
    operatorId: string[] = [];
    orderNumber = -1;
    parentId = '';
    planning = '';
    position = '';
    reminders = '';
    report = '';
    uid = '';
    unit = '';
    value = '';
    versus = '';
    workspaceId = '';

    constructor({
        caseNumber,
        chamber,
        children,
        clientId,
        clientName,
        createdAt,
        createdBy,
        disabled,
        disabledAt,
        disabledBy,
        fileUrl,
        folderUrl,
        lastProgressRecord,
        name,
        officialInformationUrlPublic,
        officialInformationUrlRestricted,
        operatorId,
        orderNumber,
        parentId,
        planning,
        position,
        reminders,
        report,
        uid,
        unit,
        value,
        versus,
        workspaceId
    }: JudicialCase){
        if(caseNumber) this.caseNumber = caseNumber;
        if(chamber) this.chamber = chamber;
        if(children) this.children = children;
        if(clientId) this.clientId = clientId;
        if(clientName) this.clientName = clientName;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(disabled) this.disabled = disabled;
        if(disabledAt) this.disabledAt = disabledAt;
        if(disabledBy) this.disabledBy = disabledBy;
        if(fileUrl) this.fileUrl = fileUrl;
        if(folderUrl) this.folderUrl = folderUrl;
        if(lastProgressRecord) this.lastProgressRecord = lastProgressRecord;
        if(name) this.name = name;
        if(officialInformationUrlPublic) this.officialInformationUrlPublic = officialInformationUrlPublic;
        if(officialInformationUrlRestricted) this.officialInformationUrlRestricted = officialInformationUrlRestricted;
        if(operatorId) this.operatorId = operatorId;
        if(orderNumber) this.orderNumber = orderNumber;
        if(parentId) this.parentId = parentId;
        if(planning) this.planning = planning;
        if(position) this.position = position;
        if(reminders) this.reminders = reminders;
        if(report) this.report = report;
        if(uid) this.uid = uid;
        if(unit) this.unit = unit;
        if(value) this.value = value;
        if(versus) this.versus = versus;
        if(workspaceId) this.workspaceId = workspaceId;
    }

    async createJudicialCase(){
        let result = null, error = null;

        const judicialCaseRef = doc(collection(db, `judicial_cases`));

        const {uid: _, ...judicialCase} = this;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(judicialCaseRef, judicialCase);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;

        const judicialCaseRef = doc(db, `judicial_cases/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(judicialCaseRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}