import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Workspace from '../../classes/Workspace';
import { useAppStateCtx, useAppStateCtxUtils } from '../../context/AppState';
import { useAppCtxAPI } from '../../context/SystemContext';

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        padding: '24px'
    },
    button: {
        color: theme => theme.palette.text.primary,
        cursor: 'pointer',
        padding: '12px 24px',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        '&:hover, &.active': {
            backgroundColor: theme => theme.palette.background.dark,
            color: '#f5f5f5'
        }
    }
};

function Dashboards(){
    useSignals();
    const { activeUser, loadingApp, selectedWorkspace } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { getActiveUserWorkspacesAndClaims, handleNavigate } = useAppCtxAPI();
    const [workspaces, setWorkspaces] = useState([]);
    const clientManagementID = 'CLIENT MANAGEMENT';

    const fetchData = async () => {
        const res = await getActiveUserWorkspacesAndClaims(activeUser.value);
        if(res.result){
            setWorkspaces(res.result.workspaces);
            if(res.result.selectedWorkspace){
                const currentSelectedWorkspace = new Workspace({...res.result.selectedWorkspace});
                selectedWorkspace.value = currentSelectedWorkspace;
                setFirestoreListener('workspaceUsers', currentSelectedWorkspace.uid);
                const path = `/${res.result.selectedWorkspace.shortName}/${res.result.selectedWorkspace.role.includes('operator') ? 'juridico' : 'pastas'}`;
                return handleNavigate(path);
            }
        }
        loadingApp.value = false;
    };

    useEffect(() => {
        if(activeUser.value){
            fetchData();
        }
    }, [activeUser.value]);

    const handleWorkspaceClick = async (workspace) => {
        if(workspace === clientManagementID) return toast('Essa funcionalidade será reativada em breve!'); //TODO
        
        loadingApp.value = true;
        const workspaceId = workspace.uid;
        const setUserCustomClaimsRes = await activeUser.value.setClaims(workspaceId);
        if(setUserCustomClaimsRes.error){
            return console.log(setUserCustomClaimsRes.error);
        }

        const currentSelectedWorkspace = new Workspace({...workspace});
        selectedWorkspace.value = currentSelectedWorkspace;
        setFirestoreListener('workspaceUsers', currentSelectedWorkspace.uid);
        const path = `/${workspace.shortName}/${workspace.role.includes('operator') ? 'juridico' : 'pastas'}`;
                
        setTimeout(() => {
            handleNavigate(path);
        }, 2000);
    };

    return (
        <Box sx={classes.root}>
            <Container maxWidth="xs">
                <Box style={{textAlign: 'center'}} mb={2}>
                    <img src={'/SOLIDA-500.png'} style={{height: 80}} alt="SOLIDA" />
                </Box>
                <Grid container spacing={1} justifyContent="center">
                    {
                        activeUser.value?.isWorkspacesManager &&
                        <Grid item xs={12}>
                            <Paper sx={classes.button} onClick={() => handleWorkspaceClick(clientManagementID)}>
                                <Grid container spacing={1}>
                                    <Grid item></Grid>
                                    <Grid item xs>
                                        <Typography variant="body2">Administrar áreas de trabalho</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    }
                    {
                        workspaces
                        .sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
                        .map((workspace) => (
                            <Grid key={workspace.uid} item xs={12}>
                                <Paper
                                    sx={classes.button}
                                    onClick={() => handleWorkspaceClick(workspace)}
                                >
                                    <Typography variant="body2">{workspace.name}</Typography>
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>
            </Container>
        </Box>
    );
}

export default Dashboards;