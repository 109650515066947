import { Routes, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import Clients from './Clients';
import Projects from './Projects';
import UserCreatedFields from './UserCreatedFields';
import { OperatorCompaniesProvider } from '../../../context/OperatorCompaniesContext';
import { OperatorFoldersProvider } from '../../../context/OperatorFoldersContext';

function Folders(){
    return (
        <Box style={{height: '100%'}}>
            <OperatorCompaniesProvider>
                <OperatorFoldersProvider>
                    <Routes>
                        <Route path="/" element={<Projects />} />
                        <Route path="/empresas" element={<Clients />} />
                        <Route path="/pastas" element={<Projects />} />
                        <Route path="/informacoes-especiais" element={<UserCreatedFields />} />
                        <Route path="*" element={<Projects />} />
                    </Routes>
                </OperatorFoldersProvider>
            </OperatorCompaniesProvider>
        </Box>
    );
}

export default Folders;