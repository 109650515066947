import { useEffect, useRef, useState } from 'react';
import { and, collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Proposal from '../classes/Proposal';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetProposals = (shouldSetListener, doneProposalsYear, contractSupervisedByFilter) => {
    useSignals();
    const { activeUser, selectedWorkspace, workspacePartners } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);
    const [contractSupervisedBy, setContractSupervisedBy] = useState(false);
    const requestDataOnContractSupervisedByFilterChange = useRef(true);

    const isActiveUserPartner = () => {
        return workspacePartners.value.some(partner => partner.uid === activeUser.value.uid);
    };

    useEffect(() => {
        if(requestDataOnContractSupervisedByFilterChange.current){
            setContractSupervisedBy(contractSupervisedByFilter);
            if(contractSupervisedByFilter === 'all'){
                requestDataOnContractSupervisedByFilterChange.current = false;
            }
        }
    }, [contractSupervisedByFilter]);

    useEffect(() => {
        if(shouldSetListener && activeUser.value && activeUserIsOperator.value && selectedWorkspace.value?.role !== 'operator'){
            setHookState(defaultHookState);
            let queryConstraints = [
                where('workspaceId', '==', selectedWorkspace.value.uid),
                where('done', '==', !!doneProposalsYear),
            ];
            if(doneProposalsYear){
                const startOfYearMoment = moment().year(doneProposalsYear).startOf('year');
                const endOfYear = moment(startOfYearMoment).endOf('year').toISOString();
                queryConstraints = [
                    and(
                        ...queryConstraints,
                        where('createdAt', '>=', startOfYearMoment.toISOString()),
                        where('createdAt', '<', endOfYear)
                    )
                ];
            } else {
                if(!contractSupervisedBy){
                    if(selectedWorkspace.value.role !== 'controller' && isActiveUserPartner()){
                        queryConstraints.push(where('contractSupervisedBy', '==', activeUser.value.uid));
                    }
                } else {
                    if(contractSupervisedBy !== 'all') queryConstraints.push(where('contractSupervisedBy', '==', contractSupervisedBy));
                }
            }
            
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'proposals'),
                    ...queryConstraints,
                    orderBy('createdAt', 'asc')
                ), (snapshot) => {
                const dataArray = [];
                snapshot.forEach(foundProposal => {
                    const data = foundProposal.data();
                    const currentProposal = new Proposal({
                        ...data,
                        uid: foundProposal.id,
                    });
                    dataArray.push(currentProposal);
                });
                setHookState({
                    loading: false,
                    data: dataArray
                });
            },
            (error) => {
                console.error(error.message);
            });
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUser.value, activeUserIsOperator.value, selectedWorkspace.value, doneProposalsYear, contractSupervisedBy]);

    return hookState;
};

export default useGetProposals;