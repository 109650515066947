import React, { createContext, useContext } from 'react';

import { computed, effect, ReadonlySignal, signal, Signal } from '@preact/signals-react';

import { CNAEs } from '../utils/cnaes';
import { getProjectTemplates } from '../utils/common';
import { currentYear } from '../utils/constants';
import { sortByKey, sortObjectEntriesByKey } from '../utils/filters';

type FirestoreListeners = {
    activeUserESignatureSettings?: boolean;
    folderESignatureSettings?: string;
    folderUsers?: string;
    clientSuppliers?: string;
    pendingProposals?: boolean;
    projectClearanceSettings?: boolean;
    projectDocumentSettings?: boolean;
    projectFormDrafts?: boolean;
    projectGroupSettings?: boolean;
    projectMisc?: string;
    projectTalentSettings?: boolean;
    proposalsWithPaymentsAsOfPreviousYear?: boolean;
    resolvedProposals?: boolean;
    workspaceActiveProjectBoards?: boolean;
    workspaceChatMessages?: boolean;
    workspaceClearanceTemplates?: boolean;
    workspaceDisabledJudicialCases?: boolean;
    workspaceDocumentsTemplates?: boolean;
    workspaceFinanceSettings?: boolean;
    workspaceJudicialCases?: boolean;
    workspaceLists?: boolean;
    workspaceListsItems?: { [listId: string]: boolean; };
    workspacePendingJudicialCasesProgress?: boolean;
    workspaceProjectsCustomFields?: boolean;
    workspaceProjectsWithClearanceIds?: boolean;
    workspaceTemplateLists?: boolean;
    workspaceTimesheetRecords?: boolean;
    workspaceUsers?: string;
    yearExpenses?: boolean;
}

type FirestoreListenerIds = keyof FirestoreListeners;

type ProjectGroupSettingsGroups = { [groupId: string]: { name: string; users: string[] } };

type AppStateTypes = {
    activeUser: { [key: string]: any } | null;
    activeUserAuth: any;
    activeUserClearanceAuthorityLevel: number;
    activeUserESignatureSettings: { [key: string]: any } | null;
    activeUserGroups: (ProjectGroupSettingsGroups[string] & { id: string })[];
    activeUserIsGroupsManager: boolean;
    activeUserMainGroup: string;
    appDialog: {
        cancelButtonLabel?: string;
        confirmButtonLabel?: string;
        onCancelButtonClick?: () => void;
        onConfirmButtonClick?: (input: string) => void;
        open: boolean;
        text?: string;
        textFieldInitialValue?: string;
        title?: string;
        type?: 'alert' | 'form'
    };
    chatViewOpen: boolean;
    clientSuppliers: { [key: string]: any }[] | null;
    eSignatureStatusExpanded: boolean;
    firestoreListeners: FirestoreListeners;
    folderESignatureSettings: { enabled: boolean; folderId: string; [key: string]: any; } | null;
    folderUsers: { [userId: string]: any } | null;
    formAllQuestions: { [key: string]: any }[];
    formFile: { [key: string]: any } | null;
    formFoundCNPJ: { [key: string]: any } | null;
    formFoundCNPJs: { [key: string]: any };
    formForceCheckValidation: boolean;
    formInitialValues: { [key: string]: any };
    formLists: { [key: string]: any };
    formQuestionNameToCNPJ: { [key: string]: any };
    formQuestionsStatus: { [key: string]: any };
    formSelectedDraft: { [key: string]: any } | null;
    formSelectedSupplier: { [key: string]: any } | null;
    formVisibleQuestions: { [key: string]: any }[];
    loadingApp: boolean;
    loadingAppText: string;
    loadingForm: boolean;
    loadingProposals: boolean;
    numberOfUnreadChatMessages: number;
    onlineClients: { [key: string]: any }[];
    onlineOperators: { [key: string]: any }[];
    onlineUsers: { [key: string]: any }[];
    onlineUsersListPopoverAnchorEl: any;
    onlineUsersListPopoverOpen: boolean;
    onlineUserWindowId: string;
    pageTitle: string;
    pendingProposals: { [key: string]: any }[] | null;
    projectClearanceSettings: { [key: string]: any } | null;
    projectDocumentSettings: { [key: string]: any } | null;
    projectDocumentsTemplateOptions: { label: string; value: string; }[] | null;
    projectDocumentsTemplates: string[];
    projectFormDrafts: { [key: string]: any }[] | null;
    projectGroups: ProjectGroupSettingsGroups;
    projectGroupSettings: { enabled: boolean; groups: ProjectGroupSettingsGroups; projectId: string; } | null;
    projectMisc: ({ projectId: string; } & { [key: string]: any }) | null;
    projectTalentSettings: { enabled: boolean; } | null;
    proposalsFilters: {
        area: string;
        accepted: string;
        contractSupervisedBy: string;
        type: string;
        status: string;
        client: string;
        text: string;
        year: number;
    };
    proposalsWithPaymentsAsOfPreviousYear: { [key: string]: any }[] | null;
    resolvedProposals: { [key: string]: any }[] | null;
    selectedDocumentWithCompleteForm: { [key: string]: any } | null;
    selectedDocumentForm: { [key: string]: any } | null;
    selectedDocumentsFormId: string;
    selectedFolder: { [key: string]: any } | null;
    selectedProjectClient: { [key: string]: any } | null;
    selectedWorkspace: { [key: string]: any } | null;
    selectedYear: number;
    workspaceActiveProjectBoards: { [key: string]: any }[] | null;
    workspaceChatMessages: { [key: string]: any }[] | null;
    workspaceClearanceTemplates: { [key: string]: any }[] | null;
    workspaceClients: { [clientId: string]: any } | null;
    workspaceDisabledJudicialCases: { [key: string]: any }[] | null;
    workspaceDocumentsTemplates: { [templateId: string]: any } | null;
    workspaceFinanceSettings: { [key: string]: any } | null;
    workspaceJudicialCases: { [key: string]: any }[] | null;
    workspaceLists: { [key: string]: any } | null;
    workspaceListsItems: { [key: string]: { [key: string]: any }[] };
    workspaceOperators: { [key: string]: any }[];
    workspaceOperatorsWithControllers: { [key: string]: any }[];
    workspacePartners: { [key: string]: any }[];
    workspacePartnersWithControllers: { [key: string]: any }[];
    workspacePendingJudicialCasesProgress: { [key: string]: any }[] | null;
    workspacePendingJudicialCasesProgressCount: number | null;
    workspaceProjects: { [projectId: string]: any } | null;
    workspaceProjectsCustomFields: { [key: string]: any }[] | null;
    workspaceProjectsWithClearanceIds: string[] | null;
    workspaceTemplateLists: { [listId: string]: any } | null;
    workspaceTimesheetRecords: { [key: string]: any }[] | null;
    workspaceUsers: { [userId: string]: any } | null;
    yearExpenses: { expenses: { [key: string]: any }[]; year: number; yearExpenses: { [key: string]: any }[] } | null;
    yearsMenuAnchorEl: HTMLElement | null,
    yearsMenuOpen: boolean;
};
type AppStateCtxValueType = { [Property in keyof AppStateTypes]: Signal<AppStateTypes[Property]> };
const AppStateCtx = createContext<AppStateCtxValueType>({} as AppStateCtxValueType);

type AppStateCtxAPIValueType = {
    activeUserIsOperator: ReadonlySignal<boolean>;
    activeUserPendingJudicialCasesProgress: ReadonlySignal<{ [key: string]: any; }[] | null>;
    mappedCNAEs: ReadonlySignal<{ code: number; description: string; }[]>;
    mappedWorkspaceClients: ReadonlySignal<any[] | null>;
    mappedWorkspaceProjects: ReadonlySignal<any[] | null>;
    mappedWorkspaceDocumentTemplates: ReadonlySignal<any[] | null>;
    selectedClientProjects: ReadonlySignal<{ [key: string]: any; }[]>;
    selectedFolderIsClient: ReadonlySignal<boolean>;
    openDialog: (data: Omit<AppStateTypes['appDialog'], 'open'>) => void;
    resetState: (preserveKeys: string[]) => void;
    setFirestoreListener: (listenerId: FirestoreListenerIds, newValue?: string) => void;
};
const AppStateCtxAPI = createContext<AppStateCtxAPIValueType>({} as AppStateCtxAPIValueType);

const defaultProposalsFilters = {
    area: 'all',
    accepted: 'all',
    contractSupervisedBy: '',
    type: 'all',
    status: 'pending',
    client: 'all',
    text: '',
    year: currentYear
}

const initialState: {
    activeUser: AppStateTypes['activeUser'];
    activeUserAuth: AppStateTypes['activeUserAuth'];
    activeUserClearanceAuthorityLevel: AppStateTypes['activeUserClearanceAuthorityLevel'];
    activeUserESignatureSettings: AppStateTypes['activeUserESignatureSettings'];
    activeUserGroups: AppStateTypes['activeUserGroups'];
    activeUserIsGroupsManager: AppStateTypes['activeUserIsGroupsManager'];
    activeUserMainGroup: AppStateTypes['activeUserMainGroup'];
    appDialog: AppStateTypes['appDialog'];
    eSignatureStatusExpanded: AppStateTypes['eSignatureStatusExpanded'];
    chatViewOpen: AppStateTypes['chatViewOpen'];
    clientSuppliers: AppStateTypes['clientSuppliers'];
    firestoreListeners: AppStateTypes['firestoreListeners'];
    folderESignatureSettings: AppStateTypes['folderESignatureSettings'];
    folderUsers: AppStateTypes['folderUsers'];
    formAllQuestions: AppStateTypes['formAllQuestions'];
    formFile: AppStateTypes['formFile'];
    formFoundCNPJ: AppStateTypes['formFoundCNPJ'];
    formFoundCNPJs: AppStateTypes['formFoundCNPJs'];
    formForceCheckValidation: AppStateTypes['formForceCheckValidation'];
    formInitialValues: AppStateTypes['formInitialValues'];
    formLists: AppStateTypes['formLists'];
    formQuestionNameToCNPJ: AppStateTypes['formQuestionNameToCNPJ'];
    formQuestionsStatus: AppStateTypes['formQuestionsStatus'];
    formSelectedDraft: AppStateTypes['formSelectedDraft'];
    formSelectedSupplier: AppStateTypes['formSelectedSupplier'];
    formVisibleQuestions: AppStateTypes['formVisibleQuestions'];
    loadingApp: AppStateTypes['loadingApp'];
    loadingAppText: AppStateTypes['loadingAppText'];
    loadingForm: AppStateTypes['loadingForm'];
    loadingProposals: AppStateTypes['loadingProposals'];
    numberOfUnreadChatMessages: AppStateTypes['numberOfUnreadChatMessages'];
    onlineClients: AppStateTypes['onlineClients'];
    onlineOperators: AppStateTypes['onlineOperators'];
    onlineUsers: AppStateTypes['onlineUsers'];
    onlineUsersListPopoverAnchorEl: AppStateTypes['onlineUsersListPopoverAnchorEl'];
    onlineUsersListPopoverOpen: AppStateTypes['onlineUsersListPopoverOpen'];
    onlineUserWindowId: AppStateTypes['onlineUserWindowId'];
    pageTitle: AppStateTypes['pageTitle'];
    pendingProposals: AppStateTypes['pendingProposals'];
    projectClearanceSettings: AppStateTypes['projectClearanceSettings'];
    projectDocumentSettings: AppStateTypes['projectDocumentSettings'];
    projectDocumentsTemplateOptions: AppStateTypes['projectDocumentsTemplateOptions'];
    projectDocumentsTemplates: AppStateTypes['projectDocumentsTemplates'];
    projectFormDrafts: AppStateTypes['projectFormDrafts'];
    projectGroups: AppStateTypes['projectGroups'];
    projectGroupSettings: AppStateTypes['projectGroupSettings'];
    projectMisc: AppStateTypes['projectMisc'];
    projectTalentSettings: AppStateTypes['projectTalentSettings'];
    proposalsFilters: AppStateTypes['proposalsFilters'];
    proposalsWithPaymentsAsOfPreviousYear: AppStateTypes['proposalsWithPaymentsAsOfPreviousYear'];
    resolvedProposals: AppStateTypes['resolvedProposals'];
    selectedDocumentWithCompleteForm: AppStateTypes['selectedDocumentWithCompleteForm'];
    selectedDocumentForm: AppStateTypes['selectedDocumentForm'];
    selectedDocumentsFormId: AppStateTypes['selectedDocumentsFormId'];
    selectedFolder: AppStateTypes['selectedFolder'];
    selectedProjectClient: AppStateTypes['selectedProjectClient'];
    selectedWorkspace: AppStateTypes['selectedWorkspace'];
    selectedYear: AppStateTypes['selectedYear'];
    workspaceActiveProjectBoards: AppStateTypes['workspaceActiveProjectBoards'];
    workspaceChatMessages: AppStateTypes['workspaceChatMessages'];
    workspaceClearanceTemplates: AppStateTypes['workspaceClearanceTemplates'];
    workspaceClients: AppStateTypes['workspaceClients'];
    workspaceDisabledJudicialCases: AppStateTypes['workspaceDisabledJudicialCases'];
    workspaceDocumentsTemplates: AppStateTypes['workspaceDocumentsTemplates'];
    workspaceFinanceSettings: AppStateTypes['workspaceFinanceSettings'];
    workspaceJudicialCases: AppStateTypes['workspaceJudicialCases'];
    workspaceLists: AppStateTypes['workspaceLists'];
    workspaceListsItems: AppStateTypes['workspaceListsItems'];
    workspaceOperators: AppStateTypes['workspaceOperators'];
    workspaceOperatorsWithControllers: AppStateTypes['workspaceOperatorsWithControllers'];
    workspacePartners: AppStateTypes['workspacePartners'];
    workspacePartnersWithControllers: AppStateTypes['workspacePartnersWithControllers'];
    workspacePendingJudicialCasesProgress: AppStateTypes['workspacePendingJudicialCasesProgress'];
    workspacePendingJudicialCasesProgressCount: AppStateTypes['workspacePendingJudicialCasesProgressCount'];
    workspaceProjects: AppStateTypes['workspaceProjects'];
    workspaceProjectsCustomFields: AppStateTypes['workspaceProjectsCustomFields'];
    workspaceProjectsWithClearanceIds: AppStateTypes['workspaceProjectsWithClearanceIds'];
    workspaceTemplateLists: AppStateTypes['workspaceTemplateLists'];
    workspaceTimesheetRecords: AppStateTypes['workspaceTimesheetRecords'];
    workspaceUsers: AppStateTypes['workspaceUsers'];
    yearExpenses: AppStateTypes['yearExpenses'];
    yearsMenuAnchorEl: AppStateTypes['yearsMenuAnchorEl'];
    yearsMenuOpen: AppStateTypes['yearsMenuOpen'];
} = {
    activeUser: null,
    activeUserAuth: null,
    activeUserClearanceAuthorityLevel: 0,
    activeUserESignatureSettings: null,
    activeUserGroups: [],
    activeUserIsGroupsManager: false,
    activeUserMainGroup: '',
    appDialog: {
        open: false,
        text: '',
    },
    chatViewOpen: false,
    clientSuppliers: null,
    eSignatureStatusExpanded: false,
    firestoreListeners: { workspaceListsItems: {} },
    folderESignatureSettings: null,
    folderUsers: null,
    formAllQuestions: [],
    formFile: null,
    formFoundCNPJ: null,
    formFoundCNPJs: {},
    formForceCheckValidation: false,
    formInitialValues: {},
    formLists: {},
    formQuestionNameToCNPJ: {},
    formQuestionsStatus: {},
    formSelectedDraft: null,
    formSelectedSupplier: null,
    formVisibleQuestions: [],
    loadingApp: false,
    loadingAppText: '',
    loadingForm: false,
    loadingProposals: true,
    numberOfUnreadChatMessages: 0,
    onlineClients: [],
    onlineOperators: [],
    onlineUsers: [],
    onlineUsersListPopoverAnchorEl: null,
    onlineUsersListPopoverOpen: false,
    onlineUserWindowId: '',
    pageTitle: '',
    pendingProposals: null,
    projectClearanceSettings: null,
    projectDocumentSettings: null,
    projectDocumentsTemplateOptions: null,
    projectDocumentsTemplates: [],
    projectFormDrafts: null,
    projectGroups: {},
    projectGroupSettings: null,
    projectMisc: null,
    projectTalentSettings: null,
    proposalsFilters: defaultProposalsFilters,
    proposalsWithPaymentsAsOfPreviousYear: null,
    resolvedProposals: null,
    selectedDocumentWithCompleteForm: null,
    selectedDocumentForm: null,
    selectedDocumentsFormId: '',
    selectedFolder: null,
    selectedProjectClient: null,
    selectedWorkspace: null,
    selectedYear: currentYear,
    workspaceActiveProjectBoards: null,
    workspaceChatMessages: null,
    workspaceClearanceTemplates: null,
    workspaceClients: null,
    workspaceDisabledJudicialCases: null,
    workspaceDocumentsTemplates: null,
    workspaceFinanceSettings: null,
    workspaceJudicialCases: null,
    workspaceLists: null,
    workspaceListsItems: {},
    workspaceOperators: [],
    workspaceOperatorsWithControllers: [],
    workspacePartners: [],
    workspacePartnersWithControllers: [],
    workspacePendingJudicialCasesProgress: null,
    workspacePendingJudicialCasesProgressCount: null,
    workspaceProjects: null,
    workspaceProjectsCustomFields: null,
    workspaceProjectsWithClearanceIds: null,
    workspaceTemplateLists: null,
    workspaceTimesheetRecords: null,
    workspaceUsers: null,
    yearExpenses: null,
    yearsMenuAnchorEl: null,
    yearsMenuOpen: false,
};

type Signalify<T> = {
    [K in keyof T]: Signal<T[K]>;
};

function signalify<T extends Record<string, unknown>>(obj: T) {
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, signal(value)])) as Signalify<T>;
}

const initialStateSignals = signalify(initialState);

const resetState = (preserveKeys?: string[]) => {
    for(const key in initialStateSignals){
        //@ts-ignore
        if(!preserveKeys || !preserveKeys.includes(key)) initialStateSignals[key].value = initialState[key];
    }
}

const activeUserIsOperator = computed(() => {
    if(initialStateSignals.selectedWorkspace.value?.role !== 'client'){
        return true;
    }
    return false;
});

const activeUserPendingJudicialCasesProgress = computed(() => {
    if(initialStateSignals.activeUser.value && initialStateSignals.workspaceJudicialCases.value && initialStateSignals.workspacePendingJudicialCasesProgress.value){
        return initialStateSignals.workspacePendingJudicialCasesProgress.value.filter(judicialCaseProgress => {
            const foundCase = initialStateSignals.workspaceJudicialCases.value!.find(judicialCase => judicialCase.uid === judicialCaseProgress.parentId);
            if(foundCase){
                return foundCase.operatorId.includes(initialStateSignals.activeUser.value!.uid);
            }
            return false;
        });
    }
    return null;
});

// useEffect(() => {
//     if(activeUser && state.pendingJudicialCases && state.pendingJudicialCasesProgress){
//         const currentPendingActiveUserJudicialCasesProgress = state.pendingJudicialCasesProgress.filter(item => {
//             const foundCase = state.pendingJudicialCases.find(judicialCase => judicialCase.uid === item.parentId);
//             return foundCase && foundCase.operatorId.includes(activeUser.uid);
//         })
//         setState('pendingActiveUserJudicialCasesProgress', currentPendingActiveUserJudicialCasesProgress);
//     }
// }, [activeUser?.uid, state.pendingJudicialCases, state.pendingJudicialCasesProgress]);

const mappedCNAEs = computed(() => {
    let currentCodes: number[] = [];
    let currentMappedCNAEs: { code: number; description: string; }[] = [];
    CNAEs.forEach(item => {
        if(!currentCodes.includes(item.code)){
            currentCodes.push(item.code);
            currentMappedCNAEs.push(item);
        }
    });
    return currentMappedCNAEs;
});

const mappedWorkspaceClients = computed(() => {
    if(initialStateSignals.workspaceClients.value){
        let orderNumber = 0;
        return Object.entries(initialStateSignals.workspaceClients.value)
        .sort(sortObjectEntriesByKey('name'))
        .map(([clientId, client]) => {
            orderNumber++;
            return {
                ...client,
                uid: clientId,
                label: client.name,
                labelWithOrderNumber: `${orderNumber}) ${client.name}`,
                value: clientId
            }
        });
    }
    return null;
});

const mappedWorkspaceProjects = computed(() => {
    if(initialStateSignals.workspaceProjects.value){
        let orderNumber = 0;
        return Object.entries(initialStateSignals.workspaceProjects.value)
        .sort(sortObjectEntriesByKey('name'))
        .map(([projectId, project]) => {
            orderNumber++;
            let clientName = '';
            if(initialStateSignals.workspaceClients.value){
                const foundClient = initialStateSignals.workspaceClients.value[project.clientId];
                if(foundClient) clientName = foundClient.name;
            }
            return {
                ...project,
                uid: projectId,
                label: project.name,
                labelWithOrderNumber: `${orderNumber}) ${project.name}`,
                labelWithOrderNumberAndClientName: `${orderNumber}) ${clientName ? `${clientName} - ` : ''}${project.name}`,
                value: projectId
            }
        });
    }
    return null;
});

const mappedWorkspaceDocumentTemplates = computed(() => {
    if(initialStateSignals.workspaceDocumentsTemplates.value){
        let orderNumber = 0;
        return Object.entries(initialStateSignals.workspaceDocumentsTemplates.value)
        .sort(sortObjectEntriesByKey('name'))
        .map(([templateId, template]) => {
            orderNumber++;
            const templateName = `${template.name}${template.description ? ` (${template.description})` : ''}`;
            return {
                ...template,
                uid: templateId,
                label: templateName,
                labelWithOrderNumber: `${orderNumber}) ${templateName}`,
                value: templateId
            }
        });
    }
    return null;
});

const selectedClientProjects = computed(() => {
    let currentProjects: { [key: string]: any }[] = [];
    if(initialStateSignals.selectedFolder.value && initialStateSignals.workspaceProjects){
        Object.entries(initialStateSignals.workspaceProjects).forEach(([projectId, project]) => {
            if(project.clientId === initialStateSignals.selectedFolder.value!.uid){
                currentProjects.push({...project, uid: projectId});
            }
        });
    }
    return currentProjects;
});

const selectedFolderIsClient = computed(() => {
    if(initialStateSignals.selectedFolder.value){
        return !initialStateSignals.selectedFolder.value.clientId;
    }
    return false;
});

const openDialog = ({
    cancelButtonLabel,
    confirmButtonLabel,
    onCancelButtonClick,
    onConfirmButtonClick,
    text,
    textFieldInitialValue,
    title,
    type = 'alert'
}: Omit<AppStateTypes['appDialog'], 'open'>) => {
    initialStateSignals.appDialog.value = {
        cancelButtonLabel,
        confirmButtonLabel,
        onCancelButtonClick,
        onConfirmButtonClick,
        open: true,
        text,
        textFieldInitialValue,
        title,
        type
    };
};

const setFirestoreListener = (listenerId: FirestoreListenerIds, newValue?: string) => {
    initialStateSignals.firestoreListeners.value = {
        ...initialStateSignals.firestoreListeners.value,
        [listenerId]: newValue || true
    }
}

const createAppState = () => {

    effect(() => {
        if(initialStateSignals.workspaceUsers.value){
            const currentOperators: AppStateTypes['workspaceOperators'] = [];
            const currentPartners: AppStateTypes['workspacePartners'] = [];
            const currentOperatorsWithControllers: AppStateTypes['workspaceOperatorsWithControllers'] = [];
            const currentPartnersWithControllers: AppStateTypes['workspacePartnersWithControllers'] = [];
            Object.entries(initialStateSignals.workspaceUsers.value).forEach(([userId, user]) => {
                if(!user.disabled && ['operator', 'operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer'].includes(user.role)){
                    const currentUser = {...user, uid: userId, label: user.name, value: userId};
                    currentOperatorsWithControllers.push(currentUser);
                    if(['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer'].includes(user.role)){
                        currentOperators.push(currentUser);
                    }
                    if(['general partner', 'operator/manager', 'operator/admin', 'operator/developer'].includes(user.role) && !user.hideUserInPartnersList){
                        currentPartners.push(currentUser);
                        currentPartnersWithControllers.push(currentUser);
                    }
                    if(user.role === 'operator/controller'){
                        currentPartnersWithControllers.push(currentUser);
                    }
                }
            });
            initialStateSignals.workspaceOperators.value = currentOperators.sort(sortByKey('name'));
            initialStateSignals.workspacePartners.value = currentPartners.sort(sortByKey('name'));
            initialStateSignals.workspaceOperatorsWithControllers.value = currentOperatorsWithControllers.sort(sortByKey('name'));
            initialStateSignals.workspacePartnersWithControllers.value = currentPartnersWithControllers.sort(sortByKey('name'));
        } 
    });

    const getActiveUserIsGroupsManager = (
        groups: ProjectGroupSettingsGroups,
        userId: string
    ) => {
        let currentActiveUserIsGroupsManager: boolean = false;
        if(groups){
            const group_admins = groups['2'];
            if(group_admins){
                currentActiveUserIsGroupsManager = group_admins.users.includes(userId);
            }
            if(!currentActiveUserIsGroupsManager){
                const group_managers = groups['1'];
                if(group_managers){
                    currentActiveUserIsGroupsManager = group_managers.users.includes(userId);
                }
            }
        }
        initialStateSignals.activeUserIsGroupsManager.value = currentActiveUserIsGroupsManager;
        return currentActiveUserIsGroupsManager;
    };

    const getActiveUserGroups = (
        activeUser: AppStateTypes['activeUser'],
        groups: ProjectGroupSettingsGroups,
        activeUserIsOperator = false
    ) => {
        let currentActiveUserGroups: AppStateTypes['activeUserGroups'] = [];
        let currentActiveUserMainGroup = '';
        if(activeUser){
            if(activeUserIsOperator){
                currentActiveUserMainGroup = '1';
                for(const groupId in groups){
                    currentActiveUserGroups.push({...groups[groupId], id: groupId});
                }
                initialStateSignals.activeUserIsGroupsManager.value = true;
            } else if(getActiveUserIsGroupsManager(groups, activeUser.uid)){
                currentActiveUserMainGroup = '1';
                for(const groupId in groups){
                    if(groupId !== '2') currentActiveUserGroups.push({...groups[groupId], id: groupId});
                }
            } else {
                for(const groupId in groups){
                    if(!['2', '1'].includes(groupId) && groups[groupId].users.includes(activeUser.uid)){
                        currentActiveUserGroups.push({...groups[groupId], id: groupId});
                        if(!currentActiveUserMainGroup) currentActiveUserMainGroup = groupId;
                    }
                }
            }
        }
        initialStateSignals.activeUserGroups.value = currentActiveUserGroups;
        initialStateSignals.activeUserMainGroup.value = currentActiveUserMainGroup;
    };

    const getProjectGroups = (projectGroupSettings: AppStateTypes['projectGroupSettings']) => {
        let groups: ProjectGroupSettingsGroups = {};
        if(projectGroupSettings && projectGroupSettings.enabled){
            groups = projectGroupSettings.groups || {};

            if(groups){
                if(!groups['1']){
                    groups['1'] = { name: 'Gerentes', users: [] };
                }

                initialStateSignals.projectGroups.value = groups;
            }

        } else {
            initialStateSignals.activeUserIsGroupsManager.value = true;
        }
        getActiveUserGroups(initialStateSignals.activeUser.value, groups, activeUserIsOperator.value);
    };

    effect(() => {
        getProjectGroups(initialStateSignals.projectGroupSettings.value);
    });

    effect(() => { 
        if(initialStateSignals.workspaceDocumentsTemplates.value && initialStateSignals.projectDocumentSettings.value && initialStateSignals.workspaceTemplateLists.value){
            const currentProjectTemplates = getProjectTemplates(initialStateSignals.projectDocumentSettings.value.templates, initialStateSignals.workspaceTemplateLists.value);
            initialStateSignals.projectDocumentsTemplates.value = currentProjectTemplates;
            let currentProjectTemplateOptions: AppStateTypes['projectDocumentsTemplateOptions'] = [];
            currentProjectTemplates.forEach(templateId => {
                if(initialStateSignals.workspaceDocumentsTemplates.value){
                    const foundTemplate = initialStateSignals.workspaceDocumentsTemplates.value[templateId];
                    if(foundTemplate) currentProjectTemplateOptions!.push({
                        label: foundTemplate.name,
                        value: templateId,
                    })
                }
            });
            initialStateSignals.projectDocumentsTemplateOptions.value = currentProjectTemplateOptions;
        }
    });

    effect(() => {
        type RiskLevelNumbers = { one: number; two: number; three: number; four: number; }
        const riskLevels: RiskLevelNumbers = { one: 1, two: 2, three: 3, four: 4 };
        type RiskLevelStrings = keyof RiskLevelNumbers;
        let currentAuthorityRiskLevel = 0;
        if(initialStateSignals.activeUser.value && initialStateSignals.projectClearanceSettings.value){
            if(initialStateSignals.projectClearanceSettings.value?.enabled && initialStateSignals.projectClearanceSettings.value.approvals){
                (initialStateSignals.projectClearanceSettings.value.approvals.authorities || []).forEach((authority: { maximumRiskLevel: RiskLevelStrings; type: string; uid: string; }) => {
                    if(authority.type === 'user' && authority.uid === initialStateSignals.activeUser.value?.uid){
                        if(!currentAuthorityRiskLevel || riskLevels[authority.maximumRiskLevel] > currentAuthorityRiskLevel) currentAuthorityRiskLevel = riskLevels[authority.maximumRiskLevel];
                    } else if(authority.type === 'group'){
                        const foundGroup = initialStateSignals.activeUserGroups.value.find(activeUserGroup => activeUserGroup.id === authority.uid);
                        if(foundGroup && (!currentAuthorityRiskLevel || riskLevels[authority.maximumRiskLevel] > currentAuthorityRiskLevel)) currentAuthorityRiskLevel = riskLevels[authority.maximumRiskLevel];
                    }
                });
            }
        }
        initialStateSignals.activeUserClearanceAuthorityLevel.value = currentAuthorityRiskLevel || 0;
    });

    return initialStateSignals;
}

const createAppStateUtils = () => ({
    activeUserIsOperator,
    activeUserPendingJudicialCasesProgress,
    mappedCNAEs,
    mappedWorkspaceClients,
    mappedWorkspaceProjects,
    mappedWorkspaceDocumentTemplates,
    selectedClientProjects,
    selectedFolderIsClient,
    openDialog,
    resetState,
    setFirestoreListener
})

const AppStateProvider = ({ children }: { children: React.ReactNode }) => {
    const appStateCtxValue: AppStateCtxValueType = createAppState();
    const appStateCtxAPIValue: AppStateCtxAPIValueType = createAppStateUtils();

    return (
        <AppStateCtx.Provider value={appStateCtxValue}>
        <AppStateCtxAPI.Provider value={appStateCtxAPIValue}>
            {children}
        </AppStateCtxAPI.Provider>
        </AppStateCtx.Provider>
    );
};

const useAppStateCtx = () => useContext(AppStateCtx);
const useAppStateCtxUtils = () => useContext(AppStateCtxAPI);

export {
    AppStateProvider,
    useAppStateCtx,
    useAppStateCtxUtils
};