import React from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { Avatar, Box, Grid, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useAppStateCtx } from '../context/AppState';

const classes = {
    root: {
        padding: '8px',
        backgroundColor: '#f5f6fa',
        marginBottom: '8px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
    },
    whiteIcon: {
        color: theme => theme.palette.primary.main,
        backgroundColor: theme => theme.palette.background.paper,
    }
};

const SidenavMobileTop = ({closeSidenavMobile, account}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();

    return (
        <Box sx={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={4}>
                    <Avatar sx={classes.whiteIcon} onClick={closeSidenavMobile}>
                        <KeyboardBackspaceIcon />
                    </Avatar>
                </Grid>
                <Grid item xs={4} container justifyContent="center">
                    <Grid item>
                        <Box mt={2}>
                            {account}
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <Box style={{textAlign: 'center'}} mb={1}>
                <Typography variant="body1">{activeUser.value?.name}</Typography>
                <Typography variant="body2">{activeUser.value?.email}</Typography>
            </Box>
        </Box>
    );
};

export default SidenavMobileTop;