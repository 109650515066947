import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
// import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';

import DashboardBox from './DashboardBox';
import Tooltip from './Tooltip';
import ViewBox from './ViewBox';

import User from '../classes/User';
import { useAppStateCtx } from '../context/AppState';
import { useAppCtxAPI } from '../context/SystemContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import sendPasswordResetEmail from '../firebase/auth/sendPasswordResetEmail';

const classes = {
    image: {
        height: '100%',
        maxHeight: '220px',
        borderRadius: '50%',
        cursor: 'pointer'
    },
};

const Img = styled('img')(unstable_styleFunctionSx);

function Profile({children}){
    useSignals();
    const { activeUser } = useAppStateCtx();
    const { setLoading } = useAppCtxAPI();

    const triggerFileButton = () => {
        document.getElementById('avatarFile').click();
    };
    const handle_selectedFile = async () => {
        const filesInput = document.getElementById('avatarFile');
        if(filesInput){
            let files = filesInput.files;
            if(files.length !== 0){
                setLoading(true);
                const toastId = toast.loading(`Salvando a imagem...`);
                
                const res = await activeUser.value.updatePhoto({ filesList: files })
                setLoading(false);
                if(res.error){
                    return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
                }
                let updatedUser = {...activeUser.value};
                updatedUser.photoUrl = res.result;
                updatedUser = new User(updatedUser);
                activeUser.value = updatedUser;
                
                filesInput.value = '';
                toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
            }
        }
    };

    const handleChangePasswordButtonClick = async () => {
        const result = await sendPasswordResetEmail(activeUser.value.email);
        if(!result.error){
            toast(`Verifique o e-mail ${activeUser.value.email} e siga as instruções.`, { type: 'info' });
        }
    }

    return (
        <ViewBox>
            {
                activeUser.value &&
                <Container maxWidth="md">
                    <Box mb={2}>
                        <Typography variant="h2">Preferências</Typography>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DashboardBox p={3} style={{height: 'auto'}}>
                                    <Box mb={2}>
                                        <Box mb={2}>
                                            <Typography variant="h5" color="textSecondary">Dados gerais</Typography>
                                        </Box>
                                        <Grid item container direction="column" alignItems="center" justifyContent="center" style={{paddingTop: 8}}>
                                            {
                                                activeUser.value.photoUrl
                                                ?
                                                    <>
                                                        <Tooltip text="Alterar imagem"><Img src={activeUser.value.photoUrl} alt={activeUser.value.name} sx={classes.image} onClick={triggerFileButton} /></Tooltip>
                                                    </>
                                                :
                                                    <Box style={{cursor: 'pointer', textAlign: 'center'}}
                                                        onClick={triggerFileButton}
                                                    >
                                                        <Box mb={1}>
                                                            <Tooltip text="Escolher imagem"><ClearIcon fontSize="large" /></Tooltip>
                                                        </Box>
                                                        <Box mb={1}>
                                                            <Typography variant="body1"><em>Nenhuma imagem de perfil carregada. Clique aqui para escolher uma imagem.</em></Typography>
                                                        </Box>
                                                    </Box>
                                            }
                                            <input style={{display: 'none'}} id="avatarFile" type="file" accept="image/*" onChange={handle_selectedFile} />
                                        </Grid>
                                        <List dense={true}>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Nome" secondary={activeUser.value.name} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <EmailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="E-mail" secondary={<Link href={`mailto:${activeUser.value.email}`} underline="hover">{activeUser.value.email}</Link>} />
                                            </ListItem>
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <LockIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Senha" />
                                                <ListItemSecondaryAction>
                                                    <Tooltip text="Alterar a sua senha">
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="change password"
                                                            onClick={handleChangePasswordButtonClick}
                                                            size="large">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </Box>
                                    <Divider />
                                    {children}
                                </DashboardBox>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            }
        </ViewBox>
    );
}

export default Profile;