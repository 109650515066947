import { useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ButtonLink from '../../components/ButtonLink'; 
import ForgotPassword from './components/ForgotPassword';
import { useAppCtxAPI } from '../../context/SystemContext';
import { FIRM_NAME } from '../../utils/constants';

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        overflowX: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    box: {
        marginTop: ['16px', '64px'],
        marginBottom: ['8px', '32px'],
        borderRadius: '8px',
        padding: '8px',
        width: '264px',
    },
    poweredBy: {
        marginTop: '8px',
        marginBottom: {xs: '8px', md: 0},
        color: 'grey',
        textAlign: {xs: 'center', md: 'right'},
        position: {md: 'absolute'},
        right: {md: '8px'},
        bottom: {md: '8px'},
    },
    logo: {
        height: 160
    },
    loadingLogo: {
        '@keyframes loading': {
            '50%': {
                opacity: 0
            },
            '100%': {
                opacity: 1
            }
        },
        opacity: 1,
        animation: 'loading 2s infinite',
    },
};

const A = styled('a')(unstable_styleFunctionSx);
const Img = styled('img')(unstable_styleFunctionSx);

function Start(){
    const { signIn } = useAppCtxAPI();
    const [loadingPage, setLoadingPage] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [passwordFieldType, set_passwordFieldType] = useState('password');
    const [forgotPasswordDialogOpen, set_forgotPasswordDialogOpen] = useState(false);
    const [forgotPasswordDialogErrorMessage, set_forgotPasswordDialogErrorMessage] = useState('');
    const systemOnline = true;

    const handleViewPasswordClick = () => {
        set_passwordFieldType(passwordFieldType === 'password' ? 'text' : 'password');
    };
    function handleSubmit(e){
        if(e.key === 'Enter') handleLogin();
    }
    async function handleLogin(){
        const alwaysAllowed = ['stefanofalcao@gmail.com'];
        if(!systemOnline && !alwaysAllowed.includes(email)){
            setErrorMessage('O sistema está em manutenção. Tente novamente mais tarde.');
        } else {
            setLoadingPage(true);
            setTimeout(async () => {
                try {
                    await signIn(email, password);
                } catch (err) {
                    let currentErrorMessage = '';
                    if(err.code === 'auth/invalid-credential'){
                        currentErrorMessage = 'E-mail e/ou senha inválidos.';
                    } else if(err.code === 'auth/too-many-requests'){
                        currentErrorMessage = 'O acesso a essa conta foi temporariamente suspenso após várias tentativas de acesso com falha. Você pode restaurar o acesso se resetar sua senha ou pode tentar novamente mais tarde';
                    }
                    setErrorMessage(currentErrorMessage || err.message);
                    setLoadingPage(false);
                }
            }, 200);
        }
    }
    const handleForgotPasswordClick = () => {
        set_forgotPasswordDialogErrorMessage('');
        set_forgotPasswordDialogOpen(true);
    };
    
    return (
        <Box sx={classes.root}>
            <Box sx={classes.box}>
                <Box style={{textAlign: 'center'}} mt={2} mb={1}>
                    <Img
                        src={'/SOLIDA-500.png'}
                        sx={[classes.logo, loadingPage && classes.loadingLogo]}
                    />
                </Box>
                <Collapse in={!loadingPage}>
                    <form name="login" style={{paddingTop: 16}}>
                        <Box mb={1}>
                            <TextField
                                fullWidth autoFocus
                                variant="outlined"
                                InputProps={{
                                    style: {
                                        backgroundColor: '#e8f0fe',
                                        borderRadius: 24,
                                        fontSize: 14
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                        
                                    )
                                }}
                                placeholder="E-mail"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                onKeyPress={handleSubmit}
                                disabled={loadingPage}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    style: {
                                        backgroundColor: '#e8f0fe',
                                        borderRadius: 24,
                                        fontSize: 14
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <IconButton size="small" onClick={handleViewPasswordClick} disabled={loadingPage}>
                                            {passwordFieldType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    )
                                }}
                                placeholder="Senha"
                                type={passwordFieldType}
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                onKeyPress={handleSubmit}
                                disabled={loadingPage}
                            />
                        </Box>

                        <Box mb={2}>
                            <Alert variant="outlined" color="info"><Typography variant="body1">Atenção! O SOLIDA recebeu uma atualização. Você terá que recuperar sua senha para entrar. Clique no botão "Esqueceu sua senha?".</Typography></Alert>
                        </Box>

                        <Box style={{textAlign: 'center'}} mb={2}>
                            <Button
                                variant="contained" color="primary"
                                disabled={loadingPage}
                                onClick={handleLogin}
                            >Entrar</Button>
                            {
                                errorMessage &&
                                <FormHelperText error>
                                    {errorMessage}
                                </FormHelperText>
                            }
                        </Box>

                        <Box mb={2} style={{textAlign: 'center'}}>
                            <ButtonLink onClick={handleForgotPasswordClick}>
                                <Typography variant="body2">Esqueceu sua senha?</Typography>
                            </ButtonLink>
                        </Box>
                    </form>
                </Collapse>
            </Box>

            <ForgotPassword
                open={forgotPasswordDialogOpen} setOpen={set_forgotPasswordDialogOpen}
                errorMessage={forgotPasswordDialogErrorMessage} setErrorMessage={set_forgotPasswordDialogErrorMessage}
            />

            <Box sx={classes.poweredBy}>
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>Desenvolvido por</Typography>
                <A href="http://dnrf.com.br/" target="_blank"><img src={'https://firebasestorage.googleapis.com/v0/b/solida-juridico.appspot.com/o/workspaces%2F60d73efec059310794fea79d%2Fimages%2Fdnrf.png?alt=media&token=a49b0015-7f97-41b0-8a58-e62c4c75ab39'} alt={FIRM_NAME} height={40} /></A>
            </Box>
        </Box>
    );
}

export default Start;