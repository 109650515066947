import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { Signal } from '@preact/signals-react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { useAppStateCtx } from '../../context/AppState';
import callFunction from '../../firebase/functions/callFunction';

const DocumentFormAIGeneratedSuggestionInput = ({
    aiGeneratedSuggestionKey,
    generateAISuggestionTriggered,
    generatingAISuggestion,
    question,
    mainValue,
    setQuestionValue
}: {
    aiGeneratedSuggestionKey: string;
    generateAISuggestionTriggered: Signal<boolean>;
    generatingAISuggestion: Signal<boolean>;
    question: { [key: string]: any; };
    mainValue: string;
    setQuestionValue: (args: any) => void;
}) => {
    useSignals();
    const {
        projectMisc,
        selectedDocumentForm,
        selectedFolder,
    } = useAppStateCtx();
    const [value, setValue] = useState('');

    useEffect(() => {
        return () => {
            generateAISuggestionTriggered.value = false;
            generatingAISuggestion.value = false;
        }
    }, []);

    const getAISuggestionFromFormText = async () => {
        let projectDescription = '';
        if(projectMisc.value && question.AISuggestionCustomFieldId){
            projectDescription = projectMisc.value[question.AISuggestionCustomFieldId] || '';
        }
        console.log('Calling GCF...');
        const res = await callFunction('getAISuggestionFromFormText', { 
            AISuggestionAdditionalInstructions: question.AISuggestionAdditionalInstructions,
            AISuggestionOutputFormat: question.AISuggestionOutputFormat,
            questionName: question.name,
            projectName: selectedFolder.value?.name || '',
            projectDescription,
            prompt: mainValue,
            shouldGetAISuggestionFromText: true,
            templateName: selectedDocumentForm.value?.name
        })
        generatingAISuggestion.value = false;
        if(res.error){
            console.error('ERROR CALLING "getAISuggestionFromFormText":', res.error);
            return generateAISuggestionTriggered.value = false;
        }
        //@ts-ignore
        const newValue = res.result!.trim();
        setValue(newValue);
        setQuestionValue((prevState: any) => ({...prevState, [aiGeneratedSuggestionKey || 'aiGeneratedSuggestion']: newValue}));
    }

    useEffect(() => {
        if(generateAISuggestionTriggered.value){
            generatingAISuggestion.value = true;
            getAISuggestionFromFormText();
        }
    }, [generateAISuggestionTriggered.value]);

    const handleChange = (newValue: string) => {
        setValue(newValue);
    }

    const handleAIGeneratedSuggestionInputBlur = () => {
        setQuestionValue((prevState: any) => ({...prevState, [aiGeneratedSuggestionKey || 'aiGeneratedSuggestion']: value}));
    }
    
    if(question.shouldGetAISuggestionFromText && !question.shouldHideAISuggestionInput && generateAISuggestionTriggered.value && mainValue){
        return (
            <Box mt={1}>
                {
                    generatingAISuggestion.value
                    ?
                    <Grid container spacing={1} alignItems="center">
                        <Grid>
                            <CircularProgress size={20}/>
                        </Grid>
                        <Grid size="grow">
                            {/* <Typography variant="body1">Gerando sugestão com Inteligência Artificial...</Typography> */}
                            <Typography variant="body1">Processando resposta...</Typography>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid container spacing={1} alignItems="center">
                            <Grid>
                                <AutoAwesomeIcon fontSize="small" />
                            </Grid>
                            <Grid size="grow">
                                <Typography variant="body2">Este texto foi gerado por Inteligência Artificial e deve ser revisado atentamente e ajustado conforme necessário para assegurar que atenda aos requisitos do documento solicitado. Caso não queira indicar um detalhamento, exclua o texto abaixo.</Typography>
                            </Grid>
                        </Grid>
                        <TextField
                            variant="standard"
                            fullWidth
                            multiline
                            placeholder="Sua resposta"
                            disabled={generatingAISuggestion.value}
                            value={value}
                            onChange={(e) => handleChange(e.target.value)}
                            onBlur={handleAIGeneratedSuggestionInputBlur}
                        />
                    </>
                }
            </Box>
        );
    }
    return null;
}

export default DocumentFormAIGeneratedSuggestionInput;