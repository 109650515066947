import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import DescriptionIcon from '@mui/icons-material/Description';
import ClearanceItemRiskDescriptionText from '../ClearanceItemRiskDescriptionText';
import MultilineText from '../MultilineText';
import NoResults from '../NoResults';
import RiskLevel from './RiskLevel';
import TinyTable from '../TinyTable';
import Tooltip from '../Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { sortClearanceReportNotes } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS } from '../../utils/constants';
import { useAppStateCtx } from '../../context/AppState';

const ClearanceItemsList = ({
    activeUserIsOperator,
    clearanceItems,
    handleCommentsButtonClick,
    handleClearanceItemBoxClick,
    hideCommentsIconButton,
    projectClearanceSettings,
    selectedReport
}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    let tableHead = [
        { content: '#', width: '5%', justify: 'center' }
    ];
    if(activeUserIsOperator){
        tableHead.push({ content: 'Pessoa', justify: 'center', width: '5%' });
    }
    tableHead = [
        ...tableHead,
        { content: selectedReport ? (selectedReport?.type === 'script' ? 'Cena' : 'TC') : 'Relatório', justify: 'center', width: '8%' },
        { content: 'Descrição', width: '17%' },
        { content: 'Direito', width: '10%' },
        { content: 'Risco', width: '10%', style: { width: 100 } },
        
    ];
    if(projectClearanceSettings?.show2FieldsForClearanceNoteRecommendation){
        tableHead.push({ content: 'Justificativa' });
        tableHead.push({ content: 'Orientações', width: '15%' });
    } else {
        tableHead.push({ content: 'Orientações' });
    }
    tableHead.push({ content: '' });

    if(clearanceItems && selectedReport){

        if(clearanceItems.length === 0){
            return (
                <NoResults text="Nenhum item no relatório." />
            );
        }

        return (
            <TinyTable
                head={tableHead}
                body={
                    clearanceItems
                    .sort(sortClearanceReportNotes(selectedReport))
                    .map((item, clearanceNoteIndex) => {
                        let columns = [];
                        
                        let riskOption = CLEARANCE_RISK_OPTIONS[0];
                        
                        riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === item.riskLevel);
                        
                        columns = [
                            { content: <Typography variant="body1"><strong>{clearanceNoteIndex + 1}</strong></Typography>, justify: 'center' }
                        ];
                        if(activeUserIsOperator){
                            const itemUser = workspaceUsers.value ? workspaceUsers.value[item.createdBy] : {};
                            columns.push({
                                content: (
                                    <Tooltip text={itemUser?.name}>
                                        <Avatar
                                            sx={{
                                                width: '24px',
                                                height: '24px',
                                            }}
                                            alt={itemUser?.name}
                                            src={itemUser?.photoUrl}
    
                                        />
                                    </Tooltip>
                                ),
                                justify: 'center'
                            });
                        }
                        if(selectedReport){
                            if(selectedReport.type === 'script'){
                                columns.push({ content: <Typography variant="body2"><span style={{color: item?.scriptScene ? 'inherit' : 'error'}}>{item?.scriptScene}</span></Typography> });    
                            } else {
                                columns.push({
                                    content: 
                                        <Box style={{textAlign: 'center'}}>
                                            {
                                                item.screenShotFileUrl &&
                                                <Box style={{textAlign: 'center'}}>
                                                    <img
                                                        src={item.screenShotFileUrl}
                                                        alt={item.description}
                                                        style={{height: 50}}
                                                    />
                                                </Box>
                                            }
                                            <Typography variant="body2"><span style={{color: item?.cutTimeCode ? 'inherit' : 'error'}}>{item?.cutTimeCode}</span></Typography>
                                        </Box>,
                                    justify: 'center'
                                });
                            }
                        } else {
                            columns.push({ content: <Typography variant="body2">{item.reportDescription}</Typography> });
                        }
                        columns = [
                            ...columns,
                            {
                                content:
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid size="grow">
                                            <Typography variant="body2" color={!item.description ? 'error' : 'inherit'}>{item.description || 'Sem descrição'}</Typography>
                                        </Grid>
                                    </Grid>
                            },
                            { content: <Typography variant="body2">{item.rightTypeDescription}</Typography> },
                        ];
                        // Risco
                        const currentRiskLevel = item.riskLevel;
                        let levelApprovalsRequired = 0;
                        let noteApprovals = 0;
                        if(!activeUserIsOperator){
                            const approvalsRequired = projectClearanceSettings?.approvals?.numberOfApprovalsRequired;
                            levelApprovalsRequired = (approvalsRequired || {})[currentRiskLevel] || 0;
                            const approvals = item.approvals;
                            let approvalsGiven = 0, approvalsDenied = 0;
                            for(const userId in approvals){
                                const type = approvals[userId];
                                if(type === 'yes'){
                                    approvalsGiven++;
                                } else if(type === 'no'){
                                    approvalsDenied++;
                                }
                            }
                            noteApprovals = approvalsGiven - approvalsDenied;
                        }
                        columns.push({
                            content:
                                item.requiresAdditionalInformation
                                ?
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid>
                                        <FontAwesomeIcon icon={faCircle} color={CLEARANCE_RISK_OPTIONS[2].rgbaColor} />
                                    </Grid>
                                    <Grid size="grow">
                                        <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.7)'}}>Aguardando informações</Typography>
                                    </Grid>
                                </Grid>
                                :
                                item.licensed
                                ?
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid>
                                        <FontAwesomeIcon icon={faCircle} color={CLEARANCE_RISK_OPTIONS[0].rgbaColor} />
                                    </Grid>
                                    <Grid size="grow">
                                        <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.7)'}}>Licença adquirida</Typography>
                                    </Grid>
                                    <Grid>
                                        {
                                            item.licenseLink
                                            ?
                                            <IconButton size="small" href={item.licenseLink} target="_blank"><DescriptionIcon /></IconButton>
                                            : <DescriptionIcon style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                                        }
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid size="grow">
                                        <RiskLevel riskLevel={riskOption} />
                                    </Grid>
                                    {
                                        levelApprovalsRequired > 0 &&
                                        <Grid>
                                            {
                                                item.approved
                                                ? <ThumbUpIcon color="primary" />
                                                :
                                                noteApprovals < 0
                                                ? <ThumbDownIcon color="secondary" />
                                                : noteApprovals === 0
                                                ? null
                                                : noteApprovals >= levelApprovalsRequired
                                                ? <ThumbUpIcon color="primary" />
                                                : <ThumbUpAltOutlinedIcon color="disabled" />
                                            }
                                        </Grid>
                                    }
                                </Grid>
                        });

                        if(projectClearanceSettings?.show2FieldsForClearanceNoteRecommendation){
                            columns.push({
                                content:
                                    <ClearanceItemRiskDescriptionText {...{ clearanceItem: item }} />
                            });
    
                            columns.push({ content: <Typography variant="body2"><MultilineText text={item?.legalRecommendation} /></Typography> });
                        } else {
                            let recommendation = item.riskDescription || '';
                            if(item.legalRecommendation) recommendation += recommendation ? `\n\n${item.legalRecommendation}` : item.legalRecommendation;
                            columns.push({ content: <Typography variant="body2"><MultilineText text={recommendation} /></Typography> });
                        }

                        columns.push({ content: !hideCommentsIconButton ? <Badge color={item.awaitingOperatorReview ? 'secondary' : 'default'} badgeContent={item.commentsCount}><IconButton size="small" onClick={(e) => handleCommentsButtonClick(e, item)}><CommentIcon /></IconButton></Badge> : '' });
    
                        return ({
                            data: item,
                            columns
                        })
                    })
                }
                handleBodyRow={handleClearanceItemBoxClick}
            />
        );

    }

    return null;
};

export default ClearanceItemsList;