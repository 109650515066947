import { useSignals } from '@preact/signals-react/runtime';

import UsersSelector from '../../../../../components/UsersSelector';

const PendingDocumentReviewerSelector = ({filterOperatorId}) => {
    useSignals();
    
    const setSelectedUser = (newValue) => {
        filterOperatorId.value = newValue;
    };

    return (
        <UsersSelector includeNone label="Revisor" selectedUser={filterOperatorId.value} setSelectedUser={setSelectedUser} />
    );
}

export default PendingDocumentReviewerSelector;