import { useRef } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import Chat from '../../pages/Operator/Home/Chat';
import PopOver from '../PopOver';
import { useAppStateCtx } from '../../context/AppState';

const classes = {
    container: {
        width: [`${300}px`, `${600}px`],
    }
};

const MessagesWindow = () => {
    useSignals();
    const { chatViewOpen, numberOfUnreadChatMessages } = useAppStateCtx();
    const messageFabRef = useRef();

    const handleOpen = () => {
        chatViewOpen.value = true;
    }
    
    const handleClose = () => {
        chatViewOpen.value = false;
    };

    return (
        <>
            <PopOver
                open={chatViewOpen.value}
                anchorEl={messageFabRef.current}
                onClose={handleClose}
                anchorOrigin={{vertical: 'center', horizontal: 'center'}}
                transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
                firstBoxSx={classes.container}
                hideCloseButton
            >
                <Chat />
            </PopOver>

            <Fab
                ref={messageFabRef}
                color="primary"
                style={{
                    visibility: !!numberOfUnreadChatMessages.value || chatViewOpen.value ? 'visible' : 'hidden',
                    position: 'fixed',
                    bottom: 4,
                    right: 4,
                    zIndex: 9999
                }}
                onClick={handleOpen}
            >
                <Badge badgeContent={numberOfUnreadChatMessages.value} color="secondary">
                    <ChatIcon />
                </Badge>
            </Fab>

        </>
    );
};

export default MessagesWindow;