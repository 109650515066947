import { Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import Notifications from './Rooms/Notifications';
import { useAppContext } from '../context/SystemContext';
import { SIDENAV_WIDTH } from '../utils/constants';

const headerHeight = 50;

const classes = {
    root: {
        backgroundColor: theme => theme.palette.background.default,
        height: '100%',
        overflow: 'hidden'
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
        left: [0, SIDENAV_WIDTH],
        width: [`100%`, `calc(100% - ${SIDENAV_WIDTH}px)`]
    },
    content: {
        height: `calc(100% - ${headerHeight}px)`,
        flex: 1,
        overflowX: 'hidden',
    }
};

function Main({children, header, sidenav, mobileSidenav}){
    const theme = useTheme();
    const screenMDDownExclusive = useMediaQuery(theme.breakpoints.down('md'));

    const { mainRef } = useAppContext();

    return (
        <Grid container sx={classes.root}>
            {sidenav}
            <Grid item sx={classes.main}>
                {header}
                <Box id="main" sx={classes.content} ref={mainRef}>
                    <Routes>
                        {children}
                    </Routes>
                </Box>
            </Grid>
            
            {screenMDDownExclusive ? mobileSidenav : null}

            <Notifications /> 

        </Grid>
    );
}

export default Main;