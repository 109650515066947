import { collection, deleteField, doc, runTransaction } from 'firebase/firestore';

import { db } from '../firebase/config';

type _ChatMessage = {
    createdAt?: string;
    createdBy: string;
    deleted?: boolean;
    deletedAt?: string;
    deletedBy?: string;
    mentionedUserIds: string[];
    message: string;
    reactions?: {
        [reactionId: string]: {
            reactionAt: string;
            unifiedId: string;
        }
    };
    roomId: string;
    uid?: string;
    workspaceId: string;
}

export default class ChatMessage implements _ChatMessage {
    createdAt = '';
    createdBy = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    mentionedUserIds: _ChatMessage['mentionedUserIds'] = [];
    message = '';
    reactions: _ChatMessage['reactions'] = {};
    roomId = '';
    uid = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        mentionedUserIds,
        message,
        reactions,
        roomId,
        uid,
        workspaceId
    }: _ChatMessage){
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        this.deleted = !!deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(mentionedUserIds) this.mentionedUserIds = mentionedUserIds;
        if(message) this.message = message;
        if(reactions) this.reactions = reactions;
        if(roomId) this.roomId = roomId;
        if(uid) this.uid = uid;
        if(workspaceId) this.workspaceId = workspaceId;
    }

    async createMessage(activeUserName: string, workspaceName: string, plainTextMessage: string){
        let result = null, error = null;

        const messageRef = doc(collection(db, `chat_messages`));
        
        const {uid: _, ...newMessage} = this;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(messageRef, newMessage);
                if(newMessage.mentionedUserIds.length !== 0){
                    for(const mentionedUserId of newMessage.mentionedUserIds){
                        transaction.set(doc(collection(db, `users/${mentionedUserId}/notifications`)), {
                            createdAt: new Date().toISOString(),
                            description: `${activeUserName} mencionou você em uma mensagem no chat: "${plainTextMessage.length > 100 ? `${plainTextMessage.substring(0, 200)}...` : plainTextMessage}"`,
                            title: `${workspaceName} | Jurídico | Chat`,
                            type: 'message',
                            url: '',
                        });
                    }
                }
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async deleteMessage(){
        let result = null, error = null;

        const messageRef = doc(db, `chat_messages`, this.uid);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.delete(messageRef);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async react(unifiedId: string, activeUserId: string){
        let result = null, error = null;

        const noticeRef = doc(db, `chat_messages/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                if(unifiedId){
                    transaction.update(noticeRef, {
                        [`reactions.${activeUserId}`]: {
                            reactionAt: new Date().toISOString(),
                            unifiedId
                        }
                    });
                } else {
                    transaction.update(noticeRef, {
                        [`reactions.${activeUserId}`]: deleteField()
                    });
                }
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}