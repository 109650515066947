import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, CircularProgress, Grid } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CompanyField from './Documents/AddDocumentWindow/components/CompanyField';
import FolderField from './Documents/AddDocumentWindow/components/FolderField';
import GroupField from './Documents/AddDocumentWindow/components/GroupField';
import DocumentNameField from './Documents/AddDocumentWindow/DocumentNameField';
import DocumentUrlField from './Documents/AddDocumentWindow/DocumentUrlField';
import Dropzone from './Dropzone';
import Window from './Window';

import Document from '../classes/Document';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { useDocumentsCtxAddDocumentView } from '../context/DocumentsContext';
import useGetProjectGroupsSettings from '../hooks/useGetProjectGroupsSettings';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const AddDocumentWindow = ({actionCallback, activeUserIsOperator, open}) => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { mappedWorkspaceProjects } = useAppStateCtxUtils();
    const { addDocumentViewSelectedFolder: selectedFolder } = useDocumentsCtxAddDocumentView();
    const [loadingProject, setLoadingProject] = useState(false);
    const [saving, setSaving] = useState(false);
    const [folderOptions, setFolderOptions] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [groupOptions, setGroupOptions] = useState(null);
    const [uploadTypeUrl, setUploadTypeUrl] = useState(false);
    const defaultAddDocumentForm = {
        companyId: '',
        folderId: '',
        groupId: '',
        documentName: '',
        documentUrl: ''
    };
    const addDocumentForm = useRef(defaultAddDocumentForm);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
    const acceptedFilesRef = useRef([]);

    useEffect(() => {
        if(open.value){
            addDocumentForm.current = defaultAddDocumentForm;
            setSubmitButtonDisabled(true);
            if(selectedFolder){
                if(selectedFolder.clientId){
                    handleSelectedCompanyChange(selectedFolder.clientId);
                    handleSelectedFolderChange(selectedFolder.uid);
                } else {
                    handleSelectedCompanyChange(selectedFolder);
                    handleSelectedFolderChange('');
                }
            } else {
                clearForm();
            }
        }
    }, [open.value]);

    const retrievedProjectGroupSettings = useGetProjectGroupsSettings(true, selectedProjectId);
    useEffect(() => {
        if(!retrievedProjectGroupSettings.loading && selectedProjectId && retrievedProjectGroupSettings.data){
            let currentGroupOptions = null;
            if(retrievedProjectGroupSettings.data.enabled){
                let projectGroups = retrievedProjectGroupSettings.data.groups;
                currentGroupOptions = [];
                Object.entries(projectGroups).forEach(([groupId, group]) => {
                    if(groupId !== '&all' && groupId !== '&none') currentGroupOptions.push({value: groupId, label: group.name});
                })
            }
            setGroupOptions(currentGroupOptions);

            addDocumentForm.current.groupId = '';

            checkSubmitButtonDisabled();

            setLoadingProject(false);
        }
    }, [selectedProjectId, retrievedProjectGroupSettings]);

    useEffect(() => {
        checkSubmitButtonDisabled();
    }, [uploadTypeUrl]);

    const handleSelectedCompanyChange = (newValue) => {
        addDocumentForm.current.companyId = newValue;
        if(newValue){
            const companyFolders = mappedWorkspaceProjects.value.filter((project) => project.clientId === newValue);
            let newProjectOptions = companyFolders.map((project) => ({value: project.uid, label: project.name}));
            setFolderOptions(newProjectOptions);
        } else {
            setFolderOptions(null);
        }
        setGroupOptions(null);
        addDocumentForm.current.folderId = '';
        setSelectedProjectId('');
        addDocumentForm.current.groupId = '';
        
        checkSubmitButtonDisabled();
    };
    
    const handleSelectedFolderChange = async (newValue) => {
        addDocumentForm.current.folderId = newValue;
        setSelectedProjectId(newValue);
        addDocumentForm.current.groupId = '';
        setLoadingProject(true);
    };

    const handleSubmit = async () => {
        const { companyId, folderId, groupId, documentName, documentUrl } = addDocumentForm.current;
        
        setSaving(true);
        const toastId = toast.loading('Salvando...');

        const showError = (message) => {
            toast(message, { type: 'error' });
            setSaving(false);
        };

        const newDocument = new Document({
            clientId: companyId,
            createdBy: activeUser.value.uid,
            groupId: groupId !== 'none' ? groupId : '',
            name: documentName,
            operatorId: activeUser.value.uid,
            projectId: folderId !== 'none' ? folderId : '',
            templateId: 'review',
            workspaceId: selectedWorkspace.value.uid
        });
        let fileUrl, filesList;
        if(!uploadTypeUrl){
            if(acceptedFilesRef.current.length !== 0){
                filesList = acceptedFilesRef.current;
            } else {
                return showError('Escolha um arquivo');
            }
        } else {
            if(documentUrl){
                fileUrl = documentUrl;
            } else {
                return showError('Insira um link.');
            }
        }

        const res = await newDocument.createDocument({
            saveFormId: null,
            fileUrl,
            filesList,
            newVersionName: 'Arquivo',
            activeUserIsOperator,
        });
        setSaving(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: `Sucesso!${activeUserIsOperator ? ' O documento foi lançado nos contratos pendentes.' : ' O documento foi gravado.'}`, type: 'success' });

        clearForm();
        handleClose();
        
        if(actionCallback) actionCallback();
    }
    const clearForm = () => {
        addDocumentForm.current = defaultAddDocumentForm;
        setGroupOptions(null);
        setFolderOptions(null);
    };

    const checkSubmitButtonDisabled = () => {
        const { companyId, folderId, groupId, documentName, documentUrl } = addDocumentForm.current;
        if(!companyId || !folderId || (groupOptions && !groupId) || !documentName || (uploadTypeUrl && !documentUrl)){
            return setSubmitButtonDisabled(true);
        }
        setSubmitButtonDisabled(false);
    };

    const handleClose = () => {
        open.value = false;
    };

    return (
        <Window open={open.value} onClose={handleClose}
            title="Adicionar documento"
            handleSubmitButtonClick={handleSubmit}
            submitButtonDisabled={saving || submitButtonDisabled}
            submitButtonText="Confirmar"
        >
            <Box mb={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} container spacing={1}>
                        {
                            !selectedFolder &&
                            <>
                                <Grid item xs={12} sm={6} md={4}>
                                    <CompanyField addDocumentForm={addDocumentForm} handleSelectedCompanyChange={handleSelectedCompanyChange} />
                                </Grid>
                                {
                                    folderOptions &&
                                    <Grid item xs={12} sm={6} md={4} container spacing={1} alignItems="center">
                                        {
                                            loadingProject &&
                                            <Grid item>
                                                <CircularProgress size={20} />
                                            </Grid>
                                        }
                                        <Grid item xs>
                                            <FolderField addDocumentForm={addDocumentForm} folderOptions={folderOptions} handleSelectedFolderChange={handleSelectedFolderChange} />
                                        </Grid>
                                    </Grid>
                                }
                            </>
                        }
                        {
                            groupOptions &&
                            <Grid item xs={12} sm={6} md={4}>
                                <GroupField addDocumentForm={addDocumentForm} groupOptions={groupOptions} checkSubmitButtonDisabled={checkSubmitButtonDisabled} />
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <DocumentNameField addDocumentForm={addDocumentForm} checkSubmitButtonDisabled={checkSubmitButtonDisabled} />
                    </Grid>
                    <Grid item xs={12} container spacing={1} justifyContent="center">
                        <Grid item>
                            <Button
                                variant={uploadTypeUrl ? 'outlined' : 'text'}
                                color={uploadTypeUrl ? 'primary' : 'inherit'}
                                startIcon={<LinkIcon />}
                                onClick={() => setUploadTypeUrl(!uploadTypeUrl)}
                            >Link</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={uploadTypeUrl ? 'text' : 'outlined'}
                                color={uploadTypeUrl ? 'inherit' : 'primary'}
                                startIcon={<AttachFileIcon />}
                                onClick={() => setUploadTypeUrl(!uploadTypeUrl)}
                            >Arquivo</Button>
                        </Grid>
                    </Grid>
                    {
                        uploadTypeUrl
                        ?
                        <Grid item xs={12}>
                            <DocumentUrlField addDocumentForm={addDocumentForm} checkSubmitButtonDisabled={checkSubmitButtonDisabled} />
                        </Grid>
                        :
                        <Grid item xs={12}>
                            <Dropzone
                                acceptedFilesRef={acceptedFilesRef}
                                accept={{
                                    'application/pdf': ['.pdf'],
                                    'application/msword': ['.doc'],
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
        </Window>
    );
};

export default AddDocumentWindow;