import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AddIcon from '@mui/icons-material/Add';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
// import BusinessIcon from '@mui/icons-material/Business';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
// import CallIcon from '@mui/icons-material/Call';
// import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import GavelIcon from '@mui/icons-material/Gavel';
// import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ListIcon from '@mui/icons-material/List';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import MovieIcon from '@mui/icons-material/Movie';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
// import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartPie,
    faCoins,
    faChartLine,
    faFileInvoiceDollar,
    faGavel,
    faGlobeAmericas,
    // faHandHoldingUsd,
    // faLandmark,
    faPiggyBank,
    // faRegistered,
    faTrophy,
    // faWallet,
} from '@fortawesome/free-solid-svg-icons';
// import { FIRST_CLIENT_ID } from '../../../utils/constants';
import { useAppStateCtx } from '../../../context/AppState';
import { useAppCtxSidenavOpen } from '../../../context/SystemContext';
import { useDocumentsCtxPendingDocumentsCount } from '../../../context/DocumentsContext';
import { useOperatorCtxAPI, useOperatorCtxCounters, useOperatorCtxMobileSidenav } from '../../../pages/Operator/context/OperatorContext';

const mobileSidenavFinanceMenuOpen = signal(false);

const useMenuItems = () => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const sidenavOpen = useAppCtxSidenavOpen()
    const { pendingDocumentsCount } = useDocumentsCtxPendingDocumentsCount();
    const { setState: setOperatorCtxState } = useOperatorCtxAPI();
    const { pendingClearanceCount } = useOperatorCtxCounters();
    const { mobileSidenavDocumentsMenuOpen, mobileSidenavFoldersMenuOpen, mobileSidenavProcessosMenuOpen, mobileSidenavTasksMenuOpen } = useOperatorCtxMobileSidenav();

    const handleCloseMobileSidenav = () => {
        setOperatorCtxState('sidenavMobileOpen', false);
    };

    const setMobileSidenavDocumentsMenuOpen = (newValue) => {
        setOperatorCtxState('mobileSidenavDocumentsMenuOpen', newValue);
    };

    const setMobileSidenavFoldersMenuOpen = (newValue) => {
        setOperatorCtxState('mobileSidenavFoldersMenuOpen', newValue);
    };

    const setMobileSidenavProcessosMenuOpen = (newValue) => {
        setOperatorCtxState('mobileSidenavProcessosMenuOpen', newValue);
    };

    const setMobileSidenavTasksMenuOpen = (newValue) => {
        setOperatorCtxState('mobileSidenavTasksMenuOpen', newValue);
    };

    const setMobileSidenavFinanceMenuOpen = (newValue) => {
        mobileSidenavFinanceMenuOpen.value = newValue;
    }

    const workspaceShortName = `${selectedWorkspace.value?.shortName}/`;

    const iconColor = !sidenavOpen ? '#b2bfdc' : undefined;

    const accountingRoutes = [
        {
            path: `/${workspaceShortName}juridico/financeiro/propostas`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/propostas\/?$`),
            icon: <FontAwesomeIcon icon={faFileInvoiceDollar} size="2x" />, label: 'Propostas'
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/nova-proposta`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/nova-proposta\/?$`),
            icon: <NoteAddIcon />, label: 'Nova proposta',
            visible: ['operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/planejamento-mensal`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/planejamento-mensal\/?$`),
            icon: <FontAwesomeIcon icon={faPiggyBank} size="2x" />, label: 'Planejamento mensal'
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/despesas`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/despesas\/?$`),
            icon: <FontAwesomeIcon icon={faCoins} size="2x" />, label: 'Despesas',
            visible: ['operator/controller', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/ranking`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/ranking\/?$`),
            icon: <FontAwesomeIcon icon={faTrophy} size="2x" />, label: 'Ranking'
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/estados`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/ranking\/?$`),
            icon: <FontAwesomeIcon icon={faGlobeAmericas} size="2x" />, label: 'Estados'
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/areas`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/ranking\/?$`),
            icon: <FontAwesomeIcon icon={faChartPie} size="2x" />, label: 'Áreas'
        },
        {
            path: `/${workspaceShortName}juridico/financeiro/composicao`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/composicao\/?$`),
            icon: <FontAwesomeIcon icon={faChartLine} size="2x" />, label: 'Composição financeira',
            visible: [
                // 'operator/controller', 'general partner', 'operator/manager', 'operator/admin',
                'operator/developer', 'developer'
            ].includes(selectedWorkspace.value?.role)
        }
    ];
    // if(selectedWorkspace.value?.uid === FIRST_CLIENT_ID || selectedWorkspace.value?.modules?.includes('dnrf-dl')){
    //     accountingRoutes.push({
    //         view: 'accounting-profits', path: `/${workspaceShortName}juridico/financeiro/lucros`, regExp: new RegExp(`${workspaceShortName}juridico\/financeiro\/lucros\/?$`),
    //         icon: <FontAwesomeIcon icon={faWallet} size="2x" />, label: 'Distribuição de lucros',
    //         visible: ['operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
    //     });
    // }

    const menuItems = [
        {
            label: 'Painel',
            path: `/${workspaceShortName}juridico`,
            highlights: [
                'juridico\/?$'
            ],
            icon: <DashboardIcon htmlColor={iconColor} />,
            mobileIcon: <DashboardOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role),
        },
        {
            label: 'Tarefas',
            path: `/${workspaceShortName}juridico/demandas/geral`,
            highlight: [
                { path: `/${workspaceShortName}juridico/demandas/geral`, mobileMenuItem: true, label: 'Minhas tarefas', visible: true },
                { path: `/${workspaceShortName}juridico/tarefas/equipe`, mobileMenuItem: true, label: 'Tarefas da equipe', visible: ['general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role) },
                { path: `/${workspaceShortName}juridico/atendimento`, mobileMenuItem: true, label: 'Projetos ativos', visible: true },
                { path: `/${workspaceShortName}juridico/cronograma`, mobileMenuItem: true, label: 'Linha do tempo', visible: true },
                { path: `/${workspaceShortName}juridico/timesheet`, mobileMenuItem: true, label: 'Timesheet', visible: selectedWorkspace.value?.modules?.includes('timesheet') }
            ],
            highlights: [
                'juridico\/demandas',
                'juridico\/tarefas',
                'juridico\/atendimento',
                'juridico\/cronograma',
                'juridico\/timesheet'
            ],
            routes: [
                {
                    path: `/${workspaceShortName}juridico/demandas/geral`, regExp: new RegExp(`${workspaceShortName}juridico\/demandas\/geral\/?$`),
                    icon: <AssignmentTurnedInOutlinedIcon />, label: `Minhas tarefas`
                },
                {
                    path: `/${workspaceShortName}juridico/tarefas/equipe`, regExp: new RegExp(`${workspaceShortName}juridico\/tarefas\/equipe\/?$`),
                    icon: <AssignmentReturnedOutlinedIcon />, label: `Tarefas da equipe`,
                    visible: ['general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
                },
                {
                    path: `/${workspaceShortName}juridico/atendimento`, regExp: new RegExp(`${workspaceShortName}juridico\/atendimento\/?$`),
                    icon: <ViewWeekOutlinedIcon />, label: `Projetos ativos`
                },
                {
                    path: `/${workspaceShortName}juridico/cronograma`, regExp: new RegExp(`${workspaceShortName}juridico\/cronograma\/?$`),
                    icon: <ViewTimelineOutlinedIcon />, label: `Linha do tempo`
                },
                {
                    path: `/${workspaceShortName}juridico/timesheet`, regExp: new RegExp(`${workspaceShortName}juridico\/timesheet\/?$`),
                    icon: <AccessTimeOutlinedIcon />, label: `Timesheet`,
                    visible: selectedWorkspace.value?.modules?.includes('timesheet')
                },
            ],
            mobileMenuOpen: mobileSidenavTasksMenuOpen,
            setMobileMenuOpen: setMobileSidenavTasksMenuOpen,
            icon: <AssignmentTurnedInIcon htmlColor={iconColor} />,
            mobileIcon: <AssignmentTurnedInOutlinedIcon />,
            onClickAdditionalAction: () => {
                setOperatorCtxState('timesheetSelectedUser', activeUser.value?.uid);
                setOperatorCtxState('timesheetSelectedDate', moment());
            },
            didNavigateAction: () => {
                handleCloseMobileSidenav();
                setOperatorCtxState('timesheetSelectedUser', activeUser.value?.uid);
                setOperatorCtxState('timesheetSelectedDate', moment());
            },
            didNavigateAction: handleCloseMobileSidenav,
            visible: selectedWorkspace.value?.modules?.includes('tasks') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role),
        },
        {
            label: 'Contratos',
            path: `/${workspaceShortName}juridico/documentos/pendentes`,
            highlight: [
                { path: `/${workspaceShortName}juridico/documentos/pendentes`, mobileMenuItem: true, label: 'Contratos em aberto', badgeValue: pendingDocumentsCount, didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/documentos/pesquisar`, mobileMenuItem: true, label: 'Pesquisa', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/documentos/formulario`, mobileMenuItem: true, label: 'Formulário', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/documentos/listas`, mobileMenuItem: true, label: 'Listas', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/documentos/matrizes`, mobileMenuItem: true, label: 'Matrizes', didNavigateAction: handleCloseMobileSidenav, visible: selectedWorkspace.value?.modules?.includes('templates') },
            ],
            highlights: [
                'juridico\/documentos'
            ],
            routes: [
                {
                    view: `docs-pending`, path: `/${workspaceShortName}juridico/documentos/pendentes`, regExp: new RegExp(`${workspaceShortName}juridico\/documentos\/pendentes\/?$`),
                    icon: <InsertDriveFileOutlinedIcon />, label: `Contratos em aberto`
                },
                {
                    view: `docs-search`, path: `/${workspaceShortName}juridico/documentos/pesquisar`, regExp: new RegExp(`${workspaceShortName}juridico\/documentos\/pesquisar\/?$`),
                    icon: <FindInPageOutlinedIcon />, label: `Pesquisa`
                },
                {
                    view: `docs-forms`, path: `/${workspaceShortName}juridico/documentos/formulario`, regExp: new RegExp(`${workspaceShortName}juridico\/documentos\/formulario\/?$`),
                    icon: <CreateOutlinedIcon />, label: `Formulário`
                },
                {
                    view: `lists`, path: `/${workspaceShortName}juridico/documentos/listas`, regExp: new RegExp(`${workspaceShortName}juridico\/documentos\/listas\/?$`),
                    icon: <ListIcon />, label: `Listas`
                },
                {
                    view: `templates`, path: `/${workspaceShortName}juridico/documentos/matrizes`, regExp: new RegExp(`${workspaceShortName}juridico\/documentos\/matrizes\/?$`),
                    icon: <SettingsOutlinedIcon />, label: `Matrizes`,
                    highlight: [
                        `/${workspaceShortName}juridico/documentos/matrizes/nova`,
                        `/${workspaceShortName}juridico/documentos/matrizes/documento`,
                        `/${workspaceShortName}juridico/documentos/matrizes/formulario`,
                        `/${workspaceShortName}juridico/documentos/matrizes/titulo`,
                        `/${workspaceShortName}juridico/documentos/matrizes/listas`
                    ],
                    visible: selectedWorkspace.value?.modules?.includes('templates')
                }
            ],
            mobileMenuOpen: mobileSidenavDocumentsMenuOpen,
            setMobileMenuOpen: setMobileSidenavDocumentsMenuOpen,
            icon: <DescriptionIcon htmlColor={iconColor} />,
            mobileIcon: <DescriptionOutlinedIcon />,
            badgeId: 'docs-count',
            badgeValue: pendingDocumentsCount,
            visible: selectedWorkspace.value?.modules?.includes('documents') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            label: 'Clearance',
            path: `/${workspaceShortName}juridico/clearance/consultas`,
            highlight: [
                { path: `/${workspaceShortName}juridico/clearance/relatorios` },
                { path: `/${workspaceShortName}juridico/clearance/matrizes` },
            ],
            highlights: [
                'juridico\/clearance'
            ],
            routes: [
                {
                    path: `/${workspaceShortName}juridico/clearance/consultas`, regExp: new RegExp(`${workspaceShortName}juridico\/clearance\/consultas\/?$`),
                    icon: <QuestionAnswerOutlinedIcon />, label: `Consultas`
                },
                {
                    path: `/${workspaceShortName}juridico/clearance/relatorios`, regExp: new RegExp(`${workspaceShortName}juridico\/clearance\/relatorios\/?$`),
                    icon: <MovieCreationOutlinedIcon />, label: `Relatórios`
                },
                // {
                //     path: `/${workspaceShortName}juridico/clearance/roteiro`, regExp: new RegExp(`${workspaceShortName}juridico\/clearance\/roteiro\/?$`),
                //     icon: <MovieCreationOutlinedIcon />, label: `Roteiro`
                // },
                {
                    path: `/${workspaceShortName}juridico/clearance/matrizes`, regExp: new RegExp(`${workspaceShortName}juridico\/clearance\/matrizes\/?$`),
                    icon: <SettingsOutlinedIcon />, label: `Matrizes`
                }
            ],
            icon: <MovieIcon htmlColor={iconColor} />,
            mobileIcon: <MovieOutlinedIcon />,
            badgeId: 'clearance-count',
            badgeValue: pendingClearanceCount,
            didNavigateAction: handleCloseMobileSidenav,
            visible: selectedWorkspace.value?.modules?.includes('clearance') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            label: 'Processos',
            path: `/${workspaceShortName}juridico/processos/judicial/andamentos`,
            highlight: [
                { path: `/${workspaceShortName}juridico/processos/judicial/andamentos`, mobileMenuItem: true, label: 'Ações judiciais', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/processos/judicial/consultar` },
                { path: `/${workspaceShortName}juridico/processos/judicial/todos` },
                { path: `/${workspaceShortName}juridico/processos/judicial/acompanhamento` },
                { path: `/${workspaceShortName}juridico/processos/administrativos/andamentos` },
                { path: `/${workspaceShortName}juridico/processos/administrativos/consultar` },
                { path: `/${workspaceShortName}juridico/processos/administrativos/todos` },
                { path: `/${workspaceShortName}juridico/processos/administrativos/acompanhamento` },
                { path: `/${workspaceShortName}juridico/processos/inpi/andamentos`, mobileMenuItem: true, label: 'INPI', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/processos/inpi/lista` },
                { path: `/${workspaceShortName}juridico/processos/inpi/acompanhamento` }
            ],
            highlights: [
                'juridico\/processos'
            ],
            routes: [
                {
                    view: `lawsuits-progress`, path: `/${workspaceShortName}juridico/processos/judicial/andamentos`, regExp: new RegExp(`${workspaceShortName}juridico\/processos\/judicial\/andamentos\/?$`),
                    highlight: [
                        `/${workspaceShortName}juridico/processos/judicial/consultar`,
                        `/${workspaceShortName}juridico/processos/judicial/todos`,
                        `/${workspaceShortName}juridico/processos/judicial/acompanhamento`
                    ],
                    icon: <FontAwesomeIcon icon={faGavel} size="2x" />, label: `Ações judiciais`
                },
                // {
                //     view: `inpi`, path: `/${workspaceShortName}juridico/processos/administrativos/andamentos`, regExp: new RegExp(`${workspaceShortName}juridico\/processos\/administrativos\/andamentos\/?$`),
                //     highlight: [
                //         `/${workspaceShortName}juridico/processos/administrativos/consultar`,
                //         `/${workspaceShortName}juridico/processos/administrativos/todos`,
                //         `/${workspaceShortName}juridico/processos/administrativos/acompanhamento`
                //     ],
                //     icon: <FontAwesomeIcon icon={faLandmark} size="2x" />, label: `Processos administrativos`
                // },
                // {
                //     view: `inpi`, path: `/${workspaceShortName}juridico/processos/inpi/andamentos`, regExp: new RegExp(`${workspaceShortName}juridico\/processos\/inpi\/andamentos\/?$`),
                //     highlight: [
                //         `/${workspaceShortName}juridico/processos/inpi/lista`,
                //         `/${workspaceShortName}juridico/processos/inpi/acompanhamento`
                //     ],
                //     icon: <FontAwesomeIcon icon={faRegistered} size="2x" />, label: `INPI`
                // }
            ],
            mobileMenuOpen: mobileSidenavProcessosMenuOpen,
            setMobileMenuOpen: setMobileSidenavProcessosMenuOpen,
            icon: <GavelIcon htmlColor={iconColor} />,
            mobileIcon: <VisibilityOutlinedIcon />,
            visible: selectedWorkspace.value?.modules?.includes('lawsuits') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            label: 'Relatórios',
            path: selectedWorkspace.value?.modules?.includes('timesheet') ? `/${workspaceShortName}juridico/relatorios/clientes` : `/${workspaceShortName}juridico/relatorios/advogados`,
            highlight: [
                { path: `/${workspaceShortName}juridico/relatorios/equipe` },
                { path: `/${workspaceShortName}juridico/relatorios/advogados` },
                { path: `/${workspaceShortName}juridico/relatorios/empresa` }
            ],
            highlights: [
                'juridico\/relatorios'
            ],
            routes: [
                {
                    view: `reports-clients`, path: `/${workspaceShortName}juridico/relatorios/clientes`, regExp: new RegExp(`${workspaceShortName}juridico\/relatorios\/clientes\/?$`),
                    icon: <FolderOutlinedIcon />, label: `Projetos`,
                    visible: selectedWorkspace.value?.modules?.includes('timesheet')
                },
                // {
                //     view: `reports-operators`, path: `/${workspaceShortName}juridico/relatorios/advogados`, regExp: new RegExp(`${workspaceShortName}juridico\/relatorios\/advogados\/?$`),
                //     icon: <PersonOutlineIcon />, label: `Advogado(a)`,
                //     visible: ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
                // },
                // {
                //     view: `reports-team`, path: `/${workspaceShortName}juridico/relatorios/equipe`, regExp: new RegExp(`${workspaceShortName}juridico\/relatorios\/equipe\/?$`),
                //     icon: <GroupOutlinedIcon />, label: `Equipe`,
                //     visible: ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
                // },
                // {
                //     path: `/${workspaceShortName}juridico/relatorios/empresa`, regExp: new RegExp(`${workspaceShortName}juridico\/relatorios\/empresa\/?$`),
                //     icon: <BusinessIcon />, label: `Empresa`,
                //     visible: ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
                // }
            ],
            icon: <AssessmentIcon htmlColor={iconColor} />,
            mobileIcon: <AssessmentOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: selectedWorkspace.value?.modules?.includes('documents') || selectedWorkspace.value?.modules?.includes('timesheet')
        },
        {
            label: 'Financeiro',
            path: `/${workspaceShortName}juridico/financeiro/propostas`,
            highlight: [
                { path: `/${workspaceShortName}juridico/financeiro/nova-proposta`, mobileMenuItem: true, label: 'Nova proposta', visible: true },
                { path: `/${workspaceShortName}juridico/financeiro/planejamento-mensal`, mobileMenuItem: true, label: 'Planejamento mensal', visible: true },
                { path: `/${workspaceShortName}juridico/financeiro/despesas`, mobileMenuItem: true, label: 'Despesas', visible: ['operator/controller', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role) },
                { path: `/${workspaceShortName}juridico/financeiro/ranking`, mobileMenuItem: true, label: 'Ranking', visible: true },
                { path: `/${workspaceShortName}juridico/financeiro/estados`, mobileMenuItem: true, label: 'Estados', visible: true },
                { path: `/${workspaceShortName}juridico/financeiro/areas`, mobileMenuItem: true, label: 'Áreas', visible: true },
                {
                    path: `/${workspaceShortName}juridico/financeiro/composicao`, mobileMenuItem: true, label: 'Composição financeira',
                    visible: [
                        // 'operator/controller', 'general partner', 'operator/manager', 'operator/admin',
                        'operator/developer', 'developer'
                    ].includes(selectedWorkspace.value?.role)
                },
                // { path: `/${workspaceShortName}juridico/financeiro/lucros` }
            ],
            highlights: [
                'juridico\/financeiro'
            ],
            routes: accountingRoutes,
            icon: <MonetizationOnIcon htmlColor={iconColor} />,
            mobileIcon: <AttachMoneyOutlinedIcon />,
            mobileMenuOpen: mobileSidenavFinanceMenuOpen.value,
            setMobileMenuOpen: setMobileSidenavFinanceMenuOpen,
            didNavigateAction: handleCloseMobileSidenav,
            visible: selectedWorkspace.value?.modules?.includes('accounting') && ['operator/controller', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            label: 'Projetos',
            path: `/${workspaceShortName}juridico/projetos/pastas`,
            highlight: [
                { path: `/${workspaceShortName}juridico/projetos/empresas`, mobileMenuItem: true, label: 'Clientes', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/projetos/pastas`, mobileMenuItem: true, label: 'Projetos', didNavigateAction: handleCloseMobileSidenav, visible: true },
                { path: `/${workspaceShortName}juridico/projetos/informacoes-especiais`, mobileMenuItem: true, label: 'Informações especiais', didNavigateAction: handleCloseMobileSidenav, visible: true }
            ],
            highlights: [
                'juridico\/projetos'
            ],
            routes: [
                // {
                //     path: `/${workspaceShortName}juridico/projetos/clientes`, regExp: new RegExp(`${workspaceShortName}juridico\/projetos\/clientes\/?$`),
                //     icon: <SettingsOutlinedIcon />, label: `Áreas de trabalho`,
                //     visible: ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
                // },
                {
                    view: `clients`, path: `/${workspaceShortName}juridico/projetos/empresas`, regExp: new RegExp(`${workspaceShortName}juridico\/projetos\/empresas\/?$`),
                    icon: <BusinessOutlinedIcon />, label: `Clientes`
                },
                {
                    view: `projects`, path: `/${workspaceShortName}juridico/projetos/pastas`, regExp: new RegExp(`${workspaceShortName}juridico\/projetos\/pastas\/?$`),
                    icon: <FolderOutlinedIcon />, label: `Projetos`
                },
                {
                    view: `projectsUserCreatedFields`, path: `/${workspaceShortName}juridico/projetos/informacoes-especiais`, regExp: new RegExp(`${workspaceShortName}juridico\/projetos\/informacoes-especiais\/?$`),
                    icon: <AddIcon />, label: `Informações especiais`,
                    visible: selectedWorkspace.value?.modules?.includes('templates')
                }
            ],
            mobileMenuOpen: mobileSidenavFoldersMenuOpen,
            setMobileMenuOpen: setMobileSidenavFoldersMenuOpen,
            icon: <FolderIcon htmlColor={iconColor} />,
            mobileIcon: <FolderOutlinedIcon />,
            visible: true
        },
        {
            label: 'Pessoas',
            path: `/${workspaceShortName}juridico/pessoas`,
            highlights: [
                'juridico\/pessoas'
            ],
            icon: <PeopleIcon htmlColor={iconColor} />,
            mobileIcon: <PeopleOutlinedIcon />,
            didNavigateAction: handleCloseMobileSidenav,
            visible: ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        // {
        //     label: 'Salas',
        //     path: `/${workspaceShortName}juridico/salas`,
        //     icon: <CallIcon htmlColor={iconColor} />,
        //     mobileIcon: <CallOutlinedIcon />,
        //     didNavigateAction: handleCloseMobileSidenav,
        //     visible: selectedWorkspace.value?.modules?.includes('meetings')
        // },
        {
            label: 'Avisos',
            path: `/${workspaceShortName}juridico/avisos`,
            icon: <CampaignIcon htmlColor={iconColor} />,
            mobileIcon: <CampaignOutlinedIcon />,
            visible: ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
        {
            label: 'Configurações',
            path: `/${workspaceShortName}juridico/config`,
            icon: <WorkspacesIcon htmlColor={iconColor} />,
            mobileIcon: <WorkspacesOutlinedIcon />,
            visible: ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role)
        },
    ];
    

    return menuItems;
};

export default useMenuItems;