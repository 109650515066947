import { collection, doc, getDoc, runTransaction, updateDoc } from 'firebase/firestore';

import DocumentsTemplate_MoreFile from './DocumentsTemplate_MoreFile';
import DocumentsTemplate_MoreForm from './DocumentsTemplate_MoreForm';
import DocumentsTemplate_MoreSettings from './DocumentsTemplate_MoreSettings';
import DocumentsTemplate_MoreTitle from './DocumentsTemplate_MoreTitle';

import { db } from '../firebase/config';

import { _DocumentsTemplate } from '../shared/types';

export default class DocumentsTemplate implements _DocumentsTemplate {
    createdAt = '';
    createdBy = '';
    description = '';
    disabled = false;
    disabledAt = '';
    disabledBy = '';
    name = '';
    updatedAt = '';
    updatedBy = '';
    uid = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        description,
        disabled,
        disabledAt,
        disabledBy,
        name,
        updatedAt,
        updatedBy,
        uid,
        workspaceId
    }: _DocumentsTemplate){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        if(description) this.description = description;
        this.disabled = disabled || false;
        this.disabledAt = disabledAt || '';
        this.disabledBy = disabledBy || '';
        this.name = name || '';
        this.updatedAt = updatedAt || this.createdAt;
        this.updatedBy = updatedBy || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async createDocumentTemplate(form: DocumentsTemplate_MoreForm['form'], fileContent: { effects: { [effectId: string]: any }[]; main: string; }){
        let error = null, result = null;

        const {uid: _, ...template} = this;

        const settings = new DocumentsTemplate_MoreSettings({});

        const title = new DocumentsTemplate_MoreTitle({ templateName: this.name });

        const templateRef = doc(collection(db, `documents_templates`));

        const file = new DocumentsTemplate_MoreFile({});
        const uploadFileRes = await file.uploadFile(this.workspaceId, templateRef.id, fileContent);
        if(uploadFileRes.error || !uploadFileRes.result){
            error = uploadFileRes.error;
        } else  {
            const fileSettings = {
                fileFirebaseStoragePath: uploadFileRes.result.fileFirebaseStoragePath,
                fileUrl: uploadFileRes.result.fileUrl
            };

            const templateFileRef = doc(db, `documents_templates/${templateRef.id}/_more/file`);
            const templateFormRef = doc(db, `documents_templates/${templateRef.id}/_more/form`);
            const templateSettingsRef = doc(db, `documents_templates/${templateRef.id}/_more/settings`);
            const templateTitleRef = doc(db, `documents_templates/${templateRef.id}/_more/title`);
            const workspaceDocumentsTemplatesRef = doc(db, `workspaces/${this.workspaceId}/_more/documents_templates`);
    
            try {
                await runTransaction(db, async (transaction) => {
                    transaction.set(templateRef, template);
                    transaction.set(templateFileRef, fileSettings);
                    transaction.set(templateFormRef, { form });
                    transaction.set(templateSettingsRef, {...settings});
                    transaction.set(templateTitleRef, {...title});
                    transaction.update(workspaceDocumentsTemplatesRef, {
                        [`documentsTemplates.${templateRef.id}`]: {
                            description: '',
                            disabled: false,
                            name: this.name,
                            updatedAt: this.updatedAt
                        }
                    })
                });
                result = templateRef.id;
            } catch (e) {
                error = e;
            }
        }

        return { error, result };
    }

    async disable(activeUserId: string, workspaceId: string){
        let result = null, error = null;
        
        const templateRef = doc(db, `documents_templates`, this.uid);
        const workspaceDocumentsTemplatesRef = doc(db, `workspaces/${workspaceId}/_more/documents_templates`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(templateRef, {
                    disabled: true,
                    disabledAt: new Date().toISOString(),
                    disabledBy: activeUserId
                });
                transaction.update(workspaceDocumentsTemplatesRef, {
                    [`documentsTemplates.${this.uid}.disabled`]: true
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getForm(){
        let result = null, error = null;
        
        const templateFormRef = doc(db, `documents_templates/${this.uid}/_more/form`);
        
        try {
            const snapshot = await getDoc(templateFormRef);
            const data = snapshot.data();
            if(data?.form) result = data.form;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async getTitlePattern(){
        let result = null, error = null;
        
        const templateFormRef = doc(db, `documents_templates/${this.uid}/_more/title`);
        
        try {
            const snapshot = await getDoc(templateFormRef);
            const data = snapshot.data();
            if(data?.title) result = data.title;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ settings, updates, workspaceId }: {
        settings: {
            averageMinutesRequiredForDocumentReview: number;
            bypassDocumentOperatorReview: boolean;
            bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText: {
                bypass: boolean;
                delay?: number;
                exceptions?: any;
            };
            checkCNPJ: boolean;
            createDocumentAsPdf: boolean;
            includeLogo: boolean;
        },
        updates: { description: string; name: string; };
        workspaceId: string
    }){
        let result = null, error = null;
        
        const templateRef = doc(db, `documents_templates/${this.uid}`);
        const templateSettingsRef = doc(db, `documents_templates/${this.uid}/_more/settings`);
        const workspaceDocumentsTemplatesRef = doc(db, `workspaces/${workspaceId}/_more/documents_templates`);
        
        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(templateRef, updates);
                const workspaceDocumentsTemplateUpdates: { [key: string]: any } = {};
                for(const key in updates){
                    if(key === 'description' || key === 'name') workspaceDocumentsTemplateUpdates[`documentsTemplates.${this.uid}.${key}`] = updates[key];
                }
                transaction.update(workspaceDocumentsTemplatesRef, workspaceDocumentsTemplateUpdates);

                transaction.update(templateSettingsRef, settings);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async updateTemplate(
        form: DocumentsTemplate_MoreForm['form'],
        fileContent: { effects: { [effectId: string]: any }[]; main: string; },
        lists: string[],
        workspaceId: string
    ){
        let error = null, result = null;

        const file = new DocumentsTemplate_MoreFile({});
        const uploadFileRes = await file.uploadFile(workspaceId, this.uid, {...fileContent, lists});
        if(uploadFileRes.error || !uploadFileRes.result){
            error = uploadFileRes.error;
        } else  {
            const fileSettings = {
                fileFirebaseStoragePath: uploadFileRes.result.fileFirebaseStoragePath,
                fileUrl: uploadFileRes.result.fileUrl
            };

            const templateRef = doc(db, `documents_templates`, this.uid);
            const templateFileRef = doc(db, `documents_templates/${this.uid}/_more/file`);
            const templateFormRef = doc(db, `documents_templates/${this.uid}/_more/form`);
            const workspaceDocumentsTemplatesRef = doc(db, `workspaces/${workspaceId}/_more/documents_templates`);
    
            const now = new Date();
            const nowISOString = now.toISOString();

            try {
                await runTransaction(db, async (transaction) => {
                    transaction.update(templateRef, {
                        updatedAt: nowISOString
                    });
                    transaction.set(templateFileRef, fileSettings);
                    transaction.set(templateFormRef, { form, lists });
                    transaction.update(workspaceDocumentsTemplatesRef, {
                        [`documentsTemplates.${this.uid}.updatedAt`]: nowISOString
                    });
                });
                result = this.uid;
            } catch (e) {
                console.error('ERROR:', e)
                error = e;
            }
        }

        return { error, result };
    }

    async updateTitle({ updatedTitlePattern }: {
        updatedTitlePattern: {[key: string]: any}[];
    }){
        let result = null, error = null;
        
        const documentsTemplateTitlePatternRef = doc(db, `documents_templates/${this.uid}/_more/title`);
        
        try {
            await updateDoc(documentsTemplateTitlePatternRef, { title: updatedTitlePattern });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}