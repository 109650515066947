import { createContext, useContext, useMemo, useReducer } from 'react';

const OperatorCompaniesCtxAPI = createContext();
const OperatorCompaniesCtxChangeCompanyView = createContext();
const OperatorCompaniesCtxCompanySignatureView = createContext();

const defaultState = {
    changeCompanyViewOpen: false,
    changeCompanyViewClickedCompany: null,
    changeCompanyViewFoundCompany: null,
    changeCompanyViewUpdatedAt: null,
    companySignatureViewOpen: false,
    companySignatureViewSelectedCompany: null,
    companySignatureViewUpdatedAt: null,
};

const reducer = (state, action) => {
    const { payload, type } = action;
    
    switch (type) {
        case 'HIDE CHANGE COMPANY VIEW':
            return { ...state, changeCompanyViewOpen: false, changeCompanyViewClickedCompany: null };
        case 'HIDE COMPANY SIGNATURE VIEW':
            return { ...state, companySignatureViewOpen: false, companySignatureViewSelectedCompany: null };
        case 'SET CHANGE COMPANY VIEW CLICKED COMPANY':
            return { ...state, changeCompanyViewClickedCompany: payload };
        case 'SET CHANGE COMPANY VIEW FOUND COMPANY':
            return { ...state, changeCompanyViewFoundCompany: payload, changeCompanyViewUpdatedAt: new Date() };
        case 'SET COMPANY SIGNATURE VIEW SELECTED COMPANY':
            return { ...state, companySignatureViewSelectedCompany: payload, companySignatureViewUpdatedAt: new Date() };
        case 'SET STATE':
            return { ...state, [payload.key]: payload.value };
        case 'SHOW ADD COMPANY VIEW':
            return { ...state, changeCompanyViewOpen: true, changeCompanyViewClickedCompany: null, changeCompanyViewFoundCompany: null };
        case 'SHOW CHANGE COMPANY VIEW':
            return { ...state, changeCompanyViewOpen: true, changeCompanyViewClickedCompany: payload.clickedCompany, changeCompanyViewFoundCompany: null };
        case 'SHOW COMPANY SIGNATURE VIEW':
            return { ...state, companySignatureViewOpen: true, companySignatureViewSelectedCompany: payload.selectedCompany };
        default: return state;
    }
    
};

const OperatorCompaniesProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, {...defaultState});
    const api = useMemo(() => {

        const addVisibleCompany = (payload) => {
            dispatch({type: 'ADD VISIBLE COMPANY', payload});
        };
        
        const hideChangeCompanyView = (payload) => {
            dispatch({type: 'HIDE CHANGE COMPANY VIEW', payload});
        };

        const hideCompanySignatureView = (payload) => {
            dispatch({type: 'HIDE COMPANY SIGNATURE VIEW', payload});
        };

        const setChangeCompanyViewClickedCompany = (clickedCompany) => {
            dispatch({type: 'SET CHANGE COMPANY VIEW CLICKED COMPANY', payload: clickedCompany});
        };

        const setChangeCompanyViewFoundCompany = (selectedCompany) => {
            dispatch({type: 'SET CHANGE COMPANY VIEW FOUND COMPANY', payload: selectedCompany});
        };

        const setCompanySignatureViewSelectedCompany = (selectedCompany) => {
            dispatch({type: 'SET COMPANY SIGNATURE VIEW SELECTED COMPANY', payload: selectedCompany});
        };

        const setState = (key, value) => {
            dispatch({ type: 'SET STATE', payload: { key, value } });
        };

        const showAddCompanyView = (payload) => {
            dispatch({type: 'SHOW ADD COMPANY VIEW', payload});
        };
        
        const showChangeCompanyView = (clickedCompany) => {
            dispatch({type: 'SHOW CHANGE COMPANY VIEW', payload: { clickedCompany }});
        };

        const showCompanySignatureView = (selectedCompany) => {
            dispatch({type: 'SHOW COMPANY SIGNATURE VIEW', payload: { selectedCompany }});
        };

        const updateVisibleCompany = (payload) => {
            dispatch({type: 'UPDATE VISIBLE COMPANY', payload});
        };

        return {
            dispatch,
            addVisibleCompany,
            hideChangeCompanyView,
            hideCompanySignatureView,
            setChangeCompanyViewClickedCompany,
            setChangeCompanyViewFoundCompany,
            setCompanySignatureViewSelectedCompany,
            setState,
            showAddCompanyView,
            showChangeCompanyView,
            showCompanySignatureView,
            updateVisibleCompany
        };
    }, []);

    const operatorCompaniesCtxChangeCompanyViewValue = useMemo(() => ({
        changeCompanyViewOpen: state.changeCompanyViewOpen,
        changeCompanyViewClickedCompany: state.changeCompanyViewClickedCompany,
        changeCompanyViewFoundCompany: state.changeCompanyViewFoundCompany,
        changeCompanyViewUpdatedAt: state.changeCompanyViewUpdatedAt
    }), [state.changeCompanyViewOpen, state.changeCompanyViewUpdatedAt]);

    const operatorCompaniesCtxCompanySignatureViewValue = useMemo(() => ({
        companySignatureViewOpen: state.companySignatureViewOpen,
        companySignatureViewSelectedCompany: state.companySignatureViewSelectedCompany,
    }), [state.companySignatureViewOpen, state.companySignatureViewUpdatedAt]);

    return (
        <OperatorCompaniesCtxAPI.Provider value={api}>
        <OperatorCompaniesCtxChangeCompanyView.Provider value={operatorCompaniesCtxChangeCompanyViewValue}>
        <OperatorCompaniesCtxCompanySignatureView.Provider value={operatorCompaniesCtxCompanySignatureViewValue}>
            {children}
        </OperatorCompaniesCtxCompanySignatureView.Provider>
        </OperatorCompaniesCtxChangeCompanyView.Provider>
        </OperatorCompaniesCtxAPI.Provider>
    );
};

const useOperatorCompaniesCtxAPI = () => useContext(OperatorCompaniesCtxAPI);
const useOperatorCompaniesCtxChangeCompanyView = () => useContext(OperatorCompaniesCtxChangeCompanyView);
const useOperatorCompaniesCtxCompanySignatureView = () => useContext(OperatorCompaniesCtxCompanySignatureView);

export {
    OperatorCompaniesProvider,
    useOperatorCompaniesCtxAPI,
    useOperatorCompaniesCtxChangeCompanyView,
    useOperatorCompaniesCtxCompanySignatureView,
};