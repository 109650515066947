import { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useForm } from '../../context/FormContext';

import FormQuestionList from '../../../../components/FormQuestionList';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';
import useGetWorkspaceListItems from '../../../../hooks/useGetWorkspaceListItems';
import { mapFormList } from '../../../../utils/common';

const ListQuestion = ({question, setQuestionValid}) => {
    useSignals();
    const { formFoundCNPJs, formLists, formQuestionNameToCNPJ } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { values } = useForm();
    const [selectedList, setSelectedList] = useState([]);
    const [foundCNPJActivities, setFoundCNPJActivities] = useState(null);
    const [CNPJActivityCodes, setCNPJActivityCodes] = useState([]);
    const [foundActivityCodeInCNPJ, setFoundActivityCodeInCNPJ] = useState(false);
    const [showCorporationIdActivityError, setShowCorporationIdActivityError] = useState(false);
    const [userAwareOfErrors, setUserAwareOfErrors] = useState(false);
    const [showMEIActivityError, setShowMEIActivityError] = useState(false);
    const [foundCNPJ, setFoundCNPJ] = useState(null);
    const additionalValidationData = useRef({});
    const { initialValue, userFormQuestion, setUserFormQuestion } = useDocumentFormQuestion({
        additionalValidationData,
        question,
        questionType: 'list',
        setQuestionValid
    });

    useEffect(() => {
        setFirestoreListener('workspaceLists');
    }, []);

    const retrievedWorkspaceListItems = useGetWorkspaceListItems(question.typeId, question.typeId);
    useEffect(() => {
        if(retrievedWorkspaceListItems.data){
            const mappedList = mapFormList(retrievedWorkspaceListItems.data[question.typeId]);
            setSelectedList(mappedList);
            formLists.value = {
                ...formLists.value,
                ...retrievedWorkspaceListItems.data
            }
        }
    }, [retrievedWorkspaceListItems]);

    useEffect(() => {
        const userNotAwareOfCorporationIdActivityError = showCorporationIdActivityError && !userAwareOfErrors;
        const userNotAwareOfMEIActivityError = showMEIActivityError && !userAwareOfErrors;
        additionalValidationData.current = {
            userNotAwareOfCorporationIdActivityError,
            userNotAwareOfMEIActivityError
        };
        setUserFormQuestion(prevState => ({...prevState}));
    }, [showCorporationIdActivityError, showMEIActivityError, userAwareOfErrors]);

    // "question.corporationIdQuestion" é a configuração que indica a pergunta de CNPJ à qual esta pergunta está vinculada
    // Quando o objeto questionNameToCNPJ é atualizado (o que acontece quando a consulta do CNPJ é realizada), a variável
    // foundCNPJ é atualizada com as informações da consulta
    useEffect(() => {
        if(question.corporationIdQuestion){
            setFoundCNPJ(formQuestionNameToCNPJ.value[question.corporationIdQuestion] || null);
        }
    }, [formQuestionNameToCNPJ.value]);

    const checkCNPJActivities = (currentCNPJActivities, currentSelectedListItem) => {
        let CNPJActivityCodes = null;
        let checkCorporationIdCorporationIdContainsActivityCode = false;
        let checkCorporationIdIsMEI = false;
        let checkCorporationIdMEIAllowedForDocumentActivity;
        if(currentCNPJActivities && currentSelectedListItem){
            CNPJActivityCodes = currentSelectedListItem.cnaes.map(code => parseInt(code.toString().replace(/\D+/g, '')));
            for(const activity of currentCNPJActivities){
                const foundCodeInCnaes = CNPJActivityCodes.find(code => code === activity.code);
                if(foundCodeInCnaes){
                    checkCorporationIdCorporationIdContainsActivityCode = true;
                }
            }
            const isMEI = foundCNPJ?.isMEI;
            checkCorporationIdIsMEI = isMEI;
            if(isMEI){
                checkCorporationIdMEIAllowedForDocumentActivity = !!currentSelectedListItem.meiAllowed;
            }
        }
        setCNPJActivityCodes(CNPJActivityCodes);
        setShowCorporationIdActivityError(CNPJActivityCodes && !checkCorporationIdCorporationIdContainsActivityCode);
        setShowMEIActivityError(checkCorporationIdIsMEI && !checkCorporationIdMEIAllowedForDocumentActivity);
        setFoundActivityCodeInCNPJ(checkCorporationIdCorporationIdContainsActivityCode);
        
        if(foundCNPJ && question.corporationIdQuestion){
            const userFormCNPJQuestion = values.current[question.corporationIdQuestion];
            if(userFormCNPJQuestion){
                const currentCNPJ = userFormCNPJQuestion.input;
                
                let nextState = {...formFoundCNPJs.value};
                if(!nextState.details) nextState.details = [];
                const prevCheckCorporationIdDetails = nextState.details;
                const questionId = question.id;
                const questionName = question.name;

                const listItemName = currentSelectedListItem?.key;
                const CNPJCodes = currentCNPJActivities;
                const listItemCodes = CNPJActivityCodes;
                const foundListItemCodeInCNPJ = checkCorporationIdCorporationIdContainsActivityCode;
                const foundCheckIfCNPJContainsListItemCodeQuestionIndex = prevCheckCorporationIdDetails.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF CNPJ CONTAINS LIST ITEM CODE');
                if(foundCheckIfCNPJContainsListItemCodeQuestionIndex !== -1){
                    nextState.details[foundCheckIfCNPJContainsListItemCodeQuestionIndex].listItemName = listItemName
                    nextState.details[foundCheckIfCNPJContainsListItemCodeQuestionIndex].listItemCodes = listItemCodes;
                    nextState.details[foundCheckIfCNPJContainsListItemCodeQuestionIndex].CNPJCodes = CNPJCodes;
                    nextState.details[foundCheckIfCNPJContainsListItemCodeQuestionIndex].foundListItemCodeInCNPJ = foundListItemCodeInCNPJ;
                } else {
                    nextState.details.push({
                        questionId,
                        questionName,
                        corporationIdQuestion: question.corporationIdQuestion,
                        action: 'CHECK IF CNPJ CONTAINS LIST ITEM CODE',
                        CNPJ: currentCNPJ,
                        listItemName,
                        listItemCodes,
                        CNPJCodes,
                        foundListItemCodeInCNPJ,
                    });
                }

                const isMEI = checkCorporationIdIsMEI;
                if(isMEI){
                    const MEIAllowsListItemCode = checkCorporationIdMEIAllowedForDocumentActivity;
                    const foundCheckIfMEIAllowsListItemCodeQuestionIndex = prevCheckCorporationIdDetails.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF MEI ALLOWS LIST ITEM CODE');
                    if(foundCheckIfMEIAllowsListItemCodeQuestionIndex !== -1){
                        nextState.details[foundCheckIfMEIAllowsListItemCodeQuestionIndex].listItemName = listItemName
                        nextState.details[foundCheckIfMEIAllowsListItemCodeQuestionIndex].listItemCodes = listItemCodes;
                        nextState.details[foundCheckIfMEIAllowsListItemCodeQuestionIndex].isMEI = isMEI;
                        nextState.details[foundCheckIfMEIAllowsListItemCodeQuestionIndex].MEIAllowsListItemCode = MEIAllowsListItemCode;
                    } else {
                        nextState.details.push({
                            questionId,
                            questionName,
                            corporationIdQuestion: question.corporationIdQuestion,
                            action: 'CHECK IF MEI ALLOWS LIST ITEM CODE',
                            listItemName,
                            listItemCodes,
                            isMEI,
                            MEIAllowsListItemCode
                        });
                    }
                }
                formFoundCNPJs.value = nextState;
            }
        }

        return { CNPJActivityCodes, checkCorporationIdCorporationIdContainsActivityCode, checkCorporationIdIsMEI, checkCorporationIdMEIAllowedForDocumentActivity };
    };
    
    useEffect(() => {
        let checkCorporationIdCorporationIdCodes = null;
        let CNPJ, CNPJQuestion;
        if(foundCNPJ){
            CNPJQuestion = question.corporationIdQuestion;
            CNPJ = foundCNPJ.cnpj;
            if(foundCNPJ.mainActivity){
                checkCorporationIdCorporationIdCodes = [
                    {
                        ...foundCNPJ.mainActivity,
                        code: foundCNPJ.mainActivity.id, description: foundCNPJ.mainActivity.text,
                        isMainActivity: true
                    }
                ];
                foundCNPJ.sideActivities.forEach(activity => {
                    checkCorporationIdCorporationIdCodes.push({
                        ...activity,
                        code: activity.id, description: activity.text
                    });
                });
            }
        }
        setFoundCNPJActivities(checkCorporationIdCorporationIdCodes);
        const selectedListItem = selectedList.find(listItem => listItem.key.toUpperCase() === userFormQuestion.input.toUpperCase());
        const { CNPJActivityCodes, checkCorporationIdCorporationIdContainsActivityCode, checkCorporationIdIsMEI, checkCorporationIdMEIAllowedForDocumentActivity } = checkCNPJActivities(checkCorporationIdCorporationIdCodes, selectedListItem);
        setUserFormQuestion(prevState => {
            let nextState = {
                ...prevState,
                activityAllowedForMEI: checkCorporationIdMEIAllowedForDocumentActivity,
                activityCodes: CNPJActivityCodes,
                CNPJCodes: checkCorporationIdCorporationIdCodes,
                CNPJIsMEI: checkCorporationIdIsMEI,
                CNPJQuestion,
                foundActivityCodeInCNPJ: checkCorporationIdCorporationIdContainsActivityCode,
            };
            if(CNPJQuestion) nextState.CNPJ = CNPJ;
            return nextState;
        });
    }, [question, foundCNPJ]);
    
    const handleChange = (questionName, updates) => {
        const newValue = {
            input: updates.input || updates.choice || '',
            choice: updates.choice || updates.input || '', //needed for logical branching
            listItemAliases: updates.listItemAliases || [],
            listItemId: updates.listItemId || '',
            listItemIsSelected: !!updates.choice,
            listItemDescription: updates.listItemDescription || '',
            listItemDescriptionChanged: !!updates.listItemDescriptionChanged
        };

        const { CNPJActivityCodes, checkCorporationIdCorporationIdContainsActivityCode, checkCorporationIdIsMEI, checkCorporationIdMEIAllowedForDocumentActivity } = checkCNPJActivities(foundCNPJActivities, updates.selectedListItem);

        setUserFormQuestion(prevState => ({
            ...prevState,
            ...newValue,
            activityCodes: CNPJActivityCodes,
            activityAllowedForMEI: checkCorporationIdMEIAllowedForDocumentActivity,
            CNPJIsMEI: checkCorporationIdIsMEI,
            foundActivityCodeInCNPJ: checkCorporationIdCorporationIdContainsActivityCode,
        }));
    };

    const handleUserAwareOfErrorsChange = (newValue) => {
        setUserAwareOfErrors(newValue);
    };
    
    return (
        <FormQuestionList
            activityAllowedForMEI={userFormQuestion?.activityAllowedForMEI}
            activityCodes={CNPJActivityCodes}
            CNPJContainsActivityCode={userFormQuestion?.foundActivityCodeInCNPJ}
            foundActivityCodeInCNPJ={foundActivityCodeInCNPJ}
            foundCNPJ={foundCNPJ} setFoundCNPJ={setFoundCNPJ}
            handleUserAwareOfErrorsChange={handleUserAwareOfErrorsChange}
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
            selectedList={selectedList}
            showCorporationIdActivityError={showCorporationIdActivityError}
            showMEIActivityError={showMEIActivityError}
            userAwareOfErrors={userAwareOfErrors}
        />
    );
}

export default ListQuestion;