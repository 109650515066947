import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import { Avatar, CircularProgress, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AttachmentIcon from '@mui/icons-material/Attachment';
import MovieIcon from '@mui/icons-material/Movie';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import NoResults from '../NoResults';
import RiskLevel from './RiskLevel';
import TinyTable from '../TinyTable';
import Tooltip from '../Tooltip';
import { useAppStateCtx } from '../../context/AppState';
import { getGroupNameById, getGroupsByFolderId, getUserImageById, getUserNameById } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS } from '../../utils/constants';

const classes = {
    smallAvatar: {
        width: '24px',
        height: '24px',
    }
};

const ClearanceQuestionsList = ({
    activeUserIsOperator,
    handleBodyRow, handleBodyRowContextMenu, handleOperatorMenuButtonPress, handleQuestionDoneButtonPress,
    list,
    projectClearanceSettings,
    projects,
    savingDone, selectedFolder
}) => {
    useSignals();
    const { activeUser, workspaceUsers } = useAppStateCtx();
    
    const tableHead = [
        { content: '#', justify: 'center' },
        { content: 'Em' },
        { content: 'Por' },
    ];
    if(activeUserIsOperator) tableHead.push({ content: 'Pasta' });
    if(!activeUserIsOperator && selectedFolder?.useGroups) tableHead.push({ content: 'Grupo' });
    tableHead.push({ content: 'Assunto' });
    tableHead.push({ content: 'Última resposta' });
    tableHead.push({ content: 'Situação' });
    tableHead.push({ content: 'Direito' });
    tableHead.push({ content: 'Condição' });
    tableHead.push({ content: 'Risco' });
    if(activeUserIsOperator) tableHead.push({ content: 'Responsável', justify: 'center' });

    if(list){
        if(list.length === 0){
            return (
                <NoResults text="Nenhuma consulta." />
            );
        }

        return (
            <TinyTable
                head={tableHead}
                body={
                    list
                    .sort((a, b) => {
                        if(activeUserIsOperator){
                            let aValue = a.createdAt;
                            if(a.lastComment) aValue = a.lastComment.createdAt;
                            let bValue = b.createdAt;
                            if(b.lastComment) bValue = b.lastComment.createdAt;
                            return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
                        }
                        return 0;
                    })
                    .map(clearanceQuestion => {
                        let groupId = clearanceQuestion.groupId;
                        let folderGroups = [];
                        let groupName = '';
                        if(projects && groupId){
                            folderGroups = getGroupsByFolderId(clearanceQuestion.folderId, projects);
                            groupName = getGroupNameById(groupId, folderGroups);
                        }
                        let lastDate = clearanceQuestion.createdAt;
                        const lastComment = clearanceQuestion.lastComment;
                        if(lastComment) lastDate = lastComment.createdAt;

                        let highlight = false;
                        let highlightColor = '';
                        if(activeUserIsOperator){
                            if(clearanceQuestion.awaitingOperatorReview){
                                const deliveryIsVeryLate = 24;
                                const deliveryIsLate = 12;
                                const thisMoment = moment();
                                const currentDayOfWeek = thisMoment.day();
                                let addHours = 0;
                                if(currentDayOfWeek === 5){
                                    addHours = thisMoment.hours();
                                } else if(currentDayOfWeek === 6){
                                    addHours = 24 + thisMoment.hours();
                                } else if(currentDayOfWeek === 1 || currentDayOfWeek === 2){
                                    addHours = 48;
                                }
                                if(thisMoment.diff(moment(lastDate), 'hours') >= (deliveryIsVeryLate + addHours)){
                                    highlight = true;
                                    highlightColor = '#ffcdd2';
                                } else if(thisMoment.diff(moment(lastDate), 'hours') >= (deliveryIsLate + addHours)){
                                    highlight = true;
                                    highlightColor = '#fff9c4';
                                }
                            }
                        } else {
                            if(activeUser.value){
                                const readAt = clearanceQuestion.commentsReadAt[activeUser.value.uid];
                                if(!readAt || moment(readAt).isBefore(moment(clearanceQuestion.lastComment?.createdAt || clearanceQuestion.createdAt))){
                                    highlight = true;
                                    highlightColor = '#fff9c4';
                                }
                            }
                        }

                        const lastMessage = lastComment?.comment ? (`${lastComment.comment.substring(0, 200)}${lastComment.comment.length > 200 ? '...' : ''}`) : '';
                        const rightTypeDescription = lastComment?.rightTypeDescription || '';
                        const useTypeDescription = lastComment?.useTypeDescription ? lastComment.useTypeDescription.replace(/^Risco.*?\s-\s/, '') : '';
                        
                        let currentRiskLevel = clearanceQuestion.riskLevel;
                        let riskOption = null;
                        if(currentRiskLevel){
                            riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === currentRiskLevel);
                        }

                        const columns = [
                            // Solicitação 
                            { content: <Typography variant="body1"><strong>{clearanceQuestion.referenceNumber}</strong></Typography>, justify: 'center' },
                            { content: <Typography variant="body2">{moment(clearanceQuestion.createdAt).format('D/M/YY H:mm:ss')}</Typography> },
                            { content: <Typography variant="body2">{clearanceQuestion.createdByName || getUserNameById(workspaceUsers.value, clearanceQuestion.createdBy)}</Typography> },
                        ];
                        // Pasta
                        if(activeUserIsOperator){
                            const foundProject = projects[clearanceQuestion.projectId];
                            columns.push({ content: <Typography variant="body2">{foundProject?.name || ''}</Typography> });
                        }
                        // Grupo
                        if(!activeUserIsOperator && selectedFolder?.useGroups){
                            columns.push({ content: <Typography variant="body2">{groupId ? groupName : ''}</Typography> });
                        }
                        // Assunto
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    {
                                        clearanceQuestion.isClearanceReportNote &&
                                        <Grid item>
                                            <MovieIcon />
                                        </Grid>
                                    }
                                    <Grid item xs>
                                        <Typography variant="body2">{clearanceQuestion.subject}</Typography>
                                    </Grid>
                                    {
                                        !!clearanceQuestion.filesCount &&
                                        <Grid item>
                                            <AttachmentIcon />
                                        </Grid>
                                    }
                                </Grid>
                        });
                        // Última resposta
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs>
                                        <Typography variant="body2">&ldquo;<em>{lastMessage}</em>&rdquo; ({lastComment?.createdByName || getUserNameById(workspaceUsers.value, lastComment?.createdBy)})</Typography>
                                    </Grid>
                                </Grid>,
                        });
                        // Situação
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid item xs>
                                        <Typography variant="body2">{clearanceQuestion.awaitingOperatorReview ? 'Aguardando retorno' : 'Consulta respondida'}</Typography>
                                    </Grid>
                                    {
                                        (activeUserIsOperator && clearanceQuestion.awaitingOperatorReview) &&
                                        <Grid item>
                                            {
                                                savingDone[clearanceQuestion.uid]
                                                ? <CircularProgress size={20} />
                                                : <IconButton size="small" onClick={(e) => handleQuestionDoneButtonPress(e, clearanceQuestion)}><AssignmentTurnedInIcon /></IconButton>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                        });
                        // Assunto
                        columns.push({ content: <Typography variant="body2">{rightTypeDescription}</Typography> });
                        // Condição
                        columns.push({ content: <Typography variant="body2">{useTypeDescription}</Typography> });
                        // Risco
                        let levelApprovalsRequired = 0;
                        let questionApprovals = 0;
                        const approvalsRequired = projectClearanceSettings?.approvals?.numberOfApprovalsRequired;
                        if(!activeUserIsOperator && currentRiskLevel && approvalsRequired){
                            levelApprovalsRequired = approvalsRequired[currentRiskLevel] || 0;
                            const approvals = clearanceQuestion.approvals;
                            let approvalsGiven = 0, approvalsDenied = 0;
                            for(const userId in approvals){
                                const type = approvals[userId];
                                if(type === 'yes'){
                                    approvalsGiven++;
                                } else if(type === 'no'){
                                    approvalsDenied++;
                                }
                            }
                            questionApprovals = approvalsGiven - approvalsDenied;
                        }
                        columns.push({
                            content:
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid item xs>
                                        <RiskLevel riskLevel={riskOption} />
                                    </Grid>
                                    {
                                        (currentRiskLevel && levelApprovalsRequired > 0) &&
                                        <Grid item>
                                            {
                                                clearanceQuestion.approved
                                                ? <ThumbUpIcon color="primary" />
                                                :
                                                questionApprovals < 0
                                                ? <ThumbDownIcon color="secondary" />
                                                : questionApprovals === 0
                                                ? null
                                                : questionApprovals >= levelApprovalsRequired
                                                ? <ThumbUpIcon color="primary" />
                                                : <ThumbUpAltOutlinedIcon color="disabled" />
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            
                        });
                        // Responsável
                        if(activeUserIsOperator){
                            columns.push({
                                content: clearanceQuestion.operatorId
                                    ?
                                        (
                                            clearanceQuestion.isClearanceReportNote
                                            ?
                                            <Tooltip text={clearanceQuestion.operatorName || getUserNameById(workspaceUsers.value, clearanceQuestion.operatorId)}>
                                                <Avatar sx={classes.smallAvatar} alt={clearanceQuestion.operatorName || getUserNameById(workspaceUsers.value, clearanceQuestion.operatorId)} src={clearanceQuestion.operatorImg || getUserImageById(workspaceUsers.value, clearanceQuestion.operatorId)} />
                                            </Tooltip>
                                            :
                                            <IconButton size="small" color="primary"
                                                onClick={(e) => handleOperatorMenuButtonPress(e, clearanceQuestion)}
                                            >
                                                <Tooltip text={clearanceQuestion.operatorName || getUserNameById(workspaceUsers.value, clearanceQuestion.operatorId)}>
                                                    <Avatar sx={classes.smallAvatar} alt={clearanceQuestion.operatorName || getUserNameById(workspaceUsers.value, clearanceQuestion.operatorId)} src={clearanceQuestion.operatorImg || getUserImageById(workspaceUsers.value, clearanceQuestion.operatorId)} />
                                                </Tooltip>
                                            </IconButton>
                                        )
                                    :
                                        <IconButton size="small" color="primary"
                                            onClick={(e) => handleOperatorMenuButtonPress(e, clearanceQuestion)}
                                        >
                                            <Avatar sx={classes.smallAvatar} alt={'Escolher revisor'} />
                                        </IconButton>,
                                justify: 'center'
                            });
                        }
                        return ({
                            data: clearanceQuestion,
                            columns,
                            highlight,
                            highlightColor
                        })
                    })
                }
                handleBodyRow={handleBodyRow}
                handleBodyRowContextMenu={handleBodyRowContextMenu}
                handleMoreOptionsShownOnMouseOverFab={handleBodyRowContextMenu}
            />
        );

    }

    return null;
};

export default ClearanceQuestionsList;