import { Draggable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ClearIcon from '@mui/icons-material/Clear';

const classes = {
    box: {
        padding: '8px',
        cursor: 'grab',
        borderRadius: 4,
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
        }
    }
};

const File = (props) => {
    const { file, index, removeFile } = props
        
    const draggingStyle = {
        backgroundColor: '#e8f5e9',
        border: `1px solid grey`
    };
    let backgroundColor = '#FFF5EE';
    let defaultBorder = '1px solid #E8E8E8';
    const boxStyle = {
        borderRadius: 3, position: 'relative', marginBottom: 4, border: defaultBorder, backgroundColor
    };

    return (
        <Draggable draggableId={file.path} index={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Box
                        sx={classes.box}
                        style={snapshot.isDragging ? {...boxStyle, ...draggingStyle} : boxStyle}

                    >
                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                            <Grid item xs>
                                <Link variant="body1" noWrap href={file.path} target="_blank">{file.fileName}</Link>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Excluir">
                                    <IconButton
                                        size="small"
                                        onClick={() => removeFile(file)}
                                    ><ClearIcon /></IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </Draggable>
    );
};

export default File;