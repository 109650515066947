import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid2';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CurrencyInput from '../pages/Operator/Accounting/components/CurrencyInput';
import { formatCurrency } from '../utils/common';
import numero from 'numero-por-extenso';

const ActorScheduleQuestion = (props) => {
    const { initialValue } = props;
    const questionOptions = [
        { value: 'details', label: 'Detalhar as diárias' },
        { value: 'tbd', label: 'A combinar' },
        { value: 'other', label: 'Outro' }
    ];
    const defaultValue = {
        schedule: 'details',
        preProduction: {
            specifyLimit: 'specify',
            limit: {
                value: 0,
                input: ''
            },
            specifyAdditionalServicesFee: 'specify',
            additionalServicesFee: {
                value: 0,
                input: ''
            },
        },
        principalPhotography: {
            specifyLimit: 'specify',
            limit: {
                value: 0,
                input: ''
            },
            specifyAdditionalServicesFee: 'specify',
            additionalServicesFee: {
                value: 0,
                input: ''
            },
        },
        postProduction: {
            specifyLimit: 'specify',
            limit: {
                value: 0,
                input: ''
            },
            specifyAdditionalServicesFee: 'specify',
            additionalServicesFee: {
                value: 0,
                input: ''
            },
        },
        other: ''
    };
    const [value, setValue] = useState(defaultValue);
    const specifyLimitOptions = [
        {value: 'none', label: 'Sem limite'},
        {value: 'specify', label: 'Especificar'},
    ];
    const specifyAdditionalServicesFeeOptions = [
        {value: 'none', label: 'Não se aplica'},
        {value: 'specify', label: 'Especificar'},
    ];

    useEffect(() => {
        setValue(initialValue ? {...defaultValue, ...initialValue} : defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange) props.onChange(props.question.name, value);
    }, [value]);
    
    const handleChange = (firstKey, secondKey, newValue) => {
        setValue(prevState => {
            const updatedState = {...prevState};
            if(secondKey === 'limit'){
                const typeSpecifics = 'numberAndInWords';
                let input = '';
                if(typeSpecifics === 'numberAndInWords'){
                    input = `${newValue} (${numero.porExtenso(newValue)})`;
                } else if(typeSpecifics === 'inWords'){
                    input = numero.porExtenso(newValue);
                } else {
                    input = newValue.toString();
                }
                newValue = {
                    value: newValue,
                    input
                };
            } else if(secondKey === 'additionalServicesFee'){
                const typeSpecifics = 'numberWordsCurrency';
                let input = '';
                if(typeSpecifics === 'numberWordsCurrency'){
                    input = `${formatCurrency(newValue)} (${numero.porExtenso(newValue / 100, numero.estilo.monetario)})`;
                } else if(typeSpecifics === 'numberCurrency'){
                    input = formatCurrency(newValue);
                } else {
                    input = newValue.toString().replace(/(\d+?)(\d{2})$/, '$1,$2');
                }
                newValue = {
                    value: newValue,
                    input
                };
            }
            if(secondKey){
                updatedState[firstKey] = {
                    ...updatedState[firstKey],
                    [secondKey]: newValue
                }
            } else {
                updatedState[firstKey] = newValue;
            }
            return updatedState;
        });
    };

    return (
        <Box
            onBlur={props.onBlur}
            onFocus={props.onFocus}
        >
            <RadioGroup
                value={value.schedule}
                onChange={(e) => handleChange('schedule', null, e.target.value)}
            >
                <Grid container spacing={1} alignItems="center">
                    {
                        questionOptions.map((option, optionIndex) => (
                            <Grid key={optionIndex}>
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </RadioGroup>
            {
                value.schedule === 'details' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <Box>
                        {
                            [
                                { key: 'preProduction', description: 'Limite de diárias de pré-produção (leituras de roteiros, ensaios, testes de figurino e outras atividades de preparação)' },
                                { key: 'principalPhotography', description: 'Limite de diárias de filmagem' },
                                { key: 'postProduction', description: 'Limite de diárias de pós-produção (sessões de dublagem e outras atividades)' }
                            ].map(item => (
                                <Grid key={item.key} container spacing={2} alignItems="center">
                                    <Grid size={{ xs: 12 }}>
                                        <Typography variant="body1" sx={{ fontWeight: 500 }}>&bull; {item.description}</Typography>
                                    </Grid>
                                    <Grid size={{ xs: 12, md: 6 }} container spacing={1} alignItems="center">
                                        <Grid>
                                            <RadioGroup
                                                value={value[item.key].specifyLimit}
                                                onChange={(e) => handleChange(item.key, 'specifyLimit', e.target.value)}
                                            >
                                                <Grid container spacing={1} alignItems="center">
                                                    {
                                                        specifyLimitOptions.map((option, optionIndex) => (
                                                            <Grid key={optionIndex}>
                                                                <FormControlLabel
                                                                    value={option.value}
                                                                    control={<Radio />}
                                                                    label={option.label}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </RadioGroup>
                                        </Grid>
                                        {
                                            value[item.key].specifyLimit === 'specify' &&
                                            <Grid size={{ xs: 12, md: 2 }}>
                                                <TextField
                                                    variant="standard"
                                                    fullWidth
                                                    placeholder="0"
                                                    type="number"
                                                    value={value[item.key].limit.value}
                                                    onChange={(e) => handleChange(item.key, 'limit', e.target.value)}
                                                />
                                            </Grid>
                                        }
                                    </Grid>
                                    {
                                        value[item.key].specifyLimit === 'specify' &&
                                        <Grid size={{ xs: 6 }} container spacing={1} alignItems="center">
                                            <Grid size={{ xs: 12 }}>
                                                <Typography variant="body1">- Valor da diária extra</Typography>
                                            </Grid>
                                            <Grid>
                                                <RadioGroup
                                                    value={value[item.key].specifyAdditionalServicesFee.value}
                                                    onChange={(e) => handleChange(item.key, 'specifyAdditionalServicesFee', e.target.value)}
                                                >
                                                    <Grid container spacing={1} alignItems="center">
                                                        {
                                                            specifyAdditionalServicesFeeOptions.map((option, optionIndex) => (
                                                                <Grid key={optionIndex}>
                                                                    <FormControlLabel
                                                                        value={option.value}
                                                                        control={<Radio />}
                                                                        label={option.label}
                                                                    />
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </RadioGroup>
                                            </Grid>
                                            {
                                                value[item.key].specifyAdditionalServicesFee === 'specify' &&
                                                <Grid size={{ xs: 12, md: 4 }}>
                                                    <CurrencyInput 
                                                        decimalPlaces={1}
                                                        currencySymbol="" decimalCharacter="," digitGroupSeparator="."
                                                        textAlign="right"
                                                        placeholder="0,00"
                                                        value={value[item.key].additionalServicesFee.value}
                                                        onChange={(e, newValue) => handleChange(item.key, 'additionalServicesFee', newValue)}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                            ))
                        }
                    </Box>
                </Box>
            }
            {
                value.schedule === 'other' &&
                <Box mt={1}>
                    <Box mb={1}>
                        <Divider />
                    </Box>
                    <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        placeholder="Sua resposta"
                        value={value.other}
                        onChange={(e) => handleChange('other', null, e.target.value)} />
                </Box>
            }
        </Box>
    );
}

export default ActorScheduleQuestion;