import { useRef, useState } from 'react';
import moment from 'moment';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SendIcon from '@mui/icons-material/Send';

import DataListItem from '../../../../../components/DataListItem';
import DocumentFilesList from '../../../../../components/DocumentFilesList';
import PendingDocLink from '../../../../../components/PendingDocLink';
import PendingDocumentComment from './PendingDocumentListItem/components/PendingDocumentComment';
import PendingDocumentInnerBox from './PendingDocumentListItem/components/PendingDocumentInnerBox';
import PendingDocumentListItemOperator from './PendingDocumentListItem/components/PendingDocumentListItemOperator';
import PendingDocumentListItemTop from './PendingDocumentListItem/components/PendingDocumentListItemTop';
import SignatureStatus from '../../../../../components/eSignature/SignatureStatus';
import UserSelectDocumentLabel from './PendingDocumentListItem/components/UserSelectDocumentLabel';
import { CNAEs } from '../../../../../utils/cnaes';

const classes = {
    detailedChecksContents: {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        border: '1px solid rgba(0, 0, 0, 0.08)',
        borderRadius: '8px',
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.08)',
        padding: '8px'
    },
    documentBox: {
        '&:hover .spinningIcon': {
            animation: theme => `spin 1000ms ${theme.transitions.easing.easeOut}`
        },
        '@keyframes spin': {
            '0%': {
                transform: 'rotate(0deg)'
            },
            '100%': {
                transform: 'rotate(360deg)'
            }
        },
    },
    clickable: {
        cursor: 'pointer',
    },
    selectedBoxUser: {
        position: 'absolute',
        top: 2,
        left: 2
    }
};

const CNPJCodes = ({question}) => (
    <Box>
        <Box>
            <strong>CNAEs do CNPJ: </strong>
        </Box>
        {
            question.CNPJCodes?.map((CNPJCode) => (
                <Box key={CNPJCode.code}>- {CNPJCode.code} ({CNPJCode.description})</Box>
            ))
        }
    </Box>
);

const CNPJActivityCodes = ({question}) => (
    <Box>
        <Box>
            <strong>CNAEs da atividade: </strong>
        </Box>
        {
            question.activityCodes?.map((activityCode) => {
                let description = '';
                const foundCode = CNAEs.find(cnae => cnae.code === activityCode);
                if(foundCode) description = foundCode.description;
                return (
                    <Box key={activityCode}>- {activityCode}{description ? ` (${description})` : ''}</Box>
                )
            })
        }
    </Box>
);

const ScheduledRelease = ({handleCancelScheduledSendButtonPress, pendingDocument}) => {
    const detailsAnchorEl = useRef(null);
    const [showDetails, setShowDetails] = useState(false);

    let foundErrors = false;
    const notes = [];
    const documentForm = JSON.parse(pendingDocument.form);
    Object.entries(documentForm)
    .sort((a, b) => a[1].id > b[1].id ? 1 : a[1].id < b[1].id ? -1 : 0)
    .forEach(([questionId, question]) => {
        if(question.type === 'corporationId'){
            let success = false;
            let description = '';
            if(question.foundCNPJ === 'active'){
                success = true;
            } else if(question.foundCNPJ || question.foundCNPJ === 'false'){
                description = `O CNPJ indicado na pergunta "${questionId}" não foi encontrado.`;
            } else if(question.foundCNPJ === 'inactive'){
                description = `O CNPJ indicado na pergunta "${questionId}" está inativo.`;
            }
            if(description){
                notes.push({...question, description, success});
            }
        }
        if(question.type === 'text' && question.assignedField === 'consentingSignatoryName' && question.CNPJ){ // INTERVENIENTE
            let success = false;
            if(question.retrievedFromCorporationId || question.foundLegalRepresentativeInCNPJ){
                success = true;
            } else {
                foundErrors = true;
            }
            let description = <>O(A) profissional {question.input} indicado(a) na pergunta "{questionId}" {success ? '' : 'NÃO '}foi encontrado(a) {question.CNPJPartners?.length === 1 ? 'no CNPJ' : <Tooltip title={question.CNPJPartners?.map((partnerName) => (<Box key={partnerName}>- {partnerName}</Box>))}><span style={{textDecoration: 'underline'}}>na lista de titulares da pessoa jurídica</span></Tooltip>}.</>;
            notes.push({...question, description, success});
        }
        if(question.type === 'text' && question.assignedField === 4 && question.CNPJ){ // REPRESENTANTE LEGAL
            let success = false;
            if(question.retrievedFromCorporationId || question.foundLegalRepresentativeInCNPJ){
                success = true;
            } else {
                foundErrors = true;
            }
            let description = <>O(A) representante legal {question.input} indicado(a) na pergunta "{questionId}" {success ? '' : 'NÃO '}foi encontrado(a) {question.CNPJLegalRepresentatives?.length === 1 ? 'no CNPJ' : <Tooltip title={question.CNPJLegalRepresentatives?.map((partnerName) => (<Box key={partnerName}>- {partnerName}</Box>))}><span style={{textDecoration: 'underline'}}>na lista de administradores da pessoa jurídica</span></Tooltip>}.</>;
            notes.push({...question, description, success});
        }
        if(question.type === 'list' && question.CNPJQuestion && question.CNPJ){ // LISTA
            let success = false;
            if(question.foundActivityCodeInCNPJ){
                success = true;
            } else {
                foundErrors = true;
            }
            let description = <>Os <Tooltip title={<CNPJActivityCodes question={question} />}><span style={{textDecoration: 'underline'}}>CNAEs vinculados à atividade "{question.input}"</span></Tooltip> {success ? '' : 'NÃO '}foram encontrados no <Tooltip title={<CNPJCodes question={question} />}><span style={{textDecoration: 'underline'}}>CNPJ <Link href={`https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp?cnpj=${question.CNPJ || ''}`} target="_blank" underline="hover">{question.CNPJ ? question.CNPJ.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') : ''}</Link></span></Tooltip>.</>;
            notes.push({...question, description, success})
            if(question.CNPJIsMEI){
                success = false;
                description = '';
                if(question.activityAllowedForMEI) success = true;
                description = `A atividade "${question.input}" ${success ? '' : 'NÃO '} é permitida para Microempreendedor Individual (MEI).`;
                notes.push({...question, description, success})
            }
        }
    });

    const showButton = notes.length !== 0
    || (pendingDocument.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText && pendingDocument.overrideBypassOperatorReviewReasons && pendingDocument.overrideBypassOperatorReviewReasons.length !== 0)
    || (pendingDocument.shouldMakeAvailableToClientAt && foundErrors);

    return (
        <Box>
            <Grid container spacing={1} alignItems="center">
                <Grid>
                    <Box
                        sx={{
                            position: 'relative'
                        }}
                    >
                        {
                            showButton &&
                            <IconButton ref={detailsAnchorEl} onClick={() => setShowDetails(true)}
                                sx={{ position: 'relative', zIndex: (theme) => theme.zIndex.fab }}
                            >
                                <ListAltIcon />
                            </IconButton>
                        }
                        {
                            pendingDocument.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText &&
                            <Box
                                sx={{ position: 'absolute', right: -2, top: -2, zIndex: (theme) => theme.zIndex.fab - 1 }}
                            >
                                {
                                    pendingDocument.shouldMakeAvailableToClientAt
                                    ? <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                    : <CancelIcon htmlColor="red" fontSize={'small'} />
                                }
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid size="grow">
                    {
                        pendingDocument.shouldMakeAvailableToClientAt &&
                        <Box>
                            <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                <Grid>
                                    <Tooltip title="Este documento será disparado automaticamente no horário indicado. Clique no X para cancelar a ação programada.">
                                        <Box
                                            sx={(theme) => ({
                                                backgroundColor: theme.palette.primary.main,
                                                borderRadius: theme.spacing(2),
                                                color: theme.palette.common.white,
                                                padding: '4px 8px'
                                            })}
                                        >
                                            <Grid container spacing={1} alignItems="center">
                                                <AccessTimeIcon />
                                                <Grid xs>
                                                    <Typography>{moment(pendingDocument.shouldMakeAvailableToClientAt).format(moment().isSame(moment(pendingDocument.shouldMakeAvailableToClientAt), 'date') ? 'HH:mm' : 'DD/MM HH:mm')}</Typography>
                                                </Grid>
                                                <IconButton size="small" color="inherit" onClick={() => handleCancelScheduledSendButtonPress(pendingDocument)}>
                                                    <CancelIcon />
                                                </IconButton>
                                            </Grid>
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    }
                </Grid>
            </Grid>
            <Popover
                open={showDetails}
                anchorEl={detailsAnchorEl.current}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                onClose={() => setShowDetails(false)}
                disableRestoreFocus
                slotProps={{ paper: {sx: classes.detailedChecksContents} }}
            >
                {
                    notes
                    .map(note => (
                        <Box>
                            <Box my={0.5}>
                                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid>
                                        {
                                            note.success
                                            ? <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                                            : <CancelIcon htmlColor="red" fontSize={'small'} />
                                        }
                                    </Grid>
                                    <Grid size="grow">
                                        <Typography variant="body2" style={{color: note.success ? 'green' : 'red'}}>{note.description}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                        </Box>
                    ))
                }
                {
                    (pendingDocument.bypassDocumentOperatorReviewUnlessFormResponseContainsFreeText && pendingDocument.overrideBypassOperatorReviewReasons) &&
                    <>
                        {
                            pendingDocument.overrideBypassOperatorReviewReasons.map(reason => (
                                <Box key={reason}>
                                    <Box mt={1}>
                                        <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                                            <Grid>
                                                <CancelIcon htmlColor="red" fontSize={'small'} />
                                            </Grid>
                                            <Grid xs>
                                                <Typography variant="body2" style={{color: 'red'}}>{reason}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Divider />
                                </Box>
                            ))
                        }
                    </>
                }
                {
                    (pendingDocument.shouldMakeAvailableToClientAt && foundErrors) &&
                    <Box>
                        <Grid container spacing={1} alignItems="center" justifyContent="center" wrap="nowrap">
                            <Grid>
                                <Typography variant="body2" style={{color: 'green'}}>O(A) solicitante manifestou ciência das questões indicadas acima.</Typography>
                            </Grid>
                            <Grid>
                                <CheckCircleIcon htmlColor="green" fontSize={'small'} />
                            </Grid>
                        </Grid>
                    </Box>
                }
            </Popover>
        </Box>
    );
};

const LocalSignatureStatus = ({ pendingDocument, setSelectedDocument }) => {
    return (
        <SignatureStatus
            activeUserIsManager
            activeUserIsOperator
            documentType="document"
            documentVersion={pendingDocument.lastVersion}
            hideSignButton
            projectName={pendingDocument.folderName}
            selectedDocument={pendingDocument}
            setSelectedDocument={setSelectedDocument}
        />
    );
}

const PendingDocumentListItem = (props) => {
    const {
        getCurrentDocumentLastDate,
        handle_actionMenuOpen, handle_clickedProject,
        handleCancelScheduledSendButtonPress,
        handleDocumentClick,
        handleDocumentHistoryButtonPress, handleMenuOpen, handle_updateDocSentButtonClick,
        pendingDocument, saving, setSelectedDocument,
    } = props;
    const theme = useTheme();
    const screenSMUpInclusive = useMediaQuery(theme.breakpoints.down('sm'));
    const screenSMDownExclusive = useMediaQuery(theme.breakpoints.down('sm'));

    let documentBoxStyle = {
        padding: 0
    };

    let currentDocumentLastDate = getCurrentDocumentLastDate(pendingDocument);
    
    let thisMoment = moment();
    let currentDayOfWeek = thisMoment.day();
    let addHours = 0;
    if(currentDayOfWeek === 5){
        addHours = thisMoment.hours();
    } else if(currentDayOfWeek === 6){
        addHours = 24 + thisMoment.hours();
    } else if(currentDayOfWeek === 1 || currentDayOfWeek === 2){
        addHours = 48;
    }
    let documentIsVeryLate = false, documentIsLate = false;
    if(thisMoment.diff(moment(currentDocumentLastDate), 'hours') >= (48 + addHours)){
        documentIsVeryLate = true;
    } else if(thisMoment.diff(moment(currentDocumentLastDate), 'hours') >= (24 + addHours)){
        documentIsLate = true;
    }
    if(pendingDocument.flag?.type === 'client') documentBoxStyle.border = `2px solid ${theme.palette.secondary.main}`;
    if(pendingDocument.flag?.type !== 'operator' && (documentIsVeryLate || documentIsLate)){
        documentBoxStyle.backgroundColor = documentIsVeryLate ? '#ef9a9a' : (documentIsLate ? '#fff9c4' : '') // ffcdd2 fffde7;
    }

    return (
        <DataListItem
            dataId={pendingDocument.uid} sx={classes.documentBox} boxStyle={documentBoxStyle}
            showMoreOptionsFab
            handleMoreOptionsShownOnMouseOverFab={(e) => handle_actionMenuOpen(e, pendingDocument)}
            moreOptionsFabStyle={{top: 0, right: 0, transform: 'translate(50%, -50%)'}}
        >
            <PendingDocumentInnerBox handleDocumentClick={handleDocumentClick} pendingDocument={pendingDocument}>
                <UserSelectDocumentLabel pendingDocument={pendingDocument} />
                <Grid container spacing={1} alignItems="center" style={{width: '100%'}}>
                    <Grid style={{width: '100%'}}>
                        <Box>
                            <PendingDocumentListItemTop handleDocumentProjectNameClick={handle_clickedProject} pendingDocument={pendingDocument} />
                        </Box>
                        <Grid container spacing={1} alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                            <Grid>
                                {
                                    pendingDocument.operatorId
                                    ?
                                    <PendingDocumentListItemOperator
                                        loading={pendingDocument.loading}
                                        onClick={(e) => handleMenuOpen(e, pendingDocument)}
                                        operatorId={pendingDocument.operatorId}
                                    />
                                    : <Tooltip title="Clique para alterar o revisor deste documento"><span><IconButton disabled={pendingDocument.loading} size="small" color="primary" onClick={(e) => handleMenuOpen(e, pendingDocument)}><Avatar style={{height: 30, width: 30}} alt={'Escolher responsável'} /></IconButton></span></Tooltip>
                                }
                            </Grid>
                            <Grid>
                                <Tooltip title="Histórico">
                                    <span>
                                        <IconButton disabled={pendingDocument.loading} size="small" onClick={(e) => handleDocumentHistoryButtonPress(e, pendingDocument)}><AccessTimeIcon /></IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            {
                                pendingDocument.loading &&
                                <Grid>
                                    <CircularProgress size={20} />
                                </Grid>
                            }
                            <Grid size="grow">
                                <Grid container spacing={1} alignItems="center">
                                    <Grid size="grow">
                                        <PendingDocLink loading={pendingDocument.loading} selectedDocument={pendingDocument} />
                                    </Grid>
                                    <Grid>
                                        <ScheduledRelease handleCancelScheduledSendButtonPress={handleCancelScheduledSendButtonPress} pendingDocument={pendingDocument} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                (screenSMUpInclusive && pendingDocument?.lastVersion?.eSignature?.enabled) &&
                                <Grid size={{ sm: 12, md: 3, lg: 2 }}>
                                    <LocalSignatureStatus pendingDocument={pendingDocument} setSelectedDocument={setSelectedDocument} />
                                </Grid>
                            }
                            <Grid>
                                <Tooltip title="Enviar" placement="left">
                                    <span>
                                        <Fab disabled={saving || pendingDocument.loading || pendingDocument.sending} size="small" color="primary" onClick={() => handle_updateDocSentButtonClick(pendingDocument)}>{pendingDocument.sending ? <CircularProgress size={20} /> : <SendIcon />}</Fab>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <PendingDocumentComment pendingDocument={pendingDocument} />
                        <DocumentFilesList doc={pendingDocument} />
                        {
                            screenSMDownExclusive &&
                            <Box mt={1}>
                                <Grid container justifyContent="center">
                                    <Grid>
                                        <LocalSignatureStatus pendingDocument={pendingDocument} setSelectedDocument={setSelectedDocument} />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </PendingDocumentInnerBox>
        </DataListItem>
    );
}

export default PendingDocumentListItem;