import { lazy } from 'react';
import { Route } from 'react-router-dom';
import LazyLoadedComponent from '../../components/LazyLoadedComponent';
import Main from '../../components/Main';
import Header from './components/Header';
import ActiveProjectsTimeline from './ActiveProjectsTimeline';
import Home from './Home';
import Docs from './Docs';
import TasksOverview from './Questions/Overview';
import TasksTeam from './TasksTeam';
import Accounts from './Questions/Accounts';
import Folders from './Folders';
import Users from './Users';
import PageNotFound from '../PageNotFound';
import { useOperatorCtxAPI, useOperatorCtxSidenavMobile } from './context/OperatorContext';
import { OperatorUsersProvider } from '../../context/OperatorUsersContext';
import DesktopSidenav from '../../components/DesktopSidenav';
import MobileSidenav from '../../components/MobileSidenav';
import useMenuItems from '../../components/OperatorContext/hooks/useMenuItems';
import Account from './Account';

const LazyLoadedAccounting = lazy(() => import('./Accounting'));
const LazyLoadedClearance = lazy(() => import('./Clearance'));
const LazyLoadedLawsuits = lazy(() => import('./Procs'));
const LazyLoadedNotices = lazy(() => import('./Notices'));
const LazyLoadedProfile = lazy(() => import('./Profile'));
const LazyLoadedReports = lazy(() => import('./Reports'));
const LazyLoadedRoom = lazy(() => import('../Room'));
const LazyLoadedRooms = lazy(() => import('./Rooms'));
const LazyLoadedTimesheet = lazy(() => import('./Time'));

function Operator(){
    const { setState: setOperatorCtxState } = useOperatorCtxAPI();
    const menuItems = useMenuItems();
    const { sidenavMobileOpen } = useOperatorCtxSidenavMobile();

    const set_sidenavMobileOpen = (newValue) => {
        setOperatorCtxState('sidenavMobileOpen', newValue);
    };

    const handleCloseMobileSidenav = () => {
        set_sidenavMobileOpen(false);
    };

    return (
        <Main
            header={<Header />}
            sidenav={<DesktopSidenav menuItems={menuItems} account={<Account />} />}
            mobileSidenav={
                <MobileSidenav
                    account={<Account size={7} />}
                    handleCloseMobileSidenav={handleCloseMobileSidenav}
                    menuItems={menuItems}
                    sidenavMobileOpen={sidenavMobileOpen}
                    set_sidenavMobileOpen={set_sidenavMobileOpen}
                />
            }
        >
            <Route path="/" element={<Home />} />
            <Route path="/timesheet/*"
                element={<LazyLoadedComponent component={<LazyLoadedTimesheet />} />}
            />
            <Route path="/documentos/*"
                element={<Docs />}
            />
            <Route path="/demandas/*"
                element={<TasksOverview />}
            />
            <Route path="/tarefas/equipe/*"
                element={<TasksTeam />}
            />
            <Route path="/tarefas/*"
                element={<TasksOverview />}
            />
            <Route path="/atendimento/*"
                element={<Accounts />}
            />
            <Route path="/cronograma/*"
                element={<ActiveProjectsTimeline />}
            />
            <Route path="/clearance/*"
                element={<LazyLoadedComponent component={<LazyLoadedClearance />} />}
            />
            <Route path="/processos/*"
                element={<LazyLoadedComponent component={<LazyLoadedLawsuits />} />}
            />
            <Route path="/relatorios/*"
                element={<LazyLoadedComponent component={<LazyLoadedReports />} />}
            />
            <Route path="/projetos/*"
                element={<Folders />}
            />
            <Route path="/pessoas"
                element={<OperatorUsersProvider><Users /></OperatorUsersProvider>}
            />
            <Route path="/avisos"
                element={<LazyLoadedComponent component={<LazyLoadedNotices />} />}
            />
            <Route path="/financeiro/*"
                element={<LazyLoadedComponent component={<LazyLoadedAccounting />} />}
            />
            <Route path="/preferencias"
                element={<LazyLoadedComponent component={<LazyLoadedProfile />} />}
            />
            <Route path="/salas"
                element={<LazyLoadedComponent component={<LazyLoadedRooms />} />}
            />
            <Route path="/salas/:roomID"
                element={<LazyLoadedComponent component={<LazyLoadedRoom />} />}
            />
            <Route path="*" element={<PageNotFound />} />
        </Main>
    );
}

export default Operator;