import { useEffect, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';
import { useAppStateCtx } from '../context/AppState';

const useGetDocumentVersions = (documentId) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null, lastVersion: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(documentId && selectedWorkspace.value){
            setHookState(defaultHookState);
            let lastVersion = null;
            const unsubscribe = onSnapshot(
                query(
                    collection(db, `documents_versions`),
                    where('documentId', '==', documentId),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const currentVersion = {
                            ...data,
                            uid: snapshot.id,
                        };
                        dataArray.push(currentVersion);
                        lastVersion = {...currentVersion};
                    });
                    setHookState({
                        loading: false,
                        data: dataArray,
                        lastVersion
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [documentId, selectedWorkspace.value]);

    return hookState;
};

export default useGetDocumentVersions;