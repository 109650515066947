import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';

import { db } from '../firebase/config';

type ConstructorArgs = {
    aliases: string[];
    cnaes: string[];
    key: string;
    listId: string;
    meiAllowed: boolean;
    uid: string;
    value: string;
};

export default class WorkspaceListItem {
    aliases: ConstructorArgs['aliases'] = [];
    cnaes: ConstructorArgs['cnaes'] = [];
    key = '';
    listId = '';
    meiAllowed = false;
    uid = '';
    value = '';

    constructor({
        aliases,
        cnaes,
        key,
        listId,
        meiAllowed,
        uid,
        value
    }: ConstructorArgs){
        this.aliases = aliases || [];
        this.cnaes = cnaes || [];
        this.key = key || '';
        this.listId = listId || '';
        this.meiAllowed = !!meiAllowed;
        this.uid = uid || '';
        this.value = value || '';
    }

    async createListItem(workspaceId: string){
        let result = null, error = null;
        
        const workspaceListItemRef = doc(collection(db, `workspaces/${workspaceId}/lists_items`));

        const {uid: _, ...newListItem} = this;
        
        try {
            await setDoc(workspaceListItemRef, newListItem);
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates, workspaceId }: {
        updates: { [key: string]: any };
        workspaceId: string;
    }){
        let result = null, error = null;
        
        const workspaceListItemRef = doc(db, `workspaces/${workspaceId}/lists_items/${this.uid}`);
        
        try {
            await updateDoc(workspaceListItemRef, updates);
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}