import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AlertDialog from '../components/AlertDialog';
import { useAppStateCtx } from '../context/AppState';
import { useDocumentsAPI, useDocumentsCtxChangeDocumentFolderView } from '../context/DocumentsContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';
import { sortByKey } from '../utils/filters';

const ChangeDocumentFolderMenu = () => {
    useSignals();
    const { workspaceProjects } = useAppStateCtx();
    const { hideChangeDocumentFolderView } = useDocumentsAPI()
    const { changeDocumentFolderViewAnchorEl, changeDocumentFolderViewOpen, changeDocumentFolderViewSelectedDocument } = useDocumentsCtxChangeDocumentFolderView();
    const [projectOptions, setProjectOptions] = useState([]);
    const [clickedProject, setClickedProject] = useState(null);
    const [selectedFolderId, setSelectedFolderId] = useState('');
    const [confirmActionDialogOpen, setConfirmActionDialogOpen] = useState(false);

    useEffect(() => {
        if(workspaceProjects.value){
            const foundFolders = Object.entries(workspaceProjects.value)
            .map(([projectId, project]) => ({value: projectId, label: project.name, clientId: project.clientId}))
            .sort(sortByKey('label'));
            setProjectOptions(foundFolders);
        }
    }, [workspaceProjects.value]);

    useEffect(() => {
        if(changeDocumentFolderViewOpen && changeDocumentFolderViewSelectedDocument){
            setSelectedFolderId(changeDocumentFolderViewSelectedDocument.project);
        }
    }, [changeDocumentFolderViewOpen, changeDocumentFolderViewSelectedDocument]);

    const handleDocumentFolderOptionClick = (newFolder) => {
        handleClose();
        setClickedProject(newFolder);
        setConfirmActionDialogOpen(true);
    };

    const handleDocumentFolderChange = async () => {
        setConfirmActionDialogOpen(false)
        const newFolderId = clickedProject.value;
        setSelectedFolderId(newFolderId);
        
        const toastId = toast.loading(`Salvando...`);
        const res = changeDocumentFolderViewSelectedDocument.update({
            updates: {
                clientId: clickedProject.clientId,
                projectId: newFolderId, 
            }
        })
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        hideChangeDocumentFolderView();
    };

    return (
        <React.Fragment>
            <Menu
                keepMounted
                anchorEl={changeDocumentFolderViewAnchorEl}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                open={changeDocumentFolderViewOpen}
                onClose={handleClose}
            >
                {
                    projectOptions
                    .map((folderOption) => (
                        <MenuItem key={folderOption.value}
                            onClick={() => handleDocumentFolderOptionClick(folderOption)}
                            selected={folderOption.value === selectedFolderId}
                        >
                            <Typography>{folderOption.label}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
            <AlertDialog
                open={confirmActionDialogOpen} onClose={() => setConfirmActionDialogOpen(false)}
                text={`Você quer alterar a pasta do documento "${changeDocumentFolderViewSelectedDocument?.name}" para ${clickedProject?.label}?`}
                okButtonOnClick={handleDocumentFolderChange}
                cancelButtonOnClick={() => {
                    setConfirmActionDialogOpen(false);
                    handleClose();
                }}
            />
        </React.Fragment>
    );
};

export default ChangeDocumentFolderMenu;