import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';

import { db } from '../firebase/config';

export default class WorkspaceCustomField {
    name = '';
    options: string[] = [];
    template: string[] = [];
    type = '';
    uid = '';

    constructor({
        name,
        options,
        template,
        type,
        uid
    }: WorkspaceCustomField){
        this.name = name || '';
        this.options = options || [];
        this.template = template || [];
        this.type = type || '';
        this.uid = uid || '';
    }

    async createCustomField(workspaceId: string){
        let result = null, error = null;
        
        const workspaceCustomFieldRef = doc(collection(db, `workspaces/${workspaceId}/custom_fields`));

        const {uid: _, ...newCustomField} = this;
        
        try {
            await setDoc(workspaceCustomFieldRef, newCustomField);
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async update({ updates, workspaceId }: {
        updates: {[key: string]: any};
        workspaceId: string;
    }){
        let result = null, error = null;
        
        const workspaceCustomFieldRef = doc(db, `workspaces/${workspaceId}/custom_fields/${this.uid}`);
        
        try {
            await updateDoc(workspaceCustomFieldRef, updates);
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}