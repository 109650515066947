import { lazy } from 'react';
import Box from '@mui/material/Box';
import LazyLoadedComponent from '../LazyLoadedComponent';
import SignaturePositionBox from './SignaturePositionBox';
import { useDroppable } from '@dnd-kit/core';
import { Page } from 'react-pdf';

const LazyLoadedPdfDocument = lazy(() => import('../PdfDocument'));

const PositionSignaturesCanvas = ({
    boxes, setBoxes,
    base64String, onDocumentLoadSuccess, onPageClick, getPosition, numberOfPages, pageRef, pageRefs, signers
}) => {
    
    const { isOver, setNodeRef } = useDroppable({ // <--- isOver //TODO
        id: 'droppable',
    });

    return (
        <Box style={{boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.16)'}} mb={1}>
            
            <LazyLoadedComponent
                component={
                    <LazyLoadedPdfDocument
                        base64String={base64String}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <div ref={pageRef}>
                            <div ref={setNodeRef}
                                id="pdf-page"
                                style={{position: 'relative'}}
                            >
                                {boxes.map((box, index) => (
                                    <SignaturePositionBox
                                        key={index}
                                        box={box}
                                        boxes={boxes} setBoxes={setBoxes}
                                        getPosition={getPosition}
                                        index={index}
                                        numberOfPages={numberOfPages}
                                        signers={signers}
                                    />
                                ))}
                                {Array.from(
                                    new Array(numberOfPages),
                                    (documentPage, index) => (
                                    <div
                                        key={`page_${index + 1}`}
                                        ref={el => pageRefs.current[index] = el}
                                        style={{ border: '1px solid #e0e0e0' }}
                                    >
                                        <Page
                                            pageNumber={index + 1}
                                            onClick={onPageClick}
                                            renderAnnotationLayer={false}
                                            renderTextLayer={false}
                                        />
                                    </div>
                                    ),
                                )}
                            </div>
                        </div>
                    </LazyLoadedPdfDocument>
                }
            />
        </Box>
    );
}

export default PositionSignaturesCanvas;