import FormQuestionMovablePropertyLeaseDetails from '../../../../components/FormQuestionMovablePropertyLeaseDetails';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const MovablePropertyLeaseDetailsQuestion = ({question, setQuestionValid}) => {
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'movablePropertyLeaseDetails', // formEffects.schedule.checkValidation
        setQuestionValid,
    });

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionMovablePropertyLeaseDetails
            onChange={handleChange}
            question={question}
            initialValue={initialValue}
        />
    );
}

export default MovablePropertyLeaseDetailsQuestion;