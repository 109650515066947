import { useSignals } from '@preact/signals-react/runtime';

import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import Tooltip from '../../../../../../../../../../components/Tooltip';
import { useAppStateCtx } from '../../../../../../../../../../context/AppState';

const classes = {
    link: {
        color: theme => theme.palette.primary.main,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
};

const Span = styled('span')(unstable_styleFunctionSx);

const PendingDocumentListItemTopClientName = ({ clientId, onClick }) => {
    useSignals();
    const { workspaceClients } = useAppStateCtx();
    const clientName = workspaceClients.value[clientId]?.name || '';
    return (
        <Tooltip text={`Pesquisar outros documentos de ${clientName}`} enterDelay={300}>
            <Typography variant="body2" noWrap><Span sx={classes.link} onClick={() => onClick(clientId, '')}>{clientName}</Span></Typography>
        </Tooltip>
    );
};

export default PendingDocumentListItemTopClientName;