import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import Autocomplete from '../Autocomplete';
import ClearanceTimeCodeScreenshot from '../ClearanceTimeCodeScreenshot/ClearanceTimeCodeScreenshot';
import CommentsList from '../CommentsList';
import LoaderEllipsis from '../LoaderEllipsis';
import NoResults from '../NoResults';
import Input from '../Input';
import Select from '../Select';
import { useAppStateCtx } from '../../context/AppState';
import useGetClearanceNotesComments from '../../hooks/useGetClearanceNotesComments';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';
import { sortByKey } from '../../utils/filters';

const classes = {
    closeButton: {
        position: 'absolute',
        right: '8px',
        top: '8px',
        color: theme => theme.palette.grey[500],
    }
};

const CommentsWindow = ({
    activeUserIsOperator,
    commentsWindowOpen,
    projectClearanceSettings,
    selectedClearanceReportNote,
    selectedReport,
    selectedProjectClearanceTemplate
}) => {
    useSignals();
    const { activeUser, activeUserClearanceAuthorityLevel } = useAppStateCtx();
    const [comments, setComments] = useState([]);
    const [newCommentValue, setNewCommentValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedRightType, setSelectedRightType] = useState(null);
    const [useTypeOptions, setUseTypeOptions] = useState([]);
    const [selectedUseType, setSelectedUseType] = useState('');
    const [selectedRiskLevel, setSelectedRiskLevel] = useState('');
    const [riskApproved, setRiskApproved] = useState('withholdOpinion');
    const [clearanceTemplate, setClearanceTemplate] = useState(null);
    const [clearanceNoteCommentsListenerSettings, setClearanceNoteCommentsListenerSettings] = useState(null);
    const [approvalsRequired, setApprovalsRequired] = useState(null);
    const riskLevelNumbers = {
        one: 1,
        two: 2,
        three: 3,
        four: 4
    };
    const prevComments = useRef([]);

    const retrievedComments = useGetClearanceNotesComments(clearanceNoteCommentsListenerSettings);
    useEffect(() => {
        if(retrievedComments.loading){
            setLoading(true);
        } else {
            prevComments.current = retrievedComments.data || [];
            setComments(retrievedComments.data || []);
            setLoading(false);
        }
    }, [retrievedComments]);

    useEffect(() => {
        if(commentsWindowOpen.value){
            setNewCommentValue('');
            if(selectedClearanceReportNote.value){
                if(clearanceNoteCommentsListenerSettings === selectedClearanceReportNote.value.uid){
                    setComments(prevComments.current);
                } else {
                    setClearanceNoteCommentsListenerSettings(selectedClearanceReportNote.value.uid);
                }
                let currentRightType = selectedClearanceReportNote.value.rightType || null;
                let currentUseType = selectedClearanceReportNote.value.useType || '';
                let currentRiskLevel = selectedClearanceReportNote.value.riskLevel || '';
                setSelectedRightType(currentRightType);
                setSelectedUseType(currentUseType);
                setSelectedRiskLevel(currentRiskLevel);
    
                let currentRiskApproved;
                if(activeUser.value){
                    const approvals = selectedClearanceReportNote.value.approvals || {};
                    const activeUserApprovals = approvals[activeUser.value.uid];
                    if(activeUserApprovals){
                        currentRiskApproved = activeUserApprovals.approval;
                    }
                }
                setRiskApproved(currentRiskApproved || 'withholdOpinion');
            }
        }
    }, [commentsWindowOpen.value, selectedClearanceReportNote.value]);

    useEffect(() => {
        if(commentsWindowOpen.value && activeUser.value && selectedClearanceReportNote.value && comments.length !== 0){
            const lastComment = comments[comments.length - 1];
            if(lastComment.createdAt !== selectedClearanceReportNote.value.commentsReadAt[activeUser.value.uid]){
                selectedClearanceReportNote.value.setCommentsReadAt({
                    activeUserId: activeUser.value.uid,
                    nowISOString: lastComment.createdAt,
                });
            }
        }
    }, [commentsWindowOpen.value, activeUser.value, selectedClearanceReportNote.value, comments]);

    useEffect(() => {
        if(commentsWindowOpen.value){
            // selectedProjectClearanceTemplate || selectedClearanceReportNote.value.template
            if(selectedProjectClearanceTemplate){
                setClearanceTemplate(selectedProjectClearanceTemplate);
            }
        }
    }, [commentsWindowOpen.value, selectedProjectClearanceTemplate]);

    useEffect(() => {
        if(commentsWindowOpen.value && projectClearanceSettings){
            const currentApprovalsRequired = projectClearanceSettings?.approvals?.numberOfApprovalsRequired;
            setApprovalsRequired(currentApprovalsRequired);
        }
    }, [commentsWindowOpen.value, projectClearanceSettings]);

    useEffect(() => {
        if(selectedClearanceReportNote.value && clearanceTemplate && selectedRightType){
            const itemType = clearanceTemplate.rightTypes[selectedRightType];
            if(itemType){
                const riskLevels = itemType.riskLevels;
                let options = [];
                for(const riskLevelKey in riskLevels){
                    const riskLevel = riskLevels[riskLevelKey];
                    const riskOption = CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === riskLevelKey);
                    options = [...options, ...Object.entries(riskLevel).map(([itemId, item]) => ({value: itemId, label: `Risco ${riskOption.label} - ${item.name}` , riskLevel: riskLevelKey})).sort(sortByKey('label'))];
                }
                setUseTypeOptions(options);
            }
        }
    }, [selectedClearanceReportNote.value, selectedProjectClearanceTemplate, selectedRightType]);

    const handleApproveRiskButtonPress = (newValue) => {
        setRiskApproved(newValue);
    };

    const handleRightTypeChange = (newValue) => {
        setSelectedRightType(newValue);
        setSelectedUseType('');
        setSelectedRiskLevel('');
    };

    const handleUseTypeChange = (newValue) => {
        setSelectedUseType(newValue);
        const useType = useTypeOptions.find(option => option.value === newValue);
        setSelectedRiskLevel(useType.riskLevel);
    };

    const handleSubmit = async () => {
        setSaving(true);

        const commentArgs = {
            activeUser: activeUser.value,
            comment: newCommentValue
        }
        if(activeUserIsOperator){
            commentArgs.riskLevel = selectedRiskLevel;
            commentArgs.riskLevelDescription = selectedRiskLevel ? CLEARANCE_RISK_OPTIONS.find(riskOption => riskOption.value === selectedRiskLevel)?.label : '';
        }
        if(!activeUserIsOperator && selectedRiskLevel && ((approvalsRequired || {})[selectedRiskLevel] || 0) > 0 && activeUserClearanceAuthorityLevel.value >= riskLevelNumbers[selectedRiskLevel]){
            if(riskApproved !== 'withholdOpinion'){
                commentArgs.riskApproved = riskApproved;
            }
        }
        const res = await selectedClearanceReportNote.value.comment(commentArgs);
        setSaving(false);
        if(res.error){
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' })
        }
        setNewCommentValue('');
        toast(SUCCESS_MESSAGE_SAVED, { type: 'success' })
    };
    
    const handleClose = () => {
        commentsWindowOpen.value = false;
        setComments([]);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={commentsWindowOpen.value}
            // TransitionComponent={Transition}
            // keepMounted
            fullWidth
            maxWidth="md"
            // fullScreen
        >
            <DialogTitle id="simple-dialog-title">
                <Grid container spacing={1} alignItems="center">
                    <Grid size="grow">
                        <Typography variant="h6">{!!activeUser.value ? 'Comentar: ' : ''}{selectedClearanceReportNote.value?.description}</Typography>
                    </Grid>
                    <Grid>
                        <IconButton
                            sx={classes.closeButton}
                            aria-label="close"
                            onClick={handleClose}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>

                <ClearanceTimeCodeScreenshot 
                    selectedClearanceReportNote={selectedClearanceReportNote}
                    selectedReport={selectedReport}
                    windowOpen={commentsWindowOpen}
                />

                {
                    loading
                    ? <LoaderEllipsis />
                    :
                    (
                        comments.length === 0
                        ? <NoResults text="Nenhum comentário." />
                        :
                        <Box mb={2}>
                            <CommentsList
                                comments={comments}
                                handleDeleteCommentButtonClick={null}
                            />
                        </Box>
                    )
                }
                {
                    !!activeUser.value &&
                    <Box>
                        {
                            (!activeUserIsOperator && selectedRiskLevel && ((approvalsRequired || {})[selectedRiskLevel] || 0) > 0 && activeUserClearanceAuthorityLevel.value >= riskLevelNumbers[selectedRiskLevel]) &&
                            <Box mb={1}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid size={{ xs: 9, sm: 8, md: 7 }}>
                                        <Select
                                            label="Deseja aprovar o risco?"
                                            options={[
                                                {value: 'withholdOpinion', label: 'Não responder (aguardar informações)'},
                                                {value: 'yes', label: <Grid container spacing={1} alignItems="center"><Grid><ThumbUpAltOutlinedIcon /></Grid><Grid xs>Aprovar o risco</Grid></Grid>},
                                                {value: 'no', label: <Grid container spacing={1} alignItems="center"><Grid><ThumbDownAltOutlinedIcon /></Grid><Grid xs>Reprovar o risco</Grid></Grid>}
                                            ]}
                                            value={riskApproved} onChange={(e) => handleApproveRiskButtonPress(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid>
                                        <IconButton size="small" onClick={() => handleApproveRiskButtonPress('yes')}>
                                            <ThumbUpAltOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid>
                                        <IconButton size="small" onClick={() => handleApproveRiskButtonPress('no')}>
                                            <ThumbDownAltOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Box mb={1}>
                            <Input
                                disabled={saving}
                                placeholder="Seu comentário"
                                autoFocus fullWidth multiline
                                minRows={5} maxRows={20}
                                value={newCommentValue} onChange={(e) => setNewCommentValue(e.target.value)}
                            />
                        </Box>
                        {
                            (activeUserIsOperator && selectedClearanceReportNote.value && clearanceTemplate && selectedRightType) &&
                            <Box mt={1}>
                                <Grid container spacing={1}>
                                    <Grid size={{ xs: 12 }}>
                                        <Autocomplete
                                            disabled
                                            label="Direito envolvido"
                                            options={Object.entries(clearanceTemplate.rightTypes).map(([rightTypeId, rightType]) => ({ ...rightType, uid: rightTypeId })).sort(sortByKey('name'))}
                                            getOptionLabel={(option) => option.name}
                                            value={{...clearanceTemplate.rightTypes[selectedRightType], uid: selectedRightType}} onChange={(_, newValue) => handleRightTypeChange(newValue)}
                                        />
                                    </Grid>
                                    <Grid size={{ xs: 6 }}>
                                        <Select
                                            disabled
                                            optionsStyle={{fontSize: 14}} multiline
                                            label="Condição de uso"
                                            options={useTypeOptions}
                                            value={selectedUseType} onChange={(e) => handleUseTypeChange(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid size={{ xs: 6 }}>
                                        <Select
                                            label="Nível de risco"
                                            options={CLEARANCE_RISK_OPTIONS.map(option => ({...option, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))}
                                            value={selectedRiskLevel} onChange={(e) => setSelectedRiskLevel(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    </Box>
                }
            </DialogContent>
            {
                !!activeUser.value &&
                <DialogActions>
                    <Button
                        onClick={handleSubmit}
                        variant="contained" color="primary"
                        disabled={saving || !newCommentValue}
                        startIcon={saving && <CircularProgress size={20} />}
                    >
                        Comentar
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
};

export default CommentsWindow;