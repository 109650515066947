import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from '../../context/AppState';

const DocumentListItemTopProject = ({ projectId }) => {
    useSignals();
    const { workspaceProjects } = useAppStateCtx();
    return (
        <span>{workspaceProjects.value[projectId]?.name || '(Sem pasta)'}</span>
    );
};

export default DocumentListItemTopProject;