import { useEffect, useState } from 'react';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import { Box, Typography } from '@mui/material';

import { useOperatorCtxClients, useOperatorCtxProjects } from '../../../context/OperatorContext';

import Form from '../../../../Form';
import { useForm } from '../../../../Form/context/FormContext';

import Window from '../../../../../components/Window';
import { useAppStateCtx } from '../../../../../context/AppState';
import { useAppContext } from '../../../../../context/SystemContext';
import { linkStyles } from '../../../../../styles/index';
import { startDocumentsForm } from '../../../../../utils/common';

const loadingForm = signal(false);
 
const TestTemplateWindow = ({open, form, effects, mainSheet}) => {
    useSignals();
    const {
        formFile,
        formInitialValues,
        formSelectedDraft,
        projectDocumentSettings,
        projectMisc,
        selectedDocumentForm,
        selectedFolder,
        workspaceDocumentsTemplates
    } = useAppStateCtx();
    const { formEffects } = useAppContext();
    const clients = useOperatorCtxClients();
    const projects = useOperatorCtxProjects();
    const { resetForm, values } = useForm();
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        if(open.value){
            formFile.value = null;
            if(form){
                resetForm({});

                const currentInitialForm = {};
                form.forEach(question => {
                    const newQuestion = {
                        id: question.id,
                        type: question.type
                    };
                    if(question.type === 'text'){
                        newQuestion.input = `[${question.name}]`;
                    } else if(question.type === 'date'){
                        const now = new Date();
                        newQuestion.input = moment(now).format('L');
                        newQuestion.value = now;
                    } else if(question.type === 'currency'){
                        const typeSpecifics = question.typeSpecifics;

                        const max = 1000000;
                        const min = 1;
                        const newValue = Math.floor(Math.random() * (max - min + 1) ) + min;
                        
                        newQuestion.typeSpecifics = typeSpecifics;
                        newQuestion.value = newValue * 100;

                        newQuestion.input = formEffects.currency.getTypeSpecificInput(newValue, typeSpecifics);
                    } else if(question.type === 'radio'){
                        newQuestion.choice = question.options[0].choice;
                        if(question.options[0].choice === '~t;'){
                            newQuestion.input = '[Preencher]';
                        }
                    } else if(question.type === 'number'){
                        const max = 999;
                        const min = 9;
                        const newValue = Math.floor(Math.random() * (max - min + 1) ) + min;
                        newQuestion.value = newValue;
                        const typeSpecifics = question.typeSpecifics;
                        newQuestion.typeSpecifics = typeSpecifics;
                        newQuestion.input = formEffects.number.getTypeSpecificInput(newValue, typeSpecifics);
                    } else if(question.type === 'individualId'){
                        newQuestion.input = '123.456.789-10';
                    } else if(question.type === 'corporationId'){
                        newQuestion.input = '12.345.678/0001-99';
                    }
                    if(newQuestion.input || newQuestion.choice) currentInitialForm[question.name] = newQuestion;
                });
                values.current = currentInitialForm;
                formInitialValues.value = currentInitialForm;

                const foundFolder = {...clients, ...projects}['61070bae270bd24b6c793c07'];
                selectedFolder.value = foundFolder || {};

                setShowForm(true);

                startDocumentsForm({
                    initialValues: formInitialValues,
                    loadingForm: loadingForm,
                    projectDocumentsSettings: projectDocumentSettings,
                    projectId: selectedFolder.value.uid,
                    projectMisc: projectMisc,
                    resetForm: resetForm,
                    selectedDraft: formSelectedDraft,
                    selectedForm: selectedDocumentForm,
                    // selectedFormId: 'test', // signal
                    templateId: 'test',
                    templates: workspaceDocumentsTemplates.value,
                    values: values,
                })
            }
        }
    }, [open.value]);

    const handleFormSubmit = (fileUrl) => {
        formFile.value = fileUrl;
    };

    const handleClickLink = (url) => {
        window.open(url, '_blank');
    };
    
    const handleClose = () => {
        open.value = false;
    };

    return (
        <Window
            id="test-template-window"
            open={open.value} onClose={handleClose}
            modalInnerBoxSx={{
                width: ['100%', '90%', '75%']
            }}
        >
            {
                formFile.value &&
                <Box style={{textAlign: 'center'}}>
                    <Typography variant="body1" sx={linkStyles} onClick={() => handleClickLink(formFile.value)}>{formFile.value}</Typography>
                </Box>
            }
            {
                showForm &&
                <Form
                    activeUserIsOperator
                    hideSaveFormButton
                    hideSuppliersButton
                    isTest
                    onFormSubmit={handleFormSubmit}
                    replaceTitle="Teste"
                    testFile={{ effects, main: mainSheet }}
                    testForm={form}

                    // selectedTemplate={{
                    //     form,
                    //     name: 'Teste',
                    //     templateFile: {
                    //         effects,
                    //         main: mainSheet
                    //     },
                    //     uid: 'test',
                    // }}
     
                />
            }
        </Window>
    );
};

export default TestTemplateWindow;