import { useCallback, useEffect } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { Collapse, List } from '@mui/material';
import PendingDocumentListItem from '../PendingDocumentListItem';
import { useAppStateCtx } from '../../../../../../context/AppState';
import { useAppCtxAPI } from '../../../../../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxLoadingPendingDocuments, useDocumentsCtxPendingDocuments } from '../../../../../../context/DocumentsContext';
import { getUserNameById, removeDiacritics } from '../../../../../../utils/common';

const PendingDocumentsList = (props) => {
    useSignals();
    const { selectedWorkspace, workspaceOperators } = useAppStateCtx();
    const {
        filterClientId,
        filterProjectId,
        filterOperatorId,
        filterText,
        saving,
        numberOfVisibleDocuments,
        visibleDocuments
    } = props;
    const { handleNavigate } = useAppCtxAPI();
    const {
        setActionMenuAnchorEl, showDocumentHistoryView, setReviewerMenuAnchorEl, setSelectedDocument,
        showCancelScheduledSendDocumentView, showSubmitDocumentView
    } = useDocumentsAPI(); 
    const loadingPendingDocuments = useDocumentsCtxLoadingPendingDocuments();
    const pendingDocuments = useDocumentsCtxPendingDocuments();

    const filterOperatorNoneSelected = (operatorId) => {
        return !operatorId || !workspaceOperators.value.map(operator => operator.uid).includes(operatorId);
    }

    const filterUser = (operatorId) => {
        if(filterOperatorId.value === 'all'){
            return true;
        } else if(filterOperatorId.value === 'none'){
            return filterOperatorNoneSelected(operatorId);
        } else {
            return operatorId === filterOperatorId.value || filterOperatorNoneSelected(operatorId);
        }
    };

    const filterClient = (clientId) => {
        if(filterClientId.value === 'all'){
            return true;
        } else {
            return clientId === filterClientId.value;
        }
    };

    const filterProject = (projectId) => {
        if(filterProjectId.value === 'all'){
            return true;
        } else if(filterProjectId.value === 'none'){
            return projectId === '';
        } else {
            return projectId === filterProjectId.value;
        }
    };

    const filterTextAction = (documentName) => {
        if(filterText.value){
            const textFields = `${documentName}`;
            return removeDiacritics(textFields).toUpperCase().indexOf(removeDiacritics(filterText.value).toUpperCase()) !== -1;
        }
        return true;
    };

    const filterItems = (item) => {
        return !item.deleted && (!item.sent || !!item.flag?.type)
        && filterTextAction(item.name) && filterUser(item.operatorId) && filterClient(item.clientId) && filterProject(item.projectId);
    };

    useEffect(() => {
        if(pendingDocuments){
            const currentVisibleDocuments = pendingDocuments.filter(filterItems);
            visibleDocuments.value = currentVisibleDocuments;
            numberOfVisibleDocuments.value = currentVisibleDocuments.length;
        }
    }, [pendingDocuments, filterText.value, filterOperatorId.value, filterClientId.value, filterProjectId.value]);

    const handle_clickedProject = (clientId, projectId) => {
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/pesquisar?c=${clientId}&p=${projectId}`);
    };
    
    const handleMenuOpen = (e, clickedDocument) => {
        setReviewerMenuAnchorEl(e.target, clickedDocument);
    };
    
    const handle_actionMenuOpen = (e, clickedDocument) => {
        setActionMenuAnchorEl(e.target, clickedDocument);
    };
    
    const handle_updateDocSentButtonClick = (clickedDocument) => {
        showSubmitDocumentView(clickedDocument);
    };
    
    const getCurrentDocumentLastDate = useCallback((currentDocument) => {
        let currentDocumentLastDate = currentDocument.createdAt;
        if(currentDocument.availableToClient && !!currentDocument.flag?.type) currentDocumentLastDate = currentDocument.flag.flaggedAt;
        return currentDocumentLastDate;
    }, []);

    const handleDocumentHistoryButtonPress = (event, clickedDocument) => {
        showDocumentHistoryView({
            left: event.clientX - 2,
            top: event.clientY - 4,
        }, clickedDocument, true, true);
    };

    const handleCancelScheduledSendButtonPress = (clickedDocument) => {
        showCancelScheduledSendDocumentView(clickedDocument);
    };

    const handleDocumentClick = () => {
        return;
    };

    if(!!visibleDocuments.value){
        return (
            <Collapse in={!loadingPendingDocuments} timeout={1000}>
                <List dense>
                    {
                        visibleDocuments.value
                        .sort((a, b) => {
                            if(a.flag?.type === 'operator' && b.flag?.type !== 'operator') return 1;
                            if(a.flag?.type !== 'operator' && b.flag?.type === 'operator') return -1;
                            let aDate = getCurrentDocumentLastDate(a);
                            let bDate = getCurrentDocumentLastDate(b);
                            return (aDate > bDate) ? 1 : ((bDate > aDate) ? -1 : 0);
                        })
                        .map((pendingDocument) => (
                            <PendingDocumentListItem key={pendingDocument.uid}
                                {
                                    ...{
                                        getCurrentDocumentLastDate,
                                        getUserNameById,
                                        handle_actionMenuOpen,
                                        handle_clickedProject,
                                        handle_updateDocSentButtonClick,
                                        handleCancelScheduledSendButtonPress,
                                        handleDocumentHistoryButtonPress,
                                        handleMenuOpen,
                                        saving,
                                    }
                                }
                                handleDocumentClick={handleDocumentClick}
                                pendingDocument={pendingDocument}
                                pendingDocumentId={pendingDocument.uid}
                                pendingDocumentName={pendingDocument.name}
                                setSelectedDocument={setSelectedDocument}
                            />
                        ))
                    }
                </List>
            </Collapse>
        );
    }

    return null;
};

export default PendingDocumentsList;