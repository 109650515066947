import callFunction from '../firebase/functions/callFunction';

export default async function setUserCustomClaims(workspaceId: string, userId?: string){
    let result = null, error = null;

    const setUserCustomClaimsRes = await callFunction('setUserCustomClaims', { userId, workspaceId });
    if(setUserCustomClaimsRes.error){
        error = setUserCustomClaimsRes.error;
    }
    result = setUserCustomClaimsRes.result;

    return { result, error };
}