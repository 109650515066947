import { useEffect, useState } from 'react';
import { collection, limit as _limit, onSnapshot, query, startAfter as _startAfter, where } from 'firebase/firestore';

import ClearanceNote from '../classes/ClearanceNote';
import { db } from '../firebase/config';

const useGetClearanceReportItems = (setClearanceReportItemsSnapshotSettings) => {
    const defaultHookState = { loading: true, data: [], snapshots: [] };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(setClearanceReportItemsSnapshotSettings?.shouldSetSnapshot){
            setHookState(defaultHookState);

            const queryConstraints = [
                where(`deleted`, '==', false),
                where('reportId', '==', setClearanceReportItemsSnapshotSettings.reportId),
            ];
    
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'clearance_notes'),
                    ...queryConstraints
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newDocument = new ClearanceNote({...data, uid: snapshot.id});
                        dataArray.push(newDocument);
                    });
                    setHookState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: dataArray,
                        snapshots: snapshots.docs
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [setClearanceReportItemsSnapshotSettings]);

    return hookState;
};

export default useGetClearanceReportItems;