import { useEffect, useRef, useState } from 'react';
import { MentionsTextField,  } from 'mui-mentions';

import { useSignals } from '@preact/signals-react/runtime';

import ChatMessage from '../../classes/ChatMessage';
import { useAppStateCtx } from '../../context/AppState';

const ChatTextField = () => {
    useSignals();
    const { activeUser, selectedWorkspace, workspaceOperators } = useAppStateCtx();
    const [fieldValue, setFieldValue] = useState('');
    const fieldMentions = useRef([]);
    const fieldPlainText = useRef('');
    const [availableMentions, setAvailableMentions] = useState([]);
    const [mentionOpen, setMentionOpen] = useState(false);

    useEffect(() => {
        if(workspaceOperators.value){
            setAvailableMentions(workspaceOperators.value.map(user => ({ id: user.uid, display: user.name })));
        }
    }, [workspaceOperators.value]);

    const handleChange = (newValue, newPlainText, mentions) => {

        const mentionMatch = newValue.match(/(^|\s)@(\w*)$/); // Matches "@" at start or after space
        if (mentionMatch) {
            const searchTerm = mentionMatch[2].toLowerCase();
            const hasMatches = availableMentions.some((mention) =>
                mention.display.toLowerCase().startsWith(searchTerm)
            );

            setMentionOpen(hasMatches); // Only open if there are valid suggestions
        } else {
            setMentionOpen(false); // Close if no @ mention is being typed
        }

        fieldPlainText.current = newPlainText;
        fieldMentions.current = mentions;
        setFieldValue(newValue);
    }

    const handleChatTextFieldKeyPress = async (e) => {
        if (mentionOpen) {
            return; // Don't trigger database save if mention is open
        }
      
        if(e.ctrlKey && e.key === 'Enter'){
            const newMessage = new ChatMessage({
                createdBy: activeUser.value.uid,
                mentionedUserIds: fieldMentions.current.map(mention => mention.id),
                message: fieldValue,
                roomId: `${selectedWorkspace.value.uid}_home`,
                workspaceId: selectedWorkspace.value.uid
            });
            const activeUserName = activeUser.value.name;
            const workspaceName = selectedWorkspace.value.name;
            await newMessage.createMessage(activeUserName, workspaceName, fieldPlainText.current);
            fieldPlainText.current = '';
            fieldMentions.current = [];
            setFieldValue('');
        }
    };

    return (
        <MentionsTextField
            autoComplete="off"
            dataSources={[
                {
                    data: availableMentions,
                    // trigger: '@',
                    onAdd: () => setMentionOpen(false)
                }
            ]}
            fullWidth
            highlightColor="lightgrey"
            onBlur={() => setMentionOpen(false)} // Close mention tracking when input loses focus
            onChange={handleChange}
            onKeyDown={handleChatTextFieldKeyPress}
            placeholder="Pressione Ctrl + Enter para enviar"
            multiline
            rows={3}
            value={fieldValue}
            variant="standard"
        />
    );
}

export default ChatTextField;