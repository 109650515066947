import ClearanceItemsListPage from '../../../components/clearance/ClearanceItemsListPage';

const Reports = ({
    initialProjectClearanceSettings,
    restrictedToProject
}) => {
    return (
        <ClearanceItemsListPage
            initialProjectClearanceSettings={initialProjectClearanceSettings}
            restrictedToProject={restrictedToProject}
        />
    );
};

export default Reports;