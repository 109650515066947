import { useEffect } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import User from '../../classes/User';
import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI } from '../../context/SystemContext';
import onAuthStateChanged from '../../firebase/auth/onAuthStateChanged';
import getDoc from '../../firebase/firestore/getDoc';

const Auth = ({children}) => {
    useSignals();
    const { activeUser, activeUserAuth, loadingApp } = useAppStateCtx();
    const { redirect } = useAppCtxAPI();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged((user) => {
            if (user) {
                loadingApp.value = true;
                activeUserAuth.value = user;
            } else {
                activeUserAuth.value = null;
            }
        });

        return () => unsubscribe();
    }, []);

    const getActiveUser = async () => {
        if(activeUserAuth.value.uid){
            const res = await getDoc(`users/${activeUserAuth.value.uid}`);
            if(res.result){ 
                const currentActiveUser = res.result;
                if(currentActiveUser){
                    const newActiveUser = new User({...currentActiveUser, uid: activeUserAuth.value.uid});
                    activeUser.value = newActiveUser;
                    redirect(newActiveUser);
                }
            }
        }
    };

    useEffect(() => {
        if(activeUserAuth.value){
            getActiveUser();
        }
    }, [activeUserAuth.value]);

    return (
        children
    );
};

export default Auth;