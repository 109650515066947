import { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import { Avatar, Box, Button, Chip, Grid, IconButton, TextField, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { useOperatorCtxOperators } from '../context/OperatorContext';

import AddOrSubtractDial from '../../../components/AddOrSubtractDial';
import Autocomplete from '../../../components/Autocomplete';
import DatePicker from '../../../components/DatePicker';
import FavoriteProjects from '../../../components/FavoriteProjects';
import Switch from '../../../components/Switch';
import Tooltip from '../../../components/Tooltip';
import Window from '../../../components/Window';
import { useAppStateCtx } from '../../../context/AppState';
import { useTasksCtxAPI, useTasksCtxTaskView } from '../../../context/TasksContext';
import { getProjectNameById } from '../../../utils/common';
import { actionWindowStyles } from '../../../styles/index';

const classes = {
    priority2Chip: {
        backgroundColor: '#ef9a9a',
        opacity: 0.5,
        transition: 'opacity .2s ease-out',
        '&&.task-priority-chip-selected': {
            backgroundColor: '#EC8484',
            opacity: 1,
        },
        '&&:hover': {
            backgroundColor: '#EC8484',
            opacity: 1,
        }
    },
    priority2ChipSelected: {
        backgroundColor: '#EC8484',
        opacity: 1,
    },
    priority1Chip: {
        backgroundColor: '#fff9c4',
        opacity: 0.5,
        transition: 'opacity .2s ease-out',
        '&&.task-priority-chip-selected': {
            backgroundColor: '#FFF491',
            opacity: 1,
        },
        '&&:hover': {
            backgroundColor: '#FFF491',
            opacity: 1,
        }
    },
    priority1ChipSelected: {
        backgroundColor: '#FFF491',
        opacity: 1,
    },
    priority0Chip: {
        backgroundColor: '#FFF5EE',
        opacity: 0.5,
        transition: 'opacity .2s ease-out',
        '&&.task-priority-chip-selected': {
            backgroundColor: '#FFD7BB',
            opacity: 1,
        },
        '&&:hover': {
            backgroundColor: '#FFD7BB',
            opacity: 1,
        }
    },
    priority0ChipSelected: {
        backgroundColor: '#FFD7BB',
        opacity: 1,
    },
    avatar: {
        height: '32px',
        width: '32px',
        cursor: 'pointer',
        opacity: 0.5,
        transition: 'opacity .2s ease-out',
        '&.avatar-selected': {
            opacity: 1,
        },
        '&:hover': {
            opacity: 1,
        }
    },
    avatarSelected: {
        opacity: 1,
    }
};

const TaskWindow = ({}) => {
    useSignals();
    const { activeUser, selectedWorkspace, workspaceClients, workspaceProjects } = useAppStateCtx();
    const operators = useOperatorCtxOperators();
    const { handleTaskSubmit, hideTaskView } = useTasksCtxAPI();
    const { taskViewOpen, taskViewSelectedTask: selectedTask } = useTasksCtxTaskView();
    const subjectRef = useRef();
    const [selectedClient, set_selectedClient] = useState(null);
    const [projectOptions, set_projectOptions] = useState([]);
    const [selectedProject, set_selectedProject] = useState('');
    const [hiddenTask, set_hiddenTask] = useState(false);
    const [questionSubject, set_questionSubject] = useState('');
    const [questionDescription, set_questionDescription] = useState('');
    const [taskUrl, set_taskUrl] = useState('');
    const [deadline, set_deadline] = useState(null);
    const [taskOperatorId, setTaskOperatorId] = useState('');
    const [taskPriority, setTaskPriority] = useState(1);
    const [estimatedMinutesRequiredToComplete, setEstimatedMinutesRequiredToComplete] = useState(0);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

    useEffect(() => {
        if(taskViewOpen){
            clearForm();
            if(!selectedTask){
                setTaskOperatorId(activeUser.value.uid);
                handle_selectedClientChange('61070c0759a35d2658590394');
                handle_selectedProjectChange('none');
            } else { // making changes
                if(selectedTask.projectId){
                    const foundFolder = {...workspaceClients.value, ...workspaceProjects.value}[selectedTask.projectId];
                    const folderType = foundFolder.clientId ? 'projects' : 'clients';
                    handleFavoriteClick(folderType, selectedTask.projectId);
                }
                setEstimatedMinutesRequiredToComplete(selectedTask.estimatedMinutesRequiredToComplete || 0);
                setTaskOperatorId(selectedTask.operatorId || '');
                set_deadline(selectedTask.deadline || null);
                set_taskUrl(selectedTask.taskUrl || '');
                set_questionSubject(selectedTask.subject);
                set_questionDescription(selectedTask.description);
                set_hiddenTask(!!selectedTask.hidden);
            }
            setTimeout(() => {
                subjectRef.current?.focus();    
            }, 500);
        }
    }, [taskViewOpen]);

    useEffect(() => {
        let disabled = false;
        if(!selectedClient || !selectedProject || !questionSubject){
            disabled = true;
        }
        setSaveButtonDisabled(disabled);
    }, [selectedClient, selectedProject, questionSubject, selectedTask, workspaceClients.value, workspaceProjects.value]);

    const handle_selectedClientChange = (newValue) => {
        set_selectedClient(newValue);
        if(newValue){
            const currentClientFolders = Object.entries(workspaceProjects.value).filter(([_, project]) => project.clientId === newValue).map(([projectId]) => projectId);
            set_projectOptions(currentClientFolders);
        } else {
            set_projectOptions([]);
        }
        set_selectedProject('none');
    };
    const handle_selectedProjectChange = (newValue) => {
        set_selectedProject(newValue);
    };
    const handleSaveTaskButtonClick = () => {
        handleSubmit();
    };
    const handleSubmit = async (timesheet) => {
        handleClose(false);

        handleTaskSubmit({
            deadline,
            hiddenTask,
            estimatedMinutesRequiredToComplete,
            questionDescription,
            questionSubject,
            selectedClient,
            selectedProject,
            taskOperatorId,
            taskPriority,
            taskUrl,
        }, timesheet);

        clearForm();
    }
    const clearForm = () => {
        set_hiddenTask(false);
        setEstimatedMinutesRequiredToComplete(0);
        set_deadline(null);
        set_taskUrl('');

        set_questionSubject('');
        set_questionDescription('');
        
        set_projectOptions([]);
        set_selectedProject(null);
        set_selectedClient(null);
        setTaskPriority(1);
    };
    const handleFavoriteClick = (folderType, folderId) => {
        if(folderType === 'projects'){
            const foundFolder = workspaceProjects.value[folderId];
            if(foundFolder){
                handle_selectedClientChange(foundFolder.clientId);
                handle_selectedProjectChange(folderId);
            }
        } else {
            handle_selectedClientChange(folderId);
            handle_selectedProjectChange('none');
        }
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter'){
            if(!saveButtonDisabled){
                handleSubmit();
            }
        }
    };

    const handleClose = () => {
        hideTaskView();
    };

    return (
        <Window open={taskViewOpen} onClose={handleClose} modalInnerBoxSx={actionWindowStyles} hideBottomButtons>
            <Box pb={2}>
                <IconButton
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0
                    }}
                    onClick={handleClose}
                    size="large"><ClearIcon /></IconButton>
            </Box>
            {
                (workspaceClients.value && workspaceProjects.value) &&
                <Box mb={2}>
                    <FavoriteProjects handleFavoriteClick={handleFavoriteClick} />
                </Box>
            }
            <Box mb={2}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item xs={12} sm container spacing={1} alignItems="center">
                        {
                            hiddenTask &&
                            <Grid item>
                                <VisibilityOffOutlinedIcon />
                            </Grid>
                        }
                        <Grid item xs>
                            <TextField
                                variant="standard"
                                fullWidth
                                inputProps={{ ref: subjectRef, style: {fontSize: 22} }}
                                placeholder={'Tarefa'}
                                value={questionSubject}
                                onChange={(e) => set_questionSubject(e.target.value)}
                                onKeyPress={handleKeyPress} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} container spacing={1} alignItems="flex-end">
                        <Grid item>
                            <LinkIcon />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="standard"
                                fullWidth
                                placeholder="Link"
                                value={taskUrl}
                                onChange={(e) => set_taskUrl(e.target.value)}
                                onKeyPress={handleKeyPress} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1} alignItems="flex-end">
                        <Grid item xs={12} sm={12} md>
                            <TextField
                                variant="standard"
                                fullWidth
                                multiline
                                placeholder="Informações adicionais"
                                value={questionDescription}
                                onChange={(e) => set_questionDescription(e.target.value)} />
                        </Grid>
                        {
                            (workspaceClients.value && workspaceProjects.value) &&
                            <Grid item xs={12} sm={12} md={8} container spacing={1}>
                                <Grid item xs={12} sm={8} md={8} container spacing={1} alignItems="flex-end">
                                    <Grid item xs={6} container spacing={1} alignItems="flex-end" wrap="nowrap">
                                        <Grid item>
                                            <HomeOutlinedIcon />
                                        </Grid>
                                        <Grid item xs>
                                            <Autocomplete
                                                placeholder="Cliente"
                                                value={selectedClient}
                                                options={Object.entries(workspaceClients.value).map(([clientId]) => clientId)}
                                                getOptionLabel={(option) => getProjectNameById(workspaceClients.value, option)}
                                                onChange={(e, value) => handle_selectedClientChange(value)}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </Grid>
                                    </Grid>
                                    {
                                        selectedClient &&
                                        <Grid item xs container spacing={1} alignItems="flex-end" wrap="nowrap">
                                            <Grid item>
                                                <FolderOutlinedIcon />
                                            </Grid>
                                            <Grid item xs>
                                                <Autocomplete
                                                    placeholder="Projeto"
                                                    value={selectedProject}
                                                    options={['none', ...projectOptions]}
                                                    getOptionLabel={(option) => {
                                                        if(option === 'none') return 'Nenhum';
                                                        return getProjectNameById(workspaceProjects.value, option);
                                                    }}
                                                    onChange={(e, value) => handle_selectedProjectChange(value)}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                                <Grid item xs container spacing={1} alignItems="center" wrap="nowrap">
                                    <Grid item>
                                        <DatePicker
                                            placeholder="Prazo"
                                            value={deadline ? moment(deadline) : null} onChange={(date) => set_deadline(date.toDate())}
                                            minDate={selectedTask ? undefined : moment()}
                                        />
                                    </Grid>
                                    {
                                        deadline &&
                                        <Grid item>
                                            <IconButton size="small" onClick={() => set_deadline(null)}><ClearIcon /></IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>
            {
                !selectedTask &&
                <Box mb={1}>
                    <Chip variant="outlined" label="Fazer hoje" sx={[classes.priority2Chip, taskPriority === 2 && classes.priority2ChipSelected]} onClick={() => setTaskPriority(2)} />&nbsp;
                    <Chip variant="outlined" label="Fazer logo" sx={[classes.priority1Chip, taskPriority === 1 && classes.priority1ChipSelected]} onClick={() => setTaskPriority(1)} />&nbsp;
                    <Chip variant="outlined" label="Em espera" sx={[classes.priority0Chip, taskPriority === 0 && classes.priority0ChipSelected]} onClick={() => setTaskPriority(0)} />
                </Box>
            }
            <Box mb={1}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <AccessTimeIcon />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">Estimativa:</Typography>
                    </Grid>
                    <Grid item xs container spacing={1} alignItems="center">
                        <Grid item>
                            <Chip label="15min" onClick={() => setEstimatedMinutesRequiredToComplete(15)} color={estimatedMinutesRequiredToComplete === 15 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="30min" onClick={() => setEstimatedMinutesRequiredToComplete(30)} color={estimatedMinutesRequiredToComplete === 30 ? 'primary' : 'default'} />&nbsp;
                            {/* <Chip label="45min" onClick={() => setEstimatedMinutesRequiredToComplete(45)} color={estimatedMinutesRequiredToComplete === 45 ? 'primary' : 'default'} />&nbsp; */}
                            <Chip label="1h" onClick={() => setEstimatedMinutesRequiredToComplete(60)} color={estimatedMinutesRequiredToComplete === 60 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="1h30" onClick={() => setEstimatedMinutesRequiredToComplete(90)} color={estimatedMinutesRequiredToComplete === 90 ? 'primary' : 'default'} />&nbsp;
                            <Chip label="2h" onClick={() => setEstimatedMinutesRequiredToComplete(120)} color={estimatedMinutesRequiredToComplete === 120 ? 'primary' : 'default'} />&nbsp;
                            {/* <Chip label="3h" onClick={() => setEstimatedMinutesRequiredToComplete(180)} color={estimatedMinutesRequiredToComplete === 180 ? 'primary' : 'default'} /> */}
                        </Grid>
                        <Grid item sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' } }}>
                            <AddOrSubtractDial
                                subtractDisabled={!estimatedMinutesRequiredToComplete || estimatedMinutesRequiredToComplete === 0}
                                subtractOnClick={() => setEstimatedMinutesRequiredToComplete(prevState => prevState - 15)}
                                number={estimatedMinutesRequiredToComplete || 0}
                                addOnClick={() => setEstimatedMinutesRequiredToComplete(prevState => prevState + 15)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xg: 'none' } }}>
                    <AddOrSubtractDial
                        subtractDisabled={!estimatedMinutesRequiredToComplete || estimatedMinutesRequiredToComplete === 0}
                        subtractOnClick={() => setEstimatedMinutesRequiredToComplete(prevState => prevState - 15)}
                        number={estimatedMinutesRequiredToComplete || 0}
                        addOnClick={() => setEstimatedMinutesRequiredToComplete(prevState => prevState + 15)}
                    />
                </Box>
            </Box>
            {
                (!selectedTask && ['general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role) && operators) &&
                <Box mb={1}>
                    <Grid container spacing={1} alignItems="center">
                        {
                            operators.map(operator => (
                                <Grid item key={operator.uid}>
                                    <Tooltip text={operator.name}>
                                        <Avatar
                                            sx={[classes.avatar, taskOperatorId === operator.uid && classes.avatarSelected]}
                                            alt={operator.name}
                                            src={operator.photoUrl}
                                            onClick={() => setTaskOperatorId(operator.uid)}
                                        />
                                    </Tooltip>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            }
            <Box>
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item>
                        <Switch label="Ocultar" checked={hiddenTask} onChange={() => set_hiddenTask(!hiddenTask)} />
                    </Grid>
                    <Grid item xs container spacing={1} alignItems="flex-end" justifyContent="flex-end">
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AssignmentOutlinedIcon />}
                                disabled={saveButtonDisabled}
                                onClick={handleSaveTaskButtonClick}
                            >Salvar tarefa</Button>
                        </Grid>
                        {
                            !selectedTask &&
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AccessTimeIcon />}
                                    disabled={saveButtonDisabled || !estimatedMinutesRequiredToComplete}
                                    onClick={() => handleSubmit('timesheet')}
                                >Só timesheet</Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Window>
    );
};

export default TaskWindow;