import { Route, Routes } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import ConnectedClient from './Client';
import ConnectedOperator from './ConnectedOperator';
import Dashboards from './Dashboards';
import PageNotFound from './PageNotFound';
import { useAppStateCtx } from '../context/AppState';
import { DocumentsProvider } from '../context/DocumentsContext';

const ActiveUserLoggedIn = () => {
    useSignals();
    const { activeUser } = useAppStateCtx();

    if(!activeUser.value) return null;

    return (
        <Routes>
            <Route path="/paineis" element={<Dashboards />} />
            <Route path="/:workspaceId/juridico/*" element={<DocumentsProvider><ConnectedOperator /></DocumentsProvider>} />
            <Route path="/:workspaceId/pastas/*" element={<DocumentsProvider><ConnectedClient /></DocumentsProvider>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default ActiveUserLoggedIn;