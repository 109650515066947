import signInWithEmailAndPassword from '../firebase/auth/signin';

class AuthService {
    login = (email, password) => {
        return new Promise(async (resolve, reject) => {
            const result = await signInWithEmailAndPassword(email, password);
            if(result.error){
                return reject(result.error);
            }
            resolve(result.result);
        });
    };
}

const authService = new AuthService();

export default authService;