import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Container } from '@mui/material';
import Input from '../../../../../components/Input';
import Dialog from '../../../../../components/Dialog/Dialog';
import { useAppStateCtx } from '../../../../../context/AppState';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';

const ListWindow = ({open, setOpen, data}) => {
    useSignals();
    const { selectedWorkspace, workspaceLists } = useAppStateCtx();
    const { selectedListId } = data;
    const defaultList = {
        name: {
            value: '',
            dirty: false
        },
        description: {
            value: '',
            dirty: false
        }
    };
    const [selectedList, set_selectedList] = useState(null);
    const [listValue, set_listValue] = useState(defaultList);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(open){
            let currentList = null;
            if(selectedListId && workspaceLists.value){
                currentList = workspaceLists.value[selectedListId];
            }
            set_selectedList(currentList);

            let currentName = '', currentDescription = '';
            if(currentList){
                currentName = currentList.name;
                currentDescription = currentList.description;
            }
            set_listValue({
                name: {value: currentName, dirty: false},
                description: {value: currentDescription, dirty: false}
            })
        }
    }, [open, selectedListId, workspaceLists.value]);

    const handleSave = async () => {
        setSaving(true);
        const toastId = toast.loading(`Criando lista "${listValue.name.value}"...`);
        const res = await selectedWorkspace.value.createList({
            name: listValue.name.value,
            description: listValue.description.value
        });
        setSaving(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            onSaveClick={handleSave}
            open={open}
            saveButtonDisabled={!listValue.name.value || !listValue.description.value}
            saving={saving}
            title={selectedList ? selectedList.name : 'Nova lista'}
        >
        
            <Container maxWidth="xs">
                <Box mb={2}>
                    <Input
                        label="Nome" fullWidth required
                        value={listValue.name.value} onChange={(e) => set_listValue(prevState => ({...prevState, name: {...prevState['name'], value: e.target.value}}))}
                        error={listValue.name.dirty && !listValue.name.value}
                        helperText={listValue.name.dirty && !listValue.name.value ? 'Esta pergunta é obrigatória' : ''}
                        onBlur={() => set_listValue(prevState => ({...prevState, name: {...prevState['name'], dirty: true}}))}
                    />
                </Box>
                <Box mb={2}>
                    <Input
                        label="Descrição" fullWidth required multiline
                        value={listValue.description.value} onChange={(e) => set_listValue(prevState => ({...prevState, description: {...prevState['description'], value: e.target.value}}))}
                        error={listValue.description.dirty && !listValue.description.value}
                        helperText={listValue.description.dirty && !listValue.description.value ? 'Esta pergunta é obrigatória' : ''}
                        onBlur={() => set_listValue(prevState => ({...prevState, description: {...prevState['description'], dirty: true}}))}
                    />
                </Box>
            </Container>
            
        </Dialog>
    );
};

export default ListWindow;