import { useEffect, useState } from 'react';
import { and, collection, limit, onSnapshot, or, query, orderBy, where } from 'firebase/firestore';

import Notice from '../classes/Notice';
import { useAppStateCtx } from '../context/AppState';
import { db } from '../firebase/config';

const useGetNotices = (shouldSetListener, settings) => {
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && selectedWorkspace.value){
            setHookState(defaultHookState);
            const constraints = [];
            const orConditions = [];
            if(settings.projectId || settings.clientId){
                orConditions.push(and(
                    where('workspaceId', '==', selectedWorkspace.value.uid),
                    where('clientId', '==', ''),
                    where('projectId', '==', '')
                ));
                if(settings.projectId){
                    orConditions.push(where('projectId', '==', settings.projectId));
                    if(settings.clientId){
                        orConditions.push(and(
                            where('clientId', '==', settings.clientId),
                            where('projectId', '==', '')
                        ));
                    }
                } else if(settings.clientId){
                    orConditions.push(and(
                        where('clientId', '==', settings.clientId),
                        where('projectId', '==', '')
                    ));
                }
                constraints.push(or(...orConditions));
            } else {
                constraints.push(where('workspaceId', '==', selectedWorkspace.value.uid));
            }
            const conditions = [
                collection(db, `notices`),
                and(
                    where('deleted', '==', false),
                    ...constraints
                ),
                orderBy('createdAt', 'desc'),
                limit(5)
            ];
            const unsubscribe = onSnapshot(
                query(...conditions),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newNotice = new Notice({ ...data, uid: snapshot.id })
                        dataArray.push(newNotice);
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, selectedWorkspace.value]);

    return hookState;
};

export default useGetNotices;