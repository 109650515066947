import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import { Box, Grid, Typography } from '@mui/material';

import FilesList from './FilesList';
import MultilineText from './MultilineText';

import { useAppStateCtx } from '../context/AppState';
import { getUserNameById } from '../utils/common';

const UserComment = ({
    comment, commentFiles, hasFiles, imageViewUrl, userId, userImage, userName, onClick, style
}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    if(!userName){
        userName = getUserNameById(workspaceUsers.value, userId) || '';
    }
    return (
        <Box>
            <Grid container spacing={2} alignItems="flex-start" style={{padding: 8}}>
                <Grid item>
                    <Avatar
                        style={{height: 32, width: 32}}
                        alt={userName}
                        src={userImage || undefined}>{userName.substring(0, 1)}</Avatar>
                </Grid>
                <Grid item xs>
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="body2"><strong>{userName}</strong></Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" sx={{ color: theme => theme.palette.grey[500] }}>{moment(comment.createdAt).format('D/M/YY H:mm')}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mb={1}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs>
                                <Typography variant="body1"><MultilineText text={comment.comment} /></Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        hasFiles &&
                        <FilesList list={commentFiles} imageViewUrl={imageViewUrl} />
                    }
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserComment;