import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import EmailIcon from '@mui/icons-material/Email';

import { useOperatorCtxClients, useOperatorCtxProjects } from '../../context/OperatorContext';

import User from '../../../../classes/User';
import Dialog from '../../../../components/Dialog/Dialog';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import MultipleAutocomplete from '../../../../components/MultipleAutocomplete';
import ResponsiveButton from '../../../../components/ResponsiveButton';
import Select from '../../../../components/Select';
import Switch from '../../../../components/Switch';
import VirtualizedAutocomplete from '../../../../components/VirtualizedAutocomplete';

import { useOperatorUsersCtxAPI, useOperatorCtxChangeUserView, useOperatorCtxUserTypeOptions } from '../../../../context/OperatorUsersContext';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../../context/AppState';
import { useAppCtxAPI } from '../../../../context/SystemContext';

import getDoc from '../../../../firebase/firestore/getDoc';

import useGetUserWorkspaceSettings from '../../../../hooks/useGetUserWorkspacePermissions';

import { copy, getUserProjects } from '../../../../utils/common';
import { EMAIL_REGEXP, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../utils/constants';

const changeUserEmailSettingsViewOpen = signal(false);
const loadingSelectedUserWorkspaceSettings = signal(true);
const selectedUserWorkspaceSettings = signal(null);

const ChangeUsersViewEmails = () => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { changeUserViewClickedUser: selectedUser } = useOperatorCtxChangeUserView();
    const defaultEmailSettingsState = {
        clearanceQuestionOrNoteDisabled: false,
        clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser: false,
        documentDeliveredByOperatorDisabled: false,
        documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser: false,
        documentFullySignedDisabled: false,
        documentSignedByThisUserEnabled: false
    };
    const [emailSettingsState, setEmailSettingsState] = useState(defaultEmailSettingsState);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        let currentState = {...defaultEmailSettingsState};
        if(changeUserEmailSettingsViewOpen.value && selectedUserWorkspaceSettings.value?.emails) currentState = {...currentState, ...selectedUserWorkspaceSettings.value.emails};
        setEmailSettingsState(currentState);
    }, [changeUserEmailSettingsViewOpen.value, selectedUserWorkspaceSettings.value]);

    const handleChangeOption = (e, optionId) => {
        setEmailSettingsState(prevState => ({...prevState, [optionId]: e.target.checked}));
    };

    const handleChange_documentDeliveredByOperatorDisabled = (e) => {
        const updates = {
            documentDeliveredByOperatorDisabled: e.target.checked
        };
        if(e.target.checked) updates.documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser = false;
        setEmailSettingsState(prevState => ({...prevState, ...updates}));
    };

    const handleChange_documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser = (e) => {
        const updates = {
            documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser: e.target.checked
        };
        if(e.target.checked) updates.documentDeliveredByOperatorDisabled = false;
        setEmailSettingsState(prevState => ({...prevState, ...updates}));
    };

    const handleChange_clearanceQuestionOrNoteDisabled = (e) => {
        const updates = {
            clearanceQuestionOrNoteDisabled: e.target.checked
        };
        if(e.target.checked) updates.clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser = false;
        setEmailSettingsState(prevState => ({...prevState, ...updates}));
    };

    const handleChange_clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser = (e) => {
        const updates = {
            clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser: e.target.checked
        };
        if(e.target.checked) updates.clearanceQuestionOrNoteDisabled = false;
        setEmailSettingsState(prevState => ({...prevState, ...updates}));
    };

    const handleSubmit = async () => {
        setSaving(true);
        const toastId = toast.loading(`Salvando configurações de e-mail...`);
        const res = await selectedUser.updateWorkspaceEmailSettings({ emailSettings: emailSettingsState, workspaceId: selectedWorkspace.value.uid });
        setSaving(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 3000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        changeUserEmailSettingsViewOpen.value = false;
    };

    return (
        <Dialog
            containerMaxWidth={false}
            onClose={handleClose}
            onSaveClick={handleSubmit}
            open={changeUserEmailSettingsViewOpen.value}
            saveButtonDisabled={saving}
            saving={saving}
            title={selectedUser?.name}

        >
            <Container maxWidth="md">
                <Box mb={3}>
                    <Box mb={2}>
                        <Box mb={1}>
                            <Typography variant="h5">E-mails</Typography>
                        </Box>
                        {
                            loadingSelectedUserWorkspaceSettings.value
                            ? <LoaderEllipsis />
                            :
                            <Box>
                                <Box mb={1}>
                                    <Switch label="Não receber e-mails com avisos sobre documentos entregues pelo revisor?"
                                        checked={emailSettingsState.documentDeliveredByOperatorDisabled}
                                        onChange={handleChange_documentDeliveredByOperatorDisabled}
                                    />
                                    <Switch label="Não receber e-mails com avisos sobre documentos entregues pelo revisor, exceto quando for o solicitante?"
                                        checked={emailSettingsState.documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser}
                                        onChange={handleChange_documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser}
                                    />
                                    <Switch label="Receber e-mails com comprovantes da própria assinatura em documentos?"
                                        checked={emailSettingsState.documentSignedByThisUserEnabled}
                                        onChange={(e) => handleChangeOption(e, 'documentSignedByThisUserEnabled')}
                                    />
                                    <Switch label="Não receber e-mails com avisos sobre documentos 100% assinados?"
                                        checked={emailSettingsState.documentFullySignedDisabled}
                                        onChange={(e) => handleChangeOption(e, 'documentFullySignedDisabled')}
                                    />
                                    <Switch label="Não receber e-mails com avisos sobre consultas e anotação de clearance?"
                                        checked={emailSettingsState.clearanceQuestionOrNoteDisabled}
                                        onChange={handleChange_clearanceQuestionOrNoteDisabled}
                                    />
                                    <Switch label="Não receber e-mails com avisos sobre consultas e anotação de clearance, exceto quando for o solicitante?"
                                        checked={emailSettingsState.clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser}
                                        onChange={handleChange_clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser}
                                    />
                                    {/*
                                    eSignatureStatusExpanded (users/X/_more/documents/)
                                    <SettingsRow
                                        booleanFieldKey="documentsSignaturesStatusExpanded" //TODO
                                        description="Expandir e manter visível a situação de assinatura eletrônica dos documentos"
                                        onChange={(e) => handleChangeOption(e, 'xxx')}
                                    /> */}
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Container>
        </Dialog>
    );
}

const ChangeUserView = () => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { mappedWorkspaceClients, mappedWorkspaceProjects } = useAppStateCtxUtils();

    const { redirect } = useAppCtxAPI();

    const clients = useOperatorCtxClients();
    const projects = useOperatorCtxProjects();
    const { hideChangeUserView } = useOperatorUsersCtxAPI();
    const { changeUserViewOpen, changeUserViewClickedUser: selectedUser } = useOperatorCtxChangeUserView();
    const userTypeOptions = useOperatorCtxUserTypeOptions();
    const [email, setEmail] = useState('');
    const [fullName, setFullName] = useState('');
    const [role, setRole] = useState('client');
    const [user_projects, setUserProjects] = useState([]);
    const [user_clients, setUserClients] = useState([]);
    const [saving, setSaving] = useState(false);

    const retrievedUserWorkspaceSettings = useGetUserWorkspaceSettings(selectedUser?.uid);
    useEffect(() => {
        if(changeUserViewOpen && selectedUser){
            if(retrievedUserWorkspaceSettings.loading){
                loadingSelectedUserWorkspaceSettings.value = true;
            } else {
                selectedUserWorkspaceSettings.value = retrievedUserWorkspaceSettings.data;
                if(retrievedUserWorkspaceSettings.data?.role){
                    setRole(retrievedUserWorkspaceSettings.data.role);
                    if(retrievedUserWorkspaceSettings.data.role === 'client'){
                        const { projects: userProjects, clients: userClients } = getUserProjects(retrievedUserWorkspaceSettings.data, clients, projects);
                        setUserClients(userClients.map(client => client.uid));
                        setUserProjects(userProjects.map(project => project.uid));
                    } else {
                        setUserClients([]);
                        setUserProjects([]);
                    }
                }
                loadingSelectedUserWorkspaceSettings.value = false;
            }
        }
    }, [changeUserViewOpen, retrievedUserWorkspaceSettings]);
    
    useEffect(() => {
        if(changeUserViewOpen){
            if(!selectedUser){
                clearForm();
            } else {
                setEmail(selectedUser.email);
                setFullName(selectedUser.name);
            }
        }
    }, [changeUserViewOpen, selectedUser]);

    const handleSubmit = async () => {
        setSaving(true);

        const toastId = toast.loading(`Salvando configurações para ${fullName}...`);
        
        if(!selectedUser){
            const newUser = new User({
                createdBy: activeUser.value.uid,
                email,
                name: fullName
            });
            const res = await newUser.createUser({
                clientsIds: user_clients,
                projectsIds: user_projects,
                role,
                workspaceId: selectedWorkspace.value.uid
            });
            setSaving(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 3000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        } else {
            let clientsIds, projectsIds;
            if(role === 'client'){
                clientsIds = user_clients;
                projectsIds = user_projects;
            }
            const res = await selectedUser.update({
                clientsIds,
                projectsIds,
                role,
                updates: {
                    name: fullName,
                },
                workspaceId: selectedWorkspace.value.uid
            });
            setSaving(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 3000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        handleClose();
    };

    const clearForm = () => {
        setEmail('');
        setFullName('');
        setRole('client');
        setUserProjects([]);
        setUserClients([]);
    };

    // const handleSignInAsUserClick = async () => {
    //     const res = await getDoc(`users/${selectedUser.uid}`);
    //     if(res.error){ 
    //         return console.log(res.error);
    //     }
    //     console.log('res', res)
    //     const currentActiveUser = res.result;
    //     if(currentActiveUser){
    //         const newActiveUser = new User({...currentActiveUser, uid: selectedUser.uid});
    //         activeUser.value = newActiveUser;
    //         redirect(newActiveUser);
    //     }
    // }

    const copyEmail = () => {
        copy(selectedUser.email, toast);
    }

    const handleClose = () => {
        hideChangeUserView();
    };

    return (
        <>
            <Dialog
                buttons={
                    selectedUser
                    ?
                    [
                        <ResponsiveButton
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<EmailIcon />}
                            onClick={() => changeUserEmailSettingsViewOpen.value = true}
                            label="E-mails"
                        />
                    ]
                    : []
                }
                containerMaxWidth={false}
                onClose={handleClose}
                onSaveClick={handleSubmit}
                open={changeUserViewOpen}
                saveButtonDisabled={(selectedUser && retrievedUserWorkspaceSettings.loading) || !fullName || !role || (!selectedUser && (!email || !EMAIL_REGEXP.test(email)))}
                saving={saving}
                title={!!selectedUser ? selectedUser.name : 'Cadastrar pessoa'}

            >
                <Container maxWidth="md">
                    {
                        (selectedUser && retrievedUserWorkspaceSettings.loading)
                        ? <LoaderEllipsis />
                        :
                        <Box mb={3}>
                            <Box mb={2}>
                                <Box mb={1}>
                                    <Typography variant="h5">Dados gerais</Typography>
                                </Box>
                                {
                                    selectedUser &&
                                    <Box mb={1}>
                                        <Typography
                                            variant="body1"
                                            color="primary"
                                            sx={{ cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
                                            onClick={copyEmail}
                                        >{selectedUser.email}</Typography>
                                    </Box>
                                }
                                <Box mb={1}>
                                    <Grid container spacing={1}>
                                        {
                                            !selectedUser &&
                                            <Grid size={{ xs: 12 }} container>
                                                <Grid size={{ xs: 12, sm: 4 }}>
                                                    <TextField
                                                        variant="standard"
                                                        label="E-mail"
                                                        fullWidth
                                                        required
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        error={(!!email && !EMAIL_REGEXP.test(email))}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid size="grow">
                                            <Box mb={1}>
                                                <TextField
                                                    variant="standard"
                                                    label="Nome"
                                                    fullWidth
                                                    required
                                                    value={fullName}
                                                    onChange={(e) => setFullName(e.target.value)} />
                                            </Box>
                                        </Grid>
                                        {
                                            ['operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                                            <Grid size={{ xs: 12, sm: 4 }}>
                                                <Select
                                                    label="Tipo" required
                                                    value={role} onChange={(e) => setRole(e.target.value)}
                                                    options={
                                                        userTypeOptions
                                                        .filter(option => !option.disabled)
                                                    }
                                                />
                                            </Grid>
                                        }
                                        {
                                            (mappedWorkspaceProjects.value && role === 'client') &&
                                            <Grid size={{ xs: 12 }}>
                                                <Box mb={1}>
                                                    <VirtualizedAutocomplete
                                                        multiple
                                                        getOptionLabel={(optionProjectId) => {
                                                            const foundProject = mappedWorkspaceProjects.value.find(project => project.uid === optionProjectId);
                                                            if(foundProject){
                                                                return foundProject.labelWithOrderNumberAndClientName;
                                                            }
                                                            return optionProjectId;
                                                        }}
                                                        label="Permissão para acessar projetos"
                                                        options={mappedWorkspaceProjects.value.map(project => project.uid)}
                                                        value={user_projects} onChange={(_, newValue) => setUserProjects(newValue)}
                                                    />
                                                </Box>
                                            </Grid>
                                        }
                                        {
                                            (['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) && mappedWorkspaceClients.value && role === 'client') &&
                                            <Grid size={{ xs: 12 }}>
                                                <Box mb={1}>
                                                    <VirtualizedAutocomplete
                                                        multiple
                                                        getOptionLabel={(optionClientId) => {
                                                            const foundClient = mappedWorkspaceClients.value.find(client => client.uid === optionClientId);
                                                            if(foundClient){
                                                                return foundClient.labelWithOrderNumber;
                                                            }
                                                            return optionClientId;
                                                        }}
                                                        label="Permissões para acessar clientes (com visualização de todos os projetos do cliente)"
                                                        options={mappedWorkspaceClients.value.map(client => client.uid)}
                                                        value={user_clients} onChange={(_, newValue) => setUserClients(newValue)}
                                                    />
                                                </Box>
                                            </Grid>
                                        }
                                    </Grid>
                                </Box>
                            </Box>
                            {
                                //TODO
                                // ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                                // <Grid container justifyContent="center">
                                //     <Grid>
                                //         <Button onClick={handleSignInAsUserClick}>Entrar com as credenciais deste usuário</Button>
                                //     </Grid>
                                // </Grid>
                            }
                        </Box>
                    }
                </Container>
            </Dialog>
            <ChangeUsersViewEmails />
        </>
    );
}

export default ChangeUserView;