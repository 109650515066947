import { lazy, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from '../context/AppState';
import { useFolderUserCtxLoadFolder } from './Client/context/ClientContext';
import LazyLoadedComponent from '../components/LazyLoadedComponent';
import Docs from '../pages/Client/Docs';
import Forms from '../pages/Client/Forms';
import Notices from '../pages/Client/Notices';
import RequestReview from '../pages/Client/RequestReview';
import Folders from '../pages/Client/Folders';
import PageNotFound from '../pages/PageNotFound';

const LazyLoadedClearance = lazy(() => import('../pages/Client/Clearance'));
const LazyLoadedDeletedDocuments = lazy(() => import('../pages/Client/DeletedDocuments'));
const LazyLoadedDocumentNotifications = lazy(() => import('../pages/Client/DocNotifications'));
const LazyLoadedRegistrationForms = lazy(() => import('../pages/Client/RegistrationForms'));
const LazyLoadedReports = lazy(() => import('../pages/Client/Reports'));
const LazyLoadedSignDocuments = lazy(() => import('../pages/Client/Docs/Sign'));

const ConnectedFolderUser = () => {
    useSignals();
    const { activeUser, selectedFolder, selectedWorkspace } = useAppStateCtx();
    const loadFolder = useFolderUserCtxLoadFolder();
    const params = useParams();
    const folderId = params.folderId;

    useEffect(() => {
        if(activeUser.value && selectedWorkspace.value && !selectedFolder.value){
            loadFolder({ activeUser: activeUser.value, clientOrProjectId: folderId, selectedWorkspaceId: selectedWorkspace.value.uid, type: 'project' });
        }
    }, [activeUser.value, selectedWorkspace.value, selectedFolder.value]);

    if(!selectedFolder.value) return null;

    return (
        <Routes>
            <Route path="/avisos" element={<Notices />} />
            <Route path="/documentos" element={<Docs />} />
            <Route path="/documentos/lixeira" element={<LazyLoadedComponent component={<LazyLoadedDeletedDocuments />} />} />
            <Route path="/assinar" element={<LazyLoadedComponent component={<LazyLoadedSignDocuments />} />} />
            <Route path="/formularios/*" element={<Forms />} />
            <Route path="/revisao" element={<RequestReview />} />
            <Route path="/documentos/alertas" element={<LazyLoadedComponent component={<LazyLoadedDocumentNotifications />} />} />
            <Route path="/fichas" element={<LazyLoadedComponent component={<LazyLoadedRegistrationForms />} />} />
            <Route path="/clearance/*" element={<LazyLoadedComponent component={<LazyLoadedClearance />} />} />
            <Route path="/relatorios" element={<LazyLoadedComponent component={<LazyLoadedReports />} />} />
            <Route path="/pastas" element={<Folders />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default ConnectedFolderUser;