import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Profile from '../../../components/Profile';
import ChangeUserBooleanField from '../../../components/ChangeUserBooleanField';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import { useAppStateCtx } from '../../../context/AppState';

const ClientProfile = () => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const [userState, setUserState] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let currentUserState = null;
        if(activeUser.value && selectedWorkspace.value){
            currentUserState = {
                clearanceQuestionOrNoteDisabled: !!selectedWorkspace.value.emails.clearanceQuestionOrNoteDisabled,
                clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser: !!selectedWorkspace.value.emails.clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser,
                documentDeliveredByOperatorDisabled: !!selectedWorkspace.value.emails.documentDeliveredByOperatorDisabled,
                documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser: !!selectedWorkspace.value.emails.documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser,
                documentFullySignedDisabled: !!selectedWorkspace.value.emails.documentFullySignedDisabled,
                documentSignedByThisUserEnabled: !!selectedWorkspace.value.emails.documentSignedByThisUserEnabled,
                uid: activeUser.value.uid,
            };
            setLoading(false);
        }
        setUserState(currentUserState);
    }, [activeUser.value, selectedWorkspace.value]);

    return (
        <Profile>
            <Box my={2}>
                <Box mb={2}>
                    <Typography variant="h5">Opções</Typography>
                </Box>
                {
                    loading
                    ? <LoaderEllipsis />
                    :
                    <Box>
                        <ChangeUserBooleanField userState={userState} setUserState={setUserState} fieldKey="clearanceQuestionOrNoteDisabled" />
                        <ChangeUserBooleanField userState={userState} setUserState={setUserState} fieldKey="clearanceQuestionOrNoteDisabledExceptIfCreatedByThisUser" />
                        <ChangeUserBooleanField userState={userState} setUserState={setUserState} fieldKey="documentDeliveredByOperatorDisabled" />
                        <ChangeUserBooleanField userState={userState} setUserState={setUserState} fieldKey="documentDeliveredByOperatorDisabledExceptIfCreatedByThisUser" />
                        <ChangeUserBooleanField userState={userState} setUserState={setUserState} fieldKey="documentFullySignedDisabled" />
                        <ChangeUserBooleanField userState={userState} setUserState={setUserState} fieldKey="documentSignedByThisUserEnabled" />
                    </Box>
                }
            </Box>
        </Profile>
    );
}

export default ClientProfile;