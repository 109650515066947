import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';

import { useFolderUserCtxLoadFolder, useFolderUserCtxAPI } from '../context/ClientContext';

import FoldersList from '../../../components/FoldersList';
import LoaderEllipsis from '../../../components/LoaderEllipsis';
import Input from '../../../components/Input';
import ViewBox from '../../../components/ViewBox';

import { useAppStateCtx } from '../../../context/AppState';
import { useAppCtxAPI } from '../../../context/SystemContext';

const filterText = signal('');
const loading = signal(true);
const userClientsAndProjects = signal(null);

const UserClientsAndProjectsList = ({onClientOrProjectClick}) => {
    useSignals();
    const { loadingApp } = useAppStateCtx();

    useEffect(() => {
        if(userClientsAndProjects.value){
            loading.value = false;
            loadingApp.value = false;
        }
    }, [userClientsAndProjects.value]);

    if(loading.value){
        return (
            <LoaderEllipsis />
        )
    }

    if(userClientsAndProjects.value){
        return (
            <Fade in={userClientsAndProjects.value.length !== 0} timeout={300}>
                <Box>
                    <FoldersList
                        foldersList={userClientsAndProjects.value.filter(clientOrProject => clientOrProject.name.toUpperCase().includes(filterText.value.toUpperCase()))}
                        handleFolderClick={onClientOrProjectClick}
                    />
                </Box>
            </Fade>
        )
    }
    return null;
};

const Home = () => {
    useSignals();
    const { workspaceId } = useParams();
    const { activeUser, loadingApp, loadingAppText, selectedFolder, selectedWorkspace } = useAppStateCtx();
    const { handleNavigate } = useAppCtxAPI();
    const loadFolder = useFolderUserCtxLoadFolder();
    const { setProjects } = useFolderUserCtxAPI();

    const getProjectAndClients = async () => {
        const res = await activeUser.value.getProjectAndClients(selectedWorkspace.value.uid, Object.keys(selectedWorkspace.value.projects || {}));
        if(res.error){
            toast(res.error, { type: 'error' });
        } else if(res.result){
            setProjects(res.result.projects);
            userClientsAndProjects.value = res.result.projects;
        }
    };

    useEffect(() => {
        selectedFolder.value = null;
        return () => {
            loading.value = true;
            userClientsAndProjects.value = null;
        }
    }, []);

    useEffect(() => {
        if(activeUser.value && selectedWorkspace.value){
            getProjectAndClients();
        }
    }, [activeUser.value, selectedWorkspace.value]);
    
    const handleFolderClick = (clientOrProject) => {
        loadingAppText.value = `Carregando ${clientOrProject.name}...`;
        loadingApp.value = true;
        loadFolder({ activeUser: activeUser.value, clientOrProjectId: clientOrProject.uid, selectedWorkspaceId: selectedWorkspace.value.uid, type: 'project' }, () => {
            const path = `/${workspaceId}/pastas/${clientOrProject.shortName || clientOrProject.uid}/avisos`;
            handleNavigate(path);
        });
    };

    const handleFilterTextChange = (e) => {
        filterText.value = e.target.value;
    };

    const clearFilterText = () => {
        filterText.value = '';
    };

    return (
        <ViewBox>
            <Container maxWidth="xs">
                <Box mb={2}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid size={{ xs: 12 }}>
                            <Input
                                fullWidth
                                placeholder="Filtrar"
                                value={filterText.value} onChange={handleFilterTextChange}
                                startIcon={<FilterListIcon />}
                                endIcon={<ClearIcon />}
                                endIconOnClick={clearFilterText}
                                endIconTitle="Limpar"
                            />
                        </Grid>
                    </Grid>
                </Box>
                <UserClientsAndProjectsList onClientOrProjectClick={handleFolderClick} />
            </Container>
        </ViewBox>
    );
}

export default Home;