import { collection, doc, getCountFromServer, increment, query, runTransaction, where } from 'firebase/firestore';

import ShortUniqueId from 'short-unique-id';

import { db } from '../firebase/config';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';
import { getKeywords } from '../utils/common';

const uploadClearanceQuestionCommentFiles = async (clearanceQuestion: { [key: string]: any }, filesList: any, filesCount: number) => {
    const files: { [fileId: string]: any } = {};
    if(filesList){
        for(const file of filesList){
            filesCount++;
            const { contentType, fileBuffer } = await getFileContent(file);
            const { result } = await uploadFile(
                `workspaces/${clearanceQuestion.workspaceId}/projects/${clearanceQuestion.projectId}/clearance/questions/${clearanceQuestion.uid}/uploads/${file.name}`, // storageFilePath
                fileBuffer,
                contentType
            );
            if(result){
                const { downloadUrl, storageFilePath } = result;
                // @ts-ignore
                const fileId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
                files[fileId] = {
                    firebaseStoragePath: storageFilePath,
                    url: downloadUrl
                };
            }
        }
    }
    return { files, filesCount };
};

export default class ClearanceQuestion {
    approvals: { [userId: string]: string } = {};
    approved = false;
    awaitingOperatorReview = false;
    commentsCount = 0;
    commentsReadAt: {
        [userId: string]: string;
    } = {};
    createdAt = '';
    createdBy = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    episode: number | null = null;
    filesCount: number = 0;
    firstReplySentByOperator = false;
    groupId = '';
    keywords: string[] = [];
    lastComment: {
        approval: string;
        createdAt: string;
        createdBy: string;
        comment: string;
        files: {
            [fileId: string]: {
                firebaseStoragePath: string;
                name: string;
                url: string;
            }
        };
        rightType: string;
        rightTypeDescription: string;
        riskLevel: string;
        riskLevelDescription: string;
        uid: string;
        useType: string;
        useTypeDescription: string;
    } | null = null;
    operatorId = '';
    projectId = '';
    referenceNumber: number | null = null;
    rightType = '';
    rightTypeDescription = '';
    riskLevel = '';
    riskLevelDescription = '';
    scriptScene: number | null = null;
    scriptTreatment = '';
    subject = '';
    uid = '';
    useType = '';
    useTypeDescription = '';
    workspaceId = '';

    constructor({
        approvals,
        approved,
        awaitingOperatorReview,
        commentsCount,
        commentsReadAt,
        createdAt,
        createdBy,
        deleted,
        deletedAt,
        deletedBy,
        episode,
        filesCount,
        firstReplySentByOperator,
        groupId,
        keywords,
        lastComment,
        operatorId,
        projectId,
        referenceNumber,
        rightType,
        rightTypeDescription,
        riskLevel,
        riskLevelDescription,
        scriptScene,
        scriptTreatment,
        subject,
        uid,
        useType,
        useTypeDescription,
        workspaceId
    }: ClearanceQuestion){
        if(approvals) this.approvals = approvals;
        if(approved) this.approved = approved;
        if(awaitingOperatorReview) this.awaitingOperatorReview = awaitingOperatorReview;
        if(commentsCount) this.commentsCount = commentsCount;
        if(commentsReadAt) this.commentsReadAt = commentsReadAt;
        this.createdAt = createdAt || new Date().toISOString();
        if(createdBy) this.createdBy = createdBy;
        if(deleted) this.deleted = deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        if(episode) this.episode = episode;
        if(filesCount) this.filesCount = filesCount;
        if(firstReplySentByOperator) this.firstReplySentByOperator = firstReplySentByOperator;
        if(groupId) this.groupId = groupId;
        if(keywords) this.keywords = keywords;
        if(lastComment) this.lastComment = lastComment;
        if(operatorId) this.operatorId = operatorId;
        if(projectId) this.projectId = projectId;
        if(referenceNumber) this.referenceNumber = referenceNumber;
        if(rightType) this.rightType = rightType;
        if(rightTypeDescription) this.rightTypeDescription = rightTypeDescription;
        if(riskLevel) this.riskLevel = riskLevel;
        if(riskLevelDescription) this.riskLevelDescription = riskLevelDescription;
        if(scriptScene) this.scriptScene = scriptScene;
        if(scriptTreatment) this.scriptTreatment = scriptTreatment;
        if(subject) this.subject = subject;
        if(uid) this.uid = uid;
        if(useType) this.useType = useType;
        if(useTypeDescription) this.useTypeDescription = useTypeDescription;
        if(workspaceId) this.workspaceId = workspaceId;
    }

    async cancel(activeUserId: string){
        let result = null, error = null;

        const clearanceQuestionRef = doc(db, `clearance_questions/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clearanceQuestionRef, {
                    deleted: true,
                    deletedAt: new Date().toISOString(),
                    deletedBy: activeUserId
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        
        return { result, error };
    }

    async createClearanceQuestion(activeUser: any, comment: {[key: string]: any}, filesList: any){
        let result = null, error = null;

        const { files, filesCount } = await uploadClearanceQuestionCommentFiles(this, filesList, 0);

        const clearanceQuestionsRef = doc(collection(db, `clearance_questions`));
        const clearanceQuestionCommentRef = doc(collection(db, `clearance_questions/${clearanceQuestionsRef.id}/comments`));

        const newComment = {
            approval: null,
            createdAt: this.createdAt,
            createdBy: this.createdBy,
            createdByName: activeUser.name,
            deleted: false,
            deletedAt: '',
            deletedBy: '',
            comment,
            files,
            rightType: this.rightType,
            rightTypeDescription: this.rightTypeDescription,
            riskLevel: '',
            riskLevelDescription: '',
            useType: '',
            useTypeDescription: '',
        };

        const {uid: _, ...newClearanceNote} = this;

        try {

            const snapshot = await getCountFromServer(query(collection(db, 'clearance_questions'), where('projectId', '==', this.projectId)))
            const data = snapshot.data();
            const count = data.count || 0;
            const referenceNumber = count + 1;

            await runTransaction(db, async (transaction) => {
                transaction.set(clearanceQuestionsRef, {
                    ...newClearanceNote,
                    commentsCount: 1,
                    commentsReadAt: {
                        [this.createdBy]: this.createdAt
                    },
                    filesCount,
                    keywords: getKeywords(newClearanceNote.subject, comment),
                    lastComment: {...newComment, uid: clearanceQuestionCommentRef.id},
                    referenceNumber
                });
                transaction.set(clearanceQuestionCommentRef, newComment);
            });

            result = true;

        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async delete(){
        let result = null, error = null;

        const clearanceQuestionRef = doc(db, `clearance_questions/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.delete(clearanceQuestionRef);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        
        return { result, error };
    }

    async reply({ activeUser, activeUserIsOperator, comment, comments, filesList, numberOfApprovalsRequired, rightType, rightTypeDescription, riskApprovalDescription, riskLevel, riskLevelDescription, useType, useTypeDescription }: {
        activeUser: any;
        activeUserIsOperator: boolean;
        comment: string;
        comments: any[];
        filesList: any[];
        numberOfApprovalsRequired: number;
        rightType: string;
        rightTypeDescription: string;
        riskApprovalDescription: 'yes' | 'no' | 'withholdOpinion';
        riskLevel: string;
        riskLevelDescription: string;
        useType: string;
        useTypeDescription: string;
    }){
        let result = null, error = null;

        const { files, filesCount } = await uploadClearanceQuestionCommentFiles(this, filesList || [], this.filesCount);

        const now = new Date();
        const nowISOString = now.toISOString();

        const clearanceQuestionRef = doc(db, `clearance_questions/${this.uid}`);
        const clearanceQuestionCommentRef = doc(collection(db, `clearance_questions/${this.uid}/comments`));
        
        const newComment: { [key: string]: any } = {
            createdAt: nowISOString,
            createdBy: activeUser.uid,
            createdByName: activeUser.name,
            comment: comment,
            deleted: false,
            deletedAt: '',
            deletedBy: '',
            files,
            rightType: rightType || '',
            rightTypeDescription: rightTypeDescription || '',
            riskLevel: riskLevel || '',
            riskLevelDescription: riskLevelDescription || '',
            useType: useType || '',
            useTypeDescription: useTypeDescription || '',
        }

        const updates: { [key: string]: any } = {
            commentsCount: increment(1),
            [`commentsReadAt.${activeUser.uid}`]: nowISOString,
            filesCount,
            lastComment: {
                ...newComment,
                uid: clearanceQuestionCommentRef.id
            },
            rightType: rightType || '',
            rightTypeDescription: rightTypeDescription || '',
            riskLevel: riskLevel || '',
            riskLevelDescription: riskLevelDescription || '',
            useType: useType || '',
            useTypeDescription: useTypeDescription || '',
        };
        if(activeUserIsOperator){
            updates.operatorId = activeUser.uid;
            updates.firstReplySentByOperator = true;
        }
        updates.awaitingOperatorReview = !activeUserIsOperator;

        updates.keywords = getKeywords(this.subject);

        if(['yes', 'no'].includes(riskApprovalDescription)){
            newComment.approval = riskApprovalDescription;
            this.approvals[activeUser.uid] = riskApprovalDescription;
            updates[`approvals.${activeUser.uid}`] = riskApprovalDescription;

            let approvalsGiven = 0, approvalsDenied = 0;
            for(const userId in this.approvals){
                const type = this.approvals[userId];
                if(type === 'yes'){
                    approvalsGiven++;
                } else if(type === 'no'){
                    approvalsDenied++;
                }
            }
            if((approvalsGiven - approvalsDenied) >= (numberOfApprovalsRequired || 0)){
                updates.approved = 'yes';
            } else if((approvalsGiven - approvalsDenied) < 0){
                updates.approved = 'no';
            }
        }

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(clearanceQuestionCommentRef, newComment);
                transaction.update(clearanceQuestionRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }
        
        return { result, error };
    }

    async update({ updates }: {
        updates: {[key: string]: any};
    }){
        let result = null, error = null;

        const clearanceQuestionRef = doc(db, `clearance_questions/${this.uid}`);

        if(updates.subject){
            updates.keywords = getKeywords(updates.subject);
        } 

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clearanceQuestionRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}