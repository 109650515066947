import { useState } from 'react';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import SearchFieldContainer from './SearchFieldContainer';
import Tooltip from './Tooltip';

const SearchEngineTextField = ({disabled, onEndIconClick, onSearch, placeholder}) => {
    const [searchEngineTextFieldValue, setSearchEngineTextFieldValue] = useState('');

    const handleKeyDown = (e) => {
        let pressedKey = e.key;
        if(pressedKey === 'Enter'){
            handleSearch(searchEngineTextFieldValue);
        } else if(pressedKey === 'Escape'){
            setSearchEngineTextFieldValue('');
        }
    };

    const handleSearch = (newValue) => {
        if(onSearch) onSearch(newValue);
    };

    return (
        <SearchFieldContainer container columnSpacing={1} alignItems="center" wrap="nowrap">
            <Grid>
                <Tooltip text="Pesquisa" enterDelay={500}>
                    <IconButton
                        size="small"
                        onClick={() => handleSearch(searchEngineTextFieldValue)}
                    >
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid size="grow">
                <TextField
                    variant="standard"
                    disabled={disabled}
                    fullWidth
                    placeholder={placeholder || "Pesquisar documentos"}
                    InputProps={{
                        disableUnderline: true
                    }}
                    onChange={(e) => setSearchEngineTextFieldValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    value={searchEngineTextFieldValue}
                />
            </Grid>
            {
                searchEngineTextFieldValue &&
                <Grid>
                    <Tooltip text="Limpar pesquisa" enterDelay={500}>
                        <IconButton
                            size="small"
                            onClick={() => setSearchEngineTextFieldValue('')}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            {
                onEndIconClick &&
                <Grid>
                    <Tooltip text="Mostrar opções de pesquisa" enterDelay={500}>
                        <IconButton
                            size="small"
                            onClick={onEndIconClick}
                        >
                            <TuneIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
        </SearchFieldContainer>
    );
};

export default SearchEngineTextField;