import { useSignals } from '@preact/signals-react/runtime';

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

import Header from '../../../../components/Header';
import HeaderWorkspaceImage from '../../../../components/HeaderWorkspaceImage';
import { useAppStateCtx } from '../../../../context/AppState';
import { HEADER_HEIGHT } from '../../../../utils/constants';

import Account from '../../Account';
import { useFolderUserCtxAPI, useFolderUserCtxSidenavMobile } from '../../context/ClientContext';

const classes = {
    title: {
        color: 'grey',
        fontFamily: `"Titillium Web", sans-serif`,
        fontWeight: 700,
        fontStyle: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

const FolderName = () => {
    useSignals();
    const { selectedFolder } = useAppStateCtx();
    if(selectedFolder.value){
        return (
            <Typography variant="h1" sx={classes.title}>{selectedFolder.value.name.toUpperCase()}</Typography>
        )
    }
    return null;
}

const PageTitle = () => {
    useSignals();
    const { pageTitle } = useAppStateCtx();
    const theme = useTheme();
    const screenMDDownExclusive = useMediaQuery(theme.breakpoints.down('md'));

    if(!screenMDDownExclusive && pageTitle.value){
        return (
            <>
                <Typography variant="h2" sx={[classes.title, { fontWeight: 400 }]}>|</Typography>
                <Typography variant="h2" sx={classes.title}>{pageTitle.value}</Typography>
            </>
        )
    }
    return null;
}

const ClientHeader = () => {
    useSignals();
    const { setSidenavMobileOpen } = useFolderUserCtxAPI();
    const sidenavMobileOpen = useFolderUserCtxSidenavMobile();

    return (
        <Header
            leftBox={
                <Grid container spacing={2} alignItems="center" wrap="nowrap" sx={{ height: HEADER_HEIGHT }}>
                    <HeaderWorkspaceImage />
                    <FolderName />
                    <PageTitle />
                </Grid>
            }
            onlineUsers={
                <></>
            }
            account={<Account />}
            data={{
                sidenavMobileOpen, set_sidenavMobileOpen: setSidenavMobileOpen
            }}
        />
    );
};

export default ClientHeader;