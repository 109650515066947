import moment from 'moment';

import FormQuestionDate from '../../../../components/FormQuestionDate';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const DateQuestion = ({question, setQuestionValid}) => {
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'date',
        setQuestionValid,
    });

    const handleChange = (questionName, updates) => {
        const input = updates.value ? moment(updates.value).format('L') : '';
        setUserFormQuestion(prevState => ({
            ...prevState,
            value: updates.value,
            input,
        }));
    };
    
    return (
        <FormQuestionDate
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
        />
    );
}

export default DateQuestion;