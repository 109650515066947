import { useEffect, useLayoutEffect, useState } from 'react';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ChangeProjectClearanceInformationWindow from '../../../../components/ChangeProjectClearanceInformationWindow';
import ChangeProjectSignersWindow from '../../../../components/ChangeProjectSignersWindow';
import ChangeProjectTemplatesWindow from '../../../../components/ChangeProjectTemplatesWindow';
import ChangeFolderView from './components/DataDrawer';
import FilterInput from '../../../../components/FilterInput';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import GroupsWindow from '../../../../components/GroupsWindow';
import Pagination from '../../../../components/Pagination';
import TinyTable from '../../../../components/TinyTable';
import ViewBox from '../../../../components/ViewBox';
import { useAppStateCtx } from '../../../../context/AppState';
import { sortByKey } from '../../../../utils/filters';
import { escapeRegex, getFolderCompanyName, removeDiacritics } from '../../../../utils/common';

const filterText = signal('');

const FilterText = () => {
    useSignals();
    const [value, setValue] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            filterText.value = value;
        }, 250);
        return () => clearTimeout(delayDebounceFn);
    }, [value])

    return (
        <FilterInput value={value} setValue={setValue} placeholder="Digite para filtrar" />
    );
};

const Pages = ({ count, currentPage, perPage, handlePageClick }) => {
    if(filterText.value.length === 0){
        return (
            <Pagination count={count} page={currentPage} perPage={perPage} onChange={(_, page) => handlePageClick(page)} />
        );
    }
    return null;
};

const projectViewOpen = signal(false);
const projectViewClickedProjectId = signal('');

const Projects = () => {
    useSignals();
    const { workspaceClients, workspaceProjects } = useAppStateCtx();
    const [loading, setLoading] = useState(true);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [visibleProjects, setVisibleProjects] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if(workspaceProjects.value){
            setAvailableProjects(
                Object.entries(workspaceProjects.value).map(([projectId, project]) => {
                    const clientName = getFolderCompanyName(workspaceClients.value, project);
                    return { ...project, uid: projectId, clientName };
                }).sort(sortByKey('name'))
            );
        }
    }, [workspaceProjects.value]);

    useEffect(() => {
        if(workspaceProjects.value && visibleProjects){
            setLoading(false);
        }
    }, [workspaceProjects.value, visibleProjects]);

    const perPage = 100;

    const showPage = () => {
        if(availableProjects){
            const firstIndex = (currentPage - 1) * perPage;
            const lastIndex = (currentPage * perPage);
            const pageArray = availableProjects.slice(firstIndex, lastIndex);
            setVisibleProjects(pageArray);
        }
    }

    useLayoutEffect(() => {
        if(filterText.value.length !== 0){
            setVisibleProjects(
                availableProjects.filter(project => {
                    let textFields = `${project.name}${project.clientName}`;
                    textFields = removeDiacritics(textFields);
                    if(filterText.value.length === 1){
                        const regEx = new RegExp(`^${escapeRegex(removeDiacritics(filterText.value)).toUpperCase()}`); 
                        return regEx.test(textFields.toUpperCase());
                    }
                    return textFields.toUpperCase().indexOf(removeDiacritics(filterText.value).toUpperCase()) !== -1;
                })
            )
        } else {
            showPage();
        }
    }, [filterText.value, availableProjects, currentPage]);
    
    const handleAddFolderButtonClick = () => {
        projectViewClickedProjectId.value = '';
        projectViewOpen.value = true;
    };

    const handleProjectRowClick = (clickedProject) => {
        projectViewClickedProjectId.value = clickedProject.data.uid;
        projectViewOpen.value = true;
    }

    const handlePageClick = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>
            
            <Box mb={2}>
                <Grid container justifyContent="flex-end" alignContent="flex-end">
                    <Grid size="grow">
                        <FilterText />
                    </Grid>
                    <Grid size={{ xs: 6 }}></Grid>
                    <Grid>
                        <Button variant="contained" onClick={handleAddFolderButtonClick} startIcon={<AddIcon />}>Nova pasta</Button>
                    </Grid>
                </Grid>
            </Box>

            {
                loading
                ? <LoaderEllipsis />
                :
                <>
                    <Pages count={availableProjects.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    <Box pb={5}>
                        <Container maxWidth="md">
                            <TinyTable
                                head={[
                                    { content: '' },
                                    { content: <Typography variant="body1">Projeto</Typography> },
                                    { content: <Typography variant="body1">Cliente</Typography> },
                                ]}
                                body={
                                    visibleProjects
                                    .sort(sortByKey('name'))
                                    .map((mappedProject) => {
                                        const columns = [
                                            {
                                                content:
                                                    mappedProject.logoUrl
                                                    ? <Box><img src={mappedProject.logoUrl} alt={mappedProject.name} loading="lazy" style={{ maxWidth: '100px' }} /></Box>
                                                    : '',
                                            },
                                            { content: <Typography variant="body2">{mappedProject.name}</Typography> },
                                            { content: <Typography variant="body2">{mappedProject.clientName}</Typography> },
                                        ];
                                        return ({
                                            data: mappedProject,
                                            columns
                                        })
                                    })
                                }
                                handleBodyRow={handleProjectRowClick}
                            />
                        </Container>
                        <Pages count={availableProjects.length} currentPage={currentPage} perPage={perPage} handlePageClick={handlePageClick} />
                    </Box>
                </>
            }

            <ChangeFolderView open={projectViewOpen} clickedProjectId={projectViewClickedProjectId} />
            <GroupsWindow activeUserIsOperator />
            <ChangeProjectClearanceInformationWindow />
            <ChangeProjectSignersWindow type="project" />
            <ChangeProjectTemplatesWindow />
        </ViewBox>
    );
}

export default Projects;