import FormQuestionRadio from '../../../../components/FormQuestionRadio';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const RadioQuestion = ({question, setQuestionValid}) => {
    const { checkValidation, initialValue, setUserFormQuestion, touchedField } = useDocumentFormQuestion({
        question,
        questionType: 'radio',
        setQuestionValid,
    });

    const handleGroupFocus = () => {
        touchedField.current = true;
    };

    const handleGroupBlur = () => {
        checkValidation();
    };

    const handleChange = (questionName, updates) => {
        setUserFormQuestion(prevState => ({
            ...prevState,
            ...updates
        }));
    };
    
    return (
        <FormQuestionRadio
            onChange={handleChange}
            onBlur={handleGroupBlur}
            onFocus={handleGroupFocus}
            question={question}
            initialValue={initialValue}
        />
    );
};

export default RadioQuestion;