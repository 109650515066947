import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import FlagIcon from '@mui/icons-material/Flag';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CustomSelect from '../pages/Client/Docs/components/CustomSelect';
import CustomText from '../pages/Client/Docs/components/CustomText';
import DataListItem from './DataListItem';
import DocumentFlag from './DocumentFlag';
import DocumentListItemTopClient from './DocumentListItemTopClient';
import DocumentListItemTopCreator from './DocumentListItemTopCreator';
import DocumentListItemTopProject from './DocumentListItemTopProject';
import Tooltip from './Tooltip';
import VersionRowMainColumn from './VersionRowMainColumn';

import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';

const CustomFields = ({listDocument}) => {
    useSignals();
    const { projectDocumentSettings } = useAppStateCtx();
    const { selectedFolderIsClient } = useAppStateCtxUtils();

    if(!selectedFolderIsClient.value && projectDocumentSettings.value?.customFields?.enabled){
        return (
            <Grid item xs={12} sm={12} md={2} container spacing={1} alignItems="center">
                {
                    Object.entries(projectDocumentSettings.value?.customFields.fields).map(([fieldId, field]) => (
                        <Grid item xs={12} sm={6} md={12} key={fieldId}>
                            <Box>
                                <Typography variant="h6">{field.name}</Typography>
                            </Box>
                            {
                                field.type === 'text' &&
                                <CustomText fieldId={fieldId} doc={listDocument} />
                            }
                            {
                                field.type === 'select' &&
                                <CustomSelect field={field} fieldId={fieldId} doc={listDocument} />
                            }
                        </Grid>
                    ))
                }
            </Grid>
        );
    }
    return null;
};

const DocumentListItem = ({
    activeUserIsManager,
    activeUserIsOperator,
    boxStyle,
    comment,
    noHtmlComment,
    documentFolder,
    eSignature,
    companyName,
    folderName,
    groupName,
    handleActionMenuOpen,
    handleFlagDocumentButtonClick,
    listDocument,
    projects,
    setSelectedDocument,
    showCompany,
    useGroups
}) => {
    const theme = useTheme();
    const screenMDUpInclusive = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <DataListItem dense boxStyle={boxStyle} showMoreOptionsFab handleMoreOptionsShownOnMouseOverFab={(e) => handleActionMenuOpen(e, listDocument)}>
            <Box mb={0.5}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={3} sm={2} md={2}>
                        <Tooltip text={moment(listDocument.createdAt).format('D/M/YY H:mm')} enterDelay={400}>
                            <Typography variant="body2" noWrap>{moment(listDocument.createdAt).format('D/M/YY H:mm')}</Typography>
                        </Tooltip>
                    </Grid>
                    {
                        screenMDUpInclusive &&
                        <Grid item xs={3} sm={3} md={2}>
                            <DocumentListItemTopCreator userId={listDocument.createdBy} />
                        </Grid>
                    }
                    <Grid item xs={4} sm={5} md={4}>
                        <Tooltip text={`${showCompany ? companyName : ''}${folderName ? `${showCompany && companyName ? ' | ' : ''}${folderName}` : ''}${useGroups && groupName ? `${folderName ? ' | ' : ''}${groupName}` : ''}`} enterDelay={400}>
                            <Grid container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                <Grid item style={{width: 24}}>
                                    <FolderOutlinedIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}} />
                                </Grid>
                                <Grid item style={{width: 'calc(100% - 24px)'}}>
                                    <Grid item container alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                                        {
                                            showCompany &&
                                            <>
                                                <Grid item style={{maxWidth: `calc(${useGroups ? '33% - 14px' : '50% - 14px'})`}}>
                                                    <Typography variant="body2" noWrap><DocumentListItemTopClient clientId={listDocument.clientId} /></Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item style={{maxWidth: `calc(${showCompany && useGroups ? '34%' : showCompany || useGroups ? '50%' : '100%'})`}}>
                                            <Typography variant="body2" noWrap><DocumentListItemTopProject projectId={listDocument.projectId} /></Typography>
                                        </Grid>
                                        {
                                            useGroups &&
                                            <>
                                                <Grid item>
                                                    <Typography variant="body2" noWrap style={{width: 14}}>&nbsp;|&nbsp;</Typography>
                                                </Grid>
                                                <Grid item style={{maxWidth: `calc(${showCompany ? '33% - 14px' : '50% - 14px'})`}}>
                                                    <Typography variant="body2" noWrap>{groupName}</Typography>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={5} sm={5} md={4}>
                        <Grid container spacing={1} alignItems="center" wrap="nowrap" style={{width: '100%'}}>
                            {
                                (listDocument.flag.type !== (activeUserIsOperator ? 'operator' : 'client')) &&
                                <Grid item style={{width: 120}}>
                                    <Button variant="outlined" size="small"
                                        onClick={() => handleFlagDocumentButtonClick(listDocument)}
                                        startIcon={<FlagIcon />}
                                    >Sinalizar</Button>
                                </Grid>
                            }
                            {
                                !!listDocument.flag.type &&
                                <Grid item style={{width: listDocument.flag.type !== (activeUserIsOperator ? 'operator' : 'client') ? `calc(100% - 120px)` : '100%'}}>
                                    <DocumentFlag 
                                        activeUserIsOperator={activeUserIsOperator}
                                        flag={listDocument.flag}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Box mt={0.5}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <VersionRowMainColumn
                            activeUserIsManager={activeUserIsManager}
                            activeUserIsOperator={activeUserIsOperator}
                            comment={comment}
                            eSignature={eSignature}
                            item={listDocument}
                            noHtmlComment={noHtmlComment}
                            project={documentFolder}
                            projects={projects}
                            setSelectedDocument={setSelectedDocument}
                        />
                    </Grid>
                    <CustomFields listDocument={listDocument} />
                </Grid>
            </Box>
        </DataListItem>
    );
};

export default DocumentListItem;