import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ActiveProjectsStatusBox from './ActiveProjectsStatusBox/ActiveProjectsStatusBox';
import ActiveProjectsWorkTypeBoxes from './ActiveProjectsWorkTypeBoxes/ActiveProjectsWorkTypeBoxes';
import AlertDialog from '../../../../../components/AlertDialog';
import DatePicker from '../../../../../components/DatePicker';
import Switch from '../../../../../components/Switch';

const ActiveProjectView = (props) => {
    useSignals();
    const { newActiveProjectViewOpen, activeProjectViewSelectedBox, setActiveProjectViewSelectedBox, activeProjectStatusOptions, activeProjectTypes, handleAddActiveProjectConfirm, handleDeleteActiveProjectConfirm } = props;
    const defaultActiveProjectState = {
        endTime: '',
        name: '',
        nameError: false,
        notes: '',
        workType: 'atendimento',
        shouldShowOnTimeline: false,
        startTime: '',
        status: 'accepted',
    };
    const [activeProjectState, setActiveProjectState] = useState(defaultActiveProjectState);
    const [deleteActiveProjectDialogOpen, setDeleteActiveProjectDialogOpen] = useState(false);
    const nameInputRef = useRef(null);

    const getActiveProjectState = () => {
        if(newActiveProjectViewOpen.value && activeProjectViewSelectedBox){
            return setActiveProjectState({...defaultActiveProjectState, ...activeProjectViewSelectedBox});
        }
        setActiveProjectState(defaultActiveProjectState);
    };
    
    useEffect(() => {
        getActiveProjectState();
    }, [newActiveProjectViewOpen.value, activeProjectViewSelectedBox]);

    useEffect(() => {
        if(newActiveProjectViewOpen.value){
            if(nameInputRef.current){
                nameInputRef.current.focus();
            }
        }
    }, [newActiveProjectViewOpen.value]);

    const handleSubmit = async () => {
        if(!activeProjectState.name) return setActiveProjectState(prevState => ({...prevState, nameError: true}));
        setActiveProjectState(prevState => ({...prevState, nameError: false}));
        handleAddActiveProjectConfirm(activeProjectState);
        handleClose();
    };

    const handleNameInputKeyUp = (e) => {
        if(e.key === 'Enter'){
            handleSubmit();
        }
    };

    const handleDeleteClick = async () => {
        setDeleteActiveProjectDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        setDeleteActiveProjectDialogOpen(false);
        handleDeleteActiveProjectConfirm(activeProjectState);
        handleClose();
    };

    const handleClose = () => {
        setActiveProjectViewSelectedBox(null);
        newActiveProjectViewOpen.value = false;
    };
    
    return (
        <>
            <Dialog open={newActiveProjectViewOpen.value} onClose={handleClose} keepMounted>
                <DialogTitle noWrap>{activeProjectState.uid ? activeProjectState.name : 'Novo projeto'}</DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <TextField
                            variant="standard"
                            fullWidth
                            inputProps={{ref: nameInputRef}}
                            label="Nome"
                            placeholder="Nome do projeto"
                            value={activeProjectState.name}
                            onChange={(e) => setActiveProjectState(prevState => ({...prevState, name: e.target.value}))}
                            onKeyUp={handleNameInputKeyUp}
                        />
                        <Collapse in={activeProjectState.nameError}>
                            <FormHelperText error>Gentileza especificar um nome para o projeto.</FormHelperText>
                        </Collapse>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={1}>
                            <ActiveProjectsWorkTypeBoxes
                                activeProjectTypes={activeProjectTypes}
                                onClick={(activeProjectType) => setActiveProjectState(prevState => ({...prevState, workType: activeProjectType.value}))}
                                style={(activeProjectType) => !activeProjectState.workType || activeProjectState.workType === activeProjectType.value || activeProjectState.workType === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                                textStyle={(activeProjectType) => !activeProjectState.workType || activeProjectState.workType === activeProjectType.value || activeProjectState.workType === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                            />
                        </Grid>
                    </Box>
                    <Box mb={2}>
                        <Grid container spacing={1} alignItems="center">
                            {
                                activeProjectStatusOptions.map(statusOption => (
                                    <Grid key={statusOption.value}>
                                        <ActiveProjectsStatusBox
                                            activeProjectStatus={statusOption}
                                            onClick={() => setActiveProjectState(prevState => ({...prevState, status: statusOption.value}))}
                                            style={(activeProjectStatus) => !activeProjectState.status || activeProjectState.status === activeProjectStatus.value || activeProjectState.status === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                                            textStyle={(activeProjectStatus) => !activeProjectState.status || activeProjectState.status === activeProjectStatus.value || activeProjectState.status === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                    <Box mb={1}>
                        <TextField
                            variant="standard"
                            multiline fullWidth
                            label="Anotações"
                            placeholder="Anotações sobre o projeto"
                            value={activeProjectState.notes}
                            onChange={(e) => setActiveProjectState(prevState => ({...prevState, notes: e.target.value}))}
                        />
                    </Box>
                    <Box>
                        <Switch
                            label={`Exibir na linha do tempo?`}
                            checked={activeProjectState.shouldShowOnTimeline}
                            onChange={(e) => setActiveProjectState(prevState => ({...prevState, shouldShowOnTimeline: e.target.checked}))}
                        />
                    </Box>
                    {
                        activeProjectState.shouldShowOnTimeline &&
                        <Box>
                            <Grid container spacing={1} alignItems="center">
                                <Grid>
                                    <Typography variant="body2">Início</Typography>
                                    <DatePicker
                                        placeholder="DD/MM/AAAA"
                                        value={activeProjectState.startTime ? moment(activeProjectState.startTime) : null}
                                        onChange={(date) => setActiveProjectState(prevState => ({...prevState, startTime: date.startOf('day').toISOString()}))}
                                    />
                                </Grid>
                                {
                                    activeProjectState.startTime &&
                                    <Grid>
                                        <Typography variant="body2">Fim</Typography>
                                        <DatePicker
                                            placeholder="DD/MM/AAAA"
                                            minDate={moment(activeProjectState.startTime).add(1, 'day')}
                                            value={activeProjectState.endTime ? moment(activeProjectState.endTime) : null}
                                            onChange={(date) => setActiveProjectState(prevState => ({...prevState, endTime: date.endOf('day').toISOString()}))}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={handleClose}
                    >Cancelar</Button>
                    {
                        activeProjectState.uid &&
                        <Button
                            variant="outlined" color="primary"
                            onClick={handleDeleteClick}
                            startIcon={<DeleteIcon />}
                        >Excluir</Button>
                    }
                    <Button
                        variant="contained" color="primary"
                        onClick={handleSubmit}
                        endIcon={<CheckIcon />}
                    >Ok</Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                open={deleteActiveProjectDialogOpen} onClose={() => setDeleteActiveProjectDialogOpen(false)}
                text="Você quer excluir esse projeto? Essa ação é irreversível."
                okButtonOnClick={handleDeleteConfirm}
            />
        </>
    );
};

export default ActiveProjectView;