import { collection, deleteField, doc, documentId, getDoc, getDocs, query, runTransaction, updateDoc, where } from 'firebase/firestore';

import { db } from '../firebase/config';

type ConstructorArgs = {
    templateName: string;
    title?: {
        type: string;
        value: any;
    }[];
};

export default class DocumentsTemplate_MoreTitle {
    title: ConstructorArgs['title'] = [];

    constructor({
        templateName,
        title,
    }: ConstructorArgs){
        this.title = title || [
            {'type': 'text', 'value': 'Cto. '},
            {'type': 'count', 'value': 'folder'},
            {'type': 'text', 'value': '-'},
            {'type': 'date', 'value': 'Y'},
            {'type': 'text', 'value': ` - ${templateName}`}
        ];
    }

    async update({ updates, workspaceId }: {
        updates: {[key: string]: any};
        workspaceId: string
    }){
        // let result = null, error = null;
        
        // const projectRef = doc(db, `projects/${this.uid}`);
        // const workspaceProjectsRef = doc(db, `workspaces/${workspaceId}/_more/projects`);
        
        // try {
        //     await runTransaction(db, async (transaction) => {
        //         transaction.update(projectRef, updates);
        //         const workspaceProjectsUpdates: { [key: string]: any } = {};
        //         for(const updateKey in updates){
        //             workspaceProjectsUpdates[`projects.${this.uid}.${updateKey}`] = updates[updateKey];
        //         }
        //         transaction.update(workspaceProjectsRef, workspaceProjectsUpdates);
        //     });
        //     result = true;
        // } catch (e) {
        //     error = e;
        // }

        // return { result, error };
    }
    
}