import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterListIcon from '@mui/icons-material/FilterList';

const FilterInput = ({ placeholder, value, setValue }) => {
    return (
        <Grid container spacing={1} alignItems="flex-end">
            <Grid item>
                <FilterListIcon />
            </Grid>
            <Grid item xs>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder={placeholder || 'Filtrar'}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    InputProps={{
                        endAdornment:
                            value &&
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    title="Limpar"
                                    aria-label="Clear text field"
                                    onClick={() => setValue('')}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                    }} />
            </Grid>
        </Grid>
    );
};

export default FilterInput;