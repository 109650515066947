import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import EditorEffectIf from './EditorEffectIf';
import EditorEffectIfNot from './EditorEffectIfNot';
import EditorEffectIfTrue from './EditorEffectIfTrue';

import { useOperatorTemplatesCtxConstants } from '../../../pages/Operator/Docs/Templates/context/TemplatesContext';

const EditorEffect = (props) => {
    const { selectedEffect } = props;
    const { specialEffects } = useOperatorTemplatesCtxConstants();
    
    const acceptedComponents = {
        if: EditorEffectIf,
        ifNot: EditorEffectIfNot,
        ifTrue: EditorEffectIfTrue,
    }

    if(selectedEffect.type === 'special' || selectedEffect.special){
        const EditorEffectType = acceptedComponents[selectedEffect.special];
        if(EditorEffectType){
            return (
                <EditorEffectType {...props} />
            );
        }
        if(specialEffects){
            const foundSpecialEffect = specialEffects.find(specialEffect => specialEffect.value === selectedEffect.id);
            if(foundSpecialEffect){
                return (
                    <Box>
                        <Box mb={2}>
                            <Typography variant="h3">{foundSpecialEffect.label}</Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography variant="body1"><strong>Tipo:</strong> Importar dados</Typography>
                        </Box>
                    </Box>
                )
            }
        }
    }
    return null;
};

export default EditorEffect;