import { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useForm } from '../../context/FormContext';

import FormQuestionText from '../../../../components/FormQuestionText';
import { useAppStateCtx } from '../../../../context/AppState';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const TextQuestion = ({question, setQuestionValid}) => {
    useSignals();
    const { formFoundCNPJs, formQuestionNameToCNPJ, formQuestionsStatus } = useAppStateCtx();
    const { values } = useForm();
    const [showRepresentativeError, setShowRepresentativeError] = useState(false);
    const [userAwareOfErrors, setUserAwareOfErrors] = useState(false);
    const [showConsentingSignatoryError, setShowConsentingSignatoryError] = useState(false);
    const [foundCNPJ, setFoundCNPJ] = useState(null);
    const additionalValidationData = useRef({});
    const { initialValue, userFormQuestion, setUserFormQuestion } = useDocumentFormQuestion({
        additionalValidationData,
        assignedField: question.assignedField,
        question,
        questionType: 'text',
        setQuestionValid,
    });

    useEffect(() => {
        if(question.corporationIdQuestion){
            setFoundCNPJ(formQuestionNameToCNPJ.value[question.corporationIdQuestion] || null);
        }
    }, [formQuestionNameToCNPJ.value]);

    const checkRepresentativeError = (questionLegalRepresentatives, newValue) => {
        let shouldShowRepresentativeError = false;
        if(questionLegalRepresentatives){
            shouldShowRepresentativeError = question.assignedField === 4 && questionLegalRepresentatives.length >= 1 && !questionLegalRepresentatives.includes(newValue);
        }
        setShowRepresentativeError(shouldShowRepresentativeError);
    };

    const checkConsentingSignatoryError = (questionPartners, newValue) => {
        let shouldShowConsentingSignatoryError = false;
        if(questionPartners){
            shouldShowConsentingSignatoryError = question.assignedField === 'consentingSignatoryName' && questionPartners.length >= 1 && !questionPartners.includes(newValue);
        }
        setShowConsentingSignatoryError(shouldShowConsentingSignatoryError);
    };

    const handleCheckCorporationIdDetailsChange = (actionKeyValuePairs) => {
        if(foundCNPJ && question.corporationIdQuestion){
            const userFormCNPJQuestion = values.current[question.corporationIdQuestion];
            if(userFormCNPJQuestion){
                const currentCNPJ = userFormCNPJQuestion.input;

                let nextState = {...formFoundCNPJs.value};
                if(!nextState.details) nextState.details = [];
                const questionId = question.id;
                const questionName = question.name;
                if(question.assignedField === 4){
                    const foundQuestionIndex = nextState.details.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON');
                    if(foundQuestionIndex !== -1){
                        if(actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                            for(let key in actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                                nextState.details[foundQuestionIndex][key] = actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON'][key];
                            }
                        }
                    } else {
                        const newItem = {
                            questionId,
                            questionName,
                            action: 'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON',
                            corporationIdQuestion: question.corporationIdQuestion,
                            CNPJ: currentCNPJ,
                        };
                        if(actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                            for(let key in actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON']){
                                newItem[key] = actionKeyValuePairs['CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON'][key];
                            }
                        }
                        nextState.details.push(newItem);
                    }
                } else if(question.assignedField === 'consentingSignatoryName'){
                    const foundQuestionIndex = nextState.details.findIndex(prevItem => prevItem.questionName === question.name && prevItem.action === 'CHECK IF CNPJ PARTNERS INCLUDE PERSON');
                    if(foundQuestionIndex !== -1){
                        if(actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                            for(let key in actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                                nextState.details[foundQuestionIndex][key] = actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON'][key];
                            }
                        }
                    } else {
                        const newItem = {
                            questionId,
                            questionName,
                            action: 'CHECK IF CNPJ PARTNERS INCLUDE PERSON',
                            corporationIdQuestion: question.corporationIdQuestion,
                            CNPJ: currentCNPJ,
                        };
                        if(actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                            for(let key in actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON']){
                                newItem[key] = actionKeyValuePairs['CHECK IF CNPJ PARTNERS INCLUDE PERSON'][key];
                            }
                        }
                        nextState.details.push(newItem);
                    }
                }
                formFoundCNPJs.value = nextState;
            }
        }
    };

    const checkIfAssignedFieldInformationRetrievedFromCNPJ = (input) => {
        let retrievedFromCorporationId = false;
        if(foundCNPJ && !!question.assignedField){
            if(question.assignedField === 1){
                retrievedFromCorporationId = foundCNPJ.companyName === input;
            } else if(question.assignedField === 3){
                retrievedFromCorporationId = foundCNPJ.fullAddress === input;
            } else if(question.assignedField === 4){
                retrievedFromCorporationId = foundCNPJ.legalRepresentativeNames && foundCNPJ.legalRepresentativeNames.includes(input);
            } else if(question.assignedField === 'consentingSignatoryName'){
                retrievedFromCorporationId = foundCNPJ.partnerNames && foundCNPJ.partnerNames.includes(input);
            }
        }
        return { retrievedFromCorporationId };
    }
    
    useEffect(() => {
        let CNPJ, CNPJLegalRepresentatives, CNPJPartners, retrievedFromCorporationId;
        if(foundCNPJ){
            CNPJ = foundCNPJ.cnpj;
            if(question.assignedField === 4) CNPJLegalRepresentatives = foundCNPJ.legalRepresentativeNames || [];
            if(question.assignedField === 'consentingSignatoryName') CNPJPartners = foundCNPJ.partnerNames || [];

            const assignedFieldInformationRetrievedFromCNPJ = checkIfAssignedFieldInformationRetrievedFromCNPJ(userFormQuestion.input);
            retrievedFromCorporationId = assignedFieldInformationRetrievedFromCNPJ.retrievedFromCorporationId;
        }
        setUserFormQuestion(prevState => {
            let nextState = {
                ...prevState,
                retrievedFromCorporationId
            }
            if(question.assignedField === 4 || question.assignedField === 'consentingSignatoryName') nextState.CNPJ = CNPJ;
            if(question.assignedField === 4) nextState.CNPJLegalRepresentatives = CNPJLegalRepresentatives;
            if(question.assignedField === 'consentingSignatoryName') nextState.CNPJPartners = CNPJPartners;
            return nextState;
        });

        handleCheckCorporationIdDetailsChange({
            'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON': {
                CNPJManagingPartners: CNPJLegalRepresentatives
            },
            'CHECK IF CNPJ PARTNERS INCLUDE PERSON': {
                CNPJPartners: CNPJPartners
            }
        });

        if(userFormQuestion){
            checkRepresentativeError(CNPJLegalRepresentatives, userFormQuestion.input);
            checkConsentingSignatoryError(CNPJPartners, userFormQuestion.input);
        }
    }, [foundCNPJ]);

    useEffect(() => {
        const userNotAwareOfRepresentativeError = showRepresentativeError && !userAwareOfErrors;
        const userNotAwareOfConsentingSignatoryError = showConsentingSignatoryError && !userAwareOfErrors;
        let foundConsentingSignatoryInCNPJ, foundLegalRepresentativeInCNPJ;
        if(question.assignedField === 4){
            foundLegalRepresentativeInCNPJ = !showRepresentativeError;
        } else if(question.assignedField === 'consentingSignatoryName'){
            foundConsentingSignatoryInCNPJ = !showConsentingSignatoryError;
        }
        additionalValidationData.current = {
            userNotAwareOfRepresentativeError,
            userNotAwareOfConsentingSignatoryError,
        };
        setUserFormQuestion(prevState => {
            let nextState = {...prevState};
            if(question.assignedField === 'consentingSignatoryName') nextState.foundConsentingSignatoryInCNPJ = foundConsentingSignatoryInCNPJ;
            if(question.assignedField === 4) nextState.foundLegalRepresentativeInCNPJ = foundLegalRepresentativeInCNPJ;
            return nextState;
        });

        handleCheckCorporationIdDetailsChange({
            'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON': {
                CNPJManagingPartnersIncludePerson: foundLegalRepresentativeInCNPJ
            },
            'CHECK IF CNPJ PARTNERS INCLUDE PERSON': {
                CNPJPartnersIncludePerson: foundConsentingSignatoryInCNPJ
            }
        });

    }, [showRepresentativeError, showConsentingSignatoryError, userAwareOfErrors]);

    const handleChange = (questionName, updates) => {
        const { retrievedFromCorporationId } = checkIfAssignedFieldInformationRetrievedFromCNPJ(updates.input);
        
        setUserFormQuestion(prevState => ({
            ...prevState,
            input: updates.input,
            aiGeneratedSuggestion: updates.aiGeneratedSuggestion || '',
            retrievedFromCorporationId
        }));
        if(userFormQuestion){
            checkRepresentativeError(userFormQuestion.CNPJLegalRepresentatives, updates.input);
            checkConsentingSignatoryError(userFormQuestion.CNPJPartners, updates.input);
        }

        handleCheckCorporationIdDetailsChange({
            'CHECK IF CNPJ MANAGING PARTNERS INCLUDE PERSON': {
                personName: updates.input
            },
            'CHECK IF CNPJ PARTNERS INCLUDE PERSON': {
                personName: updates.input
            }
        });
    };

    const handleUserAwareErrorsChange = (newUserAwareOfErrorsValue) => {
        setUserAwareOfErrors(newUserAwareOfErrorsValue);
    };

    return (
        <FormQuestionText
            CNPJStatus={formQuestionsStatus.value[question.corporationIdQuestion]}
            disabled={question.corporationIdQuestion && formQuestionsStatus.value[question.corporationIdQuestion] === 'retrievingData'}
            foundCNPJ={foundCNPJ}
            handleUserAwareErrorsChange={handleUserAwareErrorsChange}
            question={question}
            showConsentingSignatoryError={showConsentingSignatoryError} setShowConsentingSignatoryError={setShowConsentingSignatoryError}
            showRepresentativeError={showRepresentativeError} setShowRepresentativeError={setShowRepresentativeError}
            userAwareOfErrors={userAwareOfErrors}
            initialValue={initialValue}
            onChange={handleChange}
        />
    );
}

export default TextQuestion;