import { collection, deleteField, doc, runTransaction } from 'firebase/firestore';

import { db } from '../firebase/config';

type _Notice = {
    createdAt?: string;
    createdBy: string;
    clientId: string;
    deleted?: boolean;
    deletedAt?: string;
    deletedBy?: string;
    projectId: string;
    reactions?: {
        [reactionId: string]: {
            reactionAt: string;
            unifiedId: string;
        }
    };
    subject: string;
    text: string;
    uid?: string;
    workspaceId: string;
}

export default class Notice implements _Notice {
    createdAt = '';
    createdBy = '';
    clientId = '';
    deleted = false;
    deletedAt = '';
    deletedBy = '';
    projectId = '';
    reactions: _Notice['reactions'] = {};
    subject = '';
    text = '';
    uid = '';
    workspaceId = '';

    constructor({
        createdAt,
        createdBy,
        clientId,
        deleted,
        deletedAt,
        deletedBy,
        projectId,
        reactions,
        subject,
        text,
        uid,
        workspaceId
    }: _Notice){
        this.createdAt = createdAt || new Date().toISOString();
        this.createdBy = createdBy || '';
        this.clientId = clientId || '';
        this.deleted = !!deleted;
        if(deletedAt) this.deletedAt = deletedAt;
        if(deletedBy) this.deletedBy = deletedBy;
        this.projectId = projectId || '';
        if(reactions) this.reactions = reactions;
        if(subject) this.subject = subject;
        this.text = text || '';
        this.uid = uid || '';
        this.workspaceId = workspaceId || '';
    }

    async createNotice(){
        let result = null, error = null;

        const noticeRef = doc(collection(db, `notices`));
        
        const {uid: _, ...newNotice} = this;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(noticeRef, newNotice);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async react(unifiedId: string, activeUserId: string){
        let result = null, error = null;

        const noticeRef = doc(db, `notices/${this.uid}`);
        
        try {
            await runTransaction(db, async (transaction) => {
                if(unifiedId){
                    transaction.update(noticeRef, {
                        [`reactions.${activeUserId}`]: {
                            reactionAt: new Date().toISOString(),
                            unifiedId
                        }
                    });
                } else {
                    transaction.update(noticeRef, {
                        [`reactions.${activeUserId}`]: deleteField()
                    });
                }
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}