import { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import QuestionBox from '../../../components/QuestionBox';
import { useAppStateCtx } from '../../../context/AppState';

const LinkFormUser = ({captchaRef, linkForm, setReCAPTCHAToken, values}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const [linkFormUser, setLinkFormUser] = useState({name: '', email: ''});
    const [questionValid, setQuestionValid] = useState(false);

    useEffect(() => {
        setQuestionValid(linkFormUser.name && linkFormUser.email);
        values.current = {...values.current, linkFormUser};
    }, [linkFormUser]);

    if(!linkForm) return null;
    
    return (
        <Box>
            {
                !activeUser.value &&
                <QuestionBox
                    questionValid
                    name="Qual é o seu nome e e-mail?"
                    mandatory
                    type="heading"
                >
                    <Box mt={2}>
                        <Grid container spacing={1} justifyContent="center">
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label="Nome"
                                    value={linkFormUser.name}
                                    onChange={(e) => setLinkFormUser(prevState => ({...prevState, name: e.target.value}))} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth
                                    label="E-mail"
                                    value={linkFormUser.email}
                                    onChange={(e) => setLinkFormUser(prevState => ({...prevState, email: e.target.value}))} />
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse in={!questionValid}>
                        <Box mt={2}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <ErrorOutlineIcon color="secondary" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" color="secondary">Preencha o seu nome e o seu e-mail para enviar o formulário.</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Collapse>
                </QuestionBox>
            }
            <Box mb={2}>
                <ReCAPTCHA
                    sitekey="6Le8UFUhAAAAAHrhXcPU17Dr7UdVQzFT5GUmt7aS"
                    ref={captchaRef}
                    onChange={token => setReCAPTCHAToken(token)}
                />
            </Box>
        </Box>
    );
};

export default LinkFormUser;