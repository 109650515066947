import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from '../context/AppState';
import { useAppCtxAPI } from '../context/SystemContext';
import { detectPresence, setOffline } from '../firebase/realtime_database/presence';
import Operator from '../pages/Operator';
import ReviewDocument from '../pages/Operator/Docs/ReviewDocument';
import PageNotFound from '../pages/PageNotFound';

const ConnectedOperator = () => {
    useSignals();
    const { activeUser, onlineUserWindowId, selectedWorkspace } = useAppStateCtx();
    const { setWindowId } = useAppCtxAPI();

    useEffect(() => {
        if(activeUser.value?.uid && selectedWorkspace.value?.uid){
            detectPresence(activeUser.value.uid, `${selectedWorkspace.value.uid}/operators`, setWindowId);
        }
        return () => {
            if(onlineUserWindowId.value) setOffline(onlineUserWindowId.value, setWindowId);
        }
    }, [activeUser.value?.uid, selectedWorkspace.value?.uid]);

    if(!selectedWorkspace.value) return null;
    
    return (
        <Routes>
            <Route path="/documento/:documentId" element={<ReviewDocument />} />
            <Route path="/*" element={<Operator />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default ConnectedOperator;