import { FolderUserProvider } from './context/ClientContext';
import Main from './Main';

function Client(){
    return (
        <FolderUserProvider>
            <Main />
        </FolderUserProvider>
    );
}

export default Client;