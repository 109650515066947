import { doc, updateDoc as _updateDoc } from 'firebase/firestore';

import { db } from '../config';

export default async function updateDoc(path: string, data: { [key: string]: any }) {
    let result = null, error = null;
    
    const docRef = doc(db, path);

    try {
        result = await _updateDoc(docRef, data);
    } catch (e) {
        error = e;
    }

    return { result, error };
}