import { useEffect, useState } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceActiveProjects = (shouldSetListener) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value){
            setHookState(prevState => ({...prevState, loading: true}));
            const unsubscribe = onSnapshot(
                query(collection(db, `workspaces/${selectedWorkspace.value.uid}/active_projects`)),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            uid: snapshot.id,
                        })
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspaceActiveProjects;