import { useEffect, useState } from 'react';
import {useScroll} from 'react-use';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import Notifications from './Notifications';
import MenuIcon from '@mui/icons-material/Menu';

import { useAppContext } from '../context/SystemContext';
import { HEADER_HEIGHT } from '../utils/constants';

const classes = {
    header: {
        zIndex: theme => theme.zIndex.drawer - 1,
        height: `${HEADER_HEIGHT}px`,
        maxHeight: `${HEADER_HEIGHT}px`,
        overflow: 'hidden',
        '&>div': {
            minHeight: `${HEADER_HEIGHT}px`
        },

        width: '100%',

        backgroundColor: '#fbfbfc',
        transition: 'box-shadow .2s ease-out'
    },
    menuButton: {
        display: ['block', 'none'],
    }
};

const StyledHeader = styled('header')(unstable_styleFunctionSx);

function Header({leftBox, onlineUsers, account, data}){
    const { sidenavMobileOpen, set_sidenavMobileOpen } = data;
    const minRightBoxWidth = 122;
    const { mainRef } = useAppContext();
    const { y } = useScroll(mainRef);
    const [shouldChangeStyles, setShouldChangeStyles] = useState(false)

    useEffect(() => {
        setShouldChangeStyles(y > 50);
    }, [y]);
 
    return (
        <StyledHeader sx={[classes.header, shouldChangeStyles && { boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)' }]}>
            <Box style={{display: 'flex', alignItems: 'center'}} px={2}>
                <Box sx={classes.menuButton}>
                    <IconButton
                        edge="start"
                        onClick={() => set_sidenavMobileOpen(!sidenavMobileOpen)}
                        size="large"><MenuIcon /></IconButton>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center', maxWidth: `calc(100% - ${minRightBoxWidth}px)`}}>
                    {leftBox || null}
                </Box>
                <Box style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        <Notifications />
                    </Box>
                    <Box style={{display: 'flex', alignItems: 'center'}} sx={{ display: { xs: 'none', md: 'block' } }}>
                        {onlineUsers || null}
                    </Box>
                </Box>
                <Box>
                    {account || null}
                </Box>
            </Box>
        </StyledHeader>
    );
}

export default Header;