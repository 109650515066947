import { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SettingsIcon from '@mui/icons-material/Settings';

import AddTemplate from '../components/AddTemplate';

import AlertDialog from '../../../../../components/AlertDialog';
import FormEditorQuestion from './components/FormEditorQuestion';
import FormEditorQuestionWindow from './components/FormEditorQuestionWindow';
import TestTemplateWindow from '../components/TestTemplateWindow';
import ViewBox from '../../../../../components/ViewBox';
import NewTitleDialog from './components/NewTitleDialog';
import UpdateTitleDialog from './components/UpdateTitleDialog';
import UpdateDescriptionDialog from './components/UpdateDescriptionDialog';
import { ASSIGNABLE_FIELDS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../utils/constants';
import { useAppStateCtx } from '../../../../../context/AppState';
import { useAppCtxAPI, useAppCtxLoading } from '../../../../../context/SystemContext';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateName, useOperatorTemplatesCtxTemplateDocumentEditorValue, useOperatorTemplatesCtxTemplateEditorSelectedTemplate, useOperatorTemplatesCtxTemplateFormEditorQuestions, useOperatorTemplatesCtxTemplateEffects } from '../context/TemplatesContext';
import { useFormEditor } from './FormEditorContext';
import { FormProvider } from '../../../../Form/context/FormContext';
import DocumentsTemplate from '../../../../../classes/DocumentsTemplate';

const classes = {
    questionsListBox: {
        borderRadius: theme => theme.spacing(1),
        padding: theme => theme.spacing(1),
        transition: 'background-color 0.2s ease',
    },
};

const Div = styled('div')(unstable_styleFunctionSx);

const testTemplateViewOpen = signal(false);

function FormEditor(){
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { templateId: urlTemplateId } = useParams();
    const { handleNavigate } = useAppCtxAPI();
    const loading = useAppCtxLoading();
    const { deleteFormEditorQuestion, positionFormEditorQuestion, updateFormEditorQuestion } = useOperatorTemplatesCtxAPI();
    const templateName = useOperatorTemplatesCtxTemplateName();
    const documentEditorValue = useOperatorTemplatesCtxTemplateDocumentEditorValue();
    const templateEditorSelectedTemplate = useOperatorTemplatesCtxTemplateEditorSelectedTemplate();
    
    const formEditorQuestions = useOperatorTemplatesCtxTemplateFormEditorQuestions();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();
    const {
        clickedQuestion, setCorporationIdQuestions, deleteTitleDialogOpen, setDeleteTitleDialogOpen, assignFieldMenuOpen, setAssignFieldMenuOpen, assignFieldMenuAnchorEl,
        orderingForm, 
    } = useFormEditor();
    const [errorMessage, set_errorMessage] = useState('');
    const [selectedFormEditorQuestion, setSelectedFormEditorQuestion] = useState(null);
    const [formEditorQuestionWindowOpen, setFormEditorQuestionWindowOpen] = useState(false);

    useEffect(() => {
        let currentCorporationIdQuestions = [];
        for(const formEditorQuestion of formEditorQuestions){
            if(formEditorQuestion.type === 'corporationId'){
                currentCorporationIdQuestions.push(formEditorQuestion);
            }
        }
        setCorporationIdQuestions(currentCorporationIdQuestions);
    }, [formEditorQuestions]);

    const handle_formEditorSectionBackClick = () => {
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes/${urlTemplateId}/documento`);
    };
    
    const handleSaveButtonClick = async () => {
        const toastId = toast.loading(`Salvando a matriz ${templateEditorSelectedTemplate?.name || templateName.current}...`);
        set_errorMessage('');
        
        let { jsonForm, lists } = getJSONForm();

        if(templateEditorSelectedTemplate){
            const res = await templateEditorSelectedTemplate.updateTemplate(jsonForm, { main: documentEditorValue, effects: templateEffects }, lists, selectedWorkspace.value?.uid);
            if(res.error || !res.result){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        } else {
            const newDocumentTemplate = new DocumentsTemplate({
                createdBy: activeUser.value.uid,
                name: templateName.current,
                workspaceId: selectedWorkspace.value.uid
            });
            const createDocumentTemplateRes = await newDocumentTemplate.createDocumentTemplate(jsonForm, { main: documentEditorValue, effects: templateEffects });
            if(createDocumentTemplateRes.error || !createDocumentTemplateRes.result){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/documentos/matrizes`);
    };
    const getJSONForm = () => {
        let lists = [];
        const jsonForm = formEditorQuestions.map((question, questionIndex) => {
            const newQuestion = {...question};
            newQuestion.id = question.id || questionIndex;
            newQuestion.type = question.type;
            if(['text', 'list', 'special'].includes(newQuestion.type)){
                newQuestion.corporationIdQuestion = question.corporationIdQuestion || '';
            }
            if(['list', 'special'].includes(newQuestion.type)){
                newQuestion.showListDescription = !!question.showListDescription;
            }
            if(question.type === 'list'){
                if(question.typeId) lists.push(question.typeId);
            } else if(newQuestion.type === 'special'){
                lists.push('special_list_audiovisual_services');
            }
            if(newQuestion.type === 'currency' || newQuestion.type === 'number'){
                newQuestion.typeSpecifics = question.typeSpecifics;
            }
            newQuestion.name = question.name;
            if(question.options) newQuestion.options = question.options.map(o => ({choice: o.choice, label: o.label}));
            if(question.description) newQuestion.description = question.description;
            if(question.logicalBranching) newQuestion.logicalBranching = question.logicalBranching;
            newQuestion.mandatory = question.mandatory ? question.mandatory : false;
            newQuestion.assignedField = question.assignedField ? question.assignedField : 0;
            newQuestion.starred = question.starred || false;
            // if(newQuestion.type === 'special') newQuestion.special = question.special;
            newQuestion.typeId = question.typeId || '';
            newQuestion.textCase = question.textCase || '';
            if(question.shouldGetAISuggestionFromText) newQuestion.shouldGetAISuggestionFromText = question.shouldGetAISuggestionFromText;
            if(question.shouldHideAISuggestionInput) newQuestion.shouldHideAISuggestionInput = question.shouldHideAISuggestionInput;
            if(question.AISuggestionCustomFieldId) newQuestion.AISuggestionCustomFieldId = question.AISuggestionCustomFieldId;
            if(question.AISuggestionOutputFormat) newQuestion.AISuggestionOutputFormat = question.AISuggestionOutputFormat;
            if(question.AISuggestionAdditionalInstructions) newQuestion.AISuggestionAdditionalInstructions = question.AISuggestionAdditionalInstructions;
            return newQuestion;
        })
        return {
            jsonForm,
            lists
        };
    };
    const handleDeleteTitleDialogOkClick = () => {
        setFormEditorQuestionWindowOpen(false);
        setDeleteTitleDialogOpen(false);
        deleteFormEditorQuestion(clickedQuestion.id);
    };

    const handleAssignField = (fieldId) => {
        setAssignFieldMenuOpen(false);
        const newValue = {...clickedQuestion, assignedField: fieldId !== 0 ? fieldId : null};
        setSelectedFormEditorQuestion(newValue);
        updateFormEditorQuestion(newValue);
    };

    const handleQuestionDragEnd = (data) => {
        positionFormEditorQuestion(data.source.index, data.destination.index);
    };

    const handleFormEditorQuestionWindowOpenButtonPress = (question) => {
        setSelectedFormEditorQuestion(question);
        setFormEditorQuestionWindowOpen(true);
    };

    return (
        <ViewBox style={{height: '100%', padding: '50px 0 0 0', overflow: 'hidden'}}>
            <Box mb={2} style={{height: 50}} paddingX={2}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={handle_formEditorSectionBackClick}>Voltar</Button>
                    </Grid>
                    <Grid item>
                        <Button startIcon={<SettingsIcon />} onClick={() => testTemplateViewOpen.value = true}>Testar</Button>
                    </Grid>
                    {
                        !templateEditorSelectedTemplate &&
                        <Grid item xs={3}>
                            <AddTemplate />
                        </Grid>
                    }
                    <Grid item>
                        <Button disabled={loading} variant="contained" color="primary" endIcon={<SaveAltIcon />} onClick={handleSaveButtonClick}>Salvar</Button>
                        {
                            errorMessage &&
                            <FormHelperText error>
                                {errorMessage}
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box style={{height: `calc(100% - 50px)`, overflowY: 'auto'}}>
                <Container maxWidth={orderingForm ? 'xs' : 'lg'}>
                    <DragDropContext onDragEnd={handleQuestionDragEnd}>
                        <Droppable droppableId={'form-editor-droppable-container'}>
                            {(provided, snapshot) => (
                                <Div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    sx={classes.questionsListBox}
                                    style={
                                        orderingForm
                                        ?
                                            (
                                                snapshot.isDraggingOver
                                                ? { backgroundColor: '#C0CCD1' }
                                                : {backgroundColor: 'white'}
                                            )
                                        : null
                                    }
                                >
                                    {
                                        formEditorQuestions.map((question, questionIndex) => (
                                            <FormEditorQuestion
                                                key={question.id} question={question} questionIndex={questionIndex}
                                                handleFormEditorQuestionWindowOpenButtonPress={handleFormEditorQuestionWindowOpenButtonPress}
                                            />
                                        ))
                                    }
                                    {provided.placeholder}
                                </Div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Container>
            </Box>
            <NewTitleDialog setFormEditorQuestionWindowOpen={setFormEditorQuestionWindowOpen} />
            <UpdateTitleDialog />
            <UpdateDescriptionDialog setSelectedFormEditorQuestion={setSelectedFormEditorQuestion} />
            <AlertDialog
                id="form-editor-delete-title-dialog"
                open={deleteTitleDialogOpen} onClose={() => setDeleteTitleDialogOpen(false)}
                text={`Você quer excluir o título "${clickedQuestion && clickedQuestion.name}"?`}
                okButtonOnClick={handleDeleteTitleDialogOkClick}
            />
            <FormProvider>
                <TestTemplateWindow
                    open={testTemplateViewOpen}
                    form={formEditorQuestions}
                    effects={templateEffects}
                    mainSheet={documentEditorValue}
                />
            </FormProvider>
            <Menu
                anchorEl={assignFieldMenuAnchorEl}
                open={assignFieldMenuOpen}
                onClose={() => setAssignFieldMenuOpen(false)}
            >
                <MenuItem onClick={() => handleAssignField(0)}>
                    <Typography variant="inherit">&bull; Nenhuma</Typography>
                </MenuItem>
                <Divider />
                {
                    ASSIGNABLE_FIELDS.map(i => (
                        <MenuItem key={i.id} onClick={() => handleAssignField(i.id)}>
                            <Typography variant="inherit">{i.name}</Typography>
                        </MenuItem>
                    ))
                }
            </Menu>
            <FormEditorQuestionWindow
                open={formEditorQuestionWindowOpen} setOpen={setFormEditorQuestionWindowOpen}
                question={selectedFormEditorQuestion} setQuestion={setSelectedFormEditorQuestion}
            />
        </ViewBox>
    );
}

export default FormEditor;