import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DocumentsTemplateTextEffectOutputTypeSelector from '../../../components/DocumentsTemplateTextEffectOutputTypeSelector';
import TextCaseRadioOptions from '../../../components/TextCaseRadioOptions';
import { useOperatorTemplatesCtxAPI, useOperatorTemplatesCtxTemplateEffects } from '../../../pages/Operator/Docs/Templates/context/TemplatesContext';
import { formEffectTypes } from '../../../utils/constants';

const EditorEffect = (props) => {
    const { selectedEffect, setSelectedEffect } = props;
    const { setTemplateEffects } = useOperatorTemplatesCtxAPI();
    const templateEffects = useOperatorTemplatesCtxTemplateEffects();

    const handleTextCaseChange = (e) => {
        const newValue = e.target.value;
        setSelectedEffect(prevState => ({...prevState, textCase: newValue}));
        const nextState = templateEffects.map(templateEffect => {
            if(templateEffect.id === selectedEffect.id){
                return {
                    ...templateEffect,
                    textCase: newValue
                }
            }
            return templateEffect;
        })
        setTemplateEffects(nextState);
    }

    const handleOutputTypeChange = (e) => {
        const newValue = e.target.value;
        setSelectedEffect(prevState => ({...prevState, outputType: newValue}));
        const nextState = templateEffects.map(templateEffect => {
            if(templateEffect.id === selectedEffect.id){
                return {
                    ...templateEffect,
                    outputType: newValue
                }
            }
            return templateEffect;
        })
        setTemplateEffects(nextState);
    }

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="h3">{selectedEffect.label || selectedEffect.question}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Tipo:</strong> {formEffectTypes.find(formEffectType => formEffectType.value === selectedEffect.type).label}</Typography>
            </Box>
            <Box mb={1}>
                <Typography variant="body1"><strong>Pergunta:</strong> {selectedEffect.question}</Typography>
            </Box>
            <TextCaseRadioOptions
                value={selectedEffect.textCase || 'none'}
                onChange={handleTextCaseChange}
            />
            <DocumentsTemplateTextEffectOutputTypeSelector
                value={selectedEffect.outputType || 'none'}
                onChange={handleOutputTypeChange}
            />
        </Box>
    )
};

export default EditorEffect;