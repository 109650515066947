import { useCallback, useEffect, useRef, useState } from 'react';
import queryString from 'query-string'; 
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import ClearanceItemsList from './ClearanceItemsList';
import ClearanceWindow from './ClearanceWindow';
import CommentsWindow from './CommentsWindow';
import NewReportWindow from './NewReportWindow';

import Autocomplete from '../Autocomplete';
import AlertDialog from '../AlertDialog';
import FilterInput from '../FilterInput';
import LoaderEllipsis from '../LoaderEllipsis';
import MenuChip from '../MenuChip';
import NoResults from '../NoResults';
import Select from '../Select';
import SectionTitle from '../SectionTitle';
import ViewBox from '../ViewBox';

import Project from '../../classes/Project';
import { useAppStateCtx, useAppStateCtxUtils } from '../../context/AppState';
import callFunction from '../../firebase/functions/callFunction';
import useGetClearanceReportItems from '../../hooks/useGetClearanceReportItems';
import useGetProjectClearanceSettings from '../../hooks/useGetProjectClearanceSettings';
import useGetWorkspaceProjectsWithClearanceOptions from '../../hooks/useGetWorkspaceProjectsWithClearanceOptions';
import { getClearanceTemplate } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const defaultFilters = {
    rightType: 'all',
    riskLevel: 'all',
    status: 'all',
    search: ''
};

const clearanceWindowOpen = signal(false);
const commentsWindowOpen = signal(false);
const filterPickerEl = signal(null);
const filterPickerId = signal('');
const filterPickerOpen = signal(false);
const filters = signal(defaultFilters);
const filtersOptions = signal({
    rightType: [{value: 'all', label: 'Todos'}],
    riskLevel: [
        {value: 'all', label: 'Todos'},
        ...CLEARANCE_RISK_OPTIONS.map(option => ({...option, description: option.label, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))
    ],
    status: null
});
const newReportWindowOpen = signal(false);
const selectedClearanceReportNote = signal(null);

const FilterPicker = () => {
    useSignals();

    const handleClose = () => {
        filterPickerOpen.value = false;
    }

    return (
        <Menu
            id="simple-menu"
            anchorEl={filterPickerEl.value}
            keepMounted
            open={filterPickerOpen.value}
            onClose={handleClose}
        >
            {
                filterPickerId.value &&
                filtersOptions.value[filterPickerId.value].map((option, optionIndex) => (
                    <MenuItem
                        key={optionIndex}
                        onClick={() => {
                            filters.value = {...filters.value, [filterPickerId.value]: option.value};
                            // setFilters(prevState => ({...prevState, [filterPickerId.value]: option.value}));
                            filterPickerOpen.value = false;
                        }}
                        selected={option.value === filters[filterPickerId.value]}
                    >{option.label}</MenuItem>
                ))
            }
        </Menu>
    )
}

const downloadFile = (fileURL, fileName) => {
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const DownloadPdfFileButton = ({projectId, reportId, projectClearanceSettings}) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const [downloadingPdf, setDownloadingPdf] = useState(false);

    const handlePdfButtonPress = async () => {
            setDownloadingPdf(true);
            const toastId = toast.loading('Gerando o relatório... isso pode levar alguns minutos...');
            const res = await callFunction('makeClearanceReportPdfFileByReportId', {
                projectId,
                reportId,
                show2FieldsForClearanceNoteRecommendation: !!projectClearanceSettings?.show2FieldsForClearanceNoteRecommendation,
                workspaceId: selectedWorkspace.value.uid
            });
            setDownloadingPdf(false);
            if(res.error){
                return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
            }
            toast.update(toastId, { autoClose: 3000, closeOnClick: true, isLoading: false, render: 'O relatório está pronto e será disponibilizado em instantes.', type: 'success' });
            downloadFile(res.result, res.result.split('/').pop());
        };

    return (
        <Grid>
            <Button
                disabled={downloadingPdf}
                variant="outlined" size="small"
                startIcon={<FontAwesomeIcon icon={faFilePdf} />}
                onClick={handlePdfButtonPress}
            >PDF</Button>
        </Grid>
    );
}

const DownloadXlsxFileButton = ({projectId, reportId, projectClearanceSettings}) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const [downloadingExcel, setDownloadingExcel] = useState(false);

    const handleExcelButtonPress = async () => {
        setDownloadingExcel(true);
        const toastId = toast.loading('Gerando o relatório... isso pode levar alguns minutos...');
        const res = await callFunction('makeClearanceReportXlsxFileByReportId', {
            projectId,
            reportId,
            show2FieldsForClearanceNoteRecommendation: !!projectClearanceSettings?.show2FieldsForClearanceNoteRecommendation,
            workspaceId: selectedWorkspace.value.uid
        });
        setDownloadingExcel(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, closeOnClick: true, isLoading: false, render: 'O relatório está pronto e será disponibilizado em instantes.', type: 'success' });
        downloadFile(res.result, res.result.split('/').pop());
    };

    return (
        <Grid>
            <Button
                disabled={downloadingExcel}
                variant="outlined" size="small"
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={handleExcelButtonPress}
            >XLSX</Button>
        </Grid>
    );
}

const ClearanceItemsListPage = ({
    activeUserIsOperator,
    initialProjectClearanceSettings,
    projects,
    restrictedToProject
}) => {
    useSignals();
    const { activeUser, activeUserIsGroupsManager } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const [loading, setLoading] = useState(true);
    const [projectSelectedByOperator, setProjectSelectedByOperator] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedProjectClearanceType, setSelectedProjectClearanceType] = useState('');
    const [selectedProjectClearanceTemplateId, setSelectedProjectClearanceTemplateId] = useState('');
    const [selectedProjectClearanceTemplate, setSelectedProjectClearanceTemplate] = useState(null);
    const [projectClearanceSettings, setProjectClearanceSettings] = useState(initialProjectClearanceSettings || null);
    const [clearanceItems, setClearanceItems] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [availableClearanceReports, setAvailableClearanceReports] = useState([]);
    const [clearanceReportOptions, setClearanceReportOptions] = useState([]);
    const [selectedReportId, setSelectedReportId] = useState('none');
    const [selectedReport, setSelectedReport] = useState(null);
    const [sendingReport, setSendingReport] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    
    const [sendReportAlertDialogOpen, setSendReportAlertDialogOpen] = useState(false);
    const [cancelReportItemDialogOpen, setCancelReportItemDialogOpen] = useState(false);
    const usedSearchReportId = useRef(false);
    const usedSearchReportItemId = useRef(false);
    const [visibleClearanceReportNotes, setVisibleClearanceReportNotes] = useState(null);

    // IF OPERATOR, GET ALL PROJECTS WITH CLEARANCE ENABLED
    const projectOptions = useGetWorkspaceProjectsWithClearanceOptions(activeUserIsOperator, projects);
    useEffect(() => {
        if(activeUserIsOperator){
            if(projectOptions) setLoading(false);
        }
    }, [activeUserIsOperator, projectOptions])

    const [setClearanceReportItemsSnapshotSettings, setSetClearanceReportItemsSnapshotSettings] = useState(null);

    // IF OPERATOR, GET SELECTED PROJECT CLEARANCE SETTINGS (CLIENT PASSES DATA WITH initialProjectClearanceSettings VAR)
    const retrievedProjectClearanceSettings = useGetProjectClearanceSettings(activeUserIsOperator, selectedProject?.uid);
    useEffect(() => {
        if(retrievedProjectClearanceSettings.loading){
            setLoading(true);
        } else {
            setProjectClearanceSettings(retrievedProjectClearanceSettings.data || null);
        }
    }, [retrievedProjectClearanceSettings]);

    const retrievedClearanceReportItems = useGetClearanceReportItems(setClearanceReportItemsSnapshotSettings, projectClearanceSettings);
    useEffect(() => {
        if(!setClearanceReportItemsSnapshotSettings || retrievedClearanceReportItems.loading){
            setLoading(true);
        } else {
            setClearanceItems(retrievedClearanceReportItems.data || null);
            setLoading(false);
        }
    }, [retrievedClearanceReportItems]);

    useEffect(() => {
        if(clearanceItems){

            const rightTypeFilter = (clearanceReportNote, rightType) => {
                if(rightType !== 'all') return clearanceReportNote.rightType === rightType;
                return true;
            }

            const riskLevelFilter = (clearanceReportNote, riskLevel) => {
                if(riskLevel !== 'all') return clearanceReportNote.riskLevel === riskLevel;
                return true;
            }

            const statusFilter = (clearanceReportNote, status) => {
                if(status !== 'all'){
                    if(status === 'approved'){
                        return clearanceReportNote.approved === true;
                    } else if(status === 'rejected'){
                        return clearanceReportNote.approved === false;
                    }
                }
                return true;
            }

            const textFilter = (note, text) => {
                const textFields = `${note.description}${note.legalRecommendation}${note.rightTypeDescription}${note.riskDescription}${note.riskLevelDescription}${note.useTypeDescription}`;
                return textFields.toUpperCase().indexOf(text.toUpperCase()) !== -1;
            }

            setVisibleClearanceReportNotes(
                clearanceItems.filter(clearanceReportNote => {
                    return rightTypeFilter(clearanceReportNote, filters.value.rightType)
                    && riskLevelFilter(clearanceReportNote, filters.value.riskLevel)
                    && statusFilter(clearanceReportNote, filters.value.status)
                    && textFilter(clearanceReportNote, filterText);
                })
            )
        }
    }, [clearanceItems, filters.value, filterText]);

    useEffect(() => {
        if(activeUserIsOperator){
            setFirestoreListener('workspaceProjectsWithClearanceIds');
        }
    }, [activeUserIsOperator]);

    useEffect(() => {
        if(activeUserIsOperator){
            setSelectedProject(projectSelectedByOperator ? new Project({...projectSelectedByOperator}) : null);
        }
    }, [projectSelectedByOperator]);

    useEffect(() => {
        if(!activeUserIsOperator){ // if client, set initialSelectedProject
            setSelectedProject(restrictedToProject);
        }
    }, [restrictedToProject]);

    useEffect(() => {
        let currentSettings = null;
        let currentType = '';
        let currentTemplateId = '';
        let currentTemplate = null;
        let currentAvailableReports = [];
        let currentNotes = null;
        if(activeUserIsOperator){
            setClearanceItems(currentNotes);
            changeAvailableClearanceReports(currentAvailableReports);
            setProjectClearanceSettings(currentSettings);
            setSelectedProjectClearanceTemplate(currentTemplate);
            setSelectedProjectClearanceTemplateId(currentTemplateId);
            setSelectedProjectClearanceType(currentType);
            if(selectedProject){
                setLoading(true);
            }
        }
    }, [selectedProject]);
    
    useEffect(() => {
        if(selectedProject && projectClearanceSettings){
            setSelectedProjectClearanceType(projectClearanceSettings.type);
            setSelectedProjectClearanceTemplateId(projectClearanceSettings.templateId);
            const clearanceReports = Object.entries(projectClearanceSettings.reports || {}).map(([reportId, report]) => ({...report, uid: reportId}));
            changeAvailableClearanceReports(clearanceReports);
            if(clearanceReports.length === 0){
                setClearanceItems([]);
                setLoading(false);
            }
        }
    }, [selectedProject, projectClearanceSettings]);

    useEffect(() => {
        const approvalsRequired = projectClearanceSettings?.approvals?.numberOfApprovalsRequired;
        if(!activeUserIsOperator && approvalsRequired){
            let areApprovalsRequired = false;
            for(const key in approvalsRequired){
                if((approvalsRequired[key] || 0) > 0) areApprovalsRequired = true;
            }
            if(areApprovalsRequired){
                filtersOptions.value = {
                    ...filtersOptions.value,
                    status: [
                        {value: 'approved', description: 'Riscos aprovados', label: <Grid container spacing={1} alignItems="center"><Grid size="grow"><Typography variant="body1">Riscos aprovados</Typography></Grid><Grid><ThumbUpIcon /></Grid></Grid>},
                        {value: 'rejected', description: 'Riscos reprovados', label: <Grid container spacing={1} alignItems="center"><Grid size="grow"><Typography variant="body1">Riscos reprovados</Typography></Grid><Grid><ThumbDownIcon /></Grid></Grid>},
                        {value: 'all', label: 'Todas'}
                    ]
                }
            }
        }
    }, [projectClearanceSettings]);
    
    useEffect(() => {
        if(selectedProjectClearanceTemplate){
            filtersOptions.value = {
                ...filtersOptions.value,
                rightType: [{value: 'all', label: 'Todos'}, ...Object.entries(selectedProjectClearanceTemplate.rightTypes).map(([rightTypeId, rightType]) => ({value: rightTypeId, label: rightType.name})).sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))]
            }
        }
    }, [selectedProjectClearanceTemplate]);

    useEffect(() => {
        let currentFilterActive = false;
        for(const key in filters.value){
            if(!['', 'all'].includes(filters.value[key])) currentFilterActive = true;
        }
        setFilterActive(currentFilterActive);
    }, [filters.value]);

    const changeAvailableClearanceReports = (newValue, shouldNotSetSelectedReportId) => {
        if(!activeUserIsOperator) newValue = newValue.filter(report => !!report.sent);
        setAvailableClearanceReports(newValue);
        if(newValue){
            const currentClearanceReportOptions = newValue
            .sort((a, b) => {
                if(a.type !== 'script' && b.type === 'script'){
                    return 1;
                } else if(a.type === 'script' && b.type !== 'script'){
                    return -1;
                } else {
                    if(a.episode > b.episode){
                        return 1;
                    } else if(a.episode < b.episode){
                        return -1;
                    } else {
                        if(a.type === 'script' && b.type === 'script'){
                            return a.scriptTreatment > b.scriptTreatment ? 1 : a.scriptTreatment < b.scriptTreatment ? -1 : 0;
                        } else {
                            return a.cutVersion > b.cutVersion ? 1 : a.cutVersion < b.cutVersion ? -1 : 0;
                        }
                    }
                }
            })
            .map(report => {
                const reportEpisode = report.episode;
                const reportType = report.type;
                return ({value: report.uid, label: `${reportEpisode ? `Episódio ${reportEpisode}, ` : ''}${reportType === 'script' ? `Tratamento ${report.scriptTreatment}` : `Corte ${report.cutVersion}`}`});
            });
            setClearanceReportOptions(currentClearanceReportOptions);
            if(currentClearanceReportOptions.length !== 0){
                if(!shouldNotSetSelectedReportId) setSelectedReportId(currentClearanceReportOptions[currentClearanceReportOptions.length - 1].value);
            } else {
                setSelectedReportId('');
            }
        }
    };

    useEffect(() => {
        getClearance(selectedReportId);
        let currentSelectedReport = null;
        if(selectedReportId){
            currentSelectedReport = availableClearanceReports.find(report => report.uid === selectedReportId);
        }
        setSelectedReport(currentSelectedReport);
    }, [selectedReportId]);

    useEffect(() => {
        if(selectedProjectClearanceTemplateId) getClearanceTemplate({
            clearanceTemplateId: selectedProjectClearanceTemplateId,
            setClearanceTemplate: setSelectedProjectClearanceTemplate,
            setLoading
        });
    }, [selectedProjectClearanceTemplateId]);

    useEffect(() => {
        if(!usedSearchReportId.current && clearanceReportOptions?.length >= 1){
            const search = queryString.parse(window.location.search);
            const { relatorio: reportId } = search;
            if(reportId){
                usedSearchReportId.current = true;
                setSelectedReportId(reportId);
            }
        }
    }, [clearanceReportOptions]);

    useEffect(() => {
        if(!usedSearchReportItemId.current && selectedReport && clearanceItems?.length !== 0){
            const search = queryString.parse(window.location.search);
            const { anotacao: reportItemId } = search;
            if(reportItemId){
                usedSearchReportItemId.current = true;
                const foundClearanceItem = clearanceItems.find(clearanceItem => clearanceItem.uid === reportItemId);
                if(foundClearanceItem){
                    handleCommentsButtonClick(null, foundClearanceItem);
                }
            }
        }
    }, [clearanceItems]);

    const getClearance = async (reportId) => {
        if(reportId && reportId !== 'none' && (!projectClearanceSettings?.reportsRestrictedToManagers || activeUserIsOperator || activeUserIsGroupsManager.value)){
            setLoading(true);
            
            setSetClearanceReportItemsSnapshotSettings({
                projectId: selectedProject.uid,
                reportId,
                shouldSetSnapshot: true
            });

        }
    };

    const handleNewRightButtonClick = () => {
        selectedClearanceReportNote.value = null;
        clearanceWindowOpen.value = true;
    };

    const handleClearanceItemBoxClick = useCallback((clickedClearanceItem) => {
        if(clickedClearanceItem.data) selectedClearanceReportNote.value = clickedClearanceItem.data;
        if(activeUserIsOperator){
            clearanceWindowOpen.value = true;
        } else {
            commentsWindowOpen.value = true;
        }
    }, []);

    const handleCommentsButtonClick = useCallback((e, clickedClearanceItem) => {
        if(e) e.stopPropagation();
        selectedClearanceReportNote.value = clickedClearanceItem;
        commentsWindowOpen.value = true
    }, []);

    const handleNewReportButton = () => {
        newReportWindowOpen.value = true;
    };

    const handleSendReportButton = async () => {
        setSendReportAlertDialogOpen(true);
    };
    const handleSendReportConfirm = async () => {
        setSendReportAlertDialogOpen(false);
        setLoading(true);
        setSendingReport(true);
        const toastId = toast.loading(`Disponibilizando o relatório para o cliente...`);
        const res = await selectedProject.makeClearanceReportAvailableToClient({
            reportId: selectedReportId
        });
        setSendingReport(false);
        setLoading(false);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleMenuOpen = (e, selectedFilter) => {
        filterPickerEl.value = e.currentTarget;
        filterPickerId.value = selectedFilter;
        filterPickerOpen.value = true;
    };

    const handleDeleteReportItemPress = () => {
        setCancelReportItemDialogOpen(true);
    }

    const handleDeleteReportItemConfirm = async () => {
        setCancelReportItemDialogOpen(false);
        clearanceWindowOpen.value = false;

        const toastId = toast.loading(`Excluindo anotação de relatório de clearance "${selectedClearanceReportNote.value.description}..."`);

        const res = await selectedClearanceReportNote.value.cancel(activeUser.value.uid);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    }

    const getFilterLabelByValue = (value, filter) => {
        const currentFilterOptions = filtersOptions.value[filter];
        const currentFilterOption = currentFilterOptions.find(option => option.value === value);
        return currentFilterOption.description || currentFilterOption.label;
    };
    
    return (
        <ViewBox>
            {
                (!activeUserIsOperator && projectClearanceSettings?.reportsRestrictedToManagers && !activeUserIsGroupsManager.value)
                ?
                <Box>
                    <NoResults text="Nenhum relatório disponível." />
                </Box>
                :
                <Box>
                    <Box mb={2}>
                        {
                            activeUserIsOperator &&
                            <Box mb={1}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid size={{ xs: 12, sm: 4, md: 2 }}>
                                        <Autocomplete
                                            disabled={!projects}
                                            label="Projeto"
                                            options={projectOptions || []}
                                            getOptionLabel={(option) => option.name}
                                            value={projectSelectedByOperator}
                                            onChange={(_, newValue) => setProjectSelectedByOperator(newValue)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                        <Grid container spacing={1} alignItems="center">
                            <Grid size={{ xs: 12 }}>
                                <Fade in={projectClearanceSettings}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid size={{ xs: 12 }} container>
                                            {
                                                clearanceReportOptions.length !== 0 &&
                                                <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                                                    <Select
                                                        label="Relatório"
                                                        options={clearanceReportOptions}
                                                        value={selectedReportId} onChange={(e) => setSelectedReportId(e.target.value)}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                (activeUserIsOperator && selectedProjectClearanceType && selectedProjectClearanceTemplate) &&
                                                <Grid>
                                                    <Button variant="contained" color="primary" onClick={handleNewReportButton}>Novo relatório</Button>
                                                </Grid>
                                            }
                                        </Grid>
                                        {
                                            (selectedReport) &&
                                            <>
                                                <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                                                    <FilterInput value={filterText} setValue={setFilterText} />
                                                </Grid>
                                                {
                                                    filtersOptions.value.status &&
                                                    <Grid>
                                                        <MenuChip
                                                            label="Situação"
                                                            onPress={(e) => handleMenuOpen(e, 'status')}
                                                            value={filters.value.status} valueLabel={getFilterLabelByValue(filters.value.status, 'status')}
                                                        />
                                                    </Grid>
                                                }
                                                {
                                                    selectedProjectClearanceTemplate &&
                                                    <Grid>
                                                        <MenuChip
                                                            label="Direito"
                                                            onPress={(e) => handleMenuOpen(e, 'rightType')}
                                                            value={filters.value.rightType} valueLabel={getFilterLabelByValue(filters.value.rightType, 'rightType')}
                                                        />
                                                    </Grid>
                                                }
                                                <Grid>
                                                    <MenuChip
                                                        label="Risco"
                                                        onPress={(e) => handleMenuOpen(e, 'riskLevel')}
                                                        value={filters.value.riskLevel} valueLabel={getFilterLabelByValue(filters.value.riskLevel, 'riskLevel')}
                                                    />
                                                </Grid>
                                                {
                                                    filterActive &&
                                                    <Grid>
                                                        <IconButton size="small" onClick={() => filters.value = defaultFilters}><ClearIcon /></IconButton>
                                                    </Grid>
                                                }
                                            </>
                                        }
                                        <Grid size="grow"></Grid>
                                        {
                                            (selectedProject && selectedReport && clearanceItems?.length !== 0) &&
                                            <>
                                                <DownloadXlsxFileButton projectId={selectedProject.uid} reportId={selectedReportId} projectClearanceSettings={projectClearanceSettings} />
                                                <DownloadPdfFileButton projectId={selectedProject.uid} reportId={selectedReportId} projectClearanceSettings={projectClearanceSettings} />
                                            </>
                                        }
                                    </Grid>
                                </Fade>
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        selectedReport &&
                        <Box mb={3}>
                            <SectionTitle title={`${selectedReport.episode ? `Episódio ${selectedReport.episode}, ` : ''}${selectedReport.type === 'script' ? `Tratamento ${selectedReport.scriptTreatment}` : `Corte ${selectedReport.cutVersion}`}`} />
                            {
                                selectedReport.videoPlatformUrl &&
                                <Link variant="body1" href={selectedReport.videoPlatformUrl} target="_blank">{selectedReport.videoPlatformUrl}</Link>
                            }
                            {
                                (activeUserIsOperator && selectedReport && !selectedReport.sent) &&
                                <Box>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid>
                                            <Typography variant="body1">Este relatório ainda não foi disponibilizado para o cliente.</Typography>
                                        </Grid>
                                        <Grid size="grow">
                                            <Button
                                                variant="outlined" color="primary"
                                                endIcon={sendingReport ? <CircularProgress size={20} /> : <SendIcon />}
                                                onClick={handleSendReportButton}
                                            >Enviar</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </Box>
                    }
                    {
                        (loading || (!activeUserIsOperator && !clearanceItems))
                        ? <LoaderEllipsis />
                        :
                        <>
                            <div style={{ backgroundColor: 'white' }}>
                                <ClearanceItemsList
                                    activeUserIsOperator={activeUserIsOperator}
                                    clearanceItems={visibleClearanceReportNotes}
                                    handleClearanceItemBoxClick={handleClearanceItemBoxClick}
                                    handleCommentsButtonClick={handleCommentsButtonClick}
                                    projectClearanceSettings={projectClearanceSettings}
                                    selectedReport={selectedReport}
                                />
                            </div>
                            {
                                (activeUserIsOperator && visibleClearanceReportNotes && selectedReport) &&
                                <Box pt={2}>
                                    <Grid container justifyContent="center">
                                        <Grid>
                                            <Button
                                                disabled={!selectedProject || !selectedProjectClearanceTemplate || !selectedReport}
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                onClick={handleNewRightButtonClick}
                                            >
                                                Anotação
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            }
                        </>
                    }
                </Box>
            }

            <ClearanceWindow
                open={clearanceWindowOpen}
                activeUserIsOperator={activeUserIsOperator}
                clearanceItems={clearanceItems}
                projectClearanceSettings={projectClearanceSettings}
                selectedProject={selectedProject}
                selectedProjectClearanceTemplate={selectedProjectClearanceTemplate}
                selectedClearanceReportNote={selectedClearanceReportNote}
                selectedReport={selectedReport}
                handleDeleteReportItemPress={handleDeleteReportItemPress}
            />
            <CommentsWindow
                activeUserIsOperator={activeUserIsOperator}
                commentsWindowOpen={commentsWindowOpen}
                projectClearanceSettings={projectClearanceSettings}
                selectedProject={selectedProject}
                selectedClearanceReportNote={selectedClearanceReportNote}
                selectedReport={selectedReport}
                selectedProjectClearanceTemplate={selectedProjectClearanceTemplate}
                setClearanceItems={setClearanceItems}
            />
            <NewReportWindow
                newReportWindowOpen={newReportWindowOpen}
                availableClearanceReports={availableClearanceReports}
                selectedProject={selectedProject}
                selectedProjectClearanceType={selectedProjectClearanceType}
                changeAvailableClearanceReports={changeAvailableClearanceReports}
                setSelectedReportId={setSelectedReportId}
            />

            <FilterPicker />

            <AlertDialog
                open={sendReportAlertDialogOpen} onClose={() => setSendReportAlertDialogOpen(false)}
                title="Tem certeza?"
                text={`Quer disponibilizar este relatório para o cliente?`}
                okButtonOnClick={handleSendReportConfirm}
            />
            <AlertDialog
                open={cancelReportItemDialogOpen} onClose={() => setCancelReportItemDialogOpen(false)}
                title="Tem certeza?"
                text={`Quer excluir esta anotação do relatório? Essa ação é irreversível.`}
                okButtonOnClick={handleDeleteReportItemConfirm}
            />
        </ViewBox>
    );
};

export default ClearanceItemsListPage;