import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

const RadioQuestion = (props) => {
    const { initialValue } = props;
    const inputRef = useRef(null);
    const defaultValue = { choice: '', input: '' };
    const [value, setValue] = useState(defaultValue);
    const [textFieldOptionId, setTextFieldOptionId] = useState('');

    useEffect(() => {
        const currentTextFieldOption = props.question.options.find(option => option.choice === '~t;' || option.choice === '~p;' || option.choice === '&p');
        if(currentTextFieldOption){
            setTextFieldOptionId(currentTextFieldOption.choice);
        }
    }, [props.question]);
    
    useEffect(() => {
        setValue(initialValue || defaultValue);
    }, [initialValue]);

    useEffect(() => {
        if(props.onChange) props.onChange(props.question.name, value);
    }, [value]);

    const handleChoiceChangeAction = (newValue) => {
        setValue(prevState => ({...prevState, choice: newValue}));
    };
    
    const focusTextField = () => {
        inputRef.current.focus();
    };
    
    const handleChoiceChange = (newValue) => {
        handleChoiceChangeAction(newValue);
        if(newValue === textFieldOptionId) focusTextField();
    };

    const handleTextFieldFocus = () => {
        if(value.choice !== textFieldOptionId){
            handleChoiceChangeAction(textFieldOptionId);
        }
    };

    return (
        <Box>
            <RadioGroup
                aria-label={props.question.name}
                name={props.question.name}
                value={value.choice}
                onChange={(e) => handleChoiceChange(e.target.value)}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
            >
            {
                props.question.options.map((option) => (
                    <FormControlLabel
                        key={option.choice}
                        sx={{
                            color: theme => theme.palette.text.primary,
                            '&>*:nth-of-type(2)': {
                                width: '100%'
                            }
                        }}
                        data-option={option.choice}
                        value={option.choice}
                        control={<Radio />}
                        label={
                            (option.choice !== textFieldOptionId)
                            ? option.label
                            :
                            <TextField
                                variant="standard"
                                fullWidth
                                multiline
                                placeholder="Sua resposta"
                                inputRef={inputRef}
                                value={value.input}
                                onChange={(e) => setValue(prevState => ({...prevState, input: e.target.value}))}
                                onFocus={handleTextFieldFocus} />
                        }
                    />
                ))
            }
            </RadioGroup>
        </Box>
    );
}

export default RadioQuestion;