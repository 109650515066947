import { useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import FormDialog from '../../../components/FormDialog';
import { useAppStateCtx } from '../../../context/AppState';

const SavedFormNameDialog = ({open, handleOk}) => {
    useSignals();
    const { formSelectedDraft } = useAppStateCtx();
    const [textFieldValue, set_textFieldValue] = useState('');

    const getSavedFormName = (form) => {
        if(form) return form.name;
        return null;
    }

    const handleClose = () => {
        open.value = false;
    }

    return (
        <FormDialog
            id="saved-form-name-dialog"
            open={open.value} onEnter={() => set_textFieldValue(getSavedFormName(formSelectedDraft.value) || '')} onClose={handleClose}
            title="Concluir depois" text={`Escolha um nome para salvar o formulário.${formSelectedDraft.value ? ` Isso sobrescreverá o formulário "${formSelectedDraft.value.name}".` : ''}`} textFieldLabel="Nome"
            textFieldValue={textFieldValue} textFieldOnChange={(e) => set_textFieldValue(e.target.value)}
            okButtonOnClick={() => handleOk(textFieldValue)}
            okButtonDisabled={textFieldValue === ''}
        />
    );
}

export default SavedFormNameDialog;