import { useState, useEffect } from 'react';
// import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

// import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';

import ActionMenu from '../components/ActionMenu';

import CancelScheduledSendDialog from './components/CancelScheduledSendDialog';
import PendingDocumentsList from './components/PendingDocumentsList';

import OperatorDocumentsSinglePageFilters from '../components/OperatorDocumentsSinglePageFilters';

import AddDocumentWindow from '../../../../components/AddDocumentWindow';
import ChangeDocumentFolderMenu from '../../../../components/ChangeDocumentFolderMenu';
import ChangeDocumentGroupWindow from '../../../../components/ChangeDocumentGroupWindow';
import ChangeReviewerMenu from '../../../../components/ChangeReviewerMenu';
import CommentWindow from '../../../../components/CommentWindow';
import DeleteDocDialog from '../../../../components/DeleteDocDialog';
import DocFormView from '../../../../components/DocFormView';
import FlagDocumentWindow from '../../../../components/Documents/FlagDocumentWindow';
import HistoryPopOver from '../../../../components/HistoryPopOver';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import SubmitDocDialog from '../../../../components/SubmitDocDialog';
import UpdateNameDialog from '../../../../components/UpdateNameDialog';
import ViewBox from '../../../../components/ViewBox';
import { useAppStateCtx } from '../../../../context/AppState';
import { useDocumentsAPI, useDocumentsCtxLoadingPendingDocuments, useDocumentsCtxPendingDocumentsCount } from '../../../../context/DocumentsContext';

const addDocumentViewOpen = signal(false);
const filterClientId = signal('all');
const filterProjectId = signal('all');
const filterOperatorId = signal('all');
const filterText = signal('');
const numberOfVisibleDocuments = signal(0);
const visibleDocuments = signal([]);

const NoDocumentsPending = () => {
    useSignals();
    const loadingPendingDocuments = useDocumentsCtxLoadingPendingDocuments();
    const { pendingDocumentsCount } = useDocumentsCtxPendingDocumentsCount();

    if(!loadingPendingDocuments && pendingDocumentsCount === 0){
        return (
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>👏</Grid>
                    <Grid item>
                        <Typography variant="body1">Parabéns! Não há contratos aguardando revisão.</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return null;
}

const Docs = () => {
    useSignals();
    const { activeUser, workspaceProjects } = useAppStateCtx();
    const { setSnapshot: setDocumentsCtxSnapshot } = useDocumentsAPI(); 
    const loadingPendingDocuments = useDocumentsCtxLoadingPendingDocuments();
    const [saving, setSaving] = useState(false);// const [monthNumberOfDocumentsGenerated, set_monthNumberOfDocumentsGenerated] = useState(0);

    useEffect(() => {
        setDocumentsCtxSnapshot('shouldSetWorkspacePendingDocumentsSnapshot');
    }, []);

    useEffect(() => {
        if(activeUser.value) filterOperatorId.value = activeUser.value.uid;
    }, [activeUser.value]);

    // useEffect(() => {
    //     let numberOfDocumentsGenerated = 0;
    //     if(selectedWorkspace.value){
    //         const monthTotals = selectedWorkspace.value.monthTotals;
    //         if(monthTotals){
    //             const year = moment().year();
    //             if(monthTotals[year]){
    //                 const month = moment().month();
    //                 if(monthTotals[year][month]) numberOfDocumentsGenerated = monthTotals[year][month].numberOfDocumentsGenerated || 0;
    //             }
    //         }
    //     }
    //     set_monthNumberOfDocumentsGenerated(numberOfDocumentsGenerated);
    // }, [selectedWorkspace.value]);

    const handle_addDocumentClick = () => {
        addDocumentViewOpen.value = true;
    };
    
    const handleClientChange = (newValue) => {
        filterClientId.value = newValue;
        filterProjectId.value = 'all';
    };

    const handleSentDocument = () => {
        // filter();
    };

    const clearFilters = () => {
        filterClientId.value = 'all';
        filterProjectId.value = 'all';
        filterOperatorId.value = 'all';
        filterText.value = '';
    };

    return (
        <ViewBox>
            <Box mb={2}>
                <Grid container alignItems="center" justifyContent="center" spacing={1}>
                    <OperatorDocumentsSinglePageFilters
                        filterClientId={filterClientId}
                        filterProjectId={filterProjectId}
                        filterOperatorId={filterOperatorId}
                        filterText={filterText}
                        clearFilters={clearFilters}
                        handleClientChange={handleClientChange}
                        loading={loadingPendingDocuments}
                        numberOfVisibleDocuments={numberOfVisibleDocuments}
                        visibleDocuments={visibleDocuments}
                    />
                    <Grid item xs container alignItems="flex-end" justifyContent="flex-end">
                        <Grid item>
                            <Button disabled={loadingPendingDocuments} variant="contained" color="primary" startIcon={<AddIcon />} onClick={handle_addDocumentClick}>Adicionar documento</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* 
                //TODO
                <Collapse in={selectedWorkspace.value && selectedWorkspace.value.uid !== FIRST_CLIENT_ID && !!selectedWorkspace.value.documentsGeneratedMonthLimit && monthNumberOfDocumentsGenerated && (monthNumberOfDocumentsGenerated >= (selectedWorkspace.value.documentsGeneratedMonthLimit * 0.8))}>
                    <Box mb={1}>
                        <Alert variant="filled" severity="error" onClose={() => set_monthNumberOfDocumentsGenerated(0)}>Você já consumiu {monthNumberOfDocumentsGenerated} do limite de {selectedWorkspace.value?.documentsGeneratedMonthLimit} contratos mensais.</Alert>
                    </Box>
                </Collapse>
             */}

            {
                loadingPendingDocuments &&
                <LoaderEllipsis />
            }

            <NoDocumentsPending />

            <PendingDocumentsList
                filterClientId={filterClientId}
                filterProjectId={filterProjectId}
                filterOperatorId={filterOperatorId}
                filterText={filterText}
                saving={saving}
                numberOfVisibleDocuments={numberOfVisibleDocuments}
                visibleDocuments={visibleDocuments}
            />

            <ChangeReviewerMenu />
            <ActionMenu />
            <SubmitDocDialog
                actionDoneCallback={handleSentDocument} 
                setSaving={setSaving}
            />
            <HistoryPopOver />
            <DocFormView />
            <DeleteDocDialog />
            <UpdateNameDialog />
            <ChangeDocumentFolderMenu />
            <ChangeDocumentGroupWindow />
            <AddDocumentWindow activeUserIsOperator open={addDocumentViewOpen} />
            <CommentWindow />
            <FlagDocumentWindow activeUserIsOperator />
            <CancelScheduledSendDialog />
        </ViewBox>
    );
}

export default Docs;