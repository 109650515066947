import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import { Divider, Grid, List, ListItem, Typography } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
// import DeleteIcon from '@mui/icons-material/Delete';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import LoaderEllipsis from './LoaderEllipsis';
import Window from './Window';

import { useAppStateCtx } from '../context/AppState';
import { getUserNameById } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const SavedFormsWindow = ({
    onSelectedDocumentsTemplateIdChange,
    savedFormsWindowOpen, setSavedFormsWindowOpen,
    savedFormsWindowSelectedProject
}) => {
    useSignals();
    const { formSelectedDraft, selectedWorkspace, workspaceUsers } = useAppStateCtx();
    const [forms, setForms] = useState([]);
    const [loadingPage, set_loadingPage] = useState(false);
    const [folderName, set_folderName] = useState('');

    const getFormsDrafts = async () => {
        set_loadingPage(true);
        const res = await savedFormsWindowSelectedProject.getFormsDrafts();
        if(res.error){
            set_loadingPage(false);
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        set_folderName(savedFormsWindowSelectedProject.name);
        setForms(res.result);
        set_loadingPage(false);
    };

    useEffect(() => {
        if(savedFormsWindowOpen && savedFormsWindowSelectedProject){
            getFormsDrafts();
        }
    }, [savedFormsWindowOpen, savedFormsWindowSelectedProject]);

    const handleUseForm = (form) => {
        handleClose();
        formSelectedDraft.value = form;
        onSelectedDocumentsTemplateIdChange(form.templateId);
    };

    const handleClose = () => {
        setSavedFormsWindowOpen(false);
    };
    
    return (
        <Window
            open={savedFormsWindowOpen} onClose={handleClose}
            title={folderName}
            subTitle="Formulários"
        >
            {
                loadingPage
                ? <LoaderEllipsis />
                :
                <List>
                    {
                        forms
                        .map((form) => (
                            <React.Fragment key={form.uid}>
                                <ListItem>
                                    <ListItemButton onClick={() => handleUseForm(form)}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs container alignItems="center">
                                                <Grid item xs={6} sm={4} container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <AccessTimeOutlinedIcon />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">{moment(form.createdAt).format('L')}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={6} sm={4} container spacing={1} alignItems="center">
                                                    <Grid item>
                                                        <PersonOutlinedIcon />
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="body2">{getUserNameById(workspaceUsers.value, form.createdBy)}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs style={{textAlign: 'center'}}>
                                                    <Typography variant="h6">{form.name}</Typography>
                                                </Grid>
                                            </Grid>
                                            {
                                                ['operator/developer', 'developer'].includes(selectedWorkspace.value?.role) &&
                                                <Grid item>
                                                    {/* <IconButton edge="end" size="large">
                                                        <DeleteIcon />
                                                    </IconButton> */}
                                                </Grid>
                                            }
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        ))
                    }
                </List>
            }
        </Window>
    );
};

export default SavedFormsWindow;