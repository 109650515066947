import { toast } from 'react-toastify';
import AlertDialog from '../../../../../../components/AlertDialog';
import { useDocumentsAPI, useDocumentsCtxCancelScheduledSendDocumentView } from '../../../../../../context/DocumentsContext';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../../../../../utils/constants';

const CancelScheduledSendDialog = () => {
    const { hideCancelScheduledSendDocumentView } = useDocumentsAPI();
    const { cancelScheduledSendDocumentViewOpen, cancelScheduledSendDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxCancelScheduledSendDocumentView();

    const handleCancelScheduledSendConfirm = async () => {
        handleClose();
        const toastId = toast.loading(`Cancelando ação programada no documento "${selectedDocument.name}"...`);
        const res = await selectedDocument.update({
            updates: {
                shouldMakeAvailableToClientAt: ''
            }
        });
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
    };

    const handleClose = () => {
        hideCancelScheduledSendDocumentView();
    };

    return (
        <AlertDialog
            open={cancelScheduledSendDocumentViewOpen} onClose={handleClose}
            title="Tem certeza?"
            text={`Quer cancelar o envio programado do documento "${selectedDocument?.name}"?`}
            okButtonOnClick={handleCancelScheduledSendConfirm}
        />
    )
};

export default CancelScheduledSendDialog;