import { Route, Routes } from 'react-router-dom';
import ActiveUserLoggedIn from './ActiveUserLoggedIn';
import ClearanceDashboard from './ClearanceDashboard';
import LinkForm from './LinkForm';
import PageNotFound from './PageNotFound';
import { FormProvider } from './Form/context/FormContext';

const Connected = () => {
    return (
        <Routes> 
            <Route path="/formulario/:folderFormId" element={<FormProvider><LinkForm /></FormProvider>} />
            <Route path="/clearance/:folderId/*" element={<ClearanceDashboard />} />
            <Route path="/*" element={<ActiveUserLoggedIn />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
};

export default Connected;