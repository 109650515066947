import {useScroll} from 'react-use';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useAppContext } from '../../context/SystemContext';
import { SIDENAV_WIDTH } from '../../utils/constants';

const classes = {
    horizontalCenterVerticalBottomLeft: {
        position: 'fixed',
        bottom: '8px',
        left: `${SIDENAV_WIDTH + 16}px`,
        zIndex: (theme) => theme.zIndex.fab
    },
    horizontalCenterVerticalBottomRight: {
        position: 'fixed',
        bottom: '8px',
        right: '16px',
        zIndex: (theme) => theme.zIndex.fab
    },
};

const ChangePageButton = ({ numberOfRows, onNextClick, onPrevClick, page, rowsPerPage, showAtListThreshold }) => {
    const { mainRef } = useAppContext();
    const { y } = useScroll(mainRef);
    const numberOfPages = Math.ceil(numberOfRows / (rowsPerPage || 25));

    const showPrevButton = () => {
        return page > 1 && y <= 200;
    }

    const showNextButton = () => {
        return page < numberOfPages && y > 0 && (y + mainRef.current.clientHeight) > (mainRef.current.scrollHeight - 200);
    }

    if(showAtListThreshold){
        return (
            <>
                <Slide in={showPrevButton()} direction="up">
                    <Button sx={classes.horizontalCenterVerticalBottomLeft} variant="contained" startIcon={<ArrowBackIosIcon />} onClick={onPrevClick}>Página {page - 1}</Button>
                </Slide>
                <Slide in={showNextButton()} direction="up">
                    <Button sx={classes.horizontalCenterVerticalBottomRight} variant="contained" endIcon={<ArrowForwardIosIcon />} onClick={onNextClick}>Página {page + 1}</Button>
                </Slide>
            </>
        );
    }

    if(numberOfPages > 1){
        return (
            <Box>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                    {
                        page > 1 &&
                        <Grid><Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onPrevClick}>Página {page - 1}</Button></Grid>
                    }
                    <Grid><Typography variant="body1">(Página {page} de {numberOfPages})</Typography></Grid>
                    {
                        page < numberOfPages &&
                        <Grid><Button variant="outlined" endIcon={<ArrowForwardIcon />} onClick={onNextClick}>Página {page + 1}</Button></Grid>
                    }
                </Grid>
            </Box>
        );
    }
    return null;
}

export default ChangePageButton;