import FormQuestionNumber from '../../../../components/FormQuestionNumber';
import { useAppContext } from '../../../../context/SystemContext';
import useDocumentFormQuestion from '../../../../hooks/useDocumentFormQuestion';

const NumberQuestion = ({question, setQuestionValid}) => {
    const { formEffects } = useAppContext();
    const { initialValue, setUserFormQuestion } = useDocumentFormQuestion({
        question,
        questionType: 'number',
        setQuestionValid,
        typeSpecifics: question.typeSpecifics
    });

    const handleChange = (questionName, updates) => {
        const input = formEffects.number.getTypeSpecificInput(updates.value, question.typeSpecifics);
        setUserFormQuestion(prevState => ({
            ...prevState,
            value: updates.value,
            input,
        }));
    };
    
    return (
        <FormQuestionNumber
            initialValue={initialValue}
            onChange={handleChange}
            question={question}
        />
    );
};

export default NumberQuestion;