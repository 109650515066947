import moment from 'moment';

import { useSignals } from '@preact/signals-react/runtime';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import { useAppStateCtx } from '../context/AppState';
import { useDocumentsAPI } from '../context/DocumentsContext';
import { getUserImageById, getUserNameById } from '../utils/common';

import CommentLine from './Documents/CommentLine';
import ClientDocumentLink from './ClientDocumentLink';
import DocumentFilesList from './DocumentFilesList';
import PendingDocLink from './PendingDocLink';
import SignatureStatus from './eSignature/SignatureStatus';
import Tooltip from './Tooltip';

const StarredItem = ({name, info, timestamp, user}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();
    return (
        <ListItem dense style={{display: 'flex', paddingTop: 1, paddingBottom: 1}}>
            <Box style={{flex: 1, lineHeight: 1}}>
                <Tooltip
                    text={timestamp || user ? `${timestamp ? `Em ${moment(timestamp).format('D/M/YY')}` : ''}${user ? ` por ${getUserNameById(workspaceUsers.value, user)}` : ''}` : name}
                >
                    <Typography variant="body2" component="span" style={{lineHeight: 1}}><span style={{fontWeight: 500}}>{name}</span> &#8680; {info?.replace(/\n/g, ' | ')}</Typography>
                </Tooltip>
            </Box>
        </ListItem>
    )
};

const VersionIcon = ({theme, version}) => {
    if(version.fileFormat === 'pdf'){
        return <FontAwesomeIcon icon={faFilePdf} size="1x" color={theme.palette.secondary.main} />;
    } else if(version.fileFormat === 'docx'){
        return <FontAwesomeIcon icon={faFileWord} size="1x" color={theme.palette.primary.main} />;
    } else if(version.fileFormat === 'googleDoc'){
        return <FontAwesomeIcon icon={faGoogle} size="1x" color={theme.palette.primary.main} />;
    }
    return null;
};

const Starred = ({record}) => {
    return null;
    //TODO: Reprogramar sistema de informações em destaque.
    
    useSignals();
    const { projectDocumentSettings } = useAppStateCtx();

    const handle_newStarredInfoClick = (event, list = []) => {
        // set_changeStarredInfoList(list);
        // showAddDocumentStarredInformationView({
        //     left: event.clientX - 2,
        //     top: event.clientY - 4,
        // }, record);
    };

    const StarredList = ({list}) => {
        const handle_editStarredListClick = (event) => {
            // set_changeStarredInfoList(list);
            // showChangeDocumentStarredInformationView({
            //     left: event.clientX - 2,
            //     top: event.clientY - 4,
            // }, record);
        };
        return (
            <Box style={{display: 'flex', alignItems: 'center', border: '1px solid #e8e8e8', borderRadius: '8px'}} py={0.3}>
                <Box style={{flex: 1}}>
                    <List disablePadding>
                        {
                            list.map((item, index) => (
                                <StarredItem key={index} name={item.key} info={item.value} timestamp={item.timestamp} user={item.user} />
                            ))        
                        }
                    </List>
                </Box>
                <Box>
                    <IconButton
                        size="small"
                        onClick={(e) => handle_editStarredListClick(e)}><EditIcon /></IconButton>
                    <IconButton
                        size="small"
                        onClick={(e) => handle_newStarredInfoClick(e, list)}><AddIcon /></IconButton>
                </Box>
            </Box>
        )
    };

    if(projectDocumentSettings.value?.starredFields?.enabled){
        const documentInfo = record.info;
        if(Array.isArray(documentInfo) && documentInfo.length >= 1){
            return <StarredList list={documentInfo} />;
        }
        return (
            <Box style={{textAlign: 'center'}}>
                <Box style={{display: 'inline-block', backgroundColor: '#e8e8e8', borderRadius: '50%'}}>
                    <Tooltip text={"Cadastrar informações em destaque"}>
                        <IconButton
                            style={{height: 8, width: 8}}
                            onClick={(e) => handle_newStarredInfoClick(e)}
                            size="large"><AddIcon /></IconButton>
                    </Tooltip>
                </Box>
            </Box>
        );
    }

    return null;
};

const LocalSignatureStatus = ({
    activeUserIsManager,
    activeUserIsOperator,
    document,
    handleSignDocumentWindowOpenClick,
    project,
    setSelectedDocument
}) => {
    return (
        <SignatureStatus
            activeUserIsManager={activeUserIsManager}
            activeUserIsOperator={activeUserIsOperator}
            documentType="document"
            documentVersion={document.lastVersion}
            loadingSignature={document.loadingSignIcon}
            onSignDocumentClick={handleSignDocumentWindowOpenClick}
            project={project}
            projectName=""
            selectedDocument={document}
            setSelectedDocument={setSelectedDocument}
        />
    );
}

const VersionRowMainColumn = (props) => {
    useSignals();
    const {
        activeUserIsManager,
        activeUserIsOperator,
        // data,
        comment,
        eSignature,
        item,
        noHtmlComment,
        project,
        // projects,
        setSelectedDocument,
    } = props;
    const theme = useTheme();
    const screenMDDownExclusive = useMediaQuery(theme.breakpoints.down('sm'));
    const screenMDUpInclusive = useMediaQuery(theme.breakpoints.up('sm'));
    const { selectedWorkspace, workspaceDocumentsTemplates, workspaceUsers } = useAppStateCtx();
    const {
        // showAddDocumentStarredInformationView, showChangeDocumentStarredInformationView,
        showSignDocumentView, showDocumentHistoryView
    } = useDocumentsAPI();
    // const {
    //     set_changeStarredInfoList,
    // } = data;
    // const [showDocumentsStarredInformation, setShowDocumentsStarredInformation] = useState(false);

    // useEffect(() => {
    //     if(item && item.projectId && projects){
    //         const documentFolder = projects.find(folder => folder.uid === item.project);
    //         if(documentFolder){
    //             setShowDocumentsStarredInformation(!!documentFolder.showDocumentsStarredInformation);
    //         }
    //     }
    // }, [item, projects]);

    const handle_documentHistoryPress = (event, clickedDocument) => {
        showDocumentHistoryView({
            left: event.clientX - 2,
            top: event.clientY - 4,
        }, clickedDocument, activeUserIsOperator, activeUserIsManager);
    };

    const handleSignDocumentWindowOpenClick = async () => {
        showSignDocumentView({
            action: '',
            document: item,
            documentName: item.name
        });
    };

    return (
        <Box>
            {
                !item.availableToClient || item.deleted
                ?
                <Box>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid>
                            <Tooltip text="Histórico">
                                <IconButton size="small" onClick={(e) => handle_documentHistoryPress(e, item)}><AccessTimeIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid>
                            {
                                selectedWorkspace.value?.role === 'client'
                                ? <Typography variant="body2">{item.name}</Typography>
                                : <PendingDocLink selectedDocument={item} />
                            }
                        </Grid>
                        <Grid>
                            <Typography variant="body2"><em>({!item.availableToClient ? 'Em análise' : 'Cancelado'})</em></Typography>
                        </Grid>
                    </Grid>
                    <DocumentFilesList doc={item} />
                </Box>
                :
                <Box>
                    <Grid container spacing={1} alignItems="center" style={{width: '100%'}}>
                        {
                            activeUserIsOperator &&
                            <Grid>
                                <Tooltip text={`${getUserNameById(workspaceUsers.value, item.operatorId)}`}>
                                    <Avatar style={{height: 30, width: 30}} alt={getUserNameById(workspaceUsers.value, item.operatorId)} src={getUserImageById(workspaceUsers.value, item.operatorId)} />
                                </Tooltip>
                            </Grid>
                        }
                        <Grid>
                            <Tooltip text="Histórico">
                                <IconButton size="small" onClick={(e) => handle_documentHistoryPress(e, item)}><AccessTimeIcon /></IconButton>
                            </Tooltip>
                        </Grid>
                        {
                            item.lastVersion &&
                            <Grid>
                                <VersionIcon version={item.lastVersion} theme={theme} />
                            </Grid>
                        }
                        <Grid size="grow">
                            <ClientDocumentLink document={item} />
                        </Grid>
                        {
                            ((eSignature || item?.lastVersion?.eSignature?.enabled) && workspaceDocumentsTemplates.value && screenMDUpInclusive) &&
                            <Grid size={{ xs: 5, sm: 4, md: 3 }}>
                                <LocalSignatureStatus
                                    activeUserIsManager={activeUserIsManager}
                                    activeUserIsOperator={activeUserIsOperator}
                                    document={item}
                                    handleSignDocumentWindowOpenClick={handleSignDocumentWindowOpenClick}
                                    project={project}
                                    setSelectedDocument={setSelectedDocument}
                                />
                            </Grid>
                        }
                    </Grid>
                    <CommentLine comment={comment} noHtmlComment={noHtmlComment} />
                    {
                        ((eSignature || item?.lastVersion?.eSignature?.enabled) && workspaceDocumentsTemplates.value && screenMDDownExclusive) &&
                        <Box mt={1}>
                            <Grid container justifyContent="center">
                                <Grid>
                                    <LocalSignatureStatus
                                        activeUserIsManager={activeUserIsManager}
                                        activeUserIsOperator={activeUserIsOperator}
                                        document={item}
                                        handleSignDocumentWindowOpenClick={handleSignDocumentWindowOpenClick}
                                        project={project}
                                        setSelectedDocument={setSelectedDocument}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    }
                    <Starred record={item} />
                </Box>
            }
        </Box>
    );
};

export default VersionRowMainColumn;