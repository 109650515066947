import { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import Timeline, { DateHeader, SidebarHeader, TimelineHeaders, TodayMarker } from 'react-calendar-timeline';
import { TimelineItemBase } from 'react-calendar-timeline/dist/lib/types/main';
import './Timeline.scss';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { ACTIVE_PROJECT_TYPES } from '../../../utils/constants';

import ActiveProjectsWorkTypeBoxes from '../Questions/Accounts/components/ActiveProjectsWorkTypeBoxes';

type TimelineItem = TimelineItemBase<Moment> & { type: string; };

const keyboardInfoVisible = signal(true);
const projectTypeFilter = signal('all');
const visibleTimelineItems = signal<TimelineItem[]>([]);

const KeyboardInfo = () => {
    useSignals();
    const handleClose = () => {
        keyboardInfoVisible.value = false
    }
    return (
        <Collapse in={keyboardInfoVisible.value}>
            <Box mb={2}>
                <Alert severity="info" onClose={handleClose}><Typography variant="body2">SHIFT + roda do mouse para avançar ou voltar no tempo</Typography><Typography variant="body2">ALT + roda do mouse para zoom</Typography></Alert>
            </Box>
        </Collapse>
    );
}

const LocalTimeline = () => {
    useSignals();
    const { workspaceActiveProjectBoards, workspaceOperators, workspaceUsers } = useAppStateCtx();
    const [groups, setGroups] = useState<{ id: string; stackItems: boolean; title: string; }[]>([]);
    const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);

    useEffect(() => {
        if(workspaceOperators.value){
            setGroups(workspaceOperators.value.map(operator => ({ id: operator.uid, title: operator.name, stackItems: true })))
        }
    }, [workspaceOperators.value]);

    useEffect(() => {
        if(workspaceActiveProjectBoards.value){
            let currentTimelineItems: TimelineItem[] = [];
            workspaceActiveProjectBoards.value.forEach(activeProject => {
                if(activeProject.shouldShowOnTimeline){
                    const foundActiveProjectType = ACTIVE_PROJECT_TYPES.find(activeProjectType => activeProjectType.value === activeProject.workType);
                    currentTimelineItems.push({
                        canMove: false,
                        canResize: false,
                        canChangeGroup: false,
                        end_time: moment(activeProject.endTime),
                        id: activeProject.uid,
                        group: activeProject.operatorId,
                        start_time: moment(activeProject.startTime),
                        title: activeProject.name,
                        type: activeProject.workType,
                        itemProps: {
                            style: {
                                backgroundColor: foundActiveProjectType?.color || 'grey',
                                borderColor: 'transparent',
                                borderRadius: '2px'
                            }
                        }
                    })
                }
            })
            setTimelineItems(currentTimelineItems);
        }
    }, [workspaceActiveProjectBoards.value]);

    useEffect(() => {
        visibleTimelineItems.value = timelineItems.filter(timelineItem => projectTypeFilter.value === 'all' || timelineItem.type === projectTypeFilter.value);
    }, [timelineItems, projectTypeFilter.value])

    // const getLabelFormat = () => {}

    return (
        <Timeline
            groups={groups}
            groupRenderer={({ group }) => {
                let foundWorkspaceUser;
                if(workspaceUsers.value) foundWorkspaceUser = workspaceUsers.value[group.id];
                return (
                    <div className="custom-group">
                        <Grid container spacing={1} alignItems="center">
                            <Grid>
                                <Avatar style={{ height: 20, width: 20 }} src={foundWorkspaceUser?.photoUrl}>{group.title[0]}</Avatar>
                            </Grid>
                            <Grid size="grow">
                                <Typography variant="body1">{group.title}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                )
            }}
            items={visibleTimelineItems.value}
            defaultTimeStart={moment().add(-90, 'days').valueOf()}
            defaultTimeEnd={moment().add(90, 'days').valueOf()}
            minZoom={90 * 24 * 60 * 60 * 1000}
            maxZoom={2 * 360 * 24 * 60 * 60 * 1000}
            sidebarWidth={200}
            useResizeHandle
        >
            <TimelineHeaders style={{ background: 'rgb(240, 240, 240)', borderTop: '1px solid #bbb', color: '#36454F', position: 'sticky', textAlign: 'center', top: 0, zIndex: 91 }}>
                <SidebarHeader>
                    {({ getRootProps }) => {
                        return (
                            <div {...getRootProps()}>
                                <Typography variant="body1" display="inline" style={{ color: '#36454F', fontWeight: 600, position: 'absolute', top: '50%', transform: 'translate(-50%, -50%)' }}>Advogado(a)</Typography>
                            </div>
                        );
                    }}
                </SidebarHeader>
                <DateHeader unit="year" />
                <DateHeader
                    unit="month"
                    labelFormat={
                        (timeRange, _, width) => {
                            if(width){
                                const currentMoment = moment(timeRange[0].valueOf());
                                return `${currentMoment.format(width < 100 ? 'MMM' : 'MMMM')}`;
                            }
                            return '';
                        }
                    }
                />
            </TimelineHeaders>
            <TodayMarker />
        </Timeline>
    )
}

const ActiveProjectsTimeline = ({}) => {
    useSignals();
    const { setFirestoreListener } = useAppStateCtxUtils();

    useEffect(() => {
        setFirestoreListener('workspaceActiveProjectBoards');
        return () => {
            visibleTimelineItems.value = [];
        }
    }, []);

    const handleTypeFilterClick = (activeProjectType: { [key: string]: any; }) => {
        projectTypeFilter.value = projectTypeFilter.value === activeProjectType.value ? 'all' : activeProjectType.value;
    }

    return (
        <Box>
            <KeyboardInfo />
            <Box mb={2}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <ActiveProjectsWorkTypeBoxes
                        onClick={handleTypeFilterClick}
                        style={(activeProjectType: { [key: string]: any; }) => !projectTypeFilter.value || projectTypeFilter.value === activeProjectType.value || projectTypeFilter.value === 'all' ? ({border: '2px solid #313330', opacity: 1}) : undefined} // ({ border: '2px solid transparent', opacity: 0.6 })}
                        textStyle={(activeProjectType: { [key: string]: any; }) => !projectTypeFilter.value || projectTypeFilter.value === activeProjectType.value || projectTypeFilter.value === 'all' ? ({opacity: 1}) : undefined} // ({ opacity: 0.6 })}
                    />
                </Grid>
            </Box>
            <LocalTimeline />
        </Box>
    );
};

export default ActiveProjectsTimeline;