import { useEffect, useRef, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx } from '../../../context/AppState';
import FormQuestionRadioGrid from '../form-question-skeleton-components/FormQuestionRadioGrid';
import { radioGrid } from '../settings/documents-templates-radio-grid';
import { useForm } from '../../../pages/Form/context/FormContext';

const RadioGridQuestion = ({question, setQuestionValid}) => {
    useSignals();
    const { formInitialValues } = useAppStateCtx();
    const { values } = useForm();
    const [value, setValue] = useState(null);
    const touchedField = useRef(false);
    const userFormQuestion = useRef(null);
    const [userFormQuestionUpdated, setUserFormQuestionUpdated] = useState(null);

    useEffect(() => {
        const nextQuestionState = {
            ...values.current[question.name],
            id: question.id,
            type: 'radioGrid'
        };
        userFormQuestion.current = nextQuestionState;
        values.current = {
            ...values.current,
            [question.name]: nextQuestionState
        }
        setUserFormQuestionUpdated(new Date());
    }, []);
    
    useEffect(() => {
        if(formInitialValues.value[question.name]){
            setValue(formInitialValues.value[question.name]);
        }
    }, [formInitialValues.value]);

    const checkValidation = () => {
        const questionValid = radioGrid.checkValidation(userFormQuestion.current);
        setQuestionValid(questionValid);
    };
    
    useEffect(() => {
        if((userFormQuestion.current && userFormQuestion.current.choice) || touchedField.current){
            touchedField.current = true;
            checkValidation();
        }
    }, [userFormQuestionUpdated]);

    const handleGroupFocus = () => {
        touchedField.current = true;
    };

    const handleGroupBlur = () => {
        checkValidation();
    };

    const handleChange = (questionName, updates) => {
        const nextQuestionState = {
            ...values.current[questionName],
            ...updates
        };
        userFormQuestion.current = nextQuestionState;
        values.current = {
            ...values.current,
            [questionName]: nextQuestionState
        }
        setUserFormQuestionUpdated(new Date());
    };
    
    return (
        <FormQuestionRadioGrid
            onChange={handleChange}
            onBlur={handleGroupBlur}
            onFocus={handleGroupFocus}
            question={question}
            initialValue={value}
        />
    );
};

export default RadioGridQuestion;