import React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '../LoadingButton/LoadingButton';

const Dialog2 = ({ backgroundColor, buttons = [], children, closeButtonLabel, closeButtonStartIcon, containerMaxWidth, forceFullScreen, maxWidth, onClose, onSaveClick, open, saveButtonDisabled, saveButtonEndIcon, saveButtonLabel, saving, title, toolbarButtons = [], TransitionComponent }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = (event, reason) => {
        if(reason === 'backdropClick' && saving){
            return;
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown={saving} fullWidth fullScreen={fullScreen || forceFullScreen}
            maxWidth={maxWidth || 'md'}
            PaperProps={{ sx: { backgroundColor: backgroundColor || '#f8f9fb' } }}
            TransitionComponent={TransitionComponent}
        >
            {
                title &&
                <AppBar style={{position: 'relative'}}>
                    <Toolbar>
                        <Typography variant="h2" noWrap sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>
                        {
                            toolbarButtons.map((toolbarButton, toolbarButtonIndex) => (
                                <React.Fragment key={toolbarButtonIndex}>{toolbarButton}</React.Fragment>
                            ))
                        }
                    </Toolbar>
                </AppBar>
            }
            <DialogContent sx={{overflowX: 'hidden', position: 'relative' }}>
                <Container maxWidth={containerMaxWidth !== false ? containerMaxWidth || "xl" : false} disableGutters>
                    {children}
                </Container>
            </DialogContent>
            <DialogActions>
                <Button variant="text" disabled={saving} onClick={onClose} startIcon={closeButtonStartIcon}>{closeButtonLabel || 'Cancelar'}</Button>
                {
                    buttons.map((button, buttonIndex) => (
                        <React.Fragment key={buttonIndex}>{button}</React.Fragment>
                    ))
                }
                {
                    onSaveClick &&
                    <LoadingButton
                        disabled={saving || saveButtonDisabled}
                        loading={saving}
                        variant="contained" color="primary"
                        onClick={onSaveClick}
                        endIcon={saveButtonEndIcon || <CheckIcon />}
                    >{saveButtonLabel || 'Salvar'}</LoadingButton>
                }
            </DialogActions>
        </Dialog>
    );
};

export default Dialog2;