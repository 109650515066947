import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';

import Grid from '@mui/material/Grid';

import Input from '../Input';
import Select from '../Select';
import Window from '../Window';

import { useAppStateCtx } from '../../context/AppState';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const NewReportWindow = ({
    newReportWindowOpen, availableClearanceReports, selectedProject, selectedProjectClearanceType
}) => {
    useSignals();
    const { activeUser } = useAppStateCtx();
    const [loading, setLoading] = useState(false);
    const [episodeNumber, setEpisodeNumber] = useState(1);
    const [reportType, setReportType] = useState('script');
    const [scriptTreatment, setScriptTreatment] = useState(1);
    const [cutVersion, setCutVersion] = useState(1);
    const [importPreviousReportId, setImportPreviousReportId] = useState('none');

    useEffect(() => {
        if(newReportWindowOpen.value){
            const lastReport = availableClearanceReports[availableClearanceReports.length - 1];
            if(lastReport){
                setReportType(lastReport.type);
                if(lastReport.type === 'script'){
                    setScriptTreatment(lastReport.scriptTreatment + 1);
                } else {
                    setCutVersion(lastReport.cutVersion + 1);
                }
            }
        }
    }, [newReportWindowOpen.value]);

    const getPreviousReportId = () => {
        if(reportType === 'script'){
            const previousReports = availableClearanceReports.filter(report => (selectedProjectClearanceType !== 'series' || report.episode === episodeNumber) && report.type === 'script' && report.scriptTreatment < scriptTreatment);
            if(previousReports.length >= 1){
                const lastPreviousReport = previousReports[previousReports.length - 1];
                return lastPreviousReport.uid;
            }
        } else {
            let previousReports = availableClearanceReports.filter(report => (selectedProjectClearanceType !== 'series' || report.episode === episodeNumber) && report.type === 'cut' && report.cutVersion < cutVersion);
            if(previousReports.length === 0) previousReports = availableClearanceReports.filter(report => (selectedProjectClearanceType !== 'series' || report.episode === episodeNumber) && report.type === 'script');
            if(previousReports.length >= 1){
                const lastPreviousReport = previousReports[previousReports.length - 1];
                return lastPreviousReport.uid;
            }
        }
        return 'none';
    }
    
    useEffect(() => {
        const previousReportId = getPreviousReportId();
        setImportPreviousReportId(previousReportId);
    }, [reportType, scriptTreatment, cutVersion]);

    const handleReportTypeChange = (newValue) => {
        setReportType(newValue);
        setScriptTreatment(1);
        setCutVersion(1);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const toastId = toast.loading('Criando relatório de clearance...');

        const newReportArgs = {
            activeUser: activeUser.value,
            importPreviousReportId,
            reportType,
            toastId
        }
        if(selectedProjectClearanceType === 'series') newReportArgs.episode = episodeNumber;
        if(reportType === 'script'){
            newReportArgs.scriptTreatment = scriptTreatment;
        } else {
            newReportArgs.cutVersion = cutVersion;
        }

        const res = await selectedProject.createProjectClearanceReport(newReportArgs);
        if(res.error){
            setLoading(false);
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 5000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });
        
        handleClose();
        setLoading(false);
    };

    const handleClose = () => {
        newReportWindowOpen.value = false;
    };

    return (
        <Window open={newReportWindowOpen.value} onClose={handleClose}
            title="Novo relatório"
            submitButtonText="Novo relatório"
            handleSubmitButtonClick={handleSubmit}
            submitButtonLoading={loading}
        >
            <Grid container spacing={1}>
                {
                    selectedProjectClearanceType === 'series' &&
                    <Grid item xs={9} sm={4} md={1}>
                        <Input
                            fullWidth
                            type="number"
                            inputProps={{min: 1, step: 1}}
                            label="Episódio"
                            value={episodeNumber} onChange={(e) => setEpisodeNumber(e.target.value)}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={6} md={2}>
                    <Select
                        label="Tipo"
                        options={[{value: 'script', label: 'Roteiro'}, {value: 'cut', label: 'Corte'}]}
                        value={reportType} onChange={(e) => handleReportTypeChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={9} sm={4} md={1}>
                    {
                        reportType === 'script'
                        ?
                        <Input
                            fullWidth
                            type="number"
                            inputProps={{min: 1, step: 1}}
                            label="Tratamento"
                            value={scriptTreatment} onChange={(e) => setScriptTreatment(e.target.value)}
                        />
                        :
                        <Input
                            fullWidth
                            type="number"
                            inputProps={{min: 1, step: 1}}
                            label="Versão"
                            value={cutVersion} onChange={(e) => setCutVersion(e.target.value)}
                        />
                    }
                </Grid>
                <Grid item xs>
                    <Select
                        label="Importar anotações"
                        options={[
                            {value: 'none', label: 'Não (relatório em branco)'},
                            ...availableClearanceReports.map(report => ({value: report.uid, label: `${selectedProjectClearanceType === 'series' ? `Ep. ${report.episode}, ` : ''}${report.type === 'script' ? `Tratamento ${report.scriptTreatment}` : `Corte ${report.cutVersion}`}`}))
                        ]}
                        value={importPreviousReportId} onChange={(e) => setImportPreviousReportId(e.target.value)}
                    />
                </Grid>
            </Grid>
            
        </Window>
    );
};

export default NewReportWindow;