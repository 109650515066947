import { useEffect, useState } from 'react';
import moment from 'moment';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useSignals } from '@preact/signals-react/runtime';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import Chat from './Chat';
import DashboardCounter from '../../../components/DashboardCounter';
import Notices from '../../../components/Notices';
import NumberOfPendingDocuments from './components/NumberOfPendingDocuments';
import SectionTitle from '../../../components/SectionTitle';
import ViewBox from '../../../components/ViewBox';
import { FIRST_CLIENT_ID } from '../../../utils/constants';
import { useAppStateCtx, useAppStateCtxUtils } from '../../../context/AppState';
import { useAppCtxAPI } from '../../../context/SystemContext';
import { useDocumentsAPI, useDocumentsCtxPendingDocumentsCount } from '../../../context/DocumentsContext';
import { useTasksCtxAPI, useTasksCtxActiveUserTasks } from '../../../context/TasksContext';
import TaskBoard from '../Tasks/TaskBoard';
import TasksList from '../Tasks/TasksList';
import NumberOfSentDocuments from './components/NumberOfSentDocuments/index';

const boxBackgroundColor = '#f5f6fa';

const NoPendingTasks = ({label, icon}) => (
    <Grid container spacing={1} alignItems="center">
        <Grid item>
            {icon || <CheckCircleOutlineIcon />}
        </Grid>
        <Grid item>
            <Typography variant="body2">{label}</Typography>
        </Grid>
    </Grid>
);

const Home = () => {
    useSignals();
    const { activeUser, selectedWorkspace } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { handleNavigate } = useAppCtxAPI();
    const { pendingDocumentsCountPerOperator } = useDocumentsCtxPendingDocumentsCount();
    const { handleTaskDragEnd, setSnapshot: setTasksCtxSnapshot } = useTasksCtxAPI();
    const activeUserTasks = useTasksCtxActiveUserTasks();
    const { setSnapshot: setDocumentsCtxSnapshot } = useDocumentsAPI();
    const [monthNumberOfDocumentsGenerated, set_monthNumberOfDocumentsGenerated] = useState(null);
    
    useEffect(() => {
        if(selectedWorkspace.value){
            if(selectedWorkspace.value.modules?.includes('timesheet')) setFirestoreListener('workspaceTimesheetRecords');
            if(selectedWorkspace.value.modules?.includes('tasks')) setTasksCtxSnapshot('shouldSetTasksSnapshot');
            if(selectedWorkspace.value.modules?.includes('documents')) setDocumentsCtxSnapshot('shouldSetWorkspacePendingDocumentsSnapshot');
        }
    }, [selectedWorkspace.value?.modules]);

    useEffect(() => {
        if(['operator/controller'].includes(selectedWorkspace.value?.role)) handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/financeiro/propostas`);
    }, [selectedWorkspace.value]);

    useEffect(() => {
        if(selectedWorkspace.value){
            let numberOfDocumentsGenerated = 0;
            const monthTotals = selectedWorkspace.value.monthTotals;
            if(monthTotals){
                const year = moment().year();
                if(monthTotals[year]){
                    const month = moment().month();
                    if(monthTotals[year][month]) numberOfDocumentsGenerated = monthTotals[year][month].numberOfDocumentsGenerated || 0;
                }
            }
            set_monthNumberOfDocumentsGenerated(numberOfDocumentsGenerated);
        }
    }, [selectedWorkspace.value]);

    return (
        <ViewBox>
            {
                <Box>
                    {
                        selectedWorkspace.value?.modules?.includes('documents') &&
                        <>
                            <Box style={{
                                borderRadius: 8,
                                padding: '8px',
                                backgroundColor: boxBackgroundColor,
                                marginBottom: '8px',
                                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
                            }}>
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                        <NumberOfPendingDocuments />
                                    </Grid>
                                    {
                                        selectedWorkspace.value?.uid === FIRST_CLIENT_ID &&
                                        <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                            <NumberOfSentDocuments workspaceId={selectedWorkspace.value?.uid} />
                                        </Grid>
                                    }
                                    {
                                        selectedWorkspace.value?.uid !== FIRST_CLIENT_ID &&
                                        <Grid item xs={12} sm={6} md={3} container justifyContent="center">
                                            <DashboardCounter
                                                icon={<SettingsOutlinedIcon />}
                                                label="documentos gerados no mês"
                                                loading={monthNumberOfDocumentsGenerated === null}
                                            >{monthNumberOfDocumentsGenerated}</DashboardCounter>
                                        </Grid>
                                    }
                                </Grid>
                            </Box>
                            <Collapse in={selectedWorkspace.value?.uid !== FIRST_CLIENT_ID && !!selectedWorkspace.value.documentsGeneratedMonthLimit && monthNumberOfDocumentsGenerated && (monthNumberOfDocumentsGenerated >= (selectedWorkspace.value.documentsGeneratedMonthLimit * 0.8))}>
                                <Box mb={1}>
                                    <Alert variant="filled" severity="error" onClose={() => set_monthNumberOfDocumentsGenerated(0)}>Você já consumiu {monthNumberOfDocumentsGenerated} do limite de {selectedWorkspace.value?.documentsGeneratedMonthLimit} contratos mensais.</Alert>
                                </Box>
                            </Collapse>
                        </>
                    }

                    <Grid container spacing={1} justifyContent="center">
                        {
                            (selectedWorkspace.value?.modules?.includes('tasks') && ['operator', 'general partner', 'operator/manager', 'operator/admin', 'operator/developer', 'developer'].includes(selectedWorkspace.value?.role)) &&
                            <Grid item xs={12} sm={4} md={3}>
                                <SectionTitle title="Tarefas" />
                                {
                                    !activeUserTasks &&
                                    <NoPendingTasks icon={<CircularProgress size={20} />} />
                                }
                                <Collapse in={!!activeUserTasks} mountOnEnter>
                                    <Box>
                                        <DragDropContext onDragEnd={handleTaskDragEnd}>
                                            <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                                                <Grid item xs container spacing={1} alignItems="flex-start" style={{height: '100%'}}>
                                                    <Grid item style={{height: '100%', width: `100%`}}>
                                                        <TaskBoard
                                                            user={{ img: activeUser.value.photoUrl, screenName: activeUser.value.name }}
                                                            userNumberOfPendingDocuments={(pendingDocumentsCountPerOperator ? pendingDocumentsCountPerOperator[activeUser.value.uid] || 0 : 0).toString()}
                                                        >
                                                            <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserUnreadTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserUnreadTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                            <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserHighPriorityTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserHighPriorityTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                            <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserMediumPriorityTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                            <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                                                {(provided, snapshot) => (
                                                                    <TasksList
                                                                        boardId={'activeUserLowPriorityTasks'}
                                                                        innerRef={provided.innerRef}
                                                                        isActiveUserTask
                                                                        isDraggingOver={snapshot.isDraggingOver}
                                                                        tasks={activeUserTasks.activeUserLowPriorityTasks}
                                                                        
                                                                        {...provided.droppableProps}
                                                                    >
                                                                        {provided.placeholder}
                                                                    </TasksList>
                                                                )}
                                                            </Droppable>
                                                        </TaskBoard>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </DragDropContext>
                                    </Box>
                                </Collapse>
                            </Grid>
                        }
                        <Grid item xs={12} sm={4} md={3}>
                            <SectionTitle title="Avisos" />
                            <Notices activeUserIsOperator />
                        </Grid>
                        <Grid item xs={12} sm={selectedWorkspace.value?.modules?.includes('tasks') ? 4 : 8} md={selectedWorkspace.value?.modules?.includes('tasks') ? 5 : 9} container spacing={1}>
                            <Grid item xs={12}>
                                <SectionTitle title="Chat" />
                                <Chat height={300} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }
        </ViewBox>
    );
}

export default Home;