import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import queryString from 'query-string'; 
import { toast } from 'react-toastify';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
// import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import AddClearanceQuestionWindow from './AddClearanceQuestionWindow';
import AlertDialog from '../AlertDialog';
import ChangeOperatorMenu from '../ChangeOperatorMenu';
import ChangePageButton from '../ChangePageButton';
import ClearanceQuestionsList from './ClearanceQuestionsList';
import FiltersMenu from '../ClearanceQuestionsList/components/FiltersMenu';
import ImageWindow from '../ImageWindow';
import Input from '../Input';
import LoaderEllipsis from '../LoaderEllipsis';
import MenuChip from '../MenuChip';
import ReplyQuestionWindow from './ReplyQuestionWindow';
import ViewBox from '../ViewBox';

import { useAppStateCtx, useAppStateCtxUtils } from '../../context/AppState';
import { useAppContext, useAppCtxAPI } from '../../context/SystemContext';
import setDoc from '../../firebase/firestore/setDoc';
import useGetProjectClearanceSettings from '../../hooks/useGetProjectClearanceSettings';
import useGetProjectGroupsSettings from '../../hooks/useGetProjectGroupsSettings';
import useGetWorkspaceClearanceQuestions from '../../hooks/useGetWorkspaceClearanceQuestions';
import useGetWorkspaceClearanceReportNotes from '../../hooks/useGetWorkspaceClearanceReportNotes';
import useGetWorkspaceProjectsWithClearanceOptions from '../../hooks/useGetWorkspaceProjectsWithClearanceOptions';
import { getClearanceTemplate } from '../../utils/common';
import { CLEARANCE_RISK_OPTIONS, ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../../utils/constants';

const classes = {
    fab: {
        position: 'fixed',
        bottom: '8px',
        right: '16px'
    },
    extendedIcon: {
        marginRight: '8px',
    },
    searchValueBox: {
        maxWidth: '200px',
        overflowX: 'auto',
        textAlign: ['center', 'left'],
    },
    smallAvatar: {
        width: '24px',
        height: '24px',
    }
};

const defaultFilters = {
    createdBy: 'all',
    folder: 'all',
    folderGroup: 'all',
    rightType: 'all',
    riskLevel: 'all',
    status: 'all',
    search: ''
}

const addQuestionViewOpen = signal(false);
const clearanceQuestionsListFiltersMenuSettings = signal({
    element: null,
    optionId: '',
    open: false
});
const currentPage = signal(1);
const filters = signal(defaultFilters);
const filtersOptions = signal({
    createdBy: [{value: 'all', label: 'Todas'}],
    folder: [{value: 'all', label: 'Todos'}],
    folderGroup: [{value: 'all', label: 'Todos'}],
    rightType: [{value: 'all', label: 'Todos'}],
    riskLevel: [
        {value: 'all', label: 'Todos'},
        ...CLEARANCE_RISK_OPTIONS.map(option => ({...option, description: option.label, label: <span><span style={{color: option.color}}>&#11044;</span>&nbsp;{option.label}</span>}))
    ],
    status: [
        {value: 'pending', label: 'Em aberto'},
        {value: 'done', label: 'Respondidas'},
        {value: 'all', label: 'Todas'}
    ]
});
const imageViewUrl = signal('');
const loading = signal(false);
const replyQuestionWindowOpen = signal(false);
const searchFieldValue = signal('');
const selectedQuestion = signal(null);
const selectedQuestionGroupsSettings = signal(null);

const SearchField = () => {
    useSignals();

    const filterSearch = (newValue) => {
        filters.value = {
            ...filters.value,
            search: newValue
        }
        searchFieldValue.value = '';
    };

    const filterOnKeyDown = (e) => {
        const pressedKey = e.key;
        if(pressedKey === 'Enter'){
            filterSearch(e.target.value);
        }
    };

    const clearSearch = () => {
        filters.value = {
            ...filters.value,
            search: ''
        }
    }

    return (
        <>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
                <Input
                    disabled={loading.value}
                    fullWidth
                    placeholder="Busca"
                    value={searchFieldValue.value} onChange={(e) => searchFieldValue.value = e.target.value}
                    endIcon={<SearchIcon />}
                    endIconTitle="Busca"
                    endIconOnClick={() => filterSearch(searchFieldValue.value)}
                    onKeyDown={filterOnKeyDown}
                />
            </Grid>
            <Grid className={classes.searchValueBox}>
                <Collapse in={filters.value.search}>
                    <Chip
                        label={filters.value.search}
                        color="primary"
                        onDelete={clearSearch}
                    />
                </Collapse>
            </Grid>
        </>
    );
};

const ClearanceQuestionsListPage = ({
    activeUserIsOperator,
    initialProjectClearanceSettings,
    initialProjectGroupSettings,
    projects,
    restrictedToProject,
    setPendingClearanceCount
}) => {
    useSignals();
    const { activeUser, folderUsers, workspaceClearanceTemplates, workspaceUsers } = useAppStateCtx();
    const {
        // activeUserIsOperator,
        setFirestoreListener
    } = useAppStateCtxUtils();
    const { mainRef } = useAppContext();
    const { showOperatorsMenu } = useAppCtxAPI();
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [clearanceQuestions, setClearanceQuestions] = useState(null);
    const [selectedQuestionFolderId, setSelectedQuestionFolderId] = useState('');
    const [projectClearanceSettings, setProjectClearanceSettings] = useState(initialProjectClearanceSettings || null);
    const [selectedProjectClearanceTemplateId, setSelectedProjectClearanceTemplateId] = useState('');
    const [selectedProjectClearanceTemplate, setSelectedProjectClearanceTemplate] = useState(null);
    const [selectedQuestionTemplate, setSelectedQuestionTemplate] = useState(null);
    const [filterActive, setFilterActive] = useState(false);
    const usedSearchId = useRef(false);
    const [isSetQuestionDoneDialogOpen, setSetQuestionDoneDialogOpen] = useState(false);
    // const [downloadingPdf, setDownloadingPdf] = useState(false);
    const [count, setCount] = useState(0);
    const [savingDone, setSavingDone] = useState({});
    const [selectedQuestionClearanceSettings, setSelectedQuestionClearanceSettings] = useState({
        // activeUserIsOperator only
        // state change only when question for a with a different projectId opened to avoid many repetitive requests
        projectId: '',
        data: null
    });
    const fetchAction = useRef('');
    const shouldScrollToBottomAfterUpdate = useRef(false);

    // IF OPERATOR
    useEffect(() => {
        if(activeUserIsOperator){
            setFirestoreListener('workspaceClearanceTemplates');
            setFirestoreListener('workspaceProjectsWithClearanceIds');
        }
    }, []);

    // IF CLIENT
    useEffect(() => {
        if(restrictedToProject){
            setFirestoreListener('folderUsers', `projects/${restrictedToProject.uid}`);
        }
    }, []);
    useEffect(() => {
        if(restrictedToProject && initialProjectGroupSettings){
            selectedQuestionGroupsSettings.value = {
                uid: 'projectId',
                projectId: restrictedToProject.uid,
                data: initialProjectGroupSettings
            }
        }
    }, []);

    const workspaceClearanceQuestions = useGetWorkspaceClearanceQuestions(true, restrictedToProject, activeUserIsOperator, filters.value, currentPage.value, fetchAction.current);
    const workspaceClearanceReportNotes = useGetWorkspaceClearanceReportNotes(activeUserIsOperator && filters.value.status === 'pending', filters.value);
    useEffect(() => {
        if(workspaceClearanceQuestions.loading || (activeUserIsOperator && workspaceClearanceReportNotes.loading)){
            loading.value = true;
        } else if(workspaceClearanceQuestions.data && (workspaceClearanceReportNotes.data || !activeUserIsOperator)){
            let currentClearanceQuestions = workspaceClearanceQuestions.data;
            if(activeUserIsOperator){
                currentClearanceQuestions = [...currentClearanceQuestions, ...workspaceClearanceReportNotes.data].sort((a, b) => {
                    let aData = a.createdAt;
                    if(a.isClearanceReportNote && a.lastComment?.createdAt) aData = a.lastComment.createdAt;
                    let bData = b.createdAt;
                    if(b.isClearanceReportNote && b.lastComment?.createdAt) bData = b.lastComment.createdAt;
                    return aData > bData ? 1 : aData < bData ? -1 : 0;
                });
            }
            setClearanceQuestions(currentClearanceQuestions);
            setCount(workspaceClearanceQuestions.count);
            if(filters.value.status === 'pending' && setPendingClearanceCount) setPendingClearanceCount(currentClearanceQuestions.length);
        }
    }, [workspaceClearanceQuestions, workspaceClearanceReportNotes]);

    useEffect(() => {
        if(clearanceQuestions) loading.value = false;
    }, [clearanceQuestions])

    // IF OPERATOR (CLIENT PASSES initialProjectClearanceSettings VAR)
    const retrievedProjectClearanceSettings = useGetProjectClearanceSettings(activeUserIsOperator, selectedQuestionClearanceSettings.projectId);
    useEffect(() => {
        if(retrievedProjectClearanceSettings.data){
            setSelectedQuestionClearanceSettings(prevState => ({...prevState, data: retrievedProjectClearanceSettings.data}));
        }
    }, [retrievedProjectClearanceSettings]);

    // IF OPERATOR (CLIENT PASSES initialProjectGroupSettings VAR)
    const retrievedProjectDocumentsSettings = useGetProjectGroupsSettings(activeUserIsOperator, selectedQuestionClearanceSettings.projectId);
    useEffect(() => {
        if(retrievedProjectDocumentsSettings.data){
            selectedQuestionGroupsSettings.value = {
                uid: selectedQuestion.value?.uid,
                data: retrievedProjectDocumentsSettings.data
            }
        }
    }, [retrievedProjectDocumentsSettings]);

    const projectOptions = useGetWorkspaceProjectsWithClearanceOptions(activeUserIsOperator, projects);

    useEffect(() => {
        if(activeUserIsOperator && filters.value.folder && filters.value.folder !== 'all') setSelectedQuestionClearanceSettings(prevState => ({...prevState, projectId: filters.value.folder}));
    }, [filters.value.folder]);
    useEffect(() => {
        let currentSelectedQuestionTemplate = null;
        const questionTemplate = getQuestionTemplate(selectedQuestionClearanceSettings.projectId);
        if(questionTemplate) currentSelectedQuestionTemplate = questionTemplate;
        setSelectedQuestionTemplate(currentSelectedQuestionTemplate);
    }, [selectedProjectClearanceTemplate, filters.value.folder, selectedQuestionClearanceSettings, workspaceClearanceTemplates.value]);

    useEffect(() => {
        if(!activeUserIsOperator){ // if client, set initialSelectedFolder
            setSelectedFolder(restrictedToProject);
        }
    }, [restrictedToProject]);

    useEffect(() => {
        if(activeUserIsOperator){
            filters.value = {
                ...filters.value,
                status: 'pending'
            }
        }
    }, []);

    useEffect(() => {
        let currentFilterActive = false;
        for(const key in filters.value){
            if(!['', 'all'].includes(filters.value[key])) currentFilterActive = true;
        }
        setFilterActive(currentFilterActive);
    }, [filters.value]);

    useEffect(() => { //TODO Checar se esse código tem utilidade
        if(activeUserIsOperator){
            if(selectedQuestion.value){
                setSelectedQuestionFolderId(selectedQuestion.value.folderId);
            }
        } else {
            setSelectedQuestionFolderId(restrictedToProject.uid);
        }
    }, [selectedQuestion.value]);

    // SAVE TEMPLATE ID AFTER CLEARANCE SETTINGS RETRIEVED
    useEffect(() => {
        if(projectClearanceSettings){
            setSelectedProjectClearanceTemplateId(projectClearanceSettings.templateId);
        }
    }, [projectClearanceSettings]);

    useEffect(() => {
        if(selectedProjectClearanceTemplateId){
            let foundClearanceTemplate = null;
            if(activeUserIsOperator && workspaceClearanceTemplates.value){
                foundClearanceTemplate = workspaceClearanceTemplates.value.find(template => template.uid === selectedProjectClearanceTemplateId);
            }
            if(!foundClearanceTemplate) getClearanceTemplate({
                clearanceTemplateId: selectedProjectClearanceTemplateId,
                setClearanceTemplate: setSelectedProjectClearanceTemplate
            })
        }
    }, [selectedProjectClearanceTemplateId]);

    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({ activeUserIsOperator, numberOfApprovalsRequired: projectClearanceSettings?.approvals?.numberOfApprovalsRequired });
    }, [projectClearanceSettings]);
    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({ activeUserIsOperator, selectedProjectGroups: selectedQuestionGroupsSettings.value?.data });
    }, [selectedQuestionGroupsSettings.value]);
    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({selectedFolderUsers: folderUsers.value, workspaceUsers});
    }, [folderUsers.value]);
    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({selectedTemplate: selectedQuestionTemplate});
    }, [selectedQuestionTemplate]);
    useEffect(() => {
        getClearanceQuestionsListFiltersMenuOptions({projectOptions: projectOptions || []});
    }, [projectOptions]);

    useEffect(() => {
        if(!usedSearchId.current && clearanceQuestions?.length !== 0){
            const search = queryString.parse(window.location.search);
            const searchId = search.id;
            if(searchId){
                const foundQuestion = clearanceQuestions.find(clearanceQuestion => clearanceQuestion.uid === searchId);
                usedSearchId.current = true;
                if(foundQuestion){
                    handleReplyQuestionClick(foundQuestion);
                }
            }
        }
        
    }, [clearanceQuestions]);

    useLayoutEffect(() => {
        if(shouldScrollToBottomAfterUpdate.current){
            shouldScrollToBottomAfterUpdate.current = false;
            mainRef.current.scrollTo(0, mainRef.current.scrollHeight);
        }
    }, [clearanceQuestions])

    const handleReplyQuestionClick = useCallback((question) => {
        selectedQuestion.value = question;
        replyQuestionWindowOpen.value = true;
    }, []);

    const handleOperatorMenuButtonClick = useCallback((e, question) => {
        e.preventDefault();
        e.stopPropagation();
        showOperatorsMenu(e.target, question);
    }, []);

    const handleAddQuestion = () => {
        addQuestionViewOpen.value = true;
    };

    const handleClearanceQuestionBodyRow = useCallback((clearanceQuestion) => {
        if(clearanceQuestion.data){ 
            handleReplyQuestionClick(clearanceQuestion.data);
        }
    }, []);

    const handleQuestionDoneButtonPress = (e, question) => {
        e.stopPropagation();
        selectedQuestion.value = question;
        setSetQuestionDoneDialogOpen(true);
    };

    const markAsDone = async () => {
        setSetQuestionDoneDialogOpen(false);

        setSavingDone(prevState => ({...prevState, [selectedQuestion.value.uid]: true}));
        
        const toastId = toast.loading(`Marcando a consulta "${selectedQuestion.value.subject}" como concluída...`);

        let documentPath = '';
        const updates = {
            awaitingOperatorReview: false
        };
        if(selectedQuestion.value.isClearanceReportNote){
            documentPath = `clearance_notes/${selectedQuestion.value.reportItemId}`;
        } else {
            documentPath = `clearance_questions/${selectedQuestion.value.uid}`;
            updates.firstReplySentByOperator = true;
            updates.operatorId = activeUser.value.uid;
            if(activeUser.value.photoUrl) updates.operatorImg = activeUser.value.photoUrl;
            updates.operatorName = activeUser.value.name;
        }

        const res = await setDoc(documentPath, updates);
        if(res.error){
            return toast.update(toastId, { autoClose: 5000, isLoading: false, render: ERROR_MESSAGE_UNKNOWN, type: 'error' });
        }
        toast.update(toastId, { autoClose: 3000, isLoading: false, render: SUCCESS_MESSAGE_SAVED, type: 'success' });

        setSavingDone(prevState => ({...prevState, [selectedQuestion.value.uid]: false}));

    };

    // const handlePdfButtonPress = async () => {
        // setDownloadingPdf(true);
        // toast('Gerando o relatório... isso levará alguns segundos...', { type: 'info' });
        // const selectedFolderId = selectedFolder ? (filters.folder !== 'all' ? filters.folder : selectedFolder.uid) : filters.folder;
        // const query = getQuery('get');
        // let filename = '';

        //TODO /data/clearance/${selectedFolderId}/pdf${query ? `?${query}` : ''}
        // responseType: 'blob'

        // .then(response => {
        //     const disposition = response.headers['content-disposition'];
        //     filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        //     if (filename.toLowerCase().startsWith("utf-8''"))
        //        filename = decodeURIComponent(filename.replace("utf-8''", ''));
        //     else
        //        filename = filename.replace(/['"]/g, '');
        //     return response.data;
        //  })
        //  .then(blob => {
        //     var url = window.URL.createObjectURL(blob);
        //     var a = document.createElement('a');
        //     a.href = url;
        //     a.download = filename;
        //     document.body.appendChild(a); // append the element to the dom
        //     a.click();
        //     a.remove(); // afterwards, remove the element
        //     setNotification({ message: '' });
        // })
        // .catch(err => {
        //     setNotification({ severity: 'error', message: err.response.data });
        // });
        // setDownloadingPdf(false);
    // };

    const handleCommentInputKeyPress = useCallback((e) => {
        // if(e.key === '@'){
        //     setFolderUsersMenuAnchorEl(e.target);
        //     setFolderUsersMenuOpen(true);
        // }
    }, []);

    const handleFilterClick = (e, optionId) => {
        clearanceQuestionsListFiltersMenuSettings.value = {
            element: e.target, optionId, open: true
        };
    };

    const getSelectedFilterLabelById = (filterOptionId, filterValue) => {
        if(filterOptionId && filterValue){
            if(filtersOptions.value[filterOptionId]){
                const selectedFilterOption = filtersOptions.value[filterOptionId].find(option => option.value === filterValue);
                if(selectedFilterOption){
                    return selectedFilterOption.description || selectedFilterOption.label;
                }
            }
            return '';
        }
    }

    const getQuestionTemplate = (questionProjectId) => {
        let currentSelectedQuestionTemplate = selectedProjectClearanceTemplate;
        if(!currentSelectedQuestionTemplate && activeUserIsOperator && workspaceClearanceTemplates.value && questionProjectId === selectedQuestionClearanceSettings.projectId && selectedQuestionClearanceSettings.data){
            const foundTemplate = workspaceClearanceTemplates.value.find(template => template.uid === selectedQuestionClearanceSettings.data.templateId);
            if(foundTemplate) currentSelectedQuestionTemplate = foundTemplate;
        }
        return currentSelectedQuestionTemplate;
    }

    const handlePrevClick = () => {
        shouldScrollToBottomAfterUpdate.current = true;
        fetchAction.current = 'prevPage';
        currentPage.value = currentPage.value - 1;
    };

    const handleNextClick = () => {
        fetchAction.current = 'nextPage';
        currentPage.value = currentPage.value + 1;
        mainRef.current.scrollTo(0, 0);
    };

    const resetClearanceQuestionsListFilters = () => {
        filters.value = {
            ...defaultFilters,
            status: activeUserIsOperator ? 'pending' : 'all'
        }
    };

    const getClearanceQuestionsListFiltersMenuOptions = ({
        activeUserIsOperator,
        numberOfApprovalsRequired,
        projectOptions,
        selectedFolderUsers,
        selectedProjectGroups,
        selectedTemplate,
        workspaceUsers
    }) => {
        if(!activeUserIsOperator && numberOfApprovalsRequired){
            let approvalsRequired = false;
            for(const key in numberOfApprovalsRequired){ 
                if(numberOfApprovalsRequired[key] > 0) approvalsRequired = true;
            }
            if(approvalsRequired){
                filtersOptions.value = {
                    ...filtersOptions.value,
                    status: [
                        {value: 'pending', label: 'Em aberto'},
                        {value: 'done', label: 'Respondidas'},
                        {value: 'approved', description: 'Riscos aprovados', label: <Grid container spacing={1} alignItems="center"><Grid item xs><Typography variant="body1">Riscos aprovados</Typography></Grid><Grid item><ThumbUpIcon /></Grid></Grid>},
                        {value: 'rejected', description: 'Riscos reprovados', label: <Grid container spacing={1} alignItems="center"><Grid item xs><Typography variant="body1">Riscos reprovados</Typography></Grid><Grid item><ThumbDownIcon /></Grid></Grid>},
                        {value: 'all', label: 'Todas'}
                    ]
                }
            }
        }

        if(selectedProjectGroups){
            if(selectedProjectGroups.enabled){
                let groupOptions = [];
                if(activeUser.value){
                    const folderGroups = Object.entries(selectedProjectGroups.groups).map(([groupId, group]) => ({...group, id: groupId}));
                    const noGroupIds = ['&all', '&none', '~any;'];
                    // const group_any = { value:'~any;', label:'Sem grupo (disponível para todos)' };
                    if(activeUserIsOperator){
                        groupOptions = folderGroups.map(group => ({value: group.id, label: group.name}));
                    } else {
                        const activeUserInAdminGroup = folderGroups.find(folderGroup => folderGroup.id === '2' && folderGroup.users.includes(activeUser.value.uid));
                        if(activeUserInAdminGroup){
                            folderGroups.forEach(group => {
                                if(!noGroupIds.includes(group.id)) groupOptions.push({value: group.id, label: group.name});
                            });
                        } else {
                            const activeUserInManagementGroup = folderGroups.find(folderGroup => folderGroup.id === '1' && folderGroup.users.includes(activeUser.value.uid));
                            if(activeUserInManagementGroup){
                                folderGroups.forEach(group => {
                                    if(!noGroupIds.includes(group.id) && group.id !== '2') groupOptions.push({value: group.id, label: group.name});
                                });
                            } else {
                                folderGroups.forEach(group => {
                                    if(!noGroupIds.includes(group.id) && group.users.includes(activeUser.value.uid)) groupOptions.push({value: group.id, label: group.name});
                                });
                            }
                        }
                        // groups.unshift(group_any);
                    }
                }
                if(groupOptions.length !== 0){
                    filtersOptions.value = {
                        ...filtersOptions.value,
                        folderGroup: [
                            {value: 'all', label: 'Todos'},
                            ...groupOptions
                        ]
                    }
                }
            }
        }

        if(selectedFolderUsers && workspaceUsers.value){
            let mappedFolderUsers = [];
            Object.keys(selectedFolderUsers).forEach(userId => {
                const foundUser = workspaceUsers.value[userId];
                if(foundUser) mappedFolderUsers.push({ value: userId, label: foundUser.name });
            })

            filtersOptions.value = {
                ...filtersOptions.value,
                createdBy: [
                    {value: 'all', label: 'Todas'},
                    ...mappedFolderUsers.sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))
                ]
            }
        }

        if(selectedTemplate){
            filtersOptions.value = {
                ...filtersOptions.value,
                rightType: [{value: 'all', label: 'Todos'}, ...Object.entries(selectedTemplate.rightTypes).map(([rightTypeId, rightType]) => ({value: rightTypeId, label: rightType.name})).sort((a, b) => a.label > b.label ? 1 : (a.label < b.label ? -1 : 0))]
            }
        }

        if(projectOptions){
            filtersOptions.value = {
                ...filtersOptions.value,
                folder: [
                    {value: 'all', label: 'Todas'},
                    ...projectOptions.map(option => ({value: option.uid, label: option.name}))
                ]
            }
        }
    };

    return (
        <ViewBox additionalSx={[{ height: '100%' }]}>

            <Box mb={2}>
                <Grid container alignItems="flex-end" spacing={1}>
                    <SearchField />
                    <Grid size="grow" container spacing={1} alignItems="center">
                        <Grid>
                            <MenuChip
                                label="Situação"
                                onPress={(e) => handleFilterClick(e, 'status')}
                                value={filters.value.status} valueLabel={getSelectedFilterLabelById('status', filters.value.status)}
                            />
                        </Grid>
                        {
                            (!restrictedToProject && projectOptions) &&
                            <Grid>
                                <MenuChip
                                    label="Pasta"
                                    onPress={(e) => handleFilterClick(e, 'folder')}
                                    value={filters.value.folder} valueLabel={getSelectedFilterLabelById('folder', filters.value.folder)}
                                />
                            </Grid>
                        }
                        {
                            selectedQuestionClearanceSettings.groups?.enabled &&
                            <Grid>
                                <MenuChip
                                    label="Grupo"
                                    onPress={(e) => handleFilterClick(e, 'folderGroup')}
                                    value={filters.value.folderGroup} valueLabel={getSelectedFilterLabelById('folderGroup', filters.value.folderGroup)}
                                />
                            </Grid>

                        }
                        {
                            folderUsers.value &&
                            <Grid>
                                <MenuChip
                                    label="Pessoa"
                                    onPress={(e) => handleFilterClick(e, 'createdBy')}
                                    value={filters.value.createdBy} valueLabel={getSelectedFilterLabelById('createdBy', filters.value.createdBy)}
                                />
                            </Grid>
                        }
                        {
                            (((filters.value.folder && filters.value.folder !== 'all') || !activeUserIsOperator) && selectedQuestionTemplate) &&
                            <Grid>
                                <MenuChip
                                    label="Direito"
                                    onPress={(e) => handleFilterClick(e, 'rightType')}
                                    value={filters.value.rightType} valueLabel={getSelectedFilterLabelById('rightType', filters.value.rightType)}
                                />
                            </Grid>
                        }
                        <Grid>
                            <MenuChip
                                label="Risco"
                                onPress={(e) => handleFilterClick(e, 'riskLevel')}
                                value={filters.value.riskLevel} valueLabel={getSelectedFilterLabelById('riskLevel', filters.value.riskLevel)}
                            />
                        </Grid>
                        {
                            filterActive &&
                            <Grid>
                                <IconButton size="small" onClick={resetClearanceQuestionsListFilters}><ClearIcon /></IconButton>
                            </Grid>
                        }
                    </Grid>
                    {
                        // (clearanceQuestions?.length !== 0 && (selectedFolder || filters.value.folder !== 'all')) &&
                        // <Grid>
                        //     <Button variant="outlined" size="small"
                        //         disabled={downloadingPdf}
                        //         startIcon={downloadingPdf ? <CircularProgress size={20} /> : null}
                        //         onClick={handlePdfButtonPress}
                        //     >XLSX</Button>
                        // </Grid>
                    }

                </Grid>
            </Box>

            {
                loading.value
                ? <LoaderEllipsis />
                :
                <Box pb={5}>
                    <ChangePageButton numberOfRows={count} onNextClick={handleNextClick} onPrevClick={handlePrevClick} page={currentPage.value} rowsPerPage={filters.value.status === 'pending' ? count : 25} />
                    <Box my={2}>
                        <ClearanceQuestionsList
                            activeUserIsOperator={activeUserIsOperator}
                            handleBodyRow={handleClearanceQuestionBodyRow}
                            handleOperatorMenuButtonPress={handleOperatorMenuButtonClick}
                            handleQuestionDoneButtonPress={handleQuestionDoneButtonPress}
                            list={clearanceQuestions}
                            projectClearanceSettings={projectClearanceSettings}
                            projects={projects}
                            savingDone={savingDone}
                            selectedFolder={selectedFolder}
                        />
                    </Box>
                    <ChangePageButton numberOfRows={count} onNextClick={handleNextClick} onPrevClick={handlePrevClick} page={currentPage.value} rowsPerPage={filters.value.status === 'pending' ? count : 25} />
                </Box>
            }

            <AddClearanceQuestionWindow
                activeUserIsOperator={activeUserIsOperator}
                open={addQuestionViewOpen}
                getQuestionTemplate={getQuestionTemplate}
                handleCommentInputKeyPress={handleCommentInputKeyPress}
                projects={projects}
                initialSelectedProject={selectedFolder}
                selectedQuestionClearanceSettings={selectedQuestionClearanceSettings} setSelectedQuestionClearanceSettings={setSelectedQuestionClearanceSettings}
                selectedTemplate={selectedProjectClearanceTemplate}
            />

            <ReplyQuestionWindow
                activeUserIsOperator={activeUserIsOperator}
                getQuestionTemplate={getQuestionTemplate}
                handleCommentInputKeyPress={handleCommentInputKeyPress}
                imageViewUrl={imageViewUrl}
                projectClearanceSettings={projectClearanceSettings}
                replyQuestionWindowOpen={replyQuestionWindowOpen}
                selectedQuestion={selectedQuestion}
                selectedQuestionClearanceSettings={selectedQuestionClearanceSettings} setSelectedQuestionClearanceSettings={setSelectedQuestionClearanceSettings}
                selectedTemplate={selectedProjectClearanceTemplate}
            />

            {
                activeUserIsOperator &&
                <ChangeOperatorMenu collection="clearance_questions" operatorIdKey="operatorId" />
            }

            {
                activeUser.value &&
                <Fab
                    variant="extended" color="primary"
                    sx={classes.fab}
                    onClick={handleAddQuestion}
                >
                    <AddIcon className={classes.extendedIcon} />
                    Consulta
                </Fab>
            }

            <FiltersMenu
                filters={filters}
                options={filtersOptions}
                settings={clearanceQuestionsListFiltersMenuSettings}
            />

            <AlertDialog
                open={isSetQuestionDoneDialogOpen} onClose={() => setSetQuestionDoneDialogOpen(false)}
                title="Tem certeza?" text={`Você quer marcar ${selectedQuestion.value?.isClearanceReportNote ? 'o comentário' : 'a consulta'} "${selectedQuestion.value?.subject || ''}" como respondid${selectedQuestion.value?.isClearanceReportNote ? 'o' : 'a'} sem enviar uma resposta?`}
                okButtonOnClick={markAsDone}
            />

            <ImageWindow imageViewUrl={imageViewUrl} />
        </ViewBox>
    );
};

export default ClearanceQuestionsListPage;