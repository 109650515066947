import { useState } from 'react';
import { toast } from 'react-toastify';
import { useSignals } from '@preact/signals-react/runtime';

import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import UpdateIcon from '@mui/icons-material/Update';
import Tooltip from './Tooltip';
// import { useAppStateCtx } from '../context/AppState';
import { ERROR_MESSAGE_UNKNOWN } from '../utils/constants';

const UpdateSignatureStatusButton = ({document, version}) => {
    useSignals();
    const [loading, setLoading] = useState(false);

    const handleUpdateSignatureStatusClick = async () => {
        let { eSignature } = version;
        if(eSignature){
            if(eSignature.platformDocumentId){
                setLoading(true);
                const res = await document.updateDocumentESignatureStatus({
                    documentId: document.uid,
                    documentVersionId: version.uid,
                    eSignaturePlatform: eSignature.platform || 'zapSign',
                    eSignaturePlatformDocumentId: eSignature?.platformDocumentId
                });
                setLoading(false);
                if(res.error){
                    return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
                }
            }
        }
    };

    if(loading){
        return (
            <CircularProgress size={20} />
        );
    }

    return (
        <Tooltip text="Atualizar situação das assinaturas">
            <span>
                <IconButton
                    disabled={loading}
                    size="small"
                    onClick={handleUpdateSignatureStatusClick}
                >
                    <UpdateIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default UpdateSignatureStatusButton;