import { useEffect, useRef, useState } from 'react';
import { collection, onSnapshot, query, orderBy, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import ChatMessage from '../classes/ChatMessage';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';

const useGetWorkspaceChatMessages = (shouldSetListener) => {
    useSignals();
    const {
        // numberOfUnreadChatMessages,
        selectedWorkspace
    } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: null, count: 0, };
    const [hookState, setHookState] = useState(defaultHookState);
    const shouldUpdateNumberOfUnreadChatMessages = useRef(false);

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value){
            setHookState(prevState => ({...prevState, loading: true}));
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'chat_messages'),
                    where('workspaceId', '==', selectedWorkspace.value.uid),
                    orderBy('createdAt', 'asc')
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const chatMessage = new ChatMessage({
                            ...data,
                            uid: snapshot.id,
                        });
                        dataArray.push(chatMessage);
                    });
                    setHookState({
                        loading: false,
                        data: dataArray,
                        count: dataArray.length
                    });
                    if(shouldUpdateNumberOfUnreadChatMessages.current){
                        //TODO SHOULD NOT OPEN IN HOME PAGE
                        // if(dataArray.length !== 0 && dataArray.length > numberOfUnreadChatMessages.value){
                        //     numberOfUnreadChatMessages.value = dataArray.length;
                        // }
                    }
                    shouldUpdateNumberOfUnreadChatMessages.current = true;
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value]);

    return hookState;
};

export default useGetWorkspaceChatMessages;