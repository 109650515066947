import { useEffect, useState } from 'react';
import queryString from 'query-string'; 
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import { Box, Button, Collapse, Container, Fab, Fade, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { unstable_styleFunctionSx } from '@mui/system';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DescriptionIcon from '@mui/icons-material/Description';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';

import AlertDialog from '../../../../components/AlertDialog';
import LoaderEllipsis from '../../../../components/LoaderEllipsis';
import ReadDocumentTalentForm from '../../../../components/talents/ReadDocumentTalentForm';
import ReadDocumentForm from '../../../../components/Documents/ReadDocumentForm';
import SideBarSection from '../../../../components/SideBarSection';
import SubmitDocDialog from '../../../../components/SubmitDocDialog';
import Tooltip from '../../../../components/Tooltip';
import { useAppStateCtx } from '../../../../context/AppState';
import { useDocumentsAPI, useDocumentsCtxSubmitDocumentView } from '../../../../context/DocumentsContext';
import callFunction from '../../../../firebase/functions/callFunction';
import useGetDocument from '../../../../hooks/useGetDocument';
import useGetDocumentVersions from '../../../../hooks/useGetDocumentVersions';
import { getDocumentGoogleDocExportLink } from '../../../../utils/common';
import { ERROR_MESSAGE_UNKNOWN } from '../../../../utils/constants';

import LastComment from './components/LastComment';

const headerHeight = 66;
const elevatedBoxHeight = headerHeight - 12;
const classes = {
    root: {
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        height: `${headerHeight}px`,
        backgroundColor: theme => theme.palette.background.paper
    },
    elevatedBox: {
        height: `${elevatedBoxHeight}px`,
        backgroundColor: '#f5f6fa',
        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
        overflow: 'hidden',
    },
    elevatedBoxHeight: {
        height: `${elevatedBoxHeight}px`,
    },
    content: {
        backgroundColor: theme => theme.palette.background.paper,
        height: `calc(100% - ${headerHeight}px)`,
        // overflow: 'hidden',
        flex: 1,
        display: 'flex'
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none'
    },
    alert: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        msTransform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    whiteFab: {
        backgroundColor: theme => theme.palette.background.paper
    },
    mainContent: {
        height: '100%',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        display: {
            md: '70%',
            lg: '80%'
        },
        transition: 'width .4s'
    },
    mainContentFullWidth: {
        width: '100%'
    },
    sideBar: {
        width: {
            xs: '30%',
            lg: '20%'
        },
        display: {
            xs: 'none',
            md: 'block'
        },
        overflowX: 'hidden',
        overflowY: 'auto',
        transition: 'width .4s'
    },
    fileBox: {
        padding: '4px',
        border: theme => `1px solid ${theme.palette.background.paper}`,
        borderRadius: '4px'
    },
    selectedFileBox: {
        borderColor: theme => theme.palette.primary.main,
    }
};

const Div = styled('div')(unstable_styleFunctionSx);

const Iframe = styled('iframe')(unstable_styleFunctionSx);

const numberOfFiles = signal(null);
const numberOfVersions = signal(null);
const versionDownloadUrl = signal('');

const StyledFab = ({documentUrl, icon, loading, onClick, href, reviewFinished, setHeaderMessage, target, download, tooltip, white}) => (
    <Fab
        disabled={loading || ((href && !documentUrl) || (!href && reviewFinished))}
        size="small"
        color={white ? 'default' : 'primary'}
        sx={white && classes.whiteFab}
        onMouseEnter={() => setHeaderMessage(tooltip || '')}
        onMouseLeave={() => setHeaderMessage('')}
        onClick={onClick}
        href={href}
        target={target}
        download={download}
    >{icon}</Fab>
);

const DownloadButton = ({ loading, reviewFinished, versionUrlFormat, setHeaderMessage }) => {
    useSignals();
    return (
        <StyledFab
            documentUrl={versionDownloadUrl.value}
            icon={<CloudDownloadIcon />} tooltip={`Download ${versionUrlFormat === 'pdf' ? 'PDF' : 'DOCX'}`} white
            loading={loading}
            onClick={() => toast('Carregando... aguarde alguns segundos.', { type: 'info' })}
            href={versionUrlFormat === 'googleDoc' ? getDocumentGoogleDocExportLink(versionDownloadUrl.value, 'docx') : versionDownloadUrl.value }
            reviewFinished={reviewFinished}
            setHeaderMessage={setHeaderMessage}
            target={versionUrlFormat === 'pdf' ? '_blank' : undefined}
        />
    );
}

const DocumentFile = ({file, handleFileClick, icon, selectedDocumentVersionId}) => (
    <Box mb={1} sx={[classes.fileBox, file.uid === selectedDocumentVersionId && classes.selectedFileBox]}>
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <Fab
                    size="small"
                    color="primary"
                    disabled={file.uid === selectedDocumentVersionId}
                    onClick={() => handleFileClick(file)}
                >{icon}</Fab>
            </Grid>
            <Grid item xs>
                <Typography variant="body1" style={{ wordBreak: 'break-word' }}>{file.name}</Typography>
            </Grid>
        </Grid>
    </Box>
);

const DocumentFiles = ({ handleFileClick, selectedDocument, selectedDocumentVersionId }) => {
    const [documentFiles, setDocumentFiles] = useState(null);

    useEffect(() => {
        if(selectedDocument){
            const currentFiles = Object.entries(selectedDocument.files).map(([fileId, file]) => {
                return {...file, uid: fileId}
            });
            setDocumentFiles(currentFiles);
        }
    }, [selectedDocument]);

    useEffect(() => {
        if(documentFiles){
            numberOfFiles.value = documentFiles.length;
        }
    }, [documentFiles]);

    if(documentFiles){
        return (
            <Box>
                {
                    documentFiles.map(file => (
                        <DocumentFile
                            key={file.uid}
                            file={file}
                            handleFileClick={handleFileClick}
                            icon={<AttachmentIcon />}
                            selectedDocumentVersionId={selectedDocumentVersionId}
                        />
                    ))
                }
            </Box>
        )
    }
    return null;
}

const DocumentVersions = ({ handleFileClick, selectedDocumentId, selectedDocumentVersionId, setDocumentUrl, setVersionUrlFormat }) => {
    const [documentVersions, setDocumentVersions] = useState(null);

    const retrievedDocumentVersions = useGetDocumentVersions(selectedDocumentId);
    useEffect(() => {
        if(!retrievedDocumentVersions.loading && retrievedDocumentVersions.data){
            setDocumentVersions(retrievedDocumentVersions.data);
            if(selectedDocumentVersionId){
                const foundDocumentVersion = retrievedDocumentVersions.data.find(documentVersion => documentVersion.uid === selectedDocumentVersionId);
                if(foundDocumentVersion){
                    setDocumentUrl(/^\.?(googleDoc|pdf)$/.test(foundDocumentVersion.fileFormat) ? foundDocumentVersion.fileUrl : '');
                    versionDownloadUrl.value = foundDocumentVersion.fileUrl;
                    setVersionUrlFormat(foundDocumentVersion.fileFormat);
                }
            }
        }
    }, [retrievedDocumentVersions]);

    useEffect(() => {
        if(documentVersions){
            numberOfVersions.value = documentVersions.length;
        }
    }, [documentVersions]);

    if(documentVersions){
        return (
            <Box>
                {
                    documentVersions
                    .filter((documentVersion, documentVersionIndex) => {
                        const autoFile = documentVersion.name === 'Arquivo enviado para revisão' && documentVersionIndex === 0;
                        return !autoFile;
                    })
                    .map((documentVersion, documentVersionIndex) => {
                        let documentVersionUrl = documentVersion.fileUrl;
                        let documentVersionName = documentVersion.name;
                        if(!documentVersionName && documentVersionIndex === 0) documentVersionName = 'Primeira versão';
                        let documentVersionFileFormat = documentVersion.fileFormat;
                        return (
                            <DocumentFile
                                key={documentVersion.uid}
                                file={{fileFormat: documentVersionFileFormat, uid: documentVersion.uid, name: documentVersionName, url: documentVersionUrl}}
                                handleFileClick={handleFileClick}
                                icon={documentVersionFileFormat === 'googleDoc' ? <DescriptionIcon /> : <AttachmentIcon />}
                                selectedDocumentVersionId={selectedDocumentVersionId}
                            />
                        )
                    })
                }
            </Box>
        )
    }
    return null;
}

const versionUrlTemplates = {
    pdf: `{DOCUMENT_PATH}`,
    docGoogleView: `https://docs.google.com/gview?url={DOCUMENT_PATH}&embedded=true`,
    doc: `https://view.officeapps.live.com/op/embed.aspx?src={DOCUMENT_PATH}`,
    docx: `https://view.officeapps.live.com/op/embed.aspx?src={DOCUMENT_PATH}`
};

const FilesSection = ({handleFileClick, selectedDocument, selectedDocumentId, selectedDocumentVersionId, setDocumentUrl, setVersionUrlFormat}) => {
    useSignals();

    if((numberOfFiles.value === null || numberOfFiles.value > 0) || (numberOfVersions.value === null || numberOfVersions.value > 1)){
        return (
            <SideBarSection title="ARQUIVOS">
                <DocumentFiles
                    handleFileClick={handleFileClick}
                    selectedDocument={selectedDocument}
                    selectedDocumentVersionId={selectedDocumentVersionId}
                />
                <DocumentVersions
                    handleFileClick={handleFileClick}
                    selectedDocument={selectedDocument}
                    selectedDocumentId={selectedDocumentId}
                    selectedDocumentVersionId={selectedDocumentVersionId}
                    setDocumentUrl={setDocumentUrl}
                    setVersionUrlFormat={setVersionUrlFormat}
                />
            </SideBarSection>
        )
    }
    return null;
}

const ReviewDocument = () => {
    useSignals();
    const {
        loadingApp,
        selectedWorkspace
    } = useAppStateCtx();
    const { setState: setDocumentsCtxState, showSubmitDocumentView } = useDocumentsAPI();
    const { submitDocumentViewSelectedDocument: selectedDocument } = useDocumentsCtxSubmitDocumentView();
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [reviewFinished, setReviewFinished] = useState(true);
    const visibleDocumentId = params.documentId;
    const [selectedDocumentVersionId, setSelectedDocumentVersionId] = useState('');
    const [documentUrl, setDocumentUrl] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');
    const [headerMessage, setHeaderMessage] = useState(false);
    const [documentForm, setDocumentForm] = useState(null);
    const [documentTalentForm, setDocumentTalentForm] = useState(null);
    const [versionUrlFormat, setVersionUrlFormat] = useState('');
    const [convertFileToGoogleDocDialogOpen, setConvertFileToGoogleDocDialogOpen] = useState(false);
    const [cnpj, setCnpj] = useState('');
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [sideBarStyle, setSideBarStyle] = useState({});

    const setSelectedDocument = (newValue) => {
        setDocumentsCtxState('submitDocumentViewSelectedDocument', newValue);
    };

    const reviewStatusActions = {
        review: (foundDocument, documentVersionId) => {
            setSelectedDocument(foundDocument);

            setSelectedDocumentVersionId(documentVersionId || '');

            setDocumentForm(foundDocument.form || null);
            setDocumentTalentForm(foundDocument.talentForm || null);

            setAlertMessage('');

            setLoading(false);
            setReviewFinished(false);
        },
        done: (foundDocument) => {
            setDocumentTalentForm(null);

            setAlertSeverity('success');
            setAlertMessage(`Obrigado! O documento "${foundDocument.name}" foi revisado.`);

            setLoading(false);
            setReviewFinished(true);
        },
    };

    const retrievedDocument = useGetDocument(visibleDocumentId);
    useEffect(() => {
        if(!retrievedDocument.loading){
            if(retrievedDocument.error){
                setAlertSeverity('error');
                setAlertMessage(retrievedDocument.error);
            } else if(retrievedDocument.data){
                const { v: documentVersionId } = queryString.parse(window.location.search);
                startReviewAction(retrievedDocument.data, documentVersionId);
            } else {
                setAlertSeverity('error');
                setAlertMessage(`Documento não encontrado.`);
            }
            setLoading(false);
            loadingApp.value = false;
        }
    }, [retrievedDocument]);

    useEffect(() => {
        if(documentForm){
            try {
                const documentFormObject = JSON.parse(documentForm);
                if(documentFormObject['CNPJ']) setCnpj(documentFormObject['CNPJ'].input || '');
            } catch (error) {
                setCnpj('');
            }
        }
    }, [documentForm]);

    useEffect(() => {
        setSideBarStyle(sideBarOpen ? {} : { width: 0 });
    }, [sideBarOpen]);

    const startReviewAction = (foundDocument, documentVersionId) => {
        let action = 'done';
        if(foundDocument.awaitingOperatorReview) action = 'review';
        reviewStatusActions[action](foundDocument, documentVersionId);
    };

    const handleFinishedReviewButtonClick = () => {
        setHeaderMessage('');
        showSubmitDocumentView(selectedDocument);
    };
    const handleFinishedReviewDone = () => {
        reviewStatusActions.done(selectedDocument);
    };

    const handleFileClick = (file) => {
        if(!file.fileFormat){
            file.fileFormat = file.format || file.url.split('.').pop();
        }
        if(file.fileFormat === 'docx') file.fileFormat = 'doc';
        const versionUrlTemplate = versionUrlTemplates[file.fileFormat];
        const newVersionUrl = versionUrlTemplate ? versionUrlTemplates[file.fileFormat].replace('{DOCUMENT_PATH}', file.url) : file.url;
        setSelectedDocumentVersionId(file.uid);
        setDocumentUrl(newVersionUrl);
        versionDownloadUrl.value = file.url;
        setVersionUrlFormat(file.fileFormat);
    };

    const handleSaveDocInGoogleDriveButtonClick = () => {
        setConvertFileToGoogleDocDialogOpen(true);
    }

    const handleConfirmSaveDocInGoogleDrive = async () => {
        setHeaderMessage('');
        setConvertFileToGoogleDocDialogOpen(false);
        setLoading(true);
        const res = await callFunction('convertWordFileToGoogleDocAndCreateDocumentVersion', {
            documentId: visibleDocumentId, fileUrl: versionDownloadUrl.value
        });
        setLoading(false);
        if(res.error || !res.result){
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        startReviewAction(retrievedDocument.data, res.result.uid);
        window.history.pushState({}, '', `/${selectedWorkspace.value.shortName}/juridico/documento/${retrievedDocument.data.uid}?v=${res.result.uid}`);
        setDocumentUrl(res.result.fileUrl);
        versionDownloadUrl.value = res.result.fileUrl;
        setVersionUrlFormat('googleDoc');
    };

    const handleSubmitConfirmClick = () => {
        setLoading(true);
    };

    return (
        <Div sx={classes.root}>
            <Box sx={classes.header}>
                <Box sx={classes.elevatedBox}>
                    <Container maxWidth="lg" sx={classes.elevatedBoxHeight}>
                        <Grid container spacing={2} alignItems="center" sx={classes.elevatedBoxHeight}>
                            <Grid item xs={2} md={1}>
                                <img src={'/SOLIDA-50.png'} alt="Solida" /> 
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <StyledFab
                                    documentUrl={documentUrl}
                                    icon={<SendIcon />} tooltip="Concluí a revisão"
                                    loading={loading}
                                    onClick={handleFinishedReviewButtonClick}
                                    reviewFinished={reviewFinished}
                                    setHeaderMessage={setHeaderMessage}
                                />
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <StyledFab
                                    documentUrl={documentUrl}
                                    icon={<LinkIcon />} tooltip="Abrir o documento em outra janela" white
                                    loading={loading}
                                    href={documentUrl} target="_blank"
                                    reviewFinished={reviewFinished}
                                    setHeaderMessage={setHeaderMessage}
                                />
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <DownloadButton loading={loading} reviewFinished={reviewFinished} versionUrlFormat={versionUrlFormat} setHeaderMessage={setHeaderMessage} />
                            </Grid>
                            {
                                /^\.?docx?$/.test(versionUrlFormat) &&
                                <Grid item xs={2} md={1}>
                                    <StyledFab
                                        documentUrl={documentUrl}
                                        icon={<InsertDriveFileIcon />} tooltip={`Salvar no Google Drive e abrir`} white
                                        loading={loading}
                                        onClick={handleSaveDocInGoogleDriveButtonClick}
                                        reviewFinished={reviewFinished}
                                        setHeaderMessage={setHeaderMessage}
                                    />
                                </Grid>
                            }
                            <Grid item xs>
                                <Fade in={headerMessage} timeout={500}>
                                    <Typography variant="body1" color="initial" noWrap>{headerMessage}</Typography>
                                </Fade>
                            </Grid>
                            <Grid item>
                                <Tooltip text="Consultar CNPJ">
                                    <Button size="small" href={`https://servicos.receita.fazenda.gov.br/servicos/cnpjreva/cnpjreva_solicitacao.asp?cnpj=${cnpj}`} target="_blank">CNPJ</Button>
                                </Tooltip>
                                <Tooltip text="Lista de atividades permitidas para MEI">
                                    <Button size="small" href="http://www8.receita.fazenda.gov.br/SimplesNacional/Arquivos/manual/Anexo_XI.pdf" target="_blank">MEI</Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <IconButton
                    style={{ position: 'absolute', top: 2, right: 8 }}
                    onClick={() => setSideBarOpen(!sideBarOpen)}
                    size="large">{sideBarOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
            </Box>
            <Box sx={classes.content}>
                <Box sx={[classes.mainContent, reviewFinished && classes.mainContentFullWidth]}>
                    {
                        (!loading && !reviewFinished && documentUrl) &&
                        <Iframe title="Document for review" sx={classes.iframe} src={documentUrl} />
                    }
                    <Box sx={classes.alert}>
                        {
                            loading &&
                            <LoaderEllipsis />
                        }
                        <Collapse in={!!alertMessage}>
                            <Alert variant="filled" severity={alertSeverity}><Typography variant="body1">{alertMessage}</Typography></Alert>
                        </Collapse>
                    </Box>
                </Box>
                {
                    (!!selectedDocument && !reviewFinished) &&
                    <Box sx={classes.sideBar} style={sideBarStyle}>
                        <FilesSection
                            handleFileClick={handleFileClick}
                            selectedDocument={selectedDocument}
                            selectedDocumentId={visibleDocumentId}
                            selectedDocumentVersionId={selectedDocumentVersionId}
                            setDocumentUrl={setDocumentUrl}
                            setVersionUrlFormat={setVersionUrlFormat}
                        />
                        {
                            (!documentTalentForm && documentForm) &&
                            <ReadDocumentForm documentForm={documentForm} checklist sectionTop /> 
                        }
                        {
                            documentTalentForm &&
                            <ReadDocumentTalentForm documentTalentForm={documentTalentForm} review checklist sectionTop />
                        }
                    </Box>
                }
            </Box>
            
            <LastComment />

            <SubmitDocDialog
                actionConfirmCallback={handleSubmitConfirmClick}
                actionDoneCallback={handleFinishedReviewDone}
            />
            <AlertDialog
                open={convertFileToGoogleDocDialogOpen} onClose={() => setConvertFileToGoogleDocDialogOpen(false)}
                okButtonOnClick={handleConfirmSaveDocInGoogleDrive}
                text="Isso converterá esse arquivo para Google Doc e criará uma nova versão na linha do tempo do documento."
            />
        </Div>
    );
};

export default ReviewDocument;