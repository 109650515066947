export const typography = {
    fontFamily: `"Titillium Web", sans-serif`,
    h1: { fontWeight: 500, fontSize: 24, },
    h2: { fontWeight: 500, fontSize: 22, },
    h3: { fontWeight: 500, fontSize: 20, },
    h4: { fontWeight: 500, fontSize: 18, },
    h5: { fontWeight: 500, fontSize: 16, },
    h6: { fontWeight: 500, fontSize: 14, },
    body1: { fontWeight: 400, fontSize: 14, },
    body2: { fontWeight: 400, fontSize: 12, },
}