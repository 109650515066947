import React from 'react';
import ShortUniqueId from 'short-unique-id';
import InputMask from 'react-input-mask';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import Select from '../../../../components/Select';

const ProjectWindowSignatureSection = ({projectState, setProjectState}) => {

    const deleteSigner = (signerId) => {
        setProjectState(prevState => {
            let nextState = {...prevState};
            let signers = nextState.signers;
            delete signers[signerId];
            nextState.signers = signers;
            return nextState;
        })
    }

    const addSigner = () => {
        // @ts-ignore
        const newSignerId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
        setProjectState(prevState => ({...prevState, signers: { ...prevState.signers, [newSignerId]: { name: '', email: '', taxpayerNumber: '', mobile: '', type: 'select' } } }))
    }

    const handleSignerMobileChange = (signerId, newValue) => {
        let newValueNumber = newValue ? parseInt(newValue.replace(/\D/g, '')) : '';
        setProjectState(prevState => ({...prevState, signers: { ...prevState.signers, [signerId]: { ...prevState.signers[signerId], mobile: newValueNumber } } }))
    };

    return (
        <Box mb={3}>
            {
                Object.keys(projectState.signers).length !== 0 &&
                <Box mb={2}>
                    <Typography variant="h6">Signatário(a)s</Typography>
                </Box>
            }
            <Box mb={1}>
                {
                    Object.entries(projectState.signers).map(([signerId, signer]) => (
                        <Box key={signerId}>
                            <Box my={1}>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid size={{ xs: 9, sm: 10, md: 11 }} container spacing={1} alignItems="flex-end">
                                        <Grid size={{ xs: 12, sm: 10, md: 3 }} container spacing={1} alignItems="flex-end">
                                            <Grid sx={{ display: { xs: 'block', lg: 'none' } }}>
                                                <IconButton size="small"
                                                    onClick={() => deleteSigner(signerId)}
                                                ><ClearIcon /></IconButton>
                                            </Grid>
                                            <Grid size="grow">
                                                <TextField
                                                    variant="standard"
                                                    label="Nome completo"
                                                    fullWidth
                                                    value={signer.name}
                                                    onChange={(e) => setProjectState(prevState => ({...prevState, signers: { ...prevState.signers, [signerId]: { ...prevState.signers[signerId], name: e.target.value } } }))}
                                                    required
                                                    error={!signer.name} />
                                            </Grid>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                                            <Select
                                                label="Tipo"
                                                options={[{value: 'party', label: 'Parte'}, {value: 'witness', label: 'Testemunha'}]}
                                                value={signer.type} onChange={(e) => setProjectState(prevState => ({...prevState, signers: { ...prevState.signers, [signerId]: { ...prevState.signers[signerId], type: e.target.value } } }))}
                                                required error={!signer.type}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 10, md: 3 }}>
                                            <TextField
                                                variant="standard"
                                                label="E-mail"
                                                fullWidth
                                                value={signer.email}
                                                onChange={(e) => setProjectState(prevState => ({...prevState, signers: { ...prevState.signers, [signerId]: { ...prevState.signers[signerId], email: e.target.value } } }))}
                                                required
                                                error={!signer.email} />
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                                            <InputMask
                                                mask="999.999.999-99"
                                                value={signer.taxpayerNumber} onChange={(e) => setProjectState(prevState => ({...prevState, signers: { ...prevState.signers, [signerId]: { ...prevState.signers[signerId], taxpayerNumber: e.target.value } }}))}
                                                maskChar=""
                                            >
                                                {() => (
                                                    <TextField variant="standard" fullWidth label="CPF" />
                                                )}
                                            </InputMask>
                                        </Grid>
                                        <Grid size={{ xs: 12, sm: 6, md: 2 }}>
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={signer.mobile} onChange={(e) => handleSignerMobileChange(signerId, e.target.value)}
                                                maskChar=""
                                            >
                                                {() => (
                                                    <TextField
                                                        variant="standard"
                                                        fullWidth
                                                        label="Celular"
                                                        required
                                                        error={!signer.mobile} />
                                                )}
                                            </InputMask>
                                        </Grid>
                                    </Grid>
                                    <Grid sx={{ display: { xs: 'none', lg: 'block' } }}>
                                        <IconButton size="small"
                                            onClick={() => deleteSigner(signerId)}
                                        ><ClearIcon /></IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    ))
                }
            </Box>
            <Grid container justifyContent="flex-end">
                <Grid>
                    <Button
                        startIcon={<AddIcon />}
                        onClick={addSigner}
                    >Cadastrar signatário(a)</Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProjectWindowSignatureSection;