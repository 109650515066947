import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';

import { Box, Slide } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Dialog from './Dialog/Dialog';
import LoaderEllipsis from './LoaderEllipsis';
import Tooltip from './Tooltip';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { useAppCtxAPI, useAppCtxChangeFolderGroupsView } from '../context/SystemContext';
import { getUserNameById } from '../utils/common';
import { ERROR_MESSAGE_UNKNOWN, SUCCESS_MESSAGE_SAVED } from '../utils/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const availableUsers = signal([]);
const selectedGroup = signal(null);
const usersMenuAnchorEl = signal(null);
const usersMenuOpen = signal(false);

const UsersMenu = ({ onUsersMenuUserClick }) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    const handleClose = () => {
        usersMenuOpen.value = false;
    };

    return (
        <Menu
            anchorEl={usersMenuAnchorEl.value}
            open={usersMenuOpen.value}
            onClose={handleClose}
        >
            {
                availableUsers.value
                .filter(userId => !selectedGroup.value?.users.includes(userId))
                .sort((a, b) => {
                    let aFullName = getUserNameById(workspaceUsers.value, a);
                    let bFullName = getUserNameById(workspaceUsers.value, b);
                    return aFullName > bFullName ? 1 : (aFullName < bFullName ? -1 : 0);
                })
                .map((userId) => (
                    <MenuItem key={userId}
                        onClick={() => onUsersMenuUserClick(userId)}
                    >
                        <Typography>{getUserNameById(workspaceUsers.value, userId)}</Typography>
                    </MenuItem>
                ))
            }
        </Menu>
    );
}

const GroupsWindow = ({ activeUserIsOperator }) => {
    useSignals();
    const { folderUsers, projectGroups, workspaceUsers } = useAppStateCtx();
    const { setFirestoreListener } = useAppStateCtxUtils();
    const { hideChangeFolderGroupsView } = useAppCtxAPI();
    const { changeFolderGroupsViewOpen, changeFolderGroupsViewSelectedFolder: selectedFolder, changeFolderGroupsViewSelectedFolderId: projectId,  } = useAppCtxChangeFolderGroupsView();
    const managerGroups = [1, '1'];
    const adminGroups = [2, '2'];
    const managementGroups = [...managerGroups, ...adminGroups];
    const [groups, set_groups] = useState({});
    const [loadingPage, set_loadingPage] = useState(false);
    const [folderName, set_folderName] = useState('');
    const [saving, setSaving] = useState(false);
    const shouldSaveGroups = useRef(false);

    const systemIDs = ['62177a2ded92587af6430239', '61070b746c18c758e409a4be'];
    // solicitante@solidasistemas.com.br, stefanofalcao@dn.adv.br

    useEffect(() => {
        if(changeFolderGroupsViewOpen && projectId){
            set_loadingPage(true);
            setFirestoreListener('folderUsers', `projects/${projectId}`);
            set_folderName('');
            set_groups(projectGroups.value);
        }
    }, [changeFolderGroupsViewOpen, projectId]);

    useEffect(() => {
        if(changeFolderGroupsViewOpen && folderUsers.value){
            availableUsers.value = Object.keys(folderUsers.value);
            set_loadingPage(false);
        }
    }, [changeFolderGroupsViewOpen, folderUsers.value]);

    const saveGroups = async () => {
        setSaving(true);
        const res = await selectedFolder.updateGroups({
            groups
        });
        setSaving(false);
        if(res.error){
            return toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        }
        toast(SUCCESS_MESSAGE_SAVED, { type: 'success' });
    };

    useEffect(() => {
        if(groups && shouldSaveGroups.current){
            shouldSaveGroups.current = false;
            saveGroups();
        }
    }, [groups]);

    const handleMenuOpenClick = (e, group, groupId) => {
        selectedGroup.value = {...group, id: groupId};
        usersMenuAnchorEl.value = e.target;
        usersMenuOpen.value = true;
    };

    const handleAddGroupUser = (userId) => {
        usersMenuOpen.value = false;
        let confirm = true;
        let currentGroups = {...groups};
        
        if(managementGroups.includes(selectedGroup.value.id)){ // changing user to management
            for(const groupId in currentGroups){
                currentGroups[groupId].users = currentGroups[groupId].users.filter(groupUserId => groupUserId !== userId);
            }
        } else {
            let managementGroupUserIsIn = null;
            if(currentGroups['1']?.users.includes(userId)){
                managementGroupUserIsIn = {...currentGroups['1'], id: '1'};
            }
            if(currentGroups['2']?.users.includes(userId)){
                managementGroupUserIsIn = {...currentGroups['2'], id: '2'};
            }
            if(managementGroupUserIsIn){
                confirm = window.confirm(`${getUserNameById(workspaceUsers.value, userId)} está no grupo ${managementGroupUserIsIn.name}. Isso poderá alterar os privilégios dessa pessoa. Tem certeza que quer continuar?`);
                if(confirm){
                    let userIndex = managementGroupUserIsIn.users.findIndex(groupUser => groupUser === userId);
                    managementGroupUserIsIn.users.splice(userIndex, 1);
                    currentGroups = {
                        ...currentGroups,
                        [managementGroupUserIsIn.id]: {
                            ...currentGroups[managementGroupUserIsIn.id],
                            users: managementGroupUserIsIn.users
                        }
                    }
                }
            }
        }
        if(confirm){
            let selectedGroupUsers = selectedGroup.value.users;
            selectedGroupUsers.push(userId);
            currentGroups = {
                ...currentGroups,
                [selectedGroup.value.id]: {
                    ...currentGroups[selectedGroup.value.id],
                    users: selectedGroupUsers
                }
            }
            shouldSaveGroups.current = true;
            set_groups(currentGroups);
        }
    };
    const handleDeleteUserClick = (groupId, userId) => {
        shouldSaveGroups.current = true;
        set_groups(prevState => {
            const nextState = {...prevState};
            const group = nextState[groupId];
            const userIndex = group.users.findIndex(groupUserId => groupUserId == userId);
            nextState[groupId].users.splice(userIndex, 1);
            return nextState;
        });
    };
    const handleAddGroupClick = () => {
        let groupName = window.prompt('Qual é o nome do grupo?');
        if(groupName){
            const newGroupId = new Date().getTime().toString();
            const newGroup = {
                name: groupName,
                users: []
            };
            shouldSaveGroups.current = true;
            set_groups(prevState => ({...prevState, [newGroupId]: newGroup}));
        }
    };
    const handleChangeGroupNameClick = (groupId, selectedGroup) => {
        let groupName = window.prompt('Qual é o nome do grupo?', selectedGroup.name);
        if(groupName){
            shouldSaveGroups.current = true;
            set_groups(prevState => ({
                ...prevState,
                [groupId]: {
                    ...prevState[groupId],
                    name: groupName
                }
            }));
        }
    };
    const handleDeleteGroupClick = (groupId, groupName) => {
        if(window.confirm(`Tem certeza que quer excluir o grupo ${groupName}?`)){
            shouldSaveGroups.current = true;
            set_groups(prevState => {
                const nextState = {...prevState};
                delete nextState[groupId];
                return nextState;
            });
        }
    };

    const handleClose = () => {
        hideChangeFolderGroupsView();
    };
    
    return (
        <>
            <Dialog
                closeButtonLabel="Fechar"
                onClose={handleClose}
                open={changeFolderGroupsViewOpen}
                saving={saving}
                title={`${folderName ? `${folderName} | ` : ''}Grupos`}
                TransitionComponent={Transition}
            >
                <Box mb={2}>
                    {
                        loadingPage
                        ? <LoaderEllipsis />
                        :
                        <Box>
                            <Box mb={2}>
                                <Typography variant="h6">Nenhum grupo</Typography>
                                {
                                    availableUsers.value
                                    .filter(userId => {
                                        let userInGroup = false;
                                        for(const groupsId in groups){
                                            if(groups[groupsId].users.includes(userId)){
                                                userInGroup = true;
                                                return;
                                            }
                                        }
                                        return !userInGroup;
                                    })
                                    .map((userId) => (
                                        <Chip
                                            key={userId}
                                            style={{margin: 2}}
                                            label={getUserNameById(workspaceUsers.value, userId) + (systemIDs.includes(userId) ? ` (SOLIDA)` : '')}
                                        />
                                    ))
                                }
                            </Box>
                            {
                                Object.entries(groups)
                                .sort((a, b) => {
                                    if(!managementGroups.includes(a[0]) && managementGroups.includes(b[0])) return 1;
                                    if(managementGroups.includes(a[0]) && !managementGroups.includes(b[0])) return -1;
                                    return a[1].name > b[1].name ? 1 : (a[1].name < b[1].name ? -1 : 0);
                                })
                                .map(([groupId, group]) => (
                                    <Box key={groupId} mb={2}>
                                        <Box mb={1}>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item>
                                                    <Typography variant="h6">{group.name}</Typography>
                                                </Grid>
                                                {
                                                    !managementGroups.includes(groupId) &&
                                                    <Grid item>
                                                        <Tooltip text="Alterar nome">
                                                            <IconButton size="small" onClick={() => handleChangeGroupNameClick(groupId, group)}><EditIcon /></IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                                {
                                                    (activeUserIsOperator && groupId !== '1' && groupId !== '2') &&
                                                    <Grid item>
                                                        <Tooltip text="Excluir grupo">
                                                            <IconButton size="small" onClick={() => handleDeleteGroupClick(groupId, group.name)}><DeleteIcon /></IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                        {
                                            group.users.map(userId => (
                                                <Chip
                                                    key={userId}
                                                    color={systemIDs.includes(userId) ? 'secondary' : 'primary'}
                                                    style={{margin: 2}}
                                                    label={getUserNameById(workspaceUsers.value, userId) + (systemIDs.includes(userId) ? ` (SOLIDA)` : '')}
                                                    onDelete={activeUserIsOperator || !adminGroups.includes(groupId) ? () => handleDeleteUserClick(groupId, userId) : undefined}
                                                />
                                            ))
                                        }
                                        {
                                            (activeUserIsOperator || !adminGroups.includes(groupId)) &&
                                            <Tooltip text={`Alocar alguém no grupo ${group.name}`}>
                                                <IconButton size="small" onClick={(e) => handleMenuOpenClick(e, group, groupId)}><AddIcon /></IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                ))
                            }
                            <Button startIcon={<AddIcon />} onClick={handleAddGroupClick}>Novo grupo</Button>
                        </Box>
                    }
                </Box>

            </Dialog>
            <UsersMenu onUsersMenuUserClick={handleAddGroupUser} />
        </>
    );
};

export default GroupsWindow;