import { useEffect, useRef } from 'react';

import { useSignals } from '@preact/signals-react/runtime';
import { signal } from '@preact/signals-react';

import MenuItem from '@mui/material/MenuItem';
import Account from '../../components/Account'
import { useAppStateCtx } from '../../context/AppState';
import { useAppCtxAPI } from '../../context/SystemContext';

const userMenuOpen = signal(null);

const OperatorAccount = ({size}) => {
    useSignals();
    const { onlineUsersListPopoverAnchorEl, onlineUsersListPopoverOpen, selectedWorkspace } = useAppStateCtx();
    const { handleNavigate } = useAppCtxAPI();
    const userAvatarRef = useRef(null);

    useEffect(() => {
        return () => {
            userMenuOpen.value = false;
        };
    }, []);

    const handleOnlineUsersClick = () => {
        userMenuOpen.value = false;
        onlineUsersListPopoverAnchorEl.value = userAvatarRef.current;
        onlineUsersListPopoverOpen.value = true;
    };
    
    return (
        <Account size={size} userAvatarRef={userAvatarRef} userMenuOpen={userMenuOpen}> 
            <MenuItem onClick={() => handleNavigate(`/${selectedWorkspace.value.shortName}/juridico/preferencias`)}>Preferências</MenuItem>
            <MenuItem onClick={handleOnlineUsersClick}>Quem está online</MenuItem>
        </Account>
    );
};

export default OperatorAccount;