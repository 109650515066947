import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { Box, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Autocomplete from '../../../components/Autocomplete';
import FavoriteProjects from '../../../components/FavoriteProjects';
import Select from '../../../components/Select';
import PendingDocumentsTask from './PendingDocumentsTask';
import TaskBoard from './TaskBoard';
import TasksList from './TasksList';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useAppStateCtx } from '../../../context/AppState';
import { useDocumentsCtxPendingDocumentsCount } from '../../../context/DocumentsContext';
import { useTasksCtxAPI, useTasksCtxActiveUserTasks, useTasksCtxFilters, useTasksCtxTeamTasks } from '../../../context/TasksContext';
import { sortByKey, sortObjectEntriesByKey } from '../../../utils/filters';

const UsersGrid = ({ layout, projectOptions, setProjectOptions }) => {
    useSignals();
    const theme = useTheme();
    const screenXS = useMediaQuery(theme.breakpoints.down('sm'));
    const { activeUser, workspaceClients, workspaceProjects } = useAppStateCtx();
    const { pendingDocumentsCountPerOperator } = useDocumentsCtxPendingDocumentsCount();
    const { handleTaskDragEnd, setFilters, sortTasks } = useTasksCtxAPI();
    const filters = useTasksCtxFilters();
    const teamTasks = useTasksCtxTeamTasks();
    const activeUserTasks = useTasksCtxActiveUserTasks();
    const [clientOptions, setClientOptions] = useState([]);

    useEffect(() => {
        if(workspaceClients.value){
            let clientIndex = 1;
            setClientOptions(
                Object.entries(workspaceClients.value)
                .sort(sortObjectEntriesByKey('name'))
                .map(([clientId, client]) => {
                    const mappedClient = {...client, uid: clientId, name: `${clientIndex}) ${client.name}`};
                    clientIndex++;
                    return mappedClient;
                })
            )
        }
    }, [workspaceClients.value]);

    const handleFavoriteClick = (type, folderId) => {
        if(type === 'clients'){
            const client = workspaceClients.value[folderId];
            handleClientChange({...client, uid: folderId});
        } else {
            const project = workspaceProjects.value[folderId];
            if(project){
                const client = workspaceClients.value[project.clientId];
                if(client){
                    handleClientChange({...client, uid: project.clientId}, folderId);
                }
            }
        }
    };
    const handleClientChange = (clickedClient, clickedProjectId) => {
        if(clickedClient){
            const currentProjectOptions = Object.entries(workspaceProjects.value).filter(([_, project]) => project.clientId === clickedClient.uid).map(([projectId, project]) => ({value: projectId, label: project.name}));
            setProjectOptions([{value: 'all', label: 'Todos'}, ...currentProjectOptions.sort(sortByKey('label'))]);
            setFilters(prevState => ({ ...prevState, client: clickedClient, project: clickedProjectId || 'all' }));
        } else {
            setProjectOptions([]);
            setFilters(prevState => ({...prevState, client: null, project: ''}));
        }
    };

    const handleProjectChange = (newValue) => {
        setFilters(prevState => ({...prevState, project: newValue}))
    };

    if(layout === 'personal' && screenXS){
        return (
            <DragDropContext onDragEnd={handleTaskDragEnd}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                    <Grid size="grow" container spacing={1} alignItems="flex-start" style={{height: '100%'}}>
                        <Grid style={{height: '100%', width: `100%`}}>
                            <TaskBoard
                                user={{ img: activeUser.value.photoUrl, screenName: activeUser.value.name }}
                                userNumberOfPendingDocuments={(pendingDocumentsCountPerOperator ? pendingDocumentsCountPerOperator[activeUser.value.uid] || 0 : 0).toString()}
                            >
                                <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserUnreadTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserUnreadTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserHighPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserHighPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserMediumPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserLowPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserLowPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                            </TaskBoard>
                        </Grid>
                    </Grid>
                </Grid>
            </DragDropContext>
        );
    }

    if(layout === 'team' && teamTasks){
        return (
            <DragDropContext onDragEnd={handleTaskDragEnd}>
                <Grid container spacing={1} alignItems="flex-start" justifyContent="center" style={{height: '100%'}}>
                    <Grid size="grow" container spacing={1} alignItems="flex-start" wrap="nowrap" style={{height: '100%', overflowX: 'auto'}}>
                        <Grid style={{height: '100%', width: `${(100 / (teamTasks.length + 1))}%`, minWidth: '100px'}}>
                            <TaskBoard
                                user={{ img: activeUser.value.photoUrl, screenName: activeUser.value.name }}
                                userNumberOfPendingDocuments={(pendingDocumentsCountPerOperator ? pendingDocumentsCountPerOperator[activeUser.value.uid] || 0 : 0).toString()}
                            >
                                <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserUnreadTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserUnreadTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserHighPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserHighPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserMediumPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                                <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TasksList
                                            boardId={'activeUserLowPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserLowPriorityTasks}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TasksList>
                                    )}
                                </Droppable>
                            </TaskBoard>
                        </Grid>
                        {
                            teamTasks.map((user) => (
                                <Grid key={user.uid} style={{height: '100%', width: `${(100 / (teamTasks.length + 1))}%`, minWidth: '100px'}}>
                                    <Droppable droppableId={user.uid}>
                                        {(provided, snapshot) => {
                                            const userNumberOfPendingDocuments = (pendingDocumentsCountPerOperator ? pendingDocumentsCountPerOperator[user.uid] || 0 : 0).toString();
                                            return (
                                                <TaskBoard
                                                    boardId={user.uid}
                                                    innerRef={provided.innerRef}
                                                    isDraggingOver={snapshot.isDraggingOver}
                                                    tasks={user.tasks.sort(sortTasks)}
                                                    user={{ img: user.photoUrl, screenName: user.name }}
                                                    userNumberOfPendingDocuments={userNumberOfPendingDocuments}

                                                    {...provided.droppableProps}
                                                >
                                                    {provided.placeholder}
                                                </TaskBoard>
                                            )
                                        }}
                                    </Droppable>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </DragDropContext>
        );
    }

    return (
        <DragDropContext onDragEnd={handleTaskDragEnd}>
            {
                activeUser.value &&
                <Container maxWidth="lg" style={{height: '100%'}}>
                    <Grid container direction="column" spacing={1} justifyContent="center" style={{height: '100%'}}>
                        <Grid size="grow" container direction="row" spacing={1} style={{height: '100%'}}>
                            <Grid size={{ xs: 12, sm: 3 }}
                                style={{
                                    height: '100%',
                                }}
                            >
                                <Droppable droppableId={'activeUserHighPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TaskBoard
                                            boardId={'activeUserHighPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserHighPriorityTasks}
                                            title={`FAZER HOJE (${activeUserTasks.activeUserHighPriorityTasks.length})`}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TaskBoard>
                                    )}
                                </Droppable>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 3 }} style={{height: '100%'}}>
                                <Droppable droppableId={'activeUserMediumPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TaskBoard
                                            boardId={'activeUserMediumPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserMediumPriorityTasks}
                                            title={`FAZER LOGO (${activeUserTasks.activeUserMediumPriorityTasks.length})`}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TaskBoard>
                                    )}
                                </Droppable>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 3 }} style={{height: '100%'}}>
                                <Droppable droppableId={'activeUserLowPriorityTasks'}>
                                    {(provided, snapshot) => (
                                        <TaskBoard
                                            boardId={'activeUserLowPriorityTasks'}
                                            innerRef={provided.innerRef}
                                            isActiveUserTask
                                            isDraggingOver={snapshot.isDraggingOver}
                                            tasks={activeUserTasks.activeUserLowPriorityTasks}
                                            title={`EM ESPERA (${activeUserTasks.activeUserLowPriorityTasks.length})`}
                                            
                                            {...provided.droppableProps}
                                        >
                                            {provided.placeholder}
                                        </TaskBoard>
                                    )}
                                </Droppable>
                            </Grid>
                            <Grid size={{ xs: 12, sm: 3 }} style={{height: '100%'}}>
                                <Box style={{
                                    backgroundColor: 'white',
                                    height: '100%',
                                    transition: 'background-color 0.2s ease',
                                    borderRadius: 8,
                                    position: 'relative',
                                    padding: 8,
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    '&::WebkitScrollbar': {
                                        width: 3
                                    },
                                }}>
                                    {
                                        ((pendingDocumentsCountPerOperator ? pendingDocumentsCountPerOperator[activeUser.value.uid] || 0 : 0).toString()) &&
                                        <PendingDocumentsTask userNumberOfPendingDocuments={(pendingDocumentsCountPerOperator ? pendingDocumentsCountPerOperator[activeUser.uid] || 0 : 0).toString()} />
                                    }
                                    {
                                        (activeUserTasks.activeUserUnreadTasks && activeUserTasks.activeUserUnreadTasks.length >= 1) &&
                                        <Box mb={2}>
                                            <Box mt={0.5} mb={1}>
                                                <Typography variant="h6" noWrap>NÃO LIDAS ({activeUserTasks.activeUserUnreadTasks.length})</Typography>
                                            </Box>
                                            <Droppable droppableId={'activeUserUnreadTasks'} isDropDisabled>
                                                {(provided, snapshot) => (
                                                    <TasksList
                                                        boardId={'activeUserUnreadTasks'}
                                                        innerRef={provided.innerRef}
                                                        isActiveUserTask
                                                        isDraggingOver={snapshot.isDraggingOver}
                                                        tasks={activeUserTasks.activeUserUnreadTasks}
                                                        
                                                        {...provided.droppableProps}
                                                    >
                                                        {provided.placeholder}
                                                    </TasksList>
                                                )}
                                            </Droppable>
                                        </Box>
                                    }
                                    <Box mb={1}>
                                        <Typography variant="h6" noWrap>FILTRAR</Typography>
                                    </Box>
                                    <Box mb={1}>
                                        <FavoriteProjects handleFavoriteClick={handleFavoriteClick} />
                                    </Box>
                                    {
                                        (workspaceClients.value && workspaceProjects.value) &&
                                        <Box mb={1}>
                                            <Grid container spacing={1} alignItems="center" justifyContent="center">
                                                <Grid size="grow">
                                                    <Autocomplete
                                                        label="Cliente"
                                                        options={clientOptions} 
                                                        getOptionLabel={(option) => option.name}
                                                        value={filters.client} onChange={(_, newValue) => handleClientChange(newValue)}
                                                    />
                                                </Grid>
                                                {
                                                    filters.client &&
                                                    <Grid size={{ xs: 6 }}>
                                                        <Select
                                                            label="Projeto"
                                                            options={projectOptions}
                                                            value={filters.project} onChange={(e) => handleProjectChange(e.target.value)}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            }
        </DragDropContext>
    );
};

export default UsersGrid;