import { collection, doc, DocumentData, increment, runTransaction } from 'firebase/firestore';

import ClearanceNoteComment from './ClearanceNoteComment';

import { db } from '../firebase/config';
import { getFileContent, uploadFile } from '../firebase/storage/uploadFile';
import { ClearanceNote as _ClearanceNote } from '../shared/types';

const uploadClearanceNoteCommentFiles = async (clearanceNote: { [key: string]: any }, filesList: any, filesCount: number) => {
    const files: { [fileId: string]: any } = {};
    for(const file of filesList){
        filesCount++;
        const { contentType, fileBuffer } = await getFileContent(file);
        const { result } = await uploadFile(
            `workspaces/${clearanceNote.workspaceId}/projects/${clearanceNote.projectId}/clearance/reports/${clearanceNote.reportId}/${clearanceNote.uid}/uploads/${file.name}`, // storageFilePath
            fileBuffer,
            contentType
        );
        if(result){
            const { downloadUrl, storageFilePath } = result;
            // @ts-ignore
            const fileId = new ShortUniqueId({dictionary: 'alphanum_upper'}).rnd();
            files[fileId] = {
                firebaseStoragePath: storageFilePath,
                url: downloadUrl
            };
        }
    }
    return { files, filesCount };
};

export default class ClearanceNote extends _ClearanceNote {
    
    constructor(constructorArgs: _ClearanceNote){
        super(constructorArgs);
    }

    async cancel(activeUserId: string){
        let result = null, error = null;

        const clearanceNoteRef = doc(db, `clearance_notes/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clearanceNoteRef, {
                    deleted: true,
                    deletedAt: new Date().toISOString(),
                    deletedBy: activeUserId
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        
        return { result, error };
    }

    async comment({ activeUser, comment, riskLevel, riskLevelDescription }: {
        activeUser: any;
        comment: string;
        riskLevel: string;
        riskLevelDescription: string;
    }){
        let result = null, error = null;

        const now = new Date();
        const nowISOString = now.toISOString();

        const clearanceNoteRef = doc(db, `clearance_notes/${this.uid}`);
        const clearanceNoteCommentRef = doc(collection(db, `clearance_notes/${this.uid}/comments`));

        const newComment = new ClearanceNoteComment({
            createdAt: nowISOString,
            createdBy: activeUser.uid,
            createdByName: activeUser.name,
            comment: comment,
            riskLevel: riskLevel || '',
            riskLevelDescription: riskLevelDescription || '',
        });

        const {uid: _, ...newCommentWithoutUid} = newComment;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(clearanceNoteCommentRef, {...newCommentWithoutUid});
                transaction.update(clearanceNoteRef, {
                    commentsCount: increment(1),
                    [`commentsReadAt.${activeUser.uid}`]: nowISOString
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        
        return { result, error };
    }

    async createClearanceNote(newComment: {[key: string]: any}){
        let result = null, error = null;

        const clearanceNotesRef = doc(collection(db, `clearance_notes`));
        const clearanceNoteCommentRef = doc(collection(db, `clearance_notes/${clearanceNotesRef.id}/comments`));

        const {uid: _, ...rest} = this;

        const newClearanceNote: DocumentData = {...rest};

        try {
            await runTransaction(db, async (transaction) => {
                if(newComment){
                    transaction.set(clearanceNoteCommentRef, newComment);
                    newClearanceNote.commentsCount = 1;
                    newClearanceNote.commentsReadAt[newComment.createdBy] = newComment.createdAt;
                }
                transaction.set(clearanceNotesRef, newClearanceNote);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }

    async delete(){
        let result = null, error = null;

        const clearanceNoteRef = doc(db, `clearance_notes/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.delete(clearanceNoteRef);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        
        return { result, error };
    }

    async reply({
        activeUser, activeUserIsOperator, comment, filesList, numberOfApprovalsRequired, reportId, rightType, rightTypeDescription, riskApprovalDescription, riskLevel, riskLevelDescription, useType, useTypeDescription
    }: {
        activeUser: any;
        activeUserIsOperator: boolean;
        comment: string;
        filesList: any[];
        numberOfApprovalsRequired: number;
        reportId: string;
        rightType: string;
        rightTypeDescription: string;
        riskApprovalDescription: string;
        riskLevel: string;
        riskLevelDescription: string;
        useType: string;
        useTypeDescription: string;
    }){
        let result = null, error = null;

        const { files, filesCount } = await uploadClearanceNoteCommentFiles({...this, reportId}, filesList || [], this.filesCount);

        const now = new Date();
        const nowISOString = now.toISOString();

        const clearanceNoteRef = doc(db, `clearance_notes/${this.uid}`);
        const clearanceNoteCommentRef = doc(collection(db, `clearance_notes/${this.uid}/comments`));

        const newComment = new ClearanceNoteComment({
            createdAt: nowISOString,
            createdBy: activeUser.uid,
            createdByName: activeUser.name,
            comment,
            files,
            rightType: rightType || '',
            rightTypeDescription: rightTypeDescription || '',
            riskLevel: riskLevel || '',
            riskLevelDescription: riskLevelDescription || '',
            useType: useType || '',
            useTypeDescription: useTypeDescription || '',
        });

        const updates: { [key: string]: any } = {
            commentsCount: increment(1),
            [`commentsReadAt.${activeUser.uid}`]: nowISOString,
            filesCount,
            rightType: rightType || '',
            rightTypeDescription: rightTypeDescription || '',
            riskLevel: riskLevel || '',
            riskLevelDescription: riskLevelDescription || '',
            useType: useType || '',
            useTypeDescription: useTypeDescription || '',
        };
        updates.awaitingOperatorReview = !activeUserIsOperator;

        if(['yes', 'no'].includes(riskApprovalDescription)){
            newComment.approval = riskApprovalDescription;
            this.approvals[activeUser.uid] = riskApprovalDescription;
            updates[`approvals.${activeUser.uid}`] = riskApprovalDescription;

            let approvalsGiven = 0, approvalsDenied = 0;
            for(const userId in this.approvals){
                const type = this.approvals[userId];
                if(type === 'yes'){
                    approvalsGiven++;
                } else if(type === 'no'){
                    approvalsDenied++;
                }
            }
            
            if((approvalsGiven - approvalsDenied) >= (numberOfApprovalsRequired || 0)){
                updates.approved = 'yes';
            } else if((approvalsGiven - approvalsDenied) < 0){
                updates.approved = 'no';
            }
        }

        const {uid: _, ...newCommentWithoutUid} = newComment;

        try {
            await runTransaction(db, async (transaction) => {
                transaction.set(clearanceNoteCommentRef, newCommentWithoutUid);
                transaction.update(clearanceNoteRef, updates);
            });
            result = true;
        } catch (e) {
            error = e;
        }
        
        return { result, error };
    }

    async setCommentsReadAt({ activeUserId, nowISOString }: {
        activeUserId: string;
        nowISOString: string;
    }){
        let result = null, error = null;

        const clearanceNoteRef = doc(db, `clearance_notes/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                transaction.update(clearanceNoteRef, {
                    [`commentsReadAt.${activeUserId}`]: nowISOString
                });
            });
            result = true;
        } catch (e) {
            error = e;
        }

        
        return { result, error };
    }

    async update({ newComment, updates }: {
        newComment: {[key: string]: any};
        updates: {
            cutTimeCode: string;
            description: string;
            legalRecommendation: string;
            licensed: boolean;
            licenseLink: string;
            requiresAdditionalInformation: boolean;
            rightType: string;
            rightTypeDescription: string;
            riskDescription: string;
            riskLevel: string;
            riskLevelDescription: string;
            scriptScene: _ClearanceNote['scriptScene'];
            useType: string;
            useTypeDescription: string;
        };
    }){
        let result = null, error = null;

        let stateChanged = updates.cutTimeCode !== this.cutTimeCode
        || updates.description !== this.description
        || updates.legalRecommendation !== this.legalRecommendation
        || updates.licenseLink !== this.licenseLink
        || updates.licensed !== this.licensed
        || updates.requiresAdditionalInformation !== this.requiresAdditionalInformation
        || updates.rightType !== this.rightType
        || updates.rightTypeDescription !== this.rightTypeDescription
        || updates.riskDescription !== this.riskDescription
        || updates.riskLevel !== this.riskLevel
        || updates.riskLevelDescription !== this.riskLevelDescription
        || updates.scriptScene !== this.scriptScene
        || updates.useType !== this.useType
        || updates.useTypeDescription !== this.useTypeDescription;

        const finalUpdates: { [key: string]: any } = {...updates};

        finalUpdates.updatedAfterOriginalReport = stateChanged;

        const clearanceNoteRef = doc(db, `clearance_notes/${this.uid}`);

        try {
            await runTransaction(db, async (transaction) => {
                if(newComment && this.commentsCount === 0){
                    transaction.set(doc(collection(db, `clearance_notes/${clearanceNoteRef.id}/comments`)), newComment);
                    finalUpdates.commentsCount = increment(1);
                    finalUpdates[`commentsReadAt.${newComment.createdBy}`] = newComment.createdAt;
                }
                transaction.update(clearanceNoteRef, finalUpdates);
            });
            result = true;
        } catch (e) {
            error = e;
        }

        return { result, error };
    }
    
}