import { useEffect, useRef, useState } from 'react';
import { collection, limit as _limit, onSnapshot, query, orderBy as _orderBy, startAfter as _startAfter, startAt as _startAt, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import Document from '../classes/Document';
import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { db } from '../firebase/config';
import _getDocsCount from '../firebase/firestore/getDocsCount';
import { toLowerCaseWithoutSpecialCharacters } from '../utils/common';

const useGetDocuments = (shouldSetListener, documentsListenerSettings) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const defaultHookState = { loading: true, data: [], count: 0 };
    const [hookState, setHookState] = useState(defaultHookState);
    const firstSnapshot = useRef(null);
    const lastSnapshot = useRef(null);
    const prevFirstSnapshot = useRef(null);

    const getDocsCount = async (queryConstraints) => {
        const res = await _getDocsCount(`documents`, { queryConstraints });
        if(res.error){
            console.error(res.error);
        }
        setHookState(prevState => ({...prevState, count: res.result}));
    };

    useEffect(() => {
        if(shouldSetListener && activeUserIsOperator.value && selectedWorkspace.value && documentsListenerSettings){
            setHookState(defaultHookState);
            
            const queryConstraints = [
                where('workspaceId', '==', selectedWorkspace.value.uid)
            ];
    
            let orderBy = ['madeAvailableToClientAt', 'desc'];
            let startAfter = null;
            let startAt = null;
            let limit = 25;
            
            if(documentsListenerSettings.projectId && documentsListenerSettings.projectId !== 'all'){
                queryConstraints.push(where('projectId', '==', documentsListenerSettings.projectId));
            } else if(
                documentsListenerSettings.clientId && documentsListenerSettings.clientId !== 'all'
                && (!documentsListenerSettings.projectId || documentsListenerSettings.projectId === 'all')
            ){
                queryConstraints.push(where('clientId', '==', documentsListenerSettings.clientId));
            }

            if(documentsListenerSettings.status === 'sent'){
                queryConstraints.push(where('deleted', '==', false));
                queryConstraints.push(where('availableToClient', '==', true));
            } else if(documentsListenerSettings.status === 'scheduledSent'){
                queryConstraints.push(where('deleted', '==', false));
                // wheres.push(['shouldMakeAvailableToClientAt', '!=', '']);
                queryConstraints.push(where('madeAvailableToClientBy', '==', 'SOLIDA'));
            } else if(documentsListenerSettings.status === 'deleted'){
                queryConstraints.push(where('deleted', '==', true));
                orderBy = ['createdAt', 'desc'];
            } else {
                queryConstraints.push(where('deleted', '==', false));
                // NO FILTER
            }

            if(documentsListenerSettings.signatureStatus === 'eSignaturePending'){
                queryConstraints.push(where('lastVersion.eSignature.status', '!=', 'signed'));
            } else if(documentsListenerSettings.signatureStatus === 'eSignatureSigned'){
                queryConstraints.push(where('lastVersion.eSignature.status', '==', 'signed'));
            }

            if(documentsListenerSettings.text){
                queryConstraints.push(where('keywords', 'array-contains', toLowerCaseWithoutSpecialCharacters(documentsListenerSettings.text)));
            }

            if(documentsListenerSettings.orderBy === 'timestamp'){
                orderBy = ['createdAt', 'desc'];
            }

            if(documentsListenerSettings.page !== 1){
                if(documentsListenerSettings.action === 'nextPage'){
                    startAfter = lastSnapshot.current;
                } else if(documentsListenerSettings.action === 'prevPage') {
                    startAt = prevFirstSnapshot.current;
                }
            }

            getDocsCount(queryConstraints);
    
            if(documentsListenerSettings.orderBy){
                if(documentsListenerSettings.orderBy === 'sentOn') orderBy = ['madeAvailableToClientAt', 'desc'];
            }
            queryConstraints.push(_orderBy(...orderBy));

            if(startAfter) queryConstraints.push(_startAfter(startAfter));
            if(startAt) queryConstraints.push(_startAt(startAt));
    
            queryConstraints.push(_limit(limit));
    
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'documents'),
                    ...queryConstraints
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        const newDocument = new Document({...data, uid: snapshot.id});
                        dataArray.push(newDocument);
                    });
                    if(snapshots.docs){
                        prevFirstSnapshot.current = firstSnapshot.current;
                        firstSnapshot.current = snapshots.docs[0];
                        lastSnapshot.current = snapshots.docs[snapshots.docs.length - 1];
                    }
                    setHookState(prevState => ({
                        ...prevState,
                        loading: false,
                        data: dataArray,
                    }));
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetListener, activeUserIsOperator.value, selectedWorkspace.value, documentsListenerSettings]);

    return hookState;
};

export default useGetDocuments;