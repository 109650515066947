import { useEffect, useState } from 'react';
import { and, collection, onSnapshot, query, where } from 'firebase/firestore';

import { useSignals } from '@preact/signals-react/runtime';

import { db } from '../firebase/config';
import { useAppStateCtx } from '../context/AppState';
import { toLowerCaseWithoutSpecialCharacters } from '../utils/common';

const useGetWorkspaceClearanceReportNotes = (shouldSetSnapshot, filters) => {
    useSignals();
    const { selectedWorkspace } = useAppStateCtx();
    const defaultHookState = { loading: true, data: null, count: 0 };
    const [hookState, setHookState] = useState(defaultHookState);

    useEffect(() => {
        if(shouldSetSnapshot && selectedWorkspace.value?.modules.includes('clearance')){
            setHookState(defaultHookState);
            const {
                createdBy, folder, folderGroup, rightType, riskLevel, status, search
            } = filters;

            const compositeFilterConstraint = [
                where('workspaceId', '==', selectedWorkspace.value.uid),
            ];
            
            if(createdBy && createdBy !== 'all') compositeFilterConstraint.push(where('createdBy', '==', createdBy));
            if(status && status !== 'all'){
                if(status === 'pending'){
                    compositeFilterConstraint.push(where('awaitingOperatorReviewCount', '!=', 0));
                } else if(status === 'done'){
                    compositeFilterConstraint.push(where('awaitingOperatorReviewCount', '==', 0));
                } else if(status === 'approved'){
                    compositeFilterConstraint.push(where('approved', '==', 'yes'));
                } else if(status === 'rejected'){
                    compositeFilterConstraint.push(where('approved', '==', 'no'));
                }
            }
            if(folder && folder !== 'all') compositeFilterConstraint.push(where('projectId', '==', folder));
            if(folderGroup && folderGroup !== 'all') compositeFilterConstraint.push(where('groupId', '==', folderGroup));
            if(rightType && rightType !== 'all') compositeFilterConstraint.push(where('rightType', '==', rightType));
            if(riskLevel && riskLevel !== 'all') compositeFilterConstraint.push(where('riskLevel', '==', riskLevel));
            if(search) compositeFilterConstraint.push(where('keywords', 'array-contains', toLowerCaseWithoutSpecialCharacters(search)));
            
            const unsubscribe = onSnapshot(
                query(
                    collection(db, 'clearance_notes'),
                    and(...compositeFilterConstraint),
                ),
                (snapshots) => {
                    const dataArray = [];
                    snapshots.forEach(snapshot => {
                        const data = snapshot.data();
                        dataArray.push({
                            ...data,
                            isClearanceReportNote: true,
                            uid: snapshot.id,
                        })
                    });
                    setHookState({
                        loading: false,
                        data: dataArray
                    });
                },
                (error) => {
                    console.error(error.message);
                }
            );
            return () => unsubscribe();
        }
    }, [shouldSetSnapshot, selectedWorkspace.value, filters]);

    return hookState;
};

export default useGetWorkspaceClearanceReportNotes;