import { useSignals } from '@preact/signals-react/runtime';

import { Box, Divider } from '@mui/material';
import UserComment from './UserComment';

import { useAppStateCtx } from '../context/AppState';

const CommentsList = ({comments}) => {
    useSignals();
    const { workspaceUsers } = useAppStateCtx();

    return (
        <Box>
            {
                comments.map((comment, commentIndex) => {
                    let userImage = '', userName = '';
                    const commentUser = workspaceUsers.value ? workspaceUsers.value[comment.createdBy] : comment.createdByUser;
                    if(commentUser){
                        userImage = commentUser.photoUrl;
                        userName = commentUser.name;
                    }

                    return (
                        <Box key={commentIndex} mb={1}>
                            <UserComment
                                comment={comment}
                                // commentFiles={commentFiles}
                                // hasFiles={hasFiles}
                                userImage={userImage}
                                userName={userName}
                            />
                            <Divider />
                        </Box>
                        
                    )
                })
            }
        </Box>
    );
};

export default CommentsList;