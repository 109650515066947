import moment from 'moment';

import { ASSIGNABLE_FIELDS, ERROR_MESSAGE_UNKNOWN, INPITypes, SPECIAL_STRINGS_ALL } from './constants';
import { sortByKey, onlyUnique } from './filters';

import DocumentsTemplate from '../classes/DocumentsTemplate';
import getDoc from '../firebase/firestore/getDoc';

const capitalizeFirstLetter = (phrase) => {
    const phraseWords = phrase.split(' ').map(phraseWord => {
        phraseWord = phraseWord.toLowerCase();
        if(/^(das?|de|dos?)$/.test(phraseWord)){
            return phraseWord;
        }
        return phraseWord.charAt(0).toUpperCase() + phraseWord.slice(1);
    });
    const newValue = phraseWords.join(' ');
    return newValue;
}

const copy = (string, toast, toastMessage) => {
    navigator.clipboard.writeText(string);
    if(toast) toast(toastMessage || 'Agora é só colar o texto onde você quiser', { type: 'info' });
};

const deepClone = (object) => {
    return JSON.parse(JSON.stringify(object));
};

const escapeRegex = (str) => {
    return str.replace(/([.*+?^${}()|[\]\\])/g, '\\$1');
};

const filterTable = (value, tableBodyId, colIndex) => {
    let filter, table, tr, td, i, txtValue;
    filter = value.toUpperCase();
    table = document.getElementById(tableBodyId);
    tr = table.getElementsByTagName('tr');
    for (i = 0; i < tr.length; i++) {
        if(colIndex){
            td = tr[i].getElementsByTagName('td')[colIndex];
            if (td) {
                txtValue = td.textContent || td.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    tr[i].style.display = '';
                } else {
                    tr[i].style.display = 'none';
                }
            }
        } else {
            let show = false;
            txtValue = tr[i].textContent || tr[i].innerText;
            if(txtValue.toUpperCase().indexOf(filter) > -1) show = true;
            tr[i].style.display = (show ? '' : 'none');
        }
    }
};

const flagDocument = async ({
    activeUser,
    comment,
    projects,
    selectedDocument,
    setLoading,
    toast,
    type,
    users
}) => {
    if(setLoading) setLoading(true);

    const res = selectedDocument.flagDocument({
        activeUser,
        comment,
        projects,
        type,
        users
    });
    if(res.error){
        if(toast) toast(ERROR_MESSAGE_UNKNOWN, { type: 'error' });
        return;
    }

    if(!!type && toast) toast(`${type === 'client' ? 'O Jurídico' : 'O(a) solicitante'} foi notificado.`, { autoClose: 3000, type: 'success' });

    if(setLoading) setLoading(false);
};

const formatCurrency = (number) => {
    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(number / 100);
};

const getAssignedFieldNameById = (id) => {
    let field = ASSIGNABLE_FIELDS.find(f => f.id === id);
    return field.name;
};

const getCaseLawyersByCaseId = (cases, caseId) => {
    if(cases){
        const foundCase = cases.find(i => i.uid === caseId);
        if(foundCase){
            let lawyers = foundCase.operatorId;
            try {
                lawyers = JSON.parse(lawyers);
                return lawyers;
            } catch (error) {
                return lawyers;
            }
        }
    }
    return null;
};

const getCaseLawyersNames = (users, lawyers) => {
    let names = '';
    try {
        names = lawyers.map(id => getUserNameById(users, id)); 
        names = names.join(', ');
    } catch (error) {
        names = '?';
    }
    return names;
};

const getCaseLawyersNamesByCaseId = (cases, caseId, users) => {
    let names = '?';
    const foundCase = cases.find(i => i.uid === caseId);
    if(foundCase){
        let lawyers = foundCase.operatorId;
        names = getCaseLawyersNames(users, lawyers);
    }
    return names;
};

const getChartDataFoldersTime = (timesheet, setDataCallback, projects) => {
    let folders = [];
    let counter = 0;
    timesheet.forEach(i => {
        counter += i.minutes;
        let folder = i.projectId;
        let folderIndex = folders.findIndex(j => j[0] === folder);
        if(folderIndex > -1){
            folders[folderIndex][1] += i.minutes;
        } else {
            folders.push([folder, i.minutes]);
        }
    });
    folders = folders.sort(function(a, b) {
        if (a[1] === b[1]) {
            return 0;
        } else {
            return (a[1] < b[1]) ? 1 : -1;
        }
    });
    folders = folders.map(i => {
        let folderName = '';
        if(projects){
            const foundProject = projects[i[0]];
            if(foundProject) folderName = foundProject.name;
        }
        return [folderName, i[1]/60];
    });
    folders.unshift(['Projeto', 'Horas']);
    setDataCallback(folders);
    return counter;
};

const getClearanceTemplate = async ({
    clearanceTemplateId,
    setClearanceTemplate,
    setLoading
}) => {
    if(setLoading) setLoading(true);

    const res = await getDoc(`clearance_templates/${clearanceTemplateId}`);
    if(setLoading) setLoading(false);
    if(res.error){
        return;
    }

    if(setClearanceTemplate) setClearanceTemplate(res.result);
};

const getDocumentFieldLastArrayItem = (document, field) => {
    let item;
    if(document){
        let array = document[field];
        if(Array.isArray(array) && array.length >= 1){
            item = array[array.length - 1];
        }
    }
    return item;
};

const getDocumentFolderName = (document, projects) => {
    return getProjectNameById(projects, document.projectId);
};

const getDocumentGoogleDocExportLink = (googleDocUrl, exportFormat) => {
    let googleId = googleDocUrl.match(/[-\w]{25,}/);
    return `https://docs.google.com/feeds/download/documents/export/Export?id=${googleId}&exportFormat=${exportFormat}`;
}

const getDocumentLastCommentIfHuman = (selectedDocument, workspaceUsers) => {
    let documentComment = null;
    let documentNoHtmlComment = '';
    if(selectedDocument.lastComment?.comment && selectedDocument.lastVersion && moment(selectedDocument.lastComment.createdAt).isAfter(moment(selectedDocument.lastVersion.createdAt))){
        if(selectedDocument.lastComment.auto){
            documentComment = {
                ...selectedDocument.lastComment,
                comment: `${getUserNameById(workspaceUsers, selectedDocument.lastComment.createdBy)}: "${selectedDocument.lastComment.comment}"`
            }
            documentNoHtmlComment = `${getUserNameById(workspaceUsers, selectedDocument.lastComment.createdBy)}: "${selectedDocument.lastComment.comment.replace(/<[^>]+>/g, '').replace(/\n/g, ' ')}"`;
        } else {
            documentComment = selectedDocument.lastComment;
            documentNoHtmlComment = selectedDocument.lastComment.comment.replace(/<[^>]+>/g, '').replace(/\n/g, ' ');
        }
    }
    return { comment: documentComment, noHtmlComment: documentNoHtmlComment };
};

const getDocumentVersionLink = (version, serverPath) => {
    let link = ''; 
    if(version.fileFormat){
        if(version.serverFile){
            link = `${serverPath}/${version.link}`;
        } else {
            if(version.fileFormat === 'googleDoc'){
                link = version.link || version.googleDoc;
            } else {
                link = version.link;
            }
        }
    } else {
        if(version.type === 'download' || version.type === 'link'){
            if(!serverPath) serverPath = '';
            if(version.link?.match('https://') || version.link?.match('http://')){
                link = version.link;
            } else {
                link = `${serverPath}/${version.link}`;
            }
        } else {
            link = version.googleDoc;
        }
    }
    return link;
};

const getFileExtension = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return fileExtension;
};

const getFileFormat = (fileName) => {
    const acceptedFormats = ['doc', 'docx', 'pdf'];
    const fileExtension = getFileExtension(fileName);
    if(acceptedFormats.includes(fileExtension)) return fileExtension;
    return '';
};

const getFolderCompanyName = (projects, folder) => {
    if(folder){
        if(folder.clientId){
            return getProjectNameById(projects, folder.clientId);
        } else {
            return folder.name;
        }
    }
    return null;
};

const getFolderCustomDocumentFields = (folder) => {
    let folderCustomDocumentFields = folder.documentsCustomColumns || [];
    if(folderCustomDocumentFields.length === 0){
        folderCustomDocumentFields.push({
            id: '1',
            name: 'Anotações',
            type: 'text'
        });
        if(!folder?.eSignature) folderCustomDocumentFields.unshift({
            id: '0',
            name: 'Situação',
            type: 'select',
            options: ['Não assinado', 'Assinado', 'Outra']
        });
    }
    return folderCustomDocumentFields;
};

const getFolderGroups = (folder, folderUsers) => {
    let folderGroups = [];
    if(folder && folder.useGroups){
        folderGroups = folder.groups || [];
        // if(!folderGroups.find(group => group.id === '2' || group.id === 2)){
        //     folderGroups.push({id: 2, name: 'Administradores', users: []});
        // }
        if(!folderGroups.find(group => group.id === '1' || group.id === 1)){
            folderGroups.push({id: 1, name: 'Gerentes', users: []});
        }
        if(!folderGroups.find(group => group.id === '~all;' || group.id === '&all')){
            folderGroups.push({id: '&all', name: 'Todos', users: []});
        }
        if(!folderGroups.find(group => group.id === '~none;' || group.id === '&none')){
            folderGroups.push({id: '&none', name: 'Nenhum', users: []});
        }

        folderUsers.forEach(folderUser => {
            let userGroup = null;
            folderGroups.forEach(group => {
                if(group.users.includes(folderUser)) userGroup = group;
            });
            let group_all_index = folderGroups.findIndex(group => group.id === '~all;' || group.id === '&all');
            let group_none_index = folderGroups.findIndex(group => group.id === '~none;' || group.id === '&none');
            if(!userGroup && folderGroups.length > 4){
                folderGroups[group_none_index].users.push(folderUser);
            } else if(!userGroup && folderGroups.length <= 4){
                folderGroups[group_all_index].users.push(folderUser);
            }
        });

        return {
            folderGroups,
            userGroups: folderGroups
        };
    }
    return {
        userPosition: 2
    };
};

const getGroupNameById = (groupId, groups) => {
    if(groupId){
        if(groupId === '2'){
            return 'Administradores';
        } else if(groupId === '1'){
            return 'Gerentes';
        } else {
            if(groups){
                const foundGroup = groups[groupId];
                if(foundGroup) return foundGroup.name;
            }
        }
    }
    return 'Nenhum';
};

const getGroupsByFolderId = (projectId, projects) => {
    if(projects){
        const foundProject = projects[projectId];
        if(foundProject?.useGroups){
            return foundProject.groups;
        }
    }
    return false;
};

const getInpiRecordNameById = (inpi, id) => {
    let name = '';
    let record = inpi.find(i => i.uid === id);
    if(record){
        name = `"${record.name}"`;
        let recordType = INPITypes.find(i => i.id === record.type);
        let recordTypeName = recordType ? `${recordType.name} ` : '';
        name = recordTypeName + name;
    }
    return name;
};

const getKeywords = (...texts) => {
    const keywords = [''];
    for(const text of texts){
        const words = toLowerCaseWithoutSpecialCharacters(text).split(' ');
        let currentText = '';
        let lastWord = '';
        for(const word of words){
            let currentWord = '';
            if(lastWord && lastWord !== word) currentText += ' ';
            for(const character of word){
                currentWord += character;
                keywords.push(currentWord);
                currentText += character;
                keywords.push(currentText);
            }
            lastWord = word;
        }
    }
    return keywords.filter(onlyUnique);
}

const getLawsuitNameById = (id, lawsuits, projects, type = 'lawsuits') => {
    let caseName = '';
    if(id && lawsuits){
        const types = {
            lawsuitsShort(caseData){
                let result = '';
                if(caseData.caseNumber !== '') result += caseData.caseNumber + '<br />';
                result += caseData.clientName !== '' ? caseData.clientName : getProjectNameById(projects, caseData.project);
                if(caseData.position !== '') result += ' ('+caseData.position+')';
                if(caseData.versus !== '') result += ' X '+caseData.versus;
                return result;
            },
            lawsuitsShortNoBreak(caseData){
                let result = '';
                if(caseData.caseNumber !== '') result += caseData.caseNumber + ' - ';
                result += caseData.clientName !== '' ? caseData.clientName : getProjectNameById(projects, caseData.project);
                if(caseData.position !== '') result += ' ('+caseData.position+')';
                if(caseData.versus !== '') result += ' X '+caseData.versus;
                return result;
            },
            lawsuits(caseData){
                let result = '';
                if(caseData.caseNumber !== '') result += caseData.caseNumber + ' - ';
                result += caseData.clientName !== '' ? caseData.clientName : getProjectNameById(projects, caseData.project);
                if(caseData.position !== '') result += ' (' + caseData.position + ')';
                if(caseData.versus !== '') result += ' X ' + caseData.versus;
                result += ' - ' + caseData.name;
                return result;
            }
        };
    
        const foundCase = lawsuits.find(l => l.uid === id);
        if(foundCase) caseName = types[type](foundCase);
    }
    return caseName;
};

const getProjectNameById = (projects, id) => {
    if(projects){
        const foundProject = projects[id];
        if(foundProject) return foundProject.name;
    }
    return '';
};

const getProjectTemplates = (projectTemplates, templateLists) => {
    let allowedTemplates = [];
    if(projectTemplates){
        if(projectTemplates.lists?.length !== 0){
            projectTemplates.lists.forEach(listId => {
                const list = templateLists[listId];
                if(list) allowedTemplates = [...list.list];
            });
        }
        if(projectTemplates.include?.length !== 0){
            projectTemplates.include.forEach(templateId => {
                if(!allowedTemplates.includes(templateId)) allowedTemplates.push(templateId);
            });
        }
        if(projectTemplates.exclude?.length !== 0){
            projectTemplates.exclude.forEach(templateId => {
                if(allowedTemplates.includes(templateId)){
                    const templateIndex = allowedTemplates.findIndex(a_t => a_t === templateId);
                    if(templateIndex !== -1) allowedTemplates = [...allowedTemplates.slice(0, templateIndex), ...allowedTemplates.slice(templateIndex + 1)];
                }
            });
        }
    }
    return allowedTemplates;
};

const getTemplateNameById = (id, templates, includeDescription) => {
    let name = '';
    if(id){
        if(id === 'review'){
            name = 'Revisão';
        } else {
            let template = templates[id];
            if(template){
                name = template.name;
                if(includeDescription && template.description) name += ` (${template.description})`;
            } else {
                name = id;
            }
        }
    }
    return name;
};

const getUserImageById = (users, id) => {
    if(users && id){
        let user = users[id];
        if(user) return user.photoUrl;
    }
    return null;
};

const getUserNameById = (users, id) => {
    let name = '';
    if(users && id){
        const user = users[id];
        if(user) name = user.name;
        else name = '?';
    }
    return name;
};

const getUserProjects = (userPermissions, clients, projects) => {
    if(userPermissions && clients && projects){
        
        const userWorkspaceClients = userPermissions.clients || {};
        const userClients = Object.entries(clients).filter(([clientId]) => {
            return userWorkspaceClients[clientId];
        }).map(([clientId, client]) => ({...client, uid: clientId}));

        const userWorkspaceFolders = userPermissions.folders || {};
        const userProjects = Object.entries(projects).filter(([projectId]) => {
            return userWorkspaceFolders[projectId];
        }).map(([projectId, project]) => ({...project, uid: projectId}));

        return { projects: userProjects, clients: userClients };
    }
    return { projects: [], clients: [] };
};

const isGoogleDocUrlRegExTest = new RegExp(/docs\.google\.com\/document\/d\/[-\w]+\/edit/);

const mapFormList = (array) => {
    let currentList = [];
    array.forEach(item => {
        const cnaes = item.cnaes || [];
        const meiAllowed = item.meiAllowed || false;
        const mainItem = {
            id: item.uid,
            listItemId: item.uid,
            key: item.key,
            value: item.value,
            cnaes,
            meiAllowed
        }
        if(Array.isArray(item.aliases)) mainItem.aliases = item.aliases;
        currentList.push(mainItem);
        const aliases = item.aliases;
        if(Array.isArray(aliases)){
            aliases.forEach((alias, index) => {
                if(alias){
                    alias = {
                        id: `${item.uid}.${index}`,
                        listItemId: item.uid,
                        key: alias,
                        value: item.value,
                        cnaes,
                        meiAllowed
                    };
                    currentList.push(alias);
                }
            })
        }
    });
    currentList = currentList.sort(sortByKey('key'));
    return currentList;
};

const minutesToStringHoursAndMinutes = (mins) => {
    mins = parseInt(mins);
    let string = '';
    let hours = Math.floor(mins / 60);
    let minutes = mins % 60;
    if(hours > 0) string = hours + 'h';
    if(minutes > 0){
        if(hours > 0) string += ' ';
        string += minutes + 'min';
    }
    if(string.length === 0) string = '0';
    return string;
};

const removeDiacritics = (string) => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

const resetCustomDocumentFieldsMapOptions = (options) => {
    let newValue = [];
    if(options){
        newValue = options.map(field => ({...field, value: SPECIAL_STRINGS_ALL}));
    }
    return newValue;
};

const scrollToBottom = (elementId, element) => {
    if(!element) element = document.getElementById(elementId);
    if(element) element.scrollTop = element.scrollHeight;
};

const scrollToTop = (elementId, element) => {
    if(!element) element = document.getElementById(elementId);
    if(element) element.scrollTop = 0;
};

const sortClearanceReportNotes = (selectedReport) => {
    return (a, b) => {
        if(selectedReport?.type === 'script'){
            if(a.scriptScene !== null && b.scriptScene !== null && a.scriptScene !== undefined && b.scriptScene !== undefined){
                const aScriptScene = parseInt(a.scriptScene);
                const bScriptScene = parseInt(b.scriptScene);
                return aScriptScene > bScriptScene ? 1 : aScriptScene < bScriptScene ? -1 : a.createdAt > b.createdAt ? 1 : a.createdAt < b.createdAt ? -1 : 0;
            } else {
                if(a.scriptScene === null || a.scriptScene === undefined) {
                    return 1;
                } else if (b.scriptScene === null || b.scriptScene === undefined) {
                    return -1;
                }
            }
        } else if(selectedReport?.type === 'cut'){
            if(a.cutTimeCode !== null && a.cutTimeCode !== undefined && a.cutTimeCode !== '' && b.cutTimeCode !== null && b.cutTimeCode !== undefined && b.cutTimeCode !== ''){
                return a.cutTimeCode.localeCompare(b.cutTimeCode);
            } else {
                if(a.cutTimeCode === null || a.cutTimeCode === undefined || a.cutTimeCode === ''){
                    return 1;
                } else if (b.cutTimeCode === null || b.cutTimeCode === undefined || b.cutTimeCode === ''){
                    return -1;
                }
            }
        }
        return a.createdAt > b.createdAt ? 1 : (a.createdAt < b.createdAt ? -1 : 0);
    }
}

const startDocumentsForm = async ({
    initialValues,
    loadingForm,
    projectDocumentsSettings,
    projectId,
    projectMisc,
    resetForm,
    selectedDraft,
    selectedForm,
    selectedFormId,
    templateId,
    templates,
    values
}) => {

    if(selectedFormId) selectedFormId.value = templateId;

    loadingForm.value = true;

    const { currentSelectedTemplate } = startDocumentsFormGetSelectedTemplate({
        initialValues,
        resetForm,
        selectedDraft,
        selectedForm,
        templateId,
        templates
    });

    let currentProjectDocumentsSettings;
    if(!projectDocumentsSettings.value && projectId){
        const res = await getDoc(`projects/${projectId}/_more/documents`);
        if(res.result){
            currentProjectDocumentsSettings = res.result;
            projectDocumentsSettings.value = currentProjectDocumentsSettings;
        }
    }

    let currentProjectMisc;
    if(!projectMisc.value && projectId){
        const res = await getDoc(`projects/${projectId}/_more/misc`);
        if(res.result){
            currentProjectMisc = res.result;
            projectMisc.value = currentProjectMisc;
        }
    }

    if(selectedDraft.value){
        let draftValues = JSON.parse(selectedDraft.value.form);
        values.current = draftValues;
        initialValues.value = draftValues;
    } else if(currentProjectDocumentsSettings && !initialValues.value) {
        const initialForm = {};
        const projectInitialFormResponses = currentProjectDocumentsSettings.initialFormResponses || [];
        const templateIndex = projectInitialFormResponses.findIndex(item => item.templateId === templateId);
        if(templateIndex !== -1){
            for(const questionKey in projectInitialFormResponses[templateIndex].questions){
                initialForm[questionKey] = projectInitialFormResponses[templateIndex].questions[questionKey];
            };
        }
        values.current = initialForm;
        initialValues.value = initialForm;
    } else if(initialValues.value){
        values.current = initialValues.value;
    }
    
    selectedForm.value = currentSelectedTemplate;
};

const startDocumentsFormGetSelectedTemplate = ({
    initialValues,
    resetForm,
    selectedDraft,
    selectedForm,
    templateId,
    templates
}) => {
    resetForm({ preserveFormInitialValues: !!initialValues.value, preserveFormSelectedDraft: !!selectedDraft.value});
    selectedForm.value = null;
    const currentSelectedTemplate = startDocumentsFormGetSelectedTemplateAction({ templateId, templates });
    return { currentSelectedTemplate };
};

const startDocumentsFormGetSelectedTemplateAction = ({ templateId, templates }) => {
    const foundTemplate = templates[templateId];
    const currentSelectedTemplate = new DocumentsTemplate({...foundTemplate, uid: templateId});
    return currentSelectedTemplate;
};

const toLowerCaseWithoutSpecialCharacters = (text) => {
    return text.toLowerCase().normalize('NFD').replace(/[^a-zA-Z0-9 ]/g, '').trim();
}

const updateArrayItem = (array, updatedItem, idKey = 'uid') => {
    const updatedItemIndex = array.value.findIndex(item => item[idKey] === updatedItem[idKey]);
    array.value = [
        ...array.value.slice(0, updatedItemIndex),
        updatedItem,
        ...array.value.slice(updatedItemIndex + 1)
    ];
};

const updateStateAddArrayItem = (item, setState) => {
    setState(prevState => [...prevState, item]);
};

const updateStateAddPositionedArrayItem = (item, positionIndex, setState) => {
    setState(prevState => [...prevState.slice(0, positionIndex), item, ...prevState.slice(positionIndex)]);
};

const updateStateChangeArrayItemWith_id = (updatedItem, setState, _idKey = '_id') => {
    setState(prevState => {
        const index = prevState.findIndex(item => item[_idKey] === updatedItem[_idKey]);
        if(index !== -1){
            return [...prevState.slice(0, index), {...updatedItem, updatedAt: new Date()}, ...prevState.slice(index + 1)];
        }
        return prevState;
    });
};

const updateStateDeleteArrayItemBy_id = (id, setState) => {
    setState(prevState => {
        const itemIndex = prevState.findIndex(item => item.uid === id);
        if(itemIndex !== -1){
            return [...prevState.slice(0, itemIndex), ...prevState.slice(itemIndex + 1)];
        }
        return prevState;
    });
};

const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export {
    capitalizeFirstLetter,
    copy,
    deepClone,
    escapeRegex,
    filterTable,
    flagDocument,
    formatCurrency,
    getAssignedFieldNameById,
    getCaseLawyersByCaseId,
    getCaseLawyersNames,
    getCaseLawyersNamesByCaseId,
    getChartDataFoldersTime,
    getClearanceTemplate,
    getDocumentFieldLastArrayItem,
    getDocumentFolderName,
    getDocumentGoogleDocExportLink,
    getDocumentLastCommentIfHuman,
    getDocumentVersionLink,
    getFileFormat,
    getFolderCompanyName,
    getFolderCustomDocumentFields,
    getFolderGroups,
    getProjectTemplates,
    getGroupNameById,
    getGroupsByFolderId,
    getInpiRecordNameById,
    getKeywords,
    getLawsuitNameById,
    getProjectNameById,
    getTemplateNameById,
    getUserImageById,
    getUserNameById,
    getUserProjects,
    isGoogleDocUrlRegExTest,
    mapFormList,
    minutesToStringHoursAndMinutes,
    removeDiacritics,
    resetCustomDocumentFieldsMapOptions,
    scrollToBottom,
    scrollToTop,
    sortClearanceReportNotes,
    startDocumentsForm,
    startDocumentsFormGetSelectedTemplate,
    startDocumentsFormGetSelectedTemplateAction,
    toLowerCaseWithoutSpecialCharacters,
    updateArrayItem,
    updateStateAddArrayItem,
    updateStateAddPositionedArrayItem,
    updateStateChangeArrayItemWith_id,
    updateStateDeleteArrayItemBy_id,
    validateEmail
}