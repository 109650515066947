import { useEffect, useState } from 'react';

import { useSignals } from '@preact/signals-react/runtime';

import { useAppStateCtx, useAppStateCtxUtils } from '../context/AppState';
import { sortByKey } from '../utils/filters';

const useGetWorkspaceProjectsWithClearanceOptions = (shouldSetListener, projects) => {
    useSignals();
    const { workspaceProjectsWithClearanceIds } = useAppStateCtx();
    const { activeUserIsOperator } = useAppStateCtxUtils();
    const [projectOptions, setProjectOptions] = useState(null);

    useEffect(() => {
        if(shouldSetListener && projects && activeUserIsOperator.value && workspaceProjectsWithClearanceIds.value){
            const currentProjectOptions = [];
            workspaceProjectsWithClearanceIds.value
            .forEach(projectId => {
                const foundProject = projects[projectId];
                if(foundProject){
                    currentProjectOptions.push({...foundProject, uid: projectId});
                }
            });
            setProjectOptions(currentProjectOptions.sort(sortByKey('name')));
        }
    }, [shouldSetListener, projects, activeUserIsOperator.value, workspaceProjectsWithClearanceIds.value]);

    return projectOptions;
};

export default useGetWorkspaceProjectsWithClearanceOptions;