import { httpsCallable } from 'firebase/functions';

import { functions } from '../config';

export default async function callFunction(functionName: string, data: any){
    let result = null, error = null;

    const callableFunction = httpsCallable(functions, functionName);

    try {
        const callableFunctionResponse = await callableFunction(data);
        result = callableFunctionResponse.data;
    } catch (e) {
        error = e;
    }
    
    return { result, error };
}