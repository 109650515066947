import { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import InputMask from 'react-input-mask';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';

import Select from '../Select';
import Switch from '../Switch';

const classes = {
    box: {
        padding: '8px',
        cursor: 'grab',
        borderRadius: 4,
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.08)',
        '&:hover': {
            boxShadow: '0 2px 16px rgba(0, 0, 0, 0.24)'
        }
    }
};

const Signer = ({data, defaultSigner}) => {
    const {
        eSignaturePlatform,
        handleSignerChange,
        index,
        removeSigner,
        signer
    } = data;

    const draggingStyle = {
        backgroundColor: '#e8f5e9',
        border: `1px solid grey`
    };
    let backgroundColor = '#FFF5EE';
    let defaultBorder = '1px solid #E8E8E8';
    const boxStyle = {
        borderRadius: 3, position: 'relative', marginBottom: 4, border: defaultBorder, backgroundColor
    };
    const [signerState, setSignerState] = useState({...defaultSigner});

    useEffect(() => {
        setSignerState(signer);
    }, [signer]);

    const handleSignerStateChange = (newValue, key) => {
        setSignerState(prevState => ({ ...prevState, [key]: newValue }));
    }

    return (
        <Draggable draggableId={signer.uid} index={index}>
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Box
                        sx={classes.box}
                        style={snapshot.isDragging ? {...boxStyle, ...draggingStyle} : boxStyle}

                    >
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Select
                                        disabled={signer.locked}
                                        style={{width: 114}}
                                        label="Função"
                                        options={
                                            [
                                                {value: 'party', label: 'Parte'},
                                                {value: 'witness', label: 'Testemunha'},
                                                ...(signer.locked ? [{value: 'contratante', label: 'Parte (padrão)'}] : []),
                                                ...(eSignaturePlatform === 'docuSign' ? [{value: 'observer', label: 'Observador'}] : [])
                                            ]
                                        }
                                        value={signer.type}
                                        onChange={(e) => handleSignerChange(e.target.value, index, 'type')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm>
                                    <TextField
                                        variant="standard"
                                        disabled={signer.locked}
                                        fullWidth
                                        multiline
                                        label="Nome completo"
                                        value={signerState.name}
                                        onChange={(e) => handleSignerStateChange(e.target.value, 'name')}
                                        onBlur={() => handleSignerChange(signerState.name, index, 'name')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField
                                        variant="standard"
                                        disabled={signer.locked}
                                        fullWidth
                                        label="E-mail"
                                        value={signerState.email}
                                        onChange={(e) => handleSignerStateChange(e.target.value, 'email')}
                                        onBlur={() => handleSignerChange(signerState.email, index, 'email')}
                                    />
                                </Grid>
                                {
                                    signer.type !== 'observer' &&
                                    <>
                                        <Grid item xs={12} sm={2}>
                                            <Grid container alignItems="center">
                                                <Grid item>
                                                    <Switch disabled={signer.locked} checked={signer.brazilian} onChange={(e) => handleSignerChange(e.target.checked, index, 'brazilian')} />
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body1">{!signer.brazilian ? 'Não t' : 'T'}em CPF</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={12} sm={2}>
                                            {
                                                signer.brazilian &&
                                                <Grid item>
                                                    <InputMask
                                                        disabled={signer.locked}
                                                        mask="999.999.999-99"
                                                        value={signerState.realId}
                                                        onChange={(e) => handleSignerStateChange(e.target.value, 'realId')}
                                                        onBlur={() => handleSignerChange(signerState.realId, index, 'realId')}
                                                        maskChar=""
                                                    >
                                                        {() => (
                                                            <TextField
                                                                variant="standard"
                                                                disabled={signer.locked}
                                                                style={{width: 114}}
                                                                label="CPF" />
                                                        )}
                                                    </InputMask>
                                                </Grid>
                                            }
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            <Grid item>
                                <Tooltip title="Excluir">
                                    <IconButton
                                        onClick={() => removeSigner(index)} size="small"
                                    ><ClearIcon /></IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            )}
        </Draggable>
    );
};

export default Signer;